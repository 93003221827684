import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})

export class DispatchTabList {
	tab: string;
}

export class ConstantService {

	constructor() { }

	public PICKING_BOY = "Picking.boy";
	public PLANNING_MANAGER = "Planning.manager";

	public SPECIFIC = "Specific";
	public DEALER_ALL = "dealer_all";
	public DEALER = "dealer";
	public DISTRIBUTOR = "distributor";
	public ADMIN = "admin";

	public INGREDIENTS = "ING";
	public OUTPUT_PRODUCT = "OUT_PROD";
	public ALL_PRODUCTS = "ALL";
	public CONSUMABLES = "CONSUMABLES";
	public NOT_VISITED = "Not Visited";
	public options: any[] = ['NO', 'YES'];

	public dispatchTabList: DispatchTabList[] = [{ tab: 'Summary' }, { tab: 'Dispatches' }];

	getDispatchTabList(): DispatchTabList[] {
		return this.dispatchTabList;
	}

	public paymentTypes: any[] = ['Credit', 'Card', 'Cash'];

	//public paymentTypes: any[] = [ 'Card', 'Cash'];

	public selectedDescription: any[] = ["Advance received", "Balance received as per bill", "Bank Charges paid", "Cash Deposited", "Cash Received", "Cash paid as per bill", "Cash paid as per receipt no.", "Cheque collection charges", "Cheque number", "Courier charges paid", "DD number", "Electricity charges paid for the month", "Electricity charges payable for the month", "Freight paid to lorry", "Freight paid", "Salary paid for the month", "Salary payable for the month", "Stationary purchased as per bill no.", "Wages paid for the month", "Wages payable for the month", "Unloading charges paid for the month"];

	public getPaymentTypes(): any[] {
		return this.paymentTypes;
	}

	public getSelectedDescription(): any[] {
		return this.selectedDescription;
	}

	public expensePaymentModes: Object[] = [{ 'type': 'CA', 'name': 'Cash' }, { 'type': 'DD', 'name': 'Demand Draft' }, { 'type': 'CH', 'name': 'Cheque' }, { 'type': 'MO', 'name': 'Money Order' }, { 'type': 'CC', 'name': 'Credit Card' }, { 'type': 'NF', 'name': 'NEFT' }, { 'type': 'RT', 'name': 'RTGS' }];

	public getExpensePaymentModes(): Object[] {
		return this.expensePaymentModes;
	}

	public getMonthList(count, type): any[] {
		var list: any[] = [{ 'id': 0, 'name': 'January' }, { 'id': 1, 'name': 'February' }, { 'id': 2, 'name': 'March' }, { 'id': 3, 'name': 'April' }, { 'id': 4, 'name': 'May' }, { 'id': 5, 'name': 'June' }, { 'id': 6, 'name': 'July' }, { 'id': 7, 'name': 'August' }, { 'id': 8, 'name': 'September' }, { 'id': 9, 'name': 'October' }, { 'id': 10, 'name': 'November' }, { 'id': 11, 'name': 'December' }]
		var returnList: any[] = [];
		var innerCnt: number = 0;
		var curDate = new Date();
		var currentMonth = curDate.getMonth();
		var curYear = curDate.getFullYear();

		var i = list.findIndex(l => l.id == currentMonth);
		if (i != -1) {

			if (type === 'reverse') {
				var reverseCnt: number = count;

				while (reverseCnt > 0) {
					list[i].year = curYear;
					returnList.splice(0, 0, list[i]);
					i--;
					reverseCnt--;
					if (i == -1) {
						i = list.length - 1;
						curYear--;
					}
				}

			} else {

				var startFromBegin: number = count;
				while (startFromBegin > 0) {
					list[i].year = curYear;
					returnList.push(list[i]);
					i++;
					startFromBegin--;
					if (i == list.length) {
						i = 0;
						curYear++;
					}
				}

				/* if(list.length -i < count){
				  startFromBegin = count -(list.length -i);
				}
				list.forEach((l,ind) =>{
				  if(ind >=i){
					l.year = curYear;
					return returnList.push(l);
				  }
				});
				  if(startFromBegin > 0){
					curYear++;
					list.forEach((l,ind)=>{
					  if(ind < startFromBegin){
						l.year = curYear;
						return returnList.push(l);
					  }
					});
				  } */
			}

			return returnList;
		}
		return [];
	}

	public getFirstDayOfMonth(month: any, year: any): any {
		var firstDay = new Date(year, month, 1);
		return firstDay;
	}

	public getDayFromDate(d: Date): any {
		var day = d.getDate();
		return day;
	}

	//prolake 2019/10/08 
	public report_types: any[] = [{ 'name': 'Customer asks report', 'category': 1 },
	{ 'name': 'Customer last visited report', 'category': 2 },
	{ 'name': 'Visit log report', 'category': 3 }];

	public listReport_types() {
		return this.report_types;
	}

	public getRolePermissions(): any {
		var rolePermission: any[] = [];

		// acess dashboard 
		rolePermission['DASHBOARD'] = 'ng.screen.custmgmt.dashboard';

		// customer menu 
		rolePermission['CUSTOMER'] = 'ng.screen.orgmgmt.customer';
		rolePermission['CUSTOMERSEARCH'] = 'ng.screen.orgmgmt.customersearch';
		//	rolePermission['APPROVALWORKFLOW'] = 'ng.screen.approvalworkflow';


		rolePermission['EMPID'] = 'ng.component.custmgmt.empIdbranchId';
		rolePermission['PAYROLL'] = 'ng.screen.paymgmt.payroll';


		rolePermission['NOTICEBOARD'] = "ng.screen.noticeboard"

		rolePermission['REPORTMASTER'] = 'ng.screen.reportmaster'


		//docmuments menu permissions
		rolePermission['DOCMASTER'] = 'ng.screen.docmgmt.docmaster';
		rolePermission['MYDOCUMENTS'] = 'ng.screen.docmgmt.mydocuments';
		rolePermission['APPROVALINBOX'] = 'ng.screen.docmgmt.approvalInbox';
		rolePermission['DOCSUB'] = 'ng.screen.docmgmt.docsub';

		rolePermission['PAYSLIPCHECKBOXPERMISSION'] = 'ng.component.paymgmtreviewpayslips.checkboxPermission';

		rolePermission['PRIVACYPOLICY'] = 'ng.screen.police.uploadrulesandpolicy';

		// ALL Master screen permissions
		rolePermission['MASTER'] = "ng.screen.master.all" //main menu permission   // rempve this ,,, and use setup 
		rolePermission['SETUP'] = "ng.screen.emp.setup"
		rolePermission['BRANCH'] = "ng.screen.master.branch"
		rolePermission['DESIGNATION'] = "ng.screen.master.designation"
		rolePermission['EMPLOYEEDESIGNATION'] = "ng.screen.employeedesignation"
		rolePermission['EMPLOYEESUSPENSION'] = "ng.screen.empsuspension"
		rolePermission['EMPLOYEESUSPENSIONREPORT'] = "ng.screen.empsuspensionreport"
		rolePermission['APPROVEEMPLOYEESUSPENSIONREPORT'] = "ng.screen.approvesuspensionreport"
		rolePermission['DEPARTMENT'] = "ng.screen.master.department"
		rolePermission['EMPLOYEEMASTER'] = "ng.screen.master.employee"
		rolePermission['DEFINEDOCTYPE'] = "ng.screen.master.document"
		rolePermission['BULKCHPWD'] = "ng.screen.emp.bulkchpwd"
		rolePermission['EMPLOYEETYPE'] = "ng.screen.employeetype"

		// inside setup 
		rolePermission['LEAVEMASTER'] = "ng.screen.leavemaster"
		rolePermission['BRANCHMASTER'] = "ng.screen.branchmaster"
		rolePermission['ASSIGNLEAVETYPE'] = "ng.screen.assignLeaveType"
		rolePermission['FACERECOGNITION'] = "ng.screen.face-recognition"
		rolePermission['ANNUALHOLIDAYMASTER'] = "ng.screen.annual-holidaymaster"

		rolePermission['EXPENSEMASTER'] = "ng.screen.expense-master"
		rolePermission['EXPENSESUBMISSION'] = "ng.screen.expense-submission"
		rolePermission['EXPENSEAPPROVAL'] = "ng.screen.expense-approval"
		rolePermission['EXPENSETYPE'] = "ng.screen.expense-type"
		rolePermission['EXPENSEREPORT'] = "ng.screen.expense-report"

		//policy

		rolePermission['POLICYSUBMISSION'] = "ng.screen.policy-submission"
		rolePermission['POLICYAPPROVAL'] = "ng.screen.policy-approval"

		// employees sub menu permissions
		rolePermission['EMPLOYEESECTION'] = 'ng.screen.employee.section';
		rolePermission['EMPLOYEEDOCSEARCH'] = 'ng.screen.empmgmt.employee'; // search employee documents
		rolePermission['DeleteverifiedDoc'] = "ng.screen.empmgmt.employee.VerifiedDoc.delete"


		// leave and attendance master
		rolePermission['LEAVEANDATTENDANCEMASTER'] = "ng.screen.leaveattendance.master"
		rolePermission['ATTENDANCE'] = "ng.screen.attendance.menu"
		rolePermission['LEAVE'] = "ng.screen.leave.menu"
		rolePermission['LEAVEREPORTMENU'] = "ng.screen.leavereport.menu"
		rolePermission['ATTENDANCEREPORTMENU'] = "ng.screen.attendancereport.menu"

		rolePermission['ATTENDENCEMASTER'] = 'ng.screen.attendencemaster.employee'
		rolePermission['APPROVEAPPLICATION'] = 'ng.screen.attend.approve'
		rolePermission['LEAVEAPPLYONBEHALFOFEMPLOYEE'] = 'ng.screen.attend.leave.employee'
		rolePermission['LEAVEAPPLICATION'] = 'ng.screen.attend.leave'
		rolePermission['ATTENDANCEAPPROVAL'] = 'ng.screen.attendance.approval'
		rolePermission['ATTENDANCESUMMARY'] = 'ng.screen.attendance.summary'
		rolePermission['APPLYATTENDANCE'] = 'ng.screen.attendance.apply'
		rolePermission['ATTENDANCEREPORT'] = 'ng.screen.attendance.report'
		rolePermission['ATTENDANCEREPROXY'] = 'ng.screen.attendance.proxy'
		rolePermission['LEAVEENCASHMENT'] = 'ng.screen.leave.encash';
		rolePermission['LEAVEENCASHMENTREPORT'] = 'ng.screen.leave.encash.report';
		rolePermission['OVERTIMEREPORT'] = 'ng.screen.overtime.report';

		// shift master
		rolePermission['SHIFTMASTER'] = "ng.screen.shiftmaster" // permission for main menu
		rolePermission['SHIFTMASTERS'] = "ng.screen.shiftmasters"
		rolePermission['EMPLOYEEGROUP'] = "ng.screen.empmgmt.employee.group"
		rolePermission['SHIFTALLOCATION'] = "ng.screen.shiftallocation"
		rolePermission['SHIFTALLOCATED'] = "ng.screen.employee.shiftallocated"


		// user menu permissions
		rolePermission['SETTING'] = "ng.screen.admin.setting"
		rolePermission['ROLE'] = 'ng.screen.orgmgmt.role';
		rolePermission['USER'] = 'ng.screen.orgmgmt.user';
		rolePermission['CHNGEPASSWORD'] = 'ng.screen.emp.chngpassword';

		//contract management 
		rolePermission['CONTRACTCLIENT'] = "ng.screen.contractclient";
		rolePermission['CONTRACTCLIENTLOCATION'] = "ng.screen.contractclientlocation";
		rolePermission['CONTRACTCLIENTPROJECT'] = "ng.screen.contractclientproject";
		rolePermission['CONTRACTPROJECTDESEST'] = "ng.screen.contractclientprojectdesest";
		rolePermission['CONTRACTPROJECTDESACT'] = "ng.screen.contractclientprojectdesact";
		rolePermission['CONTRACTCLIENTPROJECTFREEZE'] = "ng.screen.contractclientprojectfreeze";
		rolePermission['CONTRACTPROJECTALLOCATION'] = "ng.screen.contractprojectallocation";
		rolePermission['GROUPMASTER'] = "ng.screen.groupmaster";
		rolePermission['FORMT'] = "ng.screen.formt.report";
		rolePermission['FORMTDOWNLOAD'] = "ng.screen.formtdownload.report";

		rolePermission['JOBMASTER'] = "ng.screen.jobmaster";
		rolePermission['APPLYCOMPOFF'] = "ng.screen.apply.comp.off";
		rolePermission['APPROVECOMPOFF'] = "ng.screen.approve.comp.off";
		rolePermission['APPLYEXTRATIMEOUT'] = "ng.screen.apply.extra.timeout";
		rolePermission['EXTRATIMEOUTREPORT'] = "ng.screen.extra.timeout.report";
		rolePermission['APPROVEEXTRATIMEOUT'] = "ng.screen.approve.extra.timeout";

		rolePermission['GENERATESALARY'] = "ng.screen.generate.salary";
		rolePermission['SALARYADVANCE'] = "ng.screen.salary.advance";
		rolePermission['SALARYLOANAPPLY'] = "ng.screen.salary.loanApply";
		rolePermission['SALARYADVANCEAPPROVE'] = "ng.screen.salary.advanceAprove";
		rolePermission['SALARYADVANCEREPORT'] = "ng.screen.salary.advanceReport";



		rolePermission['SALARYHEAD'] = "ng.screen.salary.head";
		rolePermission['SALARYDETAIL'] = "ng.screen.salary.detail";

		rolePermission['SALARYUPLOAD'] = "ng.screen.salary.manualfed";
		rolePermission['SALARYREPORTBANK'] = "ng.screen.salary.reportbank";
		rolePermission['PAYCALRULE'] = "ng.screen.pay.cal.rule";
		rolePermission['PFSTATEMENT'] = "ng.screen.pfstatement.report";

		rolePermission['PFDOWNLOAD'] = "ng.screen.pf.download";
		rolePermission['PFDOWNLOADREPORT'] = "ng.screen.pf.downloadReport";
		rolePermission['STATUTORYREPORT'] = "ng.screen.st.statutoryReport";
		rolePermission['ITSLABMASTER'] = "ng.screen.salary.itslabmaster";
		rolePermission['EPFABSTRACTREPORT'] = "ng.screen.epf.abstract.report";
		rolePermission['TDSREPORT'] = 'ng.screen.tds.report';
		rolePermission['ESISTATEMENTDOWNLOAD'] = "ng.screen.esi.statementDownload";
		rolePermission['SAVINGSMASTER'] = "ng.screen.employee.savingsmaster";

		// Over time 
		rolePermission['APPLYOVERTIME'] = "ng.screen.employee.overtime";
		rolePermission['APPROVEOVERTIME'] = "ng.screen.employee.approve.overtime";
		rolePermission['INCOMETAXCALCULATOR'] = "ng.screen.income.tax.calculator";

		rolePermission['PAYROLLMENU'] = "ng.screen.payroll.menu";
		rolePermission['PAYROLLREPORTMENU'] = "ng.screen.payrollreport.menu";
		rolePermission['PFDOWNLOADMENU'] = "ng.screen.pf.download";
		rolePermission['INCOMETAXMENU'] = "ng.screen.incometax.menu";
		rolePermission['POLICYMENU'] = "ng.screen.policy.menu";
		rolePermission['EVENTCALENDARMASTER'] = "ng.screen.eventcalendar";
		rolePermission['EVENTCALENDAR'] = "ng.screen.eventcalendareport";


		//Apply resignation
		rolePermission['APPLYRESIGNATION'] = "ng.screen.employee.resignation";
		//Approve resignation
		rolePermission['APPROVERESIGNATION'] = "ng.screen.employee.approve.resignation"

		rolePermission['SITEASSIGNMENT'] = "ng.screen.site.assignment";
		rolePermission['SITEDEFINITION'] = "ng.screen.site.definition";
		rolePermission['SITEREPORT'] = "ng.screen.site.report";

		rolePermission['APPROVEPAYROLL'] = "ng.screen.approve.payroll";



		return rolePermission;


	}

}
