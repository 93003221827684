import { Component, OnInit } from '@angular/core';
import { EmployeeLeaveService } from '../services/employee-leave.service';
import { CommonService } from '../services/common.service';
import { routeMaster, taskDetail } from '../models/routeMaster';
declare var $: any;

@Component({
  selector: 'app-route-definition',
  templateUrl: './route-definition.component.html',
  styleUrls: ['./route-definition.component.css']
})
export class RouteDefinitionComponent implements OnInit {

  routeName: any;
  addOrUpdate: any = true;
  add_view_toggle: any = 'viewRoute';
  query: any;
  employeeMasterLucene: any = [];
  routeDetail: routeMaster[];
  route: any;
  employeeid: any;
  queryName: any;
  info: any = null;
  siteList: any;
  routeid: any;
  fromDate: any;
  flagTocheckRouteinUse: Boolean = false;
  limitPara: number;
  offsetPara: number;
  taskid: any;

  editedRows: { [key: number]: any } = {}; // Track edited rows


  constructor(private empLeaveService: EmployeeLeaveService, private commonService: CommonService) {
    this.fromDate = new Date().toISOString().split('T')[0];
  }

  ngOnInit(): void {
    this.limitPara = 13;
    this.offsetPara = 0;
    this.viewRouteButton();
  }

  // lucene search 
  getEmployeeByNameOrId(info) {
    console.log('check info ', info);

    const [name, id] = this.query.split(' / ');
    this.queryName = name;
    this.employeeid = id; // Store the ID if it's available

    if (this.queryName.length <= 2) {
      this.employeeMasterLucene = [];
    }

    if (this.queryName.length >= 3) {
      this.empLeaveService.getEmployeeByNameOrUniqueId(this.queryName).subscribe(
        data => {
          console.log("Emp Data ", data.employeeMasterData);
          this.employeeMasterLucene = data.employeeMasterData;
        },
        error => {
          console.log("Error ", error);
        }
      );
    }

    for (let index = 0; index < this.siteList.length; index++) {
      this.trackChange(index, 'employeeName', this.queryName);
      this.trackChange(index, 'employeeid', this.employeeid);
    }


  }

  editRoute(info) {
    console.log("edit route ", info)
    this.add_view_toggle = 'addRoute';
    this.addOrUpdate = false;
    this.siteList = [];

    if (!this.siteList || !this.siteList[0]) {
      this.siteList = [{}];
    }

    // title , employeeName , description , dueDate
    this.routeName = info.routeid;
    this.siteList[0].title = info.title;
    this.siteList[0].employeeName = info.employeeName;
    this.siteList[0].employeeid = info.employeeid;
    this.siteList[0].description = info.description;
    this.siteList[0].dueDate = info.duedate;
    this.siteList[0].id = info.id;
    this.siteList[0].routeid = info.routeid;
    console.log("this sitelist ", this.siteList);

  }

  onDeleteHandler(info) {
    console.log("delete route ", info);
    $('#showAlertVer').modal('toggle');
    this.taskid = info;
  }

  addRouteButton() {
    this.add_view_toggle = 'addRoute';
    this.addOrUpdate = true;
  }

  viewRouteButton() {
    this.add_view_toggle = 'viewRoute';
    this.addOrUpdate = true;
    this.clear();
    this.getTask();
    this.getRoute();
  }

  onScroll() {
    this.offsetPara = this.offsetPara + 13;
    console.log("limit = ? ", this.limitPara, "offset = +13 ", this.offsetPara)
    this.getTask();
  }

  getTask() {

    const selectedEmpId = 0;
    this.commonService.getTaskForRoute(this.limitPara, this.offsetPara).subscribe(
      data => {

        if (this.offsetPara == 0) {
          this.routeDetail = data.taskMaster;
        } else {
          this.routeDetail = this.routeDetail.concat(data.taskMaster);
        }
      }, error => {
        this.routeDetail = [];
      }
    );

  }

  getRoute() {
    console.log("get Route ");
    this.commonService.getRoute().subscribe(
      data => {
        this.route = data.routeMaster;
      }, error => {
        this.route = [];
      }
    );
  }

  onRouteSelection() {
    this.addOrUpdate = true;
    this.siteList = [];
    console.log("on route changes ", this.routeName);
    if (this.routeName != '' || this.routeName != undefined) {

      this.siteList = this.route.filter((data) => data.id == this.routeName);
      this.flagTocheckRouteinUse = this.siteList[0]?.routeAssigned;

      if (this.siteList.length > 0 && this.siteList[0].taskmaster) {
        this.siteList = this.siteList[0].taskmaster;
        console.log("site list ", this.siteList, " ", this.flagTocheckRouteinUse);
      } else {
        console.warn("Taskmaster property not found.");
      }
    } else {
      this.siteList = [];
    }

  }

  trackChange(index: number, field: string, value: string) {
    if (!this.editedRows[index]) {
      this.editedRows[index] = { ...this.siteList[index] }; // Copy original row data
    }
    this.editedRows[index][field] = value; // Update changed field
  }

  closeReassignRoute() {
    $('#showAlertForReassignTask').modal('toggle');
  }

  reassignRoute() {
    $('#showAlertForReassignTask').modal('toggle');
    this.flagTocheckRouteinUse = false;
    this.saveChanges();
  }

  saveChanges() {
    console.log("clicked save ", this.siteList);

    if (this.flagTocheckRouteinUse) {
      $('#showAlertForReassignTask').modal('toggle');
      return;
    }


    for (let index = 0; index < this.siteList.length; index++) {
      if (this.editedRows[index]) {
        console.log('edited changes:', this.editedRows[index]);
        // Update siteList with the new due date
        this.siteList[index] = this.editedRows[index];
        // Now we don't need to track this row separately
        delete this.editedRows[index];
      }
    }

    for (let index = 0; index < this.siteList.length; index++) {
      if (this.siteList[index].employeeName == undefined || this.siteList[index].employeeName == null || this.siteList[index].employeeName == '') {
        this.alertmessages('Please Enter Name', 'danger');
        return
      }

      if (this.siteList[index].duedate == undefined || this.siteList[index].duedate == null || this.siteList[index].duedate == '') {
        this.siteList[index].duedate = this.fromDate;
      }
    }

    console.log("pass to api ", this.siteList);

    this.commonService.insertTaskAssignmentForRoute(this.siteList).subscribe(
      data => {
        this.alertmessages("Successfully Saved ", "success");
        this.clear();
        this.viewRouteButton();
      }, error => {
        this.alertmessages("Try Again Later", "danger");
      }
    );


  }

  getEmployeeByNameOrIdTable(index, name) {
    console.log("check info  index ", index, " name ", name);

    let [employeename, employeeid] = name.split(' / ');
    name = employeename;

    if (name.length <= 2) {
      this.employeeMasterLucene = [];
    }

    if (name.length >= 3) {
      this.empLeaveService.getEmployeeByNameOrUniqueId(name).subscribe(
        data => {
          console.log("Emp Data ", data.employeeMasterData);
          this.employeeMasterLucene = data.employeeMasterData;
        },
        error => {
          console.log("Error ", error);
        }
      );
    }

    if (employeename == null || employeename == undefined || employeename == '') {
      employeename = this.queryName
      employeeid = this.employeeid
    }
    console.log("employee name ", employeename);

    this.trackChange(index, 'employeeName', employeename);
    this.trackChange(index, 'employeeid', employeeid);
  }

  clear() {
    this.employeeMasterLucene = [];
    this.siteList = [];
    this.routeDetail = [];
    this.query = '';
    this.queryName = '';
    this.editedRows = {};
    this.routeid = '';
    this.routeName = '';
    this.limitPara = 13;
    this.offsetPara = 0;
  }

  // deleteRoute() {
  //   if (this.routeid != '' || this.routeid != undefined) {
  //     this.commonService.deleteRoute(this.routeid).subscribe(
  //       (data) => {
  //         this.getRoute();
  //         $('#showAlertVer').modal('toggle');
  //         this.alertmessages(data.successMessages[0]['key'], "success")
  //       }, (error) => {
  //         console.log(error)
  //         $('#showAlertVer').modal('toggle');
  //         this.alertmessages("Route cannot be deleted as it's in use", "danger");
  //       }
  //     );
  //   }
  // }

  deleteTask() {
    this.taskid;
    console.log("deleteId", this.taskid);
    this.commonService.deleteTaskAssigned(this.taskid).subscribe(
      (data) => {
        this.getTask();
        $('#showAlertVer').modal('toggle');
        this.alertmessages(data.successMessages[0]['key'], "success")
      }, (error) => {
        console.log(error)
        $('#showAlertVer').modal('toggle');
        this.alertmessages("Task cannot be deleted as it's in use", "danger");
      }
    );

  }

  closeRoute() {
    $('#showEdit').modal('toggle');
  }

  closeDelRoute() {
    $('#showAlertVer').modal('toggle');
  }

  alertmessages(message: string, sign: string): void {
    let alertDiv = document.createElement('div');
    alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
    <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message} </div>`
    let alertcomp = document.querySelector('.alertcomp');
    alertcomp.append(alertDiv);
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    setTimeout(function () {
      alertcomp.removeChild(alertDiv);
    }, 3000);
  }

  UpdateRoute() {

    const obj = {
      routename: this.routeName,
      id: this.routeid
    }

    if (obj.routename == undefined || obj.routename == null || obj.routename == '') {
      this.alertmessages('Please Enter Route Name', 'danger');
      return
    }

    this.commonService.updateRouteMaster(obj).subscribe(
      data => {
        this.closeRoute();
        this.alertmessages("Successfully Updated ", "success");
        this.clear();
        this.getRoute();
      }, error => {
        this.alertmessages("Try Again Later", "danger");
      }
    );

  }

  updateRouteAssignment() {

    for (let index = 0; index < this.siteList.length; index++) {
      if (this.editedRows[index]) {
        console.log('edited changes:', this.editedRows[index]);
        // Update siteList with the new due date
        this.siteList[index] = this.editedRows[index];
        // Now we don't need to track this row separately
        delete this.editedRows[index];
      }
    }

    for (let index = 0; index < this.siteList.length; index++) {
      if (this.siteList[index].employeeName == undefined || this.siteList[index].employeeName == null || this.siteList[index].employeeName == '') {
        this.alertmessages('Please Enter Name', 'danger');
        return
      }

      if (this.siteList[index].duedate == undefined || this.siteList[index].duedate == null || this.siteList[index].duedate == '') {
        this.siteList[index].duedate = this.fromDate;
      }
    }

    console.log("pass to api ", this.siteList);

    this.commonService.updateTaskAssignmentForRoute(this.siteList).subscribe(
      data => {
        this.alertmessages("Successfully Saved ", "success");
        this.clear();
        this.viewRouteButton();
      }, error => {
        this.alertmessages("Try Again Later", "danger");
      }
    );

  }


}
