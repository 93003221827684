<div class="app-content main-content">
    <div class="side-app main-container">

        <div class="row">
            <div class="col-xl-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <form id="fcForm" class="form-horizontal form-label-left">
                            <div class="x_content row px-4">
                                <div class="col-md-4 col-sm-4 col-xs-4">
                                    <div class="form-group label-floating">
                                        <label class="form-label"> From Date: </label>
                                        <div>
                                            <input class="form-control" type="date" name="fromdate" id="fromdate"
                                                [(ngModel)]="fromDate">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-4 col-xs-4">
                                    <div class="form-group label-floating">
                                        <label class="form-label"> To Date: </label>
                                        <div>
                                            <input class="form-control" type="date" name="todate" id="todate"
                                                [(ngModel)]="toDate">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-4 col-xs-4">
                                    <div class="form-group label-floating">
                                        <label class="form-label">Select Status </label>
                                        <div>
                                            <div>
                                                <select id="leaveStatusFilters" class="form-control"
                                                    [(ngModel)]="taskStatus" name="dateFilter">
                                                    <option value="All">All</option>
                                                    <option value="Pending">Pending</option>
                                                    <option value="Assigned">Assigned</option>
                                                    <option value="Close">Close</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-12 col-sm-12 col-xs-12 " style="padding: 0px 0px">
                                    <form class="form-horizontal" id="fcForm">
                                        <div class="form-group row">
                                            <div class="col-md-4 col-sm-4 col-xs-4">
                                                <div class="form-group label-floating">
                                                    <label for="employeename" class="form-label">Employee Name / ID
                                                    </label>
                                                    <div class="employeeSearch col-xs-12 col-md-12 px-0">
                                                        <div>
                                                            <input type="search" class="form-control" name="employeename" id="employeename" [(ngModel)]="query"
                                                                autocomplete="off" (input)="getEmployeeByNameOrId()" list="employeeMaster" autofocus="autofocus"
                                                                placeholder="Search Employee Name / ID">
                                                            <datalist id="employeeMaster">
                                                                <option [value]="emp.name + ' / ' + emp.employeeid" *ngFor="let emp of employeeMasterLucene">
                                                                    <!-- {{emp.employeeid}} uniqueemployeeid-->
                                                                </option>
                                                            </datalist>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        
                                            <!-- Apply For Dropdown -->
                                            <div class="form-group col-md-4 col-sm-4 col-xs-4 px-2">
                                                <label class="form-label">Employee Status</label>
                                                <select class="form-control" type="text" style="width: 100%;" required [(ngModel)]="selectedValue" name="selVal"
                                                    (change)="onStatusChange()">
                                                    <option value="All">All</option>
                                                    <option value="Active">Active</option>
                                                    <option value="Inactive">Inactive</option>
                                                </select>
                                            </div>
                                        
                                            <!-- Search Button -->
                                            <div class="col-4 mt-5">
                                                <label>&nbsp;</label>
                                                <button type="button" id="navigateclient" class="btn btn-primary w-45" style="margin-right: 1vw;"
                                                    (click)="limitpara();search()">
                                                    <i class="fa fa-search p-0" aria-hidden="true"></i> Search
                                                </button>
                                                <button type="button" class="btn btn-danger w-45" (click)="clear()">Clear</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>


        <div class="empty-div-one" id="add-update-client"></div>
        <div class="row">
            <div class="col-xl-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="col-md-12 col-sm-12 col-xs-12">
                            <div class="col-md-12 col-sm-12 col-xs-12">
                                <div class="card-header border-bottom-0" style="margin-top:-12px;">
                                    <h3 class="card-title">Site List</h3>
                                </div>
                                <div class="card-body card1S p-0">
                                    <div class="table-responsive"
                                        style="min-height: fit-content;max-height: 40vh ; overflow: scroll;width: 100%;">
                                        <div class="scrollsearchresults" id="scroll" infiniteScroll
                                            [infiniteScrollDistance]="2" [infiniteScrollUpDistance]="1.5"
                                            [infiniteScrollThrottle]="100" [scrollWindow]="false"
                                            (scrolled)="onScroll()" id="scrolllength">
                                            <table
                                                class="table table-hover table-vcenter text-nowrap table-bordered border-bottom"
                                                id="hr-table">
                                                <thead class="tablerow">
                                                    <tr class="rowcolors stickyatTop">
                                                        <th class="border-bottom-0 w-5 fs-6 ">Empl ID</th>
                                                        <th class="border-bottom-0 w-5 fs-6 ">Assigned To</th>
                                                        <th class="border-bottom-0 w-5 fs-6 ">Site Name
                                                        </th>
                                                        <th class="border-bottom-0 w-5 fs-6 ">Description
                                                        </th>
                                                        <th class="border-bottom-0 w-5 fs-6 ">Comment
                                                        </th>
                                                        <!-- <th class="border-bottom-0 w-5 fs-6 ">Assigned Date</th> -->
                                                        <th class="border-bottom-0 w-5 fs-6 ">Due Date</th>
                                                        <th class="border-bottom-0 w-5 fs-6 ">Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <!-- style="max-height: 75vh; overflow: scroll;" -->
                                                    <tr *ngFor="let td of taskMasterDetail; let i = index">

                                                        <td>{{td.employeeid}}</td>
                                                        <td>{{td.employeeName}}</td>
                                                        <td style="min-width: 10vw;max-width: 20vw;text-wrap: wrap;">
                                                            {{td.title}}
                                                        </td>
                                                        <td style="min-width: 12vw;max-width: 10vw;text-wrap: wrap;">
                                                            {{td.description == undefined || null ? '' :
                                                            td.description}}</td>
                                                        <td style="min-width:6vw;max-width: 12vw;text-wrap: wrap;">
                                                            {{td.comment == undefined || null ? '' :
                                                            td.comment}}</td>
                                                        <!-- <td>{{td.assignedTime | date:'mediumDate' }}</td> -->
                                                        <td>{{td.duedate | date:'mediumDate' }}</td>
                                                        <td>
                                                            <span style="font-size: 13px; font-weight: 500;" [ngClass]="
                                                                td.status === 'Pending' ? 'bg-custom-warning text-custom-dark' :
                                                                td.status === 'Close' ? 'bg-custom-red text-custom-dark' :
                                                                td.status === 'Assigned' ? 'bg-custom-green text-custom-dark' :
                                                                'bg-custom-warning text-custom-dark'
                                                              " class="badge">
                                                                {{ td.status }}
                                                            </span>
                                                        </td>

                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>