import { Component, OnInit, Renderer2, OnChanges, SimpleChanges } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { NoticeboardService } from '../services/noticeboard.service';
import { DocumentInfoService } from '../services/documentinfo.service';
import { AppService } from '../services';
import { Menu } from '../services/menu.Service';
declare var $: any;


@Component({
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.css'],
    selector: 'navbar'
})



export class NavbarComponent implements OnInit, OnChanges {
    MeId: any;
    showMenu: any;
    isloggedIn: boolean = false;
    loggedUsername: any;
    badgeLength: number;
    filteredNotifications: any[];
    messages: string[] = [];

    isMessagesViewed: boolean = false;
    isClicked: boolean = false;

    limitPara: any;
    offsetPara: any;

    notifiacationTable: NavbarComponent[] = [];
    readStatus: number;

    listOfRuleAndPolicy = [];
    urla: any;
    currentMenuName:string;
    urlsub = this.appService.getprofilesub()

    constructor(private router: Router, private render: Renderer2, private noticeService: NoticeboardService, private docmentInfoService: DocumentInfoService, private appService: AppService, private menuService: Menu) {
        this.generateMessages();
    }
    ngOnChanges(changes: SimpleChanges): void {
        this.viewprofilepic(sessionStorage.getItem('username'))
    }
    generateMessages() {
    }



    innerwidtH: number;
    ngOnInit() {
        this.limitPara = 10;
        this.offsetPara = 0;

        this.Notificationlist();

        setInterval(() => {
            this.Notificationlist();
            console.log("")
            console.log("interval to recall it every 5 min once NotifiationList 2 ")
            console.log("")
        }, 180000)


        this.innerwidtH = window.innerWidth;

        this.router.events.subscribe((value) => {
            if (value instanceof NavigationEnd) {
                if (value.url == '/login' || value.url == '/login/' || value.url == '/' || value.url == '/signup' || value.url == '/companyselection') {
                    this.isloggedIn = false;
                }
                else {
                    this.isloggedIn = true;
                    //today added
                    this.loggedUsername = sessionStorage.getItem('displayUser');
                    // console.log(sessionStorage.getItem('username'));

                    //new code for showing or hiding menu
                    if (sessionStorage.getItem('role') == "Employee")
                        //this.MeId = 1;
                        this.MeId = 0;
                    else
                        this.MeId = 0;
                }
            }
        });

        if (localStorage.getItem('mode') === "dark") {
            const darklayouticon = document.getElementById('moon')
            const bodyElement = document.getElementById('bodytag')
            bodyElement.classList.add('dark-mode');
            if (darklayouticon) {
                darklayouticon.style.display = "block";
            }
        }

        this.viewprofilepic(sessionStorage.getItem('username'))

        this.menuService.currentMenuName.subscribe(
            (name) => (this.currentMenuName = name)
          );
    }


    toggleSideMenu() {
        var element1 = document.getElementById("sideNavBar");
        var element2 = document.getElementById("overlayer");
        if (element1.style.width === "0px" || element1.style.width === "") {
            element1.style.width = "260px";
            element2.style.display = "block"
        } else {
            element1.style.width = "0px";
            element2.style.display = "none"
        }
    }

    changeView() {
        var element = document.getElementById("proApp");
        if (element.className === 'nav-md') {
            element.className = 'nav-sm';
        } else {
            element.className = 'nav-md';
        }
    }



    logout() {
        this.clearMenuName()
        console.log("Session clear >>> ")
        this.filteredNotifications = null;
        sessionStorage.clear();
        // this below code removes datepicker element 
        let datepickerelements = document.querySelectorAll('.daterangepicker');
        datepickerelements.forEach(ele => {
            ele.remove();
        })
        this.appService.setprofile(null);
        this.router.navigate(['/login']);
    }

    clearMenuName() {
        localStorage.removeItem('currentMenuName');
        this.menuService.setMenuName('Dashboard');
        localStorage.setItem('currentMenuName', 'Dashboard'); 
    }

    login() {
        window.location.reload();

    }

    togglelogo() {
        const logo = document.querySelector('.iden');
        if (logo.classList.contains('d-none')) {
            logo.classList.remove('d-none')
            const ele = document.querySelector('.s');
            ele.classList.add('d-none')

        } else {
            const ele = document.querySelector('.s');
            ele.classList.remove('d-none')
            // document.querySelector('.s').classList.remove('d-none');
            logo.classList.add('d-none');
        }
    }

    // toggle of sidebar
    addnavbarShow() {
        // console.log("Close/menu button clicked");
        // this.togglelogo()
        const sidebar = document.getElementById('appSidebar3');
        const overlayMainComponent = document.getElementById('overlayMainComponent');

        const menu_icon_one = document.getElementById('menu_icon_one');
        // menu_icon_one.classList.remove('feather-menu');  
        // menu_icon_one.classList.add('feather-x');
        // logo change based on toggle
        // const logo_element =document.getElementById('Scalelogo')
        // if(logo_element.classList.contains('d-none')){
        //     const imagele = document.getElementById('companyname');
        //     logo_element.classList.remove('d-none')
        //     imagele.classList.add('d-none')
        // }else{
        //     const imagele = document.getElementById('companyname');
        //     logo_element.classList.add('d-none')
        //     imagele.classList.remove('d-none')
        // }



        if (window.innerWidth <= 1800) {
            sidebar.style.overflow = "auto"
        }
        const body1 = document.getElementById('bodytag');
        if (window.innerWidth > 992) {
            // console.log("Now the max width is > 992px");
            const ele = document.getElementById('overlayMainComponent')
            if (!body1.classList.contains('sidenav-toggled')) {
                // console.log("inside navbar function")
                ele.style.display = "none"
            }
        }
        const body = document.querySelector('body');
        if (body.classList.contains('sidenav-toggled1')) {
            body.classList.remove('sidenav-toggled1')
        }
        if (overlayMainComponent.style.display === "block") {
            overlayMainComponent.style.display = "none";
        }
        const elementispresent = document.querySelector('.main-navbar-backdrop');
        if (elementispresent) {
            body.removeChild(elementispresent);
            const ele = document.querySelector('.app-sidebar__toggle');
            const newELement = document.createElement('div');
            body.classList.add('main-navbar-show');
            newELement.classList.add('main-navbar-backdrop');
            body.append(newELement)

            if (body.contains(newELement)) {

                newELement.addEventListener('click', () => {
                    //   console.log("backdrop is present")
                    body.classList.remove('main-navbar-show');
                    body.append(newELement)
                    body.classList.remove('sidenav-toggled')


                })
            }

        } else {

            const ele = document.querySelector('.app-sidebar__toggle');
            const newELement = document.createElement('div');
            body.classList.add('main-navbar-show');
            newELement.classList.add('main-navbar-backdrop');
            body.append(newELement)

            if (body.contains(newELement)) {

                newELement.addEventListener('click', () => {
                    body.classList.remove('main-navbar-show');
                    body.append(newELement)
                    body.classList.remove('sidenav-toggled')

                })
            }
        }

        // if(window.innerWidth >=601){
        //   if(body.classList.contains('sidenav-toggled')){
        //     sidebar.style.overflow = "scroll"
        //   }else{
        //     sidebar.style.overflow = "hidden"
        //   }
        // }
    }

    fullscreenMode(e) {
        var ele = document.documentElement;

        if (ele.requestFullscreen) {
            ele.requestFullscreen();
            // console.log('request full screen')
        }
        if (document.fullscreenElement) {
            document.exitFullscreen();
        }
        //  if(document.exitFullscreen) {
        //     document.exitFullscreen();
        //     console.log('request close screen')
        // }

    }

    darkLightToggle(e) {
        const bodyElement = document.getElementById('bodytag')
        const darklayouticon = document.getElementById('moon')
        const lightlayouticon = document.getElementById('light')
        if (bodyElement.classList.contains('dark-mode')) {
            bodyElement.classList.remove('dark-mode');
            darklayouticon.style.display = "block";
            localStorage.setItem('mode', "light");
            lightlayouticon.style.display = "none"
        } else {
            bodyElement.classList.add('dark-mode');
            darklayouticon.style.display = "none";
            lightlayouticon.style.display = "block"
            localStorage.setItem('mode', "dark");

        }
    }

    Notificationlist() {
        console.log(" notofication list>>>")

        console.log("page limit para :", this.limitPara, "page limit offset :", this.offsetPara);
        this.noticeService.notifiacationTable(this.limitPara, this.offsetPara).subscribe(
            (response) => {
                console.log(response);
                // this.notifiacationTable = response.notificationListAwake;
                // this.Notificationlist();

                if (this.offsetPara == 0) {
                    this.notifiacationTable = response.notificationListAwake;
                } else {
                    this.notifiacationTable = this.notifiacationTable.concat(response.notificationListAwake);
                }


                this.countUnreadMsg();
                this.onViewMessages();
            },
            (error) => {
                console.log(error);
            }
        );

    }

    countUnreadMsg() {
        console.log("count unread Message")
        this.filteredNotifications = this.notifiacationTable.filter(item => item.readStatus === 0);
    }


    onViewMessages() {
        console.log("on View Message")
        if (this.filteredNotifications?.length != 0) {
            this.badgeLength = this.filteredNotifications?.length;
            this.isMessagesViewed = false;
        } else {
            this.badgeLength = 0;
            this.isMessagesViewed = true;
        }
    }


    updateNotification(notificationid: number, readstatus: any, i: number) {
        console.log("update Noifiaction ")
        if (readstatus === 1) {
            return;
        }
        console.log("Read Now >>>");
        this.noticeService.readNotification(notificationid).subscribe(
            (response) => {
                this.notifiacationTable[i].readStatus = 1;
                this.countUnreadMsg()
                this.onViewMessages()
                console.log("Notification updated:", this.notifiacationTable[i])
            },
            (error) => {
                console.log(error);
            }
        );
    }



    onScroll() {
        console.log("onscroll scrolled")
        this.offsetPara = this.offsetPara + 10;
        this.Notificationlist();

    }

    viewprofilepic(username: any) {
        console.log("iiiiiiiiiiiiiiii", username)
        // console.log("Path for profile photo", filePath);
        this.urla = undefined;
        // console.log("iiiiiiiiiiiiiiii", Imagename)
        this.appService.getRoleOfLoggedUser(username).subscribe(
            data => {

                console.log("profile data", data);
                console.log("profile photo", data.user.profilebyte);
                // var downloadUrl = URL.createObjectURL(data.user.profilepath);
                // console.log("Data might be this :", downloadUrl)
                // console.log()
                console.log("Profile Path:", data.user.profilepath);
                if (data.user.profilePath !== "undefined") {
                    this.appService.viewProfilePic(data.user.profilepath).subscribe(
                        data => {
                            console.log("profile paath", data)
                            // var downloadUrl = URL.createObjectURL(data);
                            // if (data.size === 0) {
                            //     this.urla = "../../assets/img/user-3296.svg"
                            // } else {
                            var reader = new FileReader();
                            reader.readAsDataURL(data);
                            reader.onload = (_event) => {

                                this.urla = reader.result;
                                // console.log(this.url)
                            }

                        },
                        error => {
                            console.log('Error occured in download file');

                        }
                    );
                } else {
                    console.log("Gambare Gambare");
                    this.urla = '../../assets/img/user-3296.svg';
                }





            },
            error => {
                console.log('Error occured in download file');

            }
        );

    }


    setMenuName(name: string) {
        this.menuService.setMenuName(name);
      }

}
