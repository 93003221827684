<div class="page">
  <div class="page-main">
    <sidebar></sidebar>
    <navbar></navbar>
    <router-outlet></router-outlet>
  </div>
</div>



<div class="modal fade" id="pwaPrompt" tabindex="-1" aria-labelledby="Do you want to add Paytime plus to your home screen"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content needs-validation">
      <div class="modal-body text-center">
        <svg xmlns="http://www.w3.org/2000/svg" width="45px" height="45px" fill="#198754" viewBox="0 0 16 16">
          <path fill-rule="evenodd"
            d="M8.354.146a.5.5 0 0 0-.708 0l-7 7a.5.5 0 1 0 .708.708L8 1.707l6.646 6.647a.5.5 0 1 0 .708-.708l-7-7z" />
          <path fill-rule="evenodd"
            d="M12 7.5a.5.5 0 0 1 .5.5v6.5a.5.5 0 0 1-.5.5h-3v-4H7v4H4a.5.5 0 0 1-.5-.5V8a.5.5 0 0 1 .5-.5h8z" />
          <path
            d="M13.5 2a.5.5 0 0 1 .5.5V4h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V5h-1.5a.5.5 0 0 1 0-1H13V2.5a.5.5 0 0 1 .5-.5z" />
        </svg>
        <p class="my-2">Do you want to add Paytime plus to your home screen?</p>
      </div>

      <div class="modal-footer d-flex align-items-center justify-content-center">
        <button type="button" class="btn_primary__rounded" data-bs-dismiss="modal"
          (click)="promptInstall()"><span>Yes</span></button>
        <button type="button" class="btn_secondary__rounded" data-bs-dismiss="modal"><span>No</span></button>
      </div>
    </div>
  </div>
</div>