<div class="app-content main-content">
    <div class="side-app main-container">
        <div class="alertcomp">

        </div>
        <!--Page header-->
        <!-- <div class="page-header d-xl-flex d-block">
            <div class="page-leftheader">
                <div class="page-title">Apply Leave For Self</div>
            </div>
        </div> -->
        <!--End Page header-->

        <!-- Row -->
        <div class="row">
            <div class="col-xl-4 col-md-12 col-lg-4">
                <div class="card" style="margin-bottom:10px">
                    <form #leaveForm="ngForm" (ngSubmit)="onSubmit()">
                        <div class="card-header  border-0">
                            <h4 class="card-title">Apply Leave</h4>
                        </div>
                        <div class="card-body">

                            <div class="leave-types">
                                <div class="form-group">
                                    <label class="form-label">Apply for</label>
                                    <select required (ngModelChange)="onChangeDays($event)"
                                        [ngModel]="empLeaveInfo.halfFullIndic" name="leave"
                                        class="form-control custom-select select2" id="daterange-categories">
                                        <option label="Select" required></option>
                                        <option [value]="leaveDate.halfFullIndic"
                                            *ngFor="let leaveDate of leaveDatesList">{{leaveDate.leaveDay}}</option>
                                    </select>
                                </div>
                                <div class="leave-content active" id="single">
                                    <div class="form-group">
                                        <label class="form-label">Date Range:</label>
                                        <div class="input-group">
                                            <input [disabled]="true" required type="text" id="singledate"
                                                name="singledaterange" class="form-control"
                                                placeholder="select dates" />
                                            <div class="input-group-append">
                                                <div class="input-group-text">
                                                    <i class="bx bx-calendar"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="leave-content" id="multiple">
                                    <div class="form-group">
                                        <label class="form-label">Date Range:</label>
                                        <div class="input-group" (click)="addleftstyle()">
                                            <input [disabled]="true" required type="text" id="multipledate"
                                                name="daterange" class="form-control" placeholder="select dates"
                                                (focus)="addleftstyle()" />
                                            <div class="input-group-append">
                                                <div class="input-group-text">
                                                    <i class="bx bx-calendar"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="form-label">Leave Type</label>
                                    <select required [ngModel]="empLeaveInfo.leaveType"
                                        (ngModelChange)="onChangeLeaveTypes($event)" name="projects"
                                        class="form-control custom-select select2" id="leavetypes">
                                        <option label="Select" required></option>
                                        <option [ngValue]="leave.leaveType" *ngFor="let leave of leaveTypesList">
                                            {{leave.leaveName}}</option>
                                    </select>
                                    <div class="d-flex justify-content-between" >
                                        <div style="padding-top: 5px; padding-bottom: 5px ; padding-right: 10px;"
                                            id="leavetypesc"
                                            [style.display]="i.leaveType === empLeaveInfo.leaveType ? 'block':'none '"
                                            *ngFor="let i of leaveTypesList" class="font-weight-semibold">
                                            
                                            <!-- {{i.leaveName }}:

                                            <span
                                                class="badge badge-danger badge-pill ms-2">{{leaveBalance_Count}}</span> -->
                                                <ng-container *ngIf="i.leaveType !== 'ML'">  <!-- Excludes ML -->
                                                    {{ i.leaveName }}:
                                                    <span class="badge badge-danger badge-pill ms-2">{{ leaveBalance_Count }}</span>
                                                </ng-container>
                                        </div>
                                        <div style="padding-top :5px">
                                            <label class="mb-0 font-weight-semibold text-dark">Selected Days:</label>
                                            <span class="badge badge-danger badge-pill ms-2">{{totalLeaveCount}}</span>
                                        </div>
                                    </div>

                                </div>
                                <div class="form-group">
                                    <div class="form-group label-floating">
                                      <label for="file" class="form-label">Attachment(Optional)</label>
                                      <input 
                                        type="file" 
                                        id="file" 
                                        class="form-control custom-file-input"
                                        [(ngModel)]="selectedfile" #filename="ngModel" 
                                        (change)="onFileSelected($event)"
                                      />
                                    </div>
                                  </div>
                                  
                                
                                <div class="form-group">
                                    <label class="form-label">Reason:</label>
                                    <textarea required id="reason" [(ngModel)]="empLeaveInfo.reason"
                                        class="form-control" rows="2"></textarea>
                                </div>
                            </div>
                            <div class="d-flex">
                                <button [disabled]="!empLeaveInfo.reason.length" class="btn btn-success"
                                    type="submit">Submit</button>
                                <button type="button" (click)="onClear()" class="btn btn-danger ms-2">Clear</button>
    
                
                           </div>

                        </div>
                    </form>
                </div>
            </div>

            <div class="col-xl-4 col-lg-4 col-md-12">
                <div class="card">
                    <div class="card-header border-0">
                        <h4 class="card-title">Calendar</h4>
                    </div>
                    <div class="card-body">
                        <app-cust-calender></app-cust-calender>
                    </div>
                </div>
            </div>

            <div *ngIf="employeeLeaveName.length" class="col-xl-4 col-md-12 col-lg-4 ">
                <div class="card">
                    <div class="card-header  border-0">
                        <h4 class="card-title">Leave Overview</h4>
                    </div>
                    <div class="card-body">
                        <div style="display: flex;justify-content: center;align-items: center; ">
                            <apx-chart *ngIf="showChart" [series]="chartSeries" [chart]="chartDetails"
                                [labels]="chartLabels" [title]="chartTitle" [dataLabels]="chartDataLabels"
                                [legend]="chartLegend" [plotOptions]="plotOptions" [colors]="chartColor"></apx-chart>
                        </div>

                        <div class="row pt-4 mx-auto text-center mb-5">
                            <div class="col-lg-12 col-md-12 col-sm-12 mx-auto d-block">
                                <div class="row">
                                    <div class="col-6 mt-3" *ngFor="let leaveName of employeeLeaveName;let i = index">
                                        <div class="d-flex font-weight-semibold">
                                            <span [style.backgroundColor]="colorList[i]" id="{{leaveName}}"
                                                class="dot-label  me-2 my-auto"></span>{{leaveName}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <!-- End Row-->

        <!-- Row -->
        <div *ngIf="showTable" class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header  border-0">
                        <h4 class="card-title">Leave Summary</h4>

                        <div class="col-md-3 col-sm-4 col-xs-4 mt-0">
                            <select class="form-select"  style="width: 100%;" required
                                [(ngModel)]="selectedValue" name="selVal"  (change)="onTypeChange($event)">
                                <option value="Detailed">Detailed</option>
                                <option value="Consolidated">Consolidated</option>
                            </select>
                        </div>
                    </div>
                    <div class="card-body">
                        <form action="#">
                            <div class="table-responsive">
                                <div class="scrollsearchresults" id="scrolllength">
                                    <table *ngIf="selectedValue === 'Detailed'" class="table  table-vcenter text-nowrap table-bordered border-bottom"
                                        id="hr-leaves">
                                        <thead class="tablerow">
                                            <tr class="rowcolors stickyatTop">
                                                <th class="border-bottom-0 w-5">Employee ID</th>
                                                <th class="border-bottom-0 w-5 fixedcolumn">Name</th>
                                                <th class="border-bottom-0">Leave Type</th>
                                                <th class="border-bottom-0">Leave Date</th>
                                                <th class="border-bottom-0">Day</th>
                                                <th class="border-bottom-0">Reason</th>
                                                <th class="border-bottom-0">Applied on</th>
                                                <th class="border-bottom-0">Attachment</th>
                                                <th class="border-bottom-0">Status</th>
                                                <th class="border-bottom-0">Approved By</th>
                                                <th class="border-bottom-0">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody *ngIf="showTable">
                                            <tr *ngFor="let leave of employeeLeaveSummary">
                                                <td>{{leave.employeeId}}</td>
                                                <td class="fixedcolumn backgroundFW">
                                                    <div class="d-flex">
                                                        <div class="me-3 mt-0 mt-sm-2 d-block">
                                                            <h6 class="mb-1 fs-14">{{leave.employeeName}}</h6>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>{{leave.leaveName}}</td>
                                                <!-- <td>{{leave.leaveName}}</td> -->
                                                <td>{{leave.leaveDate| date: 'mediumDate'}}</td>
                                                <td class="font-weight-semibold">
                                                    {{leave.halfFullIndic === "F" ? "Full Day" : "Half Day"}}
                                                </td>
                                                <td>{{leave.reason}}</td>
                                                <td>{{leave.appliedDate| date: 'mediumDate'}}</td>
                                                <td>{{leave.fileName}}</td>
                                                <td>
                                                    <span style="font-size: 13px;font-weight: 500;"
                                                        [ngClass]="leave.leaveStatus === 'Approved' ? 'bg-custom-green text-custom-dark' : leave.leaveStatus === 'Partial Approve 1' ? 'bg-custom-orange1 text-custom-dark' : leave.leaveStatus === 'Partial Approve 2' ? 'bg-custom-orange2 text-custom-dark' :  leave.leaveStatus === 'Rejected' ? 'bg-custom-red text-custom-dark' : leave.leaveStatus === 'Leave Cancel Rejected' ? 'badge-danger':leave.leaveStatus === 'Leave Cancel Pending' ? 'badge-warning text-custom-dark' : 'bg-custom-warning text-custom-dark'"
                                                        class="badge">{{leave.leaveStatus}}</span>
                                                </td>
                                                
                                                <td>{{leave.approval }}</td>
                                                <td data-th="Action">
                                                    <button class="btn btn-primary btn-icon btn-sm me-2"
                                                    (click)="viewdocument(leave.fileName, leave.filePath)" style="width: 32px;">
                                                   <i class="fa fa-eye" data-bs-toggle="tooltip" data-original-title="download"></i>
                                               </button>

                                                    <button *ngIf="leave.leaveStatus !== 'Leave Cancel Rejected'"
                                                        type="button" (click)="onDeleteHandler(leave)"
                                                        class="btn btn-danger btn-icon btn-sm me-2" data-bs-toggle="tooltip"
                                                        data-bs-placement="top" title="Delete">
                                                        <i class="feather feather-trash-2 "></i>
                                                    </button>
                                                    
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>


                                    <table *ngIf="selectedValue === 'Consolidated'" class="table  table-vcenter text-nowrap table-bordered border-bottom"
                                        id="hr-leaves">
                                        <thead class="tablerow">
                                            <tr class="rowcolors stickyatTop">
                                                <th class="border-bottom-0">Employee ID</th>
                                                <th class="border-bottom-0 fixedcolumn">Name</th>
                                                <th class="border-bottom-0">Leave Type</th>
                                                <th class="border-bottom-0" >From Date</th>
                                                <th class="border-bottom-0">To Date</th>
                                                <th class="border-bottom-0" >No. Of Leaves</th>
                                                <th class="border-bottom-0">Day</th>
                                                <th class="border-bottom-0" >Reason</th>
                                                <th class="border-bottom-0">Applied On</th>
                                                <th class="border-bottom-0">Attachment</th>
                                                <th class="border-bottom-0">Status</th>
                                                <th class="border-bottom-0">Approved By</th>
                                                <th class="border-bottom-0">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody *ngIf="showTable">
                                            <tr *ngFor="let leave of employeeLeaveSummary">
                                                <td>{{leave.employeeId}}</td>
                                                <td class="fixedcolumn backgroundFW">
                                                    <div class="d-flex">
                                                        <div class="me-3 mt-0 mt-sm-2 d-block">
                                                            <h6 class="mb-1 fs-14">{{leave.employeeName}}</h6>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>{{leave.leaveName}}</td>
                                                <!-- <td>{{leave.leaveName}}</td> -->
                                                <!-- <td>{{leave.leaveDate| date: 'mediumDate'}}</td> -->
                                                <td>{{leave.leaveDate | date: 'mediumDate'}}</td>
                                                <td>{{leave.toDate | date: 'mediumDate'}}</td>
                                                <td>{{leave.noOfDaysLeaveApplied}}</td>

                                                <td class="font-weight-semibold">
                                                    {{leave.halfFullIndic === "F" ? "Full Day" : "Half Day"}}
                                                </td>
                                                <td>{{leave.reason}}</td>
                                                <td>{{leave.appliedDate| date: 'mediumDate'}}</td>
                                                <td>{{leave.fileName}}</td>
                                                <td>
                                                    <span style="font-size: 13px;font-weight: 500;"
                                                        [ngClass]="leave.leaveStatus === 'Approved' ? 'bg-custom-green text-custom-dark' : leave.leaveStatus === 'Partial Approve 1' ? 'bg-custom-orange1 text-custom-dark' : leave.leaveStatus === 'Partial Approve 2' ? 'bg-custom-orange2 text-custom-dark' :  leave.leaveStatus === 'Rejected' ? 'bg-custom-red text-custom-dark' : leave.leaveStatus === 'Leave Cancel Rejected' ? 'badge-danger':leave.leaveStatus === 'Leave Cancel Pending' ? 'badge-warning text-custom-dark' : 'bg-custom-warning text-custom-dark'"
                                                        class="badge">{{leave.leaveStatus}}</span>
                                                </td>
                                                
                                                <td>{{leave.approval }}</td>
                                                <td data-th="Action">
                                                    <button class="btn btn-primary btn-icon btn-sm me-2"
                                                    (click)="viewdocument(leave.fileName, leave.filePath)" style="width: 32px;">
                                                   <i class="fa fa-eye" data-bs-toggle="tooltip" data-original-title="download"></i>
                                               </button>

                                                    <button *ngIf="leave.leaveStatus !== 'Leave Cancel Rejected'"
                                                        type="button" (click)="onDeleteHandler(leave)"
                                                        class="btn btn-danger btn-icon btn-sm me-2" data-bs-toggle="tooltip"
                                                        data-bs-placement="top" title="Delete">
                                                        <i class="feather feather-trash-2 "></i>
                                                    </button>
                                                    
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>



                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Row-->
    </div><!-- end app-content-->
</div>

<div id="showAlertVer" class="modal" style="top: 40px;">
    <div class="modal-dialog">
        <div class="modal-content animate">
            <div class="modal-header">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">×</button>
                <h4 class="modal-title">Warning</h4>
            </div>

            <div class="modal-body" style="max-height: calc(100vh - 210px);overflow-y: auto;">
                <!-- auto scroll -->
                <span><b> Leave entry will be deleted permanently . Are you sure you want to continue ?</b></span>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-danger medium" (click)="confirmLeaveDelete()">OK</button>
                <button type="button" class="btn btn-primary" (click)="closeMRQModal()">Cancel</button>

            </div>

        </div>
    </div>
</div>