import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { SalaryAdvanceService } from '../services/salary-advance.service';
import { SessionService } from '../services';
import { NgForm } from '@angular/forms';
import { salaryAdvance } from '../models/salaryAdvance';
import { ExpenseType } from '../models/expenseType';
import { EmployeeMaster } from '../models/employeeMasterts';
import { LuceneSearchService } from '../services/lucene-search.service';

declare var $: any;

@Component({
  selector: 'app-salary-loan-apply',
  templateUrl: './salary-loan-apply.component.html',
  styleUrls: ['./salary-loan-apply.component.css']
})
export class SalaryLoanApplyComponent implements AfterViewInit {
  DeleteSalaryLoanApply:salaryAdvance;
  constructor(private salaryAdvanceService: SalaryAdvanceService, private sessionservice: SessionService,  private empLuceneSearch: LuceneSearchService) { }

  @ViewChild('salaryadvanceform') salaryAdvanceForm: NgForm;

  ngOnInit(): void {

    this.model.uniqueEmpId = this.sessionservice.getempid();
    this.uniqueEmpId = Number(this.sessionservice.getempid());
    this.submitted = false;

    // get the permisson from application session 
    const maxString = sessionStorage.getItem('permission').replace('[', '');
    const maxStringBox = maxString.replace(']', '');
    const maxStringAgain = maxStringBox.replace(/"/g, '');
    this.permissionList = maxStringAgain.split(',');

    this.checkAccesForUser();

    this.clear();
    this.getSalaryLoanSettings();
    this.viewTeam();
  }

  ngAfterViewInit() {
    this.clearForm();
  }


  amount: number;
  totalAmount: number;
  noOfInstallment: number;
  selectedList = new ExpenseType();
  expenseType: any[] = [];
  salaryAdvanceList: any[] = [];
  // salaryAdvance = salaryAdvance;

  date_of_loan: any;
  Ins_StartDate: any;
  model: any = {};
  installmentComp = [];
  isEdit: boolean = false;
  submitted: boolean = false;
  isOnlyView: boolean = false;
  isButtonClick: boolean = false;
  salaryAdvanceData = new salaryAdvance();
  dateError: boolean = false;
  DOLoanErr: boolean = false;
  ISDErr: boolean = false;
  fieldChanged: boolean = false;
  showBasedOnApproverScreen = true;
  showBasedOnPermissionEmployeeStatus: boolean = false;
  permissionList: any[] = [];
  reason: String;
  interestRate: number;
  salaryHeadId: any;
  salaryDetailId: any;
  totalAmountToPay: number;
  grossSalary: number;
  maxAmtForLoan: number;
  maxNoOfTimesForLoan: number;
  teamMemberList: any[];
  selectedType: string = 'select';
  uniqueEmpId:any ;
  query: any;
  queryName: any;
  employeeid: String;
  activeInativeLuceneFilter: string = 'Active';
  employeeMasterLucene: EmployeeMaster[];
  selectedempid: any;
  leavingdate: any;


  checkAccesForUser() {
    // check permission list has the permission "access link"

    // console.log(this.permissionList);
    this.showBasedOnApproverScreen = this.permissionList.includes('ng.screen.salary.advanceAprove');
    this.showBasedOnPermissionEmployeeStatus = this.permissionList.includes('ng.employee.search.show.employeestatus');
    console.log("apply for others", this.showBasedOnApproverScreen);

  }

  onDateChange(): void {
    // this.setInsStartDate();
    const currentDate = new Date(); // Current date
    const normalizedDateOfLoan = new Date(this.date_of_loan);
    const normalizedIns_StartDate = new Date(this.Ins_StartDate);
    currentDate.setHours(0, 0, 0, 0);
    normalizedDateOfLoan.setHours(0, 0, 0, 0);
    normalizedIns_StartDate.setHours(0, 0, 0, 0);

    // Check if the date_of_loan is before the current date
    if (normalizedDateOfLoan < currentDate) {
      this.DOLoanErr = true;
    } else {
      this.DOLoanErr = false;
    }
    if (normalizedIns_StartDate < currentDate) {
      this.ISDErr = true;
    } else {
      this.ISDErr = false;
    }
  }
  onfieldChange() {

    if (this.isEdit && this.noOfInstallment > 0 && this.totalAmount > 0 && this.date_of_loan && this.Ins_StartDate) {
      this.fieldChanged = true;
      this.setSalaryAdvanceModel();
      this.setInstallmentComp();
      console.log("onfieldChange")
    }
    if (this.totalAmount > 0 && this.date_of_loan ) {

      this.getGrossSalary();
    }
  }

  initialize() {
    this.totalAmount = null;
    this.noOfInstallment = null;
    this.date_of_loan = null;
    this.Ins_StartDate = null;
    this.model.installmentComp = [];
    this.installmentComp = [];
    this.isEdit = false;
  }

  calcInstallment() {
    this.submitted = true;
    this.clearDiv();
    console.log("Step  Success +++++++++++++++++++++++++++");
    console.log("uniqueEmpid", this.sessionservice.getempid())
    if (this.noOfInstallment > 0 && this.totalAmount > 0 && this.date_of_loan && this.Ins_StartDate) {
      console.log("Step 1 Success +++++++++++++++++++++++++++");
      this.setSalaryAdvanceModel();
      this.calcTotalAmountPay();
      this.setInstallmentComp();
    }

  }

  editcalcInstallment() {

    this.submitted = true;
    this.isButtonClick = true;
    this.clearDiv();
    console.log("uniqueEmpid", this.sessionservice.getempid())
    if (this.noOfInstallment > 0 && this.totalAmount > 0 && this.date_of_loan && this.Ins_StartDate) {
      this.setSalaryAdvanceModel();
      this.calcTotalAmountPay();
    }
    if (this.isOnlyView) {
      this.submitted = false;
    }
  }

  calcTotalAmountPay() {
    let totalInstallments = this.noOfInstallment;
    // Calculate installment amount
    // let installAmt = (this.totalAmount / totalInstallments).toFixed(2);
    console.log("this.totalAmount =>", this.totalAmount);
    console.log("totalInstallments =>", totalInstallments);
    console.log("this.interestRate =>", this.interestRate);
    let interest: number = (this.totalAmount * totalInstallments * this.interestRate) / (100 * 12);
    console.log("this.interest =>", interest);
    this.totalAmountToPay = Number(this.totalAmount) + Number(interest);
    console.log("this.totalAmountToPay =>", this.totalAmountToPay);
  }

  getAdvApplyHistory() {

    let uniqueEmpId = this.sessionservice.getempid();
    this.salaryAdvanceService.getAllAdvAppliesByUid(uniqueEmpId, this.salaryHeadId, this.salaryDetailId).subscribe(
      data => {
        console.log("Saved data : ", data)
        this.salaryAdvanceList = data.salaryAdvanceList;
      }, error => {

      });
  }


  getSalaryLoanSettings() {
    console.warn("getJobList ");
    this.salaryAdvanceService.getSalaryLoanSettings().subscribe(
      data => {
        this.interestRate = data.interestRateForLoan;
        this.salaryHeadId = data.salaryHeadIdForloan;
        this.salaryDetailId = data.salaryDetailIdForloan;
        this.maxNoOfTimesForLoan = data.noTimesForloan;
        this.getGrossSalary();
        this.getAdvApplyHistory();
        if(this.interestRate == undefined || this.interestRate == null || this.salaryHeadId == undefined || this.salaryHeadId == 0
          || this.salaryDetailId == undefined || this.salaryDetailId == 0 || this.maxNoOfTimesForLoan == undefined 
          || this.maxNoOfTimesForLoan == null || this.maxNoOfTimesForLoan == 0)
          {
            this.alertmessages("Salary loan setting is not updated.", "danger", '.alertcomp');
           
          }
      }, error => {
        console.log(error);
      }
    );

  }

  getGrossSalary(){
    this.salaryAdvanceService.getGrossSalaryByUId(Number(this.uniqueEmpId),this.date_of_loan).subscribe(
      data => {
        // console.log(data);
        this.grossSalary = data.grossSalary;
        this.maxAmtForLoan = Number(this.maxNoOfTimesForLoan * this.grossSalary);
         // called here because it needs resp of getSalaryAdvanceSettings method
      }, error => {
        console.log(error);
      }
    );
  }

  validate(): any {
    if(this.interestRate == undefined || this.interestRate == null || this.salaryHeadId == undefined || this.salaryHeadId == 0
      || this.salaryDetailId == undefined || this.salaryDetailId == 0 || this.maxNoOfTimesForLoan == undefined 
      || this.maxNoOfTimesForLoan == null || this.maxNoOfTimesForLoan == 0)
      {
        return false;
       
      }
    if (this.totalAmount == null || this.totalAmount == undefined) {
      return false;
    }
    if (this.noOfInstallment == null || this.noOfInstallment == undefined) {
      return false;
    }
    if (this.date_of_loan == null || this.date_of_loan == undefined) {
      return false;
    }
    if (this.Ins_StartDate == null || this.Ins_StartDate == undefined) {
      return false;
    }
    if (this.reason == null || this.reason == undefined) {
      return false;
    }
    if (!this.validateDates()) {
      return false;
    }
    if (this.model.installmentComp == null || this.model.installmentComp == undefined) {
      return false;
    }
    if (this.model.installmentComp && this.model.installmentComp.length === 0) {
      return false;
    }
    if (this.query == null || this.query == undefined || this.query == '' || this.selectedempid == null || this.selectedempid == undefined || this.selectedempid == '') {
      if(this.selectedType =="ME" || this.selectedType =="select"){
        return true;
      }else{
        if (this.selectedempid == null || this.selectedempid == undefined || this.selectedempid == '') {
          this.alertmessages("Please Select Team Member", "danger", '.alertcomp');
        }
        return false;
      }
    }
    return true;
  }

  submit() {

    // console.log(this.salaryAdvanceForm);
    this.submitted = true;

    if (this.model.installmentComp && this.model.installmentComp.length === 0) {
      this.alertmessages("Please verify the installments.", "danger", '.alertcomp');
      return false;
    }

    if (this.validate()) {

      this.salaryAdvanceService.saveSalaryAdvanceAppy(this.model).subscribe(
        data => {
          console.log("Saved data : ", data);
          this.getAdvApplyHistory();
          // this.initialize();
          this.clear();
          this.clearForm();
          this.submitted = false;
          this.alertmessages("Applied successfully.", "success", '.alertcomp');
        }, error => {

        });
    }
  }

  update() {
    if (this.fieldChanged) {
      this.alertmessages("Please verify the installments.", "danger", '.alertcomp');
      return false;
    }

    // console.log(this.salaryAdvanceForm);
    this.submitted = true;
    if (this.validate()) {

      if (!this.isButtonClick) {
        if (this.noOfInstallment > 0 && this.totalAmount > 0 && this.date_of_loan && this.Ins_StartDate) {
          this.setSalaryAdvanceModel();
        }
      }

      this.salaryAdvanceService.updateSalaryAdvanceAppy(this.model).subscribe(
        data => {

          this.getAdvApplyHistory();
          this.clear();
          this.clearForm();
          this.submitted = false;
          this.alertmessages("Updated successfully.", "success", '.alertcomp');

        }, error => {

        });
    }
  }

  editSalaryAdvance(salAdvobj: any) {
    this.isEdit = true;
    this.isOnlyView = false;
    this.totalAmount = salAdvobj.totalAmount;
    this.noOfInstallment = salAdvobj.noOfInstallment;
    this.Ins_StartDate = salAdvobj.installStartDate
    this.reason = salAdvobj.reason;

    this.date_of_loan = salAdvobj.dateOfLoan;

    this.salaryAdvanceService.getapplyAdvInfo(salAdvobj.interMasterId).subscribe(
      data => {

        this.salaryAdvanceData = data.salaryAdvance;
        this.model.interMasterId = data.salaryAdvance.interMasterId;
        this.model.installmentComp = this.salaryAdvanceData.installmentComp;
        this.installmentComp = this.model.installmentComp;
      },
      error => {
        const response = error.error;
        console.log(error);
      }
    );
    $('html,body').animate({
      scrollTop: $(`#alertcomp`).offset().top - 10
    },
      'slow');
  }

  viewSalaryAdvance() {
    this.isOnlyView = true;
    this.submitted = false;
  }

  setSalaryAdvanceModel() {

    this.model.totalAmount = this.totalAmount;
    this.model.noOfInstallment = this.noOfInstallment;

    // let currentDate = new Date(this.Ins_StartDate);
    // let year = currentDate.getFullYear();
    // let month = (currentDate.getMonth() + 1).toString().padStart(2, '0');  // Months are 0-based, so add 1
    // let day = currentDate.getDate().toString().padStart(2, '0');

    // this.model.installStartDate = `${year}-${month}-${day}`;
    this.model.installStartDate = this.Ins_StartDate;
    // this.model.installStartMonth = this.Ins_StartDate;

    // let currentDate2 = new Date(this.date_of_loan);
    // let year2 = currentDate2.getFullYear();
    // let month2 = (currentDate2.getMonth() + 1).toString().padStart(2, '0');  // Months are 0-based, so add 1
    // let day2 = currentDate2.getDate().toString().padStart(2, '0');

    // this.model.dateOfLoan = `${year2}-${month2}-${day2}`;
    this.model.dateOfLoan = this.date_of_loan;
    if(this.selectedempid){
      this.model.uniqueEmpId = this.selectedempid;
    }else{
      this.model.uniqueEmpId = this.uniqueEmpId;
    }
    console.log("current Emp Id = ", this.uniqueEmpId);
    this.model.reason = this.reason;
    this.model.salaryHeadId = this.salaryHeadId;
    this.model.salaryDetailId = this.salaryDetailId;
    this.model.interestRate = this.interestRate;
    this.model.createdUser = Number(this.sessionservice.getempid());
    this.model.advanceType = "Loan";
    this.model.userId = this.sessionservice.getempid();
    console.log("Step 2 Success +++++++++++++++++++++++++++");
  }

  // delete(sal) {
  //   console.log("sal", sal);
  //   this.salaryAdvanceService.deleteAdvApplieByMid(sal.interMasterId).subscribe(
  //     data => {

  //       this.getAdvApplyHistory();
  //       this.clearForm();
  //       this.alertmessages("Deleted successfully.", "success", '.alertcomp');
  //     },
  //     error => {
  //       const response = error.error;
  //       console.log(error);
  //     }
  //   );
  // }
  delete() {
    const deleteId = this.DeleteSalaryLoanApply.interMasterId;
    console.log("deleteId", deleteId);
    this.salaryAdvanceService.deleteAdvApplieByMid(deleteId).subscribe(
      (data) => {
        this.getAdvApplyHistory();
        this.clearForm();
        $('#showAlertVer').modal('toggle');

        this.alertmessages("Deleted successfully.", "success", '.alertcomp');


      }, (error) => {
        console.log(error)
        $('#showAlertVer').modal('toggle');
        this.alertmessages("Please Try Again", "danger",'.alertcomp');

      }
    );


  }


  onDeleteHandler(info: any) {
    console.log("delete : ", info);
    this.DeleteSalaryLoanApply = info;
    $('#showAlertVer').modal('toggle');
  }

  closeMRQModal() {
    $('#showAlertVer').modal('toggle');

  }




  setInstallmentComp() {
    console.log("Step 4 Success +++++++++++++++++++++++++++");
    let currentDate = new Date(this.Ins_StartDate);
    let year = currentDate.getFullYear();
    let totalInstallments = this.noOfInstallment;

    // Calculate interest amount
    let interest: number = (this.totalAmount * totalInstallments * this.interestRate) / (100 * 12);

    this.totalAmountToPay = Number(this.totalAmount) + Number(interest);

    // Calculate installment amount
    let installAmt = (this.totalAmountToPay / totalInstallments).toFixed(2);

    // Clear previous installments if needed
    this.model.installmentComp = [];
    this.installmentComp = [];

    for (let i = 0; i < totalInstallments; i++) {
      // Calculate new month and year
      let installmentDate = new Date(currentDate);
      installmentDate.setMonth(currentDate.getMonth() + i); // Increment month for each installment
      // installmentDate.setDate(currentDate.getDate() + 28);

      // Extract year, month, and day from the updated date
      year = installmentDate.getFullYear();
      let month = (installmentDate.getMonth() + 1).toString().padStart(2, '0');
      let day = installmentDate.getDate().toString().padStart(2, '0');

      // Format month name if needed (assuming `getMonthName` is defined elsewhere)
      let monthName = this.getMonthName(installmentDate.getMonth());

      // Add new installment to the list
      this.installmentComp.push({
        monthName: monthName, // or `${year}-${month}` if you need 'YYYY-MM'
        instAmount: installAmt,
        paidAmount: '',
        // isSalaryGenerated: false,
        instDate: `${year}-${month}-${day}` // Format: 'YYYY-MM-DD'
      });

    }
  }

  updateInstallmentCompBasedOnInstallmentAmount(index: number, instAmt: number) {
    let totalInstallments = this.noOfInstallment;
    this.installmentComp[index].isUpdated = true;
    // Calculate installment amount
    let totalPaid: number = 0;
    let pendingAmount: number = 0;
    let pendingInstallments: number = 0;
    let salGeneratedCount: number = 0;
    let skipCount: number = 0;

    for (let i = 0; i < this.installmentComp.length; i++) {
      const installment = this.installmentComp[i];

      if (installment.paidAmount != null && installment.paidAmount > 0) {
        totalPaid = totalPaid + Number(installment.paidAmount);
        console.log("installment.paidAmount 1", installment.paidAmount);

      }
      if (installment.isSalaryGenerated || installment.isUpdated) {
        if (installment.isSalaryGenerated) {
          salGeneratedCount++;
        }
        skipCount++;
        totalPaid = totalPaid + Number(installment.instAmount);
        console.log("installment.instAmount 1", installment.instAmount);
      }

      console.log("totalPaid", totalPaid);
    }

    pendingAmount = this.totalAmountToPay - totalPaid; // remaining amount to be paid
    pendingInstallments = totalInstallments - skipCount;// remaining installments to be paid

    console.log("pendingAmount", pendingAmount);
    console.log("salGeneratedCount", skipCount);
    console.log("totalInstallments", totalInstallments);
    console.log("pendingInstallments", pendingInstallments);
    console.log("totalPaid", totalPaid);

    let installAmt = 0.00;
    if (pendingAmount > 0 && pendingInstallments > 0) {
      installAmt = Number((pendingAmount / pendingInstallments).toFixed(2));
    } else {
      return;
    }

    for (let j = salGeneratedCount; j < this.installmentComp.length; j++) {
      if (j != index && !this.installmentComp[j].isUpdated) {
        this.installmentComp[j].instAmount = installAmt;
      }
    }
  }

  setInsStartDate() {
    if (this.date_of_loan) {
      // Convert 'yyyy-MM' string to Date object
      const date = new Date(this.date_of_loan);// Adding '-01' to form a valid Date

      // Add one month to the Date object
      date.setMonth(date.getMonth() + 1);

      // Format the updated date back to 'yyyy-MM' for the Ins_StartDate input
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Ensure two digits for month
      const year = date.getFullYear();

      // Set Ins_StartDate in 'yyyy-MM' format
      this.Ins_StartDate = `${year}-${month}-${day}`;
    }
  }


  clear() {
    this.totalAmount = null;
    this.noOfInstallment = null;
    this.date_of_loan = null;
    this.Ins_StartDate = null;
    this.model.installmentComp = [];
    this.installmentComp = [];
    this.isEdit = false;
    this.submitted = false;
    this.isButtonClick = false;
    this.isOnlyView = false;
    this.dateError = false;
    this.fieldChanged = false;
    this.selectedType = 'select';
    this.clearForm();
    // this.salaryAdvanceForm.reset();
  }

  clearForm() {
    if (this.salaryAdvanceForm) {
      console.log("Resetting form", this.salaryAdvanceForm);
      this.salaryAdvanceForm.reset(); // Reset form only after view initialization
    } else {
      console.log("Form is not initialized yet.");
    }
  }

  getMonthName(monthNumber: number): string {
    const monthNames = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];

    // // Validate monthNumber to be within the valid range (1-12)
    // if (monthNumber < 1 || monthNumber > 12) {
    //   throw new Error("Invalid month number. It must be between 1 and 12.");
    // }

    return monthNames[monthNumber]; // Subtract 1 because array is 0-based
  }
  readamount(e, item) {
    this.amount = e.target.value;
    console.log(this.amount, "amount and bool")
    if (this.amount >= 0) {
      item.InstAmount = Number(this.amount);
    }
  }

  changeAmount(e, item, i) {
    if(item.instAmount == null){
      item.instAmount = 0;
      console.log(item.instAmount, "item.instAmount")
    }
    let amount = e.target.value;

    let sub = this.totalAmount - amount;
    let noi = this.noOfInstallment - 1;
    let rem = (sub / noi).toFixed(2);
    // for (let j = 0; i < this.noOfInstall; j++) {
    //   if(j==i){
    //     this.model.InstallmentComp[j].InstAmount = amount;
    //   }else{
    //     this.model.InstallmentComp[j].InstAmount = rem;
    //   }

    // }
  }

  // extractYearMonth(dateString: string): string {
  //   const date = new Date(dateString); // Convert to a Date object
  //   const year = date.getFullYear();   // Get the year
  //   const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Get the month (zero-based)

  //   return `${year}-${month}`; // Return in 'YYYY-MM' format
  // }

  // getEmployeeByNameOrId() {
	// 	this.query = this.query.trim();
	// 	console.log("Len ", this.query);
	// 	if (this.query.length <= 2) {
	// 		this.employeeMasterLucene = []
	// 	}
	// 	if (this.query.length >= 3) {

	// 		this.salaryAdvanceService.getEmployeeByNameOrUniqueId(this.query).subscribe(
	// 			data => {
	// 				console.log("Emp Data ", data);
	// 				this.employeeMasterLucene = data.employeeMasterData
	// 				let reducedobj =this.employeeMasterLucene.reduce((prev,curr,i)=>{
	// 					console.log(prev?.name === this.query , prev, curr)
	// 					if(prev?.name===this.query){
	// 						return prev;
	// 					}
	// 				},)
	// 				// console.log("check data and pass id ... " , reducedobj.uniqueemployeeid)
	
	// 			},
	// 			error => {
	// 				console.log("Error ", error);

	// 			}
	// 		)
	// 	}
	// }

  getEmployeeByNameOrId() {
    this.empLuceneSearch.getEmployeeByNameOrId(this.queryName,this.employeeid,this.activeInativeLuceneFilter,this.query , this.employeeMasterLucene,this.leavingdate,this.selectedempid).subscribe(res=>{
      console.warn(res)
      this.employeeMasterLucene=res.filteredEmployees;
      this.selectedempid = res.selectedempid;
    }); }
  
  onStatusChange() {
    this.getEmployeeByNameOrId();
  }


  modelOk() {
    console.log("this.installmentComp", this.installmentComp);
    const tolerance = 0.10; // Allow a small margin for rounding errors
    const totalInstallmentAmount: number = this.installmentComp.reduce((sum: number, comp) => {
      return sum + parseFloat(comp.instAmount); // Ensuring instAmount is a number
    }, 0);
    console.log("totalAmount", this.totalAmount);
    console.log("totalInstallmentAmount", totalInstallmentAmount);
    if (Math.abs(this.totalAmountToPay - totalInstallmentAmount) < tolerance) {
      console.log("The sum of installment amounts is approximately equal to the total installments.");
      this.model.installmentComp = this.installmentComp;
      $('#calInstallmentModel').modal('hide');
    } else {
      this.clearDiv();
      this.alertmessages("The sum of installment amounts does not match the total amount.", "danger", '.alertmodel');
      console.log("The sum of installment amounts does NOT match the total installments.");
    }

    this.fieldChanged = false;
  }

  alertmessages(message: string, sign: string, alertcomponent: string): void {
    let alertDiv = document.createElement('div');
    alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
    <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message} </div>`
    let alertcomp = document.querySelector(alertcomponent);
    alertcomp.innerHTML = '';
    alertcomp.append(alertDiv);
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    // (<HTMLButtonElement>document.getElementById('btndisabled')).disabled = true;
    setTimeout(function () {
      alertcomp.removeChild(alertDiv);
    }, 3000);
  }

  clearDiv() {
    const div = document.getElementById('alertmodel');
    if (div) {
      div.innerHTML = ''; // Clears the div content
    }
  }

  validateDates() {
    if (this.date_of_loan && this.Ins_StartDate) {
      const loanDate = this.date_of_loan;
      const startDate = this.Ins_StartDate;

      if (loanDate >= startDate) {
        this.dateError = true; // Flag for error handling
      } else {
        this.dateError = false;
      }
    }
    if (this.dateError) {
      return false;
    }
    return true;
  }

  onChange(event) {
    this.selectedType = event.target.value;
    if (this.selectedType === 'Team_Member') {
      // this.viewTeam();

    } else {
      // this.sendId = this.uniqueEmpId;
      // this.teamMemberList = [];
      this.uniqueEmpId = this.sessionservice.getempid();
      this.getGrossSalary();
    }
  }

   onChange1(event) {
    console.log("inside onChange1");
    console.log(event);

    let empdetailobject = this.employeeMasterLucene.map((emp, i) => {
      if (emp.name.trim() === event.trim()) {
        console.log("emp.employeeid",emp.employeeid);
        return emp;
      }
    })

    empdetailobject.forEach(e => {
			if (e && e !== undefined) {
				this.uniqueEmpId = e.uniqueemployeeid;
			}
		})
    console.log("uniqueEmpId = ",this.uniqueEmpId);
    this.getGrossSalary();
  }

  viewTeam() {

    console.log("clicked team member ")
    this.salaryAdvanceService.getTeamMemberList(this.uniqueEmpId).subscribe(
      data => {

        this.teamMemberList = data.teamListSummary;
        console.log("team member list ", this.teamMemberList)
        // this.onClear()
      },
      error => {
        // this.onClear()
        console.log("Error team member list ", error)
      }
    )
  }

  isSalaryViewAllowed(status: string): boolean {
    return ['APPROVED', 'PAID', 'RECOVERD','CP'].includes(status);
  }

  isSalaryEditAllowed(status: string): boolean {
    return ['PENDING'].includes(status);
  }

  isSalaryDeleteAllowed(status: string): boolean {
    return !['PAID','CP','RECOVERD'].includes(status);
  }

}
