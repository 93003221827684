import { Component, HostListener, OnInit } from '@angular/core';
import { Menu } from '../services/menu.Service';
import { EmployeeLeaveService } from '../services/employee-leave.service';
import { EmployeeMasterservice } from '../services/employeemaster.service';
import { SessionService } from '../services';
import { EmployeeMaster } from '../models/employeeMasterts';
import { Resignation } from '../models/Resignation';

@Component({
  selector: 'app-approve-resignation',
  templateUrl: './approve-resignation.component.html',
  styleUrls: ['./approve-resignation.component.css']
})
export class ApproveResignationComponent implements OnInit {
  // Search & filter properties
  query: any;
  queryName: any;
  selectedValue: string = 'Active';  // For filtering employees (if needed)
  statusValue: string = 'All';       // For resignation status (Approved, Pending, Rejected)
  employeeMasterLucene: EmployeeMaster[];
  employeeMaster: EmployeeMaster[];
  selectedempid: any;
  client_id: EmployeeMaster[];
  keyinempid: any | undefined;
  id: number;

  // List of resignation requests to be approved/rejected
  approveresignationReport:Resignation[]=[];
  resignationList: any[] = [];
  selectedList: any[] = [];
  leavingdate: any;
  name: any;

  // Pagination properties
  limitPara: any = 13;
  offsetPara: number = 0;
  searchClicked: boolean = false;

  // User/permission properties
  uniqueEmployeeId = Number(sessionStorage.getItem('empId'));
  permissionList: any[] = [];
  showBasedOnPermissionEmployeeStatus: boolean = false;
  allowToViewReport: boolean = false;
  selectAll: any;

  constructor(private menuService: Menu, private empLeaveService: EmployeeLeaveService, private employeeMasterservice: EmployeeMasterservice,
    private session: SessionService) { }
  ngOnInit(): void {
    this.limitPara = 13;
    this.search();
    this.offsetPara = 0;
    const maxString = sessionStorage.getItem('permission').replace('[', '');
    const maxStringBox = maxString.replace(']', '');
    const maxStringAgain = maxStringBox.replace(/"/g, '');
    this.permissionList = maxStringAgain.split(',');
    this.checkAccessForUser();
  }

  checkAccessForUser(): void {
    this.showBasedOnPermissionEmployeeStatus = this.permissionList.includes('ng.employee.search.show.employeestatus');
    // Adjust the permission key as needed for resignation approval
    this.allowToViewReport = this.permissionList.includes('ng.employee.edit.resignation.permission');
  }

  getEmployeeByNameOrId() {
    console.log("query", this.query);
    this.query = this.query.trim();
    console.log("Len ", this.query);

    if (this.query.length <= 2) {
      this.employeeMaster = [];
    }

    if (this.query.length >= 3) {
      this.empLeaveService.getEmployeeByNameOrUniqueId(this.query).subscribe(
        data => {
          console.log("Emp Data ", data);

          // Check if data.employeeMasterData has any entries
          if (data.employeeMasterData.length > 0) {
            let filteredEmployees = data.employeeMasterData;

            // Get the current date for comparison
            const currentDate = new Date();
            console.log("Current Date: ", currentDate);  // Log current date for debugging

            // Apply filtering logic based on the selected employee status
            if (this.selectedValue === 'Active') {
              filteredEmployees = filteredEmployees.filter(emp => {
                const leavingDate = emp.leavingdateString ? new Date(emp.leavingdateString) : null;
                console.log("Leaving Date for " + emp.name + ": ", leavingDate);  // Log leaving date for debugging
                // Active employees should have leavingdate in the future or null
                return leavingDate === null || leavingDate > currentDate;
              });
            } else if (this.selectedValue === 'Inactive') {
              filteredEmployees = filteredEmployees.filter(emp => {
                const leavingDate = emp.leavingdateString ? new Date(emp.leavingdateString) : null;
                console.log("Leaving Date for " + emp.name + ": ", leavingDate);  // Log leaving date for debugging
                // Inactive employees should have leavingdate in the past or null
                return leavingDate && leavingDate < currentDate;
              });
            }

            // If the status is 'All', no filter is applied
            if (this.selectedValue === 'All') {
              filteredEmployees = data.employeeMasterData;
            }

            // Assign filtered data to the employeeMasterLucene
            this.employeeMasterLucene = filteredEmployees;

            // Log the data and leaving date of the first employee in the filtered list
            if (this.employeeMasterLucene.length > 0) {
              this.leavingdate = this.employeeMasterLucene[0].leavingdateString; // Extract the leaving date
              console.log("Filtered Employee Data: ", this.employeeMasterLucene);
              console.log("leavingdate", this.leavingdate);
            } else {
              console.log("No employee data found.");
            }
          } else {
            console.log("No employee data found.");
          }

          // Update employeeMaster with the filtered data
          this.employeeMaster = this.employeeMasterLucene;

          // Set the selectedempid to the first employee's unique ID
          if (this.employeeMasterLucene.length > 0) {
            this.selectedempid = this.employeeMasterLucene[0].uniqueemployeeid;
          }

        },
        error => {
          console.log("Error ", error);
        }
      );
    }
  }

  onStatusChange(): void {
    this.getEmployeeByNameOrId();
  }

  setMenuName(name: string): void {
    this.menuService.setMenuName(name);
  }

  navigateResignationStatus(): void {
    localStorage.setItem('navigateDashboard', 'true');
  }

  // Fetch resignation requests for approval
  search(): void {
    this.searchClicked = true;
   
    let employeename = (<HTMLInputElement>document.getElementById('employeename')).value;
    if (!employeename) {
      employeename = null;
    } else {
      employeename = employeename.trim()
      // this.statusValue ="All";
    }

    var id = null;
    if (employeename) {
      this.client_id = this.employeeMaster.filter((dName) => {
        return dName.name === employeename;
      });

      console.log("data parent: ", this.client_id)
      id = this.client_id[0].uniqueemployeeid;
      this.keyinempid = id;
    } else {
      console.log("Id parent", id)
    }

    
    this.employeeMasterservice
      .getApproveEmployeeResignation(this.uniqueEmployeeId, this.selectedempid, employeename, this.statusValue, this.limitPara, this.offsetPara)
      .subscribe(
        data => {
          // Expecting data.resignation_intermediate from the backend
          this.approveresignationReport=data.employeeresignation_intermediate;
          console.log("Data available :", data.employeeresignation_intermediate);
          console.log(this.approveresignationReport);
        },
        error => {
          console.log(error);
        }
      );
  }
  // onApproveHandler() {
  //   debugger;
  //   console.log("selected list",this.selectedList);
  //   console.log("selected list userId",this.selectedList[0].userID);
  //   let approve_reject = true;
  //   let flag = true
  //   if (this.selectedList.length === 0) {
  //     this.alertmessages("Please select item for Approval.", "danger")

  //     this.selectedList.forEach(res => {
  //       if (res.approverDate) {
  //           res.approvedExitDate = res.approverDate; // Map the selected date to approvedExitDate
  //       } else {
  //           this.alertmessages("Approved exit date is required for all selected items.", "danger");
  //           return; // Exit the function if any item is missing the approved exit date
  //       }
  //   });
  //   console.log("Selected list with approved exit date:", this.selectedList);

  //   flag = false
  //     return;
  //   }
  //   this.employeeMasterservice
  //       .resignationapproveList(this.selectedList, this.uniqueEmployeeId,approve_reject)
  //       .subscribe(
  //         (res) => {
  //           console.log("selectedList", this.selectedList);
  //           this.alertmessages("Successfully Approved ", "success");
  //           this.selectedList =[];
  //           this.search();
  //         },
  //         (error) => {
  //           console.log(error);
  //           this.alertmessages("Please try again later", "danger");
  //         }
  //       );
  // }
  onApproveHandler() {
    debugger;
    console.log("Selected list:", this.selectedList);

    if (this.selectedList.length === 0) {
        this.alertmessages("Please select an item for approval.", "danger");
        return;
    }

    let approve_reject = true;
    let allValid = true; // Flag to check if all selected items have approverDate

    this.selectedList.forEach(res => {
        if (res.approverDate) {
            res.approvedExitDate = res.approverDate; // Map the selected date to approvedExitDate
        } else {
            this.alertmessages("Approved exit date is required for selected items.", "danger");
            allValid = false;
        }
    });

    // Stop execution if any item is invalid
    if (!allValid) return;

    console.log("Selected list with approved exit date:", this.selectedList);

    this.employeeMasterservice
        .resignationapproveList(this.selectedList, this.uniqueEmployeeId, approve_reject)
        .subscribe(
            (res) => {
                console.log("Response:", res);
                this.alertmessages("Successfully Approved", "success");
                this.selectedList = [];
                this.search(); // Refresh the data
            },
            (error) => {
                console.error("Approval Error:", error);
                this.alertmessages("Please try again later", "danger");
            }
        );
}

  // Reject selected resignation requests; requires a comment for each item
  onRejectHandler(): void {
    let flag = true
    let approve_reject = false;
    if (this.selectedList.length === 0) {
      this.alertmessages("Please select item for Approval.", "danger")

      flag = false
      return;
    }
    let isCommentAbsent = false;
    this.selectedList.forEach(rejectresignation => {
  //
      if (rejectresignation.approverremarks === undefined) {
        isCommentAbsent = true;
        flag = false
        return;
      }
    })
  
    if (isCommentAbsent) {
      console.log("Reject Resignation ");
      this.alertmessages("Comments are mandatory for Rejection.", "danger")
      return;
    }

   this.employeeMasterservice
        .resignationrejectList(this.selectedList)
        .subscribe(
          (res) => {
            console.log("selectedList", this.selectedList);
            this.alertmessages("Successfully Rejected ", "success");
            this.search();
            this.selectedList =[];
          },
          (error) => {
            console.log(error);
            this.alertmessages("Please try again later", "danger");
          }
        );
  }
  //   debugger;
  //   if (this.selectedList.length === 0) {
  //     this.alertmessages("Please select item for Rejection.", "danger");
  //     return;
  //   }
  //   const missingRemarks = this.selectedList.some(item => !item.remarks || item.remarks.trim() === "");
  //   if (missingRemarks) {
  //     this.alertmessages("Comments are mandatory for Rejection.", "danger");
  //     return;
  //   }
  //   this.employeeMasterservice.resignationrejectList(this.selectedList)
  //     .subscribe(
  //       res => {
  //         console.log("Rejection response:", res);
  //         this.alertmessages("Successfully Rejected", "success");
  //         this.search();
  //         this.selectedList = [];
  //       },
  //       error => {
  //         console.log("Error during rejection:", error);
  //         this.alertmessages("Please try again later", "danger");
  //       }
  //     );
  // }

  // Helper methods for checkbox selection management
  addToSelectedList(res: any): void {
    if (!this.selectedList.includes(res)) {
      this.selectedList.push(res);
    }
  }
  removeFromSelectedList(res: any): void {
    this.selectedList = this.selectedList.filter(i => i !== res);
  }
  check_info(res: any, event: any): void {
    res.checked = event.target.checked;
    if (res.checked) {
      this.addToSelectedList(res);
    } else {
      this.removeFromSelectedList(res);
    }
    console.log("Current selected list:", this.selectedList);
  }
  checkallbox(event: any): void {
    this.resignationList.forEach((res) => {
     res.checked = event.target.checked;
      if (res.checked) {
        this.addToSelectedList(res);
      } else {
        this.removeFromSelectedList(res);
      }
    });
    console.log("resignation list",this.resignationList);
    console.log("resignation list",this.resignationList);
  }

  // Infinite scroll handler: increase offset and reload data
  onScroll(): void {
    this.offsetPara = this.offsetPara + 13;
    console.log("limit = ? ", this.limitPara, "offset = +13 ", this.offsetPara)
    this.search();
  }
  limitpara() {
    this.offsetPara = 0;
    console.log(" on clikc limit para ", this.limitPara)
    console.log("this offset", this.offsetPara)
  }
  @HostListener('scroll', ['$event'])
  scrolled() {
    console.log("Scrolled >>> ")
    this.onScroll();
  }
      


  // Display alert messages dynamically
  alertmessages(message: string, sign: string): void {
    let alertDiv = document.createElement('div');
    alertDiv.innerHTML = `<div class="alert alert-${sign} mt-4" role="alert">
      <button class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
      <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message}
    </div>`;
    let alertcomp = document.querySelector('.alertcomp');
    alertcomp.append(alertDiv);
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    setTimeout(function () {
      alertcomp.removeChild(alertDiv);
    }, 3000);
  }
}
