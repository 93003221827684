<div class="app-content main-content">
    <div class="side-app main-container" style="margin-top:-15px">
        <div class="alertcomp">

        </div>
        <div class="page-header d-xl-flex d-block" style="margin-top: -40px;">
            <!-- <div class="page-leftheader">
                <div class="page-title">Apply Attendance</div>
            </div> -->
            <div class="page-rightheader ms-md-auto">
               
            </div>
        </div>

        <div class="row">
            <div class="col-xl-4 col-md-12 col-lg-12">
                <div class="card" style="margin-bottom:10px">
                    <div class="card-body">
                        <div class="btn-list text-center ">
                            <div>
                                <img loading="lazy" src="./../../assets/img/clock.JPG" id="clockIN" data-clockin="1"
                                    class=" clock" alt="clockin" style=" width: 180px;">
                                <img loading="lazy" src="./../../assets/img/Clock-move-FINAL.gif" id="clockOut"
                                    data-clockout="2" style=" width: 180px;" class="clock displayNone" alt="clockout">
                            </div>

                            <div class="countdowntimer mt-0">
                                <span id="clocktimer2" class="border-0"></span>
                                <label class="form-label">Current Time</label>
                            </div>

                            <!-- task drop down if task present then it will show -->
                            <div class="countdowntimer mt-0" *ngIf="taskMasterDetail.length>0">
                                <div class="col-md-12 col-sm-12 col-xs-12 mb-4">
                                    <div class="form-group m-0">
                                        <div>
                                            <select id="taskList" class="form-control" name="taskList"
                                                placeholder="Select Task" [(ngModel)]="taskid" autocomplete="off"
                                                autofocus="autofocus">

                                                <!-- Default option with value undefined -->
                                                <option [ngValue]="undefined" selected="selected">Select Task</option>

                                                <!-- Dynamic options -->
                                                <!-- <option *ngFor="let data of taskMasterDetail" [ngValue]="data.id">
                                                    {{data.title}}
                                                </option> -->
                                                <option *ngFor="let data of taskMasterDetail" [ngValue]="data.id">
                                                    {{ getTaskTitle(data.duedate, data.title) }}
                                                </option>
                                                <!-- [ngClass]="getTaskClass(data.duedate)" -->
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- swipe in -->
                            <div class="btn-group  mb-2">

                                <!-- Case 1: Both capture and task exist -->
                                <ng-container
                                    *ngIf="taskid !== undefined && taskMasterDetail?.length > 0 && captureAttendanceForEmployee">
                                    <button class="btn dropdown bgcolors"
                                        (click)="checkAttendanceCaptureWithImageCheckForTask($event, 'IN')"
                                        data-bs-toggle="modal" data-bs-target="#showTaskDetailfollowedbyCapturePop">
                                        Clock In<span class="caret"></span>
                                    </button>
                                </ng-container>

                                <!-- Case 2: Only capture exists -->
                                <ng-container
                                    *ngIf="(taskid === undefined || taskMasterDetail?.length === 0) && captureAttendanceForEmployee">
                                    <button class="btn dropdown bgcolors"
                                        (click)="checkAttendanceCaptureWithImageCheckForTask($event, 'IN')"
                                        data-bs-toggle="modal" data-bs-target="#swipewithCapture">
                                        Clock In<span class="caret"></span>
                                    </button>
                                </ng-container>

                                <!-- Case 3: Only task exists -->
                                <ng-container
                                    *ngIf="!captureAttendanceForEmployee && taskid !== undefined && taskMasterDetail?.length > 0">
                                    <button class="btn dropdown bgcolors"
                                        (click)="checkAttendanceCaptureWithImageCheckForTask($event, 'IN')"
                                        data-bs-toggle="modal" data-bs-target="#showTaskDetailfollowedbyCapturePop">
                                        Clock In<span class="caret"></span>
                                    </button>
                                </ng-container>

                                <!-- Case 4: Neither capture nor task exists -->
                                <ng-container
                                    *ngIf="!captureAttendanceForEmployee && (taskid === undefined || taskMasterDetail?.length === 0)">
                                    <button class="btn dropdown bgcolors"
                                        (click)="checkAttendanceCaptureWithImageCheckForTask($event, 'IN')">
                                        Clock In<span class="caret"></span>
                                    </button>
                                </ng-container>

                            </div>

                            <!-- swipe out and  breaks  -->
                            <div class="btn-group ms-2 mb-2">

                                <!-- Case 1: Both capture and task exist -->
                                <ng-container
                                    *ngIf="taskid !== undefined && taskMasterDetail?.length > 0 && captureAttendanceForEmployee">
                                    <button class="btn dropdown bgcolors"
                                        (click)="checkAttendanceCaptureWithImageCheckForTask($event, 'OUT')"
                                        data-bs-toggle="modal" data-bs-target="#updateStatusAndCommentCapturePop">
                                        Clock Out<span class="caret"></span>
                                    </button>
                                </ng-container>

                                <!-- Case 2: Only capture exists -->
                                <ng-container
                                    *ngIf="(taskid === undefined || taskMasterDetail?.length === 0) && captureAttendanceForEmployee">
                                    <button class="btn dropdown bgcolors"
                                        (click)="checkAttendanceCaptureWithImageCheckForTask($event, 'OUT')"
                                        data-bs-toggle="modal" data-bs-target="#swipewithCapture">
                                        Clock Out<span class="caret"></span>
                                    </button>
                                </ng-container>

                                <!-- Case 3: Only task exists -->
                                <ng-container
                                    *ngIf="!captureAttendanceForEmployee && taskid !== undefined && taskMasterDetail?.length > 0">
                                    <button class="btn dropdown bgcolors"
                                        (click)="checkAttendanceCaptureWithImageCheckForTask($event, 'OUT')"
                                        data-bs-toggle="modal" data-bs-target="#updateStatusAndCommentCapturePop">
                                        Clock Out<span class="caret"></span>
                                    </button>
                                </ng-container>

                                <!-- Case 4: Neither capture nor task exists -->
                                <ng-container
                                    *ngIf="!captureAttendanceForEmployee && (taskid === undefined || taskMasterDetail?.length === 0)">
                                    <button class="btn dropdown bgcolors"
                                        (click)="checkAttendanceCaptureWithImageCheckForTask($event, 'OUT')">
                                        Clock Out<span class="caret"></span>
                                    </button>
                                </ng-container>


                                <ul class="dropdown-menu" role="menu" *ngIf="break.length!==0">
                                    <li class="dropdown-plus-title">
                                        Breaks
                                        <b class="fa fa-angle-up" aria-hidden="true"></b>
                                    </li>
                                    <li style="cursor: pointer;" *ngFor="let item of break">
                                        <a data-breakid="{{item.id}}"
                                            (click)="save_employee_attendences($event,'OUT')">{{item.breaks}}</a>
                                    </li>

                                </ul>

                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <!-- swipe info table  -->
            <div class="col-xl-8 col-md-12 col-lg-12">
                <div class="card" style="margin-bottom:10px">
                    <div class="card-header  border-0 d-flex justify-content-between align-items-center">
                        <h4 class="card-title">Swipe Info as on</h4>
                       
                            <div class="btn-list ms-auto">
                                <a [routerLink]="['/employeeattendencesummary']" class="btn btn-primary me-3"
                                    (click)="setMenuName('Swipe details'); $event.preventDefault()">Swipe details</a>
                                <!-- <button class="btn btn-primary me-3" (click)="EnableLocation1()">Enable Location 1</button> -->
                            </div>
                    </div>
                    <!-- <div class="card-body">
                        <div class="row">
                        </div>
                    </div> -->

                    <div class="card-body">
                        <div class="table-responsive" style="height: 258px;">
                            <table class="table  table-vcenter text-nowrap table-bordered border-bottom"
                                id="emp-attendance">
                                <thead class="tablerow">
                                    <tr class="rowcolors">
                                        <!-- <th class="border-bottom-0">Date</th> -->
                                        <!-- <th class="border-bottom-0">Status</th> -->
                                        <th class="border-bottom-0">#</th>
                                        <th class="border-bottom-0">Clock IN/OUT</th>
                                        <th class="border-bottom-0">Time</th>
                                        <th class="border-bottom-0">Title</th>
                                        <th *ngIf="captureAttendanceForEmployee" class="border-bottom-0"></th>
                                        <!-- <th class="border-bottom-0">Progress</th> -->
                                        <!-- <th class="border-bottom-0">Hours</th> -->
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let swipe of emp_attendence_list;let i=index">
                                        <!-- <td>{{swipe}}</td> -->
                                        <!-- <td><span class="badge badge-success">Present</span></td> -->
                                        <td style="text-align: right;">{{i+1}}</td>
                                        <td>{{ swipe.swipetype === swipeintype ? "IN" :"OUT" }}</td>
                                        <td>{{ swipe.swipetime| date: 'shortTime' }}</td>
                                        <td style="text-wrap: wrap;">{{
                                            swipe.taskTitle }}</td>
                                        <!-- <td> {{swipe.capturedPath}} - {{swipe.fileName}}</td> -->
                                        <!-- <td>
                                            <div class="progress progress-sm d-block">
                                                <div class="progress-bar bg-success w-100"></div>
                                            </div>
                                        </td> -->
                                        <!-- <td>09h :10 mins</td> -->
                                        <td *ngIf="captureAttendanceForEmployee">
                                            <button class="btn btn-success plr"
                                                *ngIf="swipe.capturedPath!= undefined && swipe.capturedPath!=null"
                                                data-bs-toggle="modal" data-bs-target="#captureImagePreview"
                                                (click)="getPreview(swipe)">
                                                <i class="si si-picture" data-bs-toggle="tooltip"
                                                    data-original-title="download"></i>
                                            </button>
                                        </td>

                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="row" *ngIf="emp_attendance_intermediatelist.length !==0">
            <div class="col-xl-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="card-header  border-0">
                        <h4 class="card-title">Regularized Attendance Summary</h4>
                    </div>
                    <!-- <div class="card-body">
                        <div class="row">
                        </div>
                    </div> -->

                    <div class="card-body">
                        <div class="table-responsive">
                            <div class="scrollsearchresults" infiniteScroll [infiniteScrollDistance]="2"
                                [infiniteScrollUpDistance]="1.5" [infiniteScrollThrottle]="100" [scrollWindow]="false">

                                <table class="table table-hover table-vcenter text-nowrap table-bordered border-bottom"
                                    id="emp-attendance">
                                    <thead class="tablerow">
                                        <tr class="stickyatTop rowcolors">
                                            <!-- <th class="border-bottom-0">Date</th> -->
                                            <!-- <th class="border-bottom-0">Status</th> -->
                                            <th class="border-bottom-0">Sl No</th>
                                            <th class="border-bottom-0">Date</th>
                                            <th class="border-bottom-0">Clock IN/OUT</th>
                                            <th class="border-bottom-0">Time</th>
                                            <th class="border-bottom-0">Status</th>
                                            <th class="border-bottom-0">Approved By</th>

                                            <!-- <th class="border-bottom-0">Progress</th> -->
                                            <!-- <th class="border-bottom-0">Hours</th> -->
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let emp of emp_attendance_intermediatelist ; let i=index">
                                            <!-- <td>{{swipe}}</td> -->
                                            <!-- <td><span class="badge badge-success">Present</span></td> -->
                                            <td>{{i+1}}</td>
                                            <td>{{emp.stime | date: 'mediumDate'}}</td>
                                            <td>{{ emp.swipetype === swipeintype ? "IN" :"OUT" }}</td>
                                            <td>{{ emp.swipetime | date: 'shortTime' }}</td>
                                            <!-- <td>
                                                <span *ngIf="emp.status === 'PENDING'"
                                                    class="badge badge-warning">{{emp.status}}</span>
                                                <span *ngIf="emp.status === 'APPROVED'"
                                                    class="badge badge-success">{{emp.status}}</span>
                                                <span *ngIf="emp.status === 'REJECTED'"
                                                    class="badge badge-danger">{{emp.status}}</span>
                                            </td> -->
                                            <td>
                                                <span style="font-size: 13px; font-weight: 500;" [ngClass]="
                                                    emp.status === 'Pending' ? 'bg-custom-warning text-custom-dark' :
                                                    emp.status === 'Approved' ? 'bg-custom-green text-custom-dark' :
                                                   emp.status === 'Rejected' ? 'bg-custom-red text-custom-dark' :
                                                   emp.status === 'Partial Approve 1' ? 'bg-custom-orange1 text-custom-dark' :
                                                   emp.status === 'Partial Approve 2' ? 'bg-custom-orange2 text-custom-dark' :
                                                    'bg-custom-warning text-custom-dark'
                                                  " class="badge">
                                                    {{ emp.status }}
                                                </span>
                                            </td>

                                            <td>{{ emp.updatedBy}}</td>
                                            <!-- <td>
                                            <div class="progress progress-sm d-block">
                                                <div class="progress-bar bg-success w-100"></div>
                                            </div>
                                        </td> -->
                                            <!-- <td>09h :10 mins</td> -->
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>

<div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="staticBackdropLabel">Select the type of break</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary">Understood</button>
            </div>
        </div>
    </div>
</div>

<!-- to check if the capture is enable with not task  -->
<div class="modal fade" id="swipewithCapture" tabindex="-1" aria-labelledby="swipewithCaptureLabel" aria-hidden="true"
    style="display: none;">
    <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="swipewithCaptureLabel" style="font-size: 20px;">Capture Attendance</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
            </div>
            <div class="modal-body">
                <div class="modal-body" *ngIf="capturedImages?.length == 0">
                    <video id="cameraStream" width="100%" height="auto" autoplay></video>
                </div>
                <ng-container *ngIf="capturedImages?.length>0">
                    <h5 class="modal-title" style="font-size: 17px;">Captured Image</h5>
                    <div class="modal-body pb-5" *ngFor="let images of capturedImages">
                        <img [src]="images" width="100%" height="auto">
                    </div>
                </ng-container>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                    (click)="stopMediaStream()">Close</button>

                <ng-container *ngIf="capturedImages?.length > 0; else captureButton">
                    <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                        (click)="saveImageAndSwipe()">OK</button>
                </ng-container>

                <ng-template #captureButton>
                    <button type="button" class="btn btn-primary" (click)="captureAndStopStream()">Capture</button>
                </ng-template>
            </div>
        </div>
    </div>
</div>

<!-- to check title and capture is enabled  -->
<div class="modal fade" id="showTaskDetailfollowedbyCapturePop" tabindex="-1" aria-labelledby="swipewithTitleLabel"
    aria-hidden="true" style="display: none;">
    <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="swipewithCaptureLabel" style="font-size: 20px;">Task Detail</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
            </div>

            <!-- simple inline  -->
            <!-- <div class="modal-body">
                <div *ngIf="previewTaskWhenSwipe?.length>0">Task Title : {{previewTaskWhenSwipe[0].title}} <br>
                    Task Description :
                    {{previewTaskWhenSwipe[0].description}}</div>
            </div> -->

            <!-- card design  -->
            <!-- <div class="card" *ngIf="previewTaskWhenSwipe.length>0">
                <div class="card-header border-bottom-0">
                    <div class="card-title">
                        {{previewTaskWhenSwipe[0].title}}
                    </div>
                </div>
                <div class="card-body">
                    <p class="card-text">{{previewTaskWhenSwipe[0].description}}</p>
                </div>
            </div> -->

            <!-- panel  -->
            <div class="modal-body">
                <div class="col-12" *ngIf="previewTaskWhenSwipe?.length > 0">
                    <div class="expanel expanel-default">
                        <div class="expanel-heading">
                            <h3 class="expanel-title"> {{previewTaskWhenSwipe[0].title}}</h3>
                        </div>
                        <div class="expanel-body">
                            {{previewTaskWhenSwipe[0].description}}
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal-footer">
                <!-- -->
                <ng-container
                    *ngIf="captureAttendanceForEmployee && taskid !== undefined && taskMasterDetail?.length > 0">
                    <button class="btn btn-primary" data-bs-target="#swipewithCapture" data-bs-toggle="modal"
                        data-bs-dismiss="modal">Ok</button>
                </ng-container>
                <ng-container
                    *ngIf="!captureAttendanceForEmployee && taskid !== undefined && taskMasterDetail?.length > 0">
                    <button class="btn btn-primary" data-bs-dismiss="modal"
                        (click)="save_employee_attendences(x,swipetype_)">Ok
                        , Swipe-{{swipetype_|
                        titlecase}}</button>
                </ng-container>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                    (click)="stopMediaStream()">Close</button>
            </div>
        </div>
    </div>
</div>

<!-- to chenges task status and comment  and capture is enabled  -->
<div class="modal fade" id="updateStatusAndCommentCapturePop" tabindex="-1" aria-labelledby="swipewithTitleLabel"
    aria-hidden="true" style="display: none;">
    <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="swipewithCaptureLabel" style="font-size: 20px;">Update Task Detail</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
            </div>
            <div class="modal-body">

                <!-- inline  -->
                <!-- <div *ngIf="previewTaskWhenSwipe?.length>0">Task Title : {{previewTaskWhenSwipe[0].title}}
                </div>
                <div class="row" *ngIf="previewTaskWhenSwipe?.length>0">
                    <div class="col-md-4 col-sm-4 col-xs-4">
                        <label class="form-label">Status</label>
                        <select id="taskStatus" class="form-control" name="taskStatusCheck" [(ngModel)]="status">
                            <option value="Assigned">Assigned</option>
                            <option value="Pending">Pending</option>
                            <option value="Close">Close</option>
                        </select>
                    </div>
                    <div class="col-md-8 col-sm-8 col-xs-8">
                        <label class="form-label">Remark</label>
                        <div class="row">
                            <textarea name="text" class="form-control col-md-12 col-xs-12" id="comment"
                                name="commentTask" required [(ngModel)]="comment"></textarea>
                        </div>

                    </div>
                </div> -->

                <!-- card design  -->
                <!-- <div class="card" *ngIf="previewTaskWhenSwipe.length>0">
                    <div class="card-header border-bottom-0">
                        <div class="card-title">
                            {{previewTaskWhenSwipe[0].title}}
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row" *ngIf="previewTaskWhenSwipe.length>0">
                            <div class="col-md-4 col-sm-4 col-xs-4">
                                <label class="form-label">Status</label>
                                <select id="taskStatus" class="form-control" name="taskStatusCheck"
                                    [(ngModel)]="status">
                                    <option value="Assigned">Assigned</option>
                                    <option value="Pending">Pending</option>
                                    <option value="Close">Close</option>
                                </select>
                            </div>
                            <div class="col-md-8 col-sm-8 col-xs-8">
                                <label class="form-label">Remark</label>
                                <div class="row">
                                    <textarea name="text" class="form-control col-md-12 col-xs-12" id="comment"
                                        name="commentTask" required [(ngModel)]="comment"></textarea>
                                </div>

                            </div>
                        </div>
                    </div>
                </div> -->

                <!-- panel design  -->
                <div class="col-12" *ngIf="previewTaskWhenSwipe?.length>0">
                    <div class="expanel expanel-default">
                        <div class="expanel-heading">
                            <h3 class="expanel-title"> {{previewTaskWhenSwipe[0].title}}</h3>
                        </div>
                        <div class="expanel-body">
                            <div class="row" *ngIf="previewTaskWhenSwipe?.length>0">
                                <div class="col-md-4 col-sm-4 col-xs-4">
                                    <label class="form-label">Status</label>
                                    <select id="taskStatus" class="form-control" name="taskStatusCheck"
                                        [(ngModel)]="status">
                                        <option value="Assigned">Assigned</option>
                                        <option value="Pending">Pending</option>
                                        <option value="Close">Close</option>
                                    </select>
                                </div>
                                <div class="col-md-8 col-sm-8 col-xs-8">
                                    <label class="form-label">Remark</label>
                                    <div class="row">
                                        <textarea name="text" class="form-control col-md-12 col-xs-12" id="comment"
                                            name="commentTask" required [(ngModel)]="comment"></textarea>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="modal-footer">
                <ng-container
                    *ngIf="captureAttendanceForEmployee && taskid !== undefined && taskMasterDetail?.length > 0">
                    <button class="btn btn-primary" data-bs-target="#swipewithCapture" data-bs-toggle="modal"
                        data-bs-dismiss="modal">Ok</button>
                </ng-container>
                <ng-container
                    *ngIf="!captureAttendanceForEmployee && taskid !== undefined && taskMasterDetail?.length > 0">
                    <button class="btn btn-primary" data-bs-dismiss="modal"
                        (click)="save_employee_attendences(x,swipetype_)">Ok
                        , Swipe-{{swipetype_|
                        titlecase}}</button>
                </ng-container>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                    (click)="stopMediaStream()">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="captureImagePreview" tabindex="-1" aria-labelledby="additionalModalLabelSwipe"
    aria-hidden="true" style="display: none;">
    <div class="modal-dialog modal-lg modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel" style="font-size: 20px;">Captured Image</h5>
            </div>
            <div class="modal-body" style="padding: 0px;">
                <div class=" container-fluid">
                    <div class="modal-body">
                        <img width="100%" height="auto" alt="img"
                            [src]="url || './../../../assets/template_assests/images/users/1.jpg'">
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                    aria-label="Close">Close</button>
            </div>
        </div>
    </div>
</div>