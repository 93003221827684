<div class="app-content main-content">
    <div class="side-app main-container main-container-margin">
        <div class="alertcomp">

        </div>
        <div class="row">
            <div class="col-xl-12 col-md-12 col-lg-12 mobileview">
                <div class="card">
                    <div class="card-body">

                        <form id="fcForm" class="form-horizontal form-label-left">
                            <form class="form-horizontal card-body row label-floating px-4" id="fcForm"
                                (keyup.enter)="search()">

                                <div class="col-md-4 col-sm-4 col-xs-4 mb-4">
                                    <label class=" p-0 form-label col-xl-6 col-md-6 col-lg-6">Employee Name / ID
                                    </label>
                                    <div class="form-group m-0">
                                        <div>
                                            <input type="search" class="form-control " name="employeename"
                                                id="employeename" [(ngModel)]="query" autocomplete="off"
                                                (input)="getEmployeeByNameOrId()" list="employeeMaster"
                                                autofocus="autofocus" placeholder="Search Employee Name / ID">

                                            <datalist id="employeeMaster">
                                                <option data-value={{emp.name}} *ngFor="let emp of employeeMaster">
                                                    {{emp.employeeid}}
                                                </option>
                                            </datalist>

                                        </div>

                                    </div>
                                </div>

                                <div class="form-group col-md-3 col-sm-3 col-xs-3 px-2">
                                    <label class="form-label">Employee Status</label>
                                    <select class="form-control" type="text" style="width: 100%;" required
                                        [(ngModel)]="selectedValue" name="selVal" (change)="onStatusChange()"
                                        [disabled]="!showBasedOnPermissionEmployeeStatus">
                                        <option value="All">All</option>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                    </select>
                                </div>

                                <div class="form-group col-md-3 col-sm-3 col-xs-3 px-2">
                                    <div class="form-group label-floating">
                                        <label class="form-label">Select Status </label>
                                        <div>
                                            <div>
                                                <select id="ResignationStatusFilters" class="form-control"
                                                    [(ngModel)]="statusValue" name="dateFilter">
                                                    <option value="All">All</option>
                                                    <option value="Resignation Approve Pending">Resignation Approve Pending</option>
                                                    <option value="Partial Approve 1">Partial Approve 1</option>
                                                    <option value="Partial Approve 2">Partial Approve 2</option>
                                                    <option value="Rejected">Rejected</option>

                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group col-md-2 col-sm-2 px-2">
                                    <label class="form-label">&nbsp;</label>
                                    <button type="button" id="navigatetarget" class="btn btn-primary  wd w-100"
                                        (click)="search()">
                                        <i class="fa fa-search p-0" aria-hidden="true"></i> Search
                                    </button>
                                </div>

                            </form>
                        </form>
                    </div>
                </div>
            </div>
        </div>


        <div class="empty-div-one" id="navigatedest"></div>
        <div class="row top_margin">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header d-flex justify-content-between align-items-center  border-0">
                        <h4 class="card-title">Employee Resignation Summary</h4>
                        <!-- <div class="d-flex justify-content-end w-100">
                            <a [routerLink]="['/suspensionreport']" class="link-text"
                                [routerLinkActive]="['highlightActiveLink']" (click)="setMenuName('Employee Suspension Report');
                        navigateSuspenseStatus()" [ngClass]="{'disable-div': !allowToViewReport}"
                                *ngIf="allowToViewReport">View Employee Suspension
                                Report</a>
                        </div> -->
                    </div>
                    <div class="card-body">
                        <!-- <form id="salaryadvanceListform" class="form-horizontal form-label-left row" #salaryadvanceListform="ngForm"> -->
                        <div class="table-responsive">
                            <div class="scrollsearchresults" infiniteScroll (scrolled)="onScroll()" id="scrolllength">
                                <table class="table table-hover table-vcenter text-nowrap table-bordered border-bottom">
                                    <thead class="tablerow stickyatTop">
                                        <tr class="rowcolors stickyatTop">
                                            <th>
                                                <input type="checkbox" [checked]="selectAll"
                                                    (change)="checkallbox($event)" style="cursor: pointer;" />
                                            </th>
                                            <th class="border-bottom-0 fs-7">Employee ID</th>
                                            <th class="border-bottom-0 fs-7">Employee Name</th>
                                            <th class="border-bottom-0 fs-7">Submission Date</th>
                                            <th class="border-bottom-0 fs-7">Notice Period</th>
                                            <th class="border-bottom-0 fs-7">Last Working Date</th>
                                            <th class="border-bottom-0 fs-7">Reason</th>
                                            <th class="border-bottom-0 fs-7">Remarks</th>
                                            <th class="border-bottom-0 fs-7">Requested Last Working Date</th>
                                            <th class="border-bottom-0 fs-7">Approved exit date</th>
                                            <th class="border-bottom-0 fs-7">Approver remarks </th>
                                            <th class="border-bottom-0 fs-7">Approved By </th>
                                            <th class="border-bottom-0 fs-7">Status</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let res of  approveresignationReport">
                                            <td>
                                                <!-- Disable checkbox if status is not 'Pending' -->
                                                <input class="unitaskbox" [checked]="res.checked"
                                                    (change)="check_info(res, $event)" type="checkbox"
                                                    [disabled]="res.status == ('Approved' || 'Rejected')"  style="cursor: pointer;" />
                                            </td>
                                            <td>{{res.empid}}</td>
                                            <td>{{res.empname}}</td>
                                            <td>{{res.submissionDate | date: 'mediumDate'}}</td>
                                            <td>{{res.notice_Period}}</td>
                                            <td>{{res.lastWorkingDate | date: 'mediumDate' }}</td>
                                            <td>{{res.reason}}</td>
                                            <td>{{ res.remarks }}</td>
                                            <td>{{res.requestedLastWorkingDate | date: 'mediumDate'}}</td>

                                            <td>
                                                <input type="date" class="form-control"
                                                    [(ngModel)]="res.approverDate"
                                                    name="approverDate"
                                                    required
                                                    placeholder="Select Date">
                                            </td>

                                            <td
                                                *ngIf="res.status === 'Resignation Approve Pending' || res.status === 'Partial Approve 1' || res.status === 'Rejected'; else showNull">
                                                <input autocomplete="off" type="text" style="min-width: 9.7rem;"
                                                    name="remark" class="form-control" [(ngModel)]="res.approverremarks"
                                                    placeholder="Comments for Rejection">
                                            </td>
                                            <ng-template #showNull>
                                                <td></td>
                                                <!-- Display null or empty content -->
                                            </ng-template>
                                            <td> {{ res.approvedBy }}</td>
                                            <td>
                                                <span style="font-size: 13px; font-weight: 500;"
                                                    [ngClass]="res.status === 'Approved' ? 'badge-success' : 
                                               res.status === 'Partial Approve 1' ? 'bg-custom-orange1 text-custom-dark' : 
                                               res.status === 'Partial Approve 2' ? 'bg-custom-orange2 text-custom-dark' : 
                                               res.status === 'Resignation Approve Pending' ? 'bg-custom-warning text-custom-dark' : 
                                               res.status === 'Rejected' ? 'badge-danger' : 'bg-custom-red text-custom-dark'" class="badge">{{res.status}}</span>
                                            </td>


                                            <!-- <td>
                                                <span style="font-size: 13px; font-weight: 500;"
                                                    [ngClass]="res.status === 'Approved' ? 'badge-success' : res.status === 'Pending' ? 'badge-warning text-custom-dark' : res.status === 'Rejected' ? 'badge-danger' : 'bg-custom-warning text-custom-dark'"
                                                    class="badge">{{res.status}}</span>
                                            </td> -->
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>

                        <div class="pro-fab btn-float-right">
                            <button *ngIf=" approveresignationReport.length > 0 && searchClicked" type="button"
                                class="btn btn-success" (click)="onApproveHandler()">Approve</button>
                            <button *ngIf=" approveresignationReport.length > 0 && searchClicked" type="button"
                                class="btn btn-danger ms-2" (click)="onRejectHandler()">Reject</button>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </div><!-- end app-content-->
</div>