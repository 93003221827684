import { Component, OnInit } from '@angular/core';
import { EmployeeMaster } from '../models/employeeMasterts';
import { EmployeeLeaveService } from '../services/employee-leave.service';
import { CommonService } from '../services/common.service';
declare var $: any;

@Component({
  selector: 'app-taskmaster',
  templateUrl: './taskmaster.component.html',
  styleUrls: ['./taskmaster.component.css']
})
export class TaskmasterComponent implements OnInit {


  taskid: any;
  taskName: any;
  taskDesc: any = '';
  taskComment: any = '';
  // dueDate: any;
  status: any;

  queryName: any;
  employeeid: any;
  employeeMasterLucene: EmployeeMaster[];
  query: any;
  add_view_toggle: string; // addTask  // viewTask
  addOrUpdate: boolean;
  taskMasterDetail: any[];
  routeDetail: any;
  routeName: any = '';
  editRouteName: any;

  constructor(private empLeaveService: EmployeeLeaveService, private commonService: CommonService) {
  }

  ngOnInit(): void {
    this.getRoute();
    this.viewTaskButton();
  }

  addTaskButton() {
    this.add_view_toggle = 'addTask';
    this.addOrUpdate = true;
    this.routeName = '';
    // this.getRoute();
  }

  updateHandeler() {
    // if (this.status == 'Pending') {
    //   $('#showAlertForReassignTask').modal('toggle');
    //   return;
    // }
    // if (this.status == 'Close') {
    //   $('#showAlertForReassignTask').modal('toggle');
    //   return;
    // }
    // if assigned 
    this.updateTaskButton();
  }

  closeReassignTask() {
    $('#showAlertForReassignTask').modal('toggle');
  }

  updateTaskButton() {
    const obj = {
      id: this.taskid,
      title: this.taskName.trim(),
      description: this.taskDesc,
      comment: this.taskComment,
      routeid: this.routeName,
    }

    if (obj.title == undefined || obj.title == null || obj.title == '') {
      this.alertmessages('Please Enter Title', 'danger');
      return
    }

    if (obj.routeid == undefined || obj.routeid == null || obj.routeid == '') {
      this.alertmessages('Please Select Route Name', 'danger');
      return
    }

    console.log(obj);

    console.log("clicked update ");

    this.commonService.updateTaskMaster(obj).subscribe(
      data => {
        this.alertmessages("Successfully Updated ", "success");
        // if (this.status != 'Assigned') {
        // this.closeReassignTask();
        // }
        this.clear();
        this.viewTaskButton();
      }, error => {
        this.alertmessages("Try Again Later", "danger");
      }
    );

  }

  viewTaskButton() {
    this.clear();
    this.addOrUpdate = true;
    this.add_view_toggle = 'viewTask';
    this.getTask();
  }

  saveTask() {

    const obj = {
      title: this.taskName.trim(),
      description: this.taskDesc,
      comment: this.taskComment,
      routeid: this.routeName,
    }

    if (obj.title == undefined || obj.title == null || obj.title == '') {
      this.alertmessages('Please Enter Title', 'danger');
      return
    }

    if (obj.routeid == undefined || obj.routeid == null || obj.routeid == '') {
      this.alertmessages('Please Select Route Name', 'danger');
      return
    }

    console.log(obj);

    console.log("clicked save");

    this.commonService.insertTaskMaster(obj).subscribe(
      data => {
        this.alertmessages("Successfully Saved ", "success");
        this.clear();
        this.viewTaskButton();
      }, error => {
        this.alertmessages("Try Again Later", "danger");
      }
    );

  }

  clear() {
    this.taskid = undefined;
    this.taskName = '';
    this.taskDesc = '';
    this.taskComment = '';
    // this.dueDate = undefined;
    this.query = '';
    this.employeeid = '';
    this.status = '';
    this.routeName = '';
  }

  getEmployeeByNameOrId(info) {
    console.log('check info ', info);

    const [name, id] = this.query.split(' / ');
    this.queryName = name;
    this.employeeid = id; // Store the ID if it's available

    console.log("Len ", this.query);
    console.log('array ', this.employeeMasterLucene);


    if (this.queryName.length <= 2) {
      this.employeeMasterLucene = [];
    }

    if (this.queryName.length >= 3) {
      this.empLeaveService.getEmployeeByNameOrUniqueId(this.queryName).subscribe(
        data => {
          console.log("Emp Data ", data.employeeMasterData);
          this.employeeMasterLucene = data.employeeMasterData;
        },
        error => {
          console.log("Error ", error);
        }
      );
    }
  }

  deleteTask() {
    this.taskid;
    console.log("deleteId", this.taskid);
    $('#showAlertVer').modal('toggle');
    this.commonService.deleteTask(this.taskid).subscribe(
      (data) => {
        this.getTask();
        this.alertmessages(data.successMessages[0]['key'], "success")
      }, (error) => {
        console.log(error)
        this.alertmessages("Task cannot be deleted as it's in use", "danger");
      }
    );

  }

  closeTask() {
    $('#showAlertVer').modal('toggle');
  }

  editTask(info: any) {

    console.log(info);
    this.taskid = info.id;
    this.taskName = info.title;
    this.taskDesc = info.description;
    this.taskComment = info.comment;
    this.query = info.employeeName;
    this.queryName = info.employeeName;
    this.employeeid = info.employeeid;
    this.routeName = info.routeid;
    console.log(info.routeid);
    console.log(info.routeName);
    // this.dueDate = info.duedate;
    this.taskComment = info.comment;
    this.status = info.status;
    this.add_view_toggle = 'addTask';
    this.addOrUpdate = false;
  }

  onDeleteHandler(id: any) {
    $('#showAlertVer').modal('toggle');
    this.taskid = id;
  }

  getTask() {

    this.commonService.getTaskMaster().subscribe(
      data => {
        this.clear();
        this.taskMasterDetail = data.taskMaster;
      }, error => {
        this.taskMasterDetail = [];
      }
    );

  }

  alertmessages(message: string, sign: string): void {
    let alertDiv = document.createElement('div');
    alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
    <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message} </div>`
    let alertcomp = document.querySelector('.alertcomp');
    alertcomp.append(alertDiv);
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    setTimeout(function () {
      alertcomp.removeChild(alertDiv);
    }, 3000);
  }

  // getRoute() {
  //   console.log("get Route ");
  //   this.commonService.getRoute().subscribe(
  //     data => {
  //       this.routeDetail = data.routeMaster;
  //       this.routeName = '';
  //     }, error => {
  //       this.routeDetail = [];
  //     }
  //   );
  // }

  addRoute() {
    $('#showAddRoute').modal('toggle');
  }

  saveRoute() {

    if (this.routeName == undefined || this.routeName == null || this.routeName == '') {
      this.alertmessages('Please Select Route Name', 'danger');
      return
    }

    this.commonService.insertRouteMaster(this.routeName).subscribe(
      data => {
        console.log("insert route ", data);

        this.closeAddRoute()
        // this.alertmessages("Successfully Saved ", "success");
        this.getRoute();
      }, error => {
        this.alertmessages("Try Again Later", "danger");
      }
    );

  }

  closeAddRoute() {
    $('#showAddRoute').modal('toggle');
  }

  editRoute() {
    $('#showEdit').modal('toggle');
  }

  closeUpdateRoute() {
    $('#showEdit').modal('toggle');
  }

  UpdateRoute() {

    const obj = {
      routename: this.editRouteName,
      id: this.routeName
    }

    if (obj.routename == undefined || obj.routename == null || obj.routename == '') {
      this.alertmessages('Please Enter Route Name', 'danger');
      return
    }

    this.commonService.updateRouteMaster(obj).subscribe(
      data => {
        this.closeUpdateRoute();
        this.alertmessages("Successfully Updated ", "success");
        this.getRoute();
      }, error => {
        this.alertmessages("Try Again Later", "danger");
      }
    );

  }

  deleteRouteHandler() {
    $('#deleteRoute').modal('toggle');
  }

  deleteRoute() {
    console.log("delete ", this.routeName);

    if (this.routeName != '' || this.routeName != undefined) {
      this.commonService.deleteRoute(this.routeName).subscribe(
        (data) => {
          this.getRoute();
          $('#deleteRoute').modal('toggle');
          this.alertmessages(data.successMessages[0]['key'], "success")
        }, (error) => {
          console.log(error)
          $('#deleteRoute').modal('toggle');
          this.alertmessages("Route cannot be deleted as it's in use", "danger");
        }
      );
    }
  }

  closeDelRoute() {
    $('#showAlertVer').modal('toggle');
  }


  getRoute() {
    console.log("Fetching Routes...");
    this.commonService.getRoute().subscribe(
      data => {
        this.routeDetail = data.routeMaster;
        console.log(" this.routeName ", this.routeName);
         // Only auto-select if a new route was just added
      if (this.routeName) {
        const newlyAddedRoute = this.routeDetail.find(route => route.routename === this.routeName);

        if (newlyAddedRoute) {
          this.routeName = newlyAddedRoute.id;  // Set newly added route as selected
          console.log("this.routeName:", this.routeName);
          
        }
      }
      
      }, 
      error => {
        this.routeDetail = [];
      }
    );
  }

}
