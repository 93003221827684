<div class="app-content main-content">
    <div class="side-app main-container">
        <div class="alertcomp"></div>
        <!-- <div class="card-body px-0">
            <div class="page-leftheader my-3">
                <div class="page-title">Job Level Master</div>
            </div>
        </div> -->
        <div class="row">
            <div class="col-xl-12 col-md-12 col-lg-12">

                <!-- <form id="fcForm" class="form-horizontal form-label-left row" #jobMasterForm="ngForm"> -->
                <div class="card">
                    <div class="card-body row">
                        <!-- job leave code  -->
                        <div class=" row col-md-6 col-sm-6 col-xs-6">
                            <div class="form-group label-floating">
                                <label class="form-label">Enter Job Level Name</label>
                                <div>
                                    <input type="text" name="annual_name" class="form-control col-md-12 col-xs-12"
                                        autocomplete="off" placeholder="Enter Level Name" autofocus="true"
                                        [(ngModel)]="jobLevelName" #jobName="ngModel" required
                                        [ngClass]="(!jobName.valid && jobName.touched ) ? 'is-invalid ' : ''" />
                                </div>
                                <span class="help-text text-danger" *ngIf="(!jobName.valid && jobName.touched)">
                                    Enter Job Level Name
                                </span>
                            </div>
                            <!-- <div class="form-group label-floating px-5">
                                <label class="form-label">Add New Job Level</label>
                                <div>
                                    <input type="text" name="Job Level" class="form-control col-md-12 col-xs-12"
                                        autocomplete="off" placeholder="Enter Job Level Code" autofocus="true"
                                        [(ngModel)]="jobLevelCode" #jobcode="ngModel"
                                        [ngClass]="(!jobcode.valid && jobcode.touched )? 'is-invalid': '' " />
                                </div>
                                <span class="help-text text-danger" *ngIf="(!jobcode.valid && jobcode.touched)">Enter
                                    Job Level Code
                                </span>
                            </div> -->
                        </div>

                        <!-- button flag  -->
                        <div class="col-md-6 col-sm-6 col-xs-6 justify-content-between align-items-start">
                            <div class="form-group label-floating">
                                <label class="form-label">&nbsp;</label>
                                <button *ngIf="addOrUpdate;else updateButton" type="button" id="navigatetarget"
                                    class="btn btn-primary w-40" style="margin-right: 2vw;margin-left: 2vw;"
                                    (click)="addJobButton()">
                                    Add
                                </button>
                                <ng-template #updateButton>
                                    <button type="button" id="navigatetarget" class="btn btn-primary w-40"
                                        style="margin-right: 2vw;margin-left: 2vw;" (click)="updateJobButton()">
                                        Update
                                    </button>
                                </ng-template>
                                <button type="button" class="btn btn-danger w-40"
                                    style="margin-right:1vw;margin-left:1vw" (click)="viewJobButton()">
                                    View
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="clearfix"> </div>

                <div class="empty-div-one" id="navigatedest"></div>

                <!-- </form> -->
                <div class="card" *ngIf="add_view_toggle == 'addJobLevel'">
                    <div class="card-body">

                        <div class="row col-md-12 col-sm-12 col-xs-12">

                            <!--Job Code  -->
                            <!-- <div class=" row col-md-12 col-sm-12 col-xs-12"> -->
                            <div class="col-md-6 col-sm-6 col-xs-6 p-0 m-0">
                                <div class="form-group label-floating">
                                    <label class="form-label">Job Level Name</label>
                                    <div>
                                        <input type="text" name="annual_name" class="form-control col-md-12 col-xs-12"
                                            autocomplete="off" placeholder="Enter Level Name" autofocus="true"
                                            [(ngModel)]="jobLevelName" #jobName="ngModel" required
                                            [ngClass]="(!jobName.valid && jobName.touched ) ? 'is-invalid ' : ''" />
                                    </div>
                                    <span class="help-text text-danger" *ngIf="(!jobName.valid && jobName.touched)">
                                        Enter Job Level Name
                                    </span>
                                </div>

                            </div>

                            <div class="col-md-6 col-sm-6 col-xs-6">
                                <div class="form-group label-floating">
                                    <label class="form-label">Job Level Code</label>
                                    <div>
                                        <input type="text" name="Job Code" class="form-control col-md-12 col-xs-12"
                                            autocomplete="off" placeholder="Enter Job Code" autofocus="true"
                                            [(ngModel)]="jobLevelCode" required #jobcode="ngModel"
                                            [ngClass]="(!jobcode.valid && jobcode.touched) ? 'is-invalid':''" />
                                    </div>
                                    <span class="help-text text-danger" *ngIf="(!jobcode.valid && jobcode.touched )">
                                        Enter Job Level Code
                                    </span>
                                </div>
                            </div>

                            <!-- </div> -->
                            <div class="clearfix"> </div>

                            <div class="col-md-6 col-sm-6 col-xs-6 p-0 m-0">
                                <div class="form-group label-floating">
                                    <label class="form-label">Job Level Hierarchy</label>
                                    <div>
                                        <input type="number" name="hierarchy" class="form-control col-md-12 col-xs-12"
                                            autocomplete="off" placeholder="Enter Job Level Hierarchy" autofocus="true"
                                            [(ngModel)]="jobHierarchy" #jobhier="ngModel" required
                                            [ngClass]="(!jobhier.valid && jobhier.touched) ? 'is-invalid' : '' " />
                                    </div>
                                    <span class="help-text text-danger" *ngIf="(!jobhier.valid && jobhier.touched)">
                                        Enter Job Level Hierarchy
                                    </span>
                                </div>
                            </div>

                            <!-- save / update  -->
                            <!-- <div class="actionBar pe-4 ps-4"> -->
                            <!-- <div class="clearfix"> </div>
                                <button class="btn btn-success" type="submit"
                                    *ngIf="addOrUpdate ; else saveupdateButton" (click)="saveAddJob()">Save</button>
                                <button   *ngIf="addOrUpdate ; else saveupdateButton"  class="btn btn-danger ms-2 " type="clear" (click)=" clear()">Clear</button>
                            </div>
                            <ng-template #saveupdateButton>
                                <div class="actionBar pe-4 ps-4">
                                </div>
                            </ng-template> -->
                        </div>
                    </div>
                </div>

                <div class="actionBar pe-4 ps-4" *ngIf="add_view_toggle == 'addJobLevel'">
                    <button class="btn btn-success" type="submit" *ngIf="addOrUpdate"
                        (click)="saveAddJob()">Save</button>
                    <button *ngIf="addOrUpdate" class="btn btn-danger ms-2 " type="clear"
                        (click)=" clear()">Clear</button>
                </div>

                <!-- view job list  -->

                <div class="row card-list-margin">
                    <div class="col-md-12 card-list1-margin">
                        <div class="card" *ngIf="add_view_toggle == 'viewJobLevel'">
                            <div class="card-body left_padding2">
                                <div class="col-md-12 col-sm-12 col-xs-12 left_padding1">
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <div class="card-header border-bottom-0" style="margin-top:-12px;">
                                            <h3 class="card-title"> List of Job Level</h3>
                                        </div>
                                        <div class="card-body card1S p-0">
                                            <div class="table-responsive">
                                                <div class="scrollsearchresults" id="scroll" infiniteScroll
                                                    [infiniteScrollDistance]="2" [infiniteScrollUpDistance]="1.5"
                                                    [infiniteScrollThrottle]="100" [scrollWindow]="false"
                                                    id="scrolllength">
                                                    <table
                                                        class="table table-hover table-vcenter text-nowrap table-bordered border-bottom"
                                                        id="hr-table">
                                                        <thead class="tablerow">
                                                            <tr class="rowcolors stickyatTop">
                                                                <th class="border-bottom-0 w-5 fs-6 ">#</th>
                                                                <th class="border-bottom-0 w-5 fs-6 ">Job Level Code
                                                                </th>
                                                                <th class="border-bottom-0 w-5 fs-6 ">Job Role Name
                                                                </th>
                                                                <th class="border-bottom-0 w-5 fs-6 ">Job level Hierarchy 
                                                                </th>
                                                                <th class="border-bottom-0 w-5 fs-6 ">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody style="max-height: 50vh; overflow: scroll;">
                                                            <tr *ngFor="let jd of jobMasterDetail; let i = index">
                                                                <td style="text-align: right;">{{i+1}}</td>
                                                                <td>{{jd.joblevel}}</td>
                                                                <td>{{jd.roledescription}}</td>
                                                                <td>{{jd.hierarchy}}</td>
                                                                <td>
                                                                    <button class="btn btn-primary btn-icon btn-sm me-2"
                                                                        data-method="edit" title="Edit"
                                                                        (click)="editJoblevel(jd)">
                                                                        <i class="fa fa-edit" data-bs-toggle="tooltip"
                                                                            data-original-title="edit"></i>
                                                                    </button>

                                                                    <button class="btn btn-danger btn-icon btn-sm me-2"
                                                                        data-method="delete" title="Delete"
                                                                        (click)="onDeleteHandler(jd)"
                                                                        data-bs-target="#showAlertWarning">
                                                                        <i class="fa fa-trash" data-bs-toggle="tooltip"
                                                                            data-original-title="delete"></i>
                                                                    </button>
                                                                     
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<div id="showAlertVer" class="modal" style="top: 40px;">
    <div class="modal-dialog">
        <div class="modal-content animate">
            <div class="modal-header">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">×</button>
                <h4 class="modal-title">Warning</h4>
            </div>

            <div class="modal-body" style="max-height: calc(100vh - 210px);overflow-y: auto;">
                <!-- auto scroll -->
                <span><b> Job Level Master data will be deleted permanently. Are you sure you want to continue?</b></span>
            </div>

            <div class="modal-footer" >
                <button type="button" class="btn btn-danger medium" (click)="deleteJob()">OK</button>
                <button type="button" class="btn btn-primary" (click)="closeMRQModal()">Cancel</button>
            </div>

        </div>
    </div>
</div>