<div class="app-content main-content">
    <div class="side-app main-container main-container-margin">
        <div class="alertcomp">

        </div>
        <!--Page header-->
        <!-- <div class="page-header d-xl-flex d-block">
             <div class="page-leftheader">
                <div class="page-title">Leave Approval</div>
            </div> -->
        <!-- <div class="page-rightheader ms-md-auto">
                <div class="d-flex align-items-end flex-wrap my-auto end-content breadcrumb-end">
                    <div class="btn-list">
                        <button  class="btn btn-light" data-bs-toggle="tooltip" data-bs-placement="top" title="E-mail"> <i class="feather feather-mail"></i> </button>
                        <button  class="btn btn-light" data-bs-placement="top" data-bs-toggle="tooltip" title="Contact"> <i class="feather feather-phone-call"></i> </button>
                        <button  class="btn btn-primary" data-bs-placement="top" data-bs-toggle="tooltip" title="Info"> <i class="feather feather-info"></i> </button>
                    </div>
                </div>
            </div> -->
        <!-- </div> -->
        <!--End Page header-->


        <!-- <div class="row">
            <div class="col-xl-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <form class="form-horizontal" id="fcForm">
                            <div class="form-group row">
                                <label for="employeename"
                                    class="w-100  max-content fontSize-15 form-label my-auto mb-0 ps-0 ">Employee
                                    Name</label>
                                <div class=" col-xs-12 col-md-5 px-0 me-md-3">
                                    <input type="text" class="form-control " name="employeename" id="employeename"
                                        [(ngModel)]="searchleave" autocomplete="off" (input)="chkInput($event)"
                                        autofocus="autofocus" placeholder="Search Employee Name">
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </div>
        </div> -->


        <div class="row">
            <div class="col-xl-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="card-body">

                        <form id="fcForm" class="form-horizontal form-label-left">
                            <form class="form-horizontal card-body row label-floating px-4" id="fcForm"
                                (keyup.enter)="search()">

                                <!-- <div class="col-md-6 col-sm-12 col-xs-12 mb-4">
                                    <label class=" p-0 form-label col-xl-6 col-md-6 col-lg-6">Employee Name / ID
                                    </label>
                                    <div class="form-group m-0">
                                        <div>
                                            <input type="text" class="form-control " name="employeename"
                                                id="employeename" [(ngModel)]="searchleave" autocomplete="off"
                                                (input)="chkInput($event)" autofocus="autofocus"
                                                placeholder="Search Employee Name">
                                        </div>

                                    </div>
                                </div> -->

                                <div class="col-md-4 col-sm-12 col-xs-12 mb-4 mt-1">
                                    <label class="form-label">Employee Name / ID</label>
                                    <input type="search" class="form-control" name="Employee Name" id="employeename"
                                        (input)="chkInput($event)" placeholder="Enter Employee Name / ID"
                                        [(ngModel)]="query" autocomplete="off" (input)="getEmployeeByNameOrId()"
                                        list="employeeMasterLucene">
                                    <datalist id="employeeMasterLucene">
                                        <option data-value="{{emp.name}}" *ngFor="let emp of employeeMasterLucene">
                                            {{emp.employeeid}}
                                        </option>
                                    </datalist>
                                </div>

                                   <!-- Active/Inactive Employee Status filter for Lucene Search -->
                                <div class="col-md-3 col-sm-12 col-xs-12 mb-4 mt-1">
                                    <label class="form-label">Employee Status</label>
                                    <select class="form-control" style="width: 100%;" required [(ngModel)]="ActiveInativeEmpStatusFilter" 
                                        name="selValu" (change)="onStatusChange()"
                                        [disabled]="!showBasedOnPermissionEmployeeStatus">
                                        <option value="All">All</option>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                    </select>
                                </div>

                                <div class="col-md-3 col-sm-4 col-xs-12 mb-4">
                                    <label class="form-label">Leave Status</label>
                                    <div class="form-group m-0">
                                        <div>
                                            <div>
                                                <select id="leaveStatusFilters" class="form-control" name="dateFilter"
                                                    [(ngModel)]="statusFilter">
                                                    <option value="all">All</option>
                                                    <option value="Leave Approve Pending">Leave Approve Pending</option>
                                                    <option value="Leave Cancel Pending">Leave Cancel Pending</option>
                                                    <option value="Partial Approve 1">Partial Approve 1</option>
                                                    <option value="Partial Approve 2">Partial Approve 2</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Apply For Dropdown -->
                                <div class="col-md-3 col-sm-4 col-xs-12 mb-4">
                                    <label class="form-label">Type</label>
                                    <select class="form-control" type="text" style="width: 100%;" required
                                        [(ngModel)]="selectedValue" name="selVal"  (change)="onTypeChange($event)">
                                        <option value="Detailed">Detailed</option>
                                        <option value="Consolidated">Consolidated</option>
                                    </select>
                                </div>
                            

                            <div class="col-md-3 col-sm-4 col-xs-12 mb-4 mt-1">
                                    <label class="form-label col-xl-2 col-md-2 col-lg-2 m-0 p-0 ">&nbsp;</label>
                                    <button type="button" id="navigatetarget" class="btn btn-primary  w-100"
                                        (click)="search()">
                                        <i class="fa fa-search p-0" aria-hidden="true"></i> Search
                                    </button>
                                </div>

                            </form>
                        </form>
                    </div>
                </div>
            </div>
        </div>


        <div class="empty-div-one" id="navigatedest"></div>
        <!-- Row -->
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header d-flex justify-content-between align-items-center  border-0">
                        <h4 class="card-title">Leave Summary</h4>
                        <span [routerLink]="['/employeeleavestatusreport']" class="link-text"
                            [routerLinkActive]="['highlightActiveLink']"
                            (click)="setMenuName('Leave Status Report');navigateLeaveStatus()"
                            [ngClass]="{'disable-div': !allowToViewReport}" *ngIf="allowToViewReport">Leave
                            Status Report</span>
                    </div>
                    <div class="card-body">
                        <div class="col-xs-12 mobile view">
                            <form action="#">
                                <div class="table-responsive">
                                    <div class="scrollsearchresults" infiniteScroll [infiniteScrollDistance]="2"
                                        [infiniteScrollUpDistance]="1.5" [infiniteScrollThrottle]="100"
                                        [scrollWindow]="false" (scrolled)="onScroll()" id="scrolllength">
                                        <table  *ngIf="selectedValue === 'Detailed'" class="table  table-vcenter text-nowrap table-bordered border-bottom"
                                            id="hr-leaves">
                                            <thead class="tablerow">
                                                <tr class="rowcolors stickyatTop ">
                                                    <th class="secondcolumn fixedcolumn"><input type="checkbox"
                                                            style="cursor: pointer;"
                                                            (change)="checkAllSelected($event)" /></th>
                                                    <th class="border-bottom-0 w-5 fixedcolumn">Employee ID</th>
                                                    <th class="border-bottom-0 w-5 secondcolumn"> Employee Name</th>
                                                    <th class="border-bottom-0">Leave Type</th>
                                                    <!-- <th class="border-bottom-0">Balance</th> -->
                                                    <th class="border-bottom-0">Leave Date</th>
                                                    <th class="border-bottom-0">Day</th>
                                                    <th class="border-bottom-0">Reason</th>
                                                    <th class="border-bottom-0">Status</th>
                                                    <th class="border-bottom-0">Attachment</th>
                                                    <th class="border-bottom-0">Applied on</th>
                                                    <th class="border-bottom-0">Remark</th>
                                                    <th class="border-bottom-0">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody *ngIf="showTable">

                                                <!-- <tr
                                                    *ngFor="let leave of taskDetailsForLeave | approveLeave:searchleave ; ">
                                                    <td class=" secondcolumn fixedcolumn backgroundFW"><input
                                                            type="checkbox" style="cursor: pointer;"
                                                            (change)="checkSelected(leave,$event)" /></td>
                                                    <td class="fixedcolumn backgroundFW">
                                                        {{leave.employeeLeaveApplied.employeeId}}</td>
                                                    <td class="secondcolumn backgroundSW">
                                                        <div class="d-flex">
                                                            <div class="me-3 mt-0 mt-sm-2 d-block">
                                                                <h6 class="mb-1 fs-14">{{leave.employeeName}}</h6>
                                                            </div>
                                                        </div>
                                                    </td>
                                                  
                                                    <td>{{leave.employeeLeaveApplied.leaveName}}</td>
                                                    <td style="text-align: center;">
                                                        {{leave.employeeLeaveCarryForward.leavecarried}}</td>
                                                    <td>{{leave.employeeLeaveApplied.leaveDate | date}}</td>
                                                    <td class="font-weight-semibold">
                                                        {{leave.employeeLeaveApplied.halfFullIndic === "F" ? "Full Day"
                                                        :
                                                        "Half Day"}}</td>
                                                    <td class="text-wrap" style="min-width:10vw;max-width: 12vw;">
                                                        {{leave.employeeLeaveApplied.reason}}</td>
                                                    <td>
                                                        <span style="font-size: 13px; font-weight: 500;" [ngClass]="
                                                            leave.employeeLeaveApplied.leaveStatus === 'Leave Cancel Pending' ? 'badge-warning text-custom-dark' :
                                                            leave.employeeLeaveApplied.leaveStatus === 'APPROVED' ? 'bg-custom-green text-custom-dark' :
                                                            leave.employeeLeaveApplied.leaveStatus === 'REJECTED' ? 'bg-custom-red text-custom-dark' :
                                                            'bg-custom-warning text-custom-dark'
                                                          " class="badge">
                                                            {{ leave.employeeLeaveApplied.leaveStatus }}
                                                        </span>
                                                    </td>

                                                    <td>{{leave.employeeLeaveApplied.appliedDate | date}}</td>
                                                    <td><input autocomplete="off" type="text" style="min-width: 9.7rem;"
                                                            name="remark" class="form-control"
                                                            [(ngModel)]="leave.employeeLeaveApplied.remark"
                                                            placeholder="Comments for Rejection"></td>
                                                </tr> -->

                                                <tr *ngFor="let leave of leaveDetailFlow">
                                                    <td class=" secondcolumn fixedcolumn backgroundFW"><input
                                                            type="checkbox" style="cursor: pointer;"
                                                            (change)="checkSelected(leave,$event)" /></td>
                                                    <td class="fixedcolumn backgroundFW">
                                                        {{leave.employeeLeaveApplied.employeeId}}</td>
                                                    <td class="secondcolumn backgroundSW">
                                                        <div class="d-flex">
                                                            <div class="me-3 mt-0 mt-sm-2 d-block">
                                                                <h6 class="mb-1 fs-14">{{leave.name}}</h6>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>{{leave.employeeLeaveApplied.leaveName}}</td>
                                                    <!-- <td style="text-align: center;">
                                                        {{leave.employeeLeaveCarryForward.leavecarried}}</td> -->
                                                    <td>{{leave.employeeLeaveApplied.leaveDate| date: 'mediumDate'}}
                                                    </td>
                                                    <td class="font-weight-semibold">
                                                        {{leave.employeeLeaveApplied.halfFullIndic === "F" ? "Full Day"
                                                        :
                                                        "Half Day"}}</td>
                                                        
                                                    <td class="text-wrap" style="min-width:10vw;max-width: 12vw;">
                                                        {{leave.employeeLeaveApplied.reason}}</td>
                                                    <td>
                                                        
                                                        <span style="font-size: 13px; font-weight: 500;" [ngClass]="
                                                            leave.employeeLeaveApplied.leaveStatus === 'Leave Cancel Pending' ? 'bg-custom-warning2 text-custom-dark' :
                                                            leave.employeeLeaveApplied.leaveStatus === 'Approved' ? 'bg-custom-green text-custom-dark' :
                                                            leave.employeeLeaveApplied.leaveStatus === 'Rejected' ? 'bg-custom-red text-custom-dark' :
                                                            leave.employeeLeaveApplied.leaveStatus === 'Partial Approve 1' ? 'bg-custom-orange1 text-custom-dark' :
                                                            leave.employeeLeaveApplied.leaveStatus === 'Partial Approve 2' ? 'bg-custom-orange2 text-custom-dark' :
                                                            'bg-custom-warning text-custom-dark'
                                                          " class="badge">
                                                            {{ leave.employeeLeaveApplied.leaveStatus }}
                                                        </span>
                                                    </td>
                                                    <td>{{leave.employeeLeaveApplied.fileName}}
                                                    <td>{{leave.employeeLeaveApplied.appliedDate| date: 'mediumDate'}}
                                                    </td>
                                                    <td><input autocomplete="off" type="text" style="min-width: 9.7rem;"
                                                            name="remark" class="form-control"
                                                            [(ngModel)]="leave.employeeLeaveApplied.remark"
                                                            placeholder="Comments for Rejection"></td>
                                                    <td>
                                                        <button class="btn btn-primary btn-icon btn-sm me-2"
                                                        (click)="viewdocument(leave.employeeLeaveApplied.fileName, leave.employeeLeaveApplied.filePath)" style="width: 32px;">
                                                       <i class="fa fa-eye" data-bs-toggle="tooltip" data-original-title="download"></i>
                                                   </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    <!-- </div>
                                </div> -->

                                <table *ngIf="selectedValue === 'Consolidated'" class="table  table-vcenter text-nowrap table-bordered border-bottom"
                                id="hr-leaves">
                                <thead class="tablerow">
                                    <tr class="rowcolors stickyatTop ">
                                        <th class="secondcolumn fixedcolumn"><input type="checkbox"
                                            style="cursor: pointer;"
                                            (change)="checkAllSelected($event)" /></th>
                                            <th class="border-bottom-0 w-5 fixedcolumn">Employee ID</th>
                                            <th class="border-bottom-0 w-5 secondcolumn">Employee Name</th>
                                            <th class="border-bottom-0">Leave Type</th>
                                            <th class="border-bottom-0">From Date</th>
                                            <th class="border-bottom-0">To Date</th>
                                            <th class="border-bottom-0">No.of Leaves</th>
                                            <th class="border-bottom-0">Day</th>
                                            <th class="border-bottom-0">Reason</th>
                                            <th class="border-bottom-0">Status</th>
                                            <th class="border-bottom-0">Attachment</th>
                                            <th class="border-bottom-0">Remarks</th>
                                            <th class="border-bottom-0">Action</th>
                                           
                                        </tr>
                                    </thead>
                                    <tbody>
                                
                                        <tr *ngFor="let leave of leaveDetailFlow">
                                            <td class=" secondcolumn fixedcolumn backgroundFW"><input
                                                type="checkbox" style="cursor: pointer;"
                                                (change)="checkSelected(leave,$event)" /></td>
                                            <td class="fixedcolumn backgroundFW">{{leave.employeeLeaveApplied.employeeId}}</td>
                                            <td class="secondcolumn backgroundSW">{{leave.name}}</td>
                                            <td>{{leave.employeeLeaveApplied.leaveName}}</td>
                                            <td>{{leave.employeeLeaveApplied.leaveDate | date: 'mediumDate'}}</td>
                                            <td>{{leave.employeeLeaveApplied.toDate | date: 'mediumDate'}}</td>
                                            <td>{{leave.employeeLeaveApplied.noOfDaysLeaveApplied}}</td>
                                            <td class="font-weight-semibold">
                                                {{leave.employeeLeaveApplied.halfFullIndic === "F" ? "Full Day"
                                                :
                                                "Half Day"}}</td>
                                            <td>{{leave.employeeLeaveApplied.reason}}</td>
                                            <td>
                                                        
                                                <span style="font-size: 13px; font-weight: 500;" [ngClass]="
                                                    leave.employeeLeaveApplied.leaveStatus === 'Leave Cancel Pending' ? 'bg-custom-warning2 text-custom-dark' :
                                                    leave.employeeLeaveApplied.leaveStatus === 'Approved' ? 'bg-custom-green text-custom-dark' :
                                                    leave.employeeLeaveApplied.leaveStatus === 'Rejected' ? 'bg-custom-red text-custom-dark' :
                                                    leave.employeeLeaveApplied.leaveStatus === 'Partial Approve 1' ? 'bg-custom-orange1 text-custom-dark' :
                                                    leave.employeeLeaveApplied.leaveStatus === 'Partial Approve 2' ? 'bg-custom-orange2 text-custom-dark' :
                                                    'bg-custom-warning text-custom-dark'
                                                  " class="badge">
                                                    {{ leave.employeeLeaveApplied.leaveStatus }}
                                                </span>
                                            </td>
                                            <td>{{leave.employeeLeaveApplied.fileName}}</td>
                                            <td><input autocomplete="off" type="text" style="min-width: 9.7rem;"
                                                name="remark" class="form-control"
                                                [(ngModel)]="leave.employeeLeaveApplied.remark"
                                                placeholder="Comments for Rejection"></td>
                                        <td>
                                            <button class="btn btn-primary btn-icon btn-sm me-2"
                                            (click)="viewdocument(leave.employeeLeaveApplied.fileName, leave.employeeLeaveApplied.filePath)" style="width: 32px;">
                                           <i class="fa fa-eye" data-bs-toggle="tooltip" data-original-title="download"></i>
                                       </button>
                                        </td>
                                        </tr>
                                    </tbody>
                                </table>

                                    </div>
                                    </div>

                                <div *ngIf="showTable" class="pro-fab btn-float-right">
                                    <button type="button" (click)="onApproveHandler()"
                                        class="btn btn-success">Approve</button>
                                    <button type="button" (click)="onRejectHandler()"
                                        class="btn btn-danger ms-2">Reject</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Row-->

    </div><!-- end app-content-->
</div>