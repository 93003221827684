import { Component, OnInit, ViewChild } from '@angular/core';
import { ExpensemanagerService } from '../services/expensemanager.service';
import { savingsmaster } from '../models/savingsmaster';
import { NgForm } from '@angular/forms';
declare var $:any
@Component({
  selector: 'app-savings-master',
  templateUrl: './savings-master.component.html',
  styleUrls: ['./savings-master.component.css']
})
export class SavingsMasterComponent implements OnInit {
  savingsMaster: savingsmaster = new savingsmaster();
  id: number;
  name: any;
  saving: number;
  maxamountmale: number;
  maxamountfemale: number;
  ishracalc:false;
  isapplyforall:false;
  savingsmaster: any[] = [];
  addOrUpdate: boolean = true;
  ISNEWREGIMEAPPLICABLE:Boolean=false;
  ISOLDREGIMEAPPLICABLE:Boolean=false;

  constructor(private expensemanagerService: ExpensemanagerService) { }
  @ViewChild('savingsmasterform', { static: false }) savingmaster: NgForm;
  ngOnInit(): void {
    this.getSavingsmasterDetails();

  }
  getSavingsmasterDetails() {
    this.expensemanagerService.getSavingsmasterDetails().subscribe(
      data => {
        console.log("List of saving master :", data)
        console.log("saving master", data);
        this.savingsmaster = data.savingsMaster_info;
      }, error => {
        console.log(error);
      }
    );
  }

  addsavingsmaster() {
    this.savingmaster.form.markAllAsTouched();
    if (this.savingmaster.invalid) {
        // Handle invalid form submission
        return;
    }

    // Trim and validate name field
    this.savingsMaster.name = this.name.trim();
    
    // Check if the trimmed name is empty
    if (this.savingsMaster.name.length === 0) {
        this.alertmessages("Name field cannot be empty or just spaces", "danger");
        return;
    }
    console.log("this.ishracalc",this.ishracalc)
    if(this.ishracalc===undefined || this.ishracalc ===null) {
      console.log("this.ishracalcInside",this.ishracalc)
      this.savingsMaster.ishracalc = false;
      console.log("this.savingsMaster.ishracalc",this.savingsMaster.ishracalc)
    }else{
       this.savingsMaster.ishracalc = this.ishracalc;
    }


  console.log("this.savingsMaster.isapplyforall",this.savingsMaster.isapplyforall)
    if(this.isapplyforall===undefined || this.ishracalc ===null) {
      this.savingsMaster.isapplyforall=false;
    }else{
      this.savingsMaster.isapplyforall = this.isapplyforall;
    }

    if(this.ISNEWREGIMEAPPLICABLE===undefined || this.ISNEWREGIMEAPPLICABLE ===null) {
      console.log("New regime", this.ISNEWREGIMEAPPLICABLE);
      this.savingsMaster.isnewregimeapplicable=false;
    }else{
      console.log("New regime", this.ISNEWREGIMEAPPLICABLE);
      this.savingsMaster.isnewregimeapplicable = this.ISNEWREGIMEAPPLICABLE;
    }

    if(this.ISOLDREGIMEAPPLICABLE===undefined || this.ISOLDREGIMEAPPLICABLE ===null) {
      console.log("Old regime", this.ISOLDREGIMEAPPLICABLE);
      this.savingsMaster.isoldregimeapplicable=false;
    }else{
      console.log("Old regime", this.ISOLDREGIMEAPPLICABLE);
      this.savingsMaster.isoldregimeapplicable = this.ISOLDREGIMEAPPLICABLE;
    }


    this.savingsMaster.saving = this.saving;
    this.savingsMaster.maxamountmale = this.maxamountmale;
    this.savingsMaster.maxamountfemale = this.maxamountfemale;
   
    console.log("---------------------------------------------- ", this.savingsMaster);

    // Validate other fields
    if (
        (this.name.length > 0) &&
        (this.saving != null || this.saving != undefined) &&
        (this.maxamountmale != null || this.maxamountmale != undefined) &&
        (this.maxamountfemale != null || this.maxamountfemale != undefined)
    ) {

      console.log("savingsMaster",this.savingsMaster,)
        this.expensemanagerService.saveSavingMaster(this.savingsMaster).subscribe(
            data => {
                console.log("data ", data);
                this.alertmessages("Successfully Saved", "success");
                this.clear();
                this.getSavingsmasterDetails();
            }, 
            error => {
                console.log(error);
                this.alertmessages("Please try again later", "danger");
            }
        );
    }
}


  clear() {
    //  this.savingsmaster = new ExtraTimeOut(-1,-1," ", " "," "," ", " ", " ", " ", " ");
    this.name = undefined;
    this.saving = undefined;
    this.maxamountmale = undefined;
    this.maxamountfemale = undefined;
    this.addOrUpdate = true;
    this.ishracalc=false;
    this.isapplyforall=false;
    this.savingmaster.reset();
  }
  deleteSavingMaster() {
    const id = this.id;
    console.log("delete Saving master : ", id)

    this.expensemanagerService.delete_savingsmaster(id).subscribe(
      data => {
        console.log("delete Saving master ", data)
        this.alertmessages("Successfully deleted ", "success")
        this.clear();
        this.getSavingsmasterDetails();
        $('#showAlertVer').modal('toggle');
      }, error => {
        $('#showAlertVer').modal('toggle');
        this.alertmessages(" Saving master is in Use ", "danger")
        console.log(error);
      }
    );

    this.clear();
  }
  onDeleteHandler(id) {
    // console.log("delete : ", info);
    this.id=id;
    $('#showAlertVer').modal('toggle');
  }





  editSavingmaster(info:any) {
    console.log("editSavingmaster : ", info)
    this.name = info.name;
    this.id = info.id;
    this.saving = info.saving;
    this.maxamountmale = info.maxamountmale;
    this.maxamountfemale = info.maxamountfemale;
    this.ishracalc = info.ishracalc;
    this.isapplyforall = info.isapplyforall;
    this.ISNEWREGIMEAPPLICABLE = info.isnewregimeapplicable;
    this.ISOLDREGIMEAPPLICABLE = info.isoldregimeapplicable;
    this.addOrUpdate = false;
  }

  updatesavingsmaster() {
    console.log("editSavingmaster : ")
    this.name = this.name.trim();
    this.saving = this.saving;
    this.maxamountmale = this.maxamountmale;
    this.maxamountfemale = this.maxamountfemale;
    this.savingsMaster.ishracalc = this.ishracalc;
    this.savingsMaster.isapplyforall = this.isapplyforall;
    this.savingsMaster.isnewregimeapplicable = this.ISNEWREGIMEAPPLICABLE;
    this.savingsMaster.isoldregimeapplicable = this.ISOLDREGIMEAPPLICABLE;

    console.log("After trim expense type: ", this.name, " :  length : ", this.name.length)
    if ((this.name.length !=null && this.name != undefined && this.name.length > 0) &&
    (this.saving != null || this.saving != undefined)&&
    (this.maxamountmale != null || this.maxamountmale != undefined)&&
    (this.maxamountfemale != null || this.maxamountfemale != undefined) ) {

      const updatedField = {
        id: this.id,
        name: this.name,
        saving: this.saving,
        maxamountmale: this.maxamountmale,
        maxamountfemale: this.maxamountfemale,
        ishracalc:this.ishracalc,
        isapplyforall:this.isapplyforall,
        isnewregimeapplicable:this.ISNEWREGIMEAPPLICABLE,
        isoldregimeapplicable:this.ISOLDREGIMEAPPLICABLE
      };

      this.expensemanagerService.edit_savingsmaster(updatedField).subscribe(
        data => {
          console.log("added Expense Type ", data)
          this.alertmessages("Successfully Updated ", "success")
          this.clear();
          this.getSavingsmasterDetails();
        }, error => {
          this.alertmessages("Please try again later", "danger")
          console.log(error);
        }
      );

      this.clear();
    }
  }
  closeMRQModal() {
    $('#showAlertVer').modal('toggle');

  }
  alertmessages(message: string, sign: string): void {
    let alertDiv = document.createElement('div');
    alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
      <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message} </div>`
    let alertcomp = document.querySelector('.alertcomp');
    alertcomp.append(alertDiv);
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    setTimeout(function () {
      alertcomp.removeChild(alertDiv);
    }, 3000);
  }


}
