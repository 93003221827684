<div class="app-content main-content">
    <div class="side-app main-container">
        <div class="alertcomp">
        </div>

        <!-- <div class="page-header d-xl-flex d-block">
            <div class="page-leftheader">
                <div class="page-title">Expense Approval</div>
            </div>
        </div> -->
        <div class="row">
            <div class="col-xl-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <form id="fcForm" class="form-horizontal form-label-left">
                            <form class="form-horizontal card-body row label-floating px-4" id="fcForm">


                                <div class="col-md-6 col-sm-12 col-xs-12">
                                    <div class="form-group label-floating">
                                        <label class="form-label"> Employee Name /
                                            ID</label>
                                            <input type="search" class="form-control " name="employeename"
                                            id="employeename" [(ngModel)]="query" autocomplete="off"
                                            (input)="getEmployeeByNameOrId()" list="employeeMaster"
                                            autofocus="autofocus"
                                            placeholder="Search Employee Name / ID">

                                        <datalist id="employeeMaster">
                                            <option [value]="emp.name + ' / ' + emp.employeeid"
                                                *ngFor="let emp of employeeMasterLucene">
                                                <!-- {{emp.name}} -->
                                            </option>
                                        </datalist>


                                    </div>
                                </div>
                                <!-- Active/Inactive Employee Status filter for Lucene Search -->
                                <div class="col-md-4 col-sm-12 col-xs-12">
                                    <label class="form-label">Employee Status</label>
                                    <select class="form-control" type="text" style="width: 100%;" required
                                        [(ngModel)]="ActiveInativeLuceneFilter" name="selVal"
                                        (change)="onStatusChange()" [disabled]="!showBasedOnPermissionEmployeeStatus">
                                        <option value="All">All</option>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                    </select>
                                </div>
                                <div class="col-md-2 col-sm-12 col-xs-12">
                                    <label class="form-label col-md-12 col-sm-4 col-xs-4 mb-1">&nbsp;</label>
                                    <button type="button" id="navigatetarget" class="btn btn-primary  wd w-100"
                                        (click)="getExpenseList()">
                                        <i class="fa fa-search p-0" aria-hidden="true"></i> Search
                                    </button>
                                </div>
                            </form>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <div class="empty-div-one" id="navigatedest"></div>
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header d-flex justify-content-between align-items-center  border-0">
                        <h4 class="card-title">Expense Summary</h4>
                        <span [routerLink]="['/expense-report']" class="link-text"
                            [routerLinkActive]="['highlightActiveLink']"
                            (click)="setMenuName('Expense Report');navigateExpenseStatus()"
                            [ngClass]="{'disable-div': !allowToViewReport}" *ngIf="allowToViewReport">View Expense
                            Report
                        </span>
                    </div>

                    <div class="card-body">
                        <form>
                            <div class="table-responsive">
                                <!-- <div class="scrollsearchresults" id="scrolllength" id="scroll" infiniteScroll
                                    [infiniteScrollDistance]="2" [infiniteScrollUpDistance]="1.5"
                                    [infiniteScrollThrottle]="100" [scrollWindow]="false" (scrolled)="onScroll()"> -->
                                    <div class="scrollsearchresults" infiniteScroll (scrolled)="onScroll()" id="scrolllength">
                                    <table
                                        class="table table-hover table-vcenter text-nowrap table-bordered border-bottom">
                                        <thead class="tablerow">
                                            <tr class="rowcolors stickyatTop">
                                                <th>
                                                    <input type="checkbox" [checked]="selectAll"
                                                        (change)="checkallbox($event)" style="cursor: pointer;" />
                                                </th>
                                                <th class="border-bottom-0 fs-7 fixedcolumn">ID</th>
                                                <th class="border-bottom-0 fs-7 secondcolumn ">Employee Name</th>
                                                <th class="border-bottom-0 w-5">Expense Name</th>
                                                <th class="border-bottom-0"> Date </th>
                                                <th class="border-bottom-0">Expense Type</th>
                                                <th class="border-bottom-0">Expense Amount</th>
                                                <th class="border-bottom-0">Approval Amount</th>
                                                <th class="border-bottom-0">Status</th>
                                                <th class="border-bottom-0">Attachment</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                        <tbody style="max-height: 50vh; overflow: scroll; padding-bottom: 10vh;">
                                            <tr *ngFor="let exp of filteredExpenseList; let i = index">
                                                <td>
                                                    <!-- <input class="unitaskbox" [checked]="exp.checked" 
                                                        (change)="check_info(exp ,$event)" type="checkbox"
                                                        style="cursor: pointer;" /> -->
                                                    <input class="secondcolumn fixedcolumn unitaskbox"
                                                        [checked]="exp.checked" [disabled]="exp.status === 'APPROVED'"
                                                        (change)="check_info(exp ,$event)" type="checkbox"
                                                        style="cursor: pointer;" />
                                                </td>
                                                <td class="fixedcolumn unitaskbox">{{ exp.uniqueempid }}</td>
                                                <td class="secondcolumn unitaskbox">{{ exp.name }}</td>
                                                <td>{{ exp.client_name }}</td>
                                                <td>{{ exp.date_of_expense| date: 'mediumDate'}}</td>
                                                <td>{{ exp.expenseName }}</td>

                                                <td style="text-align: right;">{{ exp.amount | number: '1.2-2' }}</td>
                                                <td style="min-width: 1rem;">
                                                    <!-- <input autocomplete="off" type="text" name="remark"
                                                        [(ngModel)]="exp.approvalamount" class="form-control"
                                                        [placeholder]="exp.amount | currency : 'INR' : 'symbol' : '1.2-2'"
                                                        [value]="exp.amount | currency : 'INR' : 'symbol' : '1.2-2'"> -->

                                                    <input autocomplete="off" type="text" name="remark"
                                                        [placeholder]="exp.amount | number: '1.2-2'"
                                                        [(ngModel)]="exp.approvalamount" class="form-control maxiiii"
                                                        [value]="exp.amount | number: '1.2-2'"
                                                        style="color: #000; opacity: 1; text-align: right">

                                                </td>

                                                
                                                <td>

                                                    <span style="font-size: 13px; font-weight: 500;"
                                                [ngClass]="exp.status === 'Approved' ? 'badge-success' : 
                                                exp.status === 'Partial Approve 1' ? 'bg-custom-orange1 text-custom-dark' : 
                                                exp.status === 'Partial Approve 2' ? 'bg-custom-orange2 text-custom-dark' : 
                                                exp.status === 'Expense Approve Pending' ? 'bg-custom-warning text-custom-dark' : 
                                                exp.status === 'Rejected' ? 'badge-danger' : 'bg-custom-warning text-custom-dark'"
                                                class="badge">{{exp.status}}</span>
                                                   <!--  <span *ngIf="exp.status === 'PARTIAL APPROVED 1'"
                                                        class="badge badge-warning">{{exp.status}}</span>
                                                    <span *ngIf="exp.status === 'PARTIAL APPROVED 2'"
                                                        class="badge badge-warning">{{exp.status}}</span>
                                                    <span *ngIf="exp.status === 'EXPENSE APPROVE PENDING'"
                                                        class="badge badge-warning">{{exp.status}}</span>
                                                    <span *ngIf="exp.status === 'APPROVED'"
                                                        class="badge badge-success">{{exp.status}}</span>
                                                    <span *ngIf="exp.status === 'REJECTED'"
                                                        class="badge badge-danger">{{exp.status}}</span> -->
                                                </td>
                                                <td><button class="btn btn-primary btn-icon btn-sm me-2"
                                                        (click)="myDocumentDownload(exp.attachment, exp.filename)">
                                                        <i class="fa fa-download" data-bs-toggle="tooltip"
                                                            data-original-title="download"></i>
                                                    </button>

                                                    <button class="btn btn-primary btn-icon btn-sm me-2"
                                                        (click)="viewdocument(exp.attachment, exp.filePath)"
                                                        style="width: 32px;">
                                                        <i class="fa fa-eye" data-bs-toggle="tooltip"
                                                            data-original-title="download"></i>
                                                    </button>

                                                    <button (click)="approved_details(exp.id)"
                                                        class="consolidatebtn consolidatebtn2 btn"
                                                        data-bs-toggle="modal" data-bs-target="#container_2">
                                                        <i class="mdi mdi-update" data-bs-toggle="tooltip"
                                                            aria-label="mdi-update"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <!-- <br><br> -->
                                    <div class="pro-fab btn-float-right">
                                        <!-- <a [routerLink]="['/expense-report']" class="btn btn-primary me-3"
                                            [routerLinkActive]="['highlightActiveLink']"
                                            (click)="setMenuName('Expense Report');navigateExpenseStatus()"
                                            [ngClass]="{'disable-div': !allowToViewReport}"
                                            *ngIf="allowToViewReport">Expense
                                            Report</a> -->
                                        <button type="button" class="btn btn-success"
                                            (click)="approvalhandler()">Approve</button>
                                        <button type="button" class="btn btn-danger ms-2"
                                            (click)="rejecthandler()">Reject</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="container_2" tabindex="-1" aria-labelledby="additionalModalLabel2" aria-hidden="true"
        style="display: none;">
        <div class="modal-dialog modal-lg modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel" style="font-size: 20px;">Approval History</h5>
                </div>
                <div class="modal-body" style="padding: 0px;">
                    <div class=" container-fluid">
                        <div class="mobile">
                            <div class="table-responsive"
                                style="min-height: fit-content;max-height: 40vh ; overflow: scroll;width: 100%;">
                                <table class="table  table-vcenter text-nowrap table-bordered border-bottom"
                                    id="emp-attendance">
                                    <thead class="tablerow stickyatTop">
                                        <tr class="rowcolors stickyatTop">
                                            <th class="border-bottom-0 w-5 fs-6 secondcolumn backgroundSW">#</th>
                                            <th class="border-bottom-0 w-5 fs-6">Date</th>
                                            <th class="border-bottom-0 w-5 fs-6">Approver Name</th>
                                            <th class="border-bottom-0 w-5 fs-6">Approver Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let details of approvalDetails;let j = index">
                                            <td>{{ j + 1 }}</td>
                                            <td>{{ details.timeStamp | date: 'mediumDate' }}</td>
                                            <td>{{ details.approvedBy }}</td>
                                            <td style="text-align: right;">{{ details.approvalAmount }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                        aria-label="Close">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>