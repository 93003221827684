<div class="col-lg-12 p-0" style="height:100%">
  <div class="card overflow-hidden mt-3 mb-3 mt-md-0 mb-md-0 mobile-margin-right" style="height: fit-content;">
    <div class="position-relative">
      <div class="card-header p-0 px-4 d-flex justify-content-between" style="min-height:60px;max-height: 60px;" id="header_sticky">
        <h4 class="card-title">Upcoming Events</h4>
      </div>
    </div>
    <div class="p-0 border-0 position-relative" style="min-height:200px;max-height: 200px;">
      <div class="list-group scroll" style="max-height: 147px;">
        <div class="list-group-item d-flex pt-1 pb-1 border-0" *ngFor="let holiday of eventDayList ;let i=index">
          <div class="ms-1">
            <div class="calendar-icon icons" [ngClass]="i%2===0 ? 'bg-pink-transparent' : 'bg-success-transparent'">
              <div class="date_time"> <span class="date"> {{ holiday.date | date:'d' }} </span>
                <span class="month" style="padding-right:10px"> {{ holiday.date | date: 'MMM' }}</span>
              </div>
            </div>
          </div>
          <div class="ms-1">
            <div class="h5 fs-14 mb-1 pt-3">{{ holiday.reason }}</div>
          </div>
        </div>
      </div>
      <div *ngIf="isPresent('EVENTCALENDAR')">
        <div class="card-footer w-100 d-flex justify-content-end pt-3 position-absolute fixed-bottom bg-white" id="event-footer">
          <a [routerLink]="['/event_calendar_report']" [routerLinkActive]="['highlightActiveLink']" [hasAccess]="{role:getUserRole(),key:'EVENTCALENDAR'}"
            (click)="setMenuName('Event Calendar Setup'); navigateEventCalendar()">View All</a>
        </div>
      </div>
    </div>
  </div>
</div>