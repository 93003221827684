<!-- <div class="app-content main-content">
    <div class="side-app main-container">
        <div class="alertcomp">
    
        </div> -->
        <div class="app-content main-content">
            <div class="side-app main-container">
              <div class="alertcomp" id="alertcomp"></div>

         <!-- <div class="row">
            <div class="col-xl-12 col-md-12 col-lg-12 col-xs-12">
                <div class="card">
                    <div class="card-body p-2">  -->

                        <div class="row">
                            <div class="col-xl-12 col-md-12 col-lg-12">
                              <div class="card">
                                <div class="card-body">

                        <!-- <form class="form-horizontal form-label-left" > -->
                            <form   class="form-horizontal form-label-left row">

                                <!-- <div class="row col-md-10 col-sm-10 col-xs-10">
                                    <div class="col-md-4 col-sm-4 col-xs-4 px-5">
                                        <div class="form-group label-floating"> -->

                                <div class="row col-md-12 col-sm-12 col-xs-12">

                                    <div class="col-md-3 col-sm-4 col-xs-4 px-5">
                                     <div class="form-group label-floating">

                                            <label class="form-label" for="date"> Month </label>
                                        <div>
                                            <input class="form-control col-md-12 col-xs-12" [value]="date" name="date" type="month"
                                                id="date" required="required" (change)="changeDate($event)"  />
                                        </div>
                                    </div>
                                </div>

                                <!-- <div class="col-md-4 col-sm-4 col-xs-6">
                                    <div class="form-group label-floating">     select style="width: 100%;"-->
                                        <div class="col-md-3 col-sm-4 col-xs-4">
                                            <div class="form-group label-floating">

                                        <label class="form-label">Select Member</label>
                                        <div>
                                            <div>
                                                <select class="form-control col-md-12 col-xs-12" type="text" 
                                                    (change)="onChange($event)">
                                                    <option [selected]="selectedType === 'select'"
                                                        value="{{uniqueEmpId}}">ME</option>
                                                    <option value="Team_Member" *ngIf="teamMemberList?.length">Team
                                                        Member</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <!-- <div class="col-md-3 col-sm-3 col-xs-3 mb-3"
                                *ngIf="selectedType === 'Team_Member' && teamMemberList?.length"> -->
                                <div class="col-md-3 col-sm-6 col-xs-6"
                                *ngIf="selectedType === 'Team_Member' && teamMemberList?.length">
                                    <div class="form-group label-floating">
                                <label class="form-label">Employee Name / ID</label>
                                <!-- <div class="form-group m-0"> -->
                                    <div>
                                        <input type="search" class="form-control" name="Employee Name"
                                            id="employeename" placeholder="Enter Employee Name / ID"
                                            [(ngModel)]="query" (change)="onChange1($event)" autocomplete="off"
                                            (input)="getEmployeeByNameOrId()" list="employeeMasterLucene"
                                            autofocus="autofocus">
                                        <datalist id="employeeMasterLucene">
                                            <option [value]="emp.name + ' / ' + emp.employeeid"
                                                *ngFor="let emp of employeeMasterLucene">
                                            </option>
                                        </datalist>
                                    </div>
                                </div>
                            </div>
                                                        <!-- Active/Inactive Employee Status filter for Lucene Search -->
                                                        <div class="col-md-3 col-sm-6 col-xs-6 mb-6"
                                                        *ngIf="selectedType === 'Team_Member' && teamMemberList?.length">

                                                        <!-- <div class="col-md-6 col-sm-6 col-xs-6"
                                                        *ngIf="selectedType === 'Team_Member' && teamMemberList?.length"> -->
                                                            <!-- <div class="form-group label-floating w-200"> -->

                                                        <label class="form-label">Employee Status</label>
                                                        <!-- <div class="form-group m-0"> -->
                                                            <div>
                                                        <select class="form-control" type="text" style="width: 100%;" required
                                                            [(ngModel)]="selectedluceneValue" name="selVal" (change)="onStatusChange()"
                                                            [disabled]="!showBasedOnPermissionEmployeeStatus">
                                                            <option value="All">All</option>
                                                            <option value="Active">Active</option>
                                                            <option value="Inactive">Inactive</option>
                                                        </select>
                                                    </div>
                                                        <!-- </div> -->
                                                    </div>
                                                    <div class="col-md-6 col-sm-6 col-xs-6 px-5" style="margin-top:26px"
                                                    *ngIf="selectedType === 'select' || selectedType =='1'">
                                                    <div class="form-group label-floating">
                                                     <!-- <label class="form-label w-50 fontSize-15 d-none d-md-block" > &nbsp; </label> -->
                                                     <button type="submit" class="btn btn-success" (click)="generatePayslip()">
                                                        Download
                                                      </button>
                                                    </div>
                                                    </div>
                                                    <div class="actionBar" class="col-md-6 col-sm-6 col-xs-6 px-5" style="margin-top:5px"
                                                    *ngIf="selectedType === 'Team_Member' && teamMemberList?.length">
                                                        <!-- <div> -->
                                                          <button type="submit" class="btn btn-success" style="margin-right:0.5rem" (click)="generatePayslip()">
                                                            Download
                                                          </button>
                                                          
                                                        <!-- </div> -->
                                                      <!-- </div> -->
                                                      <!-- <div class="actionBar" class="col-md-2 col-sm-3 col-xs-3" style="margin: top -4px"
                                                      *ngIf="selectedType === 'Team_Member' && teamMemberList?.length"> -->
                                                      <!-- <label class="form-label fontSize-15 d-none d-md-block mt-2"> &nbsp; </label> -->

                                                      <button class="btn btn-danger" type="button"
                                                             (click)="onClear()">Clear</button>
                                                 
                                                 </div>    
                                                

                            </div>

                        </form>
                    </div>

                </div>
            </div>
        </div>
    </div><!-- end app-content-->
</div>



        <!-- <div class="row">
            <div class="col-xl-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <form class="form-horizontal" id="fcForm" >
                            <section> 
                                <div class="col-md-12 col-sm-12 col-xs-12 p-0">
                                  <div class="form-group label-floating">
                                    <label class="form-label"> Month </label>
                                    <div>
                                        <input class="form-control col-md-12 col-xs-12" [value]="date" name="date" type="month"
                                          id="date" required="required" (change)="changeDate($event)"  />
                    
                                      </div>
                                  </div>
                                </div>
                                
                            </section>
                            <div class="col-md-12 col-sm-12 col-xs-12 p-0">
                                <div class="form-group label-floating">
                                  <label class="form-label">&nbsp;&nbsp;</label> 
                                  <div>
                                    <button type="submit" class="btn btn-primary w-100 col-md-3" (click)="generatePayslip()" >
                                       Generate
                                    </button>
                                  </div>
                                </div>
                            </div>


                        </form>
                    </div>
                </div>
              
            </div>
        </div> -->
                <!-- Row -->
        <!-- <div class="row">
            <div class="col-xl-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="card-header  border-0">
                        <h4 class="card-title">My Payslips Summary</h4>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table  table-vcenter text-nowrap table-bordered border-bottom" id="emp-attendance">
                                <thead>
                                    <tr>
                                        <th class="border-bottom-0 text-center">Employee ID</th>
                                        <th class="border-bottom-0">Month</th>
                                        <th class="border-bottom-0">Year</th>
                                        <th class="border-bottom-0">Net Salary</th>
                                        <th class="border-bottom-0">Generated Date</th>
                                        <th class="border-bottom-0">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="text-center">10029</td>
                                        <td>January</td>
                                        <td>2021</td>
                                        <td class="font-weight-semibold">32,000</td>
                                        <td>01-02-2021</td>
                                        <td>
                                            <a class="btn btn-success btn-icon btn-sm" data-bs-toggle="tooltip" data-original-title="Download"><i class="feather feather-download"></i></a>
                                         </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- End Row-->



