<div class="app-content main-content">
    <div class="side-app main-container">
        <div class="alertcomp"></div>

        <div class="row">
            <div class="col-md-12">
                <div class="card-content">
                    <form #salaryHeadForm="ngForm">
                        <section>
                            <div class="row card-list-margin">
                            <div class="col-xl-12 col-md-12 col-lg-12">
                                <div class="card">
                                    <div class="card-body">
                                        <form id="fcForm" class="form-horizontal form-label-left">
                                            <div class="form-group row">
                                                <!-- Employee Name / ID -->
                                                <div class="form-group col-md-5 col-sm-5 col-xs-5 px-2">
                                                    <label class="form-label">Employee Name / ID</label>
                                                    <input type="search" class="form-control" name="Employee Name"
                                                        id="employeename" placeholder="Enter Employee Name / ID"
                                                        [(ngModel)]="query" autocomplete="off"
                                                        (input)="getEmployeeByNameOrId()"
                                                        list="employeeMasterLucene" autofocus="autofocus">
                                                    <datalist id="employeeMasterLucene">
                                                        <option [value]="emp.name + ' / ' + emp.employeeid"
                                                            *ngFor="let emp of employeeMasterLucene">
                                                        </option>
                                                    </datalist>
                                                </div>
                            
                                                <!-- Employee Status -->
                                                <!-- <div class="form-group col-md-3 col-sm-3 col-xs-3 px-2">
                                                    <label class="form-label">Employee Status</label>
                                                    <select class="form-control" style="width: 100%;" required [(ngModel)]="selectedValue"
                                                        name="selVal" (change)="onStatusChange()"
                                                        [disabled]="!showBasedOnPermissionEmployeeStatus">
                                                        <option value="All">All</option>
                                                        <option value="Active">Active</option>
                                                        <option value="Inactive">Inactive</option>
                                                    </select>
                                                </div> -->

                                                <div class="form-group col-md-3 col-sm-3 col-xs-3 px-2">
                                                    <label class="form-label">Employee Status</label>
                                                    <select class="form-control" type="text" style="width: 100%;" required
                                                        [(ngModel)]="selectedValue" name="selVal"
                                                        (change)="onStatusChange()"
                                                        [disabled]="!showBasedOnPermissionEmployeeStatus">
                                                        <option value="All">All</option>
                                                        <option value="Active">Active</option>
                                                        <option value="Inactive">Inactive</option>
                                                    </select>
                                                </div>
                            
                                                <!-- Buttons: Add & View -->
                                                <div class="form-group col-md-4 col-sm-4 col-xs-4 px-2">
                                                    <label class="form-label">&nbsp;</label>
                                                    <button type="button" class="btn btn-primary w-40 me-2" id="navigatetarget"
                                                        (click)="searchEmployee()">Apply</button>
                                                    <button type="button" class="btn btn-danger w-40" (click)="viewSuspension()">View</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            </div>

                            <div class="empty-div-one" id="navigatedest"></div>

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="card"  *ngIf="add_view_toggle == 'addSuspension' ">
                                        <div class="card-header border-bottom-0">
                                            <h3 class="card-title ">Employee Suspension Configuration </h3>
                                        </div>
                                        <div class="card-body">
                                            <div class="panel-group1" id="accordion1">
                                                <div class="panel panel-default mb-4 overflow-hidden br-7">
                                                    <div class="panel-heading1">
                                                        <h4 class="panel-title1">
                                                            <a class="accordion-toggle" data-bs-toggle="collapse" data-parent="#accordion" href="#collapseFour" 
                                                                aria-expanded="true">Employee Suspension Detail</a>
                                                        </h4>
                                                    </div>
                                                    <div id="collapseFour" class="panel-collapse collapse show" role="tabpanel" aria-expanded="true">
                                                        <div class="panel-body m-0 p-0">
                                                            <div class="card-body">
                                                                <div class="row col-md-12 col-sm-12 col-xs-12">
                                                                    <div class="row col-md-12 col-sm-12 col-xs-12">
                                                                        <div class="col-md-4 col-sm-4 col-xs-4">
                                                                            <div class="form-group label-floating">
                                                                                <label class="form-label">Employee Name / ID <span
                                                                                        style="color: rgb(248, 111, 111);">*</span></label>
                                                                                <div>
                                                                                    <input type="search" class="form-control" name="Employee Name" id="employeename"
                                                                                           placeholder="Enter Employee Name / ID" [(ngModel)]="query" autocomplete="off"
                                                                                           (input)="getEmployeeByNameOrId()" list="employeeMasterLucene" autofocus="autofocus">
                                                                                    <datalist id="employeeMasterLucene">
                                                                                        <option [value]="emp.name + ' / ' + emp.employeeid" *ngFor="let emp of employeeMasterLucene">
                                                                                        </option>
                                                                                    </datalist>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                    
                                                                        <div class="col-md-4 col-sm-4 col-xs-4">
                                                                            <div class="form-group label-floating">
                                                                                <label class="form-label">From Date <span
                                                                                        style="color: rgb(248, 111, 111);">*</span></label>
                                                                                <div>
                                                                                    <input type="date" name="Fromdate" class="form-control col-md-12 col-xs-12"
                                                                                           autocomplete="off" autofocus="true" [(ngModel)]="insertemployeesuspension.fromdate"
                                                                                           #FROMDate_m2="ngModel"
                                                                                           [ngClass]="(!FROMDate_m2.valid && FROMDate_m2.pristine) ? 'is-invalid' : ''"/>
                                                                                </div>
                                                                                <span class="help-text text-danger"
                                                                                      *ngIf="(!FROMDate_m2.valid && FROMDate_m2.touched)">Enter From Date</span>
                                                                            </div>
                                                                        </div>
                                                    
                                                                        <div class="col-md-4 col-sm-4 col-xs-4">
                                                                            <div class="form-group label-floating">
                                                                                <label class="form-label">To Date <span
                                                                                        style="color: rgb(248, 111, 111);">*</span></label>
                                                                                <div>
                                                                                    <input type="date" name="Todate" class="form-control col-md-12 col-xs-12"
                                                                                           autocomplete="off" autofocus="true"
                                                                                           [(ngModel)]="insertemployeesuspension.todate" #ToDate_m2="ngModel"
                                                                                           [ngClass]="(!ToDate_m2.valid && ToDate_m2.pristine) ? 'is-invalid' : ''"/>
                                                                                </div>
                                                                                <span class="help-text text-danger"
                                                                                      *ngIf="(!FROMDate_m2.valid && ToDate_m2.touched)">Enter To Date</span>
                                                                            </div>
                                                                        </div>
                                                    
                                                                        <div class="col-md-4 col-sm-4 col-xs-4">
                                                                            <div class="form-group label-floating">
                                                                                <label class="form-label">Reason <span
                                                                                        style="color: rgb(248, 111, 111);">*</span></label>
                                                                                <div>
                                                                                    <textarea type="date" name="Todate" class="form-control col-md-12 col-xs-12"
                                                                                              autocomplete="off" autofocus="true"
                                                                                              placeholder="Please enter reason for suspension"
                                                                                              [(ngModel)]="insertemployeesuspension.reason" #reason_m2="ngModel"
                                                                                              [ngClass]="(!reason_m2.valid && reason_m2.pristine) ? 'is-invalid' : ''">
                                                                                    </textarea>
                                                                                </div>
                                                                                <span class="help-text text-danger"
                                                                                      *ngIf="(!reason_m2.valid && reason_m2.touched)">Enter Reason</span>
                                                                            </div>
                                                                        </div>
                                                    
                                                                    </div>
                                                                </div>
                                                    
                                                                <div class="clearfix"></div>
                                                    
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div  *ngIf="add_view_toggle == 'addSuspension' " class="actionBar pe-4 ps-4">
                                <div class="clearfix"> </div>
                                <button class="btn btn-success me-2 "
                                    type="submit" (click)="submit()">Save</button>
                                <button class="btn btn-danger"
                                    type="button" (click)="clear()">Clear</button>

                            </div>
                            <div class="row card-list-margin">
                                <div class="col-md-12 card-list1-margin" style="padding-bottom: 60px">
                                    <div class="card col-md-12 col-sm-12 col-xs-12"  *ngIf="add_view_toggle == 'viewSuspension'">
                                        <div class="card-header border-bottom-0">
                                            <h3 class="card-title">List Of Employee Suspension</h3>
                                        </div>
                                        <div class="card-body card1S p-0">
                                            <div class="table-responsive">
                                                <div class="scrollsearchresults" infiniteScroll (scrolled)="onScroll()" id="scrolllength">
                                                    <table
                                                        class="table table-hover table-vcenter text-nowrap table-bordered border-bottom"
                                                        id="hr-table">
                                                        <thead class="tablerow stickyatTop">
                                                            <tr class="rowcolors stickyatTop">
                                                                <th class="border-bottom-0 w-5 fixedcolumn">Employee ID</th>
                                                                <th class="border-bottom-0 w-5 secondcolumn">Name</th>
                                                                <th class="border-bottom-0">From Date</th>
                                                                <th class="border-bottom-0">To Date</th>
                                                                <th class="border-bottom-0">Reason</th>
                                                                <th class="border-bottom-0">Applied By</th>
                                                                <th class="border-bottom-0">Approved/Rejected By</th>
                                                                <th class="border-bottom-0">Rejected Reason</th>
                                                                <th class="border-bottom-0">Status</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody style="max-height: 50vh; overflow: scroll;">
                                                            <tr *ngFor="let s of suspension">
                                                                <td>{{s.empid}}</td>
                                                                <td>{{s.empname}}</td>
                                                                <td>{{s.fromdate | date: 'mediumDate'}} </td>
                                                                <td>{{s.todate | date: 'mediumDate'}} </td>
                                                                <td>{{s.reason}}</td>
                                                                <td>{{s.updatedBy}}</td>
                                                                <td>{{s.approvedBy}}</td>
                                                                <td>{{s.remark}}</td>
                
                                                                <td>
                                                                    <span style="font-size: 13px; font-weight: 500;"
                                                                          [ngClass]="s.status === 'Approved' ? 'badge-success' : 
                                                                          s.status === 'Partial Approve 1' ? 'bg-custom-orange1 text-custom-dark' : 
                                                                          s.status === 'Partial Approve 2' ? 'bg-custom-orange2 text-custom-dark' : 
                                                                          s.status === 'Suspension Approve Pending' ? 'bg-custom-warning text-custom-dark' : 
                                                                          s.status === 'Rejected' ? 'badge-danger' : 'bg-custom-warning text-custom-dark'"
                                                                          class="badge">{{s.status}}</span>
                                                                </td>

                                                            </tr>
                                                               
                                                        </tbody>

                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>


                        </section>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>