<div class="app-content main-content">
    <div class="side-app main-container">
        <div class="alertcomp"></div>
        <!-- <div class="page-header d-xl-flex d-block">
            <div class="page-leftheader">
                <div class="page-title">Shift Allocated</div>
            </div>
        </div>  -->

        <div class="row">
            <div class="col-xl-12 col-md-12 col-lg-12">
              <div class="card">
                <div class="card-body">
                
                  <form id="fcForm" class="form-horizontal form-label-left" #f="ngForm" (ngSubmit)="f.form.valid" >
      
                    <div class="x_content row px-4 align-items-center">
                        <div class="col-md-5 col-sm-6 col-xs-6 " >
                            <div class="form-group label-floating ">
                              <label class="form-label"> From Date: </label>
                              <div>
                                <input class="form-control" type="date" name="fromdate" id="fromdate"   [(ngModel)]="fromdate">
                                <!-- <select class="form-control " style="appearance: auto !important;"
                                  data-style="btn btn-drpdwn btn-round" id="dGroup" name="shift"
                                 [(ngModel)]="shift"
                                  > -->
                                  <!-- [(ngModel)]="myDocuments.docGroup" #docGroup="ngModel" (change)="docGroupChange()" required -->
                                  <!-- <option [value]="null" selected>Select Shifts</option>
                                  <option  >shift1</option>
                                  <option  >shift3</option> -->
    
                                <!-- </select> -->
                                <!-- <span *ngIf="submitted && !docGroup.valid " class="help-block" style="color: red;">
                                            Please Select Document Group</span> -->
                              </div>
                            </div>
                          </div>

                          <div class="col-md-5 col-sm-6 col-xs-6 " >
                            <div class="form-group label-floating ">
                              <label class="form-label"> To Date: </label>
                              <div>
                                <input class="form-control" type="date" name="todate" id="todate"   [(ngModel)]="todate">
                              </div>
                            </div>
                          </div>
                            <div class="col-md-2 col-sm-6 col-xs-6 p6">
                                <div class="d-flex justify-content-end">
                                 <button type="button" id="btnsave"
                                  class="btn btn-primary w-100  w-md-20  p-2 p-md-1"
                                  (click)="search(f.form.value)">
                                  <i class="fa fa-search" aria-hidden="true"></i> Search
                                </button>
                              </div>
                            </div>
                          </div>
      
      
                      
                      <!-- <div class="pro-fab">
                        <button class="btn btn-success" type="button"  id="btnsave" (click)="search(f.form.value)">Search</button>
                        
                      </div>
                     -->
      
                  </form>
                </div>
      
              </div>
            </div>
          </div>
        
          <div class="row" *ngIf="shiftallocated.length !==0">
            <div class="col-xl-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="card-header  border-0">
                        <h4 class="card-title">Allocated Shifts</h4>
                    </div>
                    <div class="card-body">
                        <div class="col-xs-12 mobile">
                          <div class="scrollsearchresults" id="scroll" infiniteScroll [infiniteScrollDistance]="2"
                          [infiniteScrollUpDistance]="1.5" [infiniteScrollThrottle]="100"
                          [scrollWindow]="false" (scrolled)="onScroll()">
                            <div class="scrollbar">
                            <table class="table table-hover table-vcenter text-nowrap table-bordered border-bottom" id="hr-table">
                                <thead class="tablerow">
                                    <tr class="stickyatTop rowcolors">
                                        <th class="border-bottom-0 w-5 fs-7">#</th>
                                        <th class="border-bottom-0 w-5 fs-7">Employee Name</th>
                                        <th class="border-bottom-0 w-10 fs-7">Shift Name</th>
                                        <th class="border-bottom-0 w-10 fs-7"> Date</th>
                                       
                                    </tr>
                                </thead>
                                <tbody class="tablerow">
                                    <tr *ngFor="let shift of shiftallocated;let i=index" 
                                    >
                                      <td data-th="Employee Id" style="width: max-content; text-align: right;">{{i+1}}</td>
                                      <td> {{ shift.employeeName}}</td>
                                        <td data-th="Shift" style="width: 45%;">{{shift.shiftname}}</td>
                                        <td data-th="From Date" style="width: 45%;">{{shift.date| date: 'mediumDate'}}</td>
                                    </tr>
                                </tbody>
                            </table>
                           
                          </div>  
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
</div>