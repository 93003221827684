import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
  window.console.log = () => { }
}

if ("serviceWorker" in navigator) {
  navigator.serviceWorker.register("assets/sw.js")
    .then(() => console.log("Service Worker Registered"))
    .catch(err => console.log("Service Worker Registration Failed", err));
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
