import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbstractControl, FormControl,FormsModule, ReactiveFormsModule, ValidationErrors } from '@angular/forms';
import { FieldTypeConfig, FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { CommonComponentsModule } from '../commonComponents/commonComponents.module';
import { EmployeemasterComponent } from './employeemaster/employeemaster.component';
import { PanelWrapperComponent } from './panel-wrapper.component';
import { DatatableTypeComponent } from './datatable.type';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { EmployeeMasterservice } from '../services/employeemaster.service';
import { MatTabsModule } from '@angular/material/tabs';
import { FormlyFieldTabs } from './tabs-wrapper.type';
import { GlobalConstants } from '../constants/GlobalConstants';


export function minlengthValidationMessage(field) {
  return `Should have atleast ${field.templateOptions.minLength} characters`;
}

export function maxlengthValidationMessage(field) {
  return `This value should be less than ${field.templateOptions.maxLength} characters`;
}

export function minValidationMessage(field) {
  return `This value should be more than ${field.templateOptions.min}`;
}

export function maxValidationMessage(field) {
  return `This value should be less than ${field.templateOptions.max}`;
}

export function requiredValidation(err, field) {
  return `${field.templateOptions.label} field is required`;
}

export function whitespaceValidator(form: FormControl): ValidationErrors {
  return form.value.startsWith(" ") ? { whitespace: true } : null;
}
export function whitespaceNotrequired(err, field: FormlyFieldConfig) {
  return `${field.formControl.value} is not a valid number  `;
}

export function isMobileValidator(control: FormControl): ValidationErrors {
  return !control.value || /^\d{10}$/.test(control.value) ? null : { mobile: true };
}
export function isMobileValidationMessage(err, field: FormlyFieldConfig) {
  return `${field.formControl.value} is not a valid number  `;
}

export function isDatevalidation(control: FormControl): ValidationErrors {
  let isDate = false;
  let minYear = 1900;
  if (control.value?.length === 10) {
    let date = new Date(control.value);
    let year: number = date.getFullYear();
    if (year < minYear) {
      isDate = false;
    } else {
      isDate = true;
    }
    // isDate = true;
  }
  return !control.value || isDate ? null : { date: true };
}
export function isDatevalidationMessage(err, field: FormlyFieldConfig) {
  return `${field.formControl.value} is not a valid date `;
}


export function isTextValidator(control: FormControl): ValidationErrors {
  let isText = true;
  if (control.value) {
    let enteredString: string = control.value;
    enteredString.toString().split('').forEach(
      character => {
        if (Number(character)) {
          isText = false;
        }
      }
    )
    if (!isText) {
      return { onlyText: true };
    }
    let s = Number(control.value)
    console.log(s)
    if (isNaN(s)) {
      isText = true
    } else {
      isText = false;
    }
  }

  return isText ? null : { onlyText: true };
}

export function isTextValidatorMessage(err, field: FormlyFieldConfig) {
  return `${field.formControl.value} is not a valid ${field.templateOptions.label} `;
}

export function isvalidatejoiningdate(form: AbstractControl,
  field: FormlyFieldConfig,
  options = {}): ValidationErrors {
  let dobvalue = field.form.controls.dateofbirth.value
  if (dobvalue && form.value) {
    let birthday: Date = new Date(dobvalue);
    let joiningDate: Date = new Date(form.value)
    let differenceinyear = diff_years(birthday, joiningDate)
    if (differenceinyear <= GlobalConstants.minimumAgedifference) {
      return { 'validatejoiningdate': true };
    }
  }
  return null;
}
export function diff_years(dt2, dt1) {

  var diff = (dt2.getTime() - dt1.getTime()) / 1000;
  diff /= (60 * 60 * 24);
  return Math.abs(Math.round(diff / 365.25));

}

export function isvalidationphoneandemergency(form: AbstractControl,
  field: FormlyFieldConfig): ValidationErrors {
  if (field.formControl.value) {
    let mobileno = field.form.controls.mobile.value
    if (mobileno) {
      let emergencynumber = field.formControl.value;
      if (emergencynumber === mobileno) {
        return { validationphoneandemergencyNumber: true }
      }
    }
  }


  return null;
}

export function isEmailOruserid(form: AbstractControl,
  field: FormlyFieldConfig): ValidationErrors {
  const pattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
  const val: string = field.formControl.value
  const label = field.templateOptions.label
  // console.log(field.form.controls.email.get('la'))
  if (label === "Username") {
    if (val?.length === 0) {
      return { emailOruserid: false }
    } else {
      console.log("else")
      return null;
    }
  } else if (pattern.exec(val)) {
    return null;
  } else {
    return { emailOruserid: true }
  }
}
//to prevent alphabetic characters 
export function ZIPNumericOnlyValidator(control: AbstractControl): ValidationErrors | null {
  return !control.value || /^[0-9]*$/.test(control.value) ? null : { numericOnly: true };
}

export function isZipcodevalidationMessage(err, field: FormlyFieldConfig) {
  return field.formControl.value 
    ? `${field.formControl.value} is not a valid zipcode` 
    : '';
}


@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormlyBootstrapModule,
    CommonComponentsModule,
    FormlyModule,
    NgxDatatableModule,
    MatTabsModule,
    FormsModule,

    FormlyModule.forRoot({
      validationMessages: [
        { name: 'required', message: requiredValidation },
        { name: 'minlength', message: minlengthValidationMessage },
        { name: 'maxlength', message: maxlengthValidationMessage },
        { name: 'min', message: minValidationMessage },
        { name: 'max', message: maxValidationMessage },
        { name: 'whitespaces', message: whitespaceNotrequired },
        { name: 'mobile', message: isMobileValidationMessage },
        { name: 'date', message: isDatevalidationMessage },
        { name: 'onlyText', message: isTextValidatorMessage },
        { name: 'validatejoiningdate', message: (err, field: FormlyFieldConfig) => ` The minimum age gap between DOB and joining should be 14` },
        { name: 'validationphoneandemergencyNumber', message: (err, field: FormlyFieldConfig) => ` Phone number and Emergency number cannot be same ` },
        // { name: 'emailOruserid', message: (err, field: FormlyFieldConfig) => `"${field.formControl.value}" is not a valid email address` },
        { name: 'numericOnly', message: isZipcodevalidationMessage },
      ],
      validators: [
        { name: 'whitespaces', validation: whitespaceValidator },
        { name: 'mobile', validation: isMobileValidator },
        { name: 'date', validation: isDatevalidation },
        { name: 'onlyText', validation: isTextValidator },
        { name: 'validatejoiningdate', validation: isvalidatejoiningdate },
        { name: 'validationphoneandemergencyNumber', validation: isvalidationphoneandemergency },
        // { name: 'emailOruserid', validation: isEmailOruserid }
        { name: 'numericOnly', validation: ZIPNumericOnlyValidator },
      ],
      wrappers: [
        { name: 'panel', component: PanelWrapperComponent },
      ],
      types: [
        { name: 'tabs', component: FormlyFieldTabs },
        {
          name: 'datatable',
          component: DatatableTypeComponent,
          defaultOptions: {
            templateOptions: {
              columnMode: 'force',
              rowHeight: 'auto',
              headerHeight: '40',
              footerHeight: '40',
              limit: '10',
              scrollbarH: 'true',
              reorderable: 'reorderable',
            },
          },
        },
      ],
    }),
  ],
  declarations: [
    EmployeemasterComponent,
    PanelWrapperComponent,
    DatatableTypeComponent,
    FormlyFieldTabs,
  ],
  exports: [
    EmployeemasterComponent,
  ],
  providers: [EmployeeMasterservice],
})
export class EmployeeMasterModule {
  static forChild(): ModuleWithProviders<any> {
    return {
      ngModule: EmployeeMasterModule,
      providers: []
    };
  }
}