import { Component, HostListener, OnInit } from '@angular/core';
import { Menu } from '../services/menu.Service';
import { EmployeeMaster } from '../models/employeeMasterts';
import { EmployeeLeaveService } from '../services/employee-leave.service';
import { EmployeeMasterservice } from '../services/employeemaster.service';
import { Employeesuspensioninfo } from '../models/Employeesuspensioninfo';
import { SessionService } from '../services';
import { LuceneSearchService } from '../services/lucene-search.service';


@Component({
  selector: 'app-approve-employee-suspension',
  templateUrl: './approve-employee-suspension.component.html',
  styleUrls: ['./approve-employee-suspension.component.css']
})
export class ApproveEmployeeSuspensionComponent implements OnInit {
  searchleave: any;
  showTable = false
  selectAll: boolean = false;
  query: any;
  employeeid: String;
  queryName: any;
  selectedempid: any;
  employeeMasterLucene: EmployeeMaster[];
  statusValue: string = 'All';
  client_id: EmployeeMaster[];
  keyinempid: any | undefined;
  approvesuspenseReport: Employeesuspensioninfo[]=[];
  limitPara: any;
  offsetPara: any;
  suspensionList: any[] = [];
  selectedList: any[] = [];
  uniqueempid: string = this.session.getempid()
  searchClicked = false; 
  selectedValue : string='Active';
  leavingdate: any;
  permissionList: any[] = [];
  showBasedOnPermissionEmployeeStatus: boolean = false;
  allowToViewReport: boolean = false;
  
  

  constructor(private menuService: Menu,private empLeaveService: EmployeeLeaveService,private empLuceneSearch: LuceneSearchService,private employeeMasterservice:EmployeeMasterservice,
    private session: SessionService) { }

  ngOnInit(): void {
    this.limitPara = 13;
    //this.search();
		this.offsetPara = 0;
    const maxString = sessionStorage.getItem('permission').replace('[', '');
    const maxStringBox = maxString.replace(']', '');
    const maxStringAgain = maxStringBox.replace(/"/g, '');
    this.permissionList = maxStringAgain.split(',');
    this.checkAccessForUser();
  }

  checkAllSelected(e: any) {}


  getEmployeeByNameOrId() {
		this.empLuceneSearch.getEmployeeByNameOrId(this.queryName,this.employeeid,this.selectedValue,this.query , this.employeeMasterLucene,this.leavingdate,this.selectedempid).subscribe(res=>{
		  console.warn(res)
		  this.employeeMasterLucene=res.filteredEmployees;
		  this.selectedempid = res.selectedempid;
		}); }
  
  onStatusChange() {
    this.getEmployeeByNameOrId();
  }

  navigateSuspenseStatus() {
		localStorage.setItem('navigateDashboard', 'true')
	  }

	  setMenuName(name: string) {
		this.menuService.setMenuName(name);
	  }

   

    onApproveHandler() {
      console.log("selected list",this.selectedList);
      console.log("selected list userId",this.selectedList[0].userID);
      let approve_reject = true;
      let flag = true
      if (this.selectedList.length === 0) {
        this.alertmessages("Please select item for Approval.", "danger")
  
        flag = false
        return;
      }
      this.employeeMasterservice
          .approveList(this.selectedList, this.uniqueempid,approve_reject)
          .subscribe(
            (res) => {
              console.log("selectedList", this.selectedList);
              this.alertmessages("Successfully Approved ", "success");
              this.selectedList =[];
              this.search();
            },
            (error) => {
              console.log(error);
              this.alertmessages("Please try again later", "danger");
            }
          );
    }

    
    
    
    onRejectHandler() {
      let flag = true
      let approve_reject = false;
      if (this.selectedList.length === 0) {
        this.alertmessages("Please select item for Approval.", "danger")
  
        flag = false
        return;
      }
      let isCommentAbsent = false;
      this.selectedList.forEach(rejectsuspension => {
    //
        if (rejectsuspension.remark === undefined) {
          isCommentAbsent = true;
          flag = false
          return;
        }
      })
    
      if (isCommentAbsent) {
        console.log("Reject extra time out ");
        this.alertmessages("Comments are mandatory for Rejection.", "danger")
        return;
      }

     this.employeeMasterservice
          .rejectList(this.selectedList)
          .subscribe(
            (res) => {
              console.log("selectedList", this.selectedList);
              this.alertmessages("Successfully Rejected ", "success");
              this.search();
              this.selectedList =[];
            },
            (error) => {
              console.log(error);
              this.alertmessages("Please try again later", "danger");
            }
          );
    }

    addToSelectedList(s: any): void {
      // Check if the item is not already in the selectedList before adding
      if (!this.selectedList.includes(s)) {
        this.selectedList.push(s);
      }
    }
    removeFromSelectedList(s: any): void {
      // Remove the item from the selectedList if it exists
      this.selectedList = this.selectedList.filter((item) => item !== s);
    }

    check_info(s: any, event: any): void {
      // Update the individual checkbox status
      s.checked = event.target.checked;
      if (s.checked) {
        this.addToSelectedList(s);
      } else {
        this.removeFromSelectedList(s);
      }
      console.log("Suspense list",s.checked);
      console.log("Suspense list",s);
      console.log("Suspense list",this.selectedList);
    }
  
    
    checkallbox(event: any): void {
      // Update the status of all checkboxes based on the Select All checkbox
      this.suspensionList.forEach((s) => {
        s.checked = event.target.checked;
    
        // Add or remove from selectedList based on checked status
        if (s.checked) {
          this.addToSelectedList(s);
        } else {
          this.removeFromSelectedList(s);
        }
      });
      console.log("suspense list",this.suspensionList);
      console.log("suspense list",this.suspensionList);
    }

    onScroll() {
          this.offsetPara = this.offsetPara + 13;
          console.log("limit = ? ", this.limitPara, "offset = +13 ", this.offsetPara)
          this.search();
      
        }
      
        limitpara() {
          this.offsetPara = 0;
          console.log(" on clikc limit para ", this.limitPara)
          console.log("this offset", this.offsetPara)
        }
      
        @HostListener('scroll', ['$event'])
        scrolled() {
      
          console.log("Scrolled >>> ")
          this.onScroll();
        }

        search() {
          this.searchClicked = true;
          console.warn("search");
          let employeename = (<HTMLInputElement>document.getElementById('employeename')).value;
          if (!employeename) {
            employeename = null;
          } else {
            employeename = employeename.trim()
            // this.statusValue ="All";
          }
          var id = null;
          if (employeename) {
            this.client_id = this.employeeMasterLucene.filter((dName) => {
              return dName.name === employeename;
            });
      
            console.log("data parent: ", this.client_id)
            id = this.selectedempid;
            this.keyinempid = id;
          } else {
            console.log("Id parent", id)
          }
         console.log("this.selectedempid",this.selectedempid);
          this.employeeMasterservice.getApproveEmployeeSuspension(this.uniqueempid,id,employeename,this.selectedValue,this.statusValue,  this.limitPara, this.offsetPara).subscribe(
              (data) => {


                if (this.statusValue &&  this.statusValue !='All')  {
                  this.approvesuspenseReport = data.employeesuspension_intermediate.filter(
                    (record) => record.status === this.statusValue
                  );
                } else {
                  this.approvesuspenseReport = data.employeesuspension_intermediate;
                }


                //this.approvesuspenseReport=data.employeesuspension_intermediate;
                console.log("Data available :", data.employeesuspension_intermediate);
                console.log(data.employeesuspensioninfo);
                console.log(this.approvesuspenseReport);
                
                console.log("suspension response ", this.approvesuspenseReport);


              },
              (error) => {
                console.log(error);
              }
            );
        }

    alertmessages(message: string, sign: string): void {
      let alertDiv = document.createElement('div');
      alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
    <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message}</div>`
      let alertcomp = document.querySelector('.alertcomp');
      alertcomp.append(alertDiv);
      document.body.scrollTop = document.documentElement.scrollTop = 0;
  
      setTimeout(function () {
        alertcomp.removeChild(alertDiv);
      }, 3000);
    }

    checkAccessForUser() {
      console.log("checkAccessForUser here")
      this.allowToViewReport = this.permissionList.includes('ng.screen.empsuspensionrepor');
      this.allowToViewReport =this.permissionList.includes('ng.employee.edit.suspension.permission');
      this.showBasedOnPermissionEmployeeStatus = this.permissionList.includes('ng.employee.search.show.employeestatus');
    }
   


    navigateLeaveStatus() {
      localStorage.setItem('navigateDashboard', 'true')
      }
  
    }
