<div class="app-content main-content">
    <div class="side-app main-container">
        <div class="alertcomp"></div>
        <!-- <div class="card-body px-0">
            <div class="page-leftheader my-3">
                <div class="page-title">Job Level Master</div>
            </div>
        </div> -->
        <div class="row">
            <div class="col-xl-12 col-md-12 col-lg-12">

                <!-- <form id="fcForm" class="form-horizontal form-label-left row" #jobMasterForm="ngForm"> -->
                <div class="card">
                    <div class="card-body row">
                        <!-- job leave code  -->
                        <div class=" row col-md-6 col-sm-6 col-xs-6">
                            <div class="form-group label-floating">
                                <label class="form-label">Add New Employee Type</label>
                                <div>
                                    <input type="text" name="employee_type" class="form-control col-md-12 col-xs-12"
                                    autocomplete="off" placeholder="Enter Employee Type" autofocus="true"
                                    [(ngModel)]="employeeTypeName" required #employee_type="ngModel"
                                    [ngClass]="(!employee_type.valid && employee_type.touched) ? 'is-invalid' : ''" />
                                </div>
                                <span class="help-text text-danger"
                                *ngIf="(!employee_type.valid && employee_type.touched)">Please Enter Employee
                                Type</span>
    
                            </div>
                        </div>

                        <!-- button flag  -->
                        <div class="col-md-6 col-sm-6 col-xs-6 justify-content-between align-items-start">
                            <div class="form-group label-floating">
                                <label class="form-label">&nbsp;</label>
                                <button *ngIf="addOrUpdate;else updateButton" type="button" id="navigatetarget"
                                    class="btn btn-primary w-40" style="margin-right: 2vw;margin-left: 2vw;"
                                    (click)="addempType()">
                                    Add
                                </button>
                                <ng-template #updateButton>
                                    <button type="button" id="navigatetarget" class="btn btn-primary w-40"
                                        style="margin-right: 2vw;margin-left: 2vw;" (click)="updateEmployeeType()">
                                        Update
                                    </button>
                                </ng-template>
                                <button type="button" class="btn btn-danger w-40"
                                    style="margin-right:1vw;margin-left:1vw" (click)="viewEmployeeTypeButton()">
                                    View
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="clearfix"> </div>

                <div class="empty-div-one" id="navigatedest"></div>

                <!-- </form> -->
                <div class="card" *ngIf="add_view_toggle == 'add_employeeType'">
                    <div class="card-body">

                        <div class="row col-md-12 col-sm-12 col-xs-12">

                            <!--Job Code  -->
                            <!-- <div class=" row col-md-12 col-sm-12 col-xs-12"> -->
                            <div class="col-md-6 col-sm-6 col-xs-6 p-0 m-0">
                                <div class="form-group label-floating">
                                    <label class="form-label"> Employee Type</label>
                                    <div>
                                        <input type="text" name="employee_type"
                                                class="form-control col-md-12 col-xs-12" autocomplete="off"
                                                placeholder="Enter Employee Type" autofocus="true"
                                                [(ngModel)]="employeeTypeName" required #employee_type="ngModel"
                                                [ngClass]="(!employee_type.valid && employee_type.touched) ? 'is-invalid' : ''" />
                                    </div>
                                    <span class="help-text text-danger"
                                    *ngIf="(!employee_type.valid && employee_type.touched)">Please Enter
                                    Employee Type</span>
                                </div>

                            </div>

                            <div class="col-md-6 col-sm-6 col-xs-6">
                                <div class="form-group label-floating">
                                    <label class="form-label">Employee Type code</label>
                                    <div>
                                        <input type="text" name="employee_type_code"
                                        maxlength="2"
                                        class="form-control col-md-12 col-xs-12" autocomplete="off"
                                        placeholder="Enter Employee Type" autofocus="true" [(ngModel)]="employetypecode"
                                        required #employee_type_code="ngModel"
                                        [ngClass]="(!employee_type_code.valid && employee_type_code.touched) ? 'is-invalid' : ''" />
                                    </div>
                                    <span class="help-text text-danger"
                                    *ngIf="(!employee_type_code.valid && employee_type_code.touched)">Please Enter
                                    Employee Type Code</span>
                                </div>
                            </div>

                            <!-- </div> -->
                            <div class="clearfix"> </div>

                        </div>
                    </div>
                </div>

                <div class="actionBar pe-4 ps-4" *ngIf="add_view_toggle == 'add_employeeType'">
                    <button class="btn btn-success" type="submit" *ngIf="addOrUpdate"
                        (click)="saveEmployeeType()">Save</button>
                    <button *ngIf="addOrUpdate" class="btn btn-danger ms-2 " type="clear"
                        (click)=" clear()">Clear</button>
                </div>

                <!-- view job list  -->

                <div class="row card-list-margin">
                    <div class="col-md-12 card-list1-margin">
                        <div class="card" *ngIf="add_view_toggle == 'viewEmployeeType'">
                            <div class="card-body left_padding2">
                                <div class="col-md-12 col-sm-12 col-xs-12 left_padding1">
                                    <div class="col-md-12 col-sm-12 col-xs-12">
                                        <div class="card-header border-bottom-0" style="margin-top:-12px;">
                                            <h3 class="card-title">List Of Employee Types</h3>
                                        </div>
                                        <div class="card-body card1S p-0">
                                            <div class="table-responsive">
                                                <div class="scrollsearchresults" id="scroll" infiniteScroll
                                                    [infiniteScrollDistance]="2" [infiniteScrollUpDistance]="1.5"
                                                    [infiniteScrollThrottle]="100" [scrollWindow]="false"
                                                    id="scrolllength">
                                                    <table
                                                        class="table table-hover table-vcenter text-nowrap table-bordered border-bottom"
                                                        id="hr-table">
                                                        <thead class="tablerow">
                                                            <tr class="rowcolors stickyatTop">
                                                                <th class="border-bottom-0 w-5 fs-6 ">#</th>
                                                                <th class="border-bottom-0 w-5 fs-6 ">Employee Type
                                                                </th>
                                                                <!-- <th class="border-bottom-0 w-5 fs-6 ">Employee Type Code
                                                                </th> -->
                                                                
                                                                <th class="border-bottom-0 w-5 fs-6 ">Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody style="max-height: 50vh; overflow: scroll;">
                                                           
                                                            <tr *ngFor="let emp of EmployeeType; let i = index">
                                                                <td style="text-align: right;">{{i+1}}</td>
                                                                <td>{{emp.employeeTypeName}}</td>
                                                                 <!-- <td>{{emp.employetypecode}}</td>  -->
                                                                
                                                                <td>
                                                                    <button class="btn btn-primary btn-icon btn-sm me-2"
                                                                        data-method="edit" title="Edit"
                                                                        (click)="editemployeeType(emp)">
                                                                        <i class="fa fa-edit" data-bs-toggle="tooltip"
                                                                            data-original-title="edit"></i>
                                                                    </button>

                                                                    <button class="btn btn-danger btn-icon btn-sm me-2"
                                                                        data-method="delete" title="Delete"
                                                                        (click)="onDeleteHandler(emp.id,emp.employeeTypeName)"
                                                                        data-bs-target="#showAlertWarning">
                                                                        <i class="fa fa-trash" data-bs-toggle="tooltip"
                                                                            data-original-title="delete"></i>
                                                                    </button>
                                                                     
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<div id="showAlertVer" class="modal" style="top: 40px;">
    <div class="modal-dialog">
        <div class="modal-content animate">
            <div class="modal-header">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">×</button>
                <h4 class="modal-title">Warning</h4>
            </div>

            <div class="modal-body" style="max-height: calc(100vh - 210px);overflow-y: auto;">
                <!-- auto scroll -->
                <span><b> Employee Type data will be deleted permanently. Are you sure you want to continue?</b></span>
            </div>

            <div class="modal-footer" >
                <button type="button" class="btn btn-danger medium" (click)="deleteEmployeetype()">OK</button>
                <button type="button" class="btn btn-primary" (click)="closeMRQModal()">Cancel</button>
            </div>

        </div>
    </div>
</div>