import { Component, OnInit } from '@angular/core';
import { EmployeeLeaveService } from '../services/employee-leave.service';
import { CommonService } from '../services/common.service';

@Component({
  selector: 'app-task-report',
  templateUrl: './task-report.component.html',
  styleUrls: ['./task-report.component.css']
})
export class TaskReportComponent implements OnInit {

  fromDate: any;
  toDate: any;
  taskStatus: any = 'All';
  query: any = "";
  employeeMasterLucene: any = [];
  ActiveInativeLuceneFilter: any;
  showTaskReport: true;
  employeeLuceneFilter: any = 'Active';
  selectedValue : string='Active';
  taskMasterDetail: any = [];
  limitPara: number;
  offsetPara: number;
  leavingdate: any;
  
  uniqueemployeeid: String;
  constructor(private empLeaveService: EmployeeLeaveService, private commonService: CommonService) {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const day = currentDate.getDate();
    this.fromDate = `${year}-${month.toString().padStart(2, '0')}-01`;
    const lastDay = new Date(year, month, 0).getDate();
    this.toDate = `${year}-${month.toString().padStart(2, '0')}-${lastDay.toString().padStart(2, '0')}`;
  }

  ngOnInit(): void {
    this.limitPara = 13;
    this.offsetPara = 0;

  }

  search() {
    this.getTask();
  }

  clear() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    this.fromDate = `${year}-${month.toString().padStart(2, '0')}-01`;
    const lastDay = new Date(year, month, 0).getDate();
    this.toDate = `${year}-${month.toString().padStart(2, '0')}-${lastDay.toString().padStart(2, '0')}`;
    this.query = '';
    this.taskStatus = 'All';
    this.taskMasterDetail=[];
  }

  onStatusChange() {
    this.getEmployeeByNameOrId();
  }

  getEmployeeByNameOrId() {
    console.log("Len ", this.query);
  
    // Ensure the query length is valid (either <= 2 or >= 3)
    if (this.query?.length <= 2 || this.query?.length >= 3) {
      this.empLeaveService.getEmployeeByNameOrUniqueId(this.query).subscribe(
        data => {
          console.log("Emp Data ", data);
  
          // Check if data.employeeMasterData has any entries
          if (data.employeeMasterData.length > 0) {
            let filteredEmployees = data.employeeMasterData;
  
            // Get the current date for comparison
            const currentDate = new Date();
            console.log("Current Date: ", currentDate);  // Log current date for debugging
  
            // Apply filtering logic based on the selected employee status
            if (this.selectedValue === 'Active') {
              filteredEmployees = filteredEmployees.filter(emp => {
                const leavingDate = emp.leavingdateString ? new Date(emp.leavingdateString) : null;
                console.log("Leaving Date for " + emp.name + ": ", leavingDate);  // Log leaving date for debugging
                // Active employees should have leavingdate in the future or null
                return leavingDate === null || leavingDate > currentDate;
              });
            } else if (this.selectedValue === 'Inactive') {
              filteredEmployees = filteredEmployees.filter(emp => {
                const leavingDate = emp.leavingdateString ? new Date(emp.leavingdateString) : null;
                console.log("Leaving Date for " + emp.name + ": ", leavingDate);  // Log leaving date for debugging
                // Inactive employees should have leavingdate in the past or null
                return leavingDate && leavingDate < currentDate;
              });
            }
  
            // If the status is 'All', no filter is applied
            if (this.selectedValue === 'All') {
              filteredEmployees = data.employeeMasterData;
            }
  
            // Assign filtered data to the employeeMasterLucene
            this.employeeMasterLucene = filteredEmployees;
  
            // Log the data and leaving date of the first employee in the filtered list
            if (this.employeeMasterLucene.length > 0) {
              this.leavingdate = this.employeeMasterLucene[0].leavingdateString; // Extract the leaving date
              console.log("Filtered Employee Data: ", this.employeeMasterLucene);
              console.log("leavingdate", this.leavingdate);
            } else {
              console.log("No employee data found.");
            }
          } else {
            console.log("No employee data found.");
          }
        },
        error => {
          console.log("Error ", error);
        }
      );
    }
  
    // This line will still be executed before the async request completes,
    // so you may not have a value for 'this.leavingdate' here.
    console.log("Unique Id : " + this.uniqueemployeeid);
  }

  onScroll() {
    this.offsetPara = this.offsetPara + 13;
    console.log("limit = ? ", this.limitPara, "offset = +13 ", this.offsetPara)
    this.getTask();

  }

  limitpara() {
    this.offsetPara = 0;
  }

  getTask() {
    console.log("get task ", this.query);

    const [name, id] = this.query?.split(' / ');
    let selectedName = name;
    let selectedEmpId = id;

    console.log("selecteduniqueeEmpId", selectedName, "  ", selectedEmpId);
    console.log("fromDate: ", this.fromDate, "toDate: ", this.toDate, "page limit para :", this.limitPara, "page limit offset :", this.offsetPara);

    let employeename = (<HTMLInputElement>document.getElementById('employeename')).value;
    if (!employeename) {
      employeename = null;
    } else {
      employeename = employeename.trim()
    }
    if (!selectedEmpId) {
      selectedEmpId = "0";
    } else {
      selectedEmpId = id;
    }

    this.commonService.getTaskForReport(selectedEmpId, this.limitPara, this.offsetPara, this.fromDate, this.toDate, this.taskStatus,this.selectedValue).subscribe(
      data => {

        if (this.offsetPara == 0) {
          this.taskMasterDetail = data.taskMaster;

        } else {
          this.taskMasterDetail = this.taskMasterDetail.concat(data.taskMaster);
        }
      }, error => {
        this.taskMasterDetail = [];
      }
    );

  }

}
