import { Component, HostListener, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EmployeeLeaveService } from '../services/employee-leave.service';
import { EmployeeMasterservice } from '../services/employeemaster.service';
import { EmployeeLeaveApplied } from '../models/employee-leave-applied.model';
import { EventCalendar } from '../models/EventCalendar';
import { CommonService } from '../services/common.service';

declare var $: any;

@Component({
  selector: 'app-event-calendar-report',
  templateUrl: './event-calendar-report.component.html',
  styleUrls: ['./event-calendar-report.component.css']
})
export class EventCalendarReportComponent implements OnInit {

  // constructor() { }

  // ngOnInit(): void {
  // }



  Date: String;
  //annualName: String;
  eventName: String;
  creditedDay: String = "F";
  colorCode: String;
  // holiday:any;

  //annualHolidayId: number;
  eventId: number;
  //annualhoildaylist: AnnualHoliday[];
  eventlist: EventCalendar[];

  fromDate: string;
  toDate: string;
  addOrUpdate: boolean = true;
  saveOrUpdate: boolean = true;
  title: string;
  info: any;
  //DeleteAnnualHoliday:AnnualHoliday ;
  DeleteEventday: EventCalendar;
  offsetPara: number;
  limitPara: any;
  constructor(private empLeaveService: EmployeeLeaveService, private commonService: CommonService, private route: ActivatedRoute, private render: Renderer2,
    private employeeMasterservice: EmployeeMasterservice) {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    this.fromDate = `${year}-${month.toString().padStart(2, '0')}-01`;
    const lastDay = new Date(year, month, 0).getDate();
    this.toDate = `${year}-${month.toString().padStart(2, '0')}-${lastDay.toString().padStart(2, '0')}`;
    this.title = "List Of Events";




  }
  ngOnInit(): void {
    this.geteventDayList();


    this.render.listen(document.getElementById('navigateclient'), 'click', () => {
      $('html,body').animate({
        scrollTop: $(`#add-update-client`).offset().top - 90
      },
        'slow');
    })

  }

  geteventDayList(): void {
    this.commonService.getEventDays().subscribe(data => {
      // console.log(data, "annual holiday list")
      this.eventlist = data.eventDayList;
      console.log("event days list", this.eventlist);


    });
  }

  limitpara() {
    this.offsetPara = 0;
    console.log(" on clikc limit para ", this.limitPara)
    console.log("this offset", this.offsetPara)
  }

  clear() {
    this.fromDate = '';
    this.toDate = '';

  }

}
