import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CommonService } from './common.service';
import { EmployeeLeaveInfo } from '../models/employee-leave-info.model';
import { TaskDetailsForLeave } from '../models/task-details-for-leave.model';
import { LeaveWorkFlow } from '../models/leaveWorkflow';
import { EmployeeLeaveCompOff } from '../models/employeeLeaveCompOff';
import { Resignation } from '../models/Resignation';

@Injectable({
  providedIn: 'root'
})
export class EmployeeLeaveService {
  getResignationList() {
    throw new Error('Method not implemented.');
  }
  getLeaveForApprovalToday() {
    throw new Error('Method not implemented.');
  }
  protected basePath = '/api';
  public defaultHeaders: Headers = new Headers();
  public url!: string;
  public colorList = ['#33c5ff', '#f7284a', '#01c353', '#fe7700', '#c3c001', '#33e0ff', '#6574cd', '#ef4eb8', '#ecb403', '#17a2b8'];
  public empLeaves: string[] = []
  constructor(private httpclient: HttpClient, private commonService: CommonService) { }

  public getAllLeavesById(id: any): Observable<any> {
    this.url = '/v1/leavemasterbyid';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('uniqueEmployeeId', id);
    console.log(" uid is ", id)
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  public getAllLeaves(): Observable<any> {
    this.url = '/v1/leavemaster';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  public saveEmployeeLeave(employeeLeave: EmployeeLeaveInfo): Observable<any> {
    this.url = '/v1/saving-leave-applied';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    console.log("save employee on submit ")
    return this.commonService.postWithHttpInfo(employeeLeave, queryParameters, path);
  }

//apply resignation
public submitResignation(employeeLeave: Resignation): Observable<any> {
  this.url = '/v1/saving-Resignation-applied';
  const path = this.basePath + this.url;
  let queryParameters = new HttpParams();
  console.log("save resignations on submit ")
  return this.commonService.postWithHttpInfo(employeeLeave, queryParameters, path);
}
//get submitted_resignation_list
public getresignations_list(uniqueemployeeid: number): Observable<any> {
  this.url = '/v1/get-submitted-resignations-list';
  const path = this.basePath + this.url;
  let queryParameters = new HttpParams();
  queryParameters = queryParameters.set('uniqueEmployeeId', uniqueemployeeid);
  return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
}
//update submitted_resignation_list
public updateResignation(employeeLeave: Resignation): Observable<any> {
  this.url = '/v1/update-resignation-applied';
  const path = this.basePath + this.url;
  let queryParameters = new HttpParams();
  console.log(" update resignation ")
  return this.commonService.postWithHttpInfo(employeeLeave, queryParameters, path);
}
//delete submitted_resignation_list
public deleteResignation(id: number): Observable<any> {
  console.log("delete id  : ", id);
  this.url = '/v1/delete-submitted-resignation';
  const path = this.basePath + this.url;
  let queryParameters = new HttpParams();
  queryParameters = queryParameters.set('id', id);
  return this.commonService.deleteQueryParamWithHttpInfo(queryParameters,path);
}

  public saveCompOffLeave(employeeLeave: EmployeeLeaveCompOff): Observable<any> {
    this.url = '/v1/saving-comp-off-apply';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    console.log("save employee on submit ")
    return this.commonService.postWithHttpInfo(employeeLeave, queryParameters, path);
  }

  public getLeaveForApproval(type:any,empStatus:any, limitPara: any, offsetPara: any): Observable<any> {
    this.url = '/v1/getleave-forapproval';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('type', type);
    queryParameters = queryParameters.set('empStatus', empStatus);
    queryParameters = queryParameters.set('limitPara', limitPara);
    queryParameters = queryParameters.set('offsetPara', offsetPara);
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  }


  // public getCompOffForApproval(): Observable<any> {
  //   this.url = '/v1/getCompOff-forapproval';
  //   const path = this.basePath + this.url;
  //   let queryParameters = new HttpParams();
  //   return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  // }
  public getCompOffForApproval(uid: any, empId: any, employeeName: any,empStatus: any, statusFilter: any, limitPara: any, offsetPara: any): Observable<any> {
    this.url = '/v1/getCompOff-forapproval';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    if (employeeName !== null) {
      queryParameters = queryParameters.set('employeeName', employeeName);
    }
    queryParameters = queryParameters.set('uniqueEmpid', uid);
    queryParameters = queryParameters.set('empid', empId);
    queryParameters = queryParameters.set('empStatus', empStatus);
    queryParameters = queryParameters.set('statusFilter', statusFilter);
    queryParameters = queryParameters.set('limitPara', limitPara);
    queryParameters = queryParameters.set('offsetPara', offsetPara);
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path)
  }

  // old
  // public leaveForApproval(taskDetails:TaskDetailsForLeave[]):Observable<any>{
  //   console.log("Serv ",taskDetails);
  //   this.url = '/v1/approveLeavesOfEmployee';
  //   const path = this.basePath + this.url;
  //   let queryParameters = new HttpParams();
  //   return this.commonService.postWithHttpInfo(taskDetails,queryParameters,path);
  // }

  public leaveForApproval(taskDetails: LeaveWorkFlow[], approve_reject: boolean): Observable<any> {
    console.log("both for approve and reject  ", taskDetails);

    this.url = '/v1/approveLeavesOfEmployee';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('approve_reject', approve_reject);
    return this.commonService.postWithHttpInfo(taskDetails, queryParameters, path);
  }

  public compOffForApproval(taskDetails: LeaveWorkFlow[], approve_reject: boolean): Observable<any> {
    console.log("both for approve and reject  ", taskDetails);
    this.url = '/v1/approveCompOffOfEmployee';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('approve_reject', approve_reject);
    return this.commonService.postWithHttpInfo(taskDetails, queryParameters, path);
  }

  getEmployeeLeaveAppliedSummary(uniqueEmployeeId: number,selectedtype: any): Observable<any> {
    this.url = '/v1/leave-history';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    if (uniqueEmployeeId !== null || uniqueEmployeeId !== undefined) {
      queryParameters = queryParameters.set('uniqueEmployeeId', uniqueEmployeeId);
    }
    queryParameters = queryParameters.set('selectedtype', selectedtype);
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  deleteEmployeeLeaveApplied(employeeLeaveApplied: any): Observable<any> {
    this.url = '/v1/delete-leave-applied';
    const path = this.basePath + this.url;
    const options = {
      body: employeeLeaveApplied
    };
    return this.httpclient.request('delete', path, options);
  }

  deleteEmployeeCompOffApplied(employeeLeaveApplied: any): Observable<any> {
    this.url = '/v1/delete-CompOff-applied';
    const path = this.basePath + this.url;
    const options = {
      body: employeeLeaveApplied
    };
    return this.httpclient.request('delete', path, options);
  }

  getEmployeeLeaveOverview(uniqueemployeeId: any) {
    this.url = '/v1/employee-leave-overview';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    if (uniqueemployeeId !== null || uniqueemployeeId !== undefined) {
      queryParameters = queryParameters.set('uniqueemployeeId', uniqueemployeeId);
    }
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  getEmployeesLeaveAppliedSummary(employeeName: any, fromDate: any, toDate: any,empStatus: any, statusValue: any,selectedValue:any, limitPara: any, offsetPara: any): Observable<any> {
    // getEmployeesLeaveAppliedSummary(employeeName : any, fromDate:any, toDate:any ,limitPara : any, offsetPara : any):Observable<any>{
    this.url = '/v1/employee-applied-leaves';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    if (employeeName !== null) {
      queryParameters = queryParameters.set('employeeName', employeeName);
    }
    queryParameters = queryParameters.set('limitPara', limitPara);
    queryParameters = queryParameters.set('offsetPara', offsetPara);
    queryParameters = queryParameters.set('fromDate', fromDate);
    queryParameters = queryParameters.set('toDate', toDate);
    queryParameters = queryParameters.set('toDate', toDate);
    queryParameters = queryParameters.set('empStatus', empStatus);
    queryParameters = queryParameters.set('statusValue', statusValue);
    queryParameters = queryParameters.set('selectedValue', selectedValue);

    console.log("Satus value : ", statusValue)
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  }



  getEmployeeByNameOrUniqueId(query: any): Observable<any> {
    this.url = '/v1/employeesearch';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    if (query !== null) {
      queryParameters = queryParameters.set('query', query);
    }
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  getEmployeesAbsentList(uniqueemployeeid: any) {
    this.url = '/v1/empLeave';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('uniqueemployeeid', uniqueemployeeid);
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  public getChartColors() {
    return this.colorList;
  }

  public setLeaveNames(leaveNames) {
    this.empLeaves = leaveNames;
  }

  public getLeaveNames() {
    return this.empLeaves;
  }

  getleavebalance(uempid: number, type: string, fromdate: string) {
    this.url = '/v1/getCarryforward';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('uempid', uempid);
    queryParameters = queryParameters.set('type', type);
    queryParameters = queryParameters.set('fromdate', fromdate);
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  getLeaveForPastDate(key: any) {
    this.url = '/v1/get-leave-for-past-date';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('key', key);
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  getEmpWeeklyOffByDate(fromDate: any, toDate: any) {
    this.url = '/v1/employee-weeklyoff';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('fromdate', fromDate);
    queryParameters = queryParameters.set('todate', toDate);
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  getTotalleaveapplied(uempid: number, type: string, fdate: string, tdate: string, indic: string) {
    this.url = '/v1/getAppliedLeave';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('uempid', uempid);
    queryParameters = queryParameters.set('type', type);
    queryParameters = queryParameters.set('fromdate', fdate);
    queryParameters = queryParameters.set('todate', tdate);
    queryParameters = queryParameters.set('indic', indic);
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  getInterveneLeave(leavetype: string) {
    this.url = '/v1/checkInterveneLeave';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('leavetype', leavetype);
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  }
  

  public getsuspensionlist(): Observable<any> {
    this.url = '/v1/getsuspensionlist';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path)
  }

  //save leave with attachment upload
  public saveEmployeeLeaves( employeeLeave: EmployeeLeaveInfo,file?: File): Observable<any> {
    console.log("value sent employeeLeave", employeeLeave);
    this.url = '/v1/saving-leave-applied';
    const path = this.basePath + this.url;
    const formData: FormData = new FormData();
    
    if(file!= null){
    formData.append('file', file);
    }
    // Convert the JSON data to a Blob and append it to the FormData
    const jsonBlob = new Blob([JSON.stringify(employeeLeave)], { type: 'application/json' });
    formData.append('employeeLeave', jsonBlob, 'employeeLeave.json');
  
    // Use HttpHeaders correctly
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
  
    // Use set instead of append for HttpHeaders
    const httpOptions = { headers: headers };
  
    // Use proper type for the return value (Observable<HttpResponse<any>>)
    return this.httpclient.post<any>(path, formData, httpOptions);
  }

  //view document
  public viewdocument(filepath:string): Observable<Blob> {
    this.url = '/v1/viewRequesteddocuments';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('filepath', filepath);
    console.log(queryParameters);
    return this.commonService.getByQueryParamWithHttpInfo( queryParameters, path, {responseType: 'arraybuffer'});
    // console.log("Here you can see the rsponse path: " + path)
  }
}
