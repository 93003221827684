import { Component, OnInit } from '@angular/core';
import { EmployeeLeaveService } from '../services/employee-leave.service';
import { EmployeeMasterservice } from '../services/employeemaster.service';
import { Resignation } from '../models/Resignation';
import { GeneralService } from '../services/general.service';
declare var $: any
@Component({
  selector: 'app-resignation',
  templateUrl: './resignation.component.html',
  styleUrls: ['./resignation.component.css']
})
export class ResignationComponent implements OnInit {
  uniqueEmployeeId = Number(sessionStorage.getItem('empId'));
  submissionDate: string = ''; // Auto-populated when the form loads
  requestedLastWorkingDate: string = ''; // Editable last working date
  lastWorkingDate: any = ''; // Auto-calculated based on calculated last working date & submission date
  // requiredEditableDate: string = ''; // Editable exit date
  reason: string = ''; // Selected reason from dropdown
  remarks: string = ''; // Input for remarks
  status: string;
  resignations_List: any[] = [];
  editingResignationId: number | null = null;
  isEditing: boolean = false;
  showRequestedEditableDate: boolean = false;
  id: number;
  notice_Period: number = 0;


  constructor(private empLeaveService: EmployeeLeaveService, private employeeMasterservice: EmployeeMasterservice, private generalservice: GeneralService) { }

  ngOnInit(): void {
    this.setSubmissionDate();
    this.getResignationsApplied();
    this.getNoticeperiod_from_employeemaster();
    this.updateLastWorkingDate();
  }

  // Function to auto-populate the submission date when the form loads
  setSubmissionDate(): void {
    const today = new Date();
    this.submissionDate = today.toISOString().split('T')[0]; // Format: YYYY-MM-DD

  }

  updateFromrequestedLastWorkingDate(event: any): void {
    const selectedDate = new Date(event.target.value);
    const submissionDate = new Date(this.submissionDate);
    const lastWorkingDate = new Date(this.lastWorkingDate);

    if (isNaN(selectedDate.getTime())) {
      this.requestedLastWorkingDate = '';
      return;
    }

    // Prevent selecting a date before the submission date
    if (selectedDate < submissionDate) {
      this.alertmessages("Requested Last Working Date cannot be before Submission Date.", "danger");
      this.requestedLastWorkingDate = ''; // Reset input
      return;
    }

    // Prevent selecting a date beyond the last working date
    if (selectedDate > lastWorkingDate) {
      this.alertmessages(`Requested Last Working Date cannot be beyond the Last Working Date (${this.lastWorkingDate}).`, "danger");
      this.requestedLastWorkingDate = ''; // Reset input
      return;
    }

    // If valid, assign the date
    this.requestedLastWorkingDate = selectedDate.toISOString().split('T')[0];
    this.updateLastWorkingDate();
  }

  // updateFromRequestedLastWorkingDate(event: any): void {
  //   const selectedDate = new Date(event.target.value);
  //   const submissionDate = new Date(this.submissionDate);
  //   const lastWorkingDate = new Date(this.lastWorkingDate);

  //   if (isNaN(selectedDate.getTime())) {
  //     this.requestedLastWorkingDate = '';
  //     return;
  //   }

  //   if (selectedDate < submissionDate) {
  //     this.alertmessages("Requested Last Working Date cannot be before Submission Date.", "danger");
  //     this.requestedLastWorkingDate = '';
  //     return;
  //   }

  //   if (selectedDate > lastWorkingDate) {
  //     this.alertmessages(`Requested Last Working Date cannot be beyond ${this.lastWorkingDate}.`, "danger");
  //     this.requestedLastWorkingDate = '';
  //     return;
  //   }

  //   this.requestedLastWorkingDate = selectedDate.toISOString().split('T')[0];
  //   this.updateLastWorkingDate(); 
  // }


  updateLastWorkingDate(): void {
    if (this.submissionDate && this.notice_Period > 0) {
      let submissionDate = new Date(this.submissionDate);
      submissionDate.setDate(submissionDate.getDate() + this.notice_Period);
      this.lastWorkingDate = submissionDate.toISOString().split('T')[0]; // Set last working date
    }
  }
  submit() {
    if (this.status === 'Approved') {
      this.alertmessages("Your resignation has already been approved. You cannot apply again.", "danger");
      return;
    }
    if (!this.requestedLastWorkingDate || !this.reason.trim() || !this.remarks.trim()) {
      this.alertmessages("Please fill in all required fields.", "danger");
      return;
    }

    console.log("Submitting Resignation");

    let resignationData: Resignation = {
      submissionDate: this.submissionDate,
      requestedLastWorkingDate: this.requestedLastWorkingDate,
      notice_Period: this.notice_Period,
      lastWorkingDate: this.lastWorkingDate,
      reason: this.reason.trim(),
      remarks: this.remarks.trim(),
      uniqueEmployeeId: this.uniqueEmployeeId,
      employeeId: Number(sessionStorage.getItem('empId')),
      id: this.id
    };

    console.log("Resignation Data: ", resignationData);
    debugger
    this.empLeaveService.submitResignation(resignationData).subscribe(
      data => {
        this.getResignationsApplied();
        console.log("Response Data: ", data);
        if (data.successMessages && data.successMessages.length > 0) {
          let messageKey = data.successMessages[0]['key'];
          if (messageKey === 'Resignation Successfully Submitted') {
            this.alertmessages("Resignation submitted successfully!", "success");
          } else {
            this.alertmessages("Resignation submission failed Please Contact System Administator.", "danger");
          }
        }
        this.clear();
      },
      error => {
        const response = error.error;
        if (response.errorMessages && response.errorMessages.length > 0) {
          let err: string = response.errorMessages[0]['key'];
          if (err.toLowerCase() === "AlreadyExistsException".toLowerCase()) {
            this.alertmessages("A resignation request has already been submitted for the selected date.", "danger");
          }
        }
        this.clear();
      }
    );
  }
  updateResignation() {

    if (this.status === 'Approved') {
      this.alertmessages("You cannot update an approved resignation.", "danger");
      return;
    }
    if (!this.requestedLastWorkingDate || !this.reason.trim()) {
      this.alertmessages("Please fill in all required fields.", "danger");
      return;
    }

    let updatedResignation: Resignation = {
      id: this.id,
      employeeId: this.editingResignationId,
      requestedLastWorkingDate: this.requestedLastWorkingDate,
      lastWorkingDate: this.lastWorkingDate,
      notice_Period: this.notice_Period,
      reason: this.reason.trim(),
      remarks: this.remarks.trim(),
      uniqueEmployeeId: this.uniqueEmployeeId,
      submissionDate: this.submissionDate,
    };

    console.log("Updating Resignation: ", updatedResignation);

    this.empLeaveService.updateResignation(updatedResignation).subscribe(
      data => {
        this.alertmessages("Resignation updated successfully!", "success");
        this.isEditing = false; // Disable edit mode
        this.showRequestedEditableDate = false; // Hide field after update
        this.editingResignationId = null; // Reset editing ID
        this.clear();
        this.getResignationsApplied(); // Refresh table
      },
      error => {
        console.log(error);
        this.alertmessages("Error updating resignation.", "danger");
      }
    );
  }
  //get method for getting submitted resignations
  getResignationsApplied() {
    this.empLeaveService.getresignations_list(this.uniqueEmployeeId).subscribe(
      data => {
        console.log("Resignations List:", data);
        this.resignations_List = data.submitted_resignations_list;
        console.log("this.resignationsList", this.resignations_List);

        // Check if any resignation has been approved
        const approvedResignation = this.resignations_List.find(res => res.status === 'Approved');
        if (approvedResignation) {
          this.status = 'Approved';
        } else {
          const rejectedResignation = this.resignations_List.find(res => res.status === 'Rejected');
          this.status = rejectedResignation ? 'Rejected' : 'Pending';
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  editResignation(resignation: any) {
    //
    debugger;
    console.log("resignation info ", resignation);

    if (resignation.status === 'Approved') {
      this.alertmessages("You cannot edit an approved resignation.", "danger");
      return;
    }

    this.isEditing = true;
    this.editingResignationId = resignation.employeeId;
    this.requestedLastWorkingDate = resignation.requestedLastWorkingDate ? resignation.requestedLastWorkingDate : '';
    this.reason = resignation.reason;
    this.remarks = resignation.remarks;
    this.id = resignation.id;


    console.log("resignation lwd  ", resignation.lastWorkingDate);
    const lastdate = new Date(resignation.lastWorkingDate);
    this.lastWorkingDate = lastdate.toISOString().split('T')[0];
    console.log("last working date in date ", lastdate);
    console.log("conversino ", lastdate.toISOString().split('T')[0]);
    console.log("last workng date ", this.lastWorkingDate);





    // Ensure lastWorkingDate is assigned properly
    // this.lastWorkingDate = resignation.lastWorkingDate ? resignation.lastWorkingDate : this.updateLastWorkingDate();

    console.log(this.lastWorkingDate)
  }

  deleteresignation() {

    if (this.status === 'Approved') {
      this.alertmessages("You cannot delete an approved resignation.", "danger");
      return;
    }
    console.log("delete resignation : ", this.id, " ");

    this.empLeaveService.deleteResignation(this.id).subscribe(
      (data) => {
        // console.log("delete  Expense ", data);
        this.alertmessages("Successfully deleted ", "success");
        this.clear();
        this.getResignationsApplied();
        $('#showAlertVer').modal('toggle');
      },
      (error) => {
        this.alertmessages("Expense is in Use ", "danger");
        $('#showAlertVer').modal('toggle');
        console.log(error);
      }
    );
    this.clear();
  }
  onDeleteHandler(id) {
    console.log(id)
    this.id = id;
    $('#showAlertVer').modal('toggle');
  }
  closeMRQModal() {
    $('#showAlertVer').modal('toggle');

  }


  onScroll() {
    this.getResignationsApplied;

  }
  // Clear form fields
  clear(): void {
    this.requestedLastWorkingDate = '';
    this.reason = '';
    this.remarks = '';
  }
  alertmessages(message: string, sign: string) {
    let alertDiv = document.createElement('div');
    alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
       <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message}</div>`
    let alertcomp = document.querySelector('.alertcomp');
    alertcomp.append(alertDiv);
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    setTimeout(function () {
      alertcomp.removeChild(alertDiv);
    }, 5000);
  }



  getNoticeperiod_from_employeemaster() {
    this.generalservice.getNoticeperiod_from_employeemaster(this.uniqueEmployeeId).subscribe(
      data => {
        console.log("Notice period:", data);
        this.notice_Period = Number(data.employeemaster_noticeperiod);
        this.updateLastWorkingDate();
      },
      error => {
        console.log(error);
      }
    );
  }
}
