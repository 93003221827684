<div class="app-content main-content">
    <div class="side-app main-container">
        <div class="alertcomp"></div>

        <!-- <div class="card-body px-0">
            <div class="page-leftheader my-3">
                <div class="page-title">Salary Detail Master</div>
            </div>
        </div> -->

        <div class="row">
            <div class="col-md-12">
                <div class="card-content">
                    <form #salaryDetailForm="ngForm">
                        <section>

                            <div class="card">
                                <div class="card-body">
                                    <div class=" row col-md-12 col-sm-12 col-xs-12">
                                        <div class="col-md-6 col-sm-6 col-xs-6">
                                            <div class="form-group label-floating">
                                                <label class="form-label">Enter Salary
                                                    Detail
                                                    Name</label>
                                                <div>
                                                    <input type="text" name="detailname"
                                                        class="form-control col-md-12 col-xs-12" autocomplete="off"
                                                        placeholder="Enter Salary Detail Name" autofocus="true"
                                                        [(ngModel)]="insertDetailMaster.salaryDetailName" required
                                                        #SALARYDETAILNAME_m="ngModel"
                                                        [ngClass]="(!SALARYDETAILNAME_m.valid && SALARYDETAILNAME_m.touched) ? 'is-invalid' : ''" />
                                                </div>
                                                <span class="help-text text-danger"
                                                    *ngIf="(!SALARYDETAILNAME_m.valid && SALARYDETAILNAME_m.touched)">Enter
                                                    Detail Name
                                                </span>
                                            </div>
                                        </div>
                                        <div
                                            class="col-md-6 col-sm-6 col-xs-6 justify-content-between align-items-start">
                                            <div class="form-group label-floating">
                                                <label class="form-label">&nbsp;</label>
                                                <button *ngIf="addOrUpdate; else updateButton" type="button"
                                                    class="btn btn-primary w-40" id="navigatetarget"
                                                    style="margin-right: 3vw;margin-left: 2vw;"
                                                    (click)="addSalaryDetailButton()">
                                                    Add
                                                </button>
                                                <ng-template #updateButton>
                                                    <button type="button" id="navigatetarget"
                                                        class="btn btn-primary w-40"
                                                        style="margin-right: 3vw;margin-left:3vw"
                                                        (click)="updateSalaryDetail()">
                                                        Update
                                                    </button>
                                                </ng-template>
                                                <button type="button" class="btn btn-danger w-40"
                                                    (click)="viewSalaryDetailButton()">
                                                    View
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="clearfix"> </div>
                                </div>
                            </div>
                            <div class="empty-div-one" id="navigatedest"></div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="card" *ngIf="add_view_toggle == 'addSalaryDetail' ">
                                        <div class="card-header border-bottom-0">
                                            <h3 class="card-title ">Salary Master Configuration </h3>
                                        </div>
                                        <div class="card-body">
                                            <div class="panel-group1" id="accordion1">
                                                <div class="panel panel-default mb-4 overflow-hidden br-7">
                                                    <div class="panel-heading1">
                                                        <h4 class="panel-title1">
                                                            <a class="accordion-toggle collapsed  "
                                                                data-bs-toggle="collapse" data-parent="#accordion"
                                                                href="#collapseOne" aria-expanded="false">Salary
                                                                Detail Master</a>
                                                        </h4>
                                                    </div>
                                                    <div id="collapseOne" class="panel-collapse collapse show"
                                                        role="tabpanel" aria-expanded="false">
                                                        <div class="panel-body">
                                                            <div class="card-body">
                                                                <div class="row col-md-12 col-sm-12 col-xs-12">
                                                                    <div class="col-md-6 col-sm-6 col-xs-6">
                                                                        <div class="form-group label-floating">
                                                                            <label class="form-label">Enter Salary Detail
                                                                            Name<span style="color: rgb(248, 111, 111);">
                                                                                &nbsp;*</span></label>
                                                                            <div>
                                                                                <input type="text" name="detailname"
                                                                                    class="form-control col-md-12 col-xs-12"
                                                                                    autocomplete="off"
                                                                                    placeholder="Enter Salary Detail Name"
                                                                                    autofocus="true"
                                                                                    [(ngModel)]="insertDetailMaster.salaryDetailName"
                                                                                    required
                                                                                    #SALARYDETAILNAME_m="ngModel"
                                                                                    [ngClass]="(!SALARYDETAILNAME_m.valid && SALARYDETAILNAME_m.touched) ? 'is-invalid' : ''" />
                                                                            </div>
                                                                            <span class="help-text text-danger"
                                                                                *ngIf="(!SALARYDETAILNAME_m.valid && SALARYDETAILNAME_m.touched)">Enter
                                                                                Detail Name
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 col-sm-6 col-xs-6">
                                                                        <div class="form-group label-floating">
                                                                            <label class="form-label">Select Salary
                                                                                Head<span style="color: rgb(248, 111, 111);">
                                                                                    &nbsp;*</span></label>
                                                                            <div class="w-100">
                                                                                <select required
                                                                                    class="form-control col-md-12 col-xs-12"
                                                                                    style="height: 38px;"
                                                                                    data-style="btn btn-drpdwn btn-round"
                                                                                    [(ngModel)]="insertDetailMaster.salaryHeadId"
                                                                                    id="SalaryHead" name="SalaryHead" [ngClass]="{'disable': addOrUpdate!=true}" [disabled]="!addOrUpdate">
                                                                                    <option value="">&nbsp;</option>
                                                                                    <option
                                                                                        *ngFor="let hd of salaryDetailForDropDown"
                                                                                        value="{{hd.salaryHeadId}}">
                                                                                        {{hd.salaryHeadName}}
                                                                                    </option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="row col-md-12 col-sm-12 col-xs-12">
                                                                    <div class="col-md-6 col-sm-6 col-xs-6">
                                                                        <div class="form-group label-floating">
                                                                            <label class="form-label">Use of Calc
                                                                                Indic<span style="color: rgb(248, 111, 111);">
                                                                                    &nbsp;*</span></label>
                                                                            <div class="w-100">
                                                                                <select
                                                                                    class="form-control col-md-12 col-xs-12"
                                                                                    style="height: 38px;"
                                                                                    data-style="btn btn-drpdwn btn-round"
                                                                                    [(ngModel)]="insertDetailMaster.useForCalcIndic"
                                                                                    id="USEFORCALCINDIC"
                                                                                    name="USEFORCALCINDIC">
                                                                                    <option value="Y">Yes</option>
                                                                                    <option value="M">No</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 col-sm-6 col-xs-6">
                                                                        <label class="form-label">Eligible for ESI
                                                                            criteria ?</label>

                                                                        <div class="row">
                                                                            <div class="w-42p">
                                                                                <label
                                                                                    class="form-label p-0 m-0">No</label>
                                                                            </div>
                                                                            <div class="w-50p">
                                                                                <label class="custom-switch">
                                                                                    <input type="checkbox"
                                                                                        name="custom-switch-checkbox ESICRETERIA"
                                                                                        class="custom-switch-input"
                                                                                        [(ngModel)]="insertDetailMaster.esicreteria">
                                                                                    <span
                                                                                        class="custom-switch-indicator"></span>
                                                                                </label>
                                                                            </div>
                                                                            <div class="w-60p">
                                                                                <label class="form-label">Yes</label>
                                                                            </div>
                                                                        </div>


                                                                        <!-- <div class="row">
                                                                            <div class="col-xl-2">
                                                                                <label class="form-label">No</label>
                                                                            </div>
                                                                            <div class="col-xl-2 pe-0">
                                                                                <label class="custom-switch">
                                                                                    <input type="checkbox"
                                                                                        name="custom-switch-checkbox ESICRETERIA"
                                                                                        class="custom-switch-input"
                                                                                        [(ngModel)]="insertDetailMaster.esicreteria">
                                                                                    <span
                                                                                        class="custom-switch-indicator"></span>
                                                                                </label>
                                                                            </div>
                                                                            <div class="col-xl-2">
                                                                                <label class="form-label">Yes</label>
                                                                            </div>
                                                                        </div> -->

                                                                        <!-- <div class="col-md-2">
                                                                                <input type="checkbox"
                                                                                    name="ESICRETERIA"
                                                                                    class="form-control col-md-6 col-xs-6"
                                                                                    autocomplete="off" autofocus="true"
                                                                                    [(ngModel)]="insertDetailMaster.esicreteria" />
                                                                            </div>
                                                                        </div> -->

                                                                    </div>
                                                                </div>
                                                                <div class="row col-md-12 col-sm-12 col-xs-12">
                                                                    <div class="col-md-6 col-sm-6 col-xs-6">
                                                                        <div class="form-group label-floating">
                                                                            <label class="form-label">Consider for Leave
                                                                                Encashment ?</label>

                                                                            <div class="row">
                                                                                <div class="w-42p">
                                                                                    <label
                                                                                        class="form-label p-0 m-0">No</label>
                                                                                </div>
                                                                                <div class="w-50p">
                                                                                    <label class="custom-switch">
                                                                                        <input type="checkbox"
                                                                                            name="custom-switch-checkbox LEAVEENCASHMENT"
                                                                                            class="custom-switch-input"
                                                                                            [(ngModel)]="insertDetailMaster.leaveEncashment">
                                                                                        <span
                                                                                            class="custom-switch-indicator"></span>
                                                                                    </label>
                                                                                </div>
                                                                                <div class="w-60p">
                                                                                    <label
                                                                                        class="form-label">Yes</label>
                                                                                </div>
                                                                            </div>

                                                                            <!-- <div class="col-md-2">
                                                                                <input type="checkbox"
                                                                                    name="LEAVEENCASHMENT"
                                                                                    class="form-control col-md-6 col-xs-6"
                                                                                    autocomplete="off" autofocus="true"
                                                                                    [(ngModel)]="insertDetailMaster.leaveEncashment" />
                                                                            </div> -->
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 col-sm-6 col-xs-6">
                                                                        <div class="form-group label-floating">
                                                                            <label class="form-label">Eligible for
                                                                                General
                                                                                Holiday Component Calc ?</label>

                                                                            <div class="row">
                                                                                <div class="w-42p">
                                                                                    <label
                                                                                        class="form-label p-0 m-0">No</label>
                                                                                </div>
                                                                                <div class="w-50p">
                                                                                    <label class="custom-switch">
                                                                                        <input type="checkbox"
                                                                                            name="custom-switch-checkbox GENHOLCALC"
                                                                                            class="custom-switch-input"
                                                                                            [(ngModel)]="insertDetailMaster.generalHolidayCal">
                                                                                        <span
                                                                                            class="custom-switch-indicator"></span>
                                                                                    </label>
                                                                                </div>
                                                                                <div class="w-60p">
                                                                                    <label
                                                                                        class="form-label">Yes</label>
                                                                                </div>
                                                                            </div>

                                                                            <!-- <div class="col-md-2">
                                                                                <input type="checkbox" name="GENHOLCALC"
                                                                                    class="form-control col-md-6 col-xs-6"
                                                                                    autocomplete="off" autofocus="true"
                                                                                    [(ngModel)]="insertDetailMaster.generalHolidayCal" />
                                                                            </div> -->
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="clearfix"> </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="panel panel-default  mb-4 overflow-hidden br-7">
                                                    <div class="panel-heading1">
                                                        <h4 class="panel-title1">
                                                            <a class="accordion-toggle collapsed  "
                                                                data-bs-toggle="collapse" data-parent="#accordion"
                                                                href="#collapseTwo" aria-expanded="false">Salary
                                                                Detail Rules</a>
                                                        </h4>
                                                    </div>
                                                    <div id="collapseTwo" class="panel-collapse collapse"
                                                        role="tabpanel" aria-expanded="false">
                                                        <div class="panel-body">
                                                            <div class="card-body">
                                                                <div class="row col-md-12 col-sm-12 col-xs-12">
                                                                    <div class="col-md-6 col-sm-6 col-xs-6">
                                                                        <div class="form-group label-floating">
                                                                            <label class="form-label">Consider for
                                                                                Taxable
                                                                                Salary ?</label>

                                                                            <div class="row">
                                                                                <div class="w-42p">
                                                                                    <label
                                                                                        class="form-label p-0 m-0">No</label>
                                                                                </div>
                                                                                <div class="w-50p">
                                                                                    <label class="custom-switch">
                                                                                        <input type="checkbox"
                                                                                            name="custom-switch-checkbox TAXABLESALARY"
                                                                                            class="custom-switch-input"
                                                                                            [(ngModel)]="insertDetailMaster.taxableSalary">
                                                                                        <span
                                                                                            class="custom-switch-indicator"></span>
                                                                                    </label>
                                                                                </div>
                                                                                <div class="w-60p">
                                                                                    <label
                                                                                        class="form-label">Yes</label>
                                                                                </div>
                                                                            </div>

                                                                            <!-- <div class="col-md-2">
                                                                                <input type="checkbox"
                                                                                    name="TAXABLESALARY"
                                                                                    class="form-control col-md-6 col-xs-6"
                                                                                    autocomplete="off" autofocus="true"
                                                                                    [(ngModel)]="insertDetailMaster.taxableSalary" />
                                                                            </div> -->
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 col-sm-6 col-xs-6">
                                                                        <div class="form-group label-floating">
                                                                            <label class="form-label">Is a Recovery
                                                                                Component ?</label>

                                                                            <div class="row">
                                                                                <div class="w-42p">
                                                                                    <label
                                                                                        class="form-label p-0 m-0">No</label>
                                                                                </div>
                                                                                <div class="w-50p">
                                                                                    <label class="custom-switch">
                                                                                        <input type="checkbox"
                                                                                            name="custom-switch-checkbox RECOVERYCOMP"
                                                                                            class="custom-switch-input"
                                                                                            [(ngModel)]="insertDetailMaster.recoveryComp">
                                                                                        <span
                                                                                            class="custom-switch-indicator"></span>
                                                                                    </label>
                                                                                </div>
                                                                                <div class="w-60p">
                                                                                    <label
                                                                                        class="form-label">Yes</label>
                                                                                </div>
                                                                            </div>

                                                                            <!-- <div class="col-md-2">
                                                                                <input type="checkbox"
                                                                                    name="RECOVERYCOMP"
                                                                                    class="form-control col-md-6 col-xs-6"
                                                                                    autocomplete="off" autofocus="true"
                                                                                    [(ngModel)]="insertDetailMaster.recoveryComp" />
                                                                            </div> -->
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="row col-md-12 col-sm-12 col-xs-12">
                                                                    <div class="col-md-6 col-sm-6 col-xs-6">
                                                                        <div class="form-group label-floating">
                                                                            <label class="form-label">Deduction under
                                                                                Section</label>
                                                                            <div class="w-100">
                                                                                <select
                                                                                    class="form-control col-md-12 col-xs-12"
                                                                                    style="height: 38px;"
                                                                                    data-style="btn btn-drpdwn btn-round"
                                                                                    [(ngModel)]="insertDetailMaster.savingsId"
                                                                                    id="deductionSec"
                                                                                    name="deductionSec">
                                                                                    <option *ngFor="let saving of savingsList" [value]="saving.id">
                                                                                        {{ saving.name }}
                                                                                    </option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 col-sm-6 col-xs-6">
                                                                        <div class="form-group label-floating">
                                                                            <label class="form-label">Enter Order of
                                                                                Salary
                                                                                Compnents</label>
                                                                            <div>
                                                                                <input type="text" name="" maxlength="2"
                                                                                    class="form-control col-md-12 col-xs-12"
                                                                                    autocomplete="off"
                                                                                    placeholder="Enter Order of Salary Compnents"
                                                                                    autofocus="true"
                                                                                    [(ngModel)]="insertDetailMaster.orderSalaryComponent"
                                                                                    required
                                                                                    #ORDERSALCOMPONENT_m="ngModel"
                                                                                    [ngClass]="(!ORDERSALCOMPONENT_m.valid && ORDERSALCOMPONENT_m.touched) ? 'is-invalid' : ''" />
                                                                            </div>
                                                                            <span class="help-text text-danger"
                                                                                *ngIf="(!ORDERSALCOMPONENT_m.valid && ORDERSALCOMPONENT_m.touched)">Enter
                                                                                Order of Salary
                                                                                Compnents
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="row col-md-12 col-sm-12 col-xs-12">
                                                                    <div class="col-md-6 col-sm-6 col-xs-6">
                                                                        <div class="form-group label-floating">
                                                                            <label class="form-label">Include for Gross
                                                                                ?</label>

                                                                            <div class="row">
                                                                                <div class="w-42p">
                                                                                    <label
                                                                                        class="form-label p-0 m-0">No</label>
                                                                                </div>
                                                                                <div class="w-50p">
                                                                                    <label class="custom-switch">
                                                                                        <input type="checkbox"
                                                                                            name="custom-switch-checkbox INCLUDEGROSS"
                                                                                            class="custom-switch-input"
                                                                                            [(ngModel)]="insertDetailMaster.includeGross">
                                                                                        <span
                                                                                            class="custom-switch-indicator"></span>
                                                                                    </label>
                                                                                </div>
                                                                                <div class="w-60p">
                                                                                    <label
                                                                                        class="form-label">Yes</label>
                                                                                </div>
                                                                            </div>

                                                                            <!-- <div class="col-md-2">
                                                                                <input type="checkbox"
                                                                                    name="INCLUDEGROSS"
                                                                                    class="form-control col-md-6 col-xs-6"
                                                                                    autocomplete="off" autofocus="true"
                                                                                    [(ngModel)]="insertDetailMaster.includeGross" />
                                                                            </div> -->
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 col-sm-6 col-xs-6">
                                                                        <div class="form-group label-floating">
                                                                            <label class="form-label">Eligible for
                                                                                DA?</label>

                                                                            <div class="row">
                                                                                <div class="w-42p">
                                                                                    <label
                                                                                        class="form-label p-0 m-0">No</label>
                                                                                </div>
                                                                                <div class="w-50p">
                                                                                    <label class="custom-switch">
                                                                                        <input type="checkbox"
                                                                                            name="custom-switch-checkbox ELIGIBLEDA"
                                                                                            class="custom-switch-input"
                                                                                            [(ngModel)]="insertDetailMaster.eligibleDa">
                                                                                        <span
                                                                                            class="custom-switch-indicator"></span>
                                                                                    </label>
                                                                                </div>
                                                                                <div class="w-60p">
                                                                                    <label
                                                                                        class="form-label">Yes</label>
                                                                                </div>
                                                                            </div>

                                                                            <!-- <div class="col-md-2">
                                                                                <input type="checkbox" name="ELIGIBLEDA"
                                                                                    class="form-control col-md-6 col-xs-6"
                                                                                    autocomplete="off" autofocus="true"
                                                                                    [(ngModel)]="insertDetailMaster.eligibleDa" />
                                                                            </div> -->
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="clearfix"> </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="panel panel-default  mb-4 overflow-hidden br-7">
                                                    <div class="panel-heading1">
                                                        <h4 class="panel-title1">
                                                            <a class="accordion-toggle collapsed  "
                                                                data-bs-toggle="collapse" data-parent="#accordion"
                                                                href="#collapseThree" aria-expanded="false">Salary
                                                                Detail Configuration</a>
                                                        </h4>
                                                    </div>
                                                    <div id="collapseThree" class="panel-collapse collapse"
                                                        role="tabpanel" aria-expanded="false">
                                                        <div class="panel-body">
                                                            <div class="card-body">

                                                                <div class="row col-md-12 col-sm-12 col-xs-12">
                                                                    <div class="col-md-6 col-sm-6 col-xs-6">
                                                                        <div class="form-group label-floating">
                                                                            <label class="form-label">Type of
                                                                                Component</label>
                                                                            <div class="w-100">
                                                                                <select (change)="onChange($event)"
                                                                                    class="form-control col-md-12 col-xs-12"
                                                                                    style="height: 38px;"
                                                                                    data-style="btn btn-drpdwn btn-round"
                                                                                    [(ngModel)]="insertDetailMaster.typeOfComponent"
                                                                                    id="TYPEOFCOMPONENT"
                                                                                    name="TYPEOFCOMPONENT">
                                                                                    <option value="Fixed Component">
                                                                                        Fixed Component</option>
                                                                                    <option
                                                                                        value="Rule Based Component">
                                                                                        Rule Based Component</option>
                                                                                    <option
                                                                                        value="Fixed Allowance Component">
                                                                                        Fixed Allowance Component
                                                                                    </option>
                                                                                    <option
                                                                                        value="Manually Fed Component">
                                                                                        Manually Fed Component</option>
                                                                                    <option
                                                                                        value="System Calculated Component">
                                                                                        System Calculated Component
                                                                                    </option>
                                                                                    <option
                                                                                        value="Expense Component">
                                                                                        Expense Component
                                                                                    </option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 col-sm-6 col-xs-6">
                                                                        <div class="form-group label-floating">
                                                                            <label class="form-label">&nbsp;</label>
                                                                            <div class="w-100">
                                                                                <select
                                                                                    class="form-control col-md-12 col-xs-12"
                                                                                    style="height: 38px;"
                                                                                    data-style="btn btn-drpdwn btn-round"
                                                                                    [(ngModel)]="insertDetailMaster.systemComponent"
                                                                                    id="SYSTEMCOMPONENT"
                                                                                    name="SYSTEMCOMPONENT">
                                                                                    <option
                                                                                        value="General Holiday Component calculation">
                                                                                        General Holiday Component
                                                                                        calculation</option>
                                                                                    <option
                                                                                        value="Shift Allowance Calculation">
                                                                                        Shift Allowance Calculation
                                                                                    </option>
                                                                                    <option
                                                                                        value="Conveyance Allowance calculation">
                                                                                        Conveyance Allowance calculation
                                                                                    </option>
                                                                                    <option value="ESI calculation">ESI
                                                                                        calculation </option>
                                                                                    <option value="DA calculation">DA
                                                                                        calculation</option>
                                                                                    <option
                                                                                        value="OT Allowance calculation">
                                                                                        OT Allowance calculation
                                                                                    </option>
                                                                                    <option
                                                                                        value="LIC Deduction Calculation">
                                                                                        LIC Deduction Calculation
                                                                                    </option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <!-- [ngClass]="{'disabled': insertDetailMaster.typeOfComponent !== 'SC'}" -->
                                                                <div class="row col-md-12 col-sm-12 col-xs-12">
                                                                    <div class="col-md-6 col-sm-6 col-xs-6">
                                                                        <div class="form-group label-floating">
                                                                            <label class="form-label">Remove From Salary
                                                                                Slip</label>
                                                                            <div class="w-100">
                                                                                <select
                                                                                    class="form-control col-md-12 col-xs-12"
                                                                                    style="height: 38px;"
                                                                                    data-style="btn btn-drpdwn btn-round"
                                                                                    [(ngModel)]="insertDetailMaster.Remove_from_sal_if"
                                                                                    id="Remove_from_sal_if"
                                                                                    name="Remove_from_sal_if">
                                                                                    <option value="If amount is 0">If
                                                                                        amount is 0</option>
                                                                                    <option
                                                                                        value="Remove component from Salary Slip">
                                                                                        Remove component from Salary
                                                                                        Slip</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 col-sm-6 col-xs-6">
                                                                        <div class="form-group label-floating">
                                                                            <label class="form-label">Use of
                                                                                HRA?</label>

                                                                            <div class="row">
                                                                                <div class="w-42p">
                                                                                    <label
                                                                                        class="form-label p-0 m-0">No</label>
                                                                                </div>
                                                                                <div class="w-50p">
                                                                                    <label class="custom-switch">
                                                                                        <input type="checkbox"
                                                                                            name="custom-switch-checkbox HRA"
                                                                                            class="custom-switch-input"
                                                                                            [(ngModel)]="insertDetailMaster.hraIsApplicable">
                                                                                        <span
                                                                                            class="custom-switch-indicator"></span>
                                                                                    </label>
                                                                                </div>
                                                                                <div class="w-60p">
                                                                                    <label
                                                                                        class="form-label">Yes</label>
                                                                                </div>
                                                                            </div>

                                                                            <!-- <div class="col-md-2">
                                                                                <input type="checkbox" name="HRA"
                                                                                    class="form-control col-md-6 col-xs-6"
                                                                                    autocomplete="off" autofocus="true"
                                                                                    [(ngModel)]="insertDetailMaster.hraIsApplicable" />
                                                                            </div> -->
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                                <div class="row col-md-12 col-sm-12 col-xs-12">
                                                                    <!-- Payment Months -->
                                                                    <div class="col-md-6 col-sm-6 col-xs-6">
                                                                        <div class="form-group label-floating">
                                                                            <label class="form-label">Payment
                                                                                Months</label>
                                                                            <div class="w-100">
                                                                                <div *ngFor="let month of months"
                                                                                    class="form-check">
                                                                                    <label class="form-check-label">
                                                                                        <input type="checkbox"
                                                                                            class="form-check-input"
                                                                                            [value]="month.value"
                                                                                            [checked]="isChecked(month.value)"
                                                                                            (change)="onCheckboxChange($event, month.value)">
                                                                                        {{ month.name }}
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="clearfix"> </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="addOrUpdate && add_view_toggle === 'addSalaryDetail'"
                                class="actionBar pe-4 ps-4">
                                <div class="clearfix"> </div>
                                <button *ngIf="addOrUpdate && add_view_toggle === 'addSalaryDetail'"
                                    class="btn btn-success  me-2" type="submit" (click)="submit()">Save</button>
                                <button *ngIf="addOrUpdate && add_view_toggle === 'addSalaryDetail'"
                                    class="btn btn-danger " type="button" (click)="clear()">Clear</button>
                                <!-- <button *ngIf="addOrUpdate && add_view_toggle === 'addSalaryDetail'"
                                    class="btn btn-success" type="submit" (click)="submit()">Save</button> -->
                            </div>
                            <div class="card col-md-12 col-sm-12 col-xs-12 top_margin"
                                *ngIf="add_view_toggle == 'viewSalaryDetail' ">
                                <div class="card-header border-bottom-0">
                                    <h3 class="card-title">List Of Salary Detail</h3>
                                </div>
                                <div class="card-body card1S p-0">
                                    <div class="table-responsive">
                                        <table
                                            class="table table-hover table-vcenter text-nowrap table-bordered border-bottom"
                                            id="hr-table">
                                            <thead class="tablerow">
                                                <tr class="rowcolors stickyatTop">
                                                    <th class="border-bottom-0 w-5 fs-6 ">#</th>
                                                    <th class="border-bottom-0 w-5 fs-6 ">Salary Head</th>
                                                    <th class="border-bottom-0 w-5 fs-6 ">Salary Detail Name</th>
                                                    <th class="border-bottom-0 w-5 fs-6 ">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody style="max-height: 50vh; overflow: scroll;">
                                                <tr *ngFor="let sh of salaryDetailForDropDown; let i = index">
                                                    <ng-container *ngIf="sh.salaryDetailId!=0">
                                                        <td style="text-align: right;">{{i+1}}</td>
                                                        <!-- <td>{{ getSerialNumber(i, salaryDetailForDropDown) }}</td> -->
                                                        <td>{{sh.salaryHeadName}}</td>
                                                        <td>{{sh.salaryDetailName}}</td>
                                                        <td>
                                                            <button class="btn btn-primary btn-icon btn-sm me-2"
                                                                data-method="edit" title="Edit"
                                                                (click)="editSalaryDetail(sh)">
                                                                <i class="fa fa-edit" data-bs-toggle="tooltip"
                                                                    data-original-title="edit"></i>
                                                            </button>
                                                           
                                                            <button class="btn btn-danger btn-icon btn-sm me-2"
                                                                        data-method="delete" title="Delete"
                                                                        (click)="onDeleteHandler(sh)"
                                                                        data-bs-target="#showAlertWarning">
                                                                        <i class="fa fa-trash" data-bs-toggle="tooltip"
                                                                            data-original-title="delete"></i>
                                                                    </button>
                                                        </td>
                                                    </ng-container>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<div id="showAlertVer" class="modal" style="top: 40px;">
    <div class="modal-dialog">
        <div class="modal-content animate">
            <div class="modal-header">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">×</button>
                <h4 class="modal-title">Warning</h4>
            </div>

            <div class="modal-body" style="max-height: calc(100vh - 210px);overflow-y: auto;">
                <!-- auto scroll -->
                <span><b> Salary Detail data will be deleted permanently. Are you sure you want to continue?</b></span>
            </div>

            <div class="modal-footer" >
                <button type="button" class="btn btn-danger medium" (click)="deleteSalaryDetail()">OK</button>
                <button type="button" class="btn btn-primary" (click)="closeMRQModal()">Cancel</button>
            </div>

        </div>
    </div>
</div>