import { Component, OnInit } from '@angular/core';
import { HelperService } from '../services';
import { EmpsalaryslipService } from '../services/empsalaryslip.service';
import { EmployeeMaster } from '../models/employeeMasterts';
import { EmployeeAttendanceSummary } from '../models/EmployeeAttendanceSummary';
import { SessionService } from '../services';
import { EmployeeattendenceserviceService } from '../services/employeeattendenceservice.service';
import { EmployeeLeaveService} from '../services/employee-leave.service'

@Component({
  selector: 'app-payslip',
  templateUrl: './payslip.component.html',
  styleUrls: ['./payslip.component.css']
})
export class PayslipComponent implements OnInit {
  date : string;
  uniqueEmployeeId :any
  uniqueEmpId = this.sessionService.getempid();
 empAttdSummaryList: EmployeeAttendanceSummary[];
  teamMemberList: PayslipComponent[];
   employeeMasterLucene: EmployeeMaster[];
  queryName: any;
  uniqEmpId: any;
  query: any;
  showBasedOnPermissionEmployeeStatus: boolean = false;
  permissionList: any[] = [];
  selectedType: string = 'select';
  sendUniqueEmpId: number = null;
  selectedluceneValue : string='Active';
  uniqueemployeeid: any;
  selectedempid: any;
  leavingdate: any;
  constructor(private helperService: HelperService , private empsalaryservice:EmpsalaryslipService,
    private sessionService: SessionService,private empAttendenceService: EmployeeattendenceserviceService,
  private empLeaveService: EmployeeLeaveService) { }

  ngOnInit(): void {
    const maxString = sessionStorage.getItem('permission').replace('[', '');
    const maxStringBox = maxString.replace(']', '');
    const maxStringAgain = maxStringBox.replace(/"/g, '');
    this.permissionList = maxStringAgain.split(',');
    this.date = this.getFormatedDate(new Date())
    this.uniqueEmployeeId = Number(sessionStorage.getItem('empId'));
    this.checkAccessForUser();
    this.viewTeam();
  }

  changeDate(selectedDate: any){
    this.date = selectedDate.target.value;
  }
  getFormattedFileName(date: Date, name : any): string {
    var filetype = null;
    if(name == "pdf"){
      const month = this.getFormatedMonth(date.getMonth());
    const year = date.getFullYear();
    filetype = `Payslip-${month}-${year}.pdf`;
    }else{
    const month = this.getFormatedMonth(date.getMonth());
    const year = date.getFullYear();
    filetype = `Payslip-${month}-${year}.zip`;
    }
    return  filetype;
  }

  generatePayslip() {
    let date = new Date(this.date);
    let month = this.getFormatedMonth(date.getMonth());
    let year = date.getFullYear();

    if (this.selectedType === 'Team_Member' && (!this.query || this.query.trim() === '')) {
      this.uniqEmpId = 0;  // to download all employees payslips if generated
    }
if (this.selectedType === 'Team_Member'){
  this.uniqueEmployeeId = this.uniqEmpId;
}else if(this.selectedType === 'select'){
  console.log(" inside else select  " + this.uniqueEmployeeId)
  this.uniqueEmpId = this.sessionService.getempid();
  this.uniqueEmployeeId = this.uniqueEmpId 
  console.log(" inside else select  " + this.uniqueEmployeeId)
}
    console.log(" selected employee id  " + this.uniqueEmployeeId)


    this.empsalaryservice.generate_salary_slip( this.uniqueEmployeeId, year, month).subscribe(response => {
      const contentType = response.headers?.get('Content-Type');

      if (contentType == 'application/octet-stream') {
        const name = "zip";
        const file = new Blob([response.body], { type: 'application/zip' });
        const fileName = this.getFormattedFileName(date,name);
        const fileURL = URL.createObjectURL(file);
        const anchor = document.createElement('a');
        anchor.href = fileURL;
        anchor.download = fileName;
        anchor.click();
      } else if (contentType?.includes('application/pdf')) {
        // console.log(" pdf : " ,contentType)
        const name = "pdf";
        const file = new Blob([response.body], { type: 'application/pdf' });
        const fileName = this.getFormattedFileName(date,name);
        const fileURL = URL.createObjectURL(file);
        const anchor = document.createElement('a');
        anchor.href = fileURL;
        anchor.download = fileName;
        anchor.click();
      } else {
        // console.log('Unexpected file type:', contentType);
        this.alertmessages('Unexpected file type', 'danger');
      }
  
    }, error => {
      console.log("Error Occured", error);
      if (error.status === 400) {
        this.alertmessages("Salary Not Generated For Selected Month", "danger");
      }
    });
  }

  getFormatedDate = (date: Date) => {
    let year = date.getFullYear();
    let month = this.getFormatedMonth(date.getMonth()+1) 

    return [year, month].join('-')
  }

  getFormatedMonth = (month : any) => {
    return (month < 10) ? '0' + month : month;
  }

  alertmessages(message:string , sign:string):void{
    let alertDiv = document.createElement('div');         
     alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
    <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message } </div>`
    let alertcomp = document.querySelector('.alertcomp');
    alertcomp.append(alertDiv);
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    setTimeout(function(){
      alertcomp.removeChild(alertDiv);
    }, 3000);
  }
checkAccessForUser() {
  console.log("checkAccessForUser here")
  this.showBasedOnPermissionEmployeeStatus = this.permissionList.includes('ng.employee.search.show.employeestatus');
}
onChange(event) {
  this.selectedType = event.target.value;
  console.log("selectedType---", this.selectedType);

  if (this.selectedType === 'Team_Member') {
    this.query = '';
    this.employeeMasterLucene = [];
    this.viewTeam();
    this.empAttdSummaryList = [];
  } else {
    this.uniqueEmpId = this.sessionService.getempid();
  this.uniqueEmployeeId = this.uniqueEmpId 
    console.log("else sendId---", this.uniqueEmpId);
    this.uniqEmpId = null;
    console.log("uniqEmpId---", this.uniqEmpId);
    this.empAttdSummaryList = [];
  }
}

viewTeam() {

  console.log("clicked team member ")
  this.empAttendenceService.getTeamMemberList(this.uniqueEmpId).subscribe(
    data => {

      this.teamMemberList = data.teamListSummary;
      console.log("team member list ", this.teamMemberList)
    },
    error => {
      console.log("Error team member list ", error)
    }
  )
}
onChange1(event) {
  this.sendUniqueEmpId = event.target.value;
  console.log("sendUniqueEmpId---------------------", this.sendUniqueEmpId);
  this.uniqEmpId = this.employeeMasterLucene[0].uniqueemployeeid;
   console.log("onChange1---", this.uniqEmpId)
  this.empAttdSummaryList = [];

}
getEmployeeByNameOrId() {
  const [name, id] = this.query.split(' / ');
  this.queryName = name;
  this.uniqueemployeeid = id; 
  // console.log("Len ", this.query);

  if (this.queryName.length <= 2) {
    this.employeeMasterLucene = [];
  }

  if (this.queryName.length >= 3) {
    this.empLeaveService.getEmployeeByNameOrUniqueId(this.queryName).subscribe(
      data => {

        if (data.employeeMasterData.length > 0) {
          let filteredEmployees = data.employeeMasterData;

          const currentDate = new Date();
          // console.log("Current Date: ", currentDate);

          // Apply filtering logic based on the selected employee status
          if (this.selectedluceneValue === 'Active') {
            filteredEmployees = filteredEmployees.filter(emp => {
              const leavingDate = emp.leavingdateString ? new Date(emp.leavingdateString) : null;
              // console.log("Leaving Date for " + emp.name + ": ", leavingDate);
              // Active employees should have leavingdate in the future or null
              return leavingDate === null || leavingDate > currentDate;
            });
          } else if (this.selectedluceneValue === 'Inactive') {
            filteredEmployees = filteredEmployees.filter(emp => {
              const leavingDate = emp.leavingdateString ? new Date(emp.leavingdateString) : null;
              // console.log("Leaving Date for " + emp.name + ": ", leavingDate);
              return leavingDate && leavingDate < currentDate;
            });
          }

          // If the status is 'All', no filter is applied
          if (this.selectedluceneValue === 'All') {
            filteredEmployees = data.employeeMasterData;
          }

          // Assign filtered data to the employeeMasterLucene
          this.employeeMasterLucene = filteredEmployees;

          // Log the data and leaving date of the first employee in the filtered list
          if (this.employeeMasterLucene.length > 0) {
            this.leavingdate = this.employeeMasterLucene[0].leavingdateString;
            // console.log("leavingdate", this.leavingdate);
            this.selectedempid = this.employeeMasterLucene[0].uniqueemployeeid;
          } else {
            console.log("No employee data found.");
          }
        } else {
          console.log("No employee data found.");
        }
      },
      error => {
        console.log("Error ", error);
      }
    );
  }
}
onClear() {
  // this.selectedType = 'select';
  this.query = '';
  this.uniqueEmpId = this.sessionService.getempid();
  this.uniqueEmployeeId = this.uniqueEmpId 
  console.log("onClear uniqueEmployeeId :", this.uniqueEmployeeId);
}
}
