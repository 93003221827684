import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class SalaryAdvanceService {

  protected basePath = '/api';
  public defaultHeaders: Headers = new Headers();
  public url;

  constructor(private httpclient: HttpClient, private commonService: CommonService) { }


  public saveSalaryAdvanceAppy(salAdvInfo: any): Observable<any> {

    console.log("save :", salAdvInfo)
    this.url = '/v1/applyForAdvance';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();

    return this.commonService.postWithHttpInfo(salAdvInfo, queryParameters, path);
  }

  public updateSalaryAdvanceAppy(salAdvInfo: any): Observable<any> {

    console.log("save :", salAdvInfo)
    this.url = '/v1/updateApplyForAdvance';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();

    return this.commonService.postWithHttpInfo(salAdvInfo, queryParameters, path);
  }

  public updateSalaryAdvanceApprove(salAdvInfo: any,typeFilter:any ,empStatus: any, statusFilter:any, limitPara:any , offsetPara:any): Observable<any> {

    console.log("save :", salAdvInfo)
    this.url = '/v1/updateApproveForAdvance';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('typeFilter',typeFilter);
    queryParameters = queryParameters.set('empStatus', empStatus);
    queryParameters = queryParameters.set('statusFilter',statusFilter);
    queryParameters = queryParameters.set('limitPara',limitPara);
    queryParameters = queryParameters.set('offsetPara',offsetPara);

    return this.commonService.postWithHttpInfo(salAdvInfo, queryParameters, path);
  }

  public getAllAdvApplies(typeFilter:any ,empStatus: any, statusFilter:any, uniqueEmpId:any, limitPara:any , offsetPara:any): Observable<any> {

    // console.log("save :", salAdvInfo)
    this.url = '/v1/getAllApplysForAdvance';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('typeFilter',typeFilter);
    queryParameters = queryParameters.set('empStatus', empStatus);
    queryParameters = queryParameters.set('statusFilter',statusFilter);
    queryParameters = queryParameters.set('employeeid',uniqueEmpId);
    queryParameters = queryParameters.set('limitPara',limitPara);
    queryParameters = queryParameters.set('offsetPara',offsetPara);

    return this.commonService.getByQueryParamWithHttpInfo( queryParameters, path);
  }

  public getSalaryReport(fromDate , toDate ,empStatus:any, statusFilter:any ,typeFilter:any,reporttype:any ,department,employeeid): Observable<any> {

    // console.log("save :", salAdvInfo)
    this.url = '/v1/getSalaryAdvanceReport';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('fromDate',fromDate);
    queryParameters = queryParameters.set('toDate',toDate);
    queryParameters = queryParameters.set('empStatus', empStatus);
    queryParameters = queryParameters.set('statusFilter',statusFilter);
    queryParameters = queryParameters.set('typeFilter',typeFilter);
    queryParameters = queryParameters.set('reporttype',reporttype);
    queryParameters = queryParameters.set('departmentid',department);
    queryParameters = queryParameters.set('employeeid',employeeid);

    return this.commonService.getByQueryParamWithHttpInfo( queryParameters, path);
  }

  public salaryAdvanceReportDownload(fromDate , toDate ,empStatus:any, statusFilter:any ,typeFilter:any ,reporttype:any,department,employeeid): Observable<any> {
    this.url = '/v1/downloadSalaryAdvanceReport';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();

    queryParameters = queryParameters.set('fromDate',fromDate);
    queryParameters = queryParameters.set('toDate',toDate);
    queryParameters = queryParameters.set('empStatus', empStatus);
    queryParameters = queryParameters.set('statusFilter',statusFilter);
    queryParameters = queryParameters.set('typeFilter',typeFilter);
    queryParameters = queryParameters.set('reporttype',reporttype);
    queryParameters = queryParameters.set('departmentid',department);
    queryParameters = queryParameters.set('employeeid',employeeid);

    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path, { responseType: 'arraybuffer' });
  }


  public getGrossSalaryByUId(UID:number, dateOfLoan: any): Observable<any> {

    // console.log("save :", salAdvInfo)
    this.url = '/v1/getGrossSalaryByUId';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('uniqueemployeeid',UID);
    queryParameters = queryParameters.set('dateOfLoan', dateOfLoan);
    return this.commonService.getByQueryParamWithHttpInfo( queryParameters, path);
  }

  public isAdvanceLimitCrossed(UID: number, amount: any, noTimes: any, dateOfLoan: any, salaryHeadId
    , salaryDetailId,addOrEdit: string , interMasterId:any): Observable<any> {

    // console.log("save :", salAdvInfo)
    this.url = '/v1/isAdvanceLimitCrossed';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('uniqueemployeeid', UID);
    queryParameters = queryParameters.set('Appliedamount', amount);
    queryParameters = queryParameters.set('noTimes', noTimes);
    queryParameters = queryParameters.set('dateOfLoan', dateOfLoan);
    queryParameters = queryParameters.set('salaryHeadId', salaryHeadId);
    queryParameters = queryParameters.set('salaryDetailId', salaryDetailId);
    queryParameters = queryParameters.set('addOrEditflag', addOrEdit);
    queryParameters = queryParameters.set('interMasterId', interMasterId);
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  public getSalaryLoanSettings(): Observable<any> {
    // this is existing api used to get leavetype .. dont modify db
    this.url = '/v1/getSalaryLoanSettings';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path)
  }

  public getSalaryAdvanceSettings(): Observable<any> {
    // this is existing api used to get leavetype .. dont modify db
    this.url = '/v1/getSalaryAdvanceSettings';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path)
  }

  public getAllAdvAppliesByUid(uid: any , salaryHeadId:any ,salaryDetailId:any): Observable<any> {

    // console.log("save :", salAdvInfo)
    this.url = '/v1/getapplysForAdvance';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('uniqueemployeeid',uid);
    queryParameters = queryParameters.set('salaryHeadId',salaryHeadId);
    queryParameters = queryParameters.set('salaryDetailId',salaryDetailId);
    return this.commonService.getByQueryParamWithHttpInfo( queryParameters, path);
  }
  

  public getapplyAdvInfo(mid: any): Observable<any> {

    // console.log("save :", salAdvInfo)
    this.url = '/v1/getapplyAdvInfo';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('interMasterId',mid);
    return this.commonService.getByQueryParamWithHttpInfo( queryParameters, path);
  }

  public getapplyAdvInfoForApprover(mid: any): Observable<any> {

    // console.log("save :", salAdvInfo)
    this.url = '/v1/getapplyAdvInfoForApprover';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('interMasterId',mid);
    return this.commonService.getByQueryParamWithHttpInfo( queryParameters, path);
  }

  public deleteAdvApplieByMid(mid: any): Observable<any> {

    // console.log("save :", salAdvInfo)
    this.url = '/v1/deleteApplyAdvInfo';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('interMasterId',mid);
    return this.commonService.getByQueryParamWithHttpInfo( queryParameters, path);
  }

  public getSalaryDetails(): Observable<any> {
    this.url = '/v1/salaryDetailMaster';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path)
  }

  getEmployeeByNameOrUniqueId(query: any): Observable<any> {
    this.url = '/v1/employeesearch';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    if (query !== null) {
      queryParameters = queryParameters.set('query', query);
    }
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  public approveList(salaryAdv: any,typeFilter:any ,empStatus: any,statusFilter:any,User:any, limitPara:any , offsetPara:any): Observable<any> {
    console.log("value sent ", salaryAdv);
    this.url = '/v1/salaryAdvanceApprovalList';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('typeFilter',typeFilter);
    queryParameters = queryParameters.set('empStatus', empStatus);
    queryParameters = queryParameters.set('statusFilter',statusFilter);
    queryParameters = queryParameters.set('User',User);
    queryParameters = queryParameters.set('limitPara',limitPara);
    queryParameters = queryParameters.set('offsetPara',offsetPara);
    // queryParameters = queryParameters.set('uniqueEmpid', uid);
    return this.commonService.postWithHttpInfo(salaryAdv, queryParameters, path);
  }
  public rejectList(salaryAdv: any,typeFilter:any ,empStatus: any, statusFilter:any,User:any, limitPara:any , offsetPara:any): Observable<any> {
    console.log("value sent ", salaryAdv);
    this.url = '/v1/salaryAdvanceRejectList';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('typeFilter',typeFilter);
    
    queryParameters = queryParameters.set('empStatus', empStatus);
    queryParameters = queryParameters.set('statusFilter',statusFilter);
    queryParameters = queryParameters.set('User',User);
    queryParameters = queryParameters.set('limitPara',limitPara);
    queryParameters = queryParameters.set('offsetPara',offsetPara);
    // queryParameters = queryParameters.set('uniqueEmpid', uid);
    return this.commonService.postWithHttpInfo(salaryAdv, queryParameters, path);
  }

  public PaidList(salaryAdv: any,typeFilter:any ,empStatus: any, statusFilter:any,User:any, limitPara:any , offsetPara:any): Observable<any> {
    console.log("value sent ", salaryAdv);
    this.url = '/v1/salaryAdvancePaidList';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('typeFilter',typeFilter);
    queryParameters = queryParameters.set('empStatus', empStatus);
    queryParameters = queryParameters.set('statusFilter',statusFilter);
    queryParameters = queryParameters.set('User',User);
    queryParameters = queryParameters.set('limitPara',limitPara);
    queryParameters = queryParameters.set('offsetPara',offsetPara);
    // queryParameters = queryParameters.set('uniqueEmpid', uid);
    return this.commonService.postWithHttpInfo(salaryAdv, queryParameters, path);
  }

  public getTeamMemberList(empid: string): Observable<any> {
    this.url = '/v1/empTeamMemberForLoanApply';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('uniqueempid', empid)
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  }
}
