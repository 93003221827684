<div class="app-content main-content">
  <div class="side-app main-container">
    <div class="alertcomp">
    
    </div>
    <!-- <div class="page-header d-xl-flex d-block">
      <div class="page-leftheader">
          <div class="page-title">Review PaySlips</div>
      </div>
     </div> -->

     <div class="row">
      <div class="col-xl-12 col-md-12 col-lg-12">
          <div class="card">
              <div class="card-body">
                <form id="fcForm">
                  <section> 
                    <div class="col-md-12 col-sm-12 col-xs-12 p-0">
                      <div class="form-group label-floating">
                        <label class="form-label"> From Date </label>
                        <div>
                          <input class="form-control col-md-12 col-xs-12" [value]="fromDate" name="fromDate" type="date"
                            id="datefrom" required="required" (change)="changefromDate($event)" />
      
                        </div>
                      </div>
                    </div>
      
      
                    <div class="col-md-12 col-sm-12 col-xs-12 p-0">
                      <div class="form-group label-floating">
                        <label class="form-label">To Date</label>
                        <div>
                          <input class="form-control" [value]="toDate" name="toDate" type="date" id="dateto"
                            required="required" (change)="changetoDate($event)" />
                        </div>
                      </div>
                    </div>
                  </section>

                  <div class="col-md-12 col-sm-12 col-xs-12 p-0">
                    <div class="form-group label-floating">
                      <!-- <label class="form-label">&nbsp;&nbsp;</label> -->
                      <div>
                        <button type="button" class="btn btn-primary w-100 col-md-3" (click)="searchPaySlipHistory()">
                          <i class="fa fa-search" aria-hidden="true"></i> Search
                        </button>
                      </div>
                    </div>
                  </div>

                  <div class="Clearfix"></div>

                  <div class="pro-fab">
                    <button type="button" class="btn btn-success " (click)="confirmApprove()">Approve
                    </button>
                    <button type="button" class="btn btn-danger ms-2 medium" (click)="openrejectionModal()">Reject
                    </button>
                  </div>


                </form>      
              </div>
          </div>    
      </div>
     </div>       


    <!-- <div class="col-md-12">
      <div class="card">
        <div class="card-content">
          <form id="fcForm">
    
            <section> -->
              <!-- 
              <div>
                <h3 style="margin-bottom: 30px;padding-left: 10px;">Review PaySlips</h3>
                <div class="clearfix"></div>
              </div> -->


              <!-- <div class="col-md-3 col-sm-5 col-xs-7">
                <div class="form-group label-floating">
                  <label class="form-label"> From Date </label>
                  <div>
                    <input class="form-control col-md-7 col-xs-12" [value]="fromDate" name="fromDate" type="date"
                      id="datefrom" required="required" (change)="changefromDate($event)" />

                  </div>
                </div>
              </div>


              <div class="col-md-3 col-sm-5 col-xs-7">
                <div class="form-group label-floating">
                  <label class="form-label">To Date</label>
                  <div>
                    <input class="form-control" [value]="toDate" name="toDate" type="date" id="dateto"
                      required="required" (change)="changetoDate($event)" />
                  </div>
                </div>
              </div>
            </section>


            <div class="col-md-1 col-sm-4 col-xs-6">
              <div class="form-group label-floating">
                <label class="form-label">&nbsp;&nbsp;</label>
                <div>
                  <button type="button" class="btn btn-info" (click)="searchPaySlipHistory()">
                    <i class="fa fa-search" aria-hidden="true"></i> Search
                  </button>
                </div>
              </div>
            </div>

            <div class="Clearfix"></div>

            <div class="pro-fab">
              <button type="button" class="btn btn-primary" (click)="confirmApprove()">Approve
              </button>
              <button type="button" class="btn btn-danger medium" (click)="openrejectionModal()">Reject
              </button>
            </div>
          </form>
        </div>
      </div>
    </div> -->
<div class="row">
    <div class="col-md-12 col-xs-12">
      <div class="card">
        <div class="card-body">
          <div class="Clearfix"></div>
          <form id="revList" name="revList" type="multipart/form-data">

            <div class="col-xs-12 mobile">
              <div *ngIf="reviewpayslips !== null ">
                <div class="scrollsearchresults" infiniteScroll [infiniteScrollDistance]="2"
                  [infiniteScrollUpDistance]="1.5" [infiniteScrollThrottle]="100" [scrollWindow]="false"
                  (scrolled)="onScroll()">
                  <!-- class="table table-hover cf" -->
                  <table class="table  table-vcenter text-nowrap table-bordered border-bottom">
                    <thead class="tablerow">
                      <tr class="rowcolors stickyatTop">
                        <th class="border-bottom-0 w-5 fs-7" *ngIf="checkBoxPermission"><input type="checkbox" style="cursor: pointer;" (change)="checkAllSelected($event)"/></th>
                        <th class="border-bottom-0 w-5 fs-7">Employee ID</th>
                        <th class="border-bottom-0 w-5 fs-7">Employee Name</th>
                        <th class="border-bottom-0 w-5 fs-7">Total Salary</th>
                        <th class="border-bottom-0 w-5 fs-7" *ngFor="let j=index; let salcmp of salarycomp ">{{salcmp.salaryDetailName}}</th>
                        <th class="border-bottom-0 w-5 fs-7">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr  *ngFor="let j=index; let empInfo of reviewpayslips ">
                        <td *ngIf="checkBoxPermission"><input type="checkbox"  style="cursor: pointer;" [disabled]="empInfo.approved" [checked]="empInfo.ischeckboxselected"  (change)="checkSelected(empInfo,$event)"/> &nbsp; </td> 
                        <td data-th="Employee Id" >
                          {{empInfo.uniqueEmployeeId}}
                        </td>
                        <td data-th="Employee Name">
                          {{empInfo.name}}
                        </td>
                        <td data-th="Total Salary" style="text-align: right;">
                          {{empInfo.salaryAmount}}
                        </td>
                        <td attr.data-th = "{{salaryComponent.salaryDetailName}}" *ngFor="let j=index; let salaryComponent of empInfo.salaryDetailsInfo" style="text-align: right;">
                          {{salaryComponent.salaryComponentAmount}}
                        </td>
                        <td data-th="Status">
                          {{empInfo.approvalStatus}}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
</div>



<div id="Comment" class="modal" style="top: 40px;">
  <div class="modal-dialog">
    <div class="modal-content animate">
      <div class="modal-header">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeCommetModal()" >×</button>
        <h4 class="modal-title"><b>Reject</b></h4>
      </div>

      <div class="modal-body" style="max-height: calc(100vh - 210px);overflow-y: auto;">
        <span>Comments for Rejection</span>
      </div>

      <div class="modal-footer form-group">
      <textarea class="form-control" maxlength = "250" #commento="ngModel" [(ngModel)]="comments" rows="5"  autofocus="true" required ="required" autocomplete="off"></textarea>
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-danger medium" (click)="confirmRejection()">Reject</button>
        <button type="button" class="btn btn-primary" (click)="closeCommetModal()">Cancel</button>

      </div>
    </div>
  </div>
</div>

  </div>
</div>