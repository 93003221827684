import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DesignationInfo } from '../models/designationInfo';
import { JoblevelInfo } from '../models/joblevel';
import { AlertService } from '../services';
import { CommonService } from '../services/common.service';
declare var $:any
@Component({
  selector: 'app-designation',
  templateUrl: './designation.component.html',
  styleUrls: ['./designation.component.css']
})
export class DesignationComponent implements OnInit {
  roledescription: string;
  name: string;
  designationid: number;
  payscaleid: number;
  employe: DesignationInfo[];
  list: DesignationInfo[];
  joblevel: JoblevelInfo[];
  isAdding: boolean = true;
  add_view_toggle: string = "searchDesignation";
  addOrUpdate: boolean = true;
  joblevels:any;

  constructor(private commonservice: CommonService, private router: Router, private alertservice: AlertService) { }

  ngOnInit(): void {
    this.getjoblevel();
    this.searchDesignation();
    (<HTMLInputElement>document.getElementById('textType')).focus()
  }

  redirectTo(uri: string) {
    this.router.navigateByUrl('/temp', { skipLocationChange: true }).then(() =>
      this.router.navigate([uri]));
  }

  reFresh() {
    this.name = "";
    this.roledescription = "";
    this.addOrUpdate = true;
    this.searchDesignation();
    // this.redirectTo('/designationetails');
  }

  selectDesignation(e) {
    this.name = e.target.value;
    console.log("name------",this.name)
  }

  selectedjobid: string;
  saveDesignation() {
    if (!this.name) {
      this.alertmessages("Please enter Designation Name", "danger");
      return;
    }
    console.log("save-----------" + this.roledescription);
    if (!this.roledescription) {
      this.alertmessages("Please enter Joblevel", "danger");
      return;
    }

    this.commonservice.getempDesignation(this.name.trim(), this.roledescription).subscribe(
      data => {
        this.employe = data.savedesignation;

        console.log("save" + this.employe);
        if (this.employe) {
          this.alertmessages("Successfully Saved", "success");
          this.reFresh();
          this.searchDesignation();
          return;
        }

      },
      error => {
        const response = error.error;
        if (error.status === 422 && response?.status === "Duplicate") {
          this.alertmessages("Designation already exists", "danger");
          this.reFresh();
      } else if (response?.errorMessages?.length > 0) {
          this.alertservice.error(response.errorMessages[0]['key']);
      }
      });

  }

  onScroll() {
  }
  editDesig(id, info,roledescription,itemget) {
    console.log("edit group : ", info)
    console.log("edit group : ", id)
    console.log("itemget : ", itemget)
    this.name = info;
    this.roledescription = itemget.jobid;
    this.designationid = id;
    this.add_view_toggle = 'add_designation';
    this.addOrUpdate = false;
  }

  updateDesignation() {
    this.addOrUpdate = true;
    this.name = this.name.trim();
    console.log("After trim expense type: ", this.name, " :  length : ", this.name.length)
    if (this.name != undefined && this.name.length > 0) {

      const updatedField = {
        designationid: this.designationid,
        name: this.name,
        joblevel:this.roledescription
      };

      this.commonservice.editUpdate_Designation(updatedField).subscribe(
        data => {
          console.log("added Designation ", data)
          this.alertmessages("Successfully Updated ", "success")
         // this.clear();
         this. reFresh();
         this.searchDesignation();
        }, error => {
          if (error.status === 422 && error.error && error.error.status === "Duplicate") {
            this.alertmessages("Designation already exists", "danger");
        } else {
          this.alertmessages("Please try again later", "danger")
          console.log(error);
        }
        }
      );

     // this.clear();
     this.reFresh();
    }
  }

  deleteDesignation() {
    console.log("delete designation : ",this.designationid )
    const id = this.designationid;
    this.commonservice.deleteDesignation(id).subscribe(
      data => {
        console.log("delete Designation name ", data)
        this.alertmessages("Successfully deleted ", "success")
       this. reFresh();
        this.searchDesignation();
        $('#showAlertVer').modal('toggle');
      }, error => {
        $('#showAlertVer').modal('toggle');
        this.alertmessages("Desigantion name is in Use ", "danger")
        console.log(error);
      }
    );
this. reFresh();
    //this.clear();
  }
  onDeleteHandler(id,name) {

    console.log("delete : ", name);
    console.log("delete : ", id);
    this.designationid=id;
    this.name = name;
    $('#showAlertVer').modal('toggle');
  }

  closeMRQModal() {
    $('#showAlertVer').modal('toggle');

  }
  searchDesignation() {

    // if (!this.name) {
    this.commonservice.getSearchdesignation1().subscribe(
      data => {
        this.list = data;
        this.add_view_toggle = "searchDesignation";
      },
      error => {
        const response = error.error;
        if (response.errorMessages != null && response.errorMessages.length !== 0) {
          this.alertservice.error(response.errorMessages[0]['key']);
        }
      });
    // }

    // else {

    //   if (!this.roledescription) {
    //     this.alertmessages("Please select Job Level", "danger");
    //     return;
    //   }
    //   this.commonservice.getSearchdesignation(this.name, this.roledescription).subscribe(
    //     data => {
    //       this.list = data;

    //       console.log("search" + this.list);

    //     },
    //     error => {
    //       const response = error.error;
    //       if (response.errorMessages != null && response.errorMessages.length !== 0) {
    //         this.alertservice.error(response.errorMessages[0]['key']);
    //       }

    //     });
    // }
  }

  alertmessages(message: string, sign: string): void {
    let alertDiv = document.createElement('div');
    alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
          <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message} </div>`
    let alertcomp = document.querySelector('.alertcomp');
    alertcomp.append(alertDiv);
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    //  (<HTMLButtonElement>document.getElementById('btndisabled')).disabled = true;
    setTimeout(function () {
      alertcomp.removeChild(alertDiv);
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/designationetails']);
      });


    }, 3000);
  }

  getjoblevel() {

    this.commonservice.getjobLevel().subscribe(
      data => {
        this.joblevel = data;
        console.log(this.joblevel)

      },

      error => {
        const response = error.error;
        if (response.errorMessages != null && response.errorMessages.length !== 0) {
          this.alertservice.error(response.errorMessages[0]['key']);
        }
      });
  }

  selectedJobllevel(i, e) {
    // this.getjoblevel();
    this.joblevel[i].joblevel = e.target.value;
  }

  setjobid(e) {
    console.log(e)
    console.log(e.target.value)
    this.roledescription = e.target.value;

  }

  addDoc() {
    this.add_view_toggle = "add_designation";
  }

}