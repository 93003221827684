export class savingsmaster {
    id:number;
    name: any;
    saving: number;
    maxamountmale:number;
    maxamountfemale:number;
    ishracalc:Boolean=false;
    isapplyforall:Boolean=false;
    isnewregimeapplicable:Boolean=false;
    isoldregimeapplicable:Boolean=false;
}