import { Component,OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Event, NavigationEnd } from '@angular/router';
import { AppService } from './services';


declare var $: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})


export class AppComponent {
    
    title = "app";
    installPromptEvent: any;

    constructor(private route: Router,private service: AppService) { 
                window.addEventListener('beforeinstallprompt', (event) => {
                   console.log('beforeinstallprompt event fired:', event);
                    event.preventDefault(); // Required to store the event
                    // this.installPromptEvent = event;
                    this.service.setInstallPromptEvent(event);
                });
        
                    // Step 3: Check installation status
                if (!this.isAppInstalled()) {
                    localStorage.removeItem('pwaInstalled');
                }
        
                    // Step 4: Subscribe to install prompt event from service
                // this.service.installPromptEvent$.subscribe((event) => {
                    
                //     if (event) {
                //         this.installPromptEvent = event;
                //          $('#pwaPrompt').modal('toggle');
                //     }
                // });
          
            //   // Step 5: Subscribe to login status (Show modal after login)
            //   this.isloggedin.subscribe((isLoggedIn) => {
          
            //     if (isLoggedIn && this.installPromptEvent) {
            //       setTimeout(() => {
            //         $('#pwaPrompt').modal('toggle');
            //       }, 10000);
            //     }
            //   });
                // if(this.installPromptEvent){
                //     // setTimeout(() => {
                //         console.log('beforeinstallprompt event fired333333333333333333333333333333:', event);
                //     //     $('#pwaPrompt').modal('toggle');
                //     // }, 5000);
                //     $('#pwaPrompt').modal('toggle');
                // }

    }
    


    ngOnInit() {
       

        this.route.events.subscribe(
                (page_link: Event) => {
                    if (page_link instanceof NavigationEnd ) {
                        var page = page_link.url;
                        this.redirectPage(page);
                    }
                }
            );
    }
    
    promptInstall() {
		// console.log("Butoon clickeddddddddddddddd");
		
		if (this.installPromptEvent) {
		  this.installPromptEvent.prompt(); // Now it is triggered by a user action
	
		  this.installPromptEvent.userChoice.then((choiceResult: any) => {
			if (choiceResult.outcome === 'accepted') {
			  console.log('User accepted the install prompt');
			} else {
			  console.log('User dismissed the install prompt');
			}
			this.installPromptEvent = null; // Reset the prompt
		  });
		} else {
		  console.log('No install prompt available');
		}
	  }
    
    redirectPage(currentPage) {
        
        const pages = ['/', ''];
        
        for (const page in pages) {

            if (currentPage == pages[page]) {
                 this.route.navigateByUrl('/login');
                 //this.route.navigateByUrl('/companyselection');
            }
            
        }
    }

    /**
 * Triggers the PWA installation prompt if the event is available.
 * After the user makes a choice (accept or dismiss), the event is reset.
 * 
 * - If the user accepts, it logs success and updates local storage.
 * - If the user dismisses or an error occurs, it logs the failure.
 */
//   promptInstall() {
//     if (!this.installPromptEvent) {
//       console.log("❌ Install prompt event not available!");
//       return;
//     }

//     // Show the install prompt
//     this.installPromptEvent.prompt();

//     // Handle user choice (accepted or dismissed)
//     this.installPromptEvent.userChoice
//       .then((choice: { outcome: string }) => {
//         if (choice.outcome === 'accepted') {
//           this.service.resetInstallPrompt(); // ✅ Reset after installation
//           localStorage.setItem('pwaInstalled', 'true');
//         }
//         this.installPromptEvent = null;
//       })
//       .catch(error => console.error("❌ Installation failed:", error)); // ✅ Handle errors
//   }


  /**
 * Checks if the PWA is installed or running in standalone mode.
 * 
 * - Uses `window.matchMedia('(display-mode: standalone)')` to detect standalone mode.
 * - Checks `localStorage` for a previous installation flag.
 * - If the app was previously installed but is no longer running in standalone mode,
 *   it assumes the app was uninstalled and resets the localStorage flag.
 * 
 * @returns {boolean} - `true` if the app is installed or running in standalone mode.
 */
  isAppInstalled() {
    const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
    const wasInstalledBefore = localStorage.getItem('pwaInstalled') === 'true';

    // console.log(`Standalone: ${isStandalone} - Local Store: ${wasInstalledBefore}`);

    // If the app is not currently in standalone mode but was installed before, reset storage
    if (!isStandalone && wasInstalledBefore) {
      localStorage.removeItem('pwaInstalled');
    }

    return isStandalone || wasInstalledBefore;
  }
	
}
