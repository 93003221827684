import { HttpClient, HttpHeaders, HttpParams,HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class EmpsalaryslipService {
  protected basePath = '/api';
  public defaultHeaders: Headers = new Headers();
  public url;
  constructor(private httpclient: HttpClient, private commonService: CommonService) { }

  // public generate_salary_slip(empid: any, year: any, month: any): Observable<any> {
  //   this.url = '/v1/generatesalaryslip';
  //   const path = this.basePath + this.url;
  //   let queryParameters = new HttpParams();
  //   queryParameters = queryParameters.set('employeeid', empid);
  //   queryParameters = queryParameters.set('year', year);
  //   queryParameters = queryParameters.set('month', month);
  //   // queryParameters.set('file', imgUploadFile)
  //   return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path, { responseType: 'arraybuffer' });
  // }
  public generate_salary_slip(empid: any, year: any, month: any): Observable<HttpResponse<Blob>> {
    this.url = '/v1/generatesalaryslip';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('employeeid', empid);
    queryParameters = queryParameters.set('year', year);
    queryParameters = queryParameters.set('month', month);

    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path, {
        observe: 'response',  // Ensure headers are available
        responseType: 'blob' as 'json'  // Ensure proper binary data handling
    });
}
  // get head salary master done 
  public getSalaryDetails(): Observable<any> {
    this.url = '/v1/salaryDetailMaster';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path)
  }

  // get detail salary master done 
  public getSalaryDetailsRightJoined(): Observable<any> {
    this.url = '/v1/salaryDetailMasterForDetail';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path)
  }

    // get detail salary master done 
    public getSalaryDetailsForGenerateSalary(): Observable<any> {
      this.url = '/v1/salaryDetailMasterForDetailLeftJoined';
      const path = this.basePath + this.url;
      let queryParameters = new HttpParams();
      return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path)
    }

  // delete head
  public deleteSalaryHead(id: number): Observable<any> {
    console.log("delete id  : ", id);
    this.url = '/v1/deleteSalaryHead';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.postWithHttpInfo(id, queryParameters, path);
  }

  // delete detail
  public deleteSalaryDetail(detailid: any, headid: any): Observable<any> {
    console.log("delete id  : ", detailid, headid);
    this.url = '/v1/deleteSalaryDetail';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('detailid', detailid);
    queryParameters = queryParameters.set('headid', headid);
    return this.commonService.postWithHttpInfo(detailid, queryParameters, path);
  }
  

  // save head
  public saveSalaryHeadMaster(obj: any): Observable<any> {
    console.log("value sent ", obj)
    this.url = '/v1/savingSalaryHead';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.postWithHttpInfo(obj, queryParameters, path);
  }

  // save detail 
  public saveSalaryDetailMaster(salaryDetail: any): Observable<any> {
    console.log("value sent ", salaryDetail)
    this.url = '/v1/savingSalaryDetail';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.postWithHttpInfo(salaryDetail, queryParameters, path);
  }

  // update head
  public updateSalaryHeadMaster(obj: any): Observable<any> {
    console.log("value sent ", obj)
    this.url = '/v1/updateSalaryHead';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.postWithHttpInfo(obj, queryParameters, path);
  }

  // update detail 
  public updateSalaryDetailMaster(obj: any): Observable<any> {
    console.log("value sent ", obj)
    this.url = '/v1/updateSalaryDetail';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.postWithHttpInfo(obj, queryParameters, path);
  }

  // get paycalruless 
  public getPayCalRuleDetails(): Observable<any> {
    this.url = '/v1/rulesDetails';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path)
  }

  // delete detail
  public deletePayCalculateRule(ruleid: any): Observable<any> {
    console.log("delete id  : ", ruleid);
    this.url = '/v1/deletePayCalculateRule';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('ruleid', ruleid);
    return this.commonService.postWithHttpInfo(ruleid, queryParameters, path);
  }

  // save pay-cal-rule 
  public savePayCalRuleMaster(paycalruless: any): Observable<any> {
    console.log("value sent ", paycalruless)
    this.url = '/v1/savingPayCalRules';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.postWithHttpInfo(paycalruless, queryParameters, path);
  }

  // update pay-cal-rule 
  public updatePayCalRuleMaster(obj: any): Observable<any> {
    console.log("value sent ", obj)
    this.url = '/v1/updatePayCalRuleMaster';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.postWithHttpInfo(obj, queryParameters, path);
  }

  // get rule detail                                                                                  -- remove this later
  public getRuleDetails(): Observable<any> {
    this.url = '/v1/getRuleDetails';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path)
  }

  // save rule detail                                                                                 -- remove this later
  public saveRuleDetail(paycalruless: any): Observable<any> {
    console.log("value sent ", paycalruless)
    this.url = '/v1/savingRuleDetail';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.postWithHttpInfo(paycalruless, queryParameters, path);
  }

  // delete rule detail                                                                                 -- remove this later 
  public deleteRuleDetail(ruleid: any): Observable<any> {
    console.log("delete id  : ", ruleid);
    this.url = '/v1/deleteRuleDetailByid';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('ruleid', ruleid);
    return this.commonService.postWithHttpInfo(ruleid, queryParameters, path);
  }

  // update rule details                                                                                -- remove this later 
  public updateRuleDetails(obj: any): Observable<any> {
    console.log("value sent ", obj)
    this.url = '/v1/updateRuleDetail';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.postWithHttpInfo(obj, queryParameters, path);
  }

  public uploadEmpExcelDocument(excelDocToUpload: File,fromDate , toDate): Observable<any> {
    this.url = '/v1/salaryManualFedUpload';
    const path = this.basePath + this.url;
    var formData: any = new FormData();
    debugger
    //const formData: FormData = new FormData();
    console.log("File data from service file", excelDocToUpload)
    console.log("URL to send file", path)
    formData.append('file', excelDocToUpload);
    formData.append('fromDate', fromDate);
    formData.append('toDate', toDate);
    // let queryParameters = new HttpParams();

    let headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    //headers.append( 'Accept', 'multipart/form-data' );

    const httpOptions = { headers: headers };
    // return this.commonService.postWithHttpInfo(formData, queryParameters,path)
    return this.httpclient.post(path, formData, httpOptions);

  }


  // filepath:"C:/Users/DELL/Desktop/NewFolder";
  public downloadFile(year: any, monthValue: any, monthname: any, selecteduniqueeEmpId: any,fromDate , toDate): Observable<any> {
    this.url = '/v1/downloadsalarymanualfed';
   
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('year', year);
    queryParameters = queryParameters.set('monthValue', monthValue);
    queryParameters = queryParameters.set('monthName', monthname);
    queryParameters = queryParameters.set('selecteduniqueeEmpId', selecteduniqueeEmpId);
    queryParameters = queryParameters.set('fromDate', fromDate);
    queryParameters = queryParameters.set('toDate', toDate);
       
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path, { responseType: 'arraybuffer' });
  }

  public downloadSalReportBank(year: any, monthValue: any, monthname: any, selecteduniqueeEmpId: any): Observable<any> {
    this.url = '/v1/downloadSalaryReportBank';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('year', year);
    queryParameters = queryParameters.set('monthValue', monthValue);
    queryParameters = queryParameters.set('monthName', monthname);
    queryParameters = queryParameters.set('selecteduniqueeEmpId', selecteduniqueeEmpId);
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path, { responseType: 'arraybuffer' });
  }

  public downloadESIstatementDownload(year: any, monthValue: any, monthname: any): Observable<any> {
    this.url = '/v1/downloadESI';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('year', year);
    queryParameters = queryParameters.set('monthValue', monthValue);
    queryParameters = queryParameters.set('monthName', monthname);
    //queryParameters = queryParameters.set('selecteduniqueeEmpId', selecteduniqueeEmpId);
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path, { responseType: 'arraybuffer' });
  }

  public downloadStatutoryReport(year: any, monthValue: any, monthname: any): Observable<any> {
    this.url = '/v1/downloadStatutoryReport';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('year', year);
    queryParameters = queryParameters.set('monthValue', monthValue);
    queryParameters = queryParameters.set('monthName', monthname);
    //queryParameters = queryParameters.set('selecteduniqueeEmpId', selecteduniqueeEmpId);
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path, { responseType: 'arraybuffer' });
  }


  public getEmployeeList(): Observable<any> {
    this.url = '/v1/getemployeeList';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    // queryParameters = queryParameters.set('user_id', user_id)
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path)
  }
  //get itslabmaster details
  public getItSlabMaster(): Observable<any> {
    console.log("Entered service method")
    this.url = '/v1/getitslabmaster';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path)
  }

  public saveItSlabMaster(insertITSlabDetails: any): Observable<any> {
    console.log("value sent ", insertITSlabDetails)
    this.url = '/v1/saveItSlabMaster';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    return this.commonService.postWithHttpInfo(insertITSlabDetails, queryParameters, path);
  }

  public deleteItSlabMaster(deleteId:any) : Observable<any> {
      console.log("delete id  : ", deleteId);
      this.url = '/v1/deleteItSlabMaster';
      const path = this.basePath + this.url;
      let queryParameters = new HttpParams();
      queryParameters = queryParameters.set('deleteId', deleteId);
      return this.commonService.postWithHttpInfo(deleteId, queryParameters, path);
    
  }

  public getEditItSlabMaster(editId:any) : Observable<any> {
    console.log("editIdService",editId);
    this.url = '/v1/getEditItSlabMaster';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('editId', editId);
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
  }

  public updateItSlabMaster(insertITSlabDetails: any) : Observable<any> {
    console.log("Update id  : ", insertITSlabDetails);
    this.url = '/v1/updateItSlabMaster';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    // queryParameters = queryParameters.set('updateId', insertITSlabDetails);
    return this.commonService.postWithHttpInfo(insertITSlabDetails,queryParameters, path);
  
}
  
  // public downloadIncomeTaxCalculator(employeeList:any[],selectedRegime:any,year:any,selectedempid:any): Observable<any> {
  //   this.url = '/v1/downloadTaxCalculator';
  //   const path = this.basePath + this.url;
  //   let queryParameters = new HttpParams();
  //       queryParameters = queryParameters.set('year', year);
  //       queryParameters = queryParameters.set('selectedempid', selectedempid);
  //       queryParameters = queryParameters.set('taxregime', selectedRegime);
  //       // queryParameters = queryParameters.set('employeeList', employeeList);
  //       queryParameters = queryParameters.set('employeeList', JSON.stringify(employeeList)); 
        
  //   return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path, { responseType: 'arraybuffer' });
  // }

  public downloadIncomeTaxCalculator(employeeList: any[], selectedRegime: any, year: any, selectedempid: any): Observable<any> { 
    const path: string = `${this.basePath}/v1/downloadTaxCalculator`;
    
    // Create an empty HttpParams object since no query parameters are needed
    const queryParameters = new HttpParams();

    const requestBody = {
        year: year,
        selectedempid: selectedempid,
        taxregime: selectedRegime,
        employeeList: employeeList
    };
    
    // Call postWithHttpInfo with an empty HttpParams object
    return this.commonService.postWithHttpInfo(requestBody, queryParameters, path, { responseType: 'arraybuffer' });
}

public getadditionSalaryMaster(): Observable<any> {
  this.url = '/v1/getadditionSalaryMaster';
  const path = this.basePath + this.url;
  let queryParameters = new HttpParams();
  return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path)
}


public getdeductionSalaryMaster(): Observable<any> {
  this.url = '/v1/getdeductionSalaryMaster';
  const path = this.basePath + this.url;
  let queryParameters = new HttpParams();
  return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path)
}

}
