import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from '../services/common.service';
import { JoblevelInfo } from '../models/joblevel';
import { NgModel } from '@angular/forms';
declare var $: any;

@Component({
  selector: 'app-jobmaster',
  templateUrl: './jobmaster.component.html',
  styleUrls: ['./jobmaster.component.css']
})
export class JobmasterComponent implements OnInit {

  jobId: number;
  jobLevelCode: any;
  jobLevelName: any;
  jobHierarchy: any;

  addOrUpdate: boolean;
  add_view_toggle: string;
  saveupdateButton: boolean;

  jobMasterDetail: JoblevelInfo[];
  DeleteJobMaster: JoblevelInfo;

  constructor(private commonService: CommonService) { }
    @ViewChild('annual_name') joblevelcontol: NgModel

  ngOnInit(): void {
    console.log(" on loading ");

    // //default show add and save 
    // this.addOrUpdate = true;
    // // show view screen 
    // this.add_view_toggle = 'viewJobLevel';

    // this.getJobMaster();
    this.viewJobButton();

  }

  addJobButton() {
    console.log("cliecked add btn");

    // on click add show add option
    this.add_view_toggle = 'addJobLevel';
    // show add and save btn
    this.addOrUpdate = true;

  }

  updateJobButton() {
    debugger;
    // update job leave onlywhen edit case 
    console.log("update job ");
    this.jobLevelCode = this.jobLevelCode.trim();
    // if ((this.jobLevelName != undefined && this.jobLevelName.length > 0) && (this.jobLevelCode != undefined && this.jobLevelCode.length > 0 && this.jobLevelCode != null)&& (this.jobHierarchy != null || this.jobHierarchy != undefined)  ){
      if ((this.jobLevelName != undefined && this.jobLevelName.length > 0) &&
       (this.jobLevelCode != undefined && this.jobLevelCode.length > 0 && this.jobLevelCode != null)&&
        (this.jobHierarchy != null || this.jobHierarchy != undefined) ){
      this.commonService.editUpdateJobMaster(this.jobLevelCode, this.jobLevelName, this.jobHierarchy, this.jobId).subscribe(
        data => {
          this.viewJobButton();
          this.alertmessages("Successfully Saved ", "success");
          this.joblevelcontol.reset();
        }, error =>{
          if (error.status === 422 && error.error && error.error.status === "Duplicate") {
            this.alertmessages("Job level Name already exists", "danger");
        } else  {
          this.alertmessages("Try Again Later", "danger");
        }
        });

    } 
    else {
      this.alertmessages("Please Fill all the field", "danger");
    }

  }

  viewJobButton() {
    this.clear();
    // clicked view job 
    console.log("clicked view job ");
    this.addOrUpdate = true;
    this.add_view_toggle = 'viewJobLevel';
    this.getJobMaster();

  }

  saveAddJob() {
    console.log("clicked save");
    this.jobLevelCode = this.jobLevelCode.trim();
    if ((this.jobLevelCode != null && this.jobLevelCode != undefined && this.jobLevelCode.length > 0) && (this.jobLevelName != null || this.jobLevelName != undefined)
      && (this.jobHierarchy != null || this.jobHierarchy != undefined)) {

      this.commonService.insertJobMaster(this.jobLevelCode, this.jobLevelName, this.jobHierarchy).subscribe(
        data => {
          this.alertmessages("Successfully Saved ", "success");
          this.clear();
          this.viewJobButton();
        }, error => {
          console.log(error);
          if (error.status === 422 && error.error && error.error.status === "Duplicate") {
            this.alertmessages("Job Level Name already exists", "danger");
        }else{
          this.alertmessages("Try Again Later", "danger");
        }
       } );
    } else {
      console.log("err");
      this.alertmessages("Please Fill all the required field", "danger");
    }

  }

  editJoblevel(info: any) {
    console.log("edit job ", info);
    this.addOrUpdate = false;
    this.add_view_toggle = 'addJobLevel';
    this.saveupdateButton = true;


    this.jobId = info.joblevelid;
    this.jobLevelCode = info.joblevel;
    this.jobLevelName = info.roledescription;
    this.jobHierarchy = info.hierarchy;

  }

  clear() {
    this.jobLevelCode = '';
    this.jobLevelName = '';
    this.jobHierarchy = '';
  }

  

  deleteJob() {
    const deleteId = this.DeleteJobMaster.joblevelid;
    console.log("deleteId", deleteId);
    this.commonService.deleteJobMaster(deleteId).subscribe(
      (data) => {
        this.getJobMaster();
        $('#showAlertVer').modal('toggle');
        this.alertmessages(data.successMessages[0]['key'], "success")


      }, (error) => {
        console.log(error)
        $('#showAlertVer').modal('toggle');
        this.alertmessages("Job Level cannot be deleted as it's in use", "danger");

      }
    );


  }


  onDeleteHandler(info: any) {
    console.log("delete : ", info);
    this.DeleteJobMaster = info;
    $('#showAlertVer').modal('toggle');
  }

  closeMRQModal() {
    $('#showAlertVer').modal('toggle');

  }

  getJobMaster() {
    this.commonService.getjobMaster().subscribe(data => {
      // console.log(data, "annual holiday list")
      this.jobMasterDetail = data.jobMaster;
      console.log(this.jobMasterDetail);
    });
  }

  alertmessages(message: string, sign: string): void {
    let alertDiv = document.createElement('div');
    alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
    <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message} </div>`
    let alertcomp = document.querySelector('.alertcomp');
    alertcomp.append(alertDiv);
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    setTimeout(function () {
      alertcomp.removeChild(alertDiv);
    }, 3000);
  }

  

}