import { Component, HostListener, OnInit } from '@angular/core';
import { EmployeeMaster } from '../models/employeeMasterts';
import { EmployeeLeaveService } from '../services/employee-leave.service';
import { Employeesuspensioninfo, SuspenseAuthority } from '../models/Employeesuspensioninfo';
import { EmployeeMasterservice } from '../services/employeemaster.service';

@Component({
  selector: 'app-employee-suspension',
  templateUrl: './employee-suspension.component.html',
  styleUrls: ['./employee-suspension.component.css']
})
export class EmployeeSuspensionComponent implements OnInit {
  uniqueEmployeeId = Number(sessionStorage.getItem('empId'));
  employeeMasterLucene: EmployeeMaster[];
  employeeid: String;
  query: any;
  limitPara: any;
  offsetPara: any;
  queryName: any;
  insertemployeesuspension;
  add_view_toggle: string = 'viewSuspension';
  selectedempid: number;
  suspension: any;
  selectedValue: string = 'Active';
  leavingdate: any;
  permissionList: any[] = [];
  showBasedOnPermissionEmployeeStatus: boolean = false;
  suspensionAuthority : SuspenseAuthority[]=[];

  constructor(private empLeaveService: EmployeeLeaveService, private employeeMasterservice: EmployeeMasterservice) { }

  ngOnInit(): void {
    this.limitPara = 13;
		this.offsetPara = 0;
    const maxString = sessionStorage.getItem('permission').replace('[', '');
    const maxStringBox = maxString.replace(']', '');
    const maxStringAgain = maxStringBox.replace(/"/g, '');
    this.permissionList = maxStringAgain.split(',');
    this.checkAccessForUser();
    this.viewSuspension();
    this.insertemployeesuspension = new Employeesuspensioninfo();
    this.getemployeesuspension();
  }

  getEmployeeByNameOrId(callback?: () => void) {
    const [name, id] = this.query.split(' / ');
    this.queryName = name;
    this.employeeid = id; // Store the ID if it's available

    console.log("Len ", this.query);

    if (this.queryName.length <= 2) {
      this.employeeMasterLucene = [];
      if (callback) callback();
      return; // Exit early if the query is too short
    }

    if (this.queryName.length >= 3) {
      this.empLeaveService.getEmployeeByNameOrUniqueId(this.queryName).subscribe(
        data => {
          console.log("Emp Data ", data);

          if (data.employeeMasterData && data.employeeMasterData.length > 0) {
            let filteredEmployees = data.employeeMasterData;

            // Get the current date for comparison
            const currentDate = new Date();
            console.log("Current Date: ", currentDate);  // Log current date for debugging

            // Apply filtering logic based on the selected employee status
            if (this.selectedValue === 'Active') {
              filteredEmployees = filteredEmployees.filter(emp => {
                const leavingDate = emp.leavingdateString ? new Date(emp.leavingdateString) : null;
                console.log("Leaving Date for " + emp.name + ": ", leavingDate);  // Log leaving date for debugging
                // Active employees should have leavingdate in the future or null
                return leavingDate === null || leavingDate > currentDate;
              });
            } else if (this.selectedValue === 'Inactive') {
              filteredEmployees = filteredEmployees.filter(emp => {
                const leavingDate = emp.leavingdateString ? new Date(emp.leavingdateString) : null;
                console.log("Leaving Date for " + emp.name + ": ", leavingDate);  // Log leaving date for debugging
                // Inactive employees should have leavingdate in the past or null
                return leavingDate && leavingDate < currentDate;
              });
            }

            // If the status is 'All', no filter is applied
            if (this.selectedValue === 'All') {
              filteredEmployees = data.employeeMasterData;
            }

            // Assign filtered data to the employeeMasterLucene
            this.employeeMasterLucene = filteredEmployees;

             // Now we find the employee with the matching employeeid
             const selectedEmployee = filteredEmployees.find(emp => emp.employeeid === this.employeeid);

            // Set selectedempid to the uniqueemployeeid of the first employee
            if (selectedEmployee) {
              this.selectedempid = selectedEmployee.uniqueemployeeid;
              this.leavingdate = selectedEmployee.leavingdateString;
              console.log("Filtered Employee Data: ", this.employeeMasterLucene);
              console.log("Leaving Date: ", this.leavingdate);
              console.log("Selected Employee ID: ", this.selectedempid);
            } else {
              console.log("No employee data found.");
            }
          } else {
            console.log("No employee data found.");
          }

          if (callback) callback();
        },
        error => {
          console.log("Error ", error);
          this.alertmessages("An error occurred while searching for the employee", "danger");
          if (callback) callback();
        }
      );
    }
  }
  onStatusChange() {
    this.getEmployeeByNameOrId();
  }

  searchEmployee() {
    this.getEmployeeByNameOrId();
    
    if (!this.query || this.query.trim().length === 0) {
      // Trigger the error message if query is empty or just spaces
      this.alertmessages("Please Enter Employee Name or Unique Employee ID", "danger");
      return;
    }
  
    // Call the getEmployeeByNameOrId method with callback to handle the search result
    this.getEmployeeByNameOrId(() => {
      // Check if no employee data is found
      if (this.employeeMasterLucene.length === 0) {
        this.alertmessages("No employee found with the provided ID or Name.", "danger");
        return;
      }
  
      // Check if the employee has left (leaving date is less than current date)
      const currentDate = new Date();
      const leavingDate = this.employeeMasterLucene[0].leavingdateString ? new Date(this.employeeMasterLucene[0].leavingdateString.toString()) : null;
  
      if (leavingDate && leavingDate < currentDate) {
        // Employee has left, show the appropriate error message
        this.alertmessages("Employee has left. Cannot Suspend.", "danger");
      } else {
        // Proceed with normal behavior if the employee is still active
        this.add_view_toggle = 'addSuspension';
      }
    });
  }
  


  // limitpara() {
  //   this.limitPara = 0;
  // }

  onScroll() {
        this.offsetPara = this.offsetPara + 13;
        console.log("limit = ? ", this.limitPara, "offset = +13 ", this.offsetPara)
        this.getemployeesuspension();
    
      }
    
      limitpara() {
        this.offsetPara = 0;
        console.log(" on clikc limit para ", this.limitPara)
        console.log("this offset", this.offsetPara)
      }
    
      @HostListener('scroll', ['$event'])
      scrolled() {
    
        console.log("Scrolled >>> ")
        this.onScroll();
      }
    
  submit() {
    // Validate mandatory fields
    if (
      !this.insertemployeesuspension.fromdate ||
      !this.insertemployeesuspension.todate ||
      !this.query ||
      !this.insertemployeesuspension.reason
    ) {
      this.alertmessages("Please fill all required fields.", "danger");
      return;
    }

    // Perform employee search and wait for completion
    this.getEmployeeByNameOrId(() => {
      if (this.employeeMasterLucene.length === 0) {
        // Trigger error message when no employee is found
        this.alertmessages("No employee found with the provided ID or Name.", "danger");
        return; // Exit without saving
      }

      // Validate dates
      const fromDate = new Date(this.insertemployeesuspension.fromdate);
      const toDate = new Date(this.insertemployeesuspension.todate);
      if (toDate < fromDate) {
        this.alertmessages("To Date cannot be prior to From Date.", "danger");
        return;
      }

      // Check if a suspension already exists for the same employee and the same date range
      const existingSuspension = this.suspension.some(susp => {
        const existingFromDate = new Date(susp.fromdate);
        const existingToDate = new Date(susp.todate);
        // Check if it's the same employee and date overlap
        return susp.uniqueEmployeeId === this.selectedempid &&
          (fromDate <= existingToDate && toDate >= existingFromDate);
      });

      if (existingSuspension) {
        this.alertmessages("Suspension already exists for this employee in this date", "danger");
        return;
      }

      //To avoid applying suspension for logged user themselves
      const loggedUserId = Number(sessionStorage.getItem("empId")); 
      
      
      console.log("Logged User ID:", loggedUserId, "Type:", typeof loggedUserId);
      console.log("Selected Employee ID:", this.selectedempid, "Type:", typeof this.selectedempid);
      
      if (!this.selectedempid) {
          console.error("Error: uniqueEmployeeId is undefined or null");
          return;
      }
      
      if (loggedUserId === this.selectedempid) {
          this.alertmessages("Cannot apply suspension to yourself.", "danger");
          console.log("Suspension blocked"); // Debugging
          return;
      }
      
      // Save suspension data
      console.log("save ", this.insertemployeesuspension);
      console.log("empid ", this.insertemployeesuspension.uniqueEmployeeId);
      console.log("fromdate ", this.insertemployeesuspension.fromdate);
      console.log("todate:", this.insertemployeesuspension.todate);
      console.log("Reason:", this.insertemployeesuspension.reason);

      this.insertemployeesuspension.uniqueEmployeeId = this.selectedempid;
      this.employeeMasterservice.saveEmployeeSuspension(this.insertemployeesuspension).subscribe(
        (data) => {
          console.log(data);
          this.getemployeesuspension();
          this.clear();
          this.add_view_toggle = "viewSuspension";
          this.alertmessages("Employee Suspension Applied successfully", "success");
        },
        (error) => {
          console.log(error);
          // Check if backend sent an error message -when lower jL tries to apply suspension for higher jL
          if (error.error && error.error.errorMessages && error.error.errorMessages.length > 0) {
            this.alertmessages(error.error.errorMessages[0].key, "danger"); // Show specific backend error
          } else {
            this.alertmessages("Please Try Again", "danger"); // Default error message
          }
          //this.alertmessages("Please Try Again", "danger");
        }
      );
    });
  }



  clear() {
    this.insertemployeesuspension.fromdate = "";
    this.insertemployeesuspension.todate = "";
    this.insertemployeesuspension.reason = "";
    this.insertemployeesuspension = new Employeesuspensioninfo();
    this.query = '';
  }
  alertmessages(message: string, sign: string): void {
    let alertDiv = document.createElement('div');
    alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
    <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message} </div>`
    let alertcomp = document.querySelector('.alertcomp');
    alertcomp.append(alertDiv);
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    setTimeout(function () {
      alertcomp.removeChild(alertDiv);
    }, 3000);
  }


  getemployeesuspension() {
    console.log("limitPara",this.limitPara , " offsetpara",this.offsetPara);
    this.employeeMasterservice.getemployeesuspension(this.limitPara,this.offsetPara).subscribe(
      (data) => {
        console.log("Data available :", data);
        this.suspension = data.employeesuspension_intermediate;
        console.log("List of all the suspension available :", this.suspension);
      },
      (error) => {
        console.log(error);
      }
    );
  }
  checkAccessForUser() {
    console.log("checkAccessForUser here")
    this.showBasedOnPermissionEmployeeStatus = this.permissionList.includes('ng.employee.search.show.employeestatus');
  }

 
  viewSuspension() {
    console.warn("viewShiftTypeButton ");

     this.getemployeesuspension();
     this.add_view_toggle = "viewSuspension";
    //  this.clear();

  }

}
