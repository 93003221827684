import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { EmpsalaryslipService } from '../services/empsalaryslip.service';
import { EmployeeLeaveService } from '../services/employee-leave.service';
import { EmployeeMaster } from '../models/employeeMasterts';
import { EmployeeAttendenceProxyService } from '../services/employee-attendence-proxy.service';
import { IncometaxcalculatorServiceService } from '../services/incometaxcalculator-service.service';
import { IncomeTaxCalculator } from '../models/IncomeTaxCalculator';
import { IncomeTaxSavings } from '../models/IncomeTaxSaving';

@Component({
  selector: 'app-income-tax-calculator',
  templateUrl: './income-tax-calculator.component.html',
  styleUrls: ['./income-tax-calculator.component.css']
})
export class IncomeTaxCalculatorComponent implements OnInit {

  constructor(private incometaxcalculatorServiceService: IncometaxcalculatorServiceService,private empsalaryservice: EmpsalaryslipService,private empLeaveService: EmployeeLeaveService,private employeeService: EmployeeAttendenceProxyService,private cd: ChangeDetectorRef) { }

  // ngOnInit(): void {
  //   this.limitPara = 100;
	// 	this.offsetPara = 0;
  //   this.getAllBranches();
  //   const currentYear = new Date().getFullYear();
  //   for (let year = currentYear; year >= 2018; year--) {
  //     this.years.push(`${year} - ${year + 1}`); 
  //   }
  //   this.year = `${currentYear} - ${currentYear+1}`;
  //   this.employeeList = [];
  // }

  ngOnInit(): void {
    const maxString = sessionStorage.getItem('permission').replace('[', '');
    const maxStringBox = maxString.replace(']', '');
    const maxStringAgain = maxStringBox.replace(/"/g, '');
    this.permissionList = maxStringAgain.split(',');
    this.checkAccessForUser();
    this.limitPara = 100;
    this.offsetPara = 0;
    this.regimes =[{ value: '', label: 'Select Regime' },
      { value: 'all', label: 'All' },
      { value: 'new', label: 'New Regime' },
      { value: 'old', label: 'Old Regime' }
    ];
    console.log("regime",this.regimes);
    this.getAllBranches();
    this.getSavingsMaster();
    // Initialize currentYear and years list
    const currentYear = new Date().getFullYear();
    this.years = []; // Clear previous years list if any
    for (let year = currentYear; year >= 2018; year--) {
      this.years.push(`${year} - ${year + 1}`); 
    }

    // Set the default year to the current year range
    this.year = `${currentYear} - ${currentYear + 1}`;
    
    // Initialize employee list as empty
    this.employeeList = [];
}


  months = [
    { name: 'January', value: 1 },
    { name: 'February', value: 2 },
    { name: 'March', value: 3 },
    { name: 'April', value: 4 },
    { name: 'May', value: 5 },
    { name: 'June', value: 6 },
    { name: 'July', value: 7 },
    { name: 'August', value: 8 },
    { name: 'September', value: 9 },
    { name: 'October', value: 10 },
    { name: 'November', value: 11 },
    { name: 'December', value: 12 }
  ];
  selectedMonth: { name: string, value: number } | undefined;
  years: string[] = [];
  directoryPath: string = '';
  year: any;
  selecteduniqueeName: any;
  selecteduniqueeEmpId:any;
  employeeList: any[] = [];
  savingList: any[] = [];
  savingsList: any[] = [];
  query: any;
  queryName: any;
  selectedempid: any;
  branch: any = "all";
  branchInfo: any;
  limitPara: any;
  offsetPara: any;
  clearChild: number = 0;
  selectedRegime: string = 'all';
  fileToUpload:any;
  incomeTaxCalculator:IncomeTaxCalculator[]=[];
  excelErrors: string[] = [];
  isFileValid: boolean = true;
  uploadError: boolean = false;
  isFetching:boolean = false;
  regimes:any[]=[];
  ActiveInativeLuceneFilter : string='Active';
   leavingdate: any;
   permissionList: any[] = [];
   showBasedOnPermissionEmployeeStatus: boolean = false;
   employeeMasterLucene: EmployeeMaster[];
    
  downloadFile() {
    if (!this.year) {
        console.log('year', this.year);
        return;
    }
    this.empsalaryservice.downloadIncomeTaxCalculator(this.employeeList, this.selectedRegime, this.year, this.selectedempid).subscribe(
        (data) => {
            const file = new Blob([data], { type: 'application/vnd.ms-excel' });
            const fileURL = URL.createObjectURL(file);
            const anchor = document.createElement("a");
            anchor.download = "incomeTaxReport" + this.year + ".xlsx";
            anchor.href = fileURL;
            anchor.click();
            URL.revokeObjectURL(fileURL);  // Clean up the URL object after download
        },
        (error) => {
            console.log("Error Occurred ", error);
        }
    );
}


getEmployeeByNameOrId() {
  const [name, id] = this.query.split(' / ');
  this.queryName = name;
  console.log("Len ", this.query);

  if (this.queryName.length <= 2) {
    this.employeeMasterLucene = [];
  }

  if (this.queryName.length >= 3) {
    this.empLeaveService.getEmployeeByNameOrUniqueId(this.queryName).subscribe(
      data => {
        console.log("Emp Data ", data);

        // Check if data.employeeMasterData has any entries
        if (data.employeeMasterData.length > 0) {
          let filteredEmployees = data.employeeMasterData;

          // Get the current date for comparison
          const currentDate = new Date();
          console.log("Current Date: ", currentDate);  // Log current date for debugging

          // Apply filtering logic based on the selected employee status
          if (this.ActiveInativeLuceneFilter === 'Active') {
            filteredEmployees = filteredEmployees.filter(emp => {
              const leavingDate = emp.leavingdateString ? new Date(emp.leavingdateString) : null;
              console.log("Leaving Date for " + emp.name + ": ", leavingDate);  // Log leaving date for debugging
              // Active employees should have leavingdate in the future or null
              return leavingDate === null || leavingDate > currentDate;
            });
          } else if (this.ActiveInativeLuceneFilter === 'Inactive') {
            filteredEmployees = filteredEmployees.filter(emp => {
              const leavingDate = emp.leavingdateString ? new Date(emp.leavingdateString) : null;
              console.log("Leaving Date for " + emp.name + ": ", leavingDate);  // Log leaving date for debugging
              // Inactive employees should have leavingdate in the past or null
              return leavingDate && leavingDate < currentDate;
            });
          }

          // If the status is 'All', no filter is applied
          if (this.ActiveInativeLuceneFilter === 'All') {
            filteredEmployees = data.employeeMasterData;
          }

          // Assign filtered data to the employeeMasterLucene
          this.employeeMasterLucene = filteredEmployees;

          // Log the data and leaving date of the first employee in the filtered list
          if (this.employeeMasterLucene.length > 0) {
            this.leavingdate = this.employeeMasterLucene[0].leavingdateString; // Extract the leaving date
            console.log("Filtered Employee Data: ", this.employeeMasterLucene);
            console.log("leavingdate", this.leavingdate);

            // Assign the unique employee ID to selectedempid
            this.selectedempid = this.employeeMasterLucene[0].uniqueemployeeid;
            console.log("Selected Employee ID: ", this.selectedempid); // Log the selected employee ID for debugging
          } else {
            console.log("No employee data found.");
          }
        } else {
          console.log("No employee data found.");
        }
      },
      error => {
        console.log("Error ", error);
      }
    );
  }
}


onStatusChange() {
  this.getEmployeeByNameOrId();
}
checkAccessForUser() {
  console.log("checkAccessForUser here")
  this.showBasedOnPermissionEmployeeStatus = this.permissionList.includes('ng.employee.search.show.employeestatus');
}
  onBranchChange(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    this.branch = selectElement.value;
    console.log('Branch changed to: ', this.branch);
    // this.clearOnchange();
  }

  // initializeIncomeTaxCalculator() {
  //   this.employeeList.forEach((employee, i) => {
  //     console.log("hello");
  //     this.incomeTaxCalculator[i] = {
  //       uniqueemployeeid: employee.uniqueemployeeid,
  //       otherPay: '',
  //       incomeTax: this.savingsList.map(() => new IncomeTaxSavings())
  //     };
  //   });
  // }

//   initializeIncomeTaxCalculator() {
//     this.employeeList.forEach((employee, i) => {
//         console.log("hello");
//         const filteredSavings = this.savingList.filter(sav => sav.uniqueemployeeid === employee.uniqueemployeeid); // Filtered savings

//         this.incomeTaxCalculator[i] = {
//             uniqueemployeeid: employee.uniqueemployeeid,
//             otherPay: employee.otherPay != null ? parseFloat(employee.otherPay).toFixed(2) : "0.00",
//             incomeTaxId: employee.incomeTaxId,
//             incomeTax: filteredSavings.length > 0 
//                 ? filteredSavings.map((sav) => ({
//                     ...new IncomeTaxSavings(), // Initialize with default values
//                     amount: sav.amount != null ? parseFloat(sav.amount).toFixed(2) : "0.00" 
//                 }))
//                 : this.savingsList.map(() => new IncomeTaxSavings()) // Default value when no match
//         };
//     });
//     console.log("this.incomeTaxCalculator", this.incomeTaxCalculator);
// }

  getAllBranches() {
    console.log("Called all branches >> >> >>")
    this.employeeService.getAllBranchs().subscribe(
      data => {
        console.log("check this")
        this.branchInfo = data.branches;
      },
      error => {
        console.log("Error ", error);
      }
    );
  }

  search() {
    this.isFetching=true;
    this.offsetPara = 0;
    this.clearChild = 0; 
    console.log("before calling attendnace report function ", this.clearChild);
  }

  // clear() {
  //   this.query ='';
  //   this.selectedempid ='';
  //   this.branch ="all";
  //   const currentYear = new Date().getFullYear();
  //   this.year = `${currentYear} - ${currentYear+1}`;
  //   this.selectedRegime ='new';
  //   const fileInput = document.getElementById('file') as HTMLInputElement;
  //   fileInput.value = ''; 

  //   this.savingList =[];
  //   this.savingsList =[];
  //   this.employeeList =[];
  //   this.cd.detectChanges();
  //   console.log("Emp List",this.employeeList)
  // }

  clear() {
    this.query = '';
    this.selectedempid = '';
    this.branch = "all";
    
    // Reset year to the default current year range
    const currentYear = new Date().getFullYear();
    this.year = `${currentYear} - ${currentYear + 1}`;
    
    // Reset selectedRegime and employeeList
    this.selectedRegime = 'all';
    this.employeeList = [];
    
    // Additional reset for other lists if needed
    this.savingList = [];
    // this.savingsList = [];

    // Reset file input and change detection
    const fileInput = document.getElementById('file') as HTMLInputElement;
    fileInput.value = ''; 
}


  getIncomeTaxList() {
    console.log("query",this.query);
    if(this.query != undefined){
    const [name, id] = this.query.split(' / ');
    this.selectedempid =id;
    }
    this.incometaxcalculatorServiceService.getEmployeeList(this.selectedempid,this.branch,this.year,this.limitPara,this.offsetPara,this.selectedRegime).subscribe(
      data => {
        console.log("empdata",data)
        this.employeeList = data.incomeTax;
        
        this.getSavingsMaster();
      },
      error => {
        console.log("Error ", error);
      }
    );
  }


  getSavingMaster(){
    this.incometaxcalculatorServiceService.getSavingMaster(this.selectedempid,this.branch,this.year).subscribe(
      data => {
        console.log("savingData",data)
        this.savingList = data.savingMaster;
        // console.log("saving List",this.savingList)
        // this.initializeIncomeTaxCalculator();
      },
      error => {
        console.log("Error ", error);
      }
    );
  }

  getSavingsMaster(){
    this.incometaxcalculatorServiceService.getSavingsMaster(this.selectedempid,this.branch,this.year).subscribe(
      data => {
        console.log("savingData",data)
        this.savingsList = data.savingsMaster;
        // console.log("saving List",this.savingList)
        // this.initializeIncomeTaxCalculator();
      },
      error => {
        console.log("Error ", error);
      }
    );
  }

  onOtherPayChange(inputValue: string, defaultOtherPay: number, index: number) {
    if (inputValue === '') {
      this.incomeTaxCalculator[index].otherPay = defaultOtherPay;
    } else {
      this.incomeTaxCalculator[index].otherPay = +inputValue; 
    }
  }

  alertmessages(message: string, sign: string): void {
    let alertDiv = document.createElement("div");
    alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
    <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message} </div>`;
    let alertcomp = document.querySelector(".alertcomp");
    alertcomp.append(alertDiv);
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    setTimeout(function () {
      alertcomp.removeChild(alertDiv);
    }, 3000);
  }

  handleFileInput(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.fileToUpload = file;
      console.log("file:",this.fileToUpload);
    }
    console.log("file:",this.fileToUpload);
  }

  uploadFile() {
          const typeList = ['xlsx'];
          if (typeList.includes(this.fileToUpload.name.split(".").pop())) {
           
            console.log("The Name of Uploaded Files : I If loop", this.fileToUpload.name);
      
            this.incometaxcalculatorServiceService.uploadEmpExcelDocument(this.fileToUpload,this.selectedRegime).subscribe(
              data => {
                console.log("The Error is ", data);
                this.excelErrors = data.excellmessages;
                if (this.excelErrors && this.excelErrors.length > 0) {
                  this.uploadError = true;
                  console.log("The Error is1 ", this.excelErrors);
                  const fileInput = document.getElementById('file') as HTMLInputElement;
                  fileInput.value = ''; 
                  this.alertmessagesWithLink("There Are errors in excel.", "danger");
                } else {
                        // The array is empty or undefined
                  console.log('No errors found.');
                  this.uploadError = false;
                  // this.alertmessagesWithLink("File Uploaded Successfully.", "success");
                  const fileInput = document.getElementById('file') as HTMLInputElement;
                  fileInput.value = ''; 
                  this.alertmessages("File Uploaded Successfully.", "success");
                }
              },
              error => {
                console.log("The Error is ", error);
                this.alertmessages("Failed to Save", "danger");
              });
            }else{
              this.isFileValid = false;
            }    
          }       

          alertmessagesWithLink(message: string, sign: string): void {
            let alertDiv = document.createElement('div');
            if(this.uploadError){
              alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
              <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message} 
              <button id="viewErrorsBtn" class="btn btn-link" data-bs-toggle="modal" data-bs-target="#excelErrors">View Errors</button>
              </div>`
            }else{
            alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
            <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message} </div>`
            }
        
            let alertcomp = document.querySelector('.alertcompExcelUpload');
            alertcomp.append(alertDiv);
            // document.body.scrollTop = document.documentElement.scrollTop = 0;
            (<HTMLButtonElement>document.getElementById('btndisabled')).disabled = true;
            setTimeout(function () {
              alertcomp.removeChild(alertDiv);
              this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                // this.router.navigate(['/employeemaster']);
              });
        
        
            }, 5000);
          }

          onScroll() {
              // this.incomeTaxCalculator = [];
              this.limitPara=  this.limitPara + 15;
              console.log("offset=============>",this.limitPara);
              // this.getIncomeTaxList();
              // this.getSavingMaster();
          }

          getFilteredSavingsList(empUniqueId: string) {
            return this.savingList.filter(sav => sav.uniqueemployeeid == empUniqueId);
          }

          roundUp(value: number, divisor: number): number {
            return Math.ceil(value / divisor);
          }
          
          updateNewRegime(isChecked: boolean, emp: any) {
            emp.newRegime = isChecked ? 'Y' : 'N';
          }

          onRegimeChange(){
            this.employeeList=[];
          }
          
}
