<div class="app-content main-content">
    <div class="side-app main-container">
        <div class="alertcomp">

        </div>
        <!-- <div class="page-header d-xl-flex d-block">
            <div class="page-leftheader">
                <div class="page-titleMod">Leave Status Report</div>
            </div>
        </div> -->
        <!-- <div class="row">
            <div class="col-xl-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <form id="fcForm" class="form-horizontal form-label-left">
                            <div class="x_content row px-4">
                                <div class="col-md-4 col-sm-4 col-xs-4">
                                    <div class="form-group label-floating">
                                        <label class="form-label"> From Date: </label>
                                        <div>
                                            <input class="form-control" type="date" name="fromdate" id="fromdate"
                                                [(ngModel)]="fromDate">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-4 col-xs-4">
                                    <div class="form-group label-floating">
                                        <label class="form-label"> To Date: </label>
                                        <div>
                                            <input class="form-control" type="date" name="todate" id="todate"
                                                [(ngModel)]="toDate">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-4 col-xs-4">
                                    <div class="form-group label-floating">
                                        <label class="form-label">Select Status </label>
                                        <div>
                                            <div>
                                                <select id="leaveStatusFilters" class="form-control"
                                                    (change)="onLeaveStatusChange($event.target.value)"
                                                    name="dateFilter">
                                                    <option value="All">All</option>
                                                    <option value="Approved">Approved</option>
                                                    <option value="Rejected">Rejected</option>
                                                    <option value="Leave Approve Pending">Leave Approve Pending</option>
                                                    <option value="Leave Cancel Pending">Leave Cancel Pending</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-12 col-md-12 col-lg-12">
                                <form class="form-horizontal" id="fcForm"
                                    (keyup.enter)="limitpara(); searchEmployeeLeave()">
                                    <div class="form-group row">
                                        <div class="col-md-4 col-sm-4 col-xs-4">
                                            <label for="employeename"
                                                class="w-100 max-content fontSize-15 form-label my-auto mb-0 ps-2"
                                                style="padding-right:35px">Employee Name / ID </label>
                                            <div class="employeeSearch col-xs-12 col-md-12 px-0">
                                                <input type="search" class="form-control " name="employeename"
                                                    id="employeename" [(ngModel)]="query" autocomplete="off"
                                                    (input)="getEmployeeByNameOrId()" list="employeeMaster"
                                                    autofocus="autofocus" placeholder="Search Employee Name / ID">
                                                <datalist id="employeeMaster">
                                                    <option data-value={{emp.name}} *ngFor="let emp of employeeMaster">
                                                        {{emp.employeeid}}
                                                    </option>
                                                </datalist>
                                            </div>
                                        </div>

                                        
                                        <div class="col-md-4 col-sm-4 col-xs-4">
                                            <div class="form-group label-floating">
                                                <label class="form-label">Order By</label>
                                                <div>
                                                    <select class="form-control" [(ngModel)]="orderBy"
                                                        (change)="onOrderByChange()">
                                                        <option value="employeeId">Employee ID</option>
                                                        <option value="employeeName">Employee Name</option>
                                                        <option value="leaveDate">Leave Date</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                        
                                        <div class="col-md-4 col-sm-4 col-xs-4" style="margin-top: 25px;">
                                            <button type="button" id="navigateclient"
                                                class="btn btn-primary w-100 w-md-100 mt-3 mt-md-0 p-2 p-md-1"
                                                (click)="limitpara(); searchEmployeeLeave()">
                                                <i class="fa fa-search p-0" aria-hidden="true"></i> Search
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div> -->

        <div class="row">
            <div class="col-xl-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <form id="fcForm" class="form-horizontal form-label-left">
                            <div class="x_content row px-4">
                                <div class="col-md-3 col-sm-3 col-xs-3">
                                    <div class="form-group label-floating">
                                        <label class="form-label"> From Date: </label>
                                        <div>
                                            <input class="form-control" type="date" name="fromdate" id="fromdate"
                                                [(ngModel)]="fromDate">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 col-sm-3 col-xs-3">
                                    <div class="form-group label-floating">
                                        <label class="form-label"> To Date: </label>
                                        <div>
                                            <input class="form-control" type="date" name="todate" id="todate"
                                                [(ngModel)]="toDate">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 col-sm-3 col-xs-3">
                                    <div class="form-group label-floating">
                                        <label class="form-label">Select Status </label>
                                        <div>
                                            <div>
                                                <select id="leaveStatusFilters" class="form-control"
                                                    [(ngModel)]="selectedStatus"
                                                    (change)="onLeaveStatusChange($event.target.value)"
                                                    name="dateFilter">
                                                    <option value="All">All</option>
                                                    <option value="Approved">Approved</option>
                                                    <option value="Rejected">Rejected</option>
                                                    <option value="Leave Approve Pending">Leave Approve Pending</option>
                                                    <option value="Leave Cancel Pending">Leave Cancel Pending</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Order By -->
                                <div class="col-md-3 col-sm-3 col-xs-3">
                                    <div class="form-group label-floating">
                                        <label class="form-label">Order By</label>
                                        <div>
                                            <select class="form-control" [(ngModel)]="orderBy"
                                                (change)="onOrderByChange()">
                                                <option value="employeeId">Employee ID</option>
                                                <option value="employeeName">Employee Name</option>
                                                <option value="leaveDate">Leave Date</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 col-sm-12 col-xs-12 mt-3" style="padding: 0px 0px">
                                    <form class="form-horizontal" id="fcForm"
                                        (keyup.enter)="limitpara(); searchEmployeeLeave()">
                                        <div class="form-group row">
                                            <div class="col-md-6 col-sm-4 col-xs-4">
                                                    <label class="form-label">Employee Name / ID</label>
                                                    <input type="search" class="form-control " name="employeename"
                                                    id="employeename" [(ngModel)]="query" autocomplete="off"
                                                    (input)="getEmployeeByNameOrId()" list="employeeMaster"
                                                    autofocus="autofocus"
                                                    placeholder="Search Employee Name / ID">

                                                <datalist id="employeeMaster">
                                                    <option [value]="emp.name + ' / ' + emp.employeeid"
                                                        *ngFor="let emp of employeeMasterLucene">
                                                        <!-- {{emp.name}} -->
                                                    </option>
                                                </datalist>
                                            </div>

                                            <!-- Active/Inactive Employee Status filter for Lucene Search -->
                                            <div class="col-md-3 col-sm-3 col-xs-3">
                                                <label class="form-label">Employee Status</label>
                                                <select class="form-control" type="text" style="width: 100%;" required
                                                    [(ngModel)]="ActiveInativeLuceneFilter" name="selVal"
                                                    (change)="onStatusChange()"
                                                    [disabled]="!showBasedOnPermissionEmployeeStatus">
                                                    <option value="All">All</option>
                                                    <option value="Active">Active</option>
                                                    <option value="Inactive">Inactive</option>
                                                </select>
                                            </div>

                                             
                                            <div class="col-md-3 col-sm-4 col-xs-12 mb-4">
                                                <label class="form-label">Type</label>
                                                <select class="form-control" type="text" style="width: 100%;" required
                                                    [(ngModel)]="selectedValue" name="valSel"  (change)="onTypeChange($event)">
                                                    <option value="Detailed">Detailed</option>
                                                    <option value="Consolidated">Consolidated</option>
                                                </select>
                                            </div>


                                            <!-- Search Button -->
                                            <div class="col-md-3 col-sm-4 col-xs-4 mt-5">
                                                <button type="button" id="navigateclient" class="btn btn-primary w-45"
                                                    style="margin-right: 1vw;"
                                                    (click)="limitpara(); searchEmployeeLeave()">
                                                    <i class="fa fa-search p-0" aria-hidden="true"></i> Search
                                                </button>
                                                <button type="button" class="btn btn-danger w-45"
                                                    (click)="clear()">Clear</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>


        <!-- <div class="page-header d-xl-flex d-block" *ngIf="showTotalLeaveReport">
            <div class="page-leftheader">
                <div class="page-titleMod">Total Leave Report</div>
            </div>
        </div> -->
        <!-- <div class="row">
            <div class="card-body"> -->
        <!-- <div class="card" *ngIf="showTotalLeaveReport">
            <div class="card-body">
                <div class="card-header  border-0">
                    <h4 class="card-title">Total Leave Report</h4>
                </div>

                <form id="fcForm" class="form-horizontal form-label-left">
                    <div class="row mt-3">
                        <div class="panel-body border-0 d-flex flex-wrap">
                            <div *ngFor="let leaveTypeCount of leaveTypeCounts"
                                class="p-1 d-flex font-weight-semibold align align-items-center">
                                <span [style.backgroundColor]="colorCodeMap.get(leaveTypeCount.leaveName)"
                                    class="dot-label me-2"></span>
                                {{ leaveTypeCount.leaveName }}: {{ leaveTypeCount.count }}
                            </div>
                        </div>
                        <div class="col-md-3 mx-auto text-center">
                            <label>Total Absence: {{ totalAbsence }}</label>
                        </div>
                    </div>
                </form>


            </div>
        </div> -->
        <!-- </div>
        </div> -->

        <div class="empty-div-one" id="add-update-client"></div>
        <div class="row">
            <div class="col-xl-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="card-header d-flex justify-content-between align-items-center  border-0">
                        <h4 class="card-title">{{title}}</h4>

                        <span [routerLink]="['/employeeleaveapproval']" class="link-text"
                        [routerLinkActive]="['highlightActiveLink']"
                        (click)="setMenuName('Approve Leave');"
                        [ngClass]="{'disable-div': !allowToApprove}" *ngIf="allowToApprove">Approve Leave</span>
                        
                    </div>
                    <div class="card-body">
                        <div class="col-xs-12 mobile view">
                            <!-- <div *ngIf="employeesLeaveSummary !== null "> -->
                                <div class="scrollsearchresults" infiniteScroll [infiniteScrollDistance]="2"
                                    [infiniteScrollUpDistance]="1.5" [infiniteScrollThrottle]="100"
                                    [scrollWindow]="false" (scrolled)="onScroll()" id="scrolllength">
                                    <table *ngIf="selectedValue === 'Detailed'"
                                        class="table table-hover table-vcenter text-nowrap table-bordered border-bottom"
                                        id="hr-table">
                                        <thead class="tablerow">
                                            <tr class=" stickyatTop rowcolors">
                                                <th class="border-bottom-0 w-5 fixedcolumn">Employee ID</th>
                                                <th class="border-bottom-0 w-5 secondcolumn">Name</th>
                                                <th class="border-bottom-0">Leave Type</th>
                                                <th class="border-bottom-0">Leave Date</th>
                                                <th class="border-bottom-0">Day</th>
                                                <th class="border-bottom-0">Reason</th>
                                                <th class="border-bottom-0">Applied on</th>
                                                <th class="border-bottom-0">Status</th>
                                                <th class="border-bottom-0">Attachment</th>
                                                <th class="border-bottom-0">Action</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let leave of employeesLeaveSummary">
                                                <td class="fixedcolumn backgroundFW">{{leave.employeeId}}</td>
                                                <td class="secondcolumn backgroundSW">
                                                    <div class="d-flex">
                                                        <div class="me-3 mt-0 mt-sm-2 d-block">
                                                            <h6 class="mb-1 fs-14">{{leave.employeeName}}</h6>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <span _ngcontent-fts-c87="" class="badge "
                                                        style="font-size: 13px;font-weight: 500; color: black;"
                                                        [style.background-color]="leave.colorCode">{{leave.leaveName}}</span>
                                                </td>

                                                <td>{{leave.leaveDate| date: 'mediumDate'}}</td>
                                                <td class="font-weight-semibold">{{leave.halfFullIndic === "F" ?
                                                    "1" : "0.5"}}</td>
                                                <td>{{leave.reason}}</td>
                                                <td>{{leave.appliedDate| date: 'mediumDate'}}</td>
                                                <td>
                                                    <span style="font-size: 13px;font-weight: 500;"
                                                        [ngClass]="leave.leaveStatus === 'Approved' ? 'badge-success' : leave.leaveStatus === 'Rejected' ? 'badge-danger' : leave.leaveStatus === 'Leave Cancel Rejected' ? 'badge-danger':leave.leaveStatus === 'Leave Cancel Pending' ? 'badge-warning text-custom-dark' : 'bg-custom-warning text-custom-dark'"
                                                        class="badge">{{leave.leaveStatus}}</span>
                                                </td>
                                                <td>{{leave.fileName}}</td>
                                                <td>
                                                    <button class="btn btn-primary btn-icon btn-sm me-2"
                                                    (click)="viewdocument(leave.fileName, leave.filePath)" style="width: 32px;">
                                                   <i class="fa fa-eye" data-bs-toggle="tooltip" data-original-title="download"></i>
                                               </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <table *ngIf="selectedValue === 'Consolidated'" class="table  table-vcenter text-nowrap table-bordered border-bottom"
                                    id="hr-leaves">
                                    <thead class="tablerow">
                                        <tr class="rowcolors stickyatTop">
                                            <th class="border-bottom-0">Employee ID</th>
                                            <th class="border-bottom-0 fixedcolumn">Name</th>
                                            <th class="border-bottom-0">Leave Type</th>
                                            <th class="border-bottom-0" >From Date</th>
                                            <th class="border-bottom-0">To Date</th>
                                            <th class="border-bottom-0" >No. Of Leaves</th>
                                            <th class="border-bottom-0">Day</th>
                                            <th class="border-bottom-0">Reason</th>
                                            <th class="border-bottom-0">Applied On</th>
                                            <th class="border-bottom-0">Status</th>
                                            <th class="border-bottom-0">Attachment</th>
                                            <th class="border-bottom-0">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody >
                                        <tr *ngFor="let leave of employeesLeaveSummary">
                                            <td>{{leave.employeeId}}</td>
                                            <td class="fixedcolumn backgroundFW">
                                                <div class="d-flex">
                                                    <div class="me-3 mt-0 mt-sm-2 d-block">
                                                        <h6 class="mb-1 fs-14">{{leave.employeeName}}</h6>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <span _ngcontent-fts-c87="" class="badge "
                                                    style="font-size: 13px;font-weight: 500; color: black;"
                                                    [style.background-color]="leave.colorCode">{{leave.leaveName}}</span>
                                            </td>
                                            <!-- <td>{{leave.leaveName}}</td> -->
                                            <!-- <td>{{leave.leaveDate| date: 'mediumDate'}}</td> -->
                                            <td>{{leave.leaveDate | date: 'mediumDate'}}</td>
                                            <td>{{leave.updatedDate
                                                | date: 'mediumDate'}}</td>
                                            <td>{{leave.noOfDaysLeaveApplied}}</td>

                                            <td class="font-weight-semibold">
                                                {{leave.halfFullIndic === "F" ? "Full Day" : "Half Day"}}
                                            </td>
                                            <td>{{leave.reason}}</td>
                                            <td>{{leave.appliedDate| date: 'mediumDate'}}</td>
                                           
                                            <td>
                                                <span style="font-size: 13px;font-weight: 500;"
                                                    [ngClass]="leave.leaveStatus === 'Approved' ? 'badge-success' : leave.leaveStatus === 'Rejected' ? 'badge-danger' : leave.leaveStatus === 'Leave Cancel Rejected' ? 'badge-danger':leave.leaveStatus === 'Leave Cancel Pending' ? 'badge-warning text-custom-dark' : 'bg-custom-warning text-custom-dark'"
                                                    class="badge">{{leave.leaveStatus}}</span>
                                            </td>
                                            
                                            <td>{{leave.fileName}}</td>
                                            <td data-th="Action">
                                                <button class="btn btn-primary btn-icon btn-sm me-2"
                                                (click)="viewdocument(leave.fileName, leave.filePath)" style="width: 32px;">
                                               <i class="fa fa-eye" data-bs-toggle="tooltip" data-original-title="download"></i>
                                           </button>

                                                <button *ngIf="leave.leaveStatus !== 'Leave Cancel Rejected'"
                                                    type="button" (click)="onDeleteHandler(leave)"
                                                    class="btn btn-danger btn-icon btn-sm me-2" data-bs-toggle="tooltip"
                                                    data-bs-placement="top" title="Delete">
                                                    <i class="feather feather-trash-2 "></i>
                                                </button>
                                                
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                </div>
                            <!-- </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>