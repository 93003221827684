<div class="app-content main-content">
    <div class="side-app main-container">
        <div class="alertcomp"></div>

        <!-- <div class="card-body px-0">
            <div class="page-leftheader my-3">
                <div class="page-title">Generate Salary</div>
            </div>
        </div> -->


        <div class="row">
            <div class="col-md-12">
                <div class="card-content">
                    <form #generateSalary="ngForm">
                        <section>
                            <div class="card">
                                <div class="card-body">

                                    <div class=" row col-md-12 col-sm-12 col-xs-12">

                                        <div class="col-md-3 col-sm-3 col-xs-3">
                                            <div class="form-group label-floating">
                                                <label class="form-label">From Date</label>
                                                <div>
                                                    <input class="form-control col-md-12 col-sm-12 col-xs-12"
                                                        (change)="onGenerateChange($event.target.value)" type="date"
                                                        name="fromDate" autocomplete="off" autofocus="true" required
                                                        [(ngModel)]="fromDate">
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-3 col-sm-3 col-xs-3">
                                            <div class="form-group label-floating">
                                                <label class="form-label">To Date</label>
                                                <div>
                                                    <input class="form-control col-md-12 col-sm-12 col-xs-12"
                                                        (change)="onGenerateChange($event.target.value)" type="date"
                                                        name="toDate" autocomplete="off" autofocus="true" required
                                                        [(ngModel)]="toDate">
                                                    <p *ngIf="endDate" class="help-block " style="color: #ff5757;">
                                                        ⓘ To date should be greater than From date

                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- <div class="col-md-3 col-sm-3 col-xs-3">
                                            <div class="form-group label-floating">
                                                <label class="form-label">Select Employee</label>
                                                <select id="employee" class="form-control" name="employee"
                                                    placeholder="Select Employee" autocomplete="off"
                                                    autofocus="autofocus" [(ngModel)]="employeeid"
                                                    (change)="onGenerateChange($event.target.value)">
                                                    <option value="all" selected="selected">All</option>
                                                    <option value={{data.uniqueemployeeid}}
                                                        *ngFor="let data of activeEmployeeList">
                                                        {{data.name}}
                                                    </option>
                                                </select>
                                            </div>
                                        </div> -->

                                        <div class="col-md-6 col-sm-3 col-xs-3 mb-3">
                                            <label class="form-label w-100 mb-1">Employee
                                                Name / ID </label>
                                            <div class="form-group m-0">
                                                <div>
                                                    <input type="search" class="form-control " name="employeename"
                                                        id="employeename" [(ngModel)]="query" autocomplete="off"
                                                        (input)="getEmployeeByNameOrId()" list="employeeMaster"
                                                        (change)="onGenerateChange($event.target.value)"
                                                        autofocus="autofocus" placeholder="Search Employee Name / ID">
                                                    <datalist id="employeeMaster">
                                                        <option [value]="empMas.name + ' / ' + empMas.employeeid"
                                                            *ngFor="let empMas of employeeMaster">
                                                        </option>
                                                    </datalist>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- Active/Inactive Employee Status filter for Lucene Search -->
                                        <div class="form-group col-md-3 col-sm-4 col-xs-4 px-2">
                                            <label class="form-label">Employee Status</label>
                                            <select class="form-control" type="text" style="width: 100%;" required
                                                [(ngModel)]="ActiveInativeLuceneFilter" name="selVal"
                                                (change)="onStatusChange()"
                                                [disabled]="!showBasedOnPermissionEmployeeStatus">
                                                <option value="All">All</option>
                                                <option value="Active">Active</option>
                                                <option value="Inactive">Inactive</option>
                                            </select>
                                        </div>

                                        <!-- <div class="col-md-3 col-sm-3 col-xs-3 mb-3" >
                                    <label class="form-label w-100 mb-1">Employee
                                        Name / ID </label>
                                    <div class="form-group m-0">
                                        <div>
                                            <input type="search" class="form-control " name="employeename"
                                                id="employeename" [(ngModel)]="query" autocomplete="off"
                                                (input)="getEmployeeByNameOrId()" list="employeeMaster"
                                               (change)="onGenerateChange($event.target.value)"
                                                autofocus="autofocus" placeholder="Search Employee Name / ID">
                                                <datalist id="employeeMaster">
                                                    <option  [value]="empMas.name + ' / ' + empMas.employeeid" *ngFor="let empMas of employeeMaster" >
                                                      
                                                    </option>
                                                </datalist>
                                        </div>
                                    </div>
                                </div> -->

                                        <div class="col-md-3 col-sm-3 col-xs-3">
                                            <div class="form-group label-floating">
                                                <label class="form-label">Select Branch</label>
                                                <select id="branch" class="form-control" name="branch"
                                                    placeholder="Select Branch" autocomplete="off" autofocus="autofocus"
                                                    [(ngModel)]="branch"
                                                    (change)="onGenerateChange($event.target.value)">

                                                    <option value="all" selected="selected">All</option>
                                                    <option value={{data.branchId}} *ngFor="let data of branchInfo">
                                                        {{data.branchname}}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="col-md-3 col-sm-3 col-xs-3">
                                            <label class="form-label col-md-12 col-sm-4 col-xs-4 mb-1">&nbsp;</label>
                                            <div class="form-group m-0">
                                                <button type="button" class="btn btn-primary w-100" id="generateSalary"
                                                    (click)="generateSalarySlip()">
                                                    Generate Salary
                                                </button>
                                            </div>
                                        </div>
                                        <div class="col-md-3 col-sm-3 col-xs-3">
                                            <label class="form-label col-md-12 col-sm-4 col-xs-4 mb-1">&nbsp;</label>
                                            <div class="form-group m-0">
                                                <button type="button" class="btn btn-primary w-100" id="fetchSalary"
                                                    (click)="fetchSalary()">
                                                    Fetch Salary Details
                                                </button>
                                            </div>
                                        </div>

                                    </div>



                                    <div class="clearfix"> </div>

                                </div>
                            </div>
                        </section>

                        <div class="empty-div-one top_margin" id="navigatedest"></div>
                        <div class="card">

                            <div class=" card-header  d-flex justify-content-between order-0">
                                <h4 class="w-15 card-title">Fetch Salary</h4>
                                <div class="">
                                    <button type="button" class="btn btn-primary" (click)="salaryReportCSVDownload()">
                                        <i class="fa fa-download" data-bs-toggle="tooltip"
                                            data-original-title="download"></i>
                                    </button>
                                </div>
                            </div>

                            <div class="card-body">
                                <div class="col-xs-12  mobile view">
                                    <div class="scrollsearchresults" infiniteScroll [infiniteScrollDistance]="2"
                                        [infiniteScrollUpDistance]="1.5" [infiniteScrollThrottle]="100"
                                        [scrollWindow]="false" id="scrolllength">
                                        <table
                                            class="table table-hover table-vcenter text-nowrap table-bordered border-bottom"
                                            id="hr-table">
                                            <thead class="tablerow">
                                                <tr class=" stickyatTop rowcolors">
                                                    <th>Employee ID</th>
                                                    <th>Employee Name</th>
                                                    <th>Date Of Joining</th>
                                                    <th>Account Number</th>
                                                    <!-- <th>Staff Categories</th> -->
                                                    <th>Payroll Type</th>
                                                    <th class="text-center" *ngFor="let sdd of salaryDetail">
                                                        <span>{{sdd.salaryDetailName}}</span>
                                                    </th>
                                                    <th>Pay Days</th>
                                                    <th>LOP</th>
                                                    <th>No Of Days</th>
                                                    <th>Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <ng-container *ngFor="let empInfo of fetchSalaryData ">
                                                    <tr *ngIf="empInfo.salaryDetail && empInfo.salaryDetail.length > 0">
                                                        <td>{{empInfo.employee_id}}</td>
                                                        <td>{{empInfo.employee_name}}</td>
                                                        <td>{{ empInfo.doj| date: 'mediumDate' }}</td>
                                                        <td>{{empInfo.account_number}}</td>
                                                        <td>{{empInfo.payroll_type}}</td>
                                                        <td class="text-right" *ngFor="let sd of empInfo.salaryDetail">
                                                            <span>{{ sd.salaryamount == '-1' ? ('0'| number: '1.2-2') : (sd.salaryamount | number: '1.2-2') }}</span>
                                                        </td>
                                                        <td class="text-right">{{empInfo.pay_days}}</td>
                                                        <td class="text-right">{{empInfo.lop_days}}</td>
                                                        <td class="text-right">{{empInfo.noofdays}}</td>
                                                        <td class="text-right">{{empInfo.total | number: '1.2-2' }}</td>
                                                    </tr>
                                                </ng-container>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-container [ngClass]="generate ? '' : 'd-none'">
    <div class="modal fade" id="plusModal1" tabindex="-1" role="dialog" aria-labelledby="modalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Void Salary</h4>
                </div>
                <div class="modal-body">
                    <p>Are you sure you want to delete and regenerate Salary for selected month ?</p>
                    <div class="d-flex align-items-center">
                        <textarea class="form-control ml-2" rows="3" [(ngModel)]="voidSalaryreason"
                            name="voidSalaryreason_i"></textarea>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" (click)="closemodal()">
                        Cancel
                    </button>

                    <button type="button" class="btn btn-primary medium" (click)="saveData()">
                        Yes, Generate
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-container>