<div class="app-content main-content" [ngClass]="hide == 'true' ? 'hide' : ''">
    <div class="side-app main-container">
        <div class="alertcomp">
        </div>
        <!-- <div class="page-header d-xl-flex d-block"> -->
        <!-- <div class="page-leftheader">
                <div class="page-title">Attendance Report</div>
            </div> -->
        <!-- </div> -->
        <div class="row">
            <div class="col-xl-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <form id="fcForm" class="form-horizontal form-label-left">
                            <div class="x_content row px-4">

                                <!-- report from -->
                                <div class="col-md-4 col-sm-4 col-xs-4">
                                    <div class="form-group label-floating">
                                        <label class="form-label">Report From</label>
                                        <div>
                                            <input class="form-control" type="date" name="fromDate" autocomplete="off"
                                                autofocus="true" [disabled]="false" [(ngModel)]="fromDate"
                                                (ngModelChange)="checkFromAndToDate()" required>
                                        </div>
                                    </div>
                                </div>
                                <!-- report two  -->


                                <div class="col-md-4 col-sm-4 col-xs-4">
                                    <div class="form-group label-floating">
                                        <label class="form-label">Report To</label>
                                        <div>
                                            <input class="form-control" type="date" name="toDate" autocomplete="off"
                                                autofocus="true" [disabled]="false" [(ngModel)]="toDate"
                                                (ngModelChange)="checkFromAndToDate()" required>
                                            <p *ngIf="endDate" class="help-block " style="color: #ff5757;">
                                                ⓘ End date should be greater than start date
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <!-- <p>{{reportType}}</p> -->
                                <div class="col-md-4 col-sm-4 col-xs-4">
                                    <div class="form-group label-floating">
                                        <label class="form-label">Report Type</label>
                                        <div>
                                            <div>
                                                <select id="leaveStatusFilters" class="form-control"
                                                    (change)="onAttendanceReportTypeChange($event.target.value)"
                                                    name="dateFilter" [(ngModel)]="reportType">
                                                    <option value="Consolidated Report">Consolidated
                                                        Report</option>
                                                    <option value="Detailed Report">Detailed Report</option>
                                                    <option value="Attendance Matrix By Hours">Attendance Matrix By
                                                        Hours</option>
                                                    <option value="Attendance Matrix By Status">Attendance Matrix By
                                                        Status</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="col-md-3 col-sm-3 col-xs-3">
                                    <label class="form-label w-100 mb-1">Shift </label>
                                    <div class="form-group m-0">
                                        <div>
                                            <select id="odd_Leave" class="form-control" name="dateFilter"
                                                [(ngModel)]="shift_setting">
                                                <option value="Setting">Shift Based on Setting
                                                </option>

                                                <option value="Shift">Shift Based on Shift Master</option>

                                            </select>
                                        </div>
                                    </div>
                                </div> -->

                            </div>
                            <form class="form-horizontal card-body row label-floating px-4" id="fcForm"
                                (keyup.enter)="getAttendenceReport()">

                                <!-- search bar 5 -->
                                <div [ngStyle]="{'display': showSearchBar ? 'inline-block' : 'none'}"
                                    class="col-md-6 col-sm-6 col-xs-6 mb-4">
                                    <label *ngIf="showSearchBar" class="form-label w-100 mb-1">Employee
                                        Name / ID </label>
                                    <div class="form-group m-0">
                                        <div>

                                            <input type="search" class="form-control " name="employeename"
                                                id="employeename" [(ngModel)]="query" autocomplete="off"
                                                (input)="getEmployeeByNameOrId()" list="employeeMaster"
                                                autofocus="autofocus" placeholder="Search Employee Name / ID">

                                            <datalist id="employeeMaster">
                                                <option [value]="emp.name + ' / ' + emp.employeeid"
                                                    *ngFor="let emp of employeeMasterLucene">
                                                    <!-- {{emp.name}} -->
                                                </option>
                                            </datalist>

                                        </div>
                                    </div>
                                </div>

                                <!-- Active/Inactive Employee Status filter for Lucene Search -->
                                <div [ngStyle]="{'display': showSearchBar ? 'inline-block' : 'none'}"
                                    class="col-md-3 col-sm-12 col-xs-12 mb-4">
                                    <label class="form-label">Employee Status</label>
                                    <select id="ActiveInativeLuceneFilter" class="form-control" style="width: 100%;"
                                        required [(ngModel)]="ActiveInativeLuceneFilter" name="selVal"
                                        (change)="onStatusChange()" [disabled]="!showBasedOnPermissionEmployeeStatus">
                                        <option value="All">All</option>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                    </select>
                                </div>

                                <!-- branch 3 showBasedOnPermissionEmployeeBranch  -->
                                <div [ngStyle]="{'display': nowDetailedReport ? 'none' : ''}"
                                    class="col-md-3 col-sm-3 col-xs-3 mb-4">
                                    <label class="form-label w-100 mb-1">Branch</label>
                                    <div class="form-group m-0">
                                        <div>
                                            <!-- <select id="deptInput" class="form-control" style="width: 100%;" required [(ngModel)]="branch" 
                                            name="branch" (change)="onBranchChange($event)"
                                            [disabled]="!showBasedOnPermissionEmployeeBranch">
    
                                            <option *ngIf="showBasedOnPermissionEmployeeBranch" value="all">All</option>
                                            <option *ngFor="let data of branchInfo" [value]="data.branchId">
                                            {{ data.branchname }}</option>
                                        </select> -->

                                            <select id="deptInput" class="form-control" style="width: 100%;" required
                                                [(ngModel)]="branch" name="branch"
                                                (change)="onBranchChange($event.target.value)"
                                                [disabled]="!showBasedOnPermissionEmployeeBranch">

                                                <!-- Show "All" option only if permission is enabled -->
                                                <option *ngIf="showBasedOnPermissionEmployeeBranch" value="all">All
                                                </option>

                                                <!-- Populate options dynamically from branchInfo -->
                                                <option *ngFor="let data of branchInfo" [value]="data.branchId">
                                                    {{ data.branchname }}
                                                </option>
                                            </select>



                                        </div>
                                    </div>
                                </div>

                                <!-- active / exited  3-->
                                <div [ngStyle]="{'display': showSearchBar ? 'none' : 'inline-block'}"
                                    class="col-md-3 col-sm-3 col-xs-3 mb-4">
                                    <label *ngIf="!showSearchBar" class="form-label w-100 mb-1">Employee Status </label>
                                    <div class="form-group m-0">
                                        <div>
                                            <select id="activeExitFilters" class="form-control"
                                                name="ActiveEmployeeFilter" [(ngModel)]="activeExitFilters"
                                                (change)="onActiveExitFilterChange($event)">
                                                <option value="Active">Active Employee</option>
                                                <option value="Exited">Exited Employee</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <!-- filter odd  3-->
                                <div [ngStyle]="{'display': showSearchBar ? 'none' : 'inline-block'}"
                                    class="col-md-3 col-sm-3 col-xs-3 mb-4">
                                    <label *ngIf="!showSearchBar" class="form-label w-100 mb-1">Attendance Info </label>
                                    <div class="form-group m-0">
                                        <div>
                                            <select id="odd_Leave" class="form-control" name="ExtraFilter"
                                                [(ngModel)]="odd_Leave" (change)="onOddLeaveFilterChange($event)">
                                                <option value="All" [selected]="true">All</option>
                                                <option value="Odd">Odd Swipes</option>
                                                <option value="Leave">Absent and No Leave Taken</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <!-- listing filter -->
                                <div [ngStyle]="{'display': showSearchBar ? 'none' : 'inline-block'}"
                                    class="col-md-3 col-sm-3 col-xs-3 mb-4">
                                    <label *ngIf="!showSearchBar" class="form-label w-100 mb-1">Listing </label>
                                    <div class="form-group m-0">
                                        <div>
                                            <select id="activeExitFilters" class="form-control" name="ListingFilter"
                                                [(ngModel)]="listingFilters" (change)="onListingFilterChange($event)">
                                                <option value="GBB">Group by Branch</option>
                                                <option value="SBB">Split by Branch</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <!-- search / clear  3-->
                                <!-- <label *ngIf="!showSearchBar" class="form-label col-md-2 col-sm-2 col-xs-2 mb-1">&nbsp;
                                </label> -->
                                <div class="col-md-3 col-sm-3 col-xs-3 mb-4">
                                    <label *ngIf="showSearchBar"
                                        class="form-label col-md-12 col-sm-4 col-xs-4 mb-1">&nbsp;</label>
                                    <div class="form-group m-0">
                                        <button type="button" id="navigatetarget" class="btn btn-primary w-45"
                                            style="margin-right: 10px" (click)="search(); getAttendenceReport()">
                                            <i class="fa fa-search" aria-hidden="true"></i>
                                            Search
                                        </button>
                                        <button type="button" class="btn btn-danger w-45" (click)="clear()">
                                            Clear
                                        </button>
                                    </div>
                                </div>

                            </form>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <div class="empty-div-one" id="navigatedest"></div>

        <app-consolidated-report #appconsolidatedreport [consolidatedReport]="consolidatedReport" [fromDate]="fromDate"
            [toDate]="toDate" [shift_setting]="shift_setting" *ngIf="nowConsolidateReport" [branch]="branch"
            [ActiveInativeLuceneFilter]="ActiveInativeLuceneFilter" [selectedempid]="selectedempid"
            [keyinempid]="keyinempid"></app-consolidated-report>

        <app-detailed-report [detailedReportData]="detailedReportData" [consolidatedReport]="detailedConsolidatedReport"
            [fromDate]="fromDate" [shift_setting]="shift_setting" [toDate]="toDate" *ngIf="nowDetailedReport"
            [branch]="branch" [selectedempid]="selectedempid" [keyinempid]="keyinempid"
            [ActiveInativeLuceneFilter]="ActiveInativeLuceneFilter"></app-detailed-report>

        <app-attendance-matrix [attendanceMatrixReport]="attendanceMatrixReport" *ngIf="selectedMatrix"
            [byStatusorHour]="byStatusorHour" [monthName]="monthName" [fromDate]="fromDate" [toDate]="toDate"
            [listingFilters]="listingFilters" [daysArray]="daysArray" [dayOfWeekArray]="dayOfWeekArray"
            [activeExitFilters]="activeExitFilters" (limitEvent)="childToPar($event)" [branch]="branch"
            [clearChild]="clearChild"></app-attendance-matrix>

    </div>
</div>


<div class="modal fade" id="secondaryModal" tabindex="-1" aria-labelledby="secondaryModalLabel" aria-hidden="true"
    style="display: none;">
    <div class="modal-dialog  modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="secondaryModalLabel" style="font-size: 20px;">Detailed Report</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
            </div>
            <div class="modal-body">
                <app-detailed-report [detailedReportData]="detailedReportData"></app-detailed-report>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>