<div class="app-content main-content">
    <div class="side-app main-container">
        <div class="alertcomp"></div>

        <!-- <div class="card-body px-0">
            <div class="page-leftheader my-3">
                <div class="page-title">Salary Head Master</div>
            </div>
        </div> -->


        <div class="row">
            <div class="col-md-12">
                <div class="card-content">
                    <form #salaryHeadForm="ngForm">
                        <section>

                            <div class="card">
                                <div class="card-body">

                                    <div class=" row col-md-12 col-sm-12 col-xs-12">
                                        <div class="col-md-6 col-sm-6 col-xs-6">
                                            <div class="form-group label-floating">
                                                <label class="form-label">Add Salary Head Name </label>

                                                <div>
                                                    <input type="text" name="Salary Head Name"
                                                        class="form-control col-md-12 col-xs-12" autocomplete="off"
                                                        placeholder="Enter Salary Head Name" autofocus="true"
                                                        [(ngModel)]="insertSalaryhead.salaryHeadName" required
                                                        #SALARYHEADNAME_m="ngModel"
                                                        [ngClass]="(!SALARYHEADNAME_m.valid && SALARYHEADNAME_m.touched) ? 'is-invalid' : ''" />
                                                </div>
                                                <span class="help-text text-danger"
                                                    *ngIf="(!SALARYHEADNAME_m.valid && SALARYHEADNAME_m.touched)">Enter
                                                    Salary Head Name</span>
                                            </div>
                                        </div>
                                        <div class="col-md-6 col-sm-12 col-xs-12 justify-content-between align-items-start">
                                            <div class="form-group label-floating">
                                                <label class="form-label">&nbsp;</label>
                                                <button *ngIf="addOrUpdate; else updateButton" type="button"
                                                    class="btn btn-primary w-40" id="navigatetarget"
                                                    style="margin-right: 3vw;margin-left: 2vw;" (click)="addSalaryHeadButton()">
                                                    Add
                                                </button>
                                                <ng-template #updateButton>
                                                    <button type="button" id="navigatetarget"
                                                        class="btn btn-primary w-40" style="margin-right: 3vw;margin-left: 3vw"
                                                        (click)="updateSalaryHead()">
                                                        Update
                                                    </button>
                                                </ng-template>
                                                <button type="button" class="btn btn-danger w-40"
                                                    (click)="viewSalaryHeadButton()">
                                                    View
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="clearfix"> </div>
                                </div>
                            </div>

                            <div class="empty-div-one" id="navigatedest"></div>

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="card" *ngIf="add_view_toggle == 'addSalaryHead' ">
                                        <div class="card-header border-bottom-0">
                                            <h3 class="card-title ">Salary Master Configuration </h3>
                                        </div>
                                        <div class="card-body">
                                            <div class="panel-group1" id="accordion1">
                                                <div class="panel panel-default mb-4 overflow-hidden br-7">
                                                    <div class="panel-heading1">
                                                        <h4 class="panel-title1">
                                                            <a class="accordion-toggle collapsed  "
                                                                data-bs-toggle="collapse" data-parent="#accordion"
                                                                href="#collapseFour" aria-expanded="false">Salary
                                                                Head Detail</a>
                                                        </h4>
                                                    </div>
                                                    <div id="collapseFour" class="panel-collapse collapse show"
                                                        role="tabpanel" aria-expanded="false">
                                                        <div class="panel-body m-0 p-0">
                                                            <div class="card-body">
                                                                <div class="row col-md-12 col-sm-12 col-xs-12">
                                                                    <div class=" row col-md-12 col-sm-12 col-xs-12">
                                                                        <div class="col-md-4 col-sm-4 col-xs-4">
                                                                            <div class="form-group label-floating">
                                                                                <label class="form-label">Salary Head
                                                                                    Name<span style="color: rgb(248, 111, 111);">
                                                                                        &nbsp;*</span></label>
                                                                                <div>
                                                                                    <input type="text"
                                                                                        name="SALARYHEADNAME"
                                                                                        class="form-control col-md-12 col-xs-12"
                                                                                        autocomplete="off" placeholder="Enter Salary Head
                                                                                        Name" autofocus="true"
                                                                                        [(ngModel)]="insertSalaryhead.salaryHeadName"
                                                                                        #SALARYHEADNAME_m2="ngModel"
                                                                                        [ngClass]="(!SALARYHEADNAME_m2.valid && SALARYHEADNAME_m2.pristine) ? 'is-invalid' : ''" />
                                                                                </div>
                                                                                <span class="help-text text-danger"
                                                                                    *ngIf="(!SALARYHEADNAME_m2.valid && SALARYHEADNAME_m2.touched)">Enter
                                                                                    Salary Head
                                                                                    Name</span>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-4 col-sm-4 col-xs-4">
                                                                            <div class="form-group label-floating">
                                                                                <label class="form-label">Add to /
                                                                                    Deduct from
                                                                                    Gross<span style="color: rgb(248, 111, 111);">
                                                                                        &nbsp;*</span></label>
                                                                                <div class="w-100">
                                                                                    <select
                                                                                        class="form-control col-md-12 col-xs-12"
                                                                                        style="height: 38px;"
                                                                                        data-style="btn btn-drpdwn btn-round"
                                                                                        [(ngModel)]="insertSalaryhead.addDeductIndic"
                                                                                        id="ADDDEDUCTINDIC"
                                                                                        name="ADDDEDUCTINDIC">
                                                                                        <option value="A">Add
                                                                                        </option>
                                                                                        <option value="D">Deduct
                                                                                        </option>
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-4 col-sm-4 col-xs-4">
                                                                            <div class="form-group label-floating">
                                                                                <label class="form-label">
                                                                                    Statuatory
                                                                                    Code</label>
                                                                                <div class="w-100">
                                                                                    <select
                                                                                        class="form-control col-md-12 col-xs-12"
                                                                                        style="height: 38px;"
                                                                                        data-style="btn btn-drpdwn btn-round"
                                                                                        [(ngModel)]="insertSalaryhead.statuatoryCode"
                                                                                        id="STATUATORYCODE"
                                                                                        name="STATUATORYCODE">
                                                                                        <option value="ESI">ESI
                                                                                        </option>
                                                                                        <option value="PF">PF
                                                                                        </option>
                                                                                        <option value="TDS">TDS
                                                                                        </option>
                                                                                        <option value="PT">PT
                                                                                        </option>
                                                                                        <option value="EFBF">EFBF
                                                                                        </option>
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="clearfix"> </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="addOrUpdate && add_view_toggle === 'addSalaryHead'" class="actionBar pe-4 ps-4">
                                <div class="clearfix"> </div>
                                <button *ngIf="addOrUpdate && add_view_toggle === 'addSalaryHead'"
                                    class="btn btn-success me-2 " type="submit" (click)="submit()">Save</button>
                                <button *ngIf="addOrUpdate && add_view_toggle === 'addSalaryHead'"
                                    class="btn btn-danger" type="button" (click)="clear()">Clear</button>
                                <!-- <button *ngIf="addOrUpdate && add_view_toggle === 'addSalaryHead'"
                                    class="btn btn-success me-2 " type="submit" (click)="submit()">Save</button> -->
                            </div>

                            <div class="card col-md-12 col-sm-12 col-xs-12 top_margin"
                                *ngIf="add_view_toggle == 'viewSalaryHead' ">
                                <div class="card-header border-bottom-0">
                                    <h3 class="card-title">List Of Salary Head</h3>
                                </div>
                                <div class="card-body card1S p-0">
                                    <div class="table-responsive">
                                        <table
                                            class="table table-hover table-vcenter text-nowrap table-bordered border-bottom"
                                            id="hr-table">
                                            <thead class="tablerow">
                                                <tr class="rowcolors stickyatTop">
                                                    <th class="border-bottom-0 w-5 fs-6 ">#</th>
                                                    <th class="border-bottom-0 w-5 fs-6 ">Salary Head</th>
                                                    <th class="border-bottom-0 w-5 fs-6 ">Add to / Deduct from Gross
                                                    </th>
                                                    <th class="border-bottom-0 w-5 fs-6 ">Statuatory Code</th>
                                                    <th class="border-bottom-0 w-5 fs-6 ">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody style="max-height: 50vh; overflow: scroll;">
                                                <tr *ngFor="let sh of salaryHead; let i = index">
                                                    <td style="text-align: right;">{{i+1}}</td>
                                                    <td>{{sh.salaryHeadName}}</td>
                                                    <td>{{sh.addDeductIndic=='A'?'Add to Gross':'Deduct from Gross'}}
                                                    </td>
                                                    <td>{{sh.statuatoryCode!=undefined ? sh.statuatoryCode : '--'}}</td>
                                                    <td>
                                                        <button class="btn btn-primary btn-icon btn-sm me-2"
                                                            data-method="edit" title="Edit"
                                                            (click)="editSalaryHead(sh)">
                                                            <i class="fa fa-edit" data-bs-toggle="tooltip"
                                                                data-original-title="edit"></i>
                                                        </button>
                                                        <button class="btn btn-danger btn-icon btn-sm me-2"
                                                        data-method="delete" title="Delete"
                                                        (click)="onDeleteHandler(sh)"
                                                        data-bs-target="#showAlertWarning">
                                                        <i class="fa fa-trash" data-bs-toggle="tooltip"
                                                            data-original-title="delete"></i>
                                                        </button>
                                                        
                                                    </td>
                                                </tr>
                                            </tbody>

                                        </table>
                                    </div>
                                </div>

                            </div>

                        </section>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<div id="showAlertVer" class="modal" style="top: 40px;">
    <div class="modal-dialog">
        <div class="modal-content animate">
            <div class="modal-header">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">×</button>
                <h4 class="modal-title">Warning</h4>
            </div>

            <div class="modal-body" style="max-height: calc(100vh - 210px);overflow-y: auto;">
                <!-- auto scroll -->
                <span><b> Salary  Head Master data will be deleted permanently. Are you sure you want to continue?</b></span>
            </div>

            <div class="modal-footer" >
                <button type="button" class="btn btn-danger medium" (click)="deleteSalaryHead()">OK</button>
                <button type="button" class="btn btn-primary" (click)="closeMRQModal()">Cancel</button>
            </div>

        </div>
    </div>
</div>