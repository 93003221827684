import { Injectable } from '@angular/core';
import { EmployeeLeaveService } from './employee-leave.service';
import { EmployeeMaster } from '../models/employeeMasterts';
import { map } from 'rxjs';
import { of, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LuceneSearchService {
  statusValue: string = 'All';

  constructor(private empLeaveService: EmployeeLeaveService) { }

  

  getEmployeeByNameOrId(queryName, employeeid, activeInativeLuceneFilter, query, employeeMasterLucene: EmployeeMaster[], leavingdate,  selectedempid): Observable<any> {
    const [name, id] = query.split(' / ');
    queryName = name;
    employeeid = id; // Store the ID if it's available
    console.log("query", queryName);
  
    if (queryName.length <= 2) {
      employeeMasterLucene = [];
      return of([]); // Return an Observable with an empty array
    }
  
    return this.empLeaveService.getEmployeeByNameOrUniqueId(queryName).pipe(
      map((data) => {
        console.log("Emp Data ", data);
  
        // Ensure we have employee data
        if (data.employeeMasterData.length === 0) {
          console.log("No employee data found.");
          return []; // Return an empty array
        }
  
        // Filter the employees based on selected value (Active, Inactive, All)
        let filteredEmployees = data.employeeMasterData;
  
        // Get the current date for comparison
        const currentDate = new Date();
        console.log("Current Date: ", currentDate);
  
        if (activeInativeLuceneFilter !== 'All') {
          filteredEmployees = filteredEmployees.filter(emp => {
            const leavingDate = emp.leavingdateString ? new Date(emp.leavingdateString) : null;
            console.log("Leaving Date for " + emp.name + ": ", leavingDate);
  
            if (activeInativeLuceneFilter === 'Active') {
              return !leavingDate || leavingDate > currentDate;
            } else if (activeInativeLuceneFilter === 'Inactive') {
              return leavingDate && leavingDate < currentDate;
            }
            return true; // Default case: return the employee if no specific filter matches
          });
        }
  
        // Assign filtered data to employeeMasterLucene
        employeeMasterLucene = filteredEmployees;
  
        // If there are any employees left after filtering
        if (employeeMasterLucene.length > 0) {
          leavingdate = employeeMasterLucene[0].leavingdateString; // Extract the leaving date from the first employee
          console.log("Filtered Employee Data: ", employeeMasterLucene);
          console.log("leavingdate", leavingdate);
        } else {
          console.log("No employees match the filter.");
          return []; // Return empty if no employees match the filter
        }
  
        // Now, find the employee with the matching employeeid
        const selectedEmployee = filteredEmployees.find(emp => emp.employeeid === employeeid);
  
        // Set the selectedempid to the unique employee ID of the matched employee
        if (selectedEmployee) {
          selectedempid = selectedEmployee.uniqueemployeeid;
        }

        const response = {};
        response['filteredEmployees'] = filteredEmployees;
        response['selectedempid'] = selectedempid;
        return response; // Return the filtered employees or manipulate as needed
      })
    );
  }
  
}
