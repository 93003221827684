import { Component, ElementRef, HostListener, OnInit, Renderer2 } from '@angular/core';
import { EmployeeLeaveApplied } from '../models/employee-leave-applied.model';
import { EmployeeMaster } from '../models/employeeMasterts';
import { EmployeeLeaveService } from '../services/employee-leave.service';
import { ActivatedRoute } from '@angular/router';
import { EmployeeMasterservice } from '../services/employeemaster.service';
import { Menu } from '../services/menu.Service';
import { LuceneSearchService } from '../services/lucene-search.service';

declare var $: any;

@Component({
	selector: 'app-leave-status-report',
	templateUrl: './leave-status-report.component.html',
	styleUrls: ['./leave-status-report.component.css']
})
export class LeaveStatusReportComponent implements OnInit {
	showTotalLeaveReportSection: boolean = false;
	showTotalLeaveReport = false;
	casualLeave: string = '';
	orderBy: string = 'employeeName';
	query: any = "";
	queryName: any;
	employeeid: String;
	fromDate: string;
	toDate: string;
	leaveStatus: string;
	limitPara: any;
	offsetPara: any;
	title: string;
	selectedempid: any;
	leaveTypesList = [
		{ leaveType: "", leaveName: "" }
	]
	leaveTypeMap = new Map();
	employeesLeaveSummary: EmployeeLeaveApplied[];
	employeeMaster: EmployeeMaster[];
	statusValue: string = 'All';
	types: any;
	totalAbsence: number;
	selectedStatus: string = 'All';
	existingquery: string;
	colorCodeMap: Map<string, string> = new Map();
	uniqueemployeeid: String;
	employeeMasterLucene: EmployeeMaster[];
	ActiveInativeLuceneFilter: string = 'Active';
	leavingdate: any;
	permissionList: any[] = [];
	showBasedOnPermissionEmployeeStatus: boolean = false;
	allowToApprove: boolean = false;
	selectedValue = 'Detailed'; // Default view
	constructor(private empLeaveService: EmployeeLeaveService, private route: ActivatedRoute, private render: Renderer2, private empLuceneSearch: LuceneSearchService,
		private employeeMasterservice: EmployeeMasterservice, private menuService: Menu) {
		const currentDate = new Date();
		const year = currentDate.getFullYear();
		const month = currentDate.getMonth() + 1;
		this.fromDate = `${year}-${month.toString().padStart(2, '0')}-01`;
		const lastDay = new Date(year, month, 0).getDate();
		this.toDate = `${year}-${month.toString().padStart(2, '0')}-${lastDay.toString().padStart(2, '0')}`;
		this.title = "Leave Summary";




	}


	onOrderByChange() {
		this.sortTableData();
	}
	sortTableData() {
		// Assuming employeesLeaveSummary is the array you want to sort

		this.employeesLeaveSummary.sort((a, b) => {
			if (this.orderBy === 'employeeId') {
				return a.employeeId.localeCompare(b.employeeId);
			} else if (this.orderBy === 'employeeName') {
				return a.employeeName.localeCompare(b.employeeName);
			} else if (this.orderBy === 'leaveDate') {
				return new Date(a.leaveDate).getTime() - new Date(b.leaveDate).getTime();
			}
			// Default case if orderBy is not recognized
			return 0;
		});
	}
	leaveTypeCounts: { leaveName: string, count: number }[] = [];
	leaveTypes: Map<string, number> = new Map<string, number>();
	leavesummary(leavetypelist) {
		for (const type of leavetypelist) {
			console.log(leavetypelist);
			if (type.leaveStatus === 'Approved') {
				// Check if the leave type is already in the map
				if (this.leaveTypeMap.has(type.leaveName)) {
					// If the leave type is already in the map, check if it's a half-day leave
					if (type.halfFullIndic === 'H') {
						// If it's a half-day leave, add 0.5 to the existing count
						this.leaveTypeMap.set(type.leaveName, this.leaveTypeMap.get(type.leaveName) + 0.5);
					} else {
						// If it's a full-day leave, add 1 to the existing count
						this.leaveTypeMap.set(type.leaveName, this.leaveTypeMap.get(type.leaveName) + 1);
					}
				} else {
					// If the leave type is not in the map, add it with count 0.5 for half-day and 1 for full-day
					this.leaveTypeMap.set(type.leaveName, type.halfFullIndic === 'H' ? 0.5 : 1);
				}
			}
		}
		console.log(this.leaveTypeMap, "leave summary");
		this.leaveTypeCounts = Array.from(this.leaveTypeMap.entries())
			.map(([leaveName, count]) => ({ leaveName, count }));
		this.totalAbsence = this.leaveTypeCounts.reduce((total, leaveTypeCount) => total + leaveTypeCount.count, 0);
	}
	getLeaveType() {
		this.employeeMasterservice.getLeaveType().subscribe(
			data => {
				this.types = data;
				this.leaveTypeMap.clear();
				for (let type of this.types) {
					this.leaveTypeMap.set(type.leaveName, 0);
					this.colorCodeMap.set(type.leaveName, type.colorcode);
				}
				console.log("List of all the leave type available  :", this.leaveTypeMap)
			}, error => {
				console.log(error);
			}
		);
	}
	ngOnInit(): void {
		const maxString = sessionStorage.getItem('permission').replace('[', '');
		const maxStringBox = maxString.replace(']', '');
		const maxStringAgain = maxStringBox.replace(/"/g, '');
		this.permissionList = maxStringAgain.split(',');
		this.checkAccessForUser();

		this.route.queryParams.subscribe(params => {
			// Check for 'leaveStatus' query parameter
			if (params['leaveStatus']) {
				this.leaveStatus = params['leaveStatus'];
				this.selectedStatus = params['leaveStatus'];
				// Set date range to current date to one week later
				const currentDate = new Date();
				const nextWeekDate = new Date();
				nextWeekDate.setDate(currentDate.getDate() + 7);

				this.fromDate = currentDate.toISOString().split('T')[0];
				this.toDate = nextWeekDate.toISOString().split('T')[0];
				this.title = "Leave Status Report - Weekly";
			} else {
				// Default to monthly range if 'leaveStatus' is not provided
				const currentDate = new Date();
				const year = currentDate.getFullYear();
				const month = currentDate.getMonth() + 1;

				this.fromDate = `${year}-${month.toString().padStart(2, '0')}-01`;
				const lastDay = new Date(year, month, 0).getDate();
				this.toDate = `${year}-${month.toString().padStart(2, '0')}-${lastDay.toString().padStart(2, '0')}`;

				this.title = "Leave Status Report - Monthly";
			}


		});

		this.getLeaveType();

		this.employeesLeaveSummary = new Array<EmployeeLeaveApplied>();
		this.getLeaves();
		this.limitPara = 13;

		this.offsetPara = 0;
		this.sortTableData();
		(<HTMLInputElement>document.getElementById('employeename')).focus();


		this.route.queryParams.subscribe(params => {
			// this.fromDate = params.fromDate;
			// this.toDate = params.toDate;

			this.leaveStatus = params.leaveStatus;
			if (this.leaveStatus && this.leaveStatus.trim().length !== 0) {
				this.title = "Team member on leave"
				this.onLeaveStatusChange(this.leaveStatus)
				this.searchEmployeeLeave()
			}
		});
		console.log("parameter passed from dashboard to leave status");

		this.render.listen(document.getElementById('navigateclient'), 'click', () => {
			$('html,body').animate({
				scrollTop: $(`#add-update-client`).offset().top - 90
			},
				'slow');
		})

	}


	getLeaves() {
		this.empLeaveService.getAllLeaves().subscribe(
			data => {
				this.leaveTypesList = data.leaveMasters
				// this.setLeaveMap();
				console.log("Data Leaves ", this.leaveTypesList);
			},
			error => {
				console.log("Error ", error);
			}
		)
	}

	setLeaveMap() {
		this.leaveTypesList.forEach(type => {
			this.leaveTypeMap.set(type.leaveType, type.leaveName)
		})
	}

	getLeaveName(leaveType: any) {
		return this.leaveTypeMap.get(leaveType)
	}

	searchEmployeeLeave() {
		if (this.query.trim().length === 0) {
			this.resettypeMap();
		} else if (this.existingquery !== this.query) {
			this.resettypeMap();
		}

		console.log("fromDate: ", this.fromDate, "toDate: ", this.toDate, "page limit para :", this.limitPara, "page limit offset :", this.offsetPara);
		console.log(this.statusValue);
		//	this.title="Leave Summary";
		let employeename = (<HTMLInputElement>document.getElementById('employeename')).value;

		if (!employeename) {
			employeename = null;
		} else {
			employeename = employeename.trim();

			// Decode the URL-encoded string
			employeename = decodeURIComponent(employeename);

			// Extract the part before the first slash
			const namePart = employeename.split('/')[0];

			// Update the employeename
			employeename = namePart.trim();
		}

		console.log("Employee Name is", employeename);
		this.empLeaveService.getEmployeesLeaveAppliedSummary(employeename, this.fromDate, this.toDate,this.ActiveInativeLuceneFilter, this.statusValue, this.selectedValue, this.limitPara, this.offsetPara).subscribe(
			data => {


				console.log("Backend Leave Data ", data);
				this.scrollLength()
				// if(this.query.trim().length!==0){
				// 	console.log("called")
				// 	if(!this.existingquery){
				// 		this.leavesummary(data.employeeLeaveAppliedInfo);

				// 	}else if (this.existingquery !== employeename ){
				// 		this.leavesummary(data.employeeLeaveAppliedInfo);
				// 	}
				// }
				if (this.offsetPara == 0) {
					if (this.query.trim().length !== 0) {
						this.resettypeMap();
						this.leavesummary(data.employeeLeaveAppliedInfo);
					}
					this.employeesLeaveSummary = data.employeeLeaveAppliedInfo;


				} else {
					if (this.query.trim().length !== 0) {
						this.leavesummary(data.employeeLeaveAppliedInfo);
					}
					this.employeesLeaveSummary = this.employeesLeaveSummary.concat(data.employeeLeaveAppliedInfo);
				}

				console.log("Employee Leave Summary ", this.employeesLeaveSummary);
				this.sortTableData();
				this.showTotalLeaveReport = this.query.trim() !== '';
				this.existingquery = employeename;
			},
			error => {
				console.log("Error ", error);
				(<HTMLInputElement>document.getElementById('employeename')).value = "";
			}
		)
	}

	scrollLength() {
		console.log("dynamic height on scroll ");
		console.log("");
		const regex = /^(\d+(?:\.\d+)?)\s*([a-zA-Z%]+)$/;
		const height = getComputedStyle(document.getElementById("scrolllength")).getPropertyValue("max-height")
		const computedHeight = height.match(regex);
		console.log("computed height", computedHeight, "computed height in no ", Number(computedHeight[1]), "height ", height)
		if (computedHeight && Number(computedHeight[1]) < 450) {
			let value: any = Number(computedHeight[1]);
			console.log("default value = ", value)
			value = value + (2 * 55);
			value = value + "px";
			console.log("final vl:", value)
			console.log("transition")
			document.getElementById("scrolllength").style.maxHeight = value
		}
		console.log("");
	}

	onLeaveStatusChange(selectedValue: string) {
		this.statusValue = selectedValue;
		console.log("Value", this.statusValue)
	}


	getEmployeeByNameOrId() {
		this.empLuceneSearch.getEmployeeByNameOrId(this.queryName, this.employeeid, this.ActiveInativeLuceneFilter, this.query, this.employeeMasterLucene, this.leavingdate, this.selectedempid).subscribe(res => {
			console.warn(res)
			this.employeeMasterLucene = res.filteredEmployees;
			this.selectedempid = res.selectedempid;
		});
	}

	onStatusChange() {
		this.getEmployeeByNameOrId();
	}


	onScroll() {
		this.offsetPara = this.offsetPara + 13;
		console.log("limit = ? ", this.limitPara, "offset = +13 ", this.offsetPara)
		this.searchEmployeeLeave();

	}

	limitpara() {
		this.offsetPara = 0;
		console.log(" on clikc limit para ", this.limitPara)
		console.log("this offset", this.offsetPara)
	}

	@HostListener('scroll', ['$event'])
	scrolled() {

		console.log("Scrolled >>> ")
		this.onScroll();
	}


	resettypeMap() {
		this.leaveTypeMap.forEach((val, key) => {
			console.log(val, key);
			this.leaveTypeMap.set(key, 0);
			//val  =0 ;
			// val = 0;
		})

	}

	clear() {
		// this.fromDate='';
		// this.toDate='';
		// this.selectedStatus='';
		// this.query='';
		// this.orderBy='';
		this.query = '';
		const currentDate = new Date();
		const year = currentDate.getFullYear();
		const month = currentDate.getMonth() + 1;
		this.fromDate = `${year}-${month.toString().padStart(2, '0')}-01`;
		const lastDay = new Date(year, month, 0).getDate();
		this.toDate = `${year}-${month.toString().padStart(2, '0')}-${lastDay.toString().padStart(2, '0')}`;

	}

	checkAccessForUser() {
		console.log("checkAccessForUser here")
		this.showBasedOnPermissionEmployeeStatus = this.permissionList.includes('ng.employee.search.show.employeestatus');
		this.allowToApprove = this.permissionList.includes('ng.screen.attendance.approval');

	}

	viewdocument(filename: string, path: string) {
		if (path === undefined) {
			path = filename;
		}
		if (path === undefined && filename === undefined) {
			this.alertmessages("No Attachment Found", 'danger');
		}
		console.log(" ")
		console.log("view document : ", path)
		this.empLeaveService.viewdocument(path).subscribe(
			(response) => {
				console.log(`Our response : ${response}`)
				if (filename.includes('.pdf')) {
					var file = new Blob([response], { type: 'application/pdf' });
					var fileURL = URL.createObjectURL(file);
					window.open(fileURL);
				} else if (filename.includes('.jpg') || filename.includes('.jpeg')) {
					var file = new Blob([response], { type: 'image/jpeg' });
					var fileURL = URL.createObjectURL(file);
					window.open(fileURL);
				} else if (filename.toLowerCase().includes('.png')) {
					var file = new Blob([response], { type: 'image/png' });
					var fileURL = URL.createObjectURL(file);
					window.open(fileURL);
				} else {
					console.log("Other types are not recommended!");
				}
			},
			(error) => { },
		)

	}
	onTypeChange(event: Event) {
		const selectedValue = (event.target as HTMLSelectElement).value;
		this.selectedValue = selectedValue;
		console.log("selected type: ", this.selectedValue);
		this.employeesLeaveSummary = null;
		// this.searchEmployeeLeave();
	}

	setMenuName(name: string) {
		this.menuService.setMenuName(name);
	}

	alertmessages(message: string, sign: string): void {
		let alertDiv = document.createElement('div');
		alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
		<i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message}</div>`
		let alertcomp = document.querySelector('.alertcomp');
		alertcomp.append(alertDiv);
		document.body.scrollTop = document.documentElement.scrollTop = 0;

		setTimeout(function () {
			alertcomp.removeChild(alertDiv);
		}, 5000);
	}

}

