export class EmployeeLeaveInfo {
  constructor(
    public uniqueEmployeeId: number,
    public employeeLeaveAppliedId:number,
    public fromDate: string,
    public toDate: string,
    public appliedDate: string,
    public leaveType: string,
    public halfFullIndic: string,
    public reason: string,
    public fileToUpload: File | null = null,
    public filename: any| null= null

  ) { }
}
