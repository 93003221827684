import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})

export class OvertimeReportService{

    
  protected basePath = '/api';
  public defaultHeaders: Headers = new Headers();
  public url!: string;
  public colorList = ['#33c5ff', '#f7284a', '#01c353', '#fe7700', '#c3c001', '#33e0ff', '#6574cd', '#ef4eb8', '#ecb403', '#17a2b8'];
  public empLeave: string[] = []
  constructor(private httpclient: HttpClient, private commonService: CommonService) { }

  public getovertimeRepot(fromDate: any,toDate: any,uempid: number,employeename:any,empStatus:any,departmentid:any,limitPara: any, offsetPara: any): Observable<any> {//,limitPara: any, offsetPara: any
    console.log("employee id or name employeename" +employeename);
    this.url = '/v1/getOvertimeReport';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    console.log("afteremployee id or name employeename" +employeename);
        queryParameters = queryParameters.set('fromDate', fromDate);
        queryParameters = queryParameters.set('toDate', toDate);
        queryParameters = queryParameters.set('uempid', uempid);
        if (employeename !== null) {
          queryParameters = queryParameters.set('employeename', employeename);
        }
          
          queryParameters = queryParameters.set('empStatus', empStatus);
        queryParameters =queryParameters.set('departmentid',departmentid);
        queryParameters = queryParameters.set('limitPara', limitPara);
        queryParameters = queryParameters.set('offsetPara', offsetPara);
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);

  }

  public downloadOTReport(fromDate: any,toDate: any,uempid: number,employeename:any,empStatus:any,departmentid:any,limitPara: any, offsetPara: any): Observable<any> {
    this.url = '/v1/downloadOTReport';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
        queryParameters = queryParameters.set('fromDate', fromDate);
        queryParameters = queryParameters.set('toDate', toDate);
        queryParameters = queryParameters.set('uempid', uempid);
        if (employeename !== null) {
          queryParameters = queryParameters.set('employeename', employeename);
        }
        queryParameters = queryParameters.set('empStatus', empStatus);
        queryParameters =queryParameters.set('departmentid',departmentid);
        queryParameters = queryParameters.set('limitPara', limitPara);
        queryParameters = queryParameters.set('offsetPara', offsetPara);
    return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path, { responseType: 'arraybuffer' });
}

}