<div class="app-content main-content">
    <div class="side-app main-container" style="margin-top:-20px">
        <div class="alertcomp"></div>
        <div class="w-100" style="padding: 0;">
            <div class="card-body d-flex justify-content-start align-items-center" style="margin-top: 10px; gap: 10px;">

                <div class="col-auto">
                    <button class="btn btn-primary px-3" (click)="addNewData()" data-bs-target="#addnoticemodals"
                        data-bs-toggle="modal" aria-label="Add New Notice">
                        Add New Notice
                    </button>
                </div>

            </div>
            <!-- <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body px-5">
                    <form class="form-horizontal d-flex flex-wrap align-items-center" id="fcForm">
                        
                
                        <div class="form-group col-md-4 col-sm-4 col-xs-4 px-2">
                             <label class="form-label">File Type</label>
                        
                            <div>
                                <select id="filetypes" class="form-control"   [(ngModel)]="selectedType">
                                    <option value="" disabled selected>Select a File Type</option>
                                     <option *ngFor="let file of filetypes" [value]="file">{{ file }}</option>
                                 </select>
                            </div>
                        </div>

                        <div class="form-group col-md-2 col-sm-2 px-2">
                            <label class="form-label">&nbsp;</label>
                         
                            <button type="button" class="btn btn-primary w-100" (click)="downloadnOticeboard()">Download
                                     </button>
                           
                        </div>

                        <div class="form-group col-md-2 col-sm-2 px-2">
                            <label class="form-label">&nbsp;</label> 
                            <button 
                                class="btn btn-primary px-3" 
                                (click)="addNewData()" 
                                data-bs-target="#addnoticemodals" 
                                data-bs-toggle="modal" 
                                aria-label="Add New Notice">
                                Add New Notice
                            </button>
                        </div>

                        <div class="clearfix"> </div>

                    </form>
                </div>
            </div>
        </div>
    </div> -->



        </div>
        <div class="row">
            <div class="col-xl-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title ">Notice Summary </h4>
                        <div class="download-container">
                            <button type="button" class="btn btn-primary ms-auto" (click)="toggleSelect()">
                                <i class="fa fa-download" data-bs-toggle="tooltip" data-original-title="download"></i>
                            </button>

                            <!-- Popup for file type selection -->
                            <div *ngIf="showSelect" class="popup">
                                <select id="filetypes" class="form-control" (change)="onTypeChange($event)">
                                    <option value="Select" disabled selected>Select</option>
                                    <option *ngFor="let file of filetypes" [value]="file">{{ file }}</option>
                                </select>

                            </div>
                        </div>
                    </div>
                    <div class="card-body ">
                        <div class="scrollsearchresults" id="scroll" infiniteScroll [infiniteScrollDistance]="2"
                            [infiniteScrollUpDistance]="1.5" [infiniteScrollThrottle]="100" [scrollWindow]="false"
                            (scrolled)="onScroll()" id="scrolllength">
                            <div class="table-responsive">
                                <table class="table table-hover  table-vcenter text-nowrap table-bordered border-bottom"
                                    id="hr-notice">
                                    <thead class="tablerow">
                                        <tr class=" stickyatTop rowcolors">
                                            <th class="border-bottom-0 w-5" scope="col">#</th>
                                            <th class="border-bottom-0" scope="col">Title</th>
                                            <th class="border-bottom-0" scope="col">Description</th>
                                            <th class="border-bottom-0" scope="col">From date</th>
                                            <th class="border-bottom-0" scope="col">To date</th>
                                            <!-- <th class="border-bottom-0">To</th> -->
                                            <th class="border-bottom-0" scope="col">Created On</th>
                                            <th class="border-bottom-0" scope="col">Action</th>

                                            <!-- <th class="border-bottom-0">Status</th> -->
                                            <!-- <th class="border-bottom-0">Action</th> -->
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let notice of renderNoticeList;let i=index">
                                            <td style="text-align: right;" data-label="Sl No." [scope]="i!==0 ? '': 'row'">{{i+1}}</td>
                                            <td class="text-wrap" style="min-width:10vw;max-width:14vw;"
                                                data-label="Title">{{notice.title}}</td>
                                            <td class="text-wrap" style="min-width:10vw;max-width: 20vw;"
                                                data-label="Description">{{ notice.description }}</td>
                                            <td data-label="From date">{{notice.fromdate | date }}
                                            </td>
                                            <td data-label="To date">{{notice.todate | date}}</td>
                                            <td data-label="Created On">{{notice.createdtime | date }}</td>

                                            <td>
                                                <button class="btn btn-primary btn-icon btn-sm me-2"
                                                    (click)="noticeboardEdit(notice)" data-method="edit" title="Edit">
                                                    <i class="fa fa-edit" data-bs-toggle="tooltip"
                                                        data-original-title="edit"></i>
                                                </button>


                                                <button class="btn btn-danger btn-icon btn-sm me-2"
                                                    (click)="noticeboardDelete(notice.id)" data-method="delete"
                                                    title="Delete">
                                                    <i class="fa fa-trash" data-bs-toggle="tooltip"
                                                        data-original-title="edit"></i>
                                                </button>
                                            </td>
                                        </tr>



                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>


</div>


<div id="showAlert" class="modal" style="top: 40px;">
    <div class="modal-dialog">
        <div class="modal-content animate">
            <div class="modal-header">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                    (click)="close()">×</button>
                <h4 class="modal-title">Warning</h4>
            </div>

            <div class="modal-body" style="max-height: calc(100vh - 210px);overflow-y: auto;">
                <!-- auto scroll -->
                <span><b> Do You Want to Delete This Notice ?</b></span>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="close()">Cancel</button>
                <button type="button" class="btn btn-danger medium" (click)="noticeDelete()">Yes</button>

            </div>
        </div>
    </div>
</div>





<div class="modal fade" id="addnoticemodals">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Add New Notice</h5>
                <button class="btn-close" (click)="closemodal($event)" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <form class="form-horizontal" (ngSubmit)="form.form.valid && saveNoticeInfo(form.form.valid)"
                    #form="ngForm">
                    <div class="alertcomp"></div>
                    <div class="form-group">
                        <label class="form-label">Title</label>
                        <input class="form-control" placeholder="Enter Title" id="title" name="title"
                            [(ngModel)]="savenoticeinfo.title" #title="ngModel" required>
                        <p *ngIf="submitted && !title.valid" class="help-block " style="color: #ff5757;">
                            ⓘ Title is required
                        </p>
                    </div>

                    <div class="form-group ">
                        <label class="form-label" for="dsasf32">From Date:</label>
                        <input type="datetime-local" class=" form-control" id="fromdate" name="fromdate"
                            [(ngModel)]="savenoticeinfo.fromdate" #fromdate="ngModel" required
                            (ngModelChange)="from_date_change_listner($event , fromdate)">
                        <p *ngIf="submitted && !fromdate.valid" class="help-block " style="color: #ff5757;">
                            ⓘ From Date is required
                        </p>
                        <!-- <p class="help-block" style="color: tomato;">ⓘ From date is not Valid</p> -->
                    </div>

                    <div class="form-group ">
                        <label class="form-label" for="dsasf13">To Date:</label>
                        <input type="datetime-local" class=" form-control" id="todate" name="todate"
                            [(ngModel)]="savenoticeinfo.todate" #todate="ngModel" required
                            (ngModelChange)="to_date_change_listner($event)">
                        <p *ngIf="submitted && !todate.valid" class="help-block " style="color: #ff5757;">
                            ⓘ To Date is required
                        </p>
                    </div>
                    <div class="form-group">
                        <label class="form-label" for="description">Description:</label>
                        <textarea name="text" class="form-control" id="description" name="description"
                            [(ngModel)]="savenoticeinfo.description" #description="ngModel" required></textarea>
                        <p *ngIf="submitted && !description.valid" class="help-block " style="color: #ff5757;">
                            ⓘ Description is required
                        </p>
                    </div>
                </form>
                <!-- <div class="form-group">
                    <label class="form-label">Attachment:</label>
                    <div class="form-group">
                    <label for="form-label" class="form-label"></label>
                        <input class="form-control" type="file">
                    </div>
                </div> -->

            </div>
            <div class="modal-footer">
                <button class="btn btn-success" *ngIf="!isEditing"
                    (click)="saveNoticeInfo(form.form.valid)">Save</button>
                <button class="btn btn-success" *ngIf="isEditing"
                    (click)="updateNoticeInfo(form.form.valid)">Update</button>
                <button class="btn btn-secondary" (click)="closemodal($event)">Close</button>
            </div>

        </div>
    </div>
</div>



<!-- <div class="modal fade" id="downloadnotice" tabindex="-1" role="dialog" aria-labelledby="downloadModal" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Download Noticeboard Report</h5>
                <button class="btn-close" (click)="closenotice($event)" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <form #form="ngForm" (ngSubmit)="onSubmit()">
                    <div class="form-group">
                        <label for="filetypes" class="form-label">File Type</label>
                        <select id="filetypes" class="form-control" [(ngModel)]="selectedType" name="fileType" required>
                            
                            <option *ngFor="let file of filetypes" [value]="file">{{ file }}</option>
                        </select>
                        <p *ngIf="submitted && (!selectedType || selectedType.trim() === '')" class="help-block" style="color: #ff5757;">
                            ⓘ File type is required
                        </p>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="downloadnOticeboard()">Download</button>
                <button type="button" class="btn btn-secondary" (click)="closenotice($event)">Close</button>
            </div>
        </div>
    </div>
</div> -->





<!-- change to update save  -->

<!-- <div class="modal fade" id="editnoticemodals">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Edit Notice</h5>
                <button class="btn-close" (click)="closemodale($event)" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <form class="form-horizontal" (ngSubmit)="form.form.valid && updateNoticeInfo(form.form.valid)"
                    #form="ngForm">
                    <div class="alertcomp"></div>
                    <div class="form-group">
                        <label class="form-label">Title</label>
                        <input class="form-control" placeholder="Enter Title" id="title" name="title"
                            [(ngModel)]="updatenoticeinfo.title" #title="ngModel" required>
                        <p *ngIf="submitted && !title.valid" class="help-block " style="color: #ff5757;">
                            ⓘ Title is required
                        </p>
                    </div>

                    <div class="form-group ">
                        <label class="form-label" for="dsasf32">From Date:</label>
                        <input type="datetime-local" class=" form-control" id="fromdate" name="fromdate"
                            [(ngModel)]="updatenoticeinfo.fromdate" #fromdate="ngModel" required
                            (ngModelChange)="from_date_change_listner($event , fromdate)">
                        <p *ngIf="submitted && !fromdate.valid" class="help-block " style="color: #ff5757;">
                            ⓘ From Date is required
                        </p>
                    </div>

                    <div class="form-group ">
                        <label class="form-label" for="dsasf13">To Date:</label>
                        <input type="datetime-local" class=" form-control" id="todate" name="todate"
                            [(ngModel)]="updatenoticeinfo.todate" #todate="ngModel" required
                            (ngModelChange)="to_date_change_listner($event)">
                        <p *ngIf="submitted && !todate.valid" class="help-block " style="color: #ff5757;">
                            ⓘ To Date is required
                        </p>
                    </div>
                    <div class="form-group">
                        <label class="form-label" for="description">Description:</label>
                        <textarea name="text" class="form-control" id="description" name="description"
                            [(ngModel)]="updatenoticeinfo.description" #description="ngModel" required></textarea>
                        <p *ngIf="submitted && !description.valid" class="help-block " style="color: #ff5757;">
                            ⓘ Description is required
                        </p>
                    </div>
                </form>

            </div>
            <div class="modal-footer">
                <button class="btn btn-outline-primary" (click)="closemodale($event)">Close</button>
                <button class="btn btn-success" (click)="updateNoticeInfo(form.form.valid )">Update</button>
             </div>
        </div>
    </div>
</div> -->