import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';
import { dailyTasksInfo } from '../models/dailyTasks';
import { CommonService } from './common.service';


@Injectable({ providedIn: 'root' })
export class DashboardService {
    public url;
    protected basePath = '/api';


    constructor(
        private httpnew: HttpClient, private _router: Router, private commonService: CommonService) { }


    private extendObj<T1, T2>(objA: any, objB: any) {
        for (const key in objB) {
            if (objB.hasOwnProperty(key)) {
                objA[key] = objB[key];
            }
        }
        return <T1 & T2>objA;
    }

    public getListOfTasks(userId): Observable<any> {
        let queryParams = new HttpParams();
        queryParams = queryParams.set('userId', userId);

        this.url = '/v1/getTasklist';

        return this.getByQueryParamWithHttpInfo(queryParams);
    }

    public addTask(dailyTasks: dailyTasksInfo, userId): Observable<any> {
        this.url = '/v1/saveTask';
        let queryParameters = new HttpParams();
        queryParameters = queryParameters.set('userId', userId);
        return this.postWithHttpInfo(dailyTasks, queryParameters);

    }

    public deleteTask(taskId: any): Observable<any> {
        this.url = '/v1/deleteTask';
        let queryParameters = new HttpParams();
        queryParameters = queryParameters.set('taskId', taskId);
        return this.deleteQueryParamWithHttpInfo(queryParameters);
    }

    // public getLeaveForApproval(): Observable<any> {
    //     this.url = '/v1/getleaveforapprovalview';
    //     const path = this.basePath + this.url;
    //     let queryParameters = new HttpParams();
    //     return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
    // }
    public getLeaveForApproval(): Observable<any> {
        const type = 'Detailed';
        const empStatus = 'Active';
        this.url = '/v1/getleave-forapproval';
        const path = this.basePath + this.url;
        let queryParameters = new HttpParams();
        queryParameters = queryParameters.set('type', type);
        queryParameters = queryParameters.set('empStatus', empStatus);
        return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
    }

    public getLeaveForApprovalToday(): Observable<any> {
        this.url = '/v1/leaveoncurrentdate';
        const path = this.basePath + this.url;
        let queryParameters = new HttpParams();
        return this.commonService.getByQueryParamWithHttpInfo(queryParameters, path);
    }

    public getByQueryParamWithHttpInfo(queryParameters: HttpParams, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + this.url;

        let requestOptions: any = {
            params: queryParameters
        };

        if (extraHttpRequestParams) {
            requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
        }

        return this.httpnew.get(path, requestOptions);

    }

    public postWithHttpInfo(param: any, queryParameters: HttpParams, extraHttpRequestParams?: any): Observable<any> {

        const path = this.basePath + this.url;

        // verify required parameter 'param' is not null or undefined
        if (param === null || param === undefined) {
            throw new Error('Required parameter body was null or undefined when calling tevicoPost.');
        }

        let requestOptions: any = {
            params: queryParameters
        };

        if (extraHttpRequestParams) {
            requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
        }

        return this.httpnew.post<any>(path, param, requestOptions);
    }

    public deleteQueryParamWithHttpInfo(queryParameters: HttpParams, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + this.url;

        let requestOptions: any = {
            params: queryParameters
        };

        if (extraHttpRequestParams) {
            requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
        }

        return this.httpnew.request("delete", path, requestOptions);

    }

}