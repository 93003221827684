<div class="app-content main-content">
    <div class="side-app main-container">
        <!-- <div class="page-header d-xl-flex d-block">
            <div class="page-leftheader">
                <div class="page-titleMod">Form T Report</div>
            </div>
        </div> -->
        <div class="row">
            <div class="col-xl-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <form id="fcForm" class="form-horizontal form-label-left">
                            <div class="x_content row px-4">

                                <div class="col-md-4 col-sm-4 col-xs-4">
                                    <div class="form-group label-floating">
                                        <label class="form-label"> From Date: </label>
                                        <div>
                                            <input class="form-control" type="date" name="fromdate" id="fromdate"
                                                [(ngModel)]="fromDate" (change)="onGenerateChange($event.target.value)">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-4 col-xs-4">
                                    <div class="form-group label-floating">
                                        <label class="form-label"> To Date: </label>
                                        <div>
                                            <input class="form-control" type="date" name="todate" id="todate"
                                                [(ngModel)]="toDate" (change)="onGenerateChange($event.target.value)">
                                            <p *ngIf="endDate" class="help-block " style="color: #ff5757;">
                                                ⓘ To date should be greater than From date
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-4 col-xs-4">
                                    <label class="form-label w-100 mb-1">Branch</label>
                                    <div class="form-group m-0">
                                        <div>
                                            <select id="deptInput" class="form-control" name="branch"
                                                placeholder="Select Branch" [(ngModel)]="branch" autocomplete="off"
                                                autofocus="autofocus">

                                                <option value="all" selected="selected">All</option>
                                                <option value={{data.branchId}} *ngFor="let data of branchInfo">
                                                    {{data.branchname}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <form class="form-horizontal" id="fcForm"
                                    (keyup.enter)="limitpara(); searchEmployeeformT()">
                                    <div class="form-group row px-3">
                                        <div class="col-md-4 px-0 ">
                                            <label class="form-label">Employee Name / ID</label>
                                            <input type="search" class="form-control" name="Employee Name"
                                                id="employeename" placeholder="Enter Employee Name / ID"
                                                [(ngModel)]="query" autocomplete="off" (input)="getEmployeeByNameOrId()"
                                                list="employeeMasterLucene">
                                            <datalist id="employeeMasterLucene">
                                                <option data-value={{emp.name}}
                                                    *ngFor="let emp of employeeMasterLucene">
                                                    {{emp.employeeid}}
                                                </option>
                                            </datalist>
                                        </div>

                                        <!-- Active/Inactive Employee Status filter for Lucene Search -->
                                        <div class="form-group col-md-4 col-sm-4 col-xs-4 px-2">
                                            <label class="form-label">Employee Status</label>
                                            <select class="form-control" type="text" style="width: 100%;" required
                                                [(ngModel)]="ActiveInativeLuceneFilter" name="selVal"
                                                (change)="onStatusChange()"  [disabled]="!showBasedOnPermissionEmployeeStatus">
                                                <option value="All">All</option>
                                                <option value="Active">Active</option>
                                                <option value="Inactive">Inactive</option>
                                            </select>
                                        </div>

                                            <div class="col-md-3 col-sm-4 col-xs-4 mt-5">
                                            <button type="button" id="navigatetarget"
                                                class="btn btn-primary w-45   mt-3 mt-md-0 p-2 p-md-1 "
                                                style="margin-right:1vw" (click)="limitpara(); searchEmployeeformT(); ">
                                                <i class="fa fa-search" aria-hidden="true"></i> Search
                                            </button>
                                            <button type="button" class="btn btn-danger w-45"
                                                (click)="clear()">Clear</button>
                                        </div>

                                    </div>
                                </form>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title ">Muster Roll / Form T Report </h4>
                        <div *ngIf="searchClicked" class="panel-body border-0 d-flex flex-wrap">
                            <div class="p-1 d-flex font-weight-semibold align align-items-center"
                                [style.fontSize.px]="12.5">
                                <span class="dot-label me-2 my-auto"></span>
                                A/H - Annual Holiday &nbsp; W/O - Working Off
                            </div>
                        </div>
                        <button type="button" class="btn btn-primary ms-auto" (click)="formtReportDownload()">
                            <i class="fa fa-download" data-bs-toggle="tooltip" data-original-title="download"></i>
                        </button>
                    </div>
                    <div class="card-body">
                        <div class="col-xs-12 mobile view">
                            <div *ngIf="employeeMasterList !== null ">
                                <div class="scrollsearchresults" infiniteScroll [infiniteScrollDistance]="2"
                                    [infiniteScrollUpDistance]="1.5" [infiniteScrollThrottle]="100"
                                    [scrollWindow]="false" (scrolled)="onScroll()" id="scrolllength">

                                    <table
                                        class="table table-hover table-vcenter text-nowrap table-bordered border-bottom"
                                        id="hr-table">
                                        <thead class="tablerow">
                                            <tr class=" stickyatTop rowcolors">
                                                <th class="border-bottom-0 w-5 fixedcolumn">#</th>
                                                <th class="border-bottom-0 w-5 secondcolumn">Name</th>
                                                <th class="border-bottom-0">Father / Husband Name</th>
                                                <th class="border-bottom-0">M / F</th>
                                                <th class="border-bottom-0">Designation/ Department</th>
                                                <th class="border-bottom-0">Date of Joining</th>
                                                <th class="border-bottom-0">ESI NO</th>
                                                <th class="border-bottom-0">EPF NO</th>
                                                <th class="border-bottom-0">Wages fixed Incuding VDA</th>
                                                <!-- <th class="border-bottom-0" style="text-align: center;">Month
                                            
                                                    <tr class="stickyatTop rowcolors whitebgg"> -->

                                                <th class="padding_top_only text-center border-bottom-0 wC target_row"
                                                    *ngFor="let day of daysArray; index as i">
                                                    {{ day }}

                                                    <!-- <span *ngIf="i === 0" class="move_span">Month</span> -->
                                                </th>

                                                <th class="border-bottom-0">WKD</th>
                                                <th class="border-bottom-0">W/O</th>
                                                <th class="border-bottom-0">LEAVE</th>
                                                <th class="border-bottom-0">ABSENT</th>
                                                <th class="border-bottom-0">Total</th>




                                                <th class="border-bottom-0">No. of payable Days</th>
                                                <th class="border-bottom-0">Total OT Hrs Worked</th>

                                                <ng-container
                                                    *ngIf="salaryDetailNameList && salaryDetailNameList.length > 0">
                                                    <!-- Display table headings for salary details -->
                                                    <th *ngFor="let salaryDetail of salaryDetailNameList">{{
                                                        salaryDetail.salaryDetailName }}</th>
                                                </ng-container>

                                                <th class="border-bottom-0">Total</th>



                                                <ng-container
                                                    *ngIf="salaryDetailNameList && salaryDetailNameList.length > 0">
                                                    <th *ngFor="let salaryDetail of salaryDetailNameLists">{{
                                                        salaryDetail.salaryDetailName }}</th>
                                                </ng-container>
                                                <th class="border-bottom-0">Total</th>
                                                <th class="border-bottom-0">Net Payable</th>
                                                <th class="border-bottom-0">Mode of Pay</th>
                                                <th class="border-bottom-0">Bank A/c. No</th>
                                                <th class="border-bottom-0">Employee Signature</th>







                                            </tr>


                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let employee of employeeArray; let i = index">
                                                <td style="text-align: right;">{{i+1}}</td>
                                                <td>{{employee.name ? employee.name : '--' }}</td>
                                                <td>{{employee.fathersname? employee.fathersname : '--' }}</td>
                                                <td>{{employee.gender ? employee.gender : '--'}}</td>
                                                <td>{{employee.departmentname ? employee.departmentname : '--'}}</td>
                                                <td>{{ employee.joiningDate ? (employee.joiningDate|date) : '--' }}</td>
                                                <td style="text-align: right;">{{employee.esino ? employee.esino : '--'}}</td>
                                                <td style="text-align: right;">{{employee.pfno ? employee.pfno : '--'}}</td>
                                                <td>--</td>




                                                <ng-container>
                                                    <td
                                                        *ngFor="let attendance of employee.attendanceList; let j = index">
                                                        <div data-th="Leave Type">
                                                            <span style="font-size: 13px; font-weight: 500;"
                                                                *ngIf="attendance.daysPresent === 0.5">
                                                                P/0.5
                                                            </span>
                                                            <span style="font-size: 13px; font-weight: 500;"
                                                                *ngIf="attendance.daysPresent === 1">
                                                                P
                                                            </span>
                                                            <span style="font-size: 13px; font-weight: 500; color: red;"
                                                                *ngIf="attendance.oddPunch !== 0">
                                                                ODD
                                                            </span>
                                                            <span style="font-size: 13px; font-weight: 500;"
                                                                *ngIf="attendance.leaveTaken !== 0">
                                                                A/L
                                                            </span>
                                                            <span style="font-size: 13px; font-weight: 500;"
                                                                *ngIf="attendance.daysPresent !== 1 && attendance.weeklyHolidays !== 1 && attendance.annualHoliday !== 1 && attendance.oddPunch === 0 && attendance.afterCurrentDate === 0 && attendance.leaveTaken === 0 && attendance.extraDays===0"
                                                                class="badge btn-danger">
                                                                A
                                                            </span>
                                                            <span style="font-size: 13px; font-weight: 500;"
                                                                *ngIf="attendance.weeklyHolidays === 1">
                                                                W/O
                                                            </span>
                                                            <span style="font-size: 13px; font-weight: 500;"
                                                                *ngIf="attendance.annualHoliday === 1">
                                                                A/H
                                                            </span>
                                                            <span style="font-size: 13px; font-weight: 500;"
                                                                *ngIf="!(attendance.daysPresent === 0.5 || attendance.daysPresent === 1 || attendance.oddPunch !== 0 || attendance.leaveTaken !== 0 || (attendance.daysPresent !== 1 && attendance.weeklyHolidays !== 1 && attendance.annualHoliday !== 1 && attendance.oddPunch === 0 && attendance.afterCurrentDate === 0 && attendance.leaveTaken === 0 && attendance.extraDays===0) || attendance.weeklyHolidays === 1 || attendance.annualHoliday === 1)">
                                                                --
                                                            </span>
                                                        </div>
                                                    </td>
                                                </ng-container>

                                                <td style="text-align: right;">{{employee.daysPresent ?
                                                    employee.daysPresent : '0'}}</td>
                                                <td style="text-align: right;">{{employee.weeklyHolidays ?
                                                    employee.weeklyHolidays : '0'}}</td>
                                                <td style="text-align: right;">{{employee.leaveTaken ?
                                                    employee.leaveTaken : '0'}}</td>
                                                <td style="text-align: right;">{{employee.daysAbsent ?
                                                    employee.daysAbsent : '0'}}</td>
                                                <td style="text-align: right;">{{employee.daysInMonth ?
                                                    employee.daysInMonth : '0'}}</td>
                                                <td style="text-align: right;">{{employee.payableDays ?
                                                    employee.payableDays : '0'}}</td>
                                                <td style="text-align: right;">{{employee.otHours ? employee.otHours :
                                                    '0'}}</td>


                                                <ng-container *ngIf="employee.salaryDetailsInfo">
                                                    <td *ngFor="let salaryDetail of salaryDetailNameList"
                                                        style="text-align: right;">
                                                        <span>{{ getSalaryAmount(employee.uniqueemployeeid,
                                                            salaryDetail.salaryDetailName) }}</span>
                                                    </td>
                                                </ng-container>

                                                <td style="text-align: right;">{{
                                                    sumOfAdditionAmounts(employee.uniqueemployeeid) || 0 }}</td>

                                                <ng-container *ngIf="employee.salaryDetailsInfo">
                                                    <td *ngFor="let salaryDetail of salaryDetailNameLists"
                                                        style="text-align: right;">
                                                        <span>{{ getSalaryAmount(employee.uniqueemployeeid,
                                                            salaryDetail.salaryDetailName) }}</span>
                                                    </td>
                                                </ng-container>

                                                <td style="text-align: right;">{{
                                                    sumOfDeductAmounts(employee.uniqueemployeeid) || 0 }}</td>
                                                <td style="text-align: right;">{{employee.salaryAmount ?
                                                    employee.salaryAmount : '0'}}</td>
                                                <td>--</td>
                                                <td>{{employee.bankaccount ? employee.bankaccount : '--' }}</td>
                                                <td>--</td>









                                                <!-- <td>fffff</td> -->








                                            </tr>







                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>