<div class="app-content main-content">
    <div class="side-app main-container" style="margin-top:-15px">

        <div class="alertcomp">
        </div>
        <!-- <div class="main-panel">
            <div class="content">
                <div class="container-fluid" role="main">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-header">
                                    <h3>Employee Master</h3>
                                </div>
                                <div class="card-content">
                                    <div class="card-body">
                                        <div class="container">
                                            <form [formGroup]="form" (ngSubmit)="submit()">
                                                <formly-form [model]="model" [fields]="fields" [options]="options"
                                                    [form]="form"></formly-form>
                                                <button type="submit"
                                                    class="btn btn-primary submit-button">Submit</button>
                                                <button type="button" class="btn btn-danger medium"
                                                    (click)="options.resetModel()">Reset</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        

        <!-- <div class="alert alert-success mt-4 d-none" id="uniquealert"  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
        <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>  successfully Saved.</div> -->

        <!-- header pop up  -->
        <div class="page-header d-xl-flex d-block">
            <!-- <div class="page-leftheader">
                <div class="page-title">Add Employee</div>
            </div> -->
            <div class="page-rightheader ms-md-auto">
                <div class="align-items-end flex-wrap my-auto right-content breadcrumb-right">
                    <div class="btn-list">

                        <!-- <button class="btn btn-primary me-3" data-bs-toggle="modal"
                            data-bs-target="#faceRecognitionModal">
                            Add Face Recognition</button> -->

                        <button class="btn btn-primary me-3" data-bs-toggle="modal" data-bs-target="#fourModal">
                            Weekly Holidays</button>
                        <button class="btn btn-primary me-3" data-bs-toggle="modal"
                            data-bs-target="#EmployeeUploadModal" (click)="set_emp_upload()">
                            Employee Upload</button>
                        <button class="btn btn-primary me-3" data-bs-toggle="modal" data-bs-target="#exampleModal">
                            Salary Details</button>

                            
                        <!-- <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                            data-bs-target="#additionalModal"> Additional Details</button> -->

                        <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                            data-bs-target="#secondaryModal" (click)="set_emp_secondary_dep()"> Secondary
                            Department</button>

                        <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                            data-bs-target="#thirdModal">Leave Type</button>

                        <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                            data-bs-target="#familyModal"> Family Details</button>
                    </div>
                </div>
            </div>
            <!-- <div class="page-rightheader ms-md-auto">
                <div class="align-items-end flex-wrap my-auto right-content breadcrumb-right">
                    <div class="btn-list">
                        <button class="btn btn-light" data-bs-toggle="tooltip" data-bs-placement="top" title="E-mail">
                            <i class="feather feather-mail"></i> </button>
                        <button class="btn btn-light" data-bs-placement="top" data-bs-toggle="tooltip" title="Contact">
                            <i class="feather feather-phone-call"></i> </button>
                        <button class="btn btn-primary" data-bs-placement="top" data-bs-toggle="tooltip" title="Info">
                            <i class="feather feather-info"></i> </button>
                    </div>
                </div>
            </div> -->
        </div>

        <!-- main  -->
        <div class="row">
            <div class="col-xl-3 col-md-12 col-lg-12">
                <div class="card box-widget widget-user" style="margin-bottom:10px">
                    <div class="card-body text-center">
                        <div class="widget-user-image mx-auto text-center">
                            <input type="file" id="profile" accept="image/png, image/jpeg"
                                (change)="getProfilePhoto($event)" style="visibility: hidden;">
                            <label for="profile" class=" w-100">
                                <!-- <img class="avatar avatar-xxl brround rounded-circle" alt="img"
                                    [src]="url|| './../../../assets/template_assests/images/users/1.jpg'"> -->
                                <ng-container *ngIf="empObj.gender =='M'">
                                    <img class="avatar avatar-xxl brround rounded-circle" alt="img"
                                        [src]="url || 'assets/template_assests/images/users/male.png'">
                                </ng-container>

                                <ng-container *ngIf="empObj.gender =='F'">
                                    <img class="avatar avatar-xxl brround rounded-circle" alt="img"
                                        [src]="url || 'assets/template_assests/images/users/female.png'">
                                </ng-container>

                                <ng-container *ngIf="empObj.gender ==undefined">
                                    <img class="avatar avatar-xxl brround rounded-circle" alt="img"
                                        [src]="url || 'assets/template_assests/images/users/1.jpg'">
                                </ng-container>
                            </label>
                        </div>
                        <div class="pro-user mt-3">
                            <h5 class="pro-user-username text-dark mb-1 fs-16">{{model.name || "Name"}}</h5>
                            <h6 class="pro-user-desc text-muted fs-12">{{empObj.designationName || ""}}</h6>
                        </div>

                    </div>

                    <!-- <div class="card-footer p-0">
                        <div class="row">
                            <div class="col-6 text-center py-5 border-end">
                                <h5 class="fs-12 font-weight-semibold mb-3">January</h5>
                                <h5 class="mb-2">
                                    <span class="fs-18 text-success">0</span>
                                    <span class="my-auto fs-9 font-weight-normal  ms-1 me-1">/</span>
                                    <span class="fs-18 font-weight-semibold text-dark">31</span>
                                </h5>
                                <h5 class="fs-12 mb-0">Attendance</h5>
                            </div>
                            <div class="col-6 py-5 text-center ">
                                <h5 class="fs-12 font-weight-semibold mb-3">Year-2021</h5>
                                <h5 class="mb-2">
                                    <span class="fs-18 text-orange">0</span>
                                    <span class="my-auto fs-9 font-weight-normal  ms-1 me-1">/</span>
                                    <span class="fs-18 font-weight-semibold text-dark">41</span>
                                </h5>
                                <h5 class="fs-12 mb-0">Leaves</h5>
                            </div>

                        </div>
                    </div> -->

                </div>
            </div>

            <div class="col-xl-9 col-md-12 col-lg-12" style="border-radius: 13px ;">
                <form [formGroup]="form" (ngSubmit)="submit()" autocomplete="off"
                    class="darkmode_formly lightmode_formly" enctype="multipart/form-data">
                    <formly-form [model]="model" [fields]="fields" [options]="options" [form]="form"></formly-form>

                    <div class="actionBar pe-4 ps-4" style="padding: 0px; margin-top: -20px;">
                        <div class="clearfix"> </div>
                        <div>

                            <button type="button" class="btn btn-primary m-2" *ngIf="!enablebackBtn"
                                (click)="redirectToSearchEmployee($event)"><i class="fa fa-search p-0" aria-hidden="true"></i>Search
                                Employee Details </button>
                            <button type="button" class="btn btn-primary m-2" *ngIf="enablebackBtn"
                                (click)="redirectToSearchEmployeeBack($event,2)">Back
                            </button>
                            <button type="submit" class="btn btn-success submit-button m-2"
                                [ngClass]="{'disable-div': disDiv}"
                                [hidden]="empObj?.isedit === 'true' ||  empObj?.isedit === 'false'"
                                id="btndisabled">Save</button>

                            <button type="submit" class="btn btn-secondary submit-button m-2"
                                [ngClass]="{'disable-div': disDiv}"
                                *ngIf="allowAdminToEdit && empObj?.isedit !== 'false' && empObj?.isedit">
                                Update
                            </button>

                            <!-- [hidden]="empObj?.isedit === 'false' || !empObj?.isedit"> -->

                            <!-- {{empObj?.isedit === 'false' || !empObj?.isedit}} -->

                            <button type="button" class="btn btn-danger medium m-2" (click)="resetobj()">Clear</button>

                            <!-- <button, type="button" class="btn btn-info medium " (click)="redirectToSearchEmployee($event)">Back</button> -->

                        </div>
                    </div>
                </form>
            </div>
        </div>

        <!-- Salary Details -->
        <div class="modal  fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-scrollable modal-lg">
                <div class="modal-content">
                    <div class="modal-header d-flex align-items-center mb-3">
                        <h5 class="modal-title" id="exampleModalLabel" style="font-size: 20px;">Salary Details As Of
                        </h5>
                        <!-- Current Date Field -->
                        <div class="current-date ms-3">
                            <input id="currentDate" type="date" class="form-control" [(ngModel)]="currentDate" />
                        </div>

                        <!-- Button Field -->
                        <div class="col-md-3 px-0 ms-3" style="margin-right:200px">
                            <button type="button" id="navigatetarget" class="btn btn-primary w-100 p-md-1"
                                (click)="searchSalaryDetails()">
                                <i class="fa fa-search" aria-hidden="true"></i> Search
                            </button>
                        </div>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
                    </div>

                    <div class="modal-body">
                        <div class=" container-fluid mb-6">
                            <div class="alertcompSalaryDetail">
                            </div>

                            <!-- <div>Salary Detail Additional Component</div> -->

                            <h5 class="card-title mt-3 mb-3 "> Salary Details - Addition Component</h5>

                            <div class="mobile">
                                <table class="table table-hover table-vcenter text-nowrap table-bordered border-bottom"
                                    id="hr-table1">
                                    <!-- <table class="table  table-vcenter text-nowrap table-bordered border-bottom"> -->
                                    <thead class="tablerow">
                                        <tr class="rowcolors">
                                            <th class="border-bottom-0 fs-6">Salary head</th>
                                            <th class="border-bottom-0 fs-6">Salary Detail</th>
                                            <th class="border-bottom-0 fs-6">From Date</th>
                                            <th class="border-bottom-0 fs-6">To Date</th>
                                            <th class="border-bottom-0 fs-6">Amount</th>
                                            <th class="border-bottom-0 fs-6">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td data-th="Salary head">
                                                <select class="form-control" style="appearance: auto !important;"
                                                    data-style="btn btn-drpdwn btn-round" id="SalaryHead"
                                                    name="SalaryHead" [(ngModel)]="insertaddsalarydetails.salaryHeadId"
                                                    (change)="headChangeadd(insertaddsalarydetails.salaryHeadId)">
                                                    <option value="">&nbsp;
                                                    </option>
                                                    <ng-container *ngFor="let hd of additionsalaryMaster">
                                                        <option
                                                            *ngIf="hd.salaryDetailMaster && hd.salaryDetailMaster.length > 0"
                                                            value="{{hd.salaryHeadId}}">
                                                            {{hd.salaryHeadName}}
                                                        </option>
                                                    </ng-container>
                                                </select>
                                            </td>

                                            <td data-th="Salary Detail">
                                                <!-- {{salaryDetailMaster | json}} -->
                                                <select class="form-control" style="appearance: auto !important;"
                                                    data-style="btn btn-drpdwn btn-round" id="salaryDetailId"
                                                    [(ngModel)]="insertaddsalarydetails.salarydetailId"
                                                    name="salaryDetailId">
                                                    <option value="">&nbsp;
                                                    </option>
                                                    <option *ngFor="let sd of additiondetailMasterSection"
                                                        value="{{sd.salaryDetailId}}">
                                                        {{sd.salaryDetailName}}
                                                    </option>
                                                </select>
                                            </td>
                                            <td>
                                                <input class="form-control" type="date"
                                                    [(ngModel)]="insertaddsalarydetails.fromDate" name="fromdate"
                                                    id="fromdate">
                                            </td>
                                            <td>
                                                <input class="form-control" type="date"
                                                    [(ngModel)]="insertaddsalarydetails.toDate" name="todate"
                                                    id="todate" disabled="true">
                                            </td>
                                            <td data-th="Amount" style="text-align: right;">
                                                <input type="number" min="0" class="form-control"
                                                    [(ngModel)]="insertaddsalarydetails.salaryComponentAmount">
                                            </td>
                                            <td>
                                                <button type="button"
                                                    (click)="addAdditionSal(insertaddsalarydetails.salaryHeadId,insertaddsalarydetails.salarydetailId,
                                                    insertaddsalarydetails.fromDate,insertaddsalarydetails.toDate,insertaddsalarydetails.salaryComponentAmount)"
                                                    class="btn btn_fit mb-2"><span><svg
                                                            xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                            fill="currentColor" class="bi bi-plus-lg"
                                                            viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd"
                                                                d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2" />
                                                        </svg>
                                                    </span>
                                                </button>
                                            </td>
                                        </tr>
                                        <tr *ngFor="let sh of insertaddsalarydetail; index as i">
                                            <ng-container *ngIf="sh.userId!='Del'">
                                                <td>
                                                    {{sh.salaryHeadName}}

                                                </td>
                                                <td>
                                                    {{sh.salaryDetailName}}
                                                </td>
                                                <td>
                                                    {{sh.fromDate ? (sh.fromDate | date:'mediumDate') : ''}}
                                                    <!-- <input class="form-control" type="text" id="fromDate{{i+1}}" name="fromDate" 
                                                        [(ngModel)]="sh.fromDate" 
                                                        [value]="sh.fromDate ? (sh.fromDate | date:'mediumDate') : ''" 
                                                        disabled> -->
                                                </td>

                                                <td>
                                                    {{sh.toDate ? (sh.toDate | date:'mediumDate') : ''}}
                                                    <!-- <input class="form-control" type="text" id="toDate{{i+1}}"
                                                        name="toDate" [(ngModel)]="sh.toDate"
                                                        [value]="sh.toDate ? (sh.toDate | date:'mediumDate') : ''"
                                                        disabled> -->
                                                </td>

                                                <td style="text-align: right;">
                                                    <ng-container *ngIf="!sh.toDate; else displayAmount">
                                                        <input type="number" min="0" class="form-control text-right"
                                                            id="salaryComponentAmount{{i+1}}"
                                                            name="salaryComponentAmount"
                                                            [(ngModel)]="sh.salaryComponentAmount">
                                                    </ng-container> 
                                                    <ng-template #displayAmount>
                                                        {{sh.salaryComponentAmount}}
                                                    </ng-template>
                                                </td>
                                                <td>
                                                    <button *ngIf="!sh.toDate" type="button"
                                                        (click)="deleteAdditiobSal(i)" class="btn btn_fit mb-2">
                                                        <span><svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                                height="16" fill="red" class="bi bi-trash"
                                                                viewBox="0 0 16 16">
                                                                <path
                                                                    d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                                                                <path
                                                                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                                                            </svg></span>
                                                    </button>
                                                </td>
                                            </ng-container>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div class=" container-fluid mb-6">

                            <!-- <div>Salary Detail Additional Component</div> -->
                            <h5 class="card-title mt-3 mb-3 "> Salary Details - Deduction Component</h5>


                            <div class="mobile">
                                <table class="table table-hover table-vcenter text-nowrap table-bordered border-bottom"
                                    id="hr-table1">
                                    <!-- <table class="table  table-vcenter text-nowrap table-bordered border-bottom"> -->
                                    <thead class="tablerow">
                                        <tr class="rowcolors">
                                            <th class="border-bottom-0 fs-6">Salary head</th>
                                            <th class="border-bottom-0 fs-6">Salary Detail</th>
                                            <th class="border-bottom-0 fs-6">From Date</th>
                                            <th class="border-bottom-0 fs-6">To Date</th>
                                            <th class="border-bottom-0 fs-6">Amount</th>
                                            <th class="border-bottom-0 fs-6">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td data-th="Salary head">
                                                <select class="form-control" style="appearance: auto !important;"
                                                    data-style="btn btn-drpdwn btn-round" id="SalaryHeads"
                                                    name="SalaryHeads"
                                                    [(ngModel)]="insertdeductsalarydetails.salaryHeadId"
                                                    (change)="headChangededuct(insertdeductsalarydetails.salaryHeadId)">
                                                    <option value="">&nbsp;
                                                    </option>
                                                    <ng-container *ngFor="let hd of deductionsalaryMaster">
                                                        <option
                                                            *ngIf="hd.salaryDetailMaster && hd.salaryDetailMaster.length > 0"
                                                            value="{{hd.salaryHeadId}}">
                                                            {{hd.salaryHeadName}}
                                                        </option>
                                                    </ng-container>
                                                </select>
                                            </td>

                                            <td data-th="Salary Detail">
                                                <!-- {{salaryDetailMaster | json}} -->
                                                <select class="form-control" style="appearance: auto !important;"
                                                    data-style="btn btn-drpdwn btn-round" id="salaryDetailIds"
                                                    [(ngModel)]="insertdeductsalarydetails.salarydetailId"
                                                    name="salaryDetailIds">
                                                    <option value="">&nbsp;
                                                    </option>
                                                    <option *ngFor="let sd of deductiondetailMasterSection"
                                                        value="{{sd.salaryDetailId}}">
                                                        {{sd.salaryDetailName}}
                                                    </option>
                                                </select>
                                            </td>
                                            <td>
                                                <input class="form-control" type="date"
                                                    [(ngModel)]="insertdeductsalarydetails.fromDate" name="fromdate1"
                                                    id="fromdate1">
                                            </td>
                                            <td>
                                                <input class="form-control" type="date"
                                                    [(ngModel)]="insertdeductsalarydetails.toDate" name="todate1"
                                                    id="todate1" disabled="true">
                                            </td>
                                            <td data-th="Amount">
                                                <input type="number" min="0" class="form-control"
                                                    [(ngModel)]="insertdeductsalarydetails.salaryComponentAmount">
                                            </td>
                                            <td>
                                                <button type="button"
                                                    (click)="addDeductSal(insertdeductsalarydetails.salaryHeadId,insertdeductsalarydetails.salarydetailId,
                                                    insertdeductsalarydetails.fromDate,insertdeductsalarydetails.toDate,insertdeductsalarydetails.salaryComponentAmount)"
                                                    class="btn btn_fit mb-2"><span><svg
                                                            xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                            fill="currentColor" class="bi bi-plus-lg"
                                                            viewBox="0 0 16 16">
                                                            <path fill-rule="evenodd"
                                                                d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2" />
                                                        </svg>
                                                    </span>
                                                </button>
                                            </td>
                                        </tr>
                                        <tr *ngFor="let sh of insertdeductsalarydetail; index as i">
                                            <ng-container *ngIf="sh.userId!='Del'">
                                                <td>
                                                    {{sh.salaryHeadName}}

                                                </td>
                                                <td>
                                                    {{sh.salaryDetailName}}
                                                </td>
                                                <td>
                                                    {{sh.fromDate ? (sh.fromDate | date:'mediumDate') : ''}}
                                                    <!-- <input class="form-control" type="text" id="fromDate{{i+1}}" name="fromDate" 
                                                        [(ngModel)]="sh.fromDate" 
                                                        [value]="sh.fromDate ? (sh.fromDate | date:'mediumDate') : ''" 
                                                        disabled> -->
                                                </td>

                                                <td>
                                                    {{sh.toDate ? (sh.toDate | date:'mediumDate') : ''}}
                                                    <!-- <input class="form-control" type="text" id="toDate{{i+1}}"
                                                        name="toDate" [(ngModel)]="sh.toDate"
                                                        [value]="sh.toDate ? (sh.toDate | date:'mediumDate') : ''"
                                                        disabled> -->
                                                </td>
                                                <td style="text-align: right;">
                                                    <ng-container *ngIf="!sh.toDate; else displayAmount">
                                                        <input type="number" min="0" class="form-control text-right"
                                                            id="salaryComponentAmount{{i+1}}"
                                                            name="salaryComponentAmount"
                                                            [(ngModel)]="sh.salaryComponentAmount">
                                                    </ng-container>
                                                    <ng-template #displayAmount>
                                                        {{sh.salaryComponentAmount}}
                                                    </ng-template>
                                                </td>
                                                <td>
                                                    <button *ngIf="!sh.toDate" type="button"
                                                        (click)="deleteDeductSal(i)" class="btn btn_fit mb-2">
                                                        <span><svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                                height="16" fill="red" class="bi bi-trash"
                                                                viewBox="0 0 16 16">
                                                                <path
                                                                    d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                                                                <path
                                                                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                                                            </svg></span>
                                                    </button>
                                                </td>
                                            </ng-container>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <!-- <form>
                            <div class="mb-3">
                                <label for="recipient-name" class="col-form-label">Recipient:</label>
                                <input type="text" class="form-control" id="recipient-name">
                            </div>
                            <div class="mb-3">
                                <label for="message-text" class="col-form-label">Message:</label>
                                <textarea class="form-control" id="message-text"></textarea>
                            </div>
                        </form> -->

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-primary" data-bs-dismiss="modal" aria-label="Close"
                            (click)="get()">Ok</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Employee Upload -->
        <div class="modal fade" id="EmployeeUploadModal" tabindex="-1" aria-labelledby="additionalModalLabel"
            aria-hidden="true" style="display: none;">
            <div class="modal-dialog modal-lg modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel" style="font-size: 20px;">Employee Upload
                        </h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
                    </div>
                    <div class="modal-body">
                        <div class="row mt-4">
                            <div class="alertcompExcelUpload">
                            </div>

                            <div class="col-xl-12 col-md-12 col-lg-12">
                                <div class="col-xs-12 mobile">
                                    <div message="pattern"><span class=" textColors">
                                            Upload excel with uploaded document details.
                                        </span>
                                        <button style="float: right;" class="btn btn-primary"
                                            (click)="employeeMasterCSVDownload()">Download Template </button>
                                    </div>

                                    <div>
                                        <label for="form-label" class="form-label">Select File</label>
                                        <input type="file" id="file" class="form-control" name="myFilename"
                                            style="box-sizing: border-box;" [value]="excelDoc.file" required="required"
                                            enctype="multipart/form-data" (change)="handleFileInput($event)" />
                                        <span class="help-text text-danger" *ngIf="(!isFileValid)">Upload only '.xls'
                                            file! </span>
                                    </div>
                                    <button type="button" class="btn btn-success w-100 w-md-20 mt-2"
                                        [ngStyle]="{'display': allowAdminToEdit ? '' : 'none'}" (click)="uploadImage()"
                                        (click)="uploadFile()">Upload
                                        File</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">

                    </div>
                </div>
            </div>
        </div>

        <!-- Error Details for excel -->
        <div class="modal fade" id="excelErrors" tabindex="-1" aria-labelledby="excelErrors" aria-hidden="true"
            style="display: none;">
            <div class="modal-dialog  modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="excelErrors" style="font-size: 20px;">Error Details</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
                    </div>
                    <div class="modal-body">
                        <div class="container-fluid">
                            <!-- <h5 class="card-title mt-3 mb-3">Error Details</h5> -->
                            <ul class="list-group">
                                <li class="list-group-item" *ngFor="let error of excelErrors">
                                    {{ error }}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                            (click)="clear()">Close</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- doc upload  -->
        <!-- <div class="row mt-4" >
            <div class="col-xl-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="card-body p-4">
                        <div class="col-xs-12 mobile">
                            <div message="pattern"><span class=" textColors">
                                    Step 2: Upload excel with uploaded document details.
                                    The columns should be as follows. empId, branchId, docGroup, docType, expiryDate,
                                    filePath
                                </span>
                            </div>

                            <div>
                                <label for="form-label" class="form-label">Select File</label>
                                <input type="file" id="file" class="form-control" name="myFilename"
                                    style="box-sizing: border-box;" [value]="excelDoc.file" required="required"
                                    enctype="multipart/form-data" (change)="handleFileInput($event)" />

                            </div>
                            <button type="button" class="btn btn-success w-100 w-md-20 mt-2" (click)="uploadImage()"
                                (click)="uploadFile()">Upload
                                File</button>

                        </div>
                    </div>
                </div>
            </div>
        </div> -->




        



        <!-- Additonal Details -->
        <div class="modal fade" id="additionalModal" tabindex="-1" aria-labelledby="additionalModalLabel"
            aria-hidden="true" style="display: none;">
            <div class="modal-dialog modal-lg modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel" style="font-size: 20px;">Additonal Details
                        </h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
                    </div>
                    <div class="modal-body">
                        <div class=" container-fluid">

                            <!-- <div>Salary Detail Additional Component</div> -->

                            <h5 class="card-title mt-3 mb-3 "> Additional Details </h5>
                            <div class="mobile">
                                <table class="table  table-vcenter text-nowrap table-bordered border-bottom">
                                    <thead>
                                        <tr>
                                            <th>Attribute Name</th>
                                            <th class="ps-3">Attribute Value</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of model.employeeattributevalueinfo">
                                            <td data-th="Attribute Name">{{item?.attributename }}</td>
                                            <td data-th="Attribute Value"><input type="text" class="form-control "
                                                    (input)="readamount($event,item , false)"
                                                    placeholder="Enter Attribute value" [value]="item?.attributevalue">
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                            (click)="get()">Ok</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Secondary Department -->
        <div class="modal fade" id="secondaryModal" tabindex="-1" aria-labelledby="secondaryModalLabel" 
            aria-hidden="true" style="display: none;">
            <div class="modal-dialog  modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="secondaryModalLabel" style="font-size: 20px;">Select Secondary
                            Department</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
                    </div>
                    <div class="modal-body">
                        <div class=" container-fluid">

                            <!-- {{secondaryDepartmentObj | json }} -->
                            <!-- <div>Salary Detail Additional Component</div> -->
                            <!-- <h5 class="card-title mt-3 mb-3 "> Additional Details </h5> -->

                            <ul class="list-group">
                                <li class="list-group-item " style="cursor: pointer;"
                                    *ngFor="let sec of secondaryDepartmentObjFiltered">
                                    <input type="checkbox"
                                        (change)="addSecdepartment($event , sec);$event.stopPropagation()"
                                        class="deCheckall" id="{{sec.name}}">
                                    <label for="{{sec.name}}" style="margin-left: 10px;">{{sec.name}}</label>
                                </li>

                            </ul>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                            (click)="clear()">Close</button>
                        <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                            (click)="get()">Ok</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Leave Type -->
        <div class="modal fade" id="thirdModal" tabindex="-1" aria-labelledby="thirdModalLabel" aria-hidden="true"
            style="display: none;">
            <div class="modal-dialog modal-lg modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="thirdModalLabel" style="font-size: 20px;">Leave Type</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
                    </div>
                    <div class="modal-body">
                        <div class="container-fluid ">

                            <table class="table table-hover table-vcenter text-nowrap table-bordered border-bottom"
                                id="hr-table">
                                <thead class="tablerow">
                                    <tr class="stickyatTop rowcolors">
                                        <th>Leave Type</th>
                                        <th>Days</th>
                                    </tr>
                                </thead>

                                <tbody>

                                    <!-- <tr *ngFor="let leavelist of LeaveTypeList; let i = index">
                                        <ng-container *ngIf="uidChecker === false">
                                            <td data-th="Leave Type">{{ leavelist.leaveName }}</td>
                                            <td>
                                                <input *ngIf="LeaveTypeListValue" type="text"
                                                    class="form-control col-md-12 col-xs-12 leaveType"
                                                    value="{{ LeaveTypeListValue[i]?.noOfDays === -1 ? 'Na' : LeaveTypeListValue[i].noOfDays }}"
                                                    (change)="onInputChange($event, i)">
                                            </td>
                                        </ng-container>
                                        <ng-container *ngIf="uidChecker === true">
                                            <td data-th="Leave Type">{{ leavelist.leaveName }}</td>
                                            <td>
                                                <input *ngIf="LeaveTypeListValue" type="text" class="form-control col-md-12 col-xs-12 
                                                    leaveType"
                                                    value="{{ LeaveTypeListValue[i]?.noOfDays === -1 ? 'Na' : 'Na' }}"
                                                    (change)="onInputChange($event, i)" disabled>
                                            </td>
                                        </ng-container>
                                    </tr> -->


                                    <ng-container *ngIf="uidChecker === true ">
                                        <tr *ngFor="let leavelist of LeaveTypeList; let i = index">
                                            <td data-th="Leave Type">{{leavelist.info.leaveName}}</td>
                                            <td>
                                                <input *ngIf="LeaveTypeListValue" type="text"
                                                    class="leaveType form-control col-md-12 col-xs-12"
                                                    value="{{ LeaveTypeListValue[i]?.info.noOfDays === -1 ? 'Na' : 'Na' }}"
                                                    (change)="onInputChange($event, i)" disabled>
                                            </td>
                                        </tr>
                                    </ng-container>

                                    <ng-container *ngIf="uidChecker === false">
                                        <tr *ngFor="let leavelist of LeaveTypeListValue; let i = index">
                                            <td data-th="Leave Type">{{leavelist.leaveName}}</td>
                                            <td>
                                                <input *ngIf="LeaveTypeListValue" type="text"
                                                    class="leaveType form-control col-md-12 col-xs-12"
                                                    value="{{ LeaveTypeListValue[i]?.noOfDays === -1 ? 'Na' : LeaveTypeListValue[i].noOfDays }}"
                                                    (change)="onInputChange($event, i)"
                                                    [disabled]="empObj.isedit==='false'">
                                            </td>
                                        </tr>
                                    </ng-container>




                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button *ngIf="uidChecker === false" [hidden]="empObj?.isedit === 'false' " type="button"
                            [ngStyle]="{'display': allowAdminToEdit ? '' : 'none'}" class="btn btn-primary"
                            data-bs-dismiss="modal" (click)="update_LeaveType()">Update</button>
                        <button *ngIf="uidChecker === false && empObj?.isedit === 'false'" type="button"
                            class="btn btn-primary" data-bs-dismiss="modal">OK</button>
                    </div>

                </div>
            </div>
        </div>

        <!-- Face Recognition -->
        <div class="modal fade" id="faceRecognitionModal" tabindex="-1" aria-labelledby="faceRecognitionModal"
            aria-hidden="true" style="display: none;">
            <div class="modal-dialog modal-lg modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel" style="font-size: 20px;">Add Face Recognition
                        </h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
                    </div>
                    <div class="modal-body">
                        <div class=" container-fluid">
                            <div class="mobile">
                                <h3 class="modal-title" id="exampleModalLabel" style="font-size: 10px;color: red;">
                                    Please capture minimum 3 pic for registration
                                </h3>
                                <button (click)="uploadImageFace()">Take a Photo</button>
                                <br>
                                <table class="table table-hover table-vcenter text-nowrap table-bordered border-bottom"
                                    id="emp-attendance">

                                    <thead class="tablerow">
                                        <tr class="stickyatTop rowcolors">

                                            <!-- <th class="border-bottom-0">Date</th> -->
                                            <!-- <th class="border-bottom-0">Status</th> -->

                                            <th class="border-bottom-0">#</th>
                                            <th class="border-bottom-0">ImageName</th>

                                            <!-- <th class="border-bottom-0">Date</th> -->

                                            <th class="border-bottom-0">Action</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let image of faceregisterdata;let i = index">
                                            <td>{{i+1}}</td>
                                            <td>{{image.imagePath}} </td>
                                            <td><a class="btn btn-danger btn-icon btn-sm me-2"
                                                    data-effect="effect-scale"
                                                    (click)="viewprofilepic(image.imagePath,image.imageName)"
                                                    data-bs-toggle="modal" href="#modaldemo8">
                                                    <i class="si si-eye" data-bs-toggle="tooltip"
                                                        data-original-title="view"></i>
                                                </a>

                                                <button class="btn btn-danger btn-icon btn-sm me-2"
                                                    data-bs-toggle="tooltip" (click)="deleteprofilepic(image)"
                                                    data-original-title="Delete">
                                                    <i class="feather feather-trash-2" data-bs-toggle="tooltip"
                                                        data-original-title="delete"></i>

                                                </button>
                                            </td>



                                        </tr>

                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                            (click)="saveImage()">Save</button>
                        <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- View Profile -->
        <div class="modal fade" id="modaldemo8" data-bs-backdrop="static">
            <div class="modal-dialog modal-sm modal-dialog-centered text-center" role="document">
                <div class="modal-content modal-content-demo">
                    <div class="modal-header">
                        <h6 class="modal-title">View Profile</h6><button aria-label="Close" class="btn-close"
                            data-bs-dismiss="modal"><span aria-hidden="true">&times;</span></button>
                    </div>
                    <div class="modal-body">
                        <img class="avatar avatar-xxl brround rounded-circle" alt="img"
                            [src]="url2 || './../../../assets/template_assests/images/users/1.jpg'">
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-light" data-bs-dismiss="modal" data-bs-toggle="modal"
                            href="#faceRecognitionModal">Close</button>

                    </div>
                </div>
            </div>
            <div class="empty-div-one" style="height: 10px;" id="navigatedest"></div>
        </div>

        <!-- week off -->
        <div class="modal fade" id="fourModal" tabindex="-1" aria-labelledby="fourModalLabel" aria-hidden="true"
            style="display: none;">
            <div class="modal-dialog modal-dialog-scrollable">
                <!-- modal-lg -->
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="fourModalLabel" style="font-size: 20px;">Weekly Off</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
                    </div>
                    <div class="modal-body">
                        <div class="container-fluid ">

                            <table class="table table-hover table-vcenter text-nowrap table-bordered border-bottom"
                                id="hr-table">
                                <thead class="tablerow">
                                    <tr class="stickyatTop rowcolors">
                                        <th></th>
                                        <th>Weekday</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngIf="uidChecker === true ">
                                        <tr *ngFor="let off of WeeklyOffList;let i = index">
                                            <td>
                                                <input type="checkbox" (change)="toggleSelection(off)">
                                            </td>
                                            <td>{{ getDayName(off.weeklyOffDay) }}</td>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngIf="uidChecker === false ">
                                        <tr *ngFor="let off of WeeklyOffList;let i = index">
                                            <td>
                                                <input type="checkbox" [checked]="off.weeklyOffId!==-1"
                                                    (change)="toggleSelection(off)"
                                                    [disabled]="empObj.isedit==='false'">
                                            </td>
                                            <td>{{ getDayName(off.weeklyOffDay) }}</td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>

                        <button *ngIf="uidChecker === false" [hidden]="empObj?.isedit === 'false'" type="button"
                            [ngStyle]="{'display': allowAdminToEdit ? '' : 'none'}" class="btn btn-primary"
                            data-bs-dismiss="modal" (click)="updateWeeklyType()">Update</button>
                        <button *ngIf="uidChecker === false && empObj?.isedit === 'false'" type="button"
                            class="btn btn-primary" data-bs-dismiss="modal">OK</button>
                    </div>

                </div>
            </div>
        </div>

        
        <!--Employee Family details screen-->
       
        <div class="modal fade" id="familyModal" tabindex="-1" aria-labelledby="familyModalLabel" aria-hidden="true"  (hidden.bs.modal)="viewFamilyDetails()">
            <div class="modal-dialog modal-dialog-scrollable modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Family Information</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
                    </div>
                    <div class="modal-body">
                        <div class="alertcomp1">
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="card-content">
                                    <form #familyDetailForm="ngForm">
                                        <section>
                                            <div class="card">
                                                <div class="card-body">
                                                    <div class="row col-md-12 col-sm-12 col-xs-12 px-0 ">
                                                        <div class="col-md-6 col-sm-6 col-xs-6 px-5">
                                                            <div class="form-group label-floating px-0">
                                                                <label class="form-label">Enter Name </label>

                                                                <div>

                                                                    <input type="text" name="Full Name"
                                                                        class="form-control col-md-12 col-xs-12 field_space"
                                                                        autocomplete="off" placeholder="Enter Full Name"
                                                                        autofocus="true" [(ngModel)]="insertFamilyDetailsData.fullname"
                                                                        #FullName="ngModel"
                                                                        [ngClass]="(!FullName.valid && FullName.touched) ? 'is-invalid' : ''"
                                                                        required />

                                                                    <span class="help-text text-danger"
                                                                        *ngIf="(!FullName.valid && FullName.touched)">Enter
                                                                        Full
                                                                        Name</span>

                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div
                                                            class="col-md-6 col-sm-6 col-xs-6 justify-content-between align-items-start px-0">

                                                            <div class="form-group label-floating">
                                                                <label class="form-label">&nbsp;</label>
                                                                <div class="d-flex justify-content-left  w-100">
                                                                    <button *ngIf="addOrUpdate; else updateButton"
                                                                        type="button" class="btn btn-primary w-40" style="margin-right: 3vw;margin-left: 2vw;"
                                                                        id="navigatetarget" (click)="addFamilyMember()">
                                                                        Add
                                                                    </button>
                                                                    <ng-template #updateButton>
                                                                        <button type="button" id="navigatetarget"
                                                                            class="btn btn-primary w-40"
                                                                            style="margin-right: 3vw;margin-left: 2vw;"
                                                                            (click)="updateFamilyMembers()">
                                                                            Update
                                                                        </button>
                                                                    </ng-template>
                                                                    <button type="button" class="btn btn-danger w-40"
                                                                        (click)="viewFamilyDetails()">
                                                                        View
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="clearfix"> </div>
                                                </div>
                                            </div>

                                            <!-- <div class="empty-div-one" style="height: 10px;" id="navigatedest"></div> -->

                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="card" style="min-height: fit-content;" 
                                                        *ngIf="add_view_toggle == 'addFamilyMember' ">
                                                        <div class="card-header border-bottom-0">
                                                            <h3 class="card-title ">Employee Family Details </h3>
                                                        </div>
                                                        <div class="card-body" >

                                                            <div class="panel-group1" id="accordion1">
                                                                <div 
                                                                    class="panel panel-default mb-4 overflow-hidden br-7">
                                                                    <div class="panel-heading1">
                                                                        <h4 class="panel-title1">
                                                                            <a class="accordion-toggle"
                                                                                data-bs-toggle="collapse"
                                                                                data-parent="#accordion"
                                                                                href="#collapseFour"
                                                                                aria-expanded="true">Family Details</a>
                                                                        </h4>
                                                                    </div>
                                                                    <div id="collapseFour"
                                                                        class="panel-collapse  show" role="tabpanel"
                                                                        aria-expanded="false">
                                                                        <div class="panel-body m-0 p-0">
                                                                            <div class="card-body">
                                                                                <div
                                                                                    class="row col-md-12 col-sm-12 col-xs-12">
                                                                                    <div
                                                                                        class="row col-md-12 col-sm-12 col-xs-12">
                                                                                        <div
                                                                                            class="col-md-4 col-sm-3 col-xs-3">
                                                                                            <div
                                                                                                class="form-group label-floating">
                                                                                                <label
                                                                                                    class="form-label">Name
                                                                                                    <span
                                                                                                        style="color: rgb(241, 111, 111);">&nbsp;*</span></label>
                                                                                                <div>
                                                                                                    <input type="text"
                                                                                                        name="FullName"
                                                                                                        class="form-control col-md-12 col-xs-12"
                                                                                                        autocomplete="off"
                                                                                                        placeholder="Enter Full Name"
                                                                                                        autofocus="true"
                                                                                                        [(ngModel)]="insertFamilyDetailsData.fullname"
                                                                                                        #FullName="ngModel"
                                                                                                        [ngClass]="(!FullName.valid && FullName.touched) ? 'is-invalid' : ''"
                                                                                                        required />
                                                                                                </div>
                                                                                                <span
                                                                                                    class="help-text text-danger"
                                                                                                    *ngIf="(!FullName.valid && FullName.touched)">Enter
                                                                                                    Full Name</span>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div
                                                                                            class="col-md-4 col-sm-3 col-xs-3">
                                                                                            <div
                                                                                                class="form-group label-floating">
                                                                                                <label
                                                                                                    class="form-label">Date
                                                                                                    of Birth
                                                                                                    <span
                                                                                                        style="color: rgb(241, 111, 111);">&nbsp;*</span></label>
                                                                                                <div>
                                                                                                    <input type="date"
                                                                                                        name="DOB"
                                                                                                        class="form-control col-md-12 col-xs-12"
                                                                                                        autocomplete="off"
                                                                                                        placeholder="Enter Date of birth"
                                                                                                        [(ngModel)]="insertFamilyDetailsData.dob"
                                                                                                        #DOB="ngModel"
                                                                                                        [ngClass]="(!DOB.valid && DOB.touched) ? 'is-invalid' : ''"
                                                                                                        required />
                                                                                                </div>
                                                                                                <span
                                                                                                    class="help-text text-danger"
                                                                                                    *ngIf="(!DOB.valid && DOB.touched)">Enter
                                                                                                    Date of Birth</span>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div
                                                                                            class="col-md-4 col-sm-3 col-xs-3">
                                                                                            <div
                                                                                                class="form-group label-floating">
                                                                                                <label
                                                                                                    class="form-label">Gender<span
                                                                                                        style="color: rgb(241, 111, 111);">&nbsp;*</span></label>
                                                                                                <div>
                                                                                                    <select
                                                                                                        name="genderCtrl"
                                                                                                        class="form-control col-md-12 col-xs-12"
                                                                                                        [(ngModel)]="insertFamilyDetailsData.gender"
                                                                                                        #genderCtrl="ngModel"
                                                                                                        [ngClass]="(!genderCtrl.valid && genderCtrl.touched) ? 'is-invalid' : ''"
                                                                                                        required>
                                                                                                        <option value=""
                                                                                                            disabled
                                                                                                            selected>
                                                                                                            Select
                                                                                                            Gender
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="Male">
                                                                                                            Male
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="Female">
                                                                                                            Female
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="Others">
                                                                                                            Others
                                                                                                        </option>
                                                                                                    </select>
                                                                                                </div>
                                                                                                <span
                                                                                                    class="help-text text-danger"
                                                                                                    *ngIf="(!genderCtrl.valid && genderCtrl.touched)">
                                                                                                    Please select a
                                                                                                    gender
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>

                                                                                        <!-- <div class="col-md-4 col-sm-3 col-xs-3">
                                                                            <div class="form-group label-floating">
                                                                                <label class="form-label">Relationship with Employee<span style="color: rgb(241, 111, 111);">&nbsp;*</span></label>
                                                                                <div>
                                                                                    <select name="relationship_emp" class="form-control col-md-12 col-xs-12"
                                                                                        [(ngModel)]="member.relationship_emp"
                                                                                        #relationship_emp="ngModel"
                                                                                        [ngClass]="(!relationship_emp.valid && relationship_emp.touched) ? 'is-invalid' : ''">
                                                                                        <option value="" disabled selected>Select Relation</option>
                                                                                        <option value="Parent">Parent</option>
                                                                                        <option value="Spouse">Spouse</option>
                                                                                        <option value="Child">Child</option>
                                                                                        <option value="Sibling">Sibling</option>
                                                                                    </select>
                                                                                </div>
                                                                                <span class="help-text text-danger" *ngIf="(!relationship_emp.valid && relationship_emp.touched)">
                                                                                    Please select relationshipwith employee
                                                                                </span>
                                                                            </div>
                                                                        </div> -->
                                                                                        <div
                                                                                            class="col-md-4 col-sm-3 col-xs-3">
                                                                                            <div
                                                                                                class="form-group label-floating">
                                                                                                <label
                                                                                                    class="form-label">Relationship
                                                                                                    with Employee<span
                                                                                                        style="color: rgb(241, 111, 111);">&nbsp;*</span></label>
                                                                                                <div>
                                                                                                    <select
                                                                                                        name="relationship_emp"
                                                                                                        class="form-control col-md-12 col-xs-12"
                                                                                                        [(ngModel)]="insertFamilyDetailsData.emp_relation"
                                                                                                        #relationship_emp="ngModel"
                                                                                                        [ngClass]="(!relationship_emp.valid && relationship_emp.touched) ? 'is-invalid' : ''"
                                                                                                        required
                                                                                                        (ngModelChange)="onRelationshipChange($event)">
                                                                                                        <option value=""
                                                                                                            disabled>
                                                                                                            Select
                                                                                                            Relation
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="Parent">
                                                                                                            Parent
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="Spouse">
                                                                                                            Spouse
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="Child">
                                                                                                            Child
                                                                                                        </option>
                                                                                                        <option
                                                                                                            value="Sibling">
                                                                                                            Sibling
                                                                                                        </option>
                                                                                                    </select>
                                                                                                </div>
                                                                                                <span
                                                                                                    class="help-text text-danger"
                                                                                                    *ngIf="(!relationship_emp.valid && relationship_emp.touched)">
                                                                                                    Please select
                                                                                                    relationship with
                                                                                                    employee
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>



                                                                                        <div
                                                                                            class="col-md-4 col-sm-3 col-xs-3">
                                                                                            <div
                                                                                                class="form-group label-floating">
                                                                                                <label
                                                                                                    class="form-label">
                                                                                                    Mobile Number <span
                                                                                                        style="color: rgb(241, 111, 111);">&nbsp;*</span>

                                                                                                </label>
                                                                                                <div>
                                                                                                    <input type="tel"
                                                                                                        name="mobileNumber"
                                                                                                        class="form-control col-md-12 col-xs-12"
                                                                                                        autocomplete="off"
                                                                                                        placeholder="Enter Mobile Number"
                                                                                                        pattern="[0-9]{10}"
                                                                                                        maxlength="10"
                                                                                                        [(ngModel)]="insertFamilyDetailsData.mobile_number"
                                                                                                        #mobileNumberCtrl="ngModel"
                                                                                                        (input)="filterNumericInputForMobile_Number($event)"
                                                                                                        [ngClass]="(!mobileNumberCtrl.valid && mobileNumberCtrl.touched) ? 'is-invalid' : ''"
                                                                                                        required />
                                                                                                </div>
                                                                                                <span
                                                                                                    class="help-text text-danger"
                                                                                                    *ngIf="(!mobileNumberCtrl.valid && mobileNumberCtrl.touched)">
                                                                                                    Please enter a valid
                                                                                                    10-digit mobile
                                                                                                    number
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>


                                                                                        <!-- <div class="col-md-4 col-sm-3 col-xs-3">
                                                                        <div class="form-group label-floating">
                                                                            <label class="form-label">
                                                                                Dependent <span style="color: rgb(241, 111, 111);">&nbsp;*</span>
                                                                            </label>
                                                                            <div class="form-check form-switch">
                                                                                <input class="form-check-input" type="checkbox" id="dependentToggle"
                                                                                    [(ngModel)]="member.dependent"
                                                                                    [checked]="member.dependent === 'Yes'"
                                                                                    (change)="member.dependent = $event.target.checked ? 'Yes' : 'No'">
                                                                                <label class="form-check-label" for="dependentToggle">
                                                                                    {{ member.dependent === 'Yes' ? 'Yes' : 'No' }}
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        </div> -->

                                                                                        <div
                                                                                            class="col-md-4 col-sm-3 col-xs-3">
                                                                                            <label
                                                                                            class="form-label">
                                                                                           Dependent <span
                                                                                                style="color: rgb(241, 111, 111);">&nbsp;*</span></label>

                                                                                            <div
                                                                                                class="mobileview_toggle">
                                                                                                <div class="col-md-12 col-xs-12">
                                                                                                <!-- Dependent Toggle -->
                                                                                                <label class="custom-switch">No</label>
                                                                                                <label class="custom-switch">
                                                                                                    <input type="checkbox" name="custom-switch-dependent"
                                                                                                        class="custom-switch-input"
                                                                                                        [(ngModel)]="insertFamilyDetailsData.dependent"
                                                                                                        >
                                                                                                    <span class="custom-switch-indicator"></span>
                                                                                                </label> 
                                                                                                <label class="custom-switch">Yes</label>
                                                                                            </div>
                                                                                            </div>
                                                                                        </div>
                                                                                     

                                                                                        
                                                                                        <div
                                                                                            class="col-md-4 col-sm-3 col-xs-3">
                                                                                            <div
                                                                                                class="form-group label-floating">
                                                                                                <label
                                                                                                    class="form-label">
                                                                                                    Address <span
                                                                                                        style="color: rgb(241, 111, 111);">&nbsp;*</span>

                                                                                                </label>
                                                                                                <div >
                                                                                                    <textarea
                                                                                                    
                                                                                                        name="address"
                                                                                                        class="form-control col-md-12 col-xs-12"
                                                                                                        autocomplete="off"
                                                                                                        placeholder="Enter Address"
                                                                                                        [(ngModel)]="insertFamilyDetailsData.address"
                                                                                                        #addressCtrl="ngModel"
                                                                                                        style="min-height: 38px; height: 38px; resize: vertical; overflow-y: auto;"
                                                                                                        
                                                                                                        [ngClass]="(!addressCtrl.valid && addressCtrl.touched) ? 'is-invalid' : ''"
                                                                                                        required>
                                                                                </textarea>
                                                                                                </div>
                                                                                                <span
                                                                                                    class="help-text text-danger"
                                                                                                    *ngIf="(!addressCtrl.valid && addressCtrl.touched)">
                                                                                                    Please enter your
                                                                                                    address
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div
                                                                                            class="col-md-4 col-sm-3 col-xs-3">
                                                                                            <div
                                                                                                class="form-group label-floating">
                                                                                                <label
                                                                                                    class="form-label">
                                                                                                    Aadhaar Number
                                                                                                </label>
                                                                                                <div>
                                                                                                    <input type="text"
                                                                                                        name="aadhaarNumber"
                                                                                                        class="form-control col-md-12 col-xs-12"
                                                                                                        autocomplete="off"
                                                                                                        placeholder="Enter Aadhaar Number"
                                                                                                        pattern="[0-9]{12}"
                                                                                                        maxlength="12"
                                                                                                        [(ngModel)]="insertFamilyDetailsData.aadhaar_number"
                                                                                                        #aadhaarNumberCtrl="ngModel"
                                                                                                        (input)="filterNumericInputForAadharNum($event)"
                                                                                                        [ngClass]="(!aadhaarNumberCtrl.valid && aadhaarNumberCtrl.touched) ? 'is-invalid' : ''" />
                                                                                                </div>
                                                                                                <span
                                                                                                    class="help-text text-danger"
                                                                                                    *ngIf="(!aadhaarNumberCtrl.valid && aadhaarNumberCtrl.touched)">
                                                                                                    Please enter a valid
                                                                                                    12-digit Aadhaar
                                                                                                    number
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div
                                                                                            class="col-md-4 col-sm-3 col-xs-3">
                                                                                            <div
                                                                                                class="form-group label-floating">
                                                                                                <label
                                                                                                    class="form-label">
                                                                                                    PAN Card Number
                                                                                                </label>
                                                                                                <div>
                                                                                                    <input type="text"
                                                                                                        name="panCardNumber"
                                                                                                        class="form-control col-md-12 col-xs-12"
                                                                                                        autocomplete="off"
                                                                                                        placeholder="Enter PAN Card Number"
                                                                                                        pattern="[A-Z]{5}[0-9]{4}[A-Z]{1}"
                                                                                                        maxlength="10"
                                                                                                        [(ngModel)]="insertFamilyDetailsData.pan_number"
                                                                                                        #panCardCtrl="ngModel"
                                                                                                        (input)="filterPanInput($event)"
                                                                                                        [ngClass]="(!panCardCtrl.valid && panCardCtrl.touched) ? 'is-invalid' : ''" />
                                                                                                </div>
                                                                                                <span
                                                                                                    class="help-text text-danger"
                                                                                                    *ngIf="(!panCardCtrl.valid && panCardCtrl.touched)">
                                                                                                    Please enter a valid
                                                                                                    PAN card number
                                                                                                    (e.g., ABCDE1234F).
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>



                                                                                        <!-- <div class="col-md-5 col-sm-3 col-xs-3">
                                                                            <div class="form-group label-floating">
                                                                                <label class="form-label">
                                                                                    Nominee <span style="color: rgb(241, 111, 111);">&nbsp;*</span>
                                                                                </label>
                                                                                <div class="form-check form-switch">
                                                                                    <input class="form-check-input" type="checkbox" id="nomineeToggle"
                                                                                        [(ngModel)]="member.nominee"
                                                                                        [checked]="member.nominee === 'True'"
                                                                                        (change)="member.nominee = $event.target.checked ? 'True' : 'False'">
                                                                                    <label class="form-check-label" for="nomineeToggle">
                                                                                        {{ member.nominee === 'True' ? 'True' : 'False' }}
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            </div> -->

                                                                                        <!-- Nominee Toggle -->
                                                                                        <!-- <div
                                                                                            class="col-md-5 col-sm-3 col-xs-3 pb-2">
                                                                                            
                                                                                                <label
                                                                                                    class="form-label">
                                                                                                    Nominee -->
                                                                                                    <!-- <span style="color: rgb(241, 111, 111);">&nbsp;*</span> -->
                                                                                                <!-- </label>
                                                                                                <div
                                                                                                    class="custom-switch">
                                                                                                    <label class="custom-switch">No</label>
                                                                                                <label class="custom-switch">
                                                                                                    <input type="checkbox" name="custom-switch-nominee"
                                                                                                        class="custom-switch-input"
                                                                                                        [(ngModel)]="insertFamilyDetailsData.nominee"
                                                                                                        >
                                                                                                    <span class="custom-switch-indicator"></span>
                                                                                                </label> 
                                                                                                <label class="custom-switch">Yes</label>
                                                                                               
                                                                                            </div>
                                                                                        </div> -->

                                                                                        <div class="col-md-5 col-sm-3 col-xs-3 pb-2">
                                                                                            <label class="form-label">
                                                                                                Nominee
                                                                                                <!-- <span style="color: rgb(241, 111, 111);">&nbsp;*</span> -->
                                                                                            </label>
                                                                                            <div class="custom-switch">
                                                                                                <label class="custom-switch">No</label>
                                                                                                <label class="custom-switch">
                                                                                                    <input type="checkbox" name="custom-switch-nominee"
                                                                                                        class="custom-switch-input"
                                                                                                        [(ngModel)]="insertFamilyDetailsData.nominee"
                                                                                                        (change)="onNomineeChange()">
                                                                                                    <span class="custom-switch-indicator"></span>
                                                                                                </label> 
                                                                                                <label class="custom-switch">Yes</label>
                                                                                            </div>
                                                                                        </div>
                                                                                        
                                                                                        <!-- Show Bank Details Only If Nomination is "Yes" -->

                                                                                        <div
                                                                                            class="row col-md-12 col-sm-12 col-xs-12">
                                                                                            <ng-container
                                                                                                *ngIf="insertFamilyDetailsData.nominee">
                                                                                                <div
                                                                                                class="col-md-4 col-sm-3 col-xs-3">
                                                                                                <div
                                                                                                    class="form-group label-floating">
                                                                                                    <label
                                                                                                        class="form-label">Bank
                                                                                                        Name <span
                                                                                                            style="color: rgb(241, 111, 111);">&nbsp;*</span></label>
                                                                                                    <div>
                                                                                                        <input
                                                                                                            type="text"
                                                                                                            name="BankName"
                                                                                                            class="form-control col-md-12 col-xs-12"
                                                                                                            autocomplete="off"
                                                                                                            placeholder="Enter Bank Name"
                                                                                                            autofocus="true"
                                                                                                            [(ngModel)]="insertFamilyDetailsData.bank_name"
                                                                                                            #BankName="ngModel"
                                                                                                            required
                                                                                                            [ngClass]="{'is-invalid': BankName?.invalid && BankName?.touched}" />
                                                                                                    </div>
                                                                                                    <span
                                                                                                        class="help-text text-danger"
                                                                                                        *ngIf="BankName.invalid && BankName.touched">
                                                                                                        Bank Name is
                                                                                                        required
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>


                                                                                            <div
                                                                                                class="col-md-4 col-sm-3 col-xs-3">
                                                                                                <div
                                                                                                    class="form-group label-floating">
                                                                                                    <label
                                                                                                        class="form-label">IFSC
                                                                                                        Code <span
                                                                                                            style="color: rgb(241, 111, 111);">&nbsp;*</span></label>
                                                                                                    <div>
                                                                                                        <input
                                                                                                            type="text"
                                                                                                            name="IFSCCode"
                                                                                                            class="form-control col-md-12 col-xs-12"
                                                                                                            autocomplete="off"
                                                                                                            placeholder="Enter IFSC Code"
                                                                                                            autofocus="true"
                                                                                                            [(ngModel)]="insertFamilyDetailsData.ifsc_code"
                                                                                                            #IFSCCode="ngModel" maxlength="11"
                                                                                                            (input)="filterIFSCInput($event)"
                                                                                                            required
                                                                                                            pattern="^[A-Z]{4}0[A-Z0-9]{6}$"
                                                                                                            [ngClass]="{'is-invalid': IFSCCode?.invalid && IFSCCode?.touched}" />
                                                                                                    </div>
                                                                                                    <span
                                                                                                        class="help-text text-danger"
                                                                                                        *ngIf="IFSCCode.invalid && IFSCCode.touched">
                                                                                                        Enter a
                                                                                                        valid IFSC
                                                                                                        Code (e.g.,
                                                                                                        ABCD0123456)
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>


                                                                                            <div
                                                                                                class="col-md-4 col-sm-3 col-xs-3">
                                                                                                <div
                                                                                                    class="form-group label-floating">
                                                                                                    <label
                                                                                                        class="form-label">Account
                                                                                                        Number <span
                                                                                                            style="color: red;">*</span></label>
                                                                                                    <div>
                                                                                                        <input
                                                                                                            type="text"
                                                                                                            name="AccountNumber"
                                                                                                            class="form-control col-md-12 col-xs-12"
                                                                                                            autocomplete="off"
                                                                                                            placeholder="Enter Account Number"
                                                                                                            [(ngModel)]="insertFamilyDetailsData.account_no"
                                                                                                            #accountNo="ngModel"  maxlength="18"
                                                                                                            required
                                                                                                            pattern="^[0-9]{9,18}$"
                                                                                                            (input)="filterNumericInput($event)"
                                                                                                            [ngClass]="{'is-invalid': accountNo?.invalid && accountNo?.touched}" />
                                                                                                    </div>
                                                                                                    <span
                                                                                                        class="text-danger"
                                                                                                        *ngIf="accountNo.invalid && accountNo.touched">
                                                                                                        Valid
                                                                                                        Account
                                                                                                        Number (9-18
                                                                                                        digits) is
                                                                                                        required.
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>


                                                                                            <div
                                                                                                class="col-md-4 col-sm-3 col-xs-3">
                                                                                                <div
                                                                                                    class="form-group label-floating">
                                                                                                    <label
                                                                                                        class="form-label">Account
                                                                                                        Type <span
                                                                                                            style="color: red;">*</span></label>
                                                                                                    <div>
                                                                                                        <select
                                                                                                            class="form-control col-md-12 col-xs-12"
                                                                                                            [(ngModel)]="insertFamilyDetailsData.account_type"
                                                                                                            #accountType="ngModel"
                                                                                                            required
                                                                                                            [ngClass]="{'is-invalid': accountType?.invalid && accountType?.touched}">
                                                                                                            <option
                                                                                                                value=""
                                                                                                                disabled
                                                                                                                selected>
                                                                                                                Select
                                                                                                                Account
                                                                                                                Type
                                                                                                            </option>
                                                                                                            <option
                                                                                                                value="Savings">
                                                                                                                Savings
                                                                                                            </option>
                                                                                                            <option
                                                                                                                value="Current">
                                                                                                                Current
                                                                                                            </option>
                                                                                                        </select>
                                                                                                    </div>
                                                                                                    <span
                                                                                                        class="text-danger"
                                                                                                        *ngIf="accountType.invalid && accountType.touched">
                                                                                                        Account Type
                                                                                                        is required.
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>

                                                                                            <!-- <div class="col-md-4 col-sm-3 col-xs-3">
                                                                    <label class="form-label">Nomination Percentage</label>
                                                                    <input type="number" class="form-control" [(ngModel)]="member.Nomination_percentage" min="1" max="100"/>
                                                                </div> -->


                                                                                            <div
                                                                                                class="col-md-4 col-sm-3 col-xs-3">
                                                                                                <label
                                                                                                    class="form-label">Nomination
                                                                                                    Percentage</label>
                                                                                                    <input type="number"
                                                                                                    class="form-control"
                                                                                                    name="Nomination_Percentage"
                                                                                                    #Nomination_Percentage="ngModel"
                                                                                                    [(ngModel)]="insertFamilyDetailsData.nomination_percentage"
                                                                                                      [ngClass]="{'is-invalid': Nomination_Percentage?.invalid && (insertFamilyDetailsData.nomination_percentage > 100 || insertFamilyDetailsData.nomination_percentage < 0)}"
                                                                                                    (input)="validateNominationPercentage()"
                                                                                                    min="0"
                                                                                                    max="100" 
                                                                                                    onkeydown="return event.keyCode !== 189 && event.keyCode !== 187 && event.keyCode !== 69" />                                                                                               
                                                                                                                                                                                                <span
                                                                                                    class="text-danger"
                                                                                                    *ngIf="nominationError ">
                                                                                                    {{ nominationError }}

                                                                                                </span>
                                                                                            </div>


                                                                                            </ng-container>
                                                                                        </div>
                                        
                                                                                    </div>



                                                                                </div>
                                                                                <div class="clearfix"> </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>



                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>




                                            </div>



                                            <div class="actionBar pe-4 ps-4" *ngIf="addOrUpdate && add_view_toggle === 'addFamilyMember'">
                                                <div class="clearfix"> </div>
                                                <button class="btn btn-success" type="submit" (click)="submitFamilyDetails()">Save</button>
                                                <button class="btn btn-danger ms-2" type="button" (click)="clearDetails()">Clear</button>
                                            </div>


                                           


                                            <div class="row card-list-margin">
                                                <div class="col-md-12 card-list1-margin">
                                                    <div class="card col-md-12 col-sm-12 col-xs-12"
                                                        *ngIf="add_view_toggle == 'viewFamilyDetails' ">
                                                        <div class="card-header border-bottom-0">
                                                            <h3 class="card-title">List Of Family Details</h3>
                                                        </div>
                                                        <div class="card-body card1S p-0">
                                                            <div class="table-responsive">
                                                                <div class="scrollsearchresults" id="scroll"
                                                                    infiniteScroll [infiniteScrollDistance]="2"
                                                                    [infiniteScrollUpDistance]="1.5"
                                                                    [infiniteScrollThrottle]="100"
                                                                    [scrollWindow]="false" id="scrolllength">
                                                                    <table
                                                                        class="table table-hover table-vcenter text-nowrap table-bordered border-bottom"
                                                                        id="hr-table">
                                                                        <thead class="tablerow">
                                                                            <tr class="rowcolors stickyatTop">
                                                                                <th class="border-bottom-0 w-5 fs-6 ">
                                                                                    Name</th>
                                                                                <th class="border-bottom-0 w-5 fs-6 ">
                                                                                    Relationship with Employee</th>
                                                                                <th class="border-bottom-0 w-5 fs-6 ">
                                                                                    Date of Birth</th>
                                                                                <th class="border-bottom-0 w-5 fs-6 ">
                                                                                        Nomination Percentage</th>
                                                                                <th class="border-bottom-0 w-5 fs-6 ">
                                                                                    Action</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody
                                                                            style="max-height: 50vh; overflow: scroll;">
                                                                            <tr
                                                                                *ngFor="let member of familyMembers; let i = index">
                                                                                <td>{{member.fullname}}</td>
                                                                                <td>{{member.emp_relation}}</td>
                                                                                <td>{{member.dob | date: 'mediumDate'}}</td>
                                                                                <td>{{member.nomination_percentage > 0 ? member.nomination_percentage : '' }}</td>
                                                                                <td>
                                                                                    <button
                                                                                        class="btn btn-primary btn-icon btn-sm me-2"
                                                                                        data-method="edit" title="Edit"
                                                                                        (click)="editFamilyDetails(member.id, member)">
                                                                                        <i class="fa fa-edit"
                                                                                            data-bs-toggle="tooltip"
                                                                                            data-original-title="edit"></i>
                                                                                    </button>
                                                                                    <button
                                                                                        class="btn btn-danger btn-icon btn-sm me-2"
                                                                                        data-method="delete"
                                                                                        title="Delete"
                                                                                        (click)="onDeleteHandler(member.id)"
                                                                                        data-bs-target="#showAlertWarning">
                                                                                        <i class="fa fa-trash"
                                                                                            data-bs-toggle="tooltip"
                                                                                            data-original-title="delete"></i>
                                                                                    </button>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>

                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                        </section>

                                    </form>
                                </div>
                            </div>
                        </div>

                        <!-- make change here  -->
                    </div>

                    <div class="modal-footer">
                        <!-- <button *ngIf="add_view_toggle === 'addFamilyMember'" class="btn btn-success"
                            type="submit"
                             (click)="submitFamilyDetails()">Save</button> -->
                        <button *ngIf="add_view_toggle === 'viewFamilyDetails'"  type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
        <div id="showAlertVer" class="modal" style="top: 40px;">
            <div class="modal-dialog">
                <div class="modal-content animate">
                    <div class="modal-header">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">×</button>
                        <h4 class="modal-title">Warning</h4>
                    </div>

                    <div class="modal-body" style="max-height: calc(100vh - 210px);overflow-y: auto;">
                        <!-- auto scroll -->
                        <span><b> FamilyDetail data will be deleted permanently. Are you sure you want to
                                continue?</b></span>
                    </div>

                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger medium" (click)=deleteFamilyDetails()>OK</button>
                        <button type="button" class="btn btn-primary" (click)=closeMRQModal()>Cancel</button>
                    </div>
                </div>
            </div>
        </div>