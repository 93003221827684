import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { EmployeeAttendanceSummary } from 'src/app/models/EmployeeAttendanceSummary';
import { EmployeeMaster } from 'src/app/models/employeeMasterts';
import { AttendanceService } from 'src/app/services/attendance.service';
import { EmployeeLeaveService } from 'src/app/services/employee-leave.service';
import { DocumentInfoService } from 'src/app/services/documentinfo.service';
declare var $: any;

@Component({
  selector: 'app-consolidated-report',
  templateUrl: './consolidated-report.component.html',
  styleUrls: ['./consolidated-report.component.css']
})
export class ConsolidatedReportComponent implements OnInit {
  query: any;
  currentDate: string;
  // uempid: number=2;
  // fromdate: string;
  // todate: string;
  reportType: string = "Consolidated Report";
  nowDetailedReport: boolean = false;
  leaveTypeMap = new Map();
  employeeMaster: EmployeeMaster[];
  client_id: EmployeeMaster[];
  uempid: string = "";
  consolidatedReport1: EmployeeMaster[];
  detailedConsolidatedReport: EmployeeMaster[];

  detailedReport: EmployeeAttendanceSummary[];
  detailReportInConsolidateReport: EmployeeAttendanceSummary[];

  contractstream$!: Subscription;
  receivedData: any;
  startDate: boolean;
  endDate: boolean;
  submitted: boolean;
  popname: any;
  selectedRemarks:any[];
  constructor(private _snackBar: MatSnackBar, private empLeaveService: EmployeeLeaveService, private attendanceService: AttendanceService, private documentInfoService: DocumentInfoService) {

  }


  // const currentDate = new Date();
  //   this.currentDate = this.fromDate;

  @Input() fromDate: string;
  @Input() ActiveInativeLuceneFilter: string;
  @Input() toDate: string;
  @Input() shift_setting: string;
  @Input() consolidatedReport: EmployeeMaster[] = [];
  @Input() branch: any;
  @Input() selectedempid: any;
  @Input() keyinempid: any;
limitPara: any;
offsetPara: any;



  ngOnInit(): void {

    this.limitPara = 15;
    this.offsetPara = 0;
  }
  search() {
    // this.offsetPara = 0;

  }

  onScroll() {
    this.offsetPara=  this.offsetPara + 15;
    // console.log("offset=============>",this.offsetPara)
    this.consolidate_report();
  }

  consolidate_report() {
 
 
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const day = currentDate.getDate();
    this.currentDate = `${year}-${month}-${day}`;
    var id = null;

    this.attendanceService.getAttendenceReport(this.reportType, this.fromDate, this.toDate, this.currentDate, this.ActiveInativeLuceneFilter, id, this.shift_setting, this.branch, this.limitPara, this.offsetPara).subscribe(
      data => {
          if(this.offsetPara == 0){
            this.consolidatedReport = data.attendanceDetailsConsolidate;
            console.log("1) Consolidate Report Here +++++>  : ", this.consolidatedReport)
          }else{
            this.consolidatedReport = this.consolidatedReport.concat(data.attendanceDetailsConsolidate);
            console.log("2) Consolidate Report Here +++++>  : ", this.consolidatedReport)
          }
      },
      error => {
        console.log(error);
      }
    );

    // console.log("METHOD DIDNT GET EXECUTED");
  }

  consolidate_to_deatiled(id, name, i , remarks?:any) {
    this.popname = name;
    console.log(remarks)
    this.selectedRemarks = remarks;
    this.currentDate = this.fromDate;
    // console.log(" selected  id   : ", id, " index : ", i);
    this.reportType = "Detailed Report";
    // console.log("Report Type", this.reportType)


    // console.log("passing value  from date 2 ", this.fromDate, "  todate ", this.toDate, " currentdate ", this.currentDate)
    // console.log(" report typee ", this.reportType, " id ", id, " shift : ")
    this.contractstream$ = this.attendanceService.getAttendenceReport(this.reportType, this.fromDate, this.toDate, this.currentDate,this.ActiveInativeLuceneFilter, id, this.shift_setting, this.branch, this.limitPara, this.offsetPara).subscribe(
      data => {
        console.log("Attedance report parent  : ", data)
        if (this.reportType === "Detailed Report") {
          console.log("selectd is type detailed true child :", this.reportType)
           this.detailedReport = data.attendanceDetails[0].attendanceList;
          // for (let i = 0; i < data.attendanceDetails.length; i++) {
          //   this.detailedReport = this.detailedReport.concat(data.attendanceDetails[i].attendanceList);
            
          // }
          this.detailedConsolidatedReport = data.attendanceDetailsConsolidate;
          console.log("check this : ", this.detailedReport, " ", this.detailedConsolidatedReport)
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  attendanceReportCSVDownload(reporttype: string) {
    // console.log(`Unique Emp Id: ${this.uniqueEmployeeId}, Year: ${year}, Month: ${month}`)
    console.log("consolidate report value from  parent ")
    console.log("1: ", this.fromDate, this.toDate, this.shift_setting, this.branch, this.selectedempid ? this.selectedempid : sessionStorage.getItem('id'))

    console.log("2: ", this.fromDate, this.toDate, this.shift_setting, this.branch, this.keyinempid)
    const listingFilters="GBB";
    this.documentInfoService.downloadAttendanceReportCSVFile(reporttype, this.keyinempid ? this.keyinempid : null, this.fromDate, this.toDate, this.currentDate,this.ActiveInativeLuceneFilter, null, this.branch ,listingFilters).subscribe(data => {
      // console.log(data)
      this.keyinempid = null;
      var file = new Blob([data], { type: 'application/vnd.ms-excel'});
      var fileURL = URL.createObjectURL(file);
      var anchor = document.createElement("a");
      anchor.download = "ConsolidatedAttendanceReport.xls";
      anchor.href = fileURL;
      anchor.click();
      //window.open(fileURL);
    },
      error => {
        console.log("Error Occured ", error);
        // console.log("Error Code ",error.status);
      })
  }

}
