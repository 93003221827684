export class Employeesuspensioninfo {
    id: number;
    uniqueEmployeeId: number;
    fromdate: string;
    todate: string;
    reason: string;
    status: string;
    rejcomments: string;
    }

    export class SuspenseAuthority {

        selectedJobLevel: any = 'GTE';
        jobRole: number;
        selectedDepartment: any = 'DEP';
        otherDepartment: any;
    }