<div class="app-content main-content">
    <div class="side-app main-container">

        <div class="alertcomp"></div>

        <!-- <div class="page-leftheader" style="margin-bottom: 0.75rem">
            <div class="page-title">Project Allocation</div>
        </div> -->

        <div class="col-xl-12 col-md-12 col-lg-12" style="padding: 0; margin-bottom: 30px;">
            <div class="card">

                <div class="card-body row justify-content-between">
                    <div class="col-xs-11 col-md-11 col-sm-10 col-10" id="serachClient">

                        <div class="my-3">


                            <select id="employeeMasterLucene" class="form-control" name="Employee Name"
                                id="employeename" placeholder="Search Business Name" autocomplete="on"
                                list="employeeMasterLucene" autofocus="autofocus"
                                [(ngModel)]="contractClientDesInfo.business_name"
                                (ngModelChange)="getClientName($event)">
                                <option data-value={{emp.business_name}} *ngFor="let emp of contractClientData">
                                    {{emp.business_name}}
                                </option>
                            </select>

                        </div>
                    </div>

                    <div class="col-xs-1 col-md-1 col-sm-2 col-2 my-3">
                        <button type="button" class="btn btn-primary w-100" (click)="getClientProData()">
                            <i class="fa fa-search" aria-hidden="true"></i>
                        </button>
                    </div>

                    <div class="col-md-3 col-sm-10 col-xs-10 justify-content-between d-none"
                        id="searchProjectBaseClient">
                        <select class="form-control my-3" data-style="btn btn-drpdwn btn-round"
                            name="project_location_id" placeholder="Select Location"
                            [(ngModel)]="contractClientProDesActData.project_id">

                            <option [ngValue]="undefined" [disabled]="true" [hidden]="true">Select Project</option>
                            <option value={{data.id}} *ngFor="let data of contractClientProInfoData">
                                {{data.projectname}}
                            </option>
                        </select>
                    </div>

                    <div class="col-md-2 col-sm-2 col-xs-2 d-none justify-content-between my-3"
                        id="btnProjectBaseClient">
                        <button class="btn btn-primary btn-sm w-45" (click)="getContractClientProLocSerarch()">
                            <i class="fa fa-chevron-right" data-bs-toggle="tooltip" data-original-title="edit"></i>
                        </button>

                        <button class="btn btn-primary btn-sm w-45" (click)="getContractClientProLocView()">
                            <i class="fa fa-th-list" data-bs-toggle="tooltip" data-original-title="edit"
                                id="showProjectDataList"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="row d-none" *ngIf="contractClientProInfoData.length" id="projectDataList">
            <div class="col-xl-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="card-header  border-0">
                        <h4 class="card-title">Contract Client Project Details: {{selectedBusiness}}</h4>
                    </div>
                    <div class="card-body">

                        <form id="contractclientsList" name="contractclientsList" type="multipart/form-data">
                            <div class="col-xs-12 mobile">

                                <div class="scrollsearchresults">
                                    <table class="table  table-vcenter text-nowrap table-bordered border-bottom"
                                        id="hr-table">
                                        <thead class="tablerow">
                                            <tr class="rowcolors">
                                                <th class="border-bottom-0 w-5 fs-6">Project Name</th>
                                                <th class="border-bottom-0 w-5 fs-6">Start Date</th>
                                                <th class="border-bottom-0 w-5 fs-6">End Date</th>
                                                <th class="border-bottom-0 w-5 fs-6">Remarks</th>
                                                <th class="border-bottom-0 w-5 fs-6">Estimation</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let contractclient of contractClientProInfoData">
                                                <td data-th="contractclientName">{{contractclient.projectname}}
                                                </td>
                                                <td data-th="contractclientName">{{contractclient.start_date |
                                                    date:'MM/dd/yyyy'}}
                                                </td>
                                                <td data-th="contractclientName">{{contractclient.end_date |
                                                    date:'MM/dd/yyyy'}}
                                                </td>
                                                <td data-th="contractclientName">{{contractclient.remarks}}
                                                </td>

                                                <td>
                                                    <button class="btn btn-primary btn-icon btn-sm me-2"
                                                        (click)="getContractClientProLoc(contractclient)"
                                                        data-method="edit" title="Edit" id="navigateclientLocTwo">
                                                        <i class="fa fa-chevron-right" data-bs-toggle="tooltip"
                                                            data-original-title="edit"></i>
                                                    </button>

                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>

                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="contractClientProDesEstDataList.length">
            <div class="col-xl-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="card-header border-0 py-3 d-flex justify-content-between" id="showTable">
                        <h4 class="card-title"><span *ngIf="!showBudgetingList">View </span>Budgeting details</h4>
                        <button class="btn btn-primary btn-icon btn-sm" (click)="showBudgetingTable()" title="Show">
                            <i class="fa fa-th-list" id="showBudgetingDataList"></i>
                        </button>
                    </div>
                    <div class="card-body" *ngIf="showBudgetingList">


                        <form id="contractclientsList" name="contractclientsList" type="multipart/form-data">
                            <div class="col-xs-12 mobile">

                                <div class="scrollsearchresults">
                                    <table class="table  table-vcenter text-nowrap table-bordered border-bottom"
                                        id="hr-table">
                                        <thead class="tablerow">
                                            <tr class="rowcolors">
                                                <th class="border-bottom-0 w-5 fs-6">Location</th>
                                                <th class="border-bottom-0 w-5 fs-6">Designation</th>
                                                <th class="border-bottom-0 w-5 fs-6">Count</th>
                                                <th class="border-bottom-0 w-5 fs-6">Billing Rate</th>
                                                <th class="border-bottom-0 w-5 fs-6">Start Date</th>
                                                <th class="border-bottom-0 w-5 fs-6">End Date</th>
                                                <th class="border-bottom-0 w-5 fs-6">UOM</th>
                                                <th class="border-bottom-0 w-5 fs-6">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let data of contractClientProDesEstDataList">
                                                <td data-th="contractclientName">{{data.location_name}}
                                                </td>
                                                <td data-th="contractclientName">{{data.designation_name}}
                                                </td>
                                                <td data-th="contractclientName">{{data.counts}}
                                                </td>
                                                <td data-th="contractclientName">{{data.billing_rate}}
                                                </td>
                                                <td data-th="contractclientName">{{data.start_date | date: 'mediumDate'}}
                                                </td>
                                                <td data-th="contractclientName">{{data.end_date | date: 'mediumDate'}}
                                                </td>
                                                <td data-th="contractclientName">{{data.uom}}
                                                </td>

                                                <td>
                                                    <button class="btn btn-primary btn-icon btn-sm me-2"
                                                        (click)="populateInputActual(data); getEmpListByDes(data.designation_id)"
                                                        data-method="edit" title="Edit" id="navigateclientLocTwo">
                                                        Populate
                                                        <!-- Next&nbsp;
                                                        <i class="fa fa-chevron-right" data-bs-toggle="tooltip"
                                                            data-original-title="edit"></i> -->
                                                    </button>
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>

                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>

        <div class="page-leftheader" style="margin-bottom: 0.75rem">
            <div class="page-title">Allocation <span *ngIf="cllientBusName">for {{cllientBusName}}</span></div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-content disable-div" id="add-des-act">

                        <form id="conCliForm" (ngSubmit)="contractClientDesActForm.form.valid"
                            name="addContractClientDesEstForm" #contractClientDesActForm="ngForm" as
                            ContractclientDocument novalidate>
                            <section>
                                <div class="card-body">

                                    <div class="col-md-12 col-sm-12 col-xs-12 pe-4 ps-4">
                                        <div class="form-group label-floating pt-1">
                                            <label class="form-label">Location</label>

                                            <div>
                                                <!-- <input type="text" class="form-control col-md-12 col-xs-12 disable-div"
                                                    autocomplete="off" placeholder="Location Name" autofocus="true"
                                                    name="location_name" #location_name="ngModel"
                                                    [(ngModel)]="contractClientProDesActData.location_name" required /> -->

                                                <select class="form-control" data-style="btn btn-drpdwn btn-round"
                                                    name="project_location_id" placeholder="Select Location"
                                                    #project_location_id="ngModel"
                                                    [(ngModel)]="contractClientProDesActData.project_location_id"
                                                    (ngModelChange)="getLocdataToSee($event)" required>

                                                    <option [ngValue]="undefined" [disabled]="true" [hidden]="true">
                                                        Select Location</option>
                                                    <option [ngValue]="data.id"
                                                        *ngFor="let data of contractClientProLocList">
                                                        {{data.location_name}}
                                                    </option>

                                                </select>

                                                <p *ngIf="submitted && !project_location_id.valid" class="help-block "
                                                    style="color: #ff5757;">
                                                    ⓘ Location name is required
                                                </p>

                                                <!-- <p *ngIf="notInBudgeting && project_location_id.valid"
                                                    class="help-block ">
                                                    <span style="color: #dafc1b;">ⓘ </span>Location is not present in
                                                    budgeting
                                                </p> -->
                                            </div>

                                        </div>
                                    </div>

                                    <div class="clearfix"></div>

                                    <div class="card-body row">
                                        <div class="col-md-6 col-sm-12 col-xs-12 pe-4 ps-4">
                                            <div class="form-group label-floating">
                                                <label class="form-label">Designation</label>
                                                <div>
                                                    <select class="form-control" data-style="btn btn-drpdwn btn-round"
                                                        id="dGroup" name="designationID" #designationID="ngModel"
                                                        [(ngModel)]="contractClientProDesActData.designationID"
                                                        (ngModelChange)="getEmployeeList($event)" required>

                                                        <option [ngValue]="undefined" [disabled]="true" [hidden]="true">
                                                            Select Designation</option>
                                                        <option [ngValue]="data.designationid"
                                                            *ngFor="let data of empDesList">
                                                            {{data.name}}
                                                        </option>

                                                    </select>

                                                    <p *ngIf="submitted && !designationID.valid" class="help-block "
                                                        style="color: #ff5757;">
                                                        ⓘ Designation is required
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-6 col-sm-12 col-xs-12 pe-4 ps-4">
                                            <div class="form-group label-floating">
                                                <div class="form-label d-flex justify-content-between">
                                                    <i class="fa fa-search" aria-hidden="true"></i>
                                                    <label class="m-0">Search Employee</label>

                                                    <div class="d-flex align-items-center h-100">
                                                        <input type="checkbox" value="F"
                                                            id="checkboxForEmployeeNameAndID" placeholder="Employee"
                                                            (click)="checkForCheckboc()">
                                                        <label class="px-1 m-0" for="checkboxForEmployeeNameAndID">
                                                            By ID </label>
                                                    </div>

                                                </div>
                                                <div>
                                                    <!-- autofocus="autofocus"  -->
                                                    <!-- autocomplete="on"  -->

                                                    <!-- <input type="search" class="form-control"
                                                        placeholder="Search employee by {{serachEmployeeBy}}"
                                                        list="employeeSearchWithNameId" name="empid" #empid="ngModel"
                                                        [(ngModel)]="contractClientProDesActData.empid"
                                                        (ngModelChange)="toggleDisable($event)" required> -->


                                                    <!-- <input type="text" class="form-control" 
                                                    placeholder="Search employee by {{serachEmployeeBy}}" 
                                                    [matAutocomplete]="auto" 
                                                    (ngModelChange)="toggleDisable($event)" >
                                                    <mat-autocomplete #auto="matAutocomplete">
                                                        <mat-option *ngFor="let fruit of contractClientEmpList"
                                                            [value]="fruit">
                                                            {{fruit.name}}
                                                        </mat-option>
                                                    </mat-autocomplete> -->

                                                    <!-- "#automcomplete="matAutocompleteTrigger"
                                                        formControlName="myControl" -->
                                                    <!-- [formControl]="myControl" -->
                                                    <input type="text" class="form-control"
                                                        placeholder="Search employee by {{serachEmployeeBy}}"
                                                        [formControl]="myControl" [matAutocomplete]="auto"
                                                        [(ngModel)]="contractClientProDesActData.empid"
                                                        (ngModelChange)="toggleDisable($event)">


                                                    <mat-autocomplete #auto="matAutocomplete"
                                                        [displayWith]="displayDataEmp.bind(this)">
                                                        <mat-option
                                                            *ngFor="let data of contractClientEmpList | employeeSearchAct:contractClientProDesActData.empid : serachEmployeeBy"
                                                            [value]="data.uniqueemployeeid">
                                                            {{serachEmployeeBy!='name' ? data.employeeID : data.name}}
                                                        </mat-option>
                                                    </mat-autocomplete>
                                                    <!-- (ngModelChange)="filterValueEmpList()"  -->


                                                    <!-- <datalist id="employeeSearchWithNameId"
                                                        *ngIf="serachEmployeeBy=='name'">
                                                        <option *ngFor="let data of contractClientEmpList"

                                                            [attr.data-value]="data.uniqueemployeeid">
                                                            {{data.name}}
                                                        </option>
                                                    </datalist> -->

                                                    <!-- <datalist id="employeeSearchWithNameId" *ngIf="serachEmployeeBy=='name'">
                                                        <option [ngValue]="data.uniqueemployeeid"
                                                            *ngFor="let data of contractClientEmpList">
                                                            {{data.name}}
                                                        </option>
                                                    </datalist> -->

                                                    <!-- *ngIf="serachEmployeeBy!='name'" -->
                                                    <!-- <datalist id="employeeSearchWithNameId">
                                                        <option [attr.data-value]="data.uniqueemployeeid"
                                                            *ngFor="let data of contractClientEmpList">
                                                            {{serachEmployeeBy!='name' ? data.employeeID : data.name}}
                                                        </option>
                                                    </datalist> -->

                                                    <!-- <select class="form-control" data-style="btn btn-drpdwn btn-round"
                                                        name="empid" #empid="ngModel"
                                                        [(ngModel)]="contractClientProDesActData.empid"
                                                        (ngModelChange)="toggleDisable($event)" required>

                                                        <option [ngValue]="undefined" [disabled]="true" [hidden]="true">
                                                            Select Employee</option>
                                                        <option [ngValue]="data.uniqueemployeeid"
                                                            *ngFor="let data of contractClientEmpList">
                                                            {{data.name}}
                                                        </option>
                                                    </select> -->

                                                    <!-- <p *ngIf="submitted && !empid.valid" class="help-block "
                                                        style="color: #ff5757;">
                                                        ⓘ Employee is required
                                                    </p> -->

                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div class="clearfix"></div>

                                    <div class="card-body row">

                                        <div class="col-md-6 col-sm-12 col-xs-12 pe-4 ps-4">
                                            <div class="form-group label-floating">
                                                <label class="form-label">Unit of Measurement</label>
                                                <div>
                                                    <select class="form-control" data-style="btn btn-drpdwn btn-round"
                                                        name="uom" #uom="ngModel"
                                                        [(ngModel)]="contractClientProDesActData.uom">

                                                        <option value="Month" selected>Month</option>

                                                    </select>
                                                    <p *ngIf="submitted && !uom.valid" class="help-block "
                                                        style="color: #ff5757;">
                                                        ⓘ Employee is required
                                                    </p>

                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-6 col-sm-12 col-xs-12 pe-4 ps-4">
                                            <div class="form-group label-floating">
                                                <label class="form-label">Billing Rate</label>

                                                <!-- <div>
                                                    <input type="text" class="form-control col-md-12 col-xs-12"
                                                        autocomplete="off" placeholder="Enter Billing Rate"
                                                        autofocus="true" name="billing_rate" #billing_rate="ngModel"
                                                        [(ngModel)]="contractClientProDesActData.billing_rate" />

                                                    <p *ngIf="submitted && !billing_rate.valid" class="help-block "
                                                        style="color: #ff5757;">
                                                        ⓘ Billing rate is required
                                                    </p>

                                                    <p *ngIf="billingRateNumberOnly" class="help-block "
                                                        style="color: #ff5757;">
                                                        ⓘ Enter only number
                                                    </p>

                                                </div> -->
                                                <!-- class="col-md-10 col-sm-10 col-xs-10"  -->
                                                <div class="row">
                                                    <div
                                                        [ngClass]=" filteredBillingRate.length==0 ? 'col-md-12 col-sm-12 col-xs-12' : 'col-md-10 col-sm-10 col-xs-10'">
                                                        <div class="form-group">
                                                            <div>
                                                                <input type="text"
                                                                    class="form-control col-md-12 col-xs-12"
                                                                    autocomplete="off" placeholder="Enter Billing Rate"
                                                                    autofocus="true" name="billing_rate"
                                                                    #billing_rate="ngModel"
                                                                    [(ngModel)]="contractClientProDesActData.billing_rate" />

                                                                <p *ngIf="submitted && !billing_rate.valid"
                                                                    class="help-block " style="color: #ff5757;">
                                                                    ⓘ Billing rate is required
                                                                </p>

                                                                <p *ngIf="billingRateNumberOnly" class="help-block "
                                                                    style="color: #ff5757;">
                                                                    ⓘ Enter only number
                                                                </p>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-2 col-sm-2 col-xs-2"
                                                        *ngIf="filteredBillingRate.length">
                                                        <button class="btn btn-primary btn-icon btn-sm w-100"
                                                            data-method="Owner" title="Add more owners"
                                                            data-bs-target="#showBudgetedBillingRate"
                                                            (click)="showBudgetedBillingRate()">
                                                            <i class="fa fa-eye" data-bs-toggle="tooltip"
                                                                data-original-title="Budgeting locations"></i>
                                                        </button>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div class="clearfix"></div>

                                    <div class="card-body row">
                                        <div class="col-md-6 col-sm-12 col-xs-12 pe-4 ps-4">
                                            <div class="form-group label-floating">
                                                <label class="form-label">Allocation Percentage</label>
                                                <div>
                                                    <input type="text" class="form-control col-md-12 col-xs-12"
                                                        autocomplete="off" name="allocation_percentage"
                                                        #allocation_percentage="ngModel"
                                                        [(ngModel)]="contractClientProDesActData.allocation_percentage"
                                                        placeholder="Select Allocation Percentage" autofocus="true"
                                                        [disabled]="isdisable" required />

                                                    <p *ngIf="submitted && !allocation_percentage.valid"
                                                        class="help-block " style="color: #ff5757;">
                                                        ⓘ Allocation percentage is required
                                                    </p>

                                                    <p *ngIf="alloNumberOnly" class="help-block "
                                                        style="color: #ff5757;">
                                                        ⓘ Enter only number
                                                    </p>

                                                    <p *ngIf="alloOnly100" class="help-block " style="color: #ff5757;">
                                                        ⓘ Allocation exceeds 100%
                                                    </p>



                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-6 col-sm-12 col-xs-12 pe-4 ps-4">
                                            <div class="form-group label-floating">
                                                <label class="form-label">Reporting manager</label>
                                                <div>

                                                    <select class="form-control" data-style="btn btn-drpdwn btn-round"
                                                        id="dGroup" name="rpt_mang_emp_id" #rpt_mang_emp_id="ngModel"
                                                        [(ngModel)]="contractClientProDesActData.rpt_mang_emp_id"
                                                        [disabled]="isdisable" required>

                                                        <option [ngValue]="undefined" [disabled]="true" [hidden]="true">
                                                            Select Reporting Manager</option>
                                                        <option [ngValue]="data.uniqueemployeeid"
                                                            *ngFor="let data of contractClientRPTManList">
                                                            {{data.name}}
                                                        </option>

                                                    </select>

                                                    <p *ngIf="submitted && !rpt_mang_emp_id.valid" class="help-block "
                                                        style="color: #ff5757;">
                                                        ⓘ Reporting manager is required
                                                    </p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="clearfix"></div>

                                    <div class="card-body row">
                                        <div class="col-md-6 col-sm-12 col-xs-12 pe-4 ps-4">
                                            <div class="form-group label-floating">
                                                <label class="form-label">Start Date</label>
                                                <div>
                                                    <input type="date" class="form-control col-md-12 col-xs-12"
                                                        autocomplete="off" placeholder="Enter Start Date"
                                                        autofocus="true" name="start_date" #start_date="ngModel"
                                                        [(ngModel)]="contractClientProDesActData.start_date"
                                                        (ngModelChange)="checkFromAndToDate()" required />

                                                    <p *ngIf="submitted && !start_date.valid" class="help-block "
                                                        style="color: #ff5757;">
                                                        ⓘ Start date is required
                                                    </p>

                                                    <p *ngIf="startDate" class="help-block " style="color: #ff5757;">
                                                        ⓘ Start should be less than end date
                                                    </p>

                                                    <p *ngIf="startDateSmall" class="help-block "
                                                        style="color: #ff5757;">
                                                        ⓘ Start date should be with in project start date
                                                        {{projectStartDate}}
                                                    </p>

                                                    <p *ngIf="freezeDateCheckStart" class="help-block "
                                                        style="color: #ff5757;">
                                                        ⓘ Project is freezed till {{projectFreezeDate}}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-6 col-sm-12 col-xs-12 pe-4 ps-4">
                                            <div class="form-group label-floating">
                                                <label class="form-label">End Date</label>
                                                <div>
                                                    <input type="date" class="form-control col-md-12 col-xs-12"
                                                        autocomplete="off" placeholder="Enter End Date" autofocus="true"
                                                        name="end_date" #end_date="ngModel"
                                                        [(ngModel)]="contractClientProDesActData.end_date"
                                                        (ngModelChange)="checkFromAndToDate()" required />

                                                    <p *ngIf="submitted && !end_date.valid" class="help-block "
                                                        style="color: #ff5757;">
                                                        ⓘ End date is required
                                                    </p>

                                                    <p *ngIf="endDate" class="help-block " style="color: #ff5757;">
                                                        ⓘ End date should be greater than start date
                                                    </p>

                                                    <p *ngIf="endDateGreat" class="help-block " style="color: #ff5757;">
                                                        ⓘ End date should be with in project end date {{projectEndDate}}
                                                    </p>

                                                    <p *ngIf="freezeDateCheckEnd" class="help-block "
                                                        style="color: #ff5757;">
                                                        ⓘ Project is freezed till {{projectFreezeDate}}
                                                    </p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="clearfix"></div>

                                    <div class="actionBar pe-4 ps-4">
                                        <div class="clearfix"> </div>
                                        <div>
                                            <button class="btn btn-success" type="button" id="save-btn"
                                                style="display: inline-block"
                                                (click)="saveContractClientProDesActData(contractClientDesActForm.form.valid)">Save</button>
                                            <button class="btn btn-success" type="button" id="update-btn"
                                                style="display: none"
                                                (click)="updateContractClientProDesActData(contractClientDesActForm.form.valid)">Update</button>
                                            <button class="btn btn-danger ms-2" type="button"
                                                (click)="clearContractClientProDesActData()">Clear</button>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <div class="clearfix"> </div>

                        </form>

                    </div>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="contractClientProDesActDataList.length">
            <div class="col-xl-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="card-header  border-0">
                        <h4 class="card-title">Allocation details</h4>
                    </div>
                    <div class="card-body">

                        <form id="contractclientsList" name="contractclientsList" type="multipart/form-data">
                            <div class="col-xs-12 mobile">

                                <div class="scrollsearchresults">
                                    <table class="table  table-vcenter text-nowrap table-bordered border-bottom"
                                        id="hr-table">
                                        <thead class="tablerow">
                                            <tr class="rowcolors">
                                                <th class="border-bottom-0 w-5 fs-6">Employee</th>
                                                <th class="border-bottom-0 w-5 fs-6">Location</th>
                                                <th class="border-bottom-0 w-5 fs-6">Designation</th>
                                                <th class="border-bottom-0 w-5 fs-6">Allocated</th>
                                                <th class="border-bottom-0 w-5 fs-6">Billing Rate</th>
                                                <th class="border-bottom-0 w-5 fs-6">Start Date</th>
                                                <th class="border-bottom-0 w-5 fs-6">End Date</th>
                                                <th class="border-bottom-0 w-5 fs-6">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let data of contractClientProDesActDataList">
                                                <td data-th="contractclientName">{{data.name}}
                                                </td>
                                                <td data-th="contractclientName">{{data.location_name}}
                                                </td>
                                                <td data-th="contractclientName">{{data.designation_name}}
                                                </td>
                                                <td data-th="contractclientName">{{data.allocation_percentage}}
                                                </td>
                                                <td data-th="contractclientName">{{data.billing_rate}}
                                                </td>
                                                <td data-th="contractclientName">{{data.start_date| date: 'mediumDate'}}
                                                </td>
                                                <td data-th="contractclientName">{{data.end_date | date: 'mediumDate'}}
                                                </td>

                                                <td>
                                                    <button class="btn btn-primary btn-icon btn-sm me-2"
                                                        (click)="contractclientDesActEdit(data)" data-method="edit"
                                                        title="Edit" id="navigateclientLocTwo">
                                                        <i class="fa fa-edit" data-bs-toggle="tooltip"
                                                            data-original-title="edit"></i>
                                                    </button>


                                                    <button class="btn btn-danger btn-icon btn-sm me-2"
                                                        (click)="contractclientDesActDeleteDataStore(data)"
                                                        data-method="delete" title="Delete"
                                                        data-bs-target="#showAlertVer">
                                                        <i class="fa fa-trash" data-bs-toggle="tooltip"
                                                            data-original-title="edit"></i>
                                                    </button>

                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>

                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="showAlertWarning" class="modal fade" style="top: 40px;">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body text-center p-4">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><span
                        aria-hidden="true">×</span></button>
                <i class="fe fe-alert-circle fs-80 text-warning lh-1 mb-5 d-inline-block"></i>
                <h4 class="text-danger mb-3">Are you sure you want to delete this data?</h4>
                <!-- <p class="mg-b-20 mg-x-20">This record has data attached to it! it can't be deleted.</p> -->

                <button aria-label="Close" class="btn btn-danger pd-x-25 mx-1" data-bs-dismiss="modal"
                    (click)="contractclientDesActDeleteData()">Delete</button>
                <button aria-label="Close" class="btn btn-primary pd-x-25 mx-1" data-bs-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>

<div id="showAlertCantDelete" class="modal fade" style="top: 40px;">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body text-center p-4">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><span
                        aria-hidden="true">×</span></button>
                <i class="fe fe-link fs-80 text-danger lh-1 mb-5 d-inline-block"></i>
                <h4 class="text-danger">Error: The data can't be deleted!</h4>
                <p class="mg-b-20 mg-x-20">This record has data attached to it! it can't be deleted.</p>

                <button aria-label="Close" class="btn btn-primary pd-x-25" data-bs-dismiss="modal">Okay!</button>
            </div>
        </div>
    </div>
</div>

<div id="showAlertCantUpdateFreeze" class="modal fade" style="top: 40px;">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body text-center p-4">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><span
                        aria-hidden="true">×</span></button>
                <i class="fe fe-cloud-snow fs-80 text-primary lh-1 mb-5 d-inline-block"></i>
                <h4 class="text-danger">Error: This project is freezed!</h4>
                <p class="mg-b-20 mg-x-20">Freezed till {{projectFreezeDate}}! Data can't be updated</p>

                <button aria-label="Close" class="btn btn-primary pd-x-25" data-bs-dismiss="modal">Okay!</button>
            </div>
        </div>
    </div>
</div>

<div id="showAlertCantDeleteFreeze" class="modal fade" style="top: 40px;">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body text-center p-4">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><span
                        aria-hidden="true">×</span></button>
                <i class="fe fe-cloud-snow fs-80 text-primary lh-1 mb-5 d-inline-block"></i>
                <h4 class="text-danger">Error: This project is freezed!</h4>
                <p class="mg-b-20 mg-x-20">This data can't be deleted</p>

                <button aria-label="Close" class="btn btn-primary pd-x-25" data-bs-dismiss="modal">Okay!</button>
            </div>
        </div>
    </div>
</div>

<div id="showBudgetedBillingRate" class="modal" style="top: 40px;">
    <div class="modal-dialog" role="document">
        <div class="modal-content animate">

            <div class="p-3">
                <mat-list class="p-0">

                    <h3 mat-subheader *ngIf="filteredBillingRate.length">Budgeted Billing Rate<span
                            *ngIf="filteredBillingRate.length!=1">s</span></h3>

                    <mat-list class="p-0">
                        <mat-list-item *ngFor="let locName of filteredBillingRate; let i=index">
                            <h4 mat-line class="custom-h4 py-3">{{locName.billing_rate}}</h4>
                            <!-- <button type="button" class="btn btn-danger form-group"
                                (click)="populateBudgetedBillingRate(locName)"><i class="fa fa-trash"
                                    data-original-title="Delete Location"></i></button> -->
                            <button class="btn btn-primary form-group" data-method="Budgeted" title="Budgeted"
                                (click)="populateBudgetedBillingRate(locName)">
                                Use
                            </button>

                            <mat-divider></mat-divider>

                        </mat-list-item>
                    </mat-list>

                </mat-list>
            </div>

        </div>
    </div>
</div>