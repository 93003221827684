export class EmployeeFamilyDetails {
  id:number;
  fullname:string;
  emp_relation:string;
  dob:string;
  gender:string;
  aadhaar_number:string;
  pan_number:string;
  mobile_number:string;
  address:string;
  dependent:boolean=false;
	bank_name:string;
  ifsc_code:string;
	account_no:string;
	account_type:string;
  nomination_percentage:number;
  nominee:boolean= false;
  
 constructor(data?: Partial<EmployeeFamilyDetails>) {
  // Assign default values or empty strings for string properties
  console.log("employeefamolydetails model",data);
  this.id = data?.id || 0; // Assuming id is a number
  this.fullname = data?.fullname || '';
  this.emp_relation = data?.emp_relation || '';
  this.dob = data?.dob || null;  // Use null for dates that are undefined
  this.gender = data?.gender || '';
  this.aadhaar_number = data?.aadhaar_number || '';
  this.mobile_number = data?.mobile_number || '';
  this.address = data?.address || '';
  this.pan_number = data?.pan_number || '';
  // For boolean properties, default to false if not provided
  this.dependent = data?.dependent !== undefined ? data.dependent : false;

  // Assign default values for remaining properties
  this.bank_name = data?.bank_name || '';
  this.ifsc_code = data?.ifsc_code || '';
  this.account_no = data?.account_no || '';
  this.account_type = data?.account_type || '';
  this.nomination_percentage = data?.nomination_percentage || 0;  // Default to 0
  this.nominee = data?.nominee !== undefined ? data.nominee : false;
}
}
