<div class="app-content main-content">
  <div class="side-app main-container">

    <div class="alertcomp"></div>

    <!-- <div class="page-header d-xl-flex d-block">
      <div class="page-leftheader">
        <div class="page-title">Employee Group </div>
      </div>
    </div> -->

    <div class="row">
      <div class="col-xl-12 col-md-12 col-lg-12">
        <div class="card">
          <div class="card-body">
            <!-- <div style="padding: 16px;">
               <select class="form-control" data-style="btn btn-drpdwn btn-round" (change)="getselectedOption($event)"
                style="appearance: auto !important;">
                 <option value="employee" >Search by Employee</option> 
                <option value="departmentgroup" selected disabled>Search by Department/Group</option>

              </select> 
            </div> -->
            <form id="fcForm" class="form-horizontal form-label-left" #f="ngForm" (ngSubmit)="f.form.valid">

              <div class="x_content row px-4">
                <div class="col-md-6 col-sm-6 col-xs-6 " *ngIf="searchby === 'employee'">
                  <div class="form-group label-floating">
                    <label class="form-label"> Employeename </label>
                    <div>
                      <input type="search" class="form-control" name="Employee Name" id="employeename"
                        placeholder="Enter Employee Name / ID " [(ngModel)]="query" autocomplete="off"
                        (input)="getEmployeeByNameOrId()" list="employeeMasterLucene" autofocus="autofocus">
                      <datalist id="employeeMasterLucene">
                        <option data-value={{emp.name}} *ngFor="let emp of employeeMasterLucene"> {{emp.employeeid}}
                        </option>
                      </datalist>

                      <!-- <span *ngIf="submitted && !docGroup.valid " class="help-block" style="color: red;">
                                Please Select Document Group</span> -->
                    </div>
                  </div>
                </div>
                <div class=" d-flex flex-wrap">
                  <div class="col-md-5 col-sm-6 col-xs-6 px-0" *ngIf="searchby === 'departmentgroup'">
                    <div class="form-group label-floating ">
                      <label class="form-label"> Department </label>
                      <div>

                        <select class="form-control " style="appearance: auto !important;"
                          data-style="btn btn-drpdwn btn-round" id="dGroup" name="department" [(ngModel)]="department"
                          (ngModelChange)="modelDepartment($event)">
                          <!-- [(ngModel)]="myDocuments.docGroup" #docGroup="ngModel" (change)="docGroupChange()" required -->
                          <option [ngValue]="0">All</option>
                          <option [value]="item.departmentId" *ngFor="let item of departments">{{item.name}}</option>
                        </select>
                        <!-- <span *ngIf="submitted && !docGroup.valid " class="help-block" style="color: red;">
                                    Please Select Document Group</span> -->
                      </div>
                    </div>
                  </div>



                  <div class="col-md-5 col-sm-6 col-xs-6 px-0 ps-md-2" *ngIf="searchby === 'departmentgroup'">
                    <div class="form-group label-floating">
                      <label class="form-label"> Group </label>
                      <div>
                        <select class="form-control" data-style="btn btn-drpdwn btn-round" id="dType" name="group"
                          [(ngModel)]="groupid" (ngModelChange)="modelGroup($event)">
                          <!-- [(ngModel)]="myDocuments.docType" #docType="ngModel" (change)="docTypeChange()" required -->
                          <!-- <option [ngValue]="null">
                                    Choose Months Commitment...
                                  </option> -->
                          <option [ngValue]="0">All</option>
                          <option [value]="g.id" *ngFor="let g of group">{{g.name}}</option>
                        </select>
                        <!-- <span *ngIf="submitted && !docType.valid " class="help-block" style="color: red;">
                                  Please Select Document Type</span> -->
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2 col-sm-6 col-xs-6 p6 d-flex justify-content-start align-items-end">
                    <div class="actionBar">
                      <div class="clearfix"> </div>
                      <div class="container">
                        <button type="button" id="navigatetarget" class="btn btn-primary w-100  w-md-20  p-2 p-md-1"
                          (click)="search(f.form.value) ">
                          <i class="fa fa-search" aria-hidden="true"></i> Search
                        </button>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </form>
          </div>

        </div>
      </div>
    </div>
    <div class="empty-div-one" style="height: 0px;" id="navigatedest"></div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <form action="#">
              <div class="table-responsive">
                <div class="mobile">
                  <div class="scrollsearchresults" id="scroll" infiniteScroll [infiniteScrollDistance]="2"
                    [infiniteScrollUpDistance]="1.5" [infiniteScrollThrottle]="100" [scrollWindow]="false"
                    (scrolled)="onScroll(f.form.value)" id="scrolllength">
                    <div class="scrollbar">
                      <table class="table table-hover  table-vcenter text-nowrap table-bordered border-bottom"
                        id="hr-leaves">
                        <thead class="tablerow">
                          <tr class="rowcolors stickyatTop">
                            <!-- <th><input type="checkbox" style="cursor: pointer;" /></th> -->
                            <th class="border-bottom-0 w-10">#</th>
                            <th class="border-bottom-0 w-20">Employee ID</th>
                            <th class="border-bottom-0 w-30">Name</th>
                            <th class="border-bottom-0 w-40">Group</th>
                            <!-- <th class="border-bottom-0">Leave Date</th>
                                          <th class="border-bottom-0">Day</th>
                                          <th class="border-bottom-0">Reason</th>
                                          <th class="border-bottom-0">Status</th>
                                          <th class="border-bottom-0">Applied on</th>
                                          <th class="border-bottom-0">Remark</th> -->
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let emp of employeedepgroup;let i = index">
                            <td data-th="Sl No." style="text-align: right;">{{i + 1}}</td>
                            <td data-th="Employee ID">{{emp.employeeid}}</td>
                            <td data-th="Employee Name">{{emp.name}}</td>
                            <td data-th="Select Group">
                              <select class="form-control" (change)="getgroupchanges($event,emp)">
                                <option [value]="0" [selected]="emp.groupid ===0">Select Group</option>
                                <option [value]="gp.id" *ngFor="let gp of group" [selected]="emp.groupid === gp.id">
                                  {{gp.name}}</option>
                              </select>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="actionBar">
                <div class="clearfix"> </div>
                <div class="pro-fab">
                  <!-- <button class="btn btn-info" type="button" (click)="search(f.form.value)" id="navigatetarget">Search</button>   -->
                  <button class="btn btn-success ms-2" (click)="save()">Save</button>
                  <button class="btn btn-danger ms-2" type="button" (click)="clear()">Clear</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="secondaryModal" tabindex="-1" aria-labelledby="secondaryModalLabel" aria-hidden="true"
    style="display: none;">
    <div class="modal-dialog  modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <!-- <h5 class="modal-title" id="secondaryModalLabel" style="font-size: 20px;"></h5> -->
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
        </div>
        <div class="modal-body">
          <div class=" container-fluid">
            Do You want to change all the Previous assigned records or Future assigned records
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
            (click)="confirmedSave('All Records')">Previous assigned </button>
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
            (click)="confirmedSave('Future Records')">Future assigned </button>
        </div>
      </div>
    </div>
  </div>
</div>