<div class="app-header header sticky paddingforNav" id="topNavBar" *ngIf="isloggedIn == true">
	<div class="container-fluid main-container">
		<div class="d-flex">

			<div class="app-sidebar__toggle d-flex align-items-center" data-bs-toggle="sidebar"
				style="cursor: pointer; margin-top: 0.25rem;" (click)="addnavbarShow()">
				<a class="close-toggle">
					<i class="feather feather-menu" id="menu_icon_one"></i>
				</a>
				<a class="open-toggle">
					<i class="feather feather-x" id="menu_icon_two"></i>
				</a>
			</div>

			<span class="current-menu-name d-lg-flex align-items-lg-center fontSize-20 fw-bolder mb-2">
				{{currentMenuName}}</span>

			<div class="d-flex order-lg-2 my-auto ms-auto ">
				<div class="text-center me-3 mb-1 logged-user-name">
					<span class="d-block font-weight-bold fontSize-20">Welcome</span>
					<!-- <hr class="my-1"> -->
					<span class="font-weight-normal">{{loggedUsername}}</span>
				</div>
				<button class="navbar-toggler nav-link icon navresponsive-toggler vertical-icon ms-auto" type="button"
					data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent-4"
					aria-controls="navbarSupportedContent-4" aria-expanded="false" aria-label="Toggle navigation">
					<i class="fe fe-more-vertical header-icons navbar-toggler-icon"></i>
				</button>
				<div class="mb-0 navbar navbar-expand-lg navbar-nav-right responsive-navbar navbar-dark p-0">
					<div class="collapse navbar-collapse " id="navbarSupportedContent-4">
						<div class="d-flex ms-auto justify-content-end">


							<div *ngIf="notifiacationTable.length" class="dropdown header-notify">
								<a class="nav-link icon" data-bs-toggle="sidebar-right" data-bs-target=".sidebar-right"
									(click)="onViewMessages(); Notificationlist()">
									<i class="feather feather-bell header-icon"></i>
									<span class="badge" *ngIf="filteredNotifications?.length > 0"
										[ngClass]="{'badge-danger': !isMessagesViewed, 'badge-success': isMessagesViewed}">{{
										badgeLength }}</span>
								</a>
							</div>


							<!--sidebar-right-->
							<div class="sidebar sidebar-right sidebar-animate">
								<div class="card-header border-bottom pb-5">
									<h4 class="card-title">Notifications </h4>
									<div class="card-options">
										<a class="btn btn-sm btn-icon btn-light  text-primary"
											data-bs-toggle="sidebar-right" data-bs-target=".sidebar-right"><i
												class="feather feather-x"></i> </a>
									</div>
								</div>
								<!-- infiniteScroll [infiniteScrollDistance]="2"
									[infiniteScrollUpDistance]="1.5" [infiniteScrollThrottle]="100"
									[scrollWindow]="true" -->
								<!-- (mouseover)="updateNotifiaction(notificationList.notificationid,notificationList.readStatus)" -->
								<div class="scrollsearchresults" infiniteScroll [infiniteScrollDistance]="2"
									[infiniteScrollUpDistance]="1.5" [infiniteScrollThrottle]="100"
									[scrollWindow]="false" (scrolled)="onScroll()">

									<div class="list-group-item align-items-center border-0 darkmodep"
										*ngFor="let notificationList of notifiacationTable; let i = index"
										[class.unread]="notificationList.readStatus === 0"
										[class.read]="notificationList.readStatus === 1"
										(mouseover)="updateNotification(notificationList.notificationid,notificationList.readStatus,i)">
										<div *ngIf="notificationList.action == 'REGULARIZE' ">
											<div class="d-flex">
												<div class="ps-3 text-wrap text-break ">
													<h6 class="mb-1">Hi {{notificationList.notifyname}},</h6>
													<p class="fs-13 mb-1">Regularize Your Attendance for </p>
													<p class="fs-13 mb-1">{{notificationList.qualifier}}</p>
													<div class="small text-muted">by PayTime Plus</div>
												</div>
											</div>
										</div>
										<!-- <div #other > -->
											<div *ngIf="notificationList.action != 'REGULARIZE' ">
											<div class="d-flex">
												<div class="ps-3 text-wrap text-break ">
													<h6 class="mb-1">Hi {{notificationList.notifyname}},</h6>
													<p class="fs-13 mb-1">Your Request for
														{{notificationList.qualifier}} is
														{{notificationList.action}}</p>
													<div class="small text-muted">by {{notificationList.approvername}}
														on
														{{notificationList.timestamp | date:'MM/dd/yyyy'}}</div>
												</div>
											</div>
										</div>
									</div>

								</div>

							</div>
							<!--/sidebar-right-->
							<div class="dropdown d-flex" (click)="darkLightToggle($event)">
								<a class="nav-link icon theme-layout nav-link-bg layout-setting">
									<span class="dark-layout" id="moon"><i class="fe fe-moon"></i></span>
									<span class="light-layout" id="light"><i class="fe fe-sun"></i></span>
								</a>
							</div>
							<div class="dropdown header-fullscreen" (click)=" fullscreenMode($event)">
								<a class="nav-link icon full-screen-link">
									<i class="feather feather-maximize fullscreen-button fullscreen header-icons"></i>
									<i
										class="feather feather-minimize fullscreen-button exit-fullscreen header-icons"></i>
								</a>
							</div>
							<div class="dropdown profile-dropdown">
								<a class="nav-link pe-1 ps-0 leading-none" data-bs-toggle="dropdown">
									<span>
										<img class="avatar avatar-md bradius bg-white" alt="img"
											[src]=" (urlsub | async)  || '../../assets/img/user-3296.svg' ">

									</span>


								</a>

								<!-- <div>urlsub value: {{ urlsub | async }}</div>
								<div>url value: {{ url }}</div> -->


								<div class="dropdown-menu dropdown-menu-end dropdown-menu-arrow animated">
									<div class="p-3 text-center border-bottom">
										<a class="text-center user pb-0 font-weight-bold"
											style=" word-wrap: break-word;">{{loggedUsername}}</a>
									</div>

									<a class="dropdown-item d-flex" data-bs-toggle="modal" (click)="setMenuName('Change Password')"
										data-bs-target="#changepasswordnmodal" [routerLink]="['/chpwd']">
										<i class="feather feather-edit-2 me-3 fs-16 my-auto"></i>
										<div class="mt-1">Change Password</div>
									</a>
									<a class="dropdown-item d-flex" (click)="logout()">
										<i class="feather feather-power me-3 fs-16 my-auto"></i>
										<div class="mt-1">Sign Out</div>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>


<div id="overlayer" class="overlay" (click)="toggleSideMenu()"></div>