<div class="app-content main-content">
    <div class="side-app main-container">
        <div class="alertcomp"></div>
        <!-- <div class="alertcompExcelUpload"></div> -->
        <!-- <div class="card-body px-0">
            <div class="page-leftheader my-3">
              <div class="page-title">Income Tax Calculator</div>
            </div>
          </div> -->


        <div class="row">
            <div class="col-xl-12 col-md-12 col-lg-12">
                <div class="d-flex justify-content-end mb-2">
                    <button class="btn btn-primary me-0" data-bs-toggle="modal"
                    data-bs-target="#incomeTaxUploadModal">
                    Income Tax Upload</button>
                </div>
                <div class="card">
                    <div class="card-body">
                        <div class="x_content row p-4">
                            
                            <div class="col-md-3 col-sm-6 col-xs-6">
                                <div class="form-group label-floating">
                                    <label class="form-label"> Employee Name /
                                        ID</label>
        
                                    <input type="search" class="form-control" name="Employee Name" id="employeename"
                                        placeholder="Enter Employee Name / ID " [(ngModel)]="query" autocomplete="off"
                                        (input)="getEmployeeByNameOrId()" list="employeeMasterLucene" autofocus="autofocus">
        
                                    <!-- <input type="hidden" [(ngModel)]="selecteduniqueeEmpId"> -->
                                    <datalist id="employeeMasterLucene">
                                        <option [value]="emp.name + ' / ' + emp.employeeid"
                                            *ngFor="let emp of employeeMasterLucene">
                                            <!-- {{emp.name}} -->
                                        </option>
                                    </datalist>
        
                                </div>
                            </div>

                            <!-- Active/Inactive Employee Status filter for Lucene Search -->
                            <div class="form-group col-md-3 col-sm-4 col-xs-4 px-2">
                                <label class="form-label">Employee Status</label>
                                <select class="form-control" type="text" style="width: 100%;" required
                                    [(ngModel)]="ActiveInativeLuceneFilter" name="selVal" (change)="onStatusChange()"
                                    [disabled]="!showBasedOnPermissionEmployeeStatus">
                                    <option value="All">All</option>
                                    <option value="Active">Active</option>
                                    <option value="Inactive">Inactive</option>
                                </select>
                            </div>

                            <div class="col-md-3 col-sm-3 col-xs-3 mb-3">
                                <label class="form-label w-100 mb-1">Branch</label>
                                <div class="form-group m-0">
                                    <div>
                                        <select id="deptInput" class="form-control" name="branch"
                                            placeholder="Select Branch" [(ngModel)]="branch"
                                            (change)="onBranchChange($event)" autocomplete="off" autofocus="autofocus">

                                            <option value="all" selected="selected">All</option>
                                            <option value={{data.branchId}} *ngFor="let data of branchInfo">
                                                {{data.branchname}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>


                            <div class="col-md-3 col-sm-3 col-xs-3 mb-3">
                                <div class="form-group label-floating">
                                    <label class="form-label"> Year </label>
                                    <div>
                                        <select id="year" class="form-control" [(ngModel)]="year" required="required"
                                            #selectYear="ngModel"
                                            [ngClass]="(!selectYear.valid && selectYear.touched) ? 'is-invalid' : ''">
                                            <option value="" selected>Select Year</option>
                                            <option *ngFor="let year of years" [value]="year">{{ year }}</option>
                                        </select>
                                    </div>
                                    <span class="help-text text-danger"
                                        *ngIf="(!selectYear.valid && selectYear.touched)">Please Select Year</span>
                                </div>
                            </div>
                            <div class="col-md-3 col-sm-3 col-xs-3 mb-3">
                                <div class="form-group label-floating">
                                    <label class="form-label">Tax Regime</label>
                                    <div>
                                        <select id="selectRegime" class="form-control" [(ngModel)]="selectedRegime"
                                            required="required" #selectRegime="ngModel"
                                            [ngClass]="(!selectRegime.valid && selectRegime.touched) ? 'is-invalid' : ''" (change)="onRegimeChange()">
                                            <option *ngFor="let regime of regimes" [value]="regime.value">{{
                                                regime.label }}</option>
                                        </select>
                                    </div>
                                    <span class="help-text text-danger"
                                        *ngIf="(!selectRegime.valid && selectRegime.touched)">Please Select Tax
                                        Regime</span>
                                </div>
                            </div>
                                <!-- <div class="form-group m-0 d-flex align-items-center">
                            <label class="form-check-label me-3">
                                <input type="radio" class="form-check-input" name="taxRegime" value="new" [(ngModel)]="selectedRegime"> New Regime
                            </label>
                            <label class="form-check-label">
                                <input type="radio" class="form-check-input" name="taxRegime" value="old" [(ngModel)]="selectedRegime"> Old Regime
                            </label>
                        </div> -->
                                <!-- <div class="row">
                            <div class="w-50p d-flex align-items-center">
                                <label class="custom-switch me-3">
                                    <input type="radio" class="custom-switch-input" name="taxRegime" value="new" [(ngModel)]="selectedRegime">
                                    <span class="custom-switch-indicator"></span>
                                    <span class="custom-switch-description">New Regime</span>
                                </label>
                                <label class="custom-switch">
                                    <input type="radio" class="custom-switch-input" name="taxRegime" value="old" [(ngModel)]="selectedRegime">
                                    <span class="custom-switch-indicator"></span>
                                    <span class="custom-switch-description">Old Regime</span>
                                </label>
                            </div>
                        </div> -->

                            <div class="col-md-4 col-sm-4 col-xs-4 mb-4">
                                <label class="form-label col-md-12 col-sm-4 col-xs-4 mb-1">&nbsp;</label>
                                <div class="form-group m-0">
                                    <button type="button" id="navigatetarget" class="btn btn-primary w-45"
                                        style="margin-right: 1vw" (click)="search(); getIncomeTaxList();">
                                        <i class="fa fa-search p-0" aria-hidden="true"></i>Search
                                    </button>
                                    <button type="button" class="btn btn-danger w-45" (click)="clear()">
                                        Clear
                                    </button>
                                </div>
                            </div>

                            <!-- <div class="col-md-2 col-sm-2 col-xs-2">
                            <div class="form-group label-floating">
                                    <label></label>
                            <div>
                                <button type="button" class="btn btn-primary w-100 mt-1" (click)="selectYear.control.markAsTouched(); downloadFile()">Download</button>
                            </div>
                            </div>
                        </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="card">
            <!-- <div class="card col-md-12 col-sm-12 col-xs-12"> -->
            <!-- <div class="card-header border-bottom-0">
                          <h3 class="card-title">List of Employee</h3>
                        </div> -->
            <div class="card-header  border-0">
                <h4 class="card-title">List of Employee</h4>
                <button type="button" class="btn btn-primary ms-auto" (click)="downloadFile()">
                    <i class="fa fa-download" data-bs-toggle="tooltip" data-original-title="download"></i>
                </button>
            </div>

            <div class="card-body">
                <div class="col-xs-12 mobile view">
                    <div class="scrollsearchresults" infiniteScroll [infiniteScrollDistance]="2"
                        [infiniteScrollUpDistance]="1.5" [infiniteScrollThrottle]="100" [scrollWindow]="false"
                        (scrolled)="onScroll()" id="scrolllength">
                        <div class="table-responsive">
                            <table class="table table-hover table-vcenter text-nowrap table-bordered border-bottom"
                                id="hr-table">
                                <thead class="tablerow stickyatTop">
                                    <tr class="stickyatTop rowcolors">
                                        <th class="border-bottom-0 fs-7">Employee ID</th>
                                        <th class="border-bottom-0 fs-7">Employee Name</th>
                                        <ng-container *ngIf="selectedRegime==='all'">
                                        <th class="border-bottom-0 fs-7">New Regime</th>
                                        </ng-container>
                                        <th class="border-bottom-0 fs-7">Paid Sal</th>
                                        <th class="border-bottom-0 fs-7">Proj Sal</th>
                                        <th class="border-bottom-0 fs-7">Other Pay</th>
                                        <th class="border-bottom-0 fs-7">Total Pay</th>
                                        <th *ngFor="let sav of savingsList">{{ sav.name }}</th>
                                        <th class="border-bottom-0 fs-7">Taxable Pay</th>
                                        <th class="border-bottom-0 fs-7">Income Tax</th>
                                        <th class="border-bottom-0 fs-7">Tax deducted</th>
                                        <ng-container
                                            *ngIf="employeeList && employeeList.length > 0 && employeeList[0]">
                                            <th *ngFor="let rem of employeeList[0].remainingMonth">
                                                {{ rem| date: 'mediumDate'}}
                                            </th>
                                        </ng-container>
                                    </tr>
                                </thead>
                                <tbody style="max-height: 50vh; overflow: scroll; padding-bottom: 10vh;">
                                    <tr *ngFor="let emp of employeeList; let i = index">
                                        <td>{{ emp.employeeDto.employeeid }}</td>
                                        <td>{{ emp.employeeDto.name }}</td>
                                        <ng-container *ngIf="selectedRegime==='all'">
                                            <td>
                                    
                                                <input type="checkbox" [(ngModel)]="emp.newRegime">
                                            </td>
                                        </ng-container>
                                        <td class="text-right">{{ emp.paidSalary | number: '1.2-2'}}</td>
                                        <td class="text-right">{{ emp.projectedSalary | number: '1.2-2'}}</td>
                                        <td class="text-right">{{ emp.otherPay | number: '1.2-2'}}</td>
                                        <td class="text-right">{{ emp?.totalPay ? (emp.totalPay | number: '1.2-2'):'0.00'}}</td>
                                        <td *ngFor="let sav of emp.itSavingsList" class="text-right">{{ sav?.amount ? (sav.amount | number: '1.2-2') : '0.00' }}</td>
                                        <td class="text-right">{{ emp.taxablePay | number: '1.2-2'}}</td>
                                        <td class="text-right">{{ emp.incomeTax | number: '1.2-2'}}</td>
                                        <td class="text-right">{{ emp.tdsDeducted | number: '1.2-2'}}</td>
                                        <td class="text-right" *ngFor="let rem of emp.remainingMonth">
                                            {{ roundUp(emp.incomeTax, emp.remainingMonth.length) | number: '1.2-2' }}
                                        </td>


                                        <!-- <td *ngFor="let rem of emp.remainingMonth">{{ (emp.incomeTax / emp.remainingMonth.length) | number: '1.2-2' }}</td> -->

                                        <!-- <td>
                                      <input type="text"
                                        [(ngModel)]="incomeTaxCalculator[i].otherPay"
                                        class="form-control"
                                        [placeholder]="emp.otherPay | number:'1.2-2'" />
                                    </td> -->
                                        <!-- <ng-container *ngFor="let sav of getFilteredSavingsList(emp.uniqueemployeeid); let j = index">
                                        <td>
                                        <input type="text" 
                                        [(ngModel)]="incomeTaxCalculator[i].incomeTax[j].amount" 
                                        class="form-control"
                                        [placeholder]="sav.amount | number:'1.2-2'" />
                                        </td>
                                        </ng-container> -->
                                    </tr>
                                </tbody>

                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="actionBar">
                        <div class="clearfix"></div>
                        <div class="pro-fab">
                          <button class="btn btn-success" type="button" (click)="uploadFile()">
                            Upload
                          </button> 
                          <button class="btn btn-danger ms-2" type="button" (click)="downloadFile()">
                            Download
                          </button>
                        </div>
                      </div>-->

        <!-- </div>  -->

        <!-- <div class="page-header d-xl-flex d-block">
                        <div class="page-leftheader">
                            <div class="page-title">Upload</div>
                        </div>
                    </div> -->

        <div class="modal fade" id="excelErrors" tabindex="-1" aria-labelledby="excelErrors" aria-hidden="true"
            style="display: none;">
            <div class="modal-dialog  modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="excelErrors" style="font-size: 20px;">Error Details </h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
                    </div>
                    <div class="modal-body">
                        <div class="container-fluid">
                            <!-- <h5 class="card-title mt-3 mb-3">Error Details</h5> -->
                            <ul class="list-group">
                                <li class="list-group-item" *ngFor="let error of excelErrors">
                                    {{ error }}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                            (click)="clear()">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="incomeTaxUploadModal" tabindex="-1" aria-labelledby="additionalModalLabel"
            aria-hidden="true" style="display: none;">
            <div class="modal-dialog modal-lg modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel" style="font-size: 20px;">Income Tax Upload
                        </h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">X</button>
                    </div>
                    <div class="modal-body" style="padding-bottom: 0px; padding-top: 0px;">
                        <div class="row mb-2">
                            <!-- <div class="alertcomp"></div> -->
                            <div class="alertcompExcelUpload">
                            </div>

                            <div class="col-md-3 col-sm-3 col-xs-3 mb-2 mt-2">
                                <div class="form-group label-floating">
                                    <label class="form-label">Tax Regime</label>
                                    <div>
                                        <select id="selectRegime" class="form-control" [(ngModel)]="selectedRegime"
                                            required="required" #selectRegime="ngModel"
                                            [ngClass]="(!selectRegime.valid && selectRegime.touched) ? 'is-invalid' : ''" (change)="onRegimeChange()">
                                            <option *ngFor="let regime of regimes" [value]="regime.value">{{
                                                regime.label }}</option>
                                        </select>
                                    </div>
                                    <span class="help-text text-danger"
                                        *ngIf="(!selectRegime.valid && selectRegime.touched)">Please Select Tax
                                        Regime</span>
                                </div>
                            </div>
                            <div class="col-xl-12 col-md-12 col-lg-12">
                                <!-- <div class="col-xs-12 mobile"> -->
                                    <!-- <div message="pattern"><span class=" textColors">
                                            Upload excel with uploaded document details.
                                        </span>
                                    </div> -->
                                                <div>
                                                    <label for="form-label" class="form-label text-left">Select File</label>
                                                    <input type="file" id="file" class="form-control" name="myFilename"
                                                        style="box-sizing: border-box;" required="required" enctype="multipart/form-data"
                                                        (change)="handleFileInput($event)" />
                                                        <button type="button" class="btn btn-success w-100 w-md-20 mt-2" (click)="uploadFile()">Upload
                                                            File</button>
                                                    <span class="help-text text-danger" *ngIf="(!isFileValid)">Upload only '.xls' file! </span>
                                                </div>
                                <!-- </div> -->
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">

                    </div>
                </div>
            </div>
        </div>