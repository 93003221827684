<div class="app-content main-content">
    <div class="side-app main-container">
        <div class="alertcomp">
        </div>
        <!-- <div class="page-header d-xl-flex d-block">
            <div class="page-leftheader">
                <div class="page-titleMod">Annual Leave Encashment Report</div>
            </div>
        </div> -->


        <div class="row">
            <div class="col-xl-12 col-md-12 col-lg-12">
                <div class="card">

                    <div class="card-body">
                        <form id="fcForm" class="form-horizontal form-label-left"
                            (keyup.enter)="limitpara();searchLeaveEncashReport()">

                            <div class="x_content row px-4">

                                <div class="col-md-8 col-sm-6 col-xs-6 mb-4">
                                    <label class="form-label w-100 mb-1">Employee
                                        Name / ID </label>
                                    <div class="form-group m-0">
                                        <div>
                                            <input type="search" class="form-control " name="employeename"
                                            id="employeename" [(ngModel)]="query" autocomplete="off"
                                            (input)="getEmployeeByNameOrId()" list="employeeMaster"
                                            autofocus="autofocus"
                                            placeholder="Search Employee Name / ID">

                                        <datalist id="employeeMaster">
                                            <option [value]="emp.name + ' / ' + emp.employeeid"
                                                *ngFor="let emp of employeeMasterLucene">
                                                <!-- {{emp.name}} -->
                                            </option>
                                        </datalist>
                                        </div>
                                    </div>
                                </div>

                                  <!-- Active/Inactive Employee Status filter for Lucene Search -->
                                <div class="col-md-4 col-sm-4 col-xs-4">
                                    <label class="form-label">Employee Status</label>
                                    <select class="form-control" type="text" style="width: 100%;" required
                                        [(ngModel)]="ActiveInativeLuceneFilter" name="selVal"
                                        (change)="onStatusChange()" [disabled]="!showBasedOnPermissionEmployeeStatus">
                                        <option value="All">All</option>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                    </select>
                                </div>
                            </div>
                            <div class="x_content row px-4">
                              
                                <div class="col-md-4 col-sm-4 col-xs-4">
                                    <div class="form-group label-floating">
                                        <label class="form-label">Report From</label>
                                        <div>
                                            <input class="form-control w-100" type="date" name="fromDate"
                                                autocomplete="off" autofocus="true" [disabled]="false"
                                                [(ngModel)]="fromDate" (ngModelChange)="checkFromAndToDate()" required>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-4 col-sm-4 col-xs-4">
                                    <div class="form-group label-floating">
                                        <label class="form-label">Report To</label>
                                        <div>
                                            <input class="form-control w-100" type="date" name="toDate"
                                                autocomplete="off" autofocus="true" [disabled]="false"
                                                [(ngModel)]="toDate" (ngModelChange)="checkFromAndToDate()" required>
                                            <p *ngIf="endDate" class="help-block " style="color: #ff5757;">
                                                ⓘ End date should be greater than start date
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-4 col-sm-4 col-xs-4">
                                    <label class="form-label col-md-12 col-sm-4 col-xs-4 mb-1">&nbsp;</label>
                                    <div class="form-group m-0">
                                        <button type="button" id="navigateclient" class="btn btn-primary w-45"
                                            style="margin-right: 1vw" (click)="limitpara(); searchLeaveEncashReport();">
                                            <i class="fa fa-search" aria-hidden="true"></i>Search
                                        </button>
                                        <button type="button" class="btn btn-danger w-45" (click)="clear()">
                                            Clear
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>


        <div class="empty-div-one" style="height: 0px;" id="add-update-client"></div>
        <div class="row">
            <div class="col-xl-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="card-header  border-0">
                        <h4 class="card-title">Leave Encashment Report</h4>
                        <button type="button" class="btn btn-primary ms-auto"
                            (click)="employeeEncashReportCSVDownload()">
                            <i class="fa fa-download" data-bs-toggle="tooltip" data-original-title="download"></i>
                        </button>
                    </div>
                    <div class="card-body">
                        <div class="col-xs-12 mobile view">

                            <div class="scrollsearchresults" infiniteScroll [infiniteScrollDistance]="1"
                                [infiniteScrollUpDistance]="1.5" [infiniteScrollThrottle]="100" [scrollWindow]="false"
                                (scrolled)="onScroll()" id="scrolllength">
                                <table class="table table-hover table-vcenter text-nowrap table-bordered border-bottom"
                                    id="hr-table">
                                    <thead class="tablerow">
                                        <tr class=" stickyatTop rowcolors">
                                            <th class="border-bottom-0 w-5 fs-6 ">Employee ID</th>
                                            <th class="border-bottom-0 w-5 fs-6 ">Name</th>
                                            <th class="border-bottom-0 w-5 fs-6 ">Applied On</th>
                                            <th class="border-bottom-0 w-5 fs-6 ">Leave Type</th>
                                            <th class="border-bottom-0 w-5 fs-6 ">No of Days</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let j=index; let empInfo of leaveEncashmentList ">
                                            <td class="fixedcolumn backgroundFW" data-th="Employee Id">
                                                {{empInfo.empId}}</td>
                                            <td class="fixedcolumn secondcolumn  backgroundFW backgroundSW"
                                                data-th="Employee Name"> {{empInfo.empname}}

                                            </td>
                                            <td data-th="Applied On">
                                                {{empInfo.appliedDate | date: 'mediumDate' }}
                                            </td>
                                            <td data-th="Leave Type">
                                                {{empInfo.leaveType}}</td>
                                            <td data-th="No of Days" style="text-align: right;">
                                                {{empInfo.nod}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>