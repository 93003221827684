<div class="app-content main-content">
    <div class="side-app main-container">
        <div class="alertcomp"></div>
        <div class="alertcompExcelUpload"></div>
        <!-- <div class="card-body px-0">
            <div class="page-leftheader my-3">
                <div class="page-title">PF Statement Report</div>
            </div>
        </div> -->

        <div class="row">
            <div class="col-xl-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="x_content row p-4">
                            <div class="col-md-5 col-sm-4 col-xs-4">
                                <div class="form-group label-floating">
                                    <label class="form-label">Month</label>
                                    <div>
                                        <select id="month" class="form-control" [(ngModel)]="selectedMonth" required
                                            #selectMonth="ngModel"
                                            [ngClass]="(!selectMonth.valid && selectMonth.touched) ? 'is-invalid' : ''">
                                            <option value="">Select Month</option>
                                            <option *ngFor="let month of months" [ngValue]="month">{{ month.name }}
                                            </option>
                                        </select>
                                    </div>
                                    <span class="help-text text-danger"
                                        *ngIf="(!selectMonth.valid && selectMonth.touched)">Please Select Month</span>
                                </div>
                            </div>
                            <div class="col-md-5 col-sm-4 col-xs-4">
                                <div class="form-group label-floating">
                                    <label class="form-label">Year</label>
                                    <div>
                                        <select id="year" class="form-control" [(ngModel)]="year" required
                                            #selectYear="ngModel"
                                            [ngClass]="(!selectYear.valid && selectYear.touched) ? 'is-invalid' : ''">
                                            <option value="" selected>Select Year</option>
                                            <option *ngFor="let year of years" [value]="year">{{ year }}</option>
                                        </select>
                                    </div>
                                    <span class="help-text text-danger"
                                        *ngIf="(!selectYear.valid && selectYear.touched)">Please Select Year</span>
                                </div>
                            </div>
                            <div class="col-md-2 col-sm-4 col-xs-4">
                                <label class="form-label col-md-4 col-sm-4 col-xs-4 ">&nbsp;&nbsp;</label>
                                <button type="button" class="btn btn-primary w-100"
                                    (click)="selectYear.control.markAsTouched(); selectMonth.control.markAsTouched(); executeReport()">
                                    <i class="fa fa-search" aria-hidden="true"></i>Search</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card col-md-12 col-sm-12 col-xs-12">
            <div class="card-header border-bottom-0">
                <h3 class="card-title">PF Statement Report </h3>
            </div>
            <div class="card-body card1S p-0">
                <div class="scrollsearchresults" infiniteScroll [infiniteScrollDistance]="2"
                    [infiniteScrollUpDistance]="1.5" [infiniteScrollThrottle]="100" [scrollWindow]="false"
                    (scrolled)="onScroll()" id="scrolllength">
                    <table class="table table-hover table-vcenter text-nowrap table-bordered border-bottom"
                        id="hr-table">
                        <thead class="tablerow">
                            <tr class="rowcolors stickyatTop">
                                <th class="border-bottom-0 w-5 fixedcolumn wC" rowspan="2">#</th>
                                <th class="border-bottom-0 w-5 secondcolumn wC" rowspan="2">PF No.</th>
                                <th class="border-bottom-0 wC" rowspan="2">NAME</th>
                                <th class="border-bottom-0 wC" rowspan="2">BASIC + DA</th>
                                <th class="border-bottom-0 wC" colspan="3" style="text-align: center;">EMPLOYEE'S SHARE
                                </th>
                                <th class="border-bottom-0 wC" colspan="3" style="text-align: center;">EMPLOYER'S SHARE
                                </th>
                                <th class="border-bottom-0 wC" rowspan="2">TOTAL</th>
                            </tr>

                            <tr class="rowcolors stickyatTop">
                                <th class="border-bottom-0">PF</th>
                                <th class="border-bottom-0">EPF</th>
                                <th class="border-bottom-0">FPF</th>
                                <th class="border-bottom-0">PF</th>
                                <th class="border-bottom-0">EPF</th>
                                <th class="border-bottom-0">EPS</th>

                            </tr>

                        </thead>
                        <tbody style="max-height: 50vh; overflow: scroll;">
                            <tr *ngFor="let pf of reportData; let i = index">
                                <td style="text-align: right;">{{i+1}}</td>
                                <td style="text-align: right;">{{pf.statutoryNo}}</td>
                                <td>{{pf.employeeName}}</td>
                                <td style="text-align: right;">{{pf.grossSalary}}</td>
                                <td style="text-align: right;">{{pf.salary}}</td>
                                <td style="text-align: right;">{{pf.salary}}</td>
                                <td style="text-align: right;">0.00</td>
                                <td style="text-align: right;">{{pf.salary}}</td>
                                <td style="text-align: right;">{{pf.epfepsDiffamount}}</td>
                                <td style="text-align: right;">{{pf.epsAmount}}</td>
                                <td style="text-align: right;">{{pf.total}}</td>
                            </tr>
                        </tbody>

                    </table>
                </div>
            </div>
        </div>
    </div>
</div>