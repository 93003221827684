import { Component, OnInit, HostListener, Renderer2 } from '@angular/core';
import { AlertService, ConstantService, SessionService } from 'src/app/services';
import { EmployeeDocStatusUpdates } from '../models/empDocStatusUpdates';
import { EmployeeMasterservice } from 'src/app/services/employeemaster.service';
import { Router } from '@angular/router';
import { EmployeeMaster } from '../models/employeeMasterts';
import { EmployeeLeaveService } from '../services/employee-leave.service';
import { DocumentInfoService } from '../services/documentinfo.service';
import { EmployeeDocService } from '../services/employee-doc.service';
import { DepartmentInfo } from '../models/departmentInfo';
import { MyDocuments } from '../models/mydocuents';
import { Menu } from '../services/menu.Service';
declare var $: any;


@Component({
  selector: 'app-employeedocsearch',
  templateUrl: './employeedocsearch.component.html',
  styleUrls: ['./employeedocsearch.component.css']
})
export class EmployeeDocSearchComponent implements OnInit {
  employeeDocStatusList: EmployeeDocStatusUpdates[];
  employeeDocStatusUpdates: EmployeeDocStatusUpdates;
  uniqueEmployeeId: String;
  employeeMasterLucene: EmployeeMaster[];
  query: any;
  docid_tobe_deleted: number;
  filteredDepartmentNames: DepartmentInfo[];
  filteredDocType: MyDocuments[];

  // datas must load when when we scroll down
  limitPara: any;
  offsetPara: any;
  id: any;
  fileSavedPath: any;
  type: any;
  f_Name: any;

  documentDropDown: any = "All";
  statusDropDown: any = "All";
  departmentDropDown: any = "All";
  fromdate:string;
  todate:string;
  selectedValue: string = 'Active';
  selectedempid: String;
  leavingdate: any;
  permissionList: any[] = [];
  showBasedOnPermissionEmployeeStatus: boolean = false;
  allowToApproved:boolean=false;
  constructor(private render: Renderer2, private router: Router, private alertservice: AlertService, 
    private constantService: ConstantService, private sessionService: SessionService, 
    private employeeMasterservice: EmployeeMasterservice, private empLeaveService: EmployeeLeaveService,
     private docmentInfoService: DocumentInfoService, private empdocservice: EmployeeDocService,
    private menuService: Menu) { }

  // 

  ngOnInit() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    this.fromdate = `${year}-${month.toString().padStart(2, '0')}-01`;
    const lastDay = new Date(year, month, 0).getDate();
    this.todate = `${year}-${month.toString().padStart(2, '0')}-${lastDay.toString().padStart(2, '0')}`;

    const maxString = sessionStorage.getItem('permission').replace('[', '');
    const maxStringBox = maxString.replace(']', '');
    const maxStringAgain = maxStringBox.replace(/"/g, '');
    this.permissionList = maxStringAgain.split(',');
    this.checkAccessForUser();

    this.employeeDocStatusList = new Array<EmployeeDocStatusUpdates>();
    this.employeeDocStatusUpdates = new EmployeeDocStatusUpdates;

    this.limitPara = 0;
    this.offsetPara = 12;

    this.getDepartment();
    this.getDocType();
    // this.searchEmployeeDoc();

    this.render.listen(document.getElementById('navigatetarget'), 'click', () => {
      $('html,body').animate({
        scrollTop: $(`#navigatedest`).offset().top - 90
      },
        'slow');
    })



  }
  //   ngDoCheck(){
  //     (<HTMLInputElement>document.querySelector('.focusin')).focus();
  //  }


  // ngAfterViewChecked() {
  //   //    Activate bootstrap-select
  //   if ($(".selectpicker").length != 0) {
  //     $(".selectpicker").selectpicker();
  //   }
  // }

  searchEmployeeDoc() {
    var employeename = (<HTMLInputElement>document.getElementById('employeename')).value;
    if (!employeename) {
      employeename = null;
    } else {
      employeename = employeename.trim()
    }
    this.uniqueEmployeeId = this.selectedempid;
    // here below uncomment it .............

    console.log("The Employee Id is ", this.uniqueEmployeeId);
    if (this.uniqueEmployeeId === undefined) {
      this.uniqueEmployeeId = null
    }

    this.employeeMasterservice.getEmployeeMasterDoccument(this.uniqueEmployeeId, employeename,this.selectedValue, this.fromdate, this.todate,this.departmentDropDown, this.statusDropDown, this.documentDropDown, this.limitPara, this.offsetPara).subscribe(

      data => {
        if (this.limitPara == 0) {
          this.employeeDocStatusList = data.employeeDocStatusUpdates;
          console.log("printing ",data.employeeDocStatusUpdates)
        } else {
          this.employeeDocStatusList = this.employeeDocStatusList.concat(data.employeeDocStatusUpdates);
        }
        console.log("Emp Doc after", this.employeeDocStatusList);
        console.log("check this")
        console.log(this.employeeDocStatusList.length)
        if (this.employeeDocStatusList.length === 0) {
          this.alertmessages("No records found , Please Try Again !  ", "danger");
        }
        this.scrollLength();
      }, error => {
        console.log(error);
        this.employeeDocStatusUpdates = new EmployeeDocStatusUpdates;
        (<HTMLInputElement>document.getElementById('employeename')).value = "";
      }
    );


  }

  scrollLength() {
    console.log("dynamic height on scroll ");
    console.log("");
    const regex = /^(\d+(?:\.\d+)?)\s*([a-zA-Z%]+)$/;
    const height = getComputedStyle(document.getElementById("scrolllength")).getPropertyValue("max-height")
    const computedHeight = height.match(regex);
    console.log("computed height", computedHeight, "computed height in no ", Number(computedHeight[1]), "height ", height)
    if (computedHeight && Number(computedHeight[1]) < 450) {
      let value: any = Number(computedHeight[1]);
      console.log("default value = ", value)
      value = value + (2 * 55);
      value = value + "px";
      console.log("final vl:", value)
      console.log("transition")
      document.getElementById("scrolllength").style.maxHeight = value
    }
    console.log("");
  }

  myDocumentDownload(filName: any, path: any) {

    this.fileSavedPath = path;
    console.log("The File Saved Path is", this.fileSavedPath);
    //this.fileSavedPath = '../../../assets/uploads/';
    this.type = filName.split('.').pop();
    this.f_Name = filName;

    //this.downLoadFile(this.fileSavedPath, this.type, this.f_Name);
    this.downloadImage();

  }

  downloadImage() {
    console.log("Download Image is called");
    this.docmentInfoService.downloadFile(this.fileSavedPath).subscribe(
      data => {
        var downloadUrl = URL.createObjectURL(data);
        var link = document.createElement("a");
        link.download = this.f_Name;
        link.href = downloadUrl;
        link.click();
        link.remove();
        console.log("The Url is", downloadUrl);
      },
      error => {
        console.log('Error occured in download file');
      }
    );
  }

  onScroll() {
    this.limitPara = this.limitPara + 12;
    this.searchEmployeeDoc();
  }

  limitpara() {
    this.limitPara = 0;
  }

  @HostListener('scroll', ['$event'])
  scrolled() {
    this.onScroll();
    const ele = document.getElementById('scroll')
    console.log(ele.scrollTop)
  }

  getEmployeeByNameOrId() {
    console.log("Len ", this.query);
    if (this.query.length <= 2) {
        this.employeeMasterLucene = [];
    }
    if (this.query.length >= 3) {
        this.empLeaveService.getEmployeeByNameOrUniqueId(this.query).subscribe(
            data => {
                console.log("Emp Data ", data);

                // Check if data.employeeMasterData has any entries
                if (data.employeeMasterData.length > 0) {
                    let filteredEmployees = data.employeeMasterData;

                    // Get the current date for comparison
                    const currentDate = new Date();
                    console.log("Current Date: ", currentDate);  // Log current date for debugging

                    // Apply filtering logic based on the selected employee status
                    if (this.selectedValue === 'Active') {
                        filteredEmployees = filteredEmployees.filter(emp => {
                            const leavingDate = emp.leavingdateString ? new Date(emp.leavingdateString) : null;
                            console.log("Leaving Date for " + emp.name + ": ", leavingDate);  // Log leaving date for debugging
                            // Active employees should have leavingdate in the future or null
                            return leavingDate === null || leavingDate > currentDate;
                        });
                    } else if (this.selectedValue === 'Inactive') {
                        filteredEmployees = filteredEmployees.filter(emp => {
                            const leavingDate = emp.leavingdateString ? new Date(emp.leavingdateString) : null;
                            console.log("Leaving Date for " + emp.name + ": ", leavingDate);  // Log leaving date for debugging
                            // Inactive employees should have leavingdate in the past or null
                            return leavingDate && leavingDate < currentDate;
                        });
                    }

                    // If the status is 'All', no filter is applied
                    if (this.selectedValue === 'All') {
                        filteredEmployees = data.employeeMasterData;
                    }

                    // Assign filtered data to the employeeMasterLucene
                    this.employeeMasterLucene = filteredEmployees;

                    // Log the data and leaving date of the first employee in the filtered list
                    if (this.employeeMasterLucene.length > 0) {
                        this.leavingdate = this.employeeMasterLucene[0].leavingdateString; // Extract the leaving date
                        console.log("Filtered Employee Data: ", this.employeeMasterLucene);
                        console.log("leavingdate", this.leavingdate);
                    } else {
                        console.log("No employee data found.");
                    }
                } else {
                    console.log("No employee data found.");
                }

                this.selectedempid = this.employeeMasterLucene[0]?.uniqueemployeeid?.toString() || '';
                
            },
            error => {
                console.log("Error ", error);
            }
        )
    }
}
onStatusChange() {
  this.getEmployeeByNameOrId();
}

  getUserRole() {
    return this.sessionService.getLoggedUserRole();
  }

  delete_verifieddocument(e, empdoc: EmployeeDocStatusUpdates) {
    console.log(empdoc)
    this.docid_tobe_deleted = empdoc.id
    $('#showAlertVer').modal('toggle');
  }

  closeMRQModal() {
    $('#showAlertVer').modal('toggle');
  }

  confirmDocDelete() {


    this.empdocservice.deleteVerifiedDoc(this.docid_tobe_deleted).subscribe({
      next: (res) => {
        this.alertmessages("Successfully deleted the document", "success")
      },
      error: (error) => {
        this.alertmessages("please try again ", "danger")
      },
      complete: () => {
        this.closeMRQModal();
      }
    })
  }

  alertmessages(message: string, sign: string): void {
    let alertDiv = document.createElement('div');
    alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
    <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message}</div>`
    let alertcomp = document.querySelector('.alertcomp');
    alertcomp.append(alertDiv);
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    setTimeout(function () {
      alertcomp.removeChild(alertDiv);
    }, 3000);
  }

  getDepartment() {
    this.employeeMasterservice.getDepartment().subscribe(data => {
      console.log("get department list here from data bsse ");
      console.log(data);
      this.filteredDepartmentNames = data;
    }, error => { console.log(error) });
  }

  getDocType() {
    this.employeeMasterservice.getDocType().subscribe(data => {
      console.log("get documment type  list here from data bsse ")
      console.log(data);
      this.filteredDocType = data;
    }, error => { console.log(error) });
  }
  checkAccessForUser() {
    console.log("checkAccessForUser here")
    this.showBasedOnPermissionEmployeeStatus = this.permissionList.includes('ng.employee.search.show.employeestatus');
    this.allowToApproved = this.permissionList.includes('ng.screen.docmgmt.approvalInbox');

  }
  setMenuName(name: string) {
		this.menuService.setMenuName(name);
	  }
}
