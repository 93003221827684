export class ITSlabDetails{
    id: ""; 
	lowerlimit: "";
	upperlimit: "";
	eduCessPercent: "";
	maleApplicable: "";
	femaleApplicable: "";
    selectRegimeId:  "" | "1" | "0";
    newRegime:"";
	subDetails: subDetailsList[] = [];
}

export class subDetailsList {
    
    sublowerlimit: '';
    subupperlimit: '';
    subeducesspercent: '';
    userId: '';
}
