<div class="app-content main-content">
    <div class="side-app main-container">
        <div class="alertcomp"></div>
        <!-- <div class="card-body px-0">
            <div class="page-leftheader my-3">
                <div class="page-title">Apply For OverTime</div>
            </div>
        </div> -->
        <form #overtime="ngForm">
            <div class="row">
                <div class="col-xl-12 col-md-12 col-lg-12">
                    <div class="card" style="margin-bottom:10px">
                        <div class="card-body row">
                            <div class="col-md-3 col-sm-3 col-xs-3">
                                <div class="form-group label-floating col-xl-12 col-md-12 col-lg-12">
                                    <label class="form-label">Enter Date<span style="color: rgb(248, 111, 111);">
                                            &nbsp;*</span></label>
                                    <div class="input-group">
                                        <input required type="date" id="singledate" name="singledaterange"
                                            class="form-control" placeholder="select date" [(ngModel)]="date"
                                            (change)="dateFocus()" />
                                    </div>

                                </div>
                            </div>
                            <div class="col-md-3 col-sm-3 col-xs-3">
                                <div class="form-group label-floating col-xl-12 col-md-12 col-lg-12">
                                    <label class="form-label">From Time <span style="color: rgb(248, 111, 111);">
                                            &nbsp;*</span> </label>
                                    <div> {{fromTime == undefined ? '--' : fromTime| date: 'shortTime' }} </div>

                                </div>
                            </div>
                            <div class="col-md-3 col-sm-3 col-xs-3">
                                <div class="form-group label-floating col-xl-12 col-md-12 col-lg-12">
                                    <label class="form-label">To Time <span style="color: rgb(248, 111, 111);">
                                            &nbsp;*</span></label>
                                    <div>{{toTime == undefined ? '--' : toTime| date: 'shortTime'}}</div>

                                </div>
                            </div>
                            <div class="col-md-3 col-sm-3 col-xs-3">
                                <div class="form-group label-floating col-xl-12 col-md-12 col-lg-12">
                                    <label class="form-label">Total Time</label>
                                    <div>{{totalMinutes == undefined ? '--' : totalMinutes }}</div>
                                </div>
                            </div>

                            <div class="col-md-3 col-sm-3 col-xs-3">
                                <div class="form-group label-floating col-xl-12 col-md-12 col-lg-12">
                                    <label class="form-label">Total OT Minutes</label>
                                    <div>{{totalOTMinutes == undefined  ? '--' : totalOTMinutes }}</div>
                                </div>
                            </div>
                            <div class="col-md-3 col-sm-4 col-xs-4">
                                <div class="form-group label-floating  col-xl-12 col-md-12 col-lg-12">
                                    <label class="form-label">Reason<span style="color: rgb(248, 111, 111);">
                                            &nbsp;*</span></label>
                                    <textarea required id="reason" [(ngModel)]="reason" #reasonInput="ngModel"
                                        class="form-control" rows="1" placeholder="Please enter the reason."
                                        [ngClass]="(!reasonInput.valid && reasonInput.touched) ? 'is-invalid' : ''"></textarea>
                                    <span class="help-text text-danger"
                                        *ngIf="(!reasonInput.valid && reasonInput.touched)">Enter the reason
                                    </span>
                                </div>
                            </div>

                            <div class="col-md-1">
                                <div class="d-flex" style="margin-top:28px;">
                                    <button [disabled] class="btn btn-success" type="submit"
                                        (click)="submit()">Submit</button>
                                    <button type="button" class="btn btn-danger ms-2" (click)="clear()">Clear</button>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="card">
                        <div class="card-body">
                            <div class="col-md-12 col-sm-12 col-xs-12">
                                <div class="col-md-12 col-sm-12 col-xs-12">
                                    <div class="card-header border-bottom-0">
                                        <h3 class="card-title">OT Summary</h3>
                                    </div>
                                    <div class="card-body card1S p-0">
                                        <div class="table-responsive">
                                            <div class="scrollsearchresults" infiniteScroll (scrolled)="onScroll()" id="scrolllength">
                                            <table
                                                class="table table-hover table-vcenter text-nowrap table-bordered border-bottom"
                                                id="hr-table">
                                                <thead class="tablerow">
                                                    <tr class="rowcolors stickyatTop">

                                                        <th class="border-bottom-0 w-5 fs-6 ">Applied Date </th>
                                                        <th class="border-bottom-0 w-5 fs-6 ">Total OT Minutes </th>
                                                        <th class="border-bottom-0 w-5 fs-6 ">Status</th>
                                                        <th class="border-bottom-0 w-5 fs-6 ">Approved By</th>
                                                        <th class="border-bottom-0 w-5 fs-6 ">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody style="max-height: 50vh; overflow: scroll;">
                                                    <tr *ngFor="let eto of OverTimeOutlist; let i = index">

                                                        <td>{{eto.date| date: 'mediumDate' }}</td>
                                                        <td style="text-align: right;">{{eto.totalMinutes }}</td>

                                                        <td>

                                                            <span style="font-size: 13px;font-weight: 500;"
                                                                [ngClass]="eto.status === 'Approved' ? 'bg-custom-green text-custom-dark' : 
                                                                eto.status === 'Over Time Cancel Pending' ? 'bg-custom-orange1 text-custom-dark' : 
                                                                eto.status === 'Over Time Approve Pending' ? 'bg-custom-orange2 text-custom-dark' :  eto.status === 'Rejected' ? 'bg-custom-red text-custom-dark' : eto.status === 'Over Time Cancel Rejected' ? 'badge-danger':eto.status === 'Over Time Cancel Pending' ? 'badge-warning text-custom-dark' : 'bg-custom-warning text-custom-dark'"
                                                                class="badge">{{eto.status}}</span>

                                                        </td>
                                                        <td>{{eto.updatedby }}</td>
                                                        <td class="text-start d-flex">
                                                            <button
                                                                *ngIf="eto.status !== 'Extra Time Out Cancel Rejected'"
                                                                type="button" (click)="onDeleteHandler(eto)"
                                                                class="btn btn-danger btn-icon btn-sm"
                                                                data-bs-toggle="tooltip" data-bs-placement="top"
                                                                title="Delete">
                                                                <i class="feather feather-trash-2 "></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>



    <div id="showAlertVer" class="modal" style="top: 40px;">
        <div class="modal-dialog">
            <div class="modal-content animate">
                <div class="modal-header">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">×</button>
                    <h4 class="modal-title">Warning</h4>
                </div>

                <div class="modal-body" style="max-height: calc(100vh - 210px);overflow-y: auto;">
                    <!-- auto scroll -->
                    <span><b> Applied OT will be deleted permanently. Are you sure you want to continue?</b></span>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-danger medium" (click)="confirmDelete()">OK</button>
                    <button type="button" class="btn btn-primary" (click)="closeMRQModal()">Cancel</button>
                </div>

            </div>
        </div>
    </div>