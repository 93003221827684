<div class="app-content main-content">
    <div class="side-app main-container">
        <div class="alertcomp"></div>
        <div class="row">
            <div class="col-xl-12 col-md-12 col-lg-12 mobileview">
                <div class="card">
                    <div class="card-body">
                        <form id="fcForm" class="form-horizontal form-label-left row">
                            <div class="row col-md-12 col-sm-12 col-xs-12">
                                <!-- Submission Date (Auto-Populated, Non-Editable) -->
                                <div class="col-md-3 col-sm-3 col-xs-3">
                                    <div class="form-group label-floating">
                                        <label class="form-label">Submission Date</label>
                                        <div>
                                            <input type="date" name="submission_date"
                                                class="form-control col-md-11 col-xs-12 small-input"
                                                [(ngModel)]="submissionDate" readonly />
                                        </div>
                                    </div>
                                </div>

                                <!-- Notice Period in Days (Non-Editable) -->
                                <div class="col-md-3 col-sm-3 col-xs-3">
                                    <div class="form-group label-floating">
                                        <label class="form-label">Notice Period (in days)</label>
                                        <div>
                                            <input type="number" class="form-control col-md- col-xs-12 small-input"
                                                style="text-align:center" name="noticePeriod" [(ngModel)]="notice_Period"
                                                readonly />
                                        </div>
                                    </div>
                                </div>
                                <!-- Last Working Date (Auto-Calculated, Non-Editable) -->
                                <div class="col-md-3">
                                    <div class="form-group label-floating">
                                        <label class="form-label">Last Working Date</label>
                                        <input type="date" class="form-control col-md-11 col-xs-12 small-input"
                                        [(ngModel)]="lastWorkingDate" name="lwd" readonly />
                                    </div>
                                </div>
                                 <!-- Expected Last Working Date (Non-Editable) -->
                                 <div class="col-md-3 col-sm-3 col-xs-3">
                                    <div class="form-group label-floating">
                                        <label class="form-label">Requested Last Working Date<span style="color: rgb(248, 111, 111);">
                                            &nbsp;*</span></label>
                                        <div>
                                            <input type="date" name="requested_last_working_date"
                                                class="form-control col-md-11 col-xs-12 small-input"
                                                [(ngModel)]="requestedLastWorkingDate"
                                                (change)="updateFromrequestedLastWorkingDate($event)" />
                                        </div>
                                    </div>
                                </div>

                                <!-- Required Exit Date (Editable) -->
                                <!-- <div class="col-md-3 col-sm-3 col-xs-3" *ngIf="showRequestedEditableDate">
                                    <div class="form-group label-floating">
                                        <label class="form-label">Required editable Date</label>
                                        <div>
                                            <input type="date" name="required_exit_date"
                                                class="form-control col-md-11 col-xs-12 small-input"
                                                [(ngModel)]="requiredEditableDate"
                                                (change)="updateFromRequestedEditableDate($event)" />
                                        </div>
                                    </div>
                                </div> -->

                                <!-- Reason Dropdown -->
                                <div class="col-md-3 col-sm-4 col-xs-4">
                                    <div class="form-group label-floating">
                                        <label class="form-label" for="Reason">Reason<span style="color: rgb(248, 111, 111);">
                                            &nbsp;*</span></label>
                                        <div class="w-100">
                                            <select class="form-control col-md-12 col-xs-12"
                                                data-style="btn btn-drpdwn btn-round" id="Reason" [(ngModel)]="reason">
                                                <option>Career & Job Growth</option>
                                                <option>Compensation & Financial Issues</option>
                                                <option>Family & Personal Reasons</option>
                                                <option>Relocation & Migration</option>
                                                <option>Medical Emergency</option>
                                                <option>Taking Break</option>
                                                <option>Others</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <!-- Remarks -->
                                <div class="col-md-6 col-sm-4 col-xs-4">
                                    <div class="form-group label-floating">
                                        <label for="form-label" class="form-label">Remarks<span style="color: rgb(248, 111, 111);">
                                            &nbsp;*</span></label>
                                        <input type="text" class="form-control col-md-12 col-xs-12 small-input"
                                            autofocus="true" autocomplete="off" placeholder="Remarks" name="remarks"
                                            [(ngModel)]="remarks" />
                                    </div>
                                </div>
                            </div>

                            <!-- Action Buttons -->
                            <div class="actionBar">
                                <div class="clearfix"></div>
                                <div class="pro-fab">
                                    <button class="btn btn-success" type="button" (click)="submit()">
                                        Save
                                    </button>
                                    <button class="btn btn-primary ms-2" type="button" *ngIf="isEditing" (click)="updateResignation()">
                                        Update
                                    </button>
                                    <button class="btn btn-danger ms-2" type="button" (click)="clear()">
                                        Clear
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <!-- List of Resignations Table -->
        <div class="card col-md-12 col-sm-12 col-xs-12">
            <div class="card-header border-bottom-0">
                <h3 class="card-title">Submitted Resignations</h3>
            </div>
            <div class="card-body card1S p-0">
                <div class="table-responsive">
                    <div class="scrollsearchresults" id="scrolllength" id="scroll" infiniteScroll
                        [infiniteScrollDistance]="2" [infiniteScrollUpDistance]="1.5" [infiniteScrollThrottle]="100"
                        [scrollWindow]="false" (scrolled)="onScroll()">
                        <table class="table table-hover table-vcenter text-nowrap table-bordered border-bottom"
                            id="hr-table">
                            <thead class="tablerow">
                                <tr class="rowcolors stickyatTop">
                                    <th class="border-bottom-0 fs-7">Emp ID</th>
                                    <th class="border-bottom-0 fs-7">Employee Name</th>
                                    <th class="border-bottom-0 fs-7">Submission Date</th>
                                    <th class="border-bottom-0 fs-7">Notice Period</th>
                                    <th class="border-bottom-0 fs-7">Last Working Date</th>
                                    <th class="border-bottom-0 fs-7" >Reason</th>
                                    <th class="border-bottom-0 fs-7" >Remarks</th>  
                                    <th class="border-bottom-0 fs-7">Requested Last Working Date</th>
                                    <th class="border-bottom-0 fs-7">Approved Exit Date</th>
                                    <th class="border-bottom-0 fs-7">Approved By</th>                               
                                    <th class="border-bottom-0 fs-7" >Status</th>
                                    <!-- <th class="border-bottom-0 fs-7" style="padding-right:60px">Approved By</th> -->
                                    <th class="border-bottom-0 fs-7">Action</th>
                                </tr>
                            </thead>
                            <tbody style="max-height: 50vh; overflow: scroll; padding-bottom: 10vh;">
                                <tr *ngFor="let res of resignations_List; let i = index">
                                    <td>{{ res.employeeId}}</td>
                                    <td>{{ res.employeeName }}</td>
                                    <td>{{ res.submissionDate | date: 'mediumDate' }}</td>
                                    <td>{{ res.notice_Period }}</td>
                                    <td>{{ res.lastWorkingDate | date: 'mediumDate' }}</td>
                                    <td>{{ res.reason }}</td>
                                    <td>{{ res.remarks }}</td>
                                    <td>{{ res.requestedLastWorkingDate | date: 'mediumDate' }}</td>
                                    <td>{{res.approverDate  | date: 'mediumDate'}}</td>
                                    <td>{{ res.approvedBy }}</td>
                                    

                                    <td>
                                        <span style="font-size: 13px; font-weight: 500;"
                                        [ngClass]="res.status === 'Approved' ? 'badge-success' : 
                                        res.status === 'Partial Approve 1' ? 'bg-custom-orange1 text-custom-dark' : 
                                        res.status === 'Partial Approve 2' ? 'bg-custom-orange2 text-custom-dark' : 
                                        res.status === 'Resignation Approve Pending' ? 'bg-custom-warning text-custom-dark' : 
                                        reason.status === 'Rejected' ? 'badge-danger' : 'bg-custom-warning text-custom-dark'"
                                        class="badge">{{res.status}}</span>
                                    </td>
                                    <td>
                                        <button class="btn btn-primary btn-icon btn-sm me-2" data-method="edit" title="Edit"
                                        (click)="editResignation(res)">
                                        <i class="fa fa-edit" data-bs-toggle="tooltip" data-original-title="edit"></i>
                                    </button>

                                        <button class="btn btn-danger btn-icon btn-sm me-2" title="Delete"
                                            (click)="onDeleteHandler(res.id)">
                                            <i class="fa fa-trash" data-bs-toggle="tooltip"
                                                data-original-title="Delete"></i>
                                        </button>
                                    </td>
                                </tr>
                                <!-- Dynamic rows go here -->
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div id="showAlertVer" class="modal" style="top: 40px;">
    <div class="modal-dialog">
        <div class="modal-content animate">
            <div class="modal-header">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">×</button>
                <h4 class="modal-title">Warning</h4>
            </div>

            <div class="modal-body" style="max-height: calc(100vh - 210px);overflow-y: auto;">
                <span><b> Resignation Applied data will be deleted permanently. Are you sure you want to
                        continue?</b></span>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-danger medium" (click)="deleteresignation()">OK</button>
                <button type="button" class="btn btn-primary" (click)="closeMRQModal()">Cancel</button>
            </div>

        </div>
    </div>
</div>