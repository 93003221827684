import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import { EmployeeShiftService } from '../services/employee-shift.service';
import { breakDetail, shiftMaster } from '../models/shiftMaster';
declare var $: any;
@Component({
  selector: 'app-shift-master',
  templateUrl: './shift-master.component.html',
  styleUrls: ['./shift-master.component.css']
})
export class ShiftMasterComponent implements OnInit {
  otOption: string = 'hours'; // To hold the selected radio button value



  resetValues() {
    if (this.otOption === 'time') {
      this.insertShiftMasterDetails.otStartMins = null; // Reset hours field when switching to time
    } else if (this.otOption === 'hours') {
      this.insertShiftMasterDetails.otStartTime = null; // Reset time field when switching to hours
    }
  }

  constructor(private shiftService: EmployeeShiftService) {

  }


  shiftRuleDetail: any[] = [];
  shiftMasterInfo;
  ShiftMasterData: shiftMaster[];
  DeleteShiftMaster: shiftMaster;
  insertBreakDetail;
  insertShiftMasterDetails;
  add_view_toggle: string = 'viewShift';
  addOrUpdate: boolean = true;




  @ViewChild('shiftform', { static: false }) shift: NgForm;
  @ViewChild('shift_Name') shiftcontol: NgModel
  @ViewChild('BreakForm') BreakForm: NgForm;
  @ViewChild('DaysForm') DaysForm: NgForm;

  shifts: any[];
  dailyHoursWorked

  ngOnInit(): void {
    this.viewShift();
    this.getShiftType();
    this.insertBreakDetail = new breakDetail();
    this.insertShiftMasterDetails = new shiftMaster();
    this.convertShiftTimesToHHMM();
    if (this.insertShiftMasterDetails.dailyWorkHrs) {
      this.insertShiftMasterDetails.dailyWorkHrs = this.convertDecimalToTime(this.insertShiftMasterDetails.dailyWorkHrs);
    }
    if (this.insertShiftMasterDetails.otStartMins) {
      this.insertShiftMasterDetails.otStartMins = this.convertMinutesToTime(this.insertShiftMasterDetails.otStartMins);
    }

  }



  editShift(editId: any) {
    console.log("edited function ", editId);
    this.clear();
    this.shiftService.getEditShiftMaster(editId).subscribe(

      (data) => {
        console.log(data);
        this.insertShiftMasterDetails = data.shiftMasterdto[0];
        console.log("this.insertShiftMasterDetails", this.insertShiftMasterDetails);
        console.log("data.shiftMasterdto[0].breakList.breakId", data.shiftMasterdto[0].breakList[0].breakId);
        if (data.shiftMasterdto[0].breakList[0].breakId != 0) {
          this.shiftRuleDetail = data.shiftMasterdto[0].breakList
        }
        console.log("this.insertShiftMasterDetails", this.insertShiftMasterDetails);
        console.log("this.shiftRuleDetail", this.shiftRuleDetail);
        // this.getITSlabMasterData();
        this.convertShiftTimesToHHMM();
        if (data.shiftMasterdto[0].shiftAllowance !== null) {
          this.insertShiftMasterDetails.shiftAllowance = data.shiftMasterdto[0].shiftAllowance; // This will include 0
      } else {
          this.insertShiftMasterDetails.shiftAllowance = null; // Set to null only if it's explicitly null
      }
        if (data.shiftMasterdto[0].dailyWorkHrs !== null && data.shiftMasterdto[0].dailyWorkHrs !== 0) {
          this.insertShiftMasterDetails.dailyWorkHrs = this.convertDecimalToTime(data.shiftMasterdto[0].dailyWorkHrs);
        } else {
          this.insertShiftMasterDetails.dailyWorkHrs = null;
        }
        if (data.shiftMasterdto[0].otStartMins !== null && data.shiftMasterdto[0].otStartMins !== 0) {
          this.insertShiftMasterDetails.otStartMins = this.convertMinutesToTime(data.shiftMasterdto[0].otStartMins);
        } else {
          this.insertShiftMasterDetails.otStartMins = null;
        }
        if (data.shiftMasterdto[0].otStartMins) {
          this.otOption = 'hours'; // Set radio button to 'hours' if otStartMins is provided
        } else if (data.shiftMasterdto[0].otStartTime) {
          this.otOption = 'time'; // Set radio button to 'time' if otStartTime is provided
        }
        else { this.otOption = 'hours'; }

        this.addOrUpdate = false;  //for button change
        this.add_view_toggle = 'addShift';
      }, (error) => {
        console.log(error)
        this.alertmessages("Please Try Again", "danger");
      });


    console.log("addOrUpdate ", this.addOrUpdate);

  }

  initializeDayFields() {
    const days = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
    days.forEach(day => {
      this.insertShiftMasterDetails[`${day}HalfDayMins`] = '00:00';
      this.insertShiftMasterDetails[`${day}FullDayMins`] = '00:00';
    });
  }
  onFlexibleShiftChange() {
    if (this.insertShiftMasterDetails.flexibleShift) {
    } else {
      this.insertShiftMasterDetails.lateComing = null; // or '' if you prefer an empty string
      this.insertShiftMasterDetails.earlyLeaving = null; // or ''
    }
  }

  clear() {

    console.log("clear clicked ");
    console.log("1", this.shiftRuleDetail);

    // Resetting the shift master details to initial state
    this.insertShiftMasterDetails = new shiftMaster(); // Reset to a new instance
    this.shiftRuleDetail = []; // Clear the break rules list
    this.initializeDayFields();

    console.log("2", this.shiftRuleDetail);

    this.otOption = 'hours';

    // If you're using forms, reset them as well
    if (this.shift) {
      this.shift.resetForm(); // Reset the shift form
    }
    if (this.BreakForm) {
      this.BreakForm.resetForm(); // Reset the break form
    }
  }


  // Convert decimal (e.g., 1.5) to HH:MM format (e.g., 01:30)
  convertDecimalToTime(decimal: number): string {
    const hours = Math.floor(decimal);   // Get the number of hours
    const minutes = Math.round((decimal - hours) * 60);  // Get the fractional part (minutes)
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;  // Format as HH:MM
  }

  // Convert HH:MM format (e.g., 01:30) to decimal (e.g., 1.5)
  convertTimeToDecimal(time: string): number {
    const [hours, minutes] = time.split(':').map(Number);  // Split the time string into hours and minutes
    return parseFloat((hours + minutes / 60).toFixed(5));  // Convert to decimal format
  }

  convertTimeToMinutes(time: string): number {
    const [hours, minutes] = time.split(':').map(Number);  // Split "01:30" into [1, 30]
    return hours * 60 + minutes;  // Convert to total minutes
  }

  convertMinutesToTime(minutes: number): string {
    const hours = Math.floor(minutes / 60);          // Calculate hours
    const remainingMinutes = minutes % 60;           // Calculate remaining minutes
    return `${hours.toString().padStart(2, '0')}:${remainingMinutes.toString().padStart(2, '0')}`;  // Format as "HH:MM"
  }

  convertShiftTimesToMinutes(): void {
    const days = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];

    days.forEach(day => {
      const halfDayKey = `${day}HalfDayMins`;
      const fullDayKey = `${day}FullDayMins`;

      // Convert HalfDayMins from "HH:MM" to minutes if it exists
      if (this.insertShiftMasterDetails[halfDayKey]) {
        this.insertShiftMasterDetails[halfDayKey] = this.convertTimeToMinutes(this.insertShiftMasterDetails[halfDayKey]);
      }

      // Convert FullDayMins from "HH:MM" to minutes if it exists
      if (this.insertShiftMasterDetails[fullDayKey]) {
        this.insertShiftMasterDetails[fullDayKey] = this.convertTimeToMinutes(this.insertShiftMasterDetails[fullDayKey]);
      }
    });
  }
  convertShiftTimesToHHMM(): void {
    const days = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];

    days.forEach(day => {
      const halfDayKey = `${day}HalfDayMins`;
      const fullDayKey = `${day}FullDayMins`;

      // Convert HalfDayMins from minutes back to "HH:MM"
      if (this.insertShiftMasterDetails[halfDayKey] !== undefined) {
        this.insertShiftMasterDetails[halfDayKey] = this.convertMinutesToTime(this.insertShiftMasterDetails[halfDayKey]);
      }

      // Convert FullDayMins from minutes back to "HH:MM"
      if (this.insertShiftMasterDetails[fullDayKey] !== undefined) {
        this.insertShiftMasterDetails[fullDayKey] = this.convertMinutesToTime(this.insertShiftMasterDetails[fullDayKey]);
      }
    });
  }

 

 submit() {
    console.log(" save ", this.insertShiftMasterDetails);
    console.log(" fromtime ", this.insertShiftMasterDetails.fromTimings);
    console.log(" totime ", this.insertShiftMasterDetails.toTimings);
    console.log(" fromtime ", this.insertShiftMasterDetails.otStartTime);
    console.log(" totime ", this.insertShiftMasterDetails.otStartMins);
    console.log(" totime ", this.insertShiftMasterDetails.otRule);

    console.log(" sunfull ", this.insertShiftMasterDetails.sunFullDayMins);
    console.log(" sunhalf ", this.insertShiftMasterDetails.sunHalfDayMins);

    console.log(" monfull ", this.insertShiftMasterDetails.monFullDayMins);
    console.log(" monhalf ", this.insertShiftMasterDetails.monHalfDayMins);

    console.log(" tuesfull ", this.insertShiftMasterDetails.tueFullDayMins);
    console.log(" tueshalf ", this.insertShiftMasterDetails.tueHalfDayMins);

    console.log(" wedfull ", this.insertShiftMasterDetails.wedFullDayMins);
    console.log(" wedhalf ", this.insertShiftMasterDetails.wedHalfDayMins);

    console.log(" thufull ", this.insertShiftMasterDetails.thuFullDayMins);
    console.log(" thuhalf ", this.insertShiftMasterDetails.thuHalfDayMins);

    console.log(" frifull ", this.insertShiftMasterDetails.friFullDayMins);
    console.log(" frihalf ", this.insertShiftMasterDetails.friHalfDayMins);

    console.log(" satfull ", this.insertShiftMasterDetails.satFullDayMins);
    console.log(" sathalf ", this.insertShiftMasterDetails.satHalfDayMins);

    if (!this.insertShiftMasterDetails.shiftName?.trim() ||
      !this.insertShiftMasterDetails.fromTimings?.trim() ||
      !this.insertShiftMasterDetails.toTimings?.trim()) {


      this.alertmessages("Please enter all the required fields", "danger");
      return;
    }
    // if (this.insertShiftMasterDetails.flexibleShift) {
    //   if (!this.insertShiftMasterDetails.lateComing?.trim() ||
    //     !this.insertShiftMasterDetails.earlyLeaving?.trim()) {
    //     this.alertmessages("Please enter all the required fields", "danger");
    //     return;
    //   }
    // }

    const timeFormatRegex = /^([01]\d|2[0-3]):([0-5]\d)$/; // HH:MM format

    if ((this.insertShiftMasterDetails.dailyWorkHrs && !timeFormatRegex.test(this.insertShiftMasterDetails.dailyWorkHrs)) ||
        (this.insertShiftMasterDetails.otStartMins && !timeFormatRegex.test(this.insertShiftMasterDetails.otStartMins))) {
        this.alertmessages("Please enter valid over time rules", "danger");
        return;
    }

    const dayFields = [
      { name: "sunFullDayMins", label: "Sunday Full Day" },
      { name: "sunHalfDayMins", label: "Sunday Half Day" },
      { name: "monFullDayMins", label: "Monday Full Day" },
      { name: "monHalfDayMins", label: "Monday Half Day" },
      { name: "tueFullDayMins", label: "Tuesday Full Day" },
      { name: "tueHalfDayMins", label: "Tuesday Half Day" },
      { name: "wedFullDayMins", label: "Wednesday Full Day" },
      { name: "wedHalfDayMins", label: "Wednesday Half Day" },
      { name: "thuFullDayMins", label: "Thursday Full Day" },
      { name: "thuHalfDayMins", label: "Thursday Half Day" },
      { name: "friFullDayMins", label: "Friday Full Day" },
      { name: "friHalfDayMins", label: "Friday Half Day" },
      { name: "satFullDayMins", label: "Saturday Full Day" },
      { name: "satHalfDayMins", label: "Saturday Half Day" },
    ];
    
    let emptyFields = []; // Collect labels of empty fields
    let invalidFields = []; // Collect labels of invalid format fields
    
    for (const field of dayFields) {
      const dayFieldValue = this.insertShiftMasterDetails[field.name];
      
      if (!dayFieldValue) {
        emptyFields.push(field.label); // Add to empty fields array
      } else if (!timeFormatRegex.test(dayFieldValue)) {
        invalidFields.push(field.label); // Add to invalid fields array
      }
    }
    
    // Combine empty and invalid fields into a single message
    let validationMessages = [];
    
    if (emptyFields.length > 0) {
      validationMessages.push(`${emptyFields.join(" , ")} cannot be empty`);
    }
    if (invalidFields.length > 0) {
      validationMessages.push(`Please enter valid time for ${invalidFields.join(" , ")} in HH:MM format`);
    }
    
    // If there are validation messages, alert the user
    if (validationMessages.length > 0) {
      this.alertmessages(validationMessages.join(" , "), "danger");
      return; // Exit if there are errors
    }
    
    console.log('combined list ', this.shiftRuleDetail);
    const obj1 = {
      breakName: this.insertBreakDetail.breakName === undefined ? 0 : this.insertBreakDetail.breakName,
      fromTimings: this.insertBreakDetail.fromTimings === undefined ? 0 : this.insertBreakDetail.fromTimings,
      toTimings: this.insertBreakDetail.toTimings === undefined ? 0 : this.insertBreakDetail.toTimings,
      breakMins: this.insertBreakDetail.breakMins === undefined ? 0 : this.insertBreakDetail.breakMins,
      userId: 'Add'
    }
    if (obj1.breakName != 0 && obj1.fromTimings != 0 && obj1.toTimings != 0 && obj1.breakMins != 0 && obj1.breakName != null && obj1.fromTimings != null && obj1.toTimings != null && obj1.breakMins != null) {
      this.shiftRuleDetail.push({ ...obj1 });
      this.clearBreak();
      // this.BreakForm.form.reset();
    }


    // Convert 'dailyWorkHrs' from HH:MM to decimal before sending it to the backend
    if (this.insertShiftMasterDetails.dailyWorkHrs) {
      this.insertShiftMasterDetails.dailyWorkHrs = this.convertTimeToDecimal(this.insertShiftMasterDetails.dailyWorkHrs);
    }
    if (this.insertShiftMasterDetails.otStartMins) {
      this.insertShiftMasterDetails.otStartMins = this.convertTimeToMinutes(this.insertShiftMasterDetails.otStartMins);
    }

    // Set OT rule based on conditions
    if (this.insertShiftMasterDetails.otStartTime) {
      this.insertShiftMasterDetails.otRule = 'OTST';
    } else if (this.insertShiftMasterDetails.otStartMins) {
      this.insertShiftMasterDetails.otRule = 'OTHR';
    } else {
      this.insertShiftMasterDetails.otRule = null; // Set to null if both are null
    }
    this.convertShiftTimesToMinutes();
    if (this.insertShiftMasterDetails.flexibleShift) {
      this.insertShiftMasterDetails.flexibleShift = true;
    } else {
      this.insertShiftMasterDetails.flexibleShift = false;
    }


    this.insertShiftMasterDetails.breakList = this.shiftRuleDetail;
    console.log("insertShiftMasterDetails ", this.insertShiftMasterDetails);

    this.shiftService.saveShiftMaster(this.insertShiftMasterDetails).subscribe(
      (data) => {
        console.log(data);
        this.getShiftType();
        this.clear();
        this.add_view_toggle = 'viewShift';
        this.addOrUpdate = true;
        this.alertmessages("Successfully saved Shift Master", "success");

      }, (error) => {
        console.log(error);
          if (error.status === 422 && error.error && error.error.status === "Duplicate") {
            this.alertmessages("Shift Name already exists", "danger");
        }else
        this.alertmessages("Please Try Again", "danger");
        this.convertShiftTimesToHHMM();
        if (this.insertShiftMasterDetails.otStartMins) {
          this.insertShiftMasterDetails.otStartMins = this.convertMinutesToTime(this.insertShiftMasterDetails.otStartMins);
        }
        if (this.insertShiftMasterDetails.dailyWorkHrs) {
          this.insertShiftMasterDetails.dailyWorkHrs = this.convertDecimalToTime(this.insertShiftMasterDetails.dailyWorkHrs);
        }
      });



  }

  getShiftType() {
    console.warn("getShiftType ");

    this.shiftService.getshifts().subscribe(
      data => {
        this.ShiftMasterData = data.shiftMasterdetail;
        console.log("List of all the shift type available  :", this.ShiftMasterData);
      }, error => {
        console.log(error);
      }
    );

  }





  addShift() {
    console.log("Clicked on add button")
    this.initializeDayFields();
    this.add_view_toggle = "addShift";
    this.addOrUpdate = true;
  }

  updateShift(insertShiftMasterDetails: any) {

    console.log(" save ", this.insertShiftMasterDetails);
    console.log(" fromtime ", this.insertShiftMasterDetails.fromTimings);
    console.log(" totime ", this.insertShiftMasterDetails.toTimings);
    console.log(" fromtime ", this.insertShiftMasterDetails.otStartTime);
    console.log(" totime ", this.insertShiftMasterDetails.otStartMins);
    console.log(" totime ", this.insertShiftMasterDetails.otRule);
    if (!this.insertShiftMasterDetails.shiftName?.trim() ||
      !this.insertShiftMasterDetails.fromTimings?.trim() ||
      !this.insertShiftMasterDetails.toTimings?.trim()) {


      this.alertmessages("Please enter all the required fields", "danger");
      return;
    }
    // if (this.insertShiftMasterDetails.flexibleShift) {
    //   if (!this.insertShiftMasterDetails.lateComing?.trim() ||
    //     !this.insertShiftMasterDetails.earlyLeaving?.trim()) {
    //     this.alertmessages("Please enter all the required fields", "danger");
    //     return;
    //   }
    // }

    const timeFormatRegex = /^([01]\d|2[0-3]):([0-5]\d)$/; // HH:MM format

    if ((this.insertShiftMasterDetails.dailyWorkHrs && !timeFormatRegex.test(this.insertShiftMasterDetails.dailyWorkHrs)) ||
        (this.insertShiftMasterDetails.otStartMins && !timeFormatRegex.test(this.insertShiftMasterDetails.otStartMins))) {
        this.alertmessages("Please enter valid over time rules", "danger");
        return;
    }

    

    const dayFields = [
      { name: "sunFullDayMins", label: "Sunday Full Day" },
      { name: "sunHalfDayMins", label: "Sunday Half Day" },
      { name: "monFullDayMins", label: "Monday Full Day" },
      { name: "monHalfDayMins", label: "Monday Half Day" },
      { name: "tueFullDayMins", label: "Tuesday Full Day" },
      { name: "tueHalfDayMins", label: "Tuesday Half Day" },
      { name: "wedFullDayMins", label: "Wednesday Full Day" },
      { name: "wedHalfDayMins", label: "Wednesday Half Day" },
      { name: "thuFullDayMins", label: "Thursday Full Day" },
      { name: "thuHalfDayMins", label: "Thursday Half Day" },
      { name: "friFullDayMins", label: "Friday Full Day" },
      { name: "friHalfDayMins", label: "Friday Half Day" },
      { name: "satFullDayMins", label: "Saturday Full Day" },
      { name: "satHalfDayMins", label: "Saturday Half Day" },
    ];
    
    let emptyFields = []; // Collect labels of empty fields
    let invalidFields = []; // Collect labels of invalid format fields
    
    for (const field of dayFields) {
      const dayFieldValue = this.insertShiftMasterDetails[field.name];
      
      if (!dayFieldValue) {
        emptyFields.push(field.label); // Add to empty fields array
      } else if (!timeFormatRegex.test(dayFieldValue)) {
        invalidFields.push(field.label); // Add to invalid fields array
      }
    }
    
    // Combine empty and invalid fields into a single message
    let validationMessages = [];
    
    if (emptyFields.length > 0) {
      validationMessages.push(`${emptyFields.join(" , ")} cannot be empty`);
    }
    if (invalidFields.length > 0) {
      validationMessages.push(`Please enter valid time for ${invalidFields.join(" , ")} in HH:MM format`);
    }
    
    // If there are validation messages, alert the user
    if (validationMessages.length > 0) {
      this.alertmessages(validationMessages.join(" , "), "danger");
      return; // Exit if there are errors
    }

     console.log('combined list ', this.shiftRuleDetail);
    const obj1 = {
      breakName: this.insertBreakDetail.breakName === undefined ? 0 : this.insertBreakDetail.breakName,
      fromTimings: this.insertBreakDetail.fromTimings === undefined ? 0 : this.insertBreakDetail.fromTimings,
      toTimings: this.insertBreakDetail.toTimings === undefined ? 0 : this.insertBreakDetail.toTimings,
      breakMins: this.insertBreakDetail.breakMins === undefined ? 0 : this.insertBreakDetail.breakMins,
      userId: 'Add'
    }
    if (obj1.breakName != 0 && obj1.fromTimings != 0 && obj1.toTimings != 0 && obj1.breakMins != 0 && obj1.breakName != null && obj1.fromTimings != null && obj1.toTimings != null && obj1.breakMins != null) {
      this.shiftRuleDetail.push({ ...obj1 });
      this.clearBreak();
    }


    // Convert 'dailyWorkHrs' from HH:MM to decimal before sending it to the backend
    if (this.insertShiftMasterDetails.dailyWorkHrs) {
      this.insertShiftMasterDetails.dailyWorkHrs = this.convertTimeToDecimal(this.insertShiftMasterDetails.dailyWorkHrs);
    }
    if (this.insertShiftMasterDetails.otStartMins) {
      this.insertShiftMasterDetails.otStartMins = this.convertTimeToMinutes(this.insertShiftMasterDetails.otStartMins);
    }

    // Set OT rule based on conditions
    if (this.insertShiftMasterDetails.otStartTime) {
      this.insertShiftMasterDetails.otRule = 'OTST';
    } else if (this.insertShiftMasterDetails.otStartMins) {
      this.insertShiftMasterDetails.otRule = 'OTHR';
    } else {
      this.insertShiftMasterDetails.otRule = null; // Set to null if both are null
    }
    this.convertShiftTimesToMinutes();

    if (this.insertShiftMasterDetails.flexibleShift) {
      this.insertShiftMasterDetails.flexibleShift = true;
    } else {
      this.insertShiftMasterDetails.flexibleShift = false;
    }


    this.insertShiftMasterDetails.breakList = this.shiftRuleDetail;
    console.log("insertShiftMasterDetails ", this.insertShiftMasterDetails);

    this.shiftService.updateShiftMaster(insertShiftMasterDetails).subscribe(
      (data) => {
        console.log(data);
        this.getShiftType();
        this.clear();
        this.add_view_toggle = 'viewShift';
        this.addOrUpdate = true;
        this.alertmessages("Successfully updated Shift Master", "success");
        //  After submission, convert the decimal back to HH:MM format for display in the UI

      }, (error) => {
        console.log(error)
        if (error.status === 422 && error.error && error.error.status === "Duplicate") {
          this.alertmessages("Shift Name already exists", "danger");
      }else
        this.alertmessages("Please Try Again", "danger");
        this.convertShiftTimesToHHMM();
        if (this.insertShiftMasterDetails.otStartMins) {
          this.insertShiftMasterDetails.otStartMins = this.convertMinutesToTime(this.insertShiftMasterDetails.otStartMins);
        }
        // After submission, convert the decimal back to HH:MM format for display in the UI
        if (this.insertShiftMasterDetails.dailyWorkHrs) {
          this.insertShiftMasterDetails.dailyWorkHrs = this.convertDecimalToTime(this.insertShiftMasterDetails.dailyWorkHrs);
        }
      });



  }


  viewShift() {
    console.warn("viewShiftTypeButton ");

    this.getShiftType();
    this.addOrUpdate = true;
    this.add_view_toggle = "viewShift";
    this.clear();

  }


  addBreak() {
    console.log("add Break comp ");

    const obj1 = {
      breakName: this.insertBreakDetail.breakName === null ? 0 : this.insertBreakDetail.breakName,
      fromTimings: this.insertBreakDetail.fromTimings === null ? 0 : this.insertBreakDetail.fromTimings,
      toTimings: this.insertBreakDetail.toTimings === null ? 0 : this.insertBreakDetail.toTimings,
      breakMins: this.insertBreakDetail.breakMins === null ? 0 : this.insertBreakDetail.breakMins,
      userId: 'Add',
    }

    const valuesToCheck = [
      obj1.breakName,
      obj1.fromTimings,
      obj1.toTimings,
      obj1.breakMins,
    ];


    const isNumberOnly = /^\d+$/.test(obj1.breakName);
    if (!obj1.breakName || isNumberOnly || !obj1.fromTimings || !obj1.toTimings || obj1.breakMins === '' || isNaN(obj1.breakMins) || obj1.breakMins < 0) {
      this.alertmessages("Please insert all the value for Breaks", "danger");
      if (isNumberOnly) {
        this.alertmessages("Break Name cannot be a number only. Please provide a valid name", "danger");
      }
      this.BreakForm.form.reset();
      return;
    }





    this.shiftRuleDetail.push({ ...obj1 });
    this.clearBreak();
    console.log("addShift ", this.shiftRuleDetail);
    this.BreakForm.form.reset();


  }
  deleteBreak(index) {

    console.log(" slice obj from deleteBreak ", index);
    if (index !== null && index !== undefined) {

      console.log("this indez ", this.shiftRuleDetail[index]);
      this.shiftRuleDetail[index].userId = 'Del';
      this.shiftRuleDetail.splice(index, 1);
      console.log("List existing ", this.shiftRuleDetail);

    } else {
      console.error("Select Object to Delete!");
    }
  }


  clearBreak() {
    this.insertBreakDetail.breakName = '';
    this.insertBreakDetail.fromTimings = '';
    this.insertBreakDetail.toTimings = '';
    this.insertBreakDetail.breakMins = '';

  }

  onDeleteHandler(info: any) {
    console.log("delete : ", info);
    this.DeleteShiftMaster = info;
    $('#showAlertVer').modal('toggle');
  }

  closeMRQModal() {
    $('#showAlertVer').modal('toggle');

  }

  deleteShift() {
    const deleteId = this.DeleteShiftMaster.shiftid;
    console.log("deleteId", deleteId);
    this.shiftService.deleteShiftMaster(deleteId).subscribe(
      (data) => {
        this.getShiftType();
        $('#showAlertVer').modal('toggle');
        this.alertmessages(data.successMessages[0]['key'], "success")


      }, (error) => {
        console.log(error)
        $('#showAlertVer').modal('toggle');
        this.alertmessages(" Shift is allocated to employee", "danger");
      }
    );


  }

  alertmessages(message: string, sign: string): void {
    let alertDiv = document.createElement('div');
    alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
    <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message} </div>`
    let alertcomp = document.querySelector('.alertcomp');
    alertcomp.append(alertDiv);
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    setTimeout(function () {
      alertcomp.removeChild(alertDiv);
    }, 3000);
  }

}
