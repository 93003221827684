import { Component,HostListener, OnInit, Renderer2 } from '@angular/core';
import { ApplicationSettingsService } from '../services/application-settings.service';
import { EmployeeMaster } from '../models/employeeMasterts';
import { EmployeeLeaveService } from '../services/employee-leave.service';
import { ExpensemanagerService } from '../services/expensemanager.service';
import { ExpenseReport } from '../models/ExpenseReport';
import { EmployeeAttendenceProxyService } from '../services/employee-attendence-proxy.service';
import { LuceneSearchService } from '../services/lucene-search.service';
declare var $: any;
@Component({
  selector: 'app-expense-report',
  templateUrl: './expense-report.component.html',
  styleUrls: ['./expense-report.component.css']
})
export class ExpenseReportComponent implements OnInit {
  fromDate: string;
  toDate: string;
  currentDate: string;
  startDate: boolean;
  endDate: boolean;
  submitted: boolean;
  designation: any[]=[];
  department: any[] = [];
  employeeName: any;
  employeeId: any;
  expenseId:any;
  branchInfo: any[] = [];
  limitPara: any;
	offsetPara: any;
  query: any;
 
  keyinempid: any | undefined;
  selectedempid: any;
  expenseReport: ExpenseReport[]=[];
  selecteddesignation: string = "0";
  selecteddepartment: string= "0";
  branch: any = "0";
  employeeMaster: EmployeeMaster[];
  client_id: EmployeeMaster[];
  ActiveInativeLuceneFilter : string='Active';
  queryName: any;
  employeeid:any;
  leavingdate: any;
  permissionList: any[] = [];
  showBasedOnPermissionEmployeeStatus: boolean = false;
  employeeMasterLucene: EmployeeMaster[];

  constructor(private applicationSettingsService: ApplicationSettingsService,private render: Renderer2,private empLuceneSearch: LuceneSearchService, private empLeaveService: EmployeeLeaveService,
    private expensemanagerService: ExpensemanagerService, private employeeService: EmployeeAttendenceProxyService) {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const day = currentDate.getDate();
    this.currentDate = `${year}-${month}-${day}`;
    this.fromDate = `${year}-${month.toString().padStart(2, '0')}-01`;
    const lastDay = new Date(year, month, 0).getDate();
    this.toDate = `${year}-${month.toString().padStart(2, '0')}-${lastDay.toString().padStart(2, '0')}`;
  }

  ngOnInit(): void {
    this.limitPara = 13;

		this.offsetPara = 0;
   //this.getDesignation();
    this.getAllBranches();
    this.getDepartment();
    const maxString = sessionStorage.getItem('permission').replace('[', '');
    const maxStringBox = maxString.replace(']', '');
    const maxStringAgain = maxStringBox.replace(/"/g, '');
    this.permissionList = maxStringAgain.split(',');
    this.checkAccessForUser();

    this.render.listen(document.getElementById('navigateclient'), 'click', () => {
			$('html,body').animate({
				scrollTop: $(`#add-update-client`).offset().top - 90
			},
				'slow');
		})
   
  }

  checkFromAndToDate() {

    console.log("from date ", "to date ");

    this.startDate = false;
    this.endDate = false;
    this.submitted = true;
    const currentDate = new Date();

    if (this.toDate) {
      if (this.fromDate > this.toDate) {
        this.submitted = false
        this.endDate = true;
      } else {
        this.endDate = false;
        return true;
      }
    }
  }

  getDesignation() {
    this.applicationSettingsService.getDesignationType().subscribe(
      (data) => {
        console.log("Data available :", data);
        this.designation = data.designationDetail;
        console.log("List of all the designation available :", this.designation);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getDepartment() {
    this.applicationSettingsService.getDepartmentType().subscribe(
      (data) => {
        console.log("Data available :", data);
        this.department = data.departmentDetail;
        console.log("List of all the department available :", this.department);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getEmployeeByNameOrId() {
    this.empLuceneSearch.getEmployeeByNameOrId(this.queryName,this.employeeid,this.ActiveInativeLuceneFilter,this.query , this.employeeMasterLucene,this.leavingdate,this.selectedempid).subscribe(res=>{
      console.warn(res)
      this.employeeMasterLucene=res.filteredEmployees;
      this.selectedempid = res.selectedempid;
    }); }
  onStatusChange() {
    this.getEmployeeByNameOrId();
  }


  onScroll() {
    this.offsetPara = this.offsetPara + 13;
    console.log("limit = ? ", this.limitPara, "offset = +13 ", this.offsetPara)
    this.getExpenseReport();

  }

  limitpara() {
    this.offsetPara = 0;
    console.log(" on clikc limit para ", this.limitPara)
    console.log("this offset", this.offsetPara)
  }

  @HostListener('scroll', ['$event'])
  scrolled() {

    console.log("Scrolled >>> ")
    this.onScroll();
  }
  
  getExpenseReport() {
    console.warn("search");
    let employeename = (<HTMLInputElement>document.getElementById('employeename')).value;
    if (!employeename) {
      employeename = null;
    } else {
      employeename = employeename.trim()
      // this.statusValue ="All";
    }
    var id = null;
    if (employeename) {
      this.client_id = this.employeeMasterLucene.filter((dName) => {
        return dName.name === employeename;
      });

      console.log("data parent: ", this.client_id)
      id = this.selectedempid;
    } else {
      console.log("Id parent", id)
    }
    this.expensemanagerService.getExpenseReport(this.fromDate, this.toDate,this.ActiveInativeLuceneFilter, this.branch,
      this.selecteddepartment, id,employeename,this.limitPara, this.offsetPara).subscribe(
        (data) => {
          this.expenseReport=data.expenseReportList;
          console.log("Data available :", data);
          console.log(data.expenseReportList);
          console.log(this.expenseReport);
          
        },
        (error) => {
          console.log(error);
        }
      );
  }

  clear() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const day = currentDate.getDate();
    this.currentDate = `${year}-${month}-${day}`;
    this.fromDate = `${year}-${month.toString().padStart(2, '0')}-01`;
    const lastDay = new Date(year, month, 0).getDate();
    this.toDate = `${year}-${month.toString().padStart(2, '0')}-${lastDay.toString().padStart(2, '0')}`;
    this.branch= "0";
    this.employeeName = null;
    this.selecteddepartment = "0";
    this.selecteddesignation = "0";
    this.query='';
    this.expenseReport=[];
  }

  getAllBranches() {
    console.log("Called all branches >> >> >>")
    this.employeeService.getAllBranchs().subscribe(
      data => {
        console.log("check this")
        this.branchInfo = data.branches;
      },
      error => {
      }
    );
  }
  checkAccessForUser() {
    console.log("checkAccessForUser here")
    this.showBasedOnPermissionEmployeeStatus = this.permissionList.includes('ng.employee.search.show.employeestatus');
  }

  expenseReportDownload(){
    console.warn("search");
    let employeename = (<HTMLInputElement>document.getElementById('employeename')).value;
    if (!employeename) {
      employeename = null;
    } else {
      employeename = employeename.trim()
      // this.statusValue ="All";
    }
    var id = null;
    if (employeename) {
      this.client_id = this.employeeMasterLucene.filter((dName) => {
        return dName.name === employeename;
      });

      console.log("data parent: ", this.client_id)
      id = this.selectedempid;
    } else {
      console.log("Id parent", id)
    }

    this.expensemanagerService.downloadExpenseReport(this.fromDate, this.toDate,this.ActiveInativeLuceneFilter, this.branch,
      this.selecteddepartment,id,employeename,this.limitPara, this.offsetPara).subscribe(
        (data) => {
          var file = new Blob([data], { type: 'application/vnd.ms-excel' });
          var fileURL = URL.createObjectURL(file);
          var anchor = document.createElement("a");
          anchor.download = "Expense Report.xls";
          anchor.href = fileURL;
          anchor.click();
          //window.open(fileURL);
        },
          error => {
            console.log("Error Occured ", error);
            // console.log("Error Code ",error.status);
          })
    
      }
    }
