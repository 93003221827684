import { Component,HostListener ,OnInit } from '@angular/core';
import { LeaveWorkFlow } from '../models/leaveWorkflow';
import { TaskDetailsForLeave } from '../models/task-details-for-leave.model';
import { EmployeeLeaveService } from '../services/employee-leave.service';
import { ApproveLeavePipe } from '../pipes/approve-leave.pipe';
import { TitleCasePipe } from '@angular/common';
import { Menu } from '../services/menu.Service';
import { EmployeeMaster } from '../models/employeeMasterts';
import { SessionService } from '../services';

declare var $: any;

@Component({
  selector: 'app-approve-comp-off',
  templateUrl: './approve-comp-off.component.html',
  styleUrls: ['./approve-comp-off.component.css']
})
export class ApproveCompOffComponent implements OnInit {
  private APPROVED: string = "APPROVED";
  private REJECTED: string = "REJECTED";
  private LEAVE_APPROVE_PENDING: string = "Leave Approve Pending"
  private LEAVE_CANCEL_PENDING: string = "Leave Cancel Pending"
  private LEAVE_CANCEL_APPROVED: string = "Leave Cancel Approved"
  private LEAVE_CANCEL_REJECTED: string = "Leave Cancel Rejected"
  searchleave: any;

  taskDetailsForLeave: TaskDetailsForLeave[] = [];
  leaveDetailFlow: LeaveWorkFlow[] = []
  leaveTypesList = [
    { leaveType: "", leaveName: "" }
  ]
  leaveTypeMap = new Map();
  approvalLeaves: TaskDetailsForLeave[] = [];
  leavesApproval: LeaveWorkFlow[] = [];
  templeaveDetailFlow: LeaveWorkFlow[] = [];
  statusFilter: any = 'all';
  limitPara: any;
  offsetPara: any;
  uniqueempid: string = this.session.getempid()
  ActiveInativeEmployeeFilter: string = 'Active';
  leavingdate: any;
  query: any;
  uniqueemployeeid: String;
  employeeMasterLucene: EmployeeMaster[];
  showBasedOnPermissionEmployeeStatus: boolean = false;
  permissionList: any[] = [];
  allowToViewReport: boolean = false;
  client_id: EmployeeMaster[];
  employeeMaster: EmployeeMaster[];
  statusValue: string = 'All';
  keyinempid: any | undefined;
  selectedempid: any;

  constructor(private empLeaveService: EmployeeLeaveService, private approve: ApproveLeavePipe, private titleCase: TitleCasePipe,
     private session: SessionService, private menuService: Menu) { }

  ngOnInit(): void {
    this.limitPara = 13;
		this.offsetPara = 0;
    if (localStorage.getItem('navigateDashboard') == 'true') {
      this.search();
      localStorage.setItem('navigateDashboard', 'false');
    }
    const maxString = sessionStorage.getItem('permission').replace('[', '');
		const maxStringBox = maxString.replace(']', '');
		const maxStringAgain = maxStringBox.replace(/"/g, '');
		this.permissionList = maxStringAgain.split(',');
		this.checkAccessForUser();
  }

  onScroll() {
            this.offsetPara = this.offsetPara + 13;
            console.log("limit = ? ", this.limitPara, "offset = +13 ", this.offsetPara)
            this.search();
        
          }
        
          limitpara() {
            this.offsetPara = 0;
            console.log(" on clikc limit para ", this.limitPara)
            console.log("this offset", this.offsetPara)
          }
        
          @HostListener('scroll', ['$event'])
          scrolled() {
        
            console.log("Scrolled >>> ")
            this.onScroll();
          }

  search() {
    this.getLeaves();
    this.getCompOffForApproval();
  }

  getLeaves() {
    this.empLeaveService.getAllLeaves().subscribe(
      data => {
        this.leaveTypesList = data.leaveMasters
        this.setLeaveMap();
      },
      error => {
        console.log("Error ", error);
      }
    )
  }

  showTable = false
  // getCompOffForApproval() {
  //   this.leaveDetailFlow = [];
  //   this.templeaveDetailFlow = [];
  //   this.empLeaveService.getCompOffForApproval().subscribe(
  //     data => {
  //       console.log("get Leave for Approval ");
  //       if (this.statusFilter == 'all') {
  //         console.log("this.statusFilter ", this.statusFilter);
  //         this.leaveDetailFlow = data.leaveWorkflow;
  //         this.leaveDetailFlow.forEach(item => {
  //           item.employeeLeaveApplied.leaveStatus = this.titleCase.transform(item.employeeLeaveApplied.leaveStatus);
  //         })
  //         this.templeaveDetailFlow = this.leaveDetailFlow;
  //       } else {
  //         console.log("this.statusFilter ", this.statusFilter);
  //         this.leaveDetailFlow = data.leaveWorkflow;
  //         this.leaveDetailFlow.forEach(item => {
  //           item.employeeLeaveApplied.leaveStatus = this.titleCase.transform(item.employeeLeaveApplied.leaveStatus);
  //         })
  //         this.leaveDetailFlow = this.leaveDetailFlow.filter(
  //           ele => ele.employeeLeaveApplied.leaveStatus == this.statusFilter
  //         );
  //         this.templeaveDetailFlow = this.leaveDetailFlow;
  //       }
  //       if (this.leaveDetailFlow.length > 0) {
  //         this.showTable = true
  //       }
  //       console.log("leave response ", this.leaveDetailFlow);
  //       $('html,body').animate({
  //         scrollTop: $(`#navigatedest`).offset().top - 90
  //       },
  //         'slow');
  //     },
  //     error => {
  //       console.error("Error while getting Leave For Approval ", error);
  //     }
  //   )
  // }

  getCompOffForApproval(){
    console.warn("search");
    let employeename = (<HTMLInputElement>document.getElementById('employeename')).value;
    if (!employeename) {
      employeename = null;
    } else {
      employeename = employeename.trim()
      // this.statusValue ="All";
    }
    var id = null;
    if (employeename) {
      this.client_id = this.employeeMaster.filter((dName) => {
        return dName.name === employeename;
      });

      console.log("data parent: ", this.client_id)
      id = this.client_id[0].uniqueemployeeid;
      this.keyinempid = id;
    } else {
      console.log("Id parent", id)
    }
   
    this.empLeaveService.getCompOffForApproval(this.uniqueempid,this.selectedempid,employeename,this.ActiveInativeEmployeeFilter,this.statusFilter,  this.limitPara, this.offsetPara).subscribe(
        (data) => {
          this.leaveDetailFlow = data.leaveWorkflow;
          if (this.leaveDetailFlow.length > 0) {
                  this.showTable = true
                   }
          console.log("Data available :", data.leaveWorkflow);


        },
        (error) => {
          console.log(error);
        }
      );

  }

  setLeaveMap() {
    this.leaveTypesList.forEach(type => {
      this.leaveTypeMap.set(type.leaveType, type.leaveName)
    })
  }

  getLeaveName(leaveType: any) {
    return this.leaveTypeMap.get(leaveType)
  }

  checkAllSelected(e: any) {
    console.log("checkAllSelected ");

    const checkboxes = document.querySelectorAll('input');
    if (e.target.checked) {
      for (let i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].type == 'checkbox') {
          checkboxes[i].checked = true;
        }
      }
      this.leavesApproval.splice(0, this.leavesApproval.length)
      console.log(" select all ", this.leavesApproval);

      if (this.searchleave) {
        this.searchLeaves.forEach(task => {
          this.leavesApproval.push()
        });
      } else {
        this.leaveDetailFlow.forEach(task => {
          this.leavesApproval.push(task)
        });
      }
      console.log(" check  all ", this.leavesApproval.length);
    } else {
      for (let i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].type == 'checkbox') {
          checkboxes[i].checked = false;
        }
      }
      this.leavesApproval.splice(0, this.leavesApproval.length);
      console.log(" end  ", this.leavesApproval.length);
    }
  }

  checkSelected(leave: any, e: any) {
    console.log("check selected ");

    if (e.target.checked) {
      console.log(" before ", this.leavesApproval.length);
      console.log(this.leavesApproval);
      this.leavesApproval.push(leave);
      console.log("after ", this.leavesApproval.length);
      console.log(this.leavesApproval);
    } else {
      console.log("else part before ", this.leavesApproval.length);
      console.log(this.leavesApproval);
      this.leavesApproval = this.leavesApproval.filter(item => item.employeeLeaveApplied.employeeLeaveAppliedId !== leave.employeeLeaveApplied.employeeLeaveAppliedId);
      console.log("else part after ", this.leavesApproval.length);
      console.log(this.leavesApproval);
    }
    console.log("check selected done ");
  }

  onApproveHandler() {
    let approve_reject = true;
    let flag = true
    if (this.leavesApproval.length === 0) {
      this.alertmessages("Please select item for Approval.", "danger")
      flag = false
      return;
    }

    if (flag) {
      this.empLeaveService.compOffForApproval(this.leavesApproval, approve_reject).subscribe(
        data => {
          this.alertmessages("Successfully Approved.", "success")
          this.leavesApproval.splice(0, this.leavesApproval.length)
          this.searchleave = ""
          this.resetAllCheckBoxes()
          this.getCompOffForApproval()
        },
        error => {
          this.searchleave = ""
          console.error("Error ", error);
          const response = error.error;
          if (response.errorMessages != null && response.errorMessages.length !== 0) {
            let err: string = response.errorMessages[0]['key'];
            if (err.toLocaleLowerCase() === "FlowableObjectNotFoundExceptionSingle".toLocaleLowerCase()) {
              this.alertmessages("A requests have already been processed.", "danger")
            } else if (err.toLocaleLowerCase() === "FlowableObjectNotFoundException".toLocaleLowerCase()) {
              this.alertmessages("One or more requests have already been processed.", "danger")
            } else {
              this.alertmessages("Not Approved.", "danger")
            }
          } else {
            this.alertmessages("Not Approved.", "danger")
          }
          this.resetAllCheckBoxes()
          this.leavesApproval.splice(0, this.leavesApproval.length)
          this.getCompOffForApproval()
        }
      )
    }
  }

  onRejectHandler() {
    let approve_reject = false;
    let flag = true
    if (this.leavesApproval.length === 0) {
      this.alertmessages("Please select item for Rejection.", "danger")

      flag = false
      return;
    }

    let isCommentAbsent = false;
    this.leavesApproval.forEach(rejectLeave => {

      if (rejectLeave.employeeLeaveApplied.remark === undefined) {
        console.log("Reject extra time out ");
        isCommentAbsent = true;
        flag = false
        return;
      }
    })

    if (isCommentAbsent) {
      this.alertmessages("Comments are mandatory for Rejection.", "danger")
      return;
    }

    if (flag) {
      this.empLeaveService.compOffForApproval(this.leavesApproval, approve_reject).subscribe(
        data => {
          this.alertmessages("Successfully Rejected.", "success")
          this.leavesApproval.splice(0, this.leavesApproval.length)
          this.searchleave = ""
          this.resetAllCheckBoxes()
          this.getCompOffForApproval()
        },
        error => {
          this.searchleave = ""
          console.error("Error ", error);
          const response = error.error;
          if (response.errorMessages != null && response.errorMessages.length !== 0) {
            let err: string = response.errorMessages[0]['key'];
            if (err.toLocaleLowerCase() === "FlowableObjectNotFoundExceptionSingle".toLocaleLowerCase()) {
              this.alertmessages("A requests have already been processed.", "danger")
            } else if (err.toLocaleLowerCase() === "FlowableObjectNotFoundException".toLocaleLowerCase()) {
              this.alertmessages("One or more requests have already been processed.", "danger")
            } else {
              this.alertmessages("Not Rejected.", "danger")
            }
          } else {
            this.alertmessages("Not Rejected.", "danger")
          }

          this.leavesApproval.splice(0, this.leavesApproval.length)
          this.resetAllCheckBoxes()
          this.getCompOffForApproval()
        }
      )

    }
  }

  searchLeaves: LeaveWorkFlow[] = [];
  chkInput(e: any) {
    console.log("chkinput ", this.query);
    if (this.query.length > 3) {
      console.log("call filter function ");
      this.leaveDetailFlow = this.leaveDetailFlow.filter(item =>
        item.name.toLowerCase().includes(this.query.toLowerCase())
      );
      console.log(this.leaveDetailFlow);
    }
    else {
      this.leaveDetailFlow = this.templeaveDetailFlow;
    }
  }

  resetAllCheckBoxes() {

    const checkboxes = document.querySelectorAll('input');
    for (let i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].type == 'checkbox') {
        checkboxes[i].checked = false;
      }
    }
    this.searchleave = ""
    this.leavesApproval.splice(0, this.leavesApproval.length)
  }

  alertmessages(message: string, sign: string): void {
    let alertDiv = document.createElement('div');
    alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
		<i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message}</div>`
    let alertcomp = document.querySelector('.alertcomp');
    alertcomp.append(alertDiv);
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    setTimeout(function () {
      alertcomp.removeChild(alertDiv);
    }, 5000);
  }

  navigateLeaveStatus() {
    localStorage.setItem('navigateDashboard', 'true')
  }

  setMenuName(name: string) {
    this.menuService.setMenuName(name);
  }

  getEmployeeByNameOrId() {
    console.log("query", this.query);
    this.query = this.query.trim();
    console.log("Len ", this.query);
    
    if (this.query.length <= 2) {
      this.employeeMaster = [];
    }
  
    if (this.query.length >= 3) {
      this.empLeaveService.getEmployeeByNameOrUniqueId(this.query).subscribe(
        data => {
          console.log("Emp Data ", data);
          
          // Check if data.employeeMasterData has any entries
          if (data.employeeMasterData.length > 0) {
            let filteredEmployees = data.employeeMasterData;
    
            // Get the current date for comparison
            const currentDate = new Date();
            console.log("Current Date: ", currentDate);  // Log current date for debugging
    
            // Apply filtering logic based on the selected employee status
            if (this.ActiveInativeEmployeeFilter === 'Active') {
              filteredEmployees = filteredEmployees.filter(emp => {
                const leavingDate = emp.leavingdateString ? new Date(emp.leavingdateString) : null;
                console.log("Leaving Date for " + emp.name + ": ", leavingDate);  // Log leaving date for debugging
                // Active employees should have leavingdate in the future or null
                return leavingDate === null || leavingDate > currentDate;
              });
            } else if (this.ActiveInativeEmployeeFilter === 'Inactive') {
              filteredEmployees = filteredEmployees.filter(emp => {
                const leavingDate = emp.leavingdateString ? new Date(emp.leavingdateString) : null;
                console.log("Leaving Date for " + emp.name + ": ", leavingDate);  // Log leaving date for debugging
                // Inactive employees should have leavingdate in the past or null
                return leavingDate && leavingDate < currentDate;
              });
            }
    
            // If the status is 'All', no filter is applied
            if (this.ActiveInativeEmployeeFilter === 'All') {
              filteredEmployees = data.employeeMasterData;
            }
    
            // Assign filtered data to the employeeMasterLucene
            this.employeeMasterLucene = filteredEmployees;
    
            // Log the data and leaving date of the first employee in the filtered list
            if (this.employeeMasterLucene.length > 0) {
              this.leavingdate = this.employeeMasterLucene[0].leavingdateString; // Extract the leaving date
              console.log("Filtered Employee Data: ", this.employeeMasterLucene);
              console.log("leavingdate", this.leavingdate);
            } else {
              console.log("No employee data found.");
            }
          } else {
            console.log("No employee data found.");
          }
          
          // Update employeeMaster with the filtered data
          this.employeeMaster = this.employeeMasterLucene;
  
          // Set the selectedempid to the first employee's unique ID
          if (this.employeeMasterLucene.length > 0) {
            this.selectedempid = this.employeeMasterLucene[0].uniqueemployeeid;
          }
          
        },
        error => {
          console.log("Error ", error);
        }
      );
    }
  }

  onStatusChange() {
    this.getEmployeeByNameOrId();
  }
  checkAccessForUser() {
		console.log("checkAccessForUser here");
    this.allowToViewReport =this.permissionList.includes('ng.leave.edit.leavestatus.report.permission');
		this.showBasedOnPermissionEmployeeStatus = this.permissionList.includes('ng.employee.search.show.employeestatus');
	  }
    
}
