import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {
  protected basePath = '/api';
  public defaultHeaders: Headers = new Headers();
  public url;

  constructor(private httpclient: HttpClient, private commonService: CommonService) {}


  public getNoticeperiod_from_employeemaster(mid: any): Observable<any> {
    this.url = '/v1/getnoticeperiod-from-employeemaster';
    const path = this.basePath + this.url;
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('inputvalue',mid);
    return this.commonService.getByQueryParamWithHttpInfo( queryParameters, path);
  }
}
