import { Component, HostListener, OnInit } from '@angular/core';
import { EmployeeLeaveService } from '../services/employee-leave.service';
import { EmployeeMaster } from '../models/employeeMasterts';
import { ApplicationSettingsService } from '../services/application-settings.service';
import { EmployeeMasterservice } from '../services/employeemaster.service';
import { Employeesuspensioninfo } from '../models/Employeesuspensioninfo';
import { LuceneSearchService } from '../services/lucene-search.service';
import { Menu } from '../services/menu.Service';

@Component({
  selector: 'app-employee-suspension-report',
  templateUrl: './employee-suspension-report.component.html',
  styleUrls: ['./employee-suspension-report.component.css']
})
export class EmployeeSuspensionReportComponent implements OnInit {
  statusValue: string = 'All';
  activeInativeLuceneFilter: string = 'Active';
  query: any;
  queryName: any;
  employeeid: String;
  uniqueemployeeid: String;
  employeeMasterLucene: EmployeeMaster[];
  department: any[] = [];
  limitPara: any;
	offsetPara: any;
  selecteddepartment: string= "0";
  fromDate: string;
	toDate: string;
  leaveStatus: string;
  suspenseReport: Employeesuspensioninfo[]=[];
  client_id: EmployeeMaster[];
  keyinempid: any | undefined;
  // employeeMaster: EmployeeMaster[];
  selectedempid: any;
  leavingdate: any;
  permissionList: any[] = [];
  showBasedOnPermissionEmployeeStatus: boolean = false;
  allowToApprove: boolean=false;

  constructor(private empLeaveService: EmployeeLeaveService,private applicationSettingsService: ApplicationSettingsService,
    private empLuceneSearch: LuceneSearchService,
    private employeeMasterservice:EmployeeMasterservice,private menuService: Menu) {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth() + 1;
      this.fromDate = `${year}-${month.toString().padStart(2, '0')}-01`;
      const lastDay = new Date(year, month, 0).getDate();
      this.toDate = `${year}-${month.toString().padStart(2, '0')}-${lastDay.toString().padStart(2, '0')}`;
   }

  ngOnInit(): void {
    this.getDepartment();
    this.limitPara = 13;
    this.offsetPara = 0;
    const maxString = sessionStorage.getItem('permission').replace('[', '');
    const maxStringBox = maxString.replace(']', '');
    const maxStringAgain = maxStringBox.replace(/"/g, '');
    this.permissionList = maxStringAgain.split(',');
    this.checkAccessForUser();
  
  }
  getDepartment() {
    this.applicationSettingsService.getDepartmentType().subscribe(
      (data) => {
        console.log("Data available :", data);
        this.department = data.departmentDetail;
        console.log("List of all the department available :", this.department);
      },
      (error) => {
        console.log(error);
      }
    );
  }
 
 
  getEmployeeByNameOrId() {
    this.empLuceneSearch.getEmployeeByNameOrId(this.queryName,this.employeeid,this.activeInativeLuceneFilter,this.query , this.employeeMasterLucene,this.leavingdate,this.selectedempid).subscribe(res=>{
      console.warn(res)
      this.employeeMasterLucene=res.filteredEmployees;
      this.selectedempid = res.selectedempid;
    }); }
  
  onStatusChange() {
    this.getEmployeeByNameOrId();
  }

    onScroll() {
      this.offsetPara = this.offsetPara + 13;
      console.log("limit = ? ", this.limitPara, "offset = +13 ", this.offsetPara)
      this.searchSuspensionReport();
  
    }
  
    limitpara() {
      this.offsetPara = 0;
      console.log(" on clikc limit para ", this.limitPara)
      console.log("this offset", this.offsetPara)
    }
  
    @HostListener('scroll', ['$event'])
    scrolled() {
  
      console.log("Scrolled >>> ")
      this.onScroll();
    }
  
  
  searchSuspensionReport() {
    console.log("uniqueeempid :", this.selectedempid);
    console.warn("search");
    let employeename = (<HTMLInputElement>document.getElementById('employeename')).value;
    if (!employeename) {
      employeename = null;
    } else {
      employeename = employeename.trim()
    //   this.statusValue ="All";
    //  this.selecteddepartment = "0";
    }
    console.log("employeename :", employeename);
    var id = null;
    if (employeename) {
      this.client_id = this.employeeMasterLucene.filter((dName) => {
        return dName.name === employeename;
      });

      console.log("data parent: ", this.client_id)
      id = this.selectedempid;
      this.keyinempid = id;
    } else {
      console.log("Id parent", id)
    }
    this.employeeMasterservice.getEmployeeSuspensionReport(this.fromDate, this.toDate,this.activeInativeLuceneFilter,this.statusValue,this.selecteddepartment, id,employeename, this.limitPara, this.offsetPara).subscribe(
        (data) => {
          this.suspenseReport=data.employeesuspension_intermediate;
          console.log("Data available :", data);
          console.log(data.employeesuspensioninfo);
          console.log(this.suspenseReport);
          
        },
        (error) => {
          console.log(error);
        }
      );
  }
  clear(){
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    this.fromDate = `${year}-${month.toString().padStart(2, '0')}-01`;
    const lastDay = new Date(year, month, 0).getDate();
    this.toDate = `${year}-${month.toString().padStart(2, '0')}-${lastDay.toString().padStart(2, '0')}`;
     this.selecteddepartment = "0";
    this.query='';
   }

   checkAccessForUser() {
    console.log("checkAccessForUser here")
    this.showBasedOnPermissionEmployeeStatus = this.permissionList.includes('ng.employee.search.show.employeestatus');
    this.allowToApprove= this.permissionList.includes('ng.screen.approvesuspensionreport');
  }
  setMenuName(name: string) {
		this.menuService.setMenuName(name);
	  }
}
