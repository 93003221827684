<!-- page content -->
<div class="app-content main-content">
    <div class="side-app main-container">

        <div class="alertcomp"></div>

        <!-- <div class="page-header d-xl-flex d-block"> -->
        <!-- <div class="page-leftheader">
                <div class="page-title">Document Submission Report</div>
            </div> -->
        <!-- <div class="page-rightheader ms-md-auto">
                <div class="align-items-end flex-wrap my-auto right-content breadcrumb-right">
                    <div class="btn-list">
                        <a [routerLink]="['/employeemaster']" class="btn btn-primary me-3">Add New Employee</a> -->
        <!-- <button  class="btn btn-light" data-bs-toggle="tooltip" data-bs-placement="top" title="E-mail"> <i class="feather feather-mail"></i> </button>
                <button  class="btn btn-light" data-bs-placement="top" data-bs-toggle="tooltip" title="Contact"> <i class="feather feather-phone-call"></i> </button>
                <button  class="btn btn-primary" data-bs-placement="top" data-bs-toggle="tooltip" title="Info"> <i class="feather feather-info"></i> </button> -->
        <!-- </div>
                </div>
            </div> -->
        <!-- </div> -->

        <div class="row">
            <div class="col-xl-12 col-md-12 col-lg-12">
                <div class="card" style="margin-bottom:10px">
                    <!-- <div class="card-header border-bottom-0">
                <h4 class="card-title">Horizontal Form</h4>
            </div> -->
                    <div class="card-body">
                        <form class="form-horizontal" id="fcForm" (keyup.enter)="limitpara(); searchEmployeeDoc()">
                            <!-- <div class="row p-4">
                        <div class="col-md-4 col-sm-6 col-xs-12 p-0">
                            <div class="form-group label-floating">
                                 <label for="employeename" class="form-label"> Employee Name</label>
                                <div >
                                    <input type="text" placeholder="Enter Employee Name" class="form-control col-md-12 col-xs-12"  name="Employee Name" id="employeename">
                                </div>
                                <div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-4 col-sm-6 col-xs-12 p-0">
                            <div class="form-group label-floating">
                                <label for="uniqueemployeeid" class="form-label"> Employee Unique Id</label>
                                <div>
                                    <input type="text" class="form-control col-md-12 col-xs-12"  placeholder="Enter Employee Uniquee ID"
                                      [(ngModel)]="employeeDocStatusUpdates.uniqueEmployeeId" name="uniqueemployeeid"
                                    #uniqueemployeeid="ngModel" id="uniqueid" autofocus="autofocus">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6 col-xs-12 p-0">
                            <div class="form-group label-floating">
                                <br>
                                <div>
                                    <button type="button" class="btn btn-primary  w-100  w-md-20 mt-3 mt-md-0 p-2 p-md-1" (click)="limitpara(); searchEmployeeDoc()">
                                        <i class="fa fa-search" aria-hidden="true"></i> Search
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div> -->

                            <div class="x_content row px-4">
                                <div class="col-md-8 col-sm-8 col-xs-8">
                                    <div class="form-group">
                                        <!-- <label for="inputName"
                                     class="w-100  w-md-20 fontSize-15 form-label mt-auto mb-2 p-0">Employee Name /
                                    ID</label>  -->


                                        <label for="inputName" class="form-label">Employee Name /
                                            ID</label>

                                        <div class="px-0">
                                            <input type="search" class="form-control" name="Employee Name"
                                                id="employeename" placeholder="Enter Employee Name / ID "
                                                [(ngModel)]="query" autocomplete="off" (input)="getEmployeeByNameOrId()"
                                                list="employeeMasterLucene" autofocus="autofocus">
                                            <datalist id="employeeMasterLucene">
                                                <option data-value={{emp.name}}
                                                    *ngFor="let emp of employeeMasterLucene">
                                                    {{emp.employeeid}}
                                                </option>
                                            </datalist>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group col-md-4 col-sm-4 col-xs-4 px-2">
                                    <label class="form-label">Employee Status</label>
                                    <select class="form-control" type="text" style="width: 100%;" required
                                        [(ngModel)]="selectedValue" name="selVal"
                                        (change)="onStatusChange()"
                                        [disabled]="!showBasedOnPermissionEmployeeStatus">
                                        <option value="All">All</option>
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                    </select>
                                </div>
                            </div>
                            <!-- <div class="form-group row">    
                        <label for="inputEmail3" class="w-100  w-md-20 form-label mt-auto mb-0 fontSize-15 p-0">Employee Id</label>
                        <div class="col-md-12 px-0 py-2">
                            <input  type="text" [(ngModel)]="employeeDocStatusUpdates.uniqueEmployeeId" name="uniqueemployeeid" class="form-control" placeholder="Enter Employee ID"
                            #uniqueemployeeid="ngModel" id="uniqueid" autofocus="autofocus">
                        </div>
                    </div> -->
                            <div class="x_content row px-4">
                                <div class="col-md-4 col-sm-4 col-xs-4">
                                    <div class="form-group label-floating ">
                                        <label class="form-label"> From Date: </label>
                                        <div>
                                            <input class="form-control" type="date" name="fromdate" id="fromdate"
                                                [(ngModel)]="fromdate">
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-4 col-sm-4 col-xs-4">
                                    <div class="form-group label-floating ">
                                        <label class="form-label"> To Date: </label>
                                        <div>
                                            <input class="form-control" type="date" name="todate" id="todate"
                                                [(ngModel)]="todate">
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-4 col-sm-4 col-xs-4">
                                    <div class="form-group label-floating">
                                        <label class="form-label">Department</label>
                                        <div>
                                            <div>
                                                <select id="departmentDropDown" [(ngModel)]="departmentDropDown"
                                                    class="form-control" name="dateFilter">
                                                    <option value="All">All</option>
                                                    <option value="{{data.departmentId}}"
                                                        *ngFor="let data of filteredDepartmentNames;let i = index">
                                                        {{data.departmentname}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="x_content row px-4">
                                <div class="col-md-4 col-sm-4 col-xs-4">
                                    <div class="form-group label-floating">
                                        <label class="form-label">Status</label>
                                        <div>
                                            <div>
                                                <select id="statusDropDown" [(ngModel)]="statusDropDown"
                                                    class="form-control" name="dateFilter">
                                                    <option value="All" selected>All</option>
                                                    <option value="Submitted">Submitted</option>
                                                    <option value="Verified & Approved">Verified & Approved</option>
                                                    <option value="Rejected">Rejected</option>
                                                    <option value="NotSubmitted">Not Submitted</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 col-sm-4 col-xs-4">
                                    <div class="form-group label-floating">
                                        <label class="form-label">Document Type</label>
                                        <div>
                                            <div>
                                                <select id="documentDropDown" [(ngModel)]="documentDropDown"
                                                    class="form-control" name="dateFilter">
                                                    <option value="All">All</option>
                                                    <option value={{data.docType}} *ngFor="let data of filteredDocType">
                                                        {{data.docType}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 col-sm-3 col-xs-3">
                                    <label class="form-label col-md-12 col-sm-4 col-xs-4 mb-1">&nbsp;</label>
                                    <div class="form-group m-0">
                                        <button type="button"
                                            class="btn btn-primary  w-100  w-md-250 mt-3 mt-md-0 p-2 p-md-1"
                                            (click)="limitpara(); searchEmployeeDoc()" id="navigatetarget">
                                            <i class="fa fa-search" aria-hidden="true"></i> Search
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="empty-div-one" id="add-update-navigatedest"></div>
        <div class="row">
            <div class="col-xl-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="card-header d-flex justify-content-between align-items-center  border-0">
                            <h4 class="card-title">Employees Documents</h4>
                           
                            <span [routerLink]="['/approvalinbox']" class="link-text"
                            [routerLinkActive]="'highlightActiveLink'"
                            (click)="setMenuName('View/Approve Documents')"
                            [ngClass]="{'disable-div': !allowToApproved}"
                            *ngIf="allowToApproved">
                            View/Approve Documents
                            </span>
                         
                        
                    </div>
                    <div class="card-body">
                        <div class="col-xs-12 mobile_view">
                            <div *ngIf="employeeDocStatusList !== null ">
                                <div class="scrollsearchresults" id="scroll" infiniteScroll [infiniteScrollDistance]="2"
                                    [infiniteScrollUpDistance]="1.5" [infiniteScrollThrottle]="100"
                                    [scrollWindow]="false" (scrolled)="onScroll()" id="scrolllength">
                                    <table
                                        class="table table-hover table-vcenter text-nowrap table-bordered border-bottom"
                                        id="hr-table">
                                        <thead class="tablerow">
                                            <tr class="stickyatTop rowcolors">
                                                <th class="border-bottom-0 w-5 fs-7 fixedcolumn">Id</th>
                                                <th class="border-bottom-0 fs-7 fixedcolumn secondcolumn">Employee Name
                                                </th>
                                                <th class="border-bottom-0 w-10 fs-7">Doc Group</th>
                                                <th class="border-bottom-0 fs-7">Doc Type</th>
                                                <!-- <th class="border-bottom-0 fs-7">File Name</th> -->
                                                <th class="border-bottom-0 fs-7">Status</th>
                                                <th class="border-bottom-0 fs-7">Comments</th>
                                                <th class="border-bottom-0 fs-7">Approved By</th>
                                                <th class="border-bottom-0 fs-7">Expiry Date</th>
                                                <th class="border-bottom-0 fs-7">Uploaded On</th>
                                                <th class=""> </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let j=index; let empDocInfo of employeeDocStatusList ">
                                                <td class="fixedcolumn backgroundFW" data-th="Employee Id">
                                                    {{empDocInfo.employeeid}}
                                                </td>
                                                <td class="fixedcolumn backgroundFW secondcolumn backgroundSW"
                                                    data-th="Employee Name">
                                                    {{empDocInfo.employeename}}
                                                </td>
                                                <td data-th="Doc Group">
                                                    {{empDocInfo.docGroup}}
                                                </td>
                                                <td data-th="Doc Type">
                                                    {{empDocInfo.docType}}
                                                </td>

                                                <!-- <td  class="text-wrap" style="min-width:10vw;max-width:14vw;" data-th="File Name">
                                                    {{empDocInfo.fileName}}
                                                </td> -->
                                                <td data-th="Status">
                                                    {{empDocInfo.overallStatus}}
                                                </td>
                                                <td class="text-wrap" style="min-width:10vw;max-width: 20vw;"
                                                    data-th="Comments">
                                                    {{empDocInfo.comments}}
                                                </td>

                                                <td data-th="Approved By">
                                                    {{empDocInfo.approvedBy}}
                                                </td>

                                                <td data-th="Expiry Date">
                                                    {{empDocInfo.expiryDate | date: 'mediumDate'}}
                                                </td>
                                                <td data-th="Uploaded On">
                                                    {{empDocInfo.uploadedOn | date: 'mediumDate' }}
                                                </td>
                                                <td>
                                                    <button class="btn btn-primary btn-icon btn-sm me-2"
                                                        (click)="myDocumentDownload(empDocInfo.fileName, empDocInfo.filePath)">
                                                        <i class="fa fa-download" data-bs-toggle="tooltip"
                                                            data-original-title="download"></i>
                                                    </button>
                                                </td>

                                                <td *ngIf="empDocInfo.overallStatus === 'Verfied & Approved'"
                                                    [hasAccess]="{role:getUserRole(),key:'DeleteverifiedDoc'}">
                                                    <button class="btn btn-danger btn-icon btn-sm me-2"
                                                        (click)="delete_verifieddocument($event , empDocInfo)"
                                                        data-bs-toggle="tooltip" data-original-title="Delete">
                                                        <i class="feather feather-trash-2" data-bs-toggle="tooltip"
                                                            data-original-title="delete"></i>
                                                    </button>
                                                </td>
                                                <!-- <td><span class="badge badge-success">Active</span></td> -->
                                                <!-- <td>
                                    <a class="btn btn-primary btn-icon btn-sm"  href="hr-empview.html">
                                        <i class="feather feather-edit" data-bs-toggle="tooltip" data-original-title="View/Edit"></i>
                                    </a>
                                    <a class="btn btn-danger btn-icon btn-sm" data-bs-toggle="tooltip" data-original-title="Delete"><i class="feather feather-trash-2"></i></a>
                                </td> -->
                                                <!-- <td>
                                    <a class="btn btn-primary btn-icon btn-sm me-2"  >
                                        <i class="feather feather-edit" data-bs-toggle="tooltip" data-original-title="View/Edit"></i>
                                    </a>
                                    <a class="btn btn-danger btn-icon btn-sm" data-bs-toggle="tooltip" data-original-title="Delete"><i class="feather feather-trash-2"></i></a>
                                </td> -->
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>

<div id="showAlertVer" class="modal" style="top: 40px;">
    <div class="modal-dialog">
        <div class="modal-content animate">
            <div class="modal-header">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">×</button>
                <h4 class="modal-title">Warning</h4>
            </div>

            <div class="modal-body" style="max-height: calc(100vh - 210px);overflow-y: auto;">
                <!-- auto scroll -->
                <span><b> Document will be deleted permanently . Are you sure you want to continue ?</b></span>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-danger medium" (click)="confirmDocDelete()">OK</button>
                <button type="button" class="btn btn-primary" (click)="closeMRQModal()">Cancel</button>

            </div>

        </div>
    </div>
</div>