import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup,  NgForm, NgModel,Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import { map } from 'rxjs/operators';
import { AlertService, SessionService } from 'src/app/services';
import { EmployeeMasterservice } from 'src/app/services/employeemaster.service';
import { DatePipe } from '@angular/common';
import { Approvalinboxservice } from 'src/app/services/approvalinbox.service';

import { CustomerService } from 'src/app/services/customer.service';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Configkey } from 'src/app/models/Configkey';
import { BehaviorSubject } from 'rxjs';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Faceregister } from 'src/app/models/faceregister';
import { Menu } from 'src/app/services/menu.Service';
import { EmpsalaryslipService } from 'src/app/services/empsalaryslip.service';
import { SalaryComponentDetailsInfo } from 'src/app/models/salaryComponents';
// import subject and takeuntil rxjs concept 
import { EmployeeFamilyDetails } from 'src/app/models/EmployeeFamilyDetails';

let dobDate: string;
declare var $: any;
@Component({
  selector: 'app',
  templateUrl: './employeemaster.component.html',
  styleUrls: ['./employeemaster.component.css']
})




export class EmployeemasterComponent implements OnInit, AfterViewInit {
  subject = new BehaviorSubject(true);
  LeaveTypeList: [];
  LeaveTypeListValue: [{ leaveType: any }, { noOfDays: number }];
  currentDate: string;
  WeeklyOffList: [];

  uidChecker: Boolean = false;
  // LeaveTypeListValue:[];
  leaveMastercorrect: any[];
  form = new FormGroup({});
  model: any = {};
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[];
  optionvalueArr: Array<Object> = []
  url: string | ArrayBuffer;
  uniqueempid: string = this.session.getempid()
  secondaryDepartmentObj: any[];
  secondaryDepartmentObjFiltered: any[]
  requestDepartmentObj: any[] = [];
  existing_emp_secondarydeplist: any[] = [];
  isEmpSecondaryDepisEdited: boolean = false;
  defaultmailid: string = "";
  contractClientRPTManList: any;
  dependentOn: any[] = ['Yes', 'No'];
  relationshipWithEmp: any[] = ['Mother', 'Father', 'Spouse', 'Son', 'Daughter'];
  selectFromDrop: boolean = false;
  url2: string | ArrayBuffer;
  uploadError: boolean = false;
  insertaddsalarydetails;
  insertdeductsalarydetails
  insertaddsalarydetail = [];
  insertdeductsalarydetail = [];
  additiondetailMasterSection: any;
  deductiondetailMasterSection: any;
  additionsalaryMaster: any[] = [];
  deductionsalaryMaster: any[] = [];
  salaryData: any[] = [];
  
  

  constructor(private route: ActivatedRoute, private employeeMasterService: EmployeeMasterservice, private alertService: AlertService, private router: Router, private datepipe: DatePipe,
    private approvalinboxservice: Approvalinboxservice, private session: SessionService,
    private empService: EmpsalaryslipService,

    private customerservie: CustomerService, private _snackBar: MatSnackBar, private employeeMasterservice: EmployeeMasterservice,
    private menuService: Menu) {
    const today = new Date();
    this.currentDate = today.toISOString().split('T')[0];
  }

  private ngUnsubscribe: Subject<void> = new Subject<void>(); // create new subject 


  curentMainDepId: number;
  empObj: any;
  amount: any;
  imageToupload: File | null = null;
  trackobj: string = '';
  disDiv: boolean = false;
  // Bulk upload

  excelDoc: any = {};
  fileToUpload: File | null = null;
  submitted: boolean;
  public files: any[] = [];
  fileslength: boolean;
  multipleFilePath = [];
  uploadedDocDetails = [];
  loading: boolean = false; // Flag variable
  permissionList: any[] = [];
  allowAdminToEdit: boolean = false;

  /// this to override mandatory field 
  mandatoryFields: Configkey[] = [];

  DOB: any;
  MobileNumber: any;
  Email: any;
  EmployeeID: any;
  // Designation: any;
  ApproveAuthority: any;
  Department: any;
  UnitBranch: any;
  DOJ: any;
  enablebackBtn: boolean = false;
  faceregisterdata: Faceregister[] = [];
  cameramsg: string;

  imagePath: any;
  imageName: any;
  excelErrors: string[] = [];
  isFileValid: boolean = true;

  tempUid;


//employee family screen -fields
  
@ViewChild('familyDetailForm') familyDetailForm: NgForm;
insertFamilyDetailsData;
familyMembers: EmployeeFamilyDetails[] = [];
add_view_toggle: string = 'viewFamilyDetails';
addOrUpdate: boolean = true;
totalNominationPercentage: number;
nominationError: string;


  getUnit() {
    if (this.empObj?.uniqueemployeeid === undefined) {
      this.tempUid = this.uniqueempid;
    } else {
      this.tempUid = this.empObj?.uniqueemployeeid;
    }
    return this.employeeMasterService.getSelectOptions(this.tempUid).
      pipe(
        map(data => {
          console.log(data)
          return data.activebranchs;
        })
      );
  }

  getDesignations() {
    if (this.empObj?.uniqueemployeeid === undefined) {
      this.tempUid = this.uniqueempid;
    } else {
      this.tempUid = this.empObj?.uniqueemployeeid;
    }
    return this.employeeMasterService.getSelectOptions(this.tempUid).
      pipe(
        map(data => {
          console.log(data)
          return data.designation;
        })
      );
  }

  getShiftMasters() {
    if (this.empObj?.uniqueemployeeid === undefined) {
      this.tempUid = this.uniqueempid;
    } else {
      this.tempUid = this.empObj?.uniqueemployeeid;
    }
    return this.employeeMasterService.getSelectOptions(this.tempUid).
      pipe(
        map(data => {
          return data.shiftid;
        })
      );
  }

  getDepartments() {
    if (this.empObj?.uniqueemployeeid === undefined) {
      this.tempUid = this.uniqueempid;
    } else {
      this.tempUid = this.empObj?.uniqueemployeeid;
    }
    return this.employeeMasterService.getSelectOptions(this.tempUid).
      pipe(
        map(data => {
          this.secondaryDepartmentObj = data.department;
          this.filterSecondaryDepartment()
          return data.department;
        })
      );
  }

  filterSecondaryDepartment() {
    if (!this.selectFromDrop) {
      this.curentMainDepId = this.empObj.departmentid;
    }
    if (this.curentMainDepId != null || this.curentMainDepId != undefined) {
      this.secondaryDepartmentObjFiltered = [...this.secondaryDepartmentObj];
      this.secondaryDepartmentObjFiltered = this.secondaryDepartmentObjFiltered.filter(secondaryDep => secondaryDep.departmentId != this.curentMainDepId)
    }
  }

  getSecondaryDepartments(id) {
    this.employeeMasterService.getempSecondary_department(id).
      subscribe({
        next: (data) => {
          console.log(data, "sec_deps");
          this.existing_emp_secondarydeplist = data.secondarydepartment
        },
        error: (e) => {
          console.log(e)
        },
        complete: () => {

        }
      }
      )
  }

  set_emp_secondary_dep() {
    console.log("set_emp_secondary_dep : ", this.existing_emp_secondarydeplist)
    if (!this.isEmpSecondaryDepisEdited) {
      if (this.empObj?.isedit === "true") {
        console.log(this.existing_emp_secondarydeplist, "insidetheif");

        this.existing_emp_secondarydeplist.forEach(item => {
          (<HTMLInputElement>document.getElementById(`${item.name}`)).checked = true;
        })

        this.requestDepartmentObj = [...this.existing_emp_secondarydeplist];
        this.isEmpSecondaryDepisEdited = true;
      }
      else {
        this.secondaryDepartmentObjFiltered.forEach(item => {
          (<HTMLInputElement>document.getElementById(`${item.name}`)).disabled = true;
        })
        this.existing_emp_secondarydeplist.forEach(item => {
          (<HTMLInputElement>document.getElementById(`${item.name}`)).checked = true;

        })
      }
    }
  }

  getWeeklyHolidayScreen() {
    if (this.empObj?.uniqueemployeeid === undefined) {
      this.uidChecker = true;
      console.log("weekly off  , when seleced employee doesnt have uid ");
      this.weeklyOff(this.uniqueempid);
    } else {
      this.uidChecker = false;
      console.log("weekly off  , when seleced employee does have uid ");
      this.weeklyOff(this.empObj?.uniqueemployeeid);
    }
  }

  weeklyOff(uid) {
    this.employeeMasterService.getSelectOptions(uid).
      subscribe((data => {
        this.WeeklyOffList = data.weeklyOff;
        console.log("this.WeeklyOffList", this.WeeklyOffList);
      })
      );
  }

  getDayName(weeklyOffDay: number): string {
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    return daysOfWeek[weeklyOffDay - 1];
  }

  toggleSelection(off: any) {
    console.log("toggle selection ", off);
    if (off.weeklyOffId != -1) {
      off.flag = off.flag === "false" ? "true" : "false";
    } else {
      off.flag = off.flag === "true" ? "false" : "true";
    }
    console.log("after ", off);
  }

  getSalaryMaster() {
    if (this.empObj?.uniqueemployeeid === undefined) {
      this.uidChecker = true;
      console.log("salary master , when seleced employee doesnt have uid ");
      this.SalaryApi(this.uniqueempid);
    } else {
      this.uidChecker = false;
      console.log("salary master , when seleced employee doesnt have uid ");
      this.SalaryApi(this.empObj?.uniqueemployeeid);
    }
  }

  SalaryApi(uid) {
    if (this.empObj?.uniqueemployeeid === undefined) {
      this.uidChecker = true;
      this.employeeMasterService.getSelectOptions(uid).
        subscribe((data => {
          console.log("salary api without uid make it 0 not -1 ");
          // this.model.salaryadditionComp = JSON.parse(JSON.stringify(data.salaryadditionComp));
          // this.model.salarydeductionComp = JSON.parse(JSON.stringify(data.salarydeductionComp));

          this.model.employeeattributevalueinfo = JSON.parse(JSON.stringify(data.employeeattributevalueinfo));

          this.model.salaryadditionComp = this.insertaddsalarydetail;
          this.model.salarydeductionComp = this.insertdeductsalarydetail;

          console.log("additional Comp ", this.model.salaryadditionComp);
          console.log("deduction Comp ", this.model.salarydeductionComp);

        })
        );
    } else {
      this.uidChecker = false;
      this.employeeMasterService.getSelectOptions(uid).
        subscribe((data => {
          console.log("salary api with uid , show what value is there it self ");

          // this.model.salaryadditionComp = this.insertsalarydetails;
          // this.model.salarydeductionComp = this.insertsalarysdetails;

          this.insertdeductsalarydetail = data.salarydeductionComp;
          this.insertaddsalarydetail = data.salaryadditionComp;
          this.model.employeeattributevalueinfo = JSON.parse(JSON.stringify(data.employeeattributevalueinfo));

          console.log("additional Comp ", this.insertaddsalarydetail);
          console.log("deduction Comp ", this.insertdeductsalarydetail);
        })
        );
    }
  }

  redirectToSearchEmployeeBack(e, info) {
    console.log("back btn to search emp")
    this.router.navigate(['employeesearch', { name: this.empObj.name }]);
  }

  redirectToSearchEmployee(e) {
    this.router.navigate(['employeesearch']);
    this.setMenuName("Employee Search");
  }

  submit() {
    console.log(" ")
    console.log("submit : ")
    // this.getMandatoryFields();

    console.log(this.form, this.form.get('name'))
    if (this.form.valid) {

      if (this.empObj?.isedit === "true") {
        console.log("model preview for update ", this.model)
        this.model.salaryadditionComp = this.insertaddsalarydetail;
        this.model.salarydeductionComp = this.insertdeductsalarydetail;
        this.model.weeklyOff = this.WeeklyOffList;
        this.model.userid = this.empObj?.userid;
        this.model.uniqueemployeeid = this.empObj?.uniqueemployeeid
        this.model.secdepartment = this.requestDepartmentObj;
        console.log("check this model value here update ", this.model)
        this.employeeMasterService.updateemployeData(this.model, this.imageToupload).subscribe(data => {
          console.log("Response from server : ", data)
          this.alertmessages("Successfully Updated", "success");
          this.downloadImageHelper(this.imageToupload, this.model.employeeid).subscribe((res: { profilepath: string }) => {
            this.alertmessages("Successfully Updated", "success");
            this.options.resetModel();
            console.log("Data shown before edit in screen : ", this.empObj)
            this.empObj = {};
            this.empObj = data.empMasterinfoById;
            this.empObj.isedit = true;
            this.empObj.profilepath = res?.profilepath;
            this.reloadthescreenwithupdatedData(this.empObj);

          }, error => {
            this.alertmessages("Successfully Updated", "success");
            this.options.resetModel();
            console.log("Data shown before edit in screen : ", this.empObj)
            this.empObj = {};
            this.empObj = data.empMasterinfoById;
            this.empObj.isedit = true;
            this.reloadthescreenwithupdatedData(this.empObj);
          });

          console.log("Data shown after edit in screen : ", this.empObj)
        },
          (error) => {
            if (error.error.errorMessages != null && error.error.errorMessages.length !== 0) {
              //  this.alertService.error(error.error.errorMessages[0]['key']);
              this.alertmessages(error.error.errorMessages[0]['key'], "danger");
            }
          })
      } else {
        console.log("model preview for save ", this.model)
        this.model.salaryadditionComp = this.insertaddsalarydetail;
        this.model.salarydeductionComp = this.insertdeductsalarydetail;
        this.model.weeklyOff = this.WeeklyOffList;
        this.model.profilepath = null
        // this.model.file = this.imageToupload ; 
        this.model.secdepartment = this.requestDepartmentObj;
        console.log(this.requestDepartmentObj)
        this.model.leavemasterlist = this.LeaveTypeListValue;
        console.log("check this model value here save  ", this.model)
        this.employeeMasterService.saveEmployeeMaster(this.model).subscribe(
          data => {
            console.log("Saved data : ", data)
            this.downloadImageHelper(this.imageToupload, this.model.employeeid).subscribe((res: { profilepath: string }) => {
              this.alertmessages("Successfully Updated", "success");
              this.options.resetModel();

              console.log("Data shown before edit in screen : ", this.empObj)
              this.empObj = {};
              this.empObj = data.empMasterinfoById;
              this.empObj.isedit = true;
              this.empObj.profilepath = res?.profilepath;

              this.reloadthescreenwithupdatedData(this.empObj);

            }, error => {
              this.alertmessages("Successfully Updated", "success");
              this.options.resetModel();

              console.log("Data shown before edit in screen : ", this.empObj)
              this.empObj = {};
              this.empObj = data.empMasterinfoById;
              this.empObj.isedit = true;
              this.reloadthescreenwithupdatedData(this.empObj);
            });
          },
          error => {
            if (error.error.errorMessages != null && error.error.errorMessages.length !== 0) {
              this.alertmessages(error.error.errorMessages[0]['key'], "danger");
            } else {
              this.alertmessages("Not Saved", "danger")
            }

          }
        );
      }

    } else {
      // invalid case
      let errors = this.form.errors
    }
  }

  getFieldobj(key: string, fields: FormlyFieldConfig[]): FormlyFieldConfig {
    if (!this.fields) {
      return;
    }
    for (let i = 0, len = fields.length; i < len; i++) {
      const f = fields[i];
      if (f.key === key) {
        return f;
      }

      if (f.fieldGroup && !f.key) {
        const cf = this.getFieldobj(key, f.fieldGroup);
        if (cf) {
          return cf;
        }
      }
    }
  }

  createUser(uniqueemployeeid: any, branch: any) {
    this.router.navigate(['/people'], { queryParams: { empIdcorrect: uniqueemployeeid, branchcorrect: branch } });
  }


  getFamilyDetailsScreen() {

    if (this.empObj?.uniqueemployeeid === undefined) {
      this.uidChecker = true;
      console.log("this.uniqueempid",this.uniqueempid);
      this.getFamilyDetails(this.uniqueempid); // if new then populte with 0
    } else {
      this.uidChecker = false;
      console.log("this.uniqueempid",this.uniqueempid);
      this.getFamilyDetails(this.empObj?.uniqueemployeeid); // if wxistng then pop its own valeue from db 
    }
    
    
  }
  
  
  getFamilyDetails(uniqueempid) {
    debugger;
    console.warn("Fetching family details...");
    console.log( this.session.getempid(),"session id in getfamily details ")
    if (this.empObj?.uniqueemployeeid === undefined) {
      this.uidChecker = true;
      console.log(this.tempUid);
      console.log("family detail get method  , when seleced employee doesnt have uid ",this.empObj?.uniqueemployeeid);
      this.employeeMasterService.getEmpFamilyDetails(uniqueempid).subscribe(
        (data: any) => { // Use 'any' to inspect what data looks like
          console.log("Data from server:", data);
          this.familyMembers=data.family_detail;
          console.log("data.family_detail",this.familyMembers);
          console.log("List of family details:", this.familyMembers);
        },
        (error) => {
          console.error("Error fetching family details:", error);
        }
      );
      
    } else {
      this.tempUid = this.empObj?.uniqueemployeeid;
      console.log(this.tempUid);
      console.log("family detail get method  , when seleced employee does have uid ");
      this.employeeMasterService.getEmpFamilyDetails(uniqueempid).subscribe(
        (data: any) => { // Use 'any' to inspect what data looks like
          console.log("Data from server:", data);
          this.familyMembers=data.family_detail;
          console.log("data.family_detail",this.familyMembers);
          console.log("List of family details:", this.familyMembers);
        },
        (error) => {
          console.error("Error fetching family details:", error);
        }
      );
    }
    
   
  }

 
  ngOnInit() {
    this.insertaddsalarydetails = new SalaryComponentDetailsInfo();
    this.insertdeductsalarydetails = new SalaryComponentDetailsInfo();
    this.insertFamilyDetailsData = new EmployeeFamilyDetails();
    this.getFamilyDetailsScreen();
    this.viewFamilyDetails();
  
    
    this.searchSalaryDetails();
    this.getadditionSalaryMaster();
    this.getdeductionSalaryMaster();
    this.getSalaryMaster();
    this.getWeeklyHolidayScreen();
    const queryParams = this.route.snapshot.queryParams;
    this.empObj = queryParams;
    console.log("Data come from search employee : ", this.empObj)
    this.getDepartments();
    this.getdefaultemailid();
    this.showLeaveDate();
    this.getLeaveType();
    this.swapper(); // check selected uid is new or existing ..... 
    // this.setMandatoryFields();  // set 
    // this.getMandatoryFields();  // makes api call 


    this.getMandatoryFields().subscribe(() => {
    });                                           // make a subsribe to sub 

    const maxString = sessionStorage.getItem('permission').replace('[', '');
    const maxStringBox = maxString.replace(']', '');
    const maxStringAgain = maxStringBox.replace(/"/g, '');
    this.permissionList = maxStringAgain.split(',');

    this.checkAccessForUser();

  }

  swapper() {
    console.log("v1/getIndividualEmpLeaveType swapper api ");

    if (this.empObj?.uniqueemployeeid === undefined) {
      this.uidChecker = true;
      this.getIndividualLeaveType(this.uniqueempid); // if new then populte with 0
    } else {
      this.uidChecker = false;
      this.getIndividualLeaveType(this.empObj?.uniqueemployeeid); // if wxistng then pop its own valeue from db 
    }
  }

  resetobj() {
    this.disDiv = false;
    this.options.resetModel();
    this.reloadthescreen()
  }

  reloadthescreen() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/employeemaster']);
    });
  }

  reloadthescreenwithupdatedData(model) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/employeemaster'], { queryParams: model });
    });
  }

  showLeaveDate() {
    this.disDiv = false;
    // console.log("Leave date", this.empObj.leavingdate, " : ", Date());
    var leavingDate = this.empObj.leavingdateString;
    var currentDate = new Date().toISOString().split('T')[0];
    // console.log("Leaving date : date", this.empObj);
    // console.log("Leaving date String", this.empObj?.leavingdate, this.empObj.leavingdateString);
    // console.log("Leaving date", leavingDate);
    // console.log("Current date", currentDate)

    // Lezer: Check leaving date is there or not
    if (this.empObj.leavingdate) {

      // console.log("One -- if")
      if (leavingDate === currentDate) {
        // console.log("two -- if")
        this.alertmessages("Today is the last working day of this Employee in this Organization", "secondary")
      } else if (leavingDate < currentDate) {
        // console.log("One -- else if")
        this.disDiv = true;
        this.alertmessages("This Employee does not belong to this Organization anymore! Data cannot be edited.", "danger")
      } else {
        // console.log("One -- else")
        return;
      }
    }
  }

  getMandatoryFields() {    // add a return type else pipe will not wrk 
    console.log(" ")
    console.log("List from adminconfig table:");
    this.employeeMasterService.getMandatoryFieldForEM().subscribe({
      next: (data) => {
        this.mandatoryFields = data.mandatoryFieldEM;
        console.log(" ")
        console.log("Get Mandatory Fields")
        console.log("Data received: ", this.mandatoryFields);

        // Mapping the received data to the corresponding variables
        const fieldMap: { [key: string]: string } = {
          'DOB': 'DOB',
          'MobileNumber': 'MobileNumber',
          'Email': 'Email',
          'EmployeeID': 'EmployeeID',
          'Designation': 'Designation',
          'ApproveAuthority': 'ApproveAuthority',
          'Department': 'Department',
          'UnitBranch': 'UnitBranch',
          'DOJ': 'DOJ',

        };

        this.mandatoryFields.forEach(field => {
          const variable = fieldMap[field.configkey];
          if (variable !== undefined) {
            this[variable] = field.configvalue === '1';
          }
        });

        console.log(
          ": DOB - ", this.DOB,
          ": MOB - ", this.MobileNumber,
          ": EML - ", this.Email,
          ": EID - ", this.EmployeeID,
          ": AAH - ", this.ApproveAuthority,
          ": DID - ", this.Department,
          ": BID - ", this.UnitBranch,
          ": DOJ - ", this.DOJ
        )
        console.log("Get Mandatory")
        console.log(" ")
      },
      error: (e) => {
        console.log(e);
      },
    });

    // return type . mobile
    return this.employeeMasterService.getMandatoryFieldForEM().pipe(
      takeUntil(this.ngUnsubscribe)
    );
  }

  setMandatoryFields() {
    console.log(" ")
    console.log("Set Mandatory")
    this.DOB = true;
    this.MobileNumber = true;
    this.Email = true;
    this.EmployeeID = true;
    this.ApproveAuthority = true;
    this.Department = true;
    this.UnitBranch = true;
    this.DOJ = true;


    console.log(
      ": DOB - ", this.DOB,
      ": MOB - ", this.MobileNumber,
      ": EML - ", this.Email,
      ": EID - ", this.EmployeeID,
      ": AAH - ", this.ApproveAuthority,
      ": DID - ", this.Department,
      ": BID - ", this.UnitBranch,
      ": DOJ - ", this.DOJ
    )
    console.log("Set Mandatory")
    console.log(" ")


  }

  getField() {
    console.log('%check inside getField empObj ', 'color: green; background: yellow; font-size: 30px', this.empObj);
    this.fields = [
      {
        type: 'tabs',
        fieldGroup: [
          {
            templateOptions: { label: 'Personal Details *' },
            className: "white",
            fieldGroup: [
              {
                fieldGroupClassName: 'row mx-0 my-2  ',
                fieldGroup: [
                  {
                    key: 'uniqueemployeeid',
                    className: ' col-xs-12 col-md-6 form-label',
                    type: 'input',
                    defaultValue: (this.empObj?.uniqueemployeeid?.length > 0) ? this.empObj?.uniqueemployeeid : null,
                    templateOptions: {
                      label: 'Unique Employee Id',
                      required: false,
                    },
                    hooks: {
                      onInit: (field) => {
                        // if (this.empObj?.uniqueemployeeid) {
                          field.formControl.disable();
                        // }
                      },
                    },
                    // hideExpression: true,
                  },
                ],
              },
              {
                fieldGroupClassName: 'row mx-0 my-2 ',
                className: "white",
                fieldGroup: [
                  {
                    key: 'name',
                    className: ' col-xs-12 col-md-6 form-label',
                    type: 'input',
                    defaultValue: (this.empObj?.name?.length > 0) ? this.empObj?.name : null,
                    templateOptions: {
                      label: 'Employee Name',
                      placeholder: "Employee Name",
                      required: true,
                      change: (field, $event) => {
                        field.formControl.patchValue($event.target.value.trim())
                      }

                    },
                  },
                  {
                    key: 'dateofbirth',
                    className: ' col-xs-12 col-md-6 remove_isymbol',
                    type: 'input',
                    defaultValue: (this.empObj?.dateofbirth?.length > 0) ? this.datepipe.transform(this.empObj?.dateofbirth, 'yyyy-MM-dd') : null,
                    templateOptions: {
                      type: 'date',
                      label: 'Date of Birth',
                      // xyxyx
                      required: this.DOB,
                      change(field: FormlyFieldConfig, $event) {
                        dobDate = field.formControl.value
                      }
                    },
                    hooks: {

                    },
                    validators: {
                      validation: ['date'],
                    }
                  },
                ],
              },
              {
                fieldGroupClassName: 'row mx-0 my-2  ',
                fieldGroup: [
                  // Field: Gender
                  {
                    key: 'gender',
                    className: 'col-xs-12 col-md-6  ',
                    defaultValue: this.empObj?.gender
                      === "M" ? "M" : "F",
                    type: 'radio',
                    // defaultValue: "M",
                    templateOptions: {
                      label: 'Gender',

                      placeholder: 'Gender',
                      // xyxyx
                      required: true,
                      options: [
                        { value: 'M', label: 'Male' },
                        { value: 'F', label: 'Female' },
                      ],
                    },
                  },

                  // Field: Father's Name
                  {
                    key: 'fathersname',
                    className: 'col-xs-12 col-md-6 ',
                    type: 'input',
                    defaultValue: (this.empObj?.fathersname?.length > 0) ? this.empObj?.fathersname : null,
                    templateOptions: {
                      label: "Father's Name",
                      change: (field, $event) => {
                        field.formControl.patchValue($event.target.value.trim())
                      }
                    },
                    validators: {
                      validation: ['onlyText'],
                    }
                  },
                ],
              },
              {
                fieldGroupClassName: 'row mx-0 my-2',
                fieldGroup: [
                  // Field: Spouse Name
                  {
                    key: 'spousename',
                    className: 'col-xs-12 col-md-6',
                    type: 'input',
                    defaultValue: (this.empObj?.spousename?.length > 0) ? this.empObj?.spousename : null,
                    templateOptions: {
                      label: 'Spouse Name',
                      change: (field, $event) => {
                        field.formControl.patchValue($event.target.value.trim())
                      }
                    },
                    validators: {
                      validation: ['onlyText'],
                    }
                  },

                  // Field: DateofBirth
                  {
                    key: 'spousedateofbirth',
                    className: 'col-xs-12 col-md-6',
                    type: 'input',

                    templateOptions: {
                      type: 'date',
                      label: 'Spouse Date of Birth',
                      datepickerOptions: {
                        min: '2019-09-10'
                      },

                    },
                    validators: {
                      validation: ['date'],
                    }

                  },
                ],
              },
              {
                fieldGroupClassName: 'row mx-0 my-2',
                fieldGroup: [
                  // Field: Mobile Number
                  {
                    key: 'mobile',
                    className: 'col-xs-12 col-md-6',
                    type: 'input',
                    defaultValue: this.empObj?.mobile ? this.empObj?.mobile : null,

                    templateOptions: {
                      label: 'Mobile Number',
                      type: 'text',
                      // xyxyx
                      required: this.MobileNumber,
                      maxLength: 10, 
                    },
                    validators: {
                      validation: ['mobile'],
                    },
                  },

                  // Field: Emergency Number
                  {
                    key: 'emergencycontactno',
                    className: 'col-xs-12 col-md-6',
                    type: 'input',
                    defaultValue: (this.empObj?.emergencycontactno?.length > 0) ? this.empObj?.emergencycontactno : null,
                    templateOptions: {
                      label: 'Emergency Contact Number',
                    },
                    validators: {
                      validation: ['mobile', 'validationphoneandemergencyNumber'],
                    }
                  },
                ],
              },
              {
                fieldGroupClassName: 'row mx-0 my-2',
                fieldGroup: [
                  // Field: Mothers Name
                  {
                    key: 'mothername',
                    className: 'col-xs-12 col-md-6',
                    type: 'input',
                    defaultValue: (this.empObj?.mothername?.length > 0) ? this.empObj?.mothername : null,
                    templateOptions: {
                      label: "Mother's Name",
                      change: (field, $event) => {
                        field.formControl.patchValue($event.target.value.trim())
                      }
                    },
                    validators: {
                      validation: ['onlyText'],
                    }
                  },

                  // Field: Nominee
                  {
                    key: 'nominee',
                    className: 'col-xs-12 col-md-6',
                    type: 'input',
                    defaultValue: (this.empObj?.nominee?.length > 0) ? this.empObj?.nominee : null,
                    templateOptions: {
                      label: 'Nominee',
                      change: (field, $event) => {
                        field.formControl.patchValue($event.target.value.trim())
                      }
                    },
                    validators: {
                      validation: ['onlyText'],
                    }
                  },
                ],
              },
              {
                fieldGroupClassName: 'row mx-0 my-2',
                fieldGroup: [
                  // Field: Qualification
                  {
                    key: 'qualification',
                    className: 'col-xs-12 col-md-6',
                    type: 'input',
                    defaultValue: (this.empObj?.qualification?.length > 0) ? this.empObj?.qualification : null,
                    templateOptions: {
                      label: 'Qualification',
                      change: (field, $event) => {
                        field.formControl.patchValue($event.target.value.trim())
                      }
                    },
                  },

                  // Field: Additional Qualification
                  {
                    key: 'additionalqualification',
                    className: 'col-xs-12 col-md-6',
                    type: 'input',
                    defaultValue: (this.empObj?.additionalqualification?.length > 0) ? this.empObj?.additionalqualification : null,
                    templateOptions: {
                      label: 'Additional Qualification',
                      change: (field, $event) => {
                        field.formControl.patchValue($event.target.value.trim())
                      }
                    },
                  },
                ],
              },
              {
                fieldGroupClassName: 'row mx-0 my-2',
                fieldGroup: [
                  // Field: Email Address
                  {
                    key: 'email',
                    className: 'col-xs-12 col-md-6',
                    type: 'input',
                    defaultValue: (this.empObj?.email?.length > 0) ? this.empObj?.email : null,
                    templateOptions: {
                      type: 'email',
                      label: this.defaultmailid === "Employeeid" ? 'Email' : 'Username',
                      // xyxyx
                      required: this.Email,
                      disabled: (this.empObj?.email) ? true : false,
                      change: () => {
                        console.log(this.defaultmailid === "Employeeid")
                      }
                      // defaultemail:this.defaultmailid,
                    },
                    // validators: {
                    //   validation: ['emailOruserid'],
                    // },

                  },

                  // Field: Home Town
                  {
                    key: 'hometown',
                    className: 'col-xs-12 col-md-6',
                    type: 'input',
                    defaultValue: (this.empObj?.hometown?.length > 0) ? this.empObj?.hometown : null,
                    templateOptions: {
                      label: 'Home Town',
                      change: (field, $event) => {
                        field.formControl.patchValue($event.target.value.trim())
                      }
                    },
                  },
                ],
              },
            ],
          },
          {
            templateOptions: { label: 'Employee Address' },
            fieldGroup: [
              {
                fieldGroupClassName: 'row mx-0 my-2',
                fieldGroup: [

                  {
                    template: '<h5>Present Address</h5>',
                    className: 'col-xs-12 col-md-12'
                  },
                  // Field: Address
                  {
                    key: 'presentaddressl1',
                    className: 'col-xs-12 col-md-6',
                    type: 'input',
                    defaultValue: (this.empObj?.presentaddressl1?.length > 0) ? this.empObj?.presentaddressl1 : null,
                    templateOptions: {
                      label: 'Address Line 1',
                      change: (field, $event) => {
                        field.formControl.patchValue($event.target.value.trim())
                      }
                    },
                  },

                  // Field: Address
                  {
                    key: 'presentaddressl2',
                    className: 'col-xs-12 col-md-6',
                    type: 'input',
                    defaultValue: (this.empObj?.presentaddressl2?.length > 0) ? this.empObj?.presentaddressl2 : null,
                    templateOptions: {
                      label: 'Address Line 2',
                      change: (field, $event) => {
                        field.formControl.patchValue($event.target.value.trim())
                      }
                    },
                  },
                  // Field: Address
                  {
                    key: 'presentcity',
                    className: 'col-xs-12 col-md-6',
                    type: 'input',
                    defaultValue: (this.empObj?.permanentcity?.length > 0) ? this.empObj?.permanentcity : null,
                    templateOptions: {
                      label: 'City',
                      change: (field, $event) => {
                        field.formControl.patchValue($event.target.value.trim())
                      }
                    },
                    validators: {
                      validation: ['onlyText'],
                    }
                  },

                  // Field: Address
                  {
                    key: 'presentstate',
                    className: 'col-xs-12 col-md-6',
                    type: 'input',
                    defaultValue: (this.empObj?.presentstate?.length > 0) ? this.empObj?.presentstate : null,
                    templateOptions: {
                      label: 'State',
                      change: (field, $event) => {
                        field.formControl.patchValue($event.target.value.trim())
                      }
                    },
                    validators: {
                      validation: ['onlyText'],
                    }
                  },

                  // Field: Address
                  {
                    key: 'presentcountry',
                    className: 'col-xs-12 col-md-6',
                    type: 'input',
                    defaultValue: (this.empObj?.presentcountry?.length > 0) ? this.empObj?.presentcountry : null,
                    templateOptions: {
                      label: 'Country',
                      change: (field, $event) => {
                        field.formControl.patchValue($event.target.value.trim())
                      }
                    },
                    validators: {
                      validation: ['onlyText'],
                    }
                  },

                  // Field: Address
                  // {
                  //   key: 'presentzipcode',
                  //   className: 'col-xs-12 col-md-6',
                  //   type: 'input',
                  //   defaultValue: (this.empObj?.presentzipcode?.length > 0) ? this.empObj?.presentzipcode : null,
                  //   templateOptions: {
                  //     label: 'ZIP/PIN CODE',
                  //     change: (field, $event) => {
                  //       field.formControl.patchValue($event.target.value.trim())
                  //     }
                  //   },

                  // },

                  //To prevent entering char
                  {
                    key: 'presentzipcode',
                    className: 'col-xs-12 col-md-6',
                    type: 'input',
                    defaultValue: (this.empObj?.presentzipcode?.length > 0) ? this.empObj?.presentzipcode : null,
                    templateOptions: {
                      label: 'ZIP/PIN CODE',
                      change: (field, $event) => {
                        const numericValue = $event.target.value.replace(/\D/g, ''); // Remove non-numeric characters
                        field.formControl.patchValue(numericValue);
                      },
                      attributes: {
                        onkeypress: "return event.charCode >= 48 && event.charCode <= 57", // Restrict non-numeric input
                      }
                    },
                    validators: {
                      validation: ['numericOnly'],
                    }
                  },
                  

                  {
                    template: '<h5>Permanent Address</h5>'
                  },

                  {
                    key: 'samepresentpermanentadd',
                    type: 'checkbox',
                    className: 'col-xs-12 col-md-12',
                    defaultValue: false,
                    templateOptions: {
                      label: 'Same as Present Address',

                    },
                    expressionProperties: {
                      "model.permanentaddressl1": ((model: any, formState: any, field?: FormlyFieldConfig) => {
                        if (this.empObj?.permanentaddressl1?.length > 0) {
                          return this.empObj?.permanentaddressl1;
                        } else {
                          if (model.samepresentpermanentadd === true) {
                            return model.presentaddressl1;
                          } else {
                            return null;
                          }

                        }
                      }),
                      "model.permanentaddressl2": ((model: any, formState: any, field?: FormlyFieldConfig) => {
                        if (this.empObj?.permanentaddressl2?.length > 0) {
                          return this.empObj?.permanentaddressl2;
                        } else {
                          if (model.samepresentpermanentadd === true) {
                            return model.presentaddressl2;
                          } else {
                            return null;
                          }

                        }
                      }),
                      "model.permanentcity": ((model: any, formState: any, field?: FormlyFieldConfig) => {
                        if (this.empObj?.permanentcity?.length > 0) {
                          return this.empObj?.permanentcity;
                        } else {
                          if (model.samepresentpermanentadd === true) {
                            return model.presentcity;
                          } else {
                            return null;
                          }

                        }
                      }),
                      "model.permanentstate": ((model: any, formState: any, field?: FormlyFieldConfig) => {
                        if (this.empObj?.permanentstate?.length > 0) {
                          return this.empObj?.permanentstate;
                        } else {
                          if (model.samepresentpermanentadd === true) {
                            return model.presentstate;
                          } else {
                            return null;
                          }

                        }
                      }),
                      "model.permanentcountry": ((model: any, formState: any, field?: FormlyFieldConfig) => {
                        if (this.empObj?.permanentcountry?.length > 0) {
                          return this.empObj?.permanentcountry;
                        } else {
                          if (model.samepresentpermanentadd === true) {
                            return model.presentcountry;
                          } else {
                            return null;
                          }

                        }
                      }),
                      "model.permanentzipcode": ((model: any, formState: any, field?: FormlyFieldConfig) => {
                        if (this.empObj?.permanentzipcode?.length > 0) {
                          return this.empObj?.permanentzipcode;
                        } else {
                          if (model.samepresentpermanentadd === true) {
                            return model.presentzipcode;
                          } else {
                            return null;
                          }

                        }
                      }),
                   
                      
                      },
                    },
                  // Field: Address
                  {
                    key: 'permanentaddressl1',
                    className: 'col-xs-12 col-md-6',
                    type: 'input',
                    defaultValue: (this.empObj?.permanentaddressl1?.length > 0) ? this.empObj?.permanentaddressl1 : null,
                    templateOptions: {
                      label: 'Address Line 1',
                      change: (field, $event) => {
                        field.formControl.patchValue($event.target.value.trim())
                      }

                    },

                  },

                  // Field: Address
                  {
                    key: 'permanentaddressl2',
                    className: 'col-xs-12 col-md-6',
                    type: 'input',
                    defaultValue: (this.empObj?.permanentaddressl2?.length > 0) ? this.empObj?.permanentaddressl2 : null,
                    templateOptions: {
                      label: 'Address Line 2',
                      change: (field, $event) => {
                        field.formControl.patchValue($event.target.value.trim())
                      }
                    },
                  },
                  // Field: Address
                  {
                    key: 'permanentcity',
                    className: 'col-xs-12 col-md-6',
                    type: 'input',
                    defaultValue: (this.empObj?.permanentcity?.length > 0) ? this.empObj?.permanentcity : null,
                    templateOptions: {
                      label: 'City',
                      change: (field, $event) => {
                        field.formControl.patchValue($event.target.value.trim())
                      }
                    },
                    validators: {
                      validation: ['onlyText'],
                    }
                  },

                  // Field: Address
                  {
                    key: 'permanentstate',
                    className: 'col-xs-12 col-md-6',
                    type: 'input',
                    defaultValue: (this.empObj?.permanentstate?.length > 0) ? this.empObj?.permanentstate : null,
                    templateOptions: {
                      label: 'State',
                      change: (field, $event) => {
                        field.formControl.patchValue($event.target.value.trim())
                      }
                    },
                    validators: {
                      validation: ['onlyText'],
                    }
                  },

                  // Field: Address
                  {
                    key: 'permanentcountry',
                    className: 'col-xs-12 col-md-6',
                    type: 'input',
                    defaultValue: (this.empObj?.permanentcountry?.length > 0) ? this.empObj?.permanentcountry : null,
                    templateOptions: {
                      label: 'Country',
                      change: (field, $event) => {
                        field.formControl.patchValue($event.target.value.trim())
                      }
                    },
                    validators: {
                      validation: ['onlyText'],
                    }
                  },

                  // Field: Address
                  // {
                  //   key: 'permanentzipcode',
                  //   className: 'col-xs-12 col-md-6',
                  //   type: 'input',
                  //   defaultValue: (this.empObj?.permanentzipcode?.length > 0) ? this.empObj?.permanentzipcode : null,
                  //   templateOptions: {
                  //     label: 'ZIP/PIN CODE',
                  //     change: (field, $event) => {
                  //       field.formControl.patchValue($event.target.value.trim())
                  //     }
                  //   },
                  // },

                  {
                  key: 'permanentzipcode',
                    className: 'col-xs-12 col-md-6',
                    type: 'input',
                    defaultValue: (this.empObj?.permanentzipcode?.length > 0) ? this.empObj?.permanentzipcode : null,
                    templateOptions: {
                      label: 'ZIP/PIN CODE',
                      change: (field, $event) => {
                        const numericValue = $event.target.value.replace(/\D/g, ''); // Remove non-numeric characters
                        field.formControl.patchValue(numericValue);
                      },
                      attributes: {
                        onkeypress: "return event.charCode >= 48 && event.charCode <= 57", // Restrict non-numeric input
                      }
                    },
                    validators: {
                      validation: ['numericOnly'],
                    }
                  },
                ],
              },
            ],
          },

          {
            templateOptions: { label: 'Employee Details *' },
            fieldGroup: [
              {
                fieldGroupClassName: 'row mx-0 my-2',
                fieldGroup: [

                  // Field: Employee Id
                  {
                    key: 'employeeid',
                    className: 'col-xs-12 col-md-6',
                    type: 'input',
                    defaultValue: (this.empObj?.employeeid?.length > 0) ? this.empObj?.employeeid : null,
                    templateOptions: {
                      label: 'Employee ID',
                      // xyxyx
                      required: this.EmployeeID,
                      change: (field, $event) => {
                        field.formControl.patchValue($event.target.value.trim())
                      }
                    },
                  },

                  {
                    key: 'designationName',
                    className: 'col-xs-12 col-md-6',
                    type: 'input',
                    defaultValue: (this.empObj?.designationName?.length > 0) ? this.empObj?.designationName : null,
                    templateOptions: {
                      label: 'Designation Name',
                      disabled: true, // Disable input as it is non-editable
                    },
                  },
                  {
                    key: 'designationId',
                    className: 'col-xs-12 col-md-6',
                    type: 'select',
                    defaultValue: (this.empObj?.designation) ? Number(this.empObj?.designation) : null,
                    templateOptions: {
                      label: 'Designation',
                      options: this.getDesignations(), // Dropdown options fetched from backend
                      valueProp: 'designationid', // Pass designation ID to the server
                      labelProp: 'name', // Display designation name in the dropdown
                      required: true,
                    },
                    hooks: {
                      onInit: (field) => {
                        if (this.empObj?.designationName) {
                          field.formControl.disable(); // Disable dropdown if necessary
                        }
                      },
                    },
                    hideExpression: true, // Hide this field from the user
                  },
                ]


              },

              {
                fieldGroupClassName: 'row mx-0 my-2',
                fieldGroup: [
                ],
              },

              {
                fieldGroupClassName: 'row mx-0 my-2',
                fieldGroup: [
                  {
                    key: 'approvalauthoritystatus',
                    type: 'radio',
                    className: 'col-xs-12 col-md-6',
                    defaultValue: this.empObj?.approvalauthoritystatus === "true" ? true : false,
                    templateOptions: {
                      label: 'Approval Authority',
                      placeholder: 'select',
                      // xyxyx
                      required: this.ApproveAuthority,
                      options: [
                        { value: true, label: 'YES' },
                        { value: false, label: 'NO' },

                      ],
                    }
                  },
                  // Field: Department
                  {
                    key: 'departmentid',
                    className: 'col-xs-12 col-md-6',
                    type: 'select',
                    defaultValue: (this.empObj?.departmentid) ? Number(this.empObj?.departmentid) : null,
                    templateOptions: {
                      label: 'Department',
                      options: this.getDepartments(),
                      valueProp: 'departmentId',
                      labelProp: 'name',
                      // xyxyx
                      required: this.Department,
                      change: (field, $event) => {
                        // console.log("Data XX", field, $event)
                        // console.log("Data XX - YY ", field.form.controls.departmentid.value);
                        this.curentMainDepId = field.form.controls.departmentid.value;
                        this.selectFromDrop = true;
                        // console.log("Data XX - YY - ZZ ", this.curentMainDepId);
                        this.filterSecondaryDepartment();
                      }
                    },
                  },
                ],
              },

              {
                fieldGroupClassName: 'row mx-0 my-2',
                fieldGroup: [
                  // Field: Unit
                  {
                    key: 'branchid',
                    className: 'col-xs-12 col-md-6',
                    type: 'select',
                    defaultValue: (this.empObj?.branchid) ? Number(this.empObj?.branchid) : null,
                    templateOptions: {
                      label: 'Unit/Branch',
                      options: this.getUnit(),
                      valueProp: 'branchId',
                      // xyxyx
                      required: this.UnitBranch,
                      labelProp: 'name',
                      // disabled: (this.empObj?.branchid) ? true : false
                    },
                  },

                  // Field: Blood Group
                  {
                    key: 'bloodgroup',
                    className: 'col-xs-12 col-md-6',
                    type: 'select',
                    defaultValue: (this.empObj?.bloodgroup?.length > 0) ? this.empObj?.bloodgroup : null,
                    templateOptions: {
                      label: 'Blood Group',
                      options: [
                        { label: 'O+ve', value: 'O+ve' },
                        { label: 'O-ve', value: 'O-ve' },
                        { label: 'A+ve', value: 'A+ve' },
                        { label: 'A-ve', value: 'A-ve' },
                        { label: 'B+ve', value: 'B+ve' },
                        { label: 'B-ve', value: 'B-ve' },
                        { label: 'AB+ve', value: 'AB+ve' },
                        { label: 'AB-ve', value: 'AB-ve' },
                      ],
                    },
                  },
                ],
              },

              {
                fieldGroupClassName: 'row mx-0 my-2',
                fieldGroup: [
                  // Field: Bank Name
                  {
                    key: 'bankname',
                    className: 'col-xs-12 col-md-6',
                    type: 'input',
                    defaultValue: (this.empObj?.bankname?.length > 0) ? this.empObj?.bankname : null,
                    templateOptions: {
                      label: 'Bank Name',
                      change: (field, $event) => {
                        field.formControl.patchValue($event.target.value.trim())
                      }
                    },
                  },

                  // Field: Bank Account Number
                  {
                    key: 'bankaccountnumber',
                    className: 'col-xs-12 col-md-6',
                    type: 'input',
                    defaultValue: (this.empObj?.bankaccountnumber?.length > 0) ? this.empObj?.bankaccountnumber : null,
                    templateOptions: {
                      label: 'Bank Account Number',
                      change: (field, $event) => {
                        field.formControl.patchValue($event.target.value.trim())
                      }
                    },
                  },
                ],
              },

              {
                fieldGroupClassName: 'row mx-0 my-2',
                fieldGroup: [
                  // Field: IFSC Code
                  {
                    key: 'ifsccode',
                    className: 'col-xs-12 col-md-6',
                    type: 'input',
                    defaultValue: (this.empObj?.ifsccode?.length > 0) ? this.empObj?.ifsccode : null,
                    templateOptions: {
                      label: 'IFSC Code',
                      change: (field, $event) => {
                        field.formControl.patchValue($event.target.value.trim())
                      }
                    },
                  },

                  // Field: Branch (Bank City)
                  {
                    key: 'bankbranch',
                    className: 'col-xs-12 col-md-6',
                    type: 'input',
                    defaultValue: (this.empObj?.bankbranch?.length > 0) ? this.empObj?.bankbranch : null,
                    templateOptions: {
                      label: 'Bank Branch',
                      change: (field, $event) => {
                        field.formControl.patchValue($event.target.value.trim())
                      }
                    },
                  },
                ],
              },

              {
                fieldGroupClassName: 'row mx-0 my-2',
                fieldGroup: [
                  {
                    key: 'bankaccount',
                    className: 'col-xs-12 col-md-6',
                    type: 'select',
                    templateOptions: {
                      label: 'Bank Account type',
                      options: [
                        { label: 'Savings Account', value: 'Savings Account' },
                        { label: 'Current Account', value: 'Current Account' },
                        { label: 'Salary Account', value: 'Salary Account' },
                        { label: 'Other', value: 'Other' },
                      ],
                    },
                  },

                  // Field: Joining Date
                  {
                    key: 'joiningdate',
                    className: 'col-xs-12 col-md-6',
                    type: 'input',
                    defaultValue: (this.empObj?.joiningdate?.length > 0) ? this.datepipe.transform(this.empObj?.joiningdate, 'yyyy-MM-dd') : null,

                    templateOptions: {
                      label: 'Joining Date',
                      type: 'date',
                      // xyxyx
                      required: this.DOJ,
                    },
                    validators: {
                      validation: ['date', { name: 'validatejoiningdate' }],

                    },
                  },
                ],
              },

              {
                fieldGroupClassName: 'row mx-0 my-2',
                fieldGroup: [
                  // Field: Confirmation Date
                  {
                    key: 'confirmationdate',
                    className: 'col-xs-12 col-md-6',
                    type: 'input',
                    defaultValue: (this.empObj?.confirmationdate?.length > 0) ? this.datepipe.transform(this.empObj?.confirmationdate, 'yyyy-MM-dd') : null,
                    templateOptions: {
                      label: 'Confirmation Date',
                      type: 'date',
                    },
                    validators: {
                      validation: ['date'],
                    }
                  },

                  // Field: Resignation Submisssion Date
                  {
                    key: 'resignsubmitdate',
                    className: 'col-xs-12 col-md-6',
                    type: 'input',
                    defaultValue: (this.empObj?.resignsubmitdate?.length > 0) ? this.datepipe.transform(this.empObj?.resignsubmitdate, 'yyyy-MM-dd') : null,
                    templateOptions: {
                      label: 'Resignation Submission Date',
                      type: 'date',
                    },
                    validators: {
                      validation: ['date'],
                    }
                  },
                ],
              },

              {
                fieldGroupClassName: 'row mx-0 my-2',
                fieldGroup: [
                  // Field: Notice Period in Days
                  {
                    key: 'noticeperiod',
                    className: 'col-xs-12 col-md-6',
                    type: 'input',
                    defaultValue: (this.empObj?.noticeperiod?.length > 0) ? this.empObj?.noticeperiod : null,
                    templateOptions: {
                      label: 'Notice Period(in Days)',
                      type: 'number',
                    },
                  },

                  // Field: Leaving Date
                  {
                    key: 'leavingdate',
                    className: 'col-xs-12 col-md-6',
                    type: 'input',
                    defaultValue: (this.empObj?.leavingdate?.length > 0) ? this.datepipe.transform(this.empObj?.leavingdate, 'yyyy-MM-dd') : null,
                    templateOptions: {
                      label: 'Leaving Date',
                      type: 'date',
                    },
                    validators: {
                      validation: ['date'],
                    }
                  },

                  {
                    key: 'attendancesetting',
                    className: 'col-xs-12 col-md-6',
                    type: 'select',
                    defaultValue: (this.empObj?.attendancesetting?.length > 0) ? this.empObj?.attendancesetting : null,
                    templateOptions: {
                      label: 'Attendance Setting',
                      options: [
                        { label: 'First In  Last out', value: 'first_in_lastout' },
                        { label: 'Effective Time', value: 'effective_time' },
                      ],
                    },
                  },

                  // {
                  //   key: 'employetype',
                  //   className: 'col-xs-12 col-md-6',
                  //   type: 'select',
                  //   defaultValue: (this.empObj?.employetype?.length > 0) ? this.empObj?.employetype : null,
                  //   templateOptions: {
                  //     label: 'Employee Type',
                  //     options: [
                  //       { label: 'Worker', value: 'W' },
                  //       { label: 'Staff', value: 'S' },
                  //     ],
                  //   },
                  // },

                  {
                    key: 'employetype',
                    className: 'col-xs-12 col-md-6',
                    type: 'select',
                    defaultValue: (this.empObj?.employetype?.length > 0) ? this.empObj?.employetype : null,
                    templateOptions: {
                      label: 'Employee Type',
                      options:this.getEmployeeType(),
                      valueProp: 'employetypecode',
                      labelProp: 'employeeTypeName'
                    },
                  },

                  {
                    key: 'default_present',
                    type: 'checkbox',
                    className: 'col-xs-12 col-md-12',
                    defaultValue: this.empObj?.default_present === "true" ? true : false,
                    //defaultValue: (this.empObj?.default_present == "false") ? false : true,
                    templateOptions:
                    {
                      label: 'Default Present (Log attendance automatically based on shift timings)',
                    },
                  },

                  {
                    key: 'capture_attendance',
                    type: 'checkbox',
                    className: 'col-xs-12 col-md-12',
                    defaultValue: this.empObj?.capture_attendance === "true" ? true : false,
                    //defaultValue: (this.empObj?.capture_attendance == "false") ? false : true,
                    templateOptions:
                    {
                      label: 'Prompt Selfie during Clock In  Clock Out',
                    },
                  },



                ],
              },
            ],
          },
          {
          templateOptions: { label: 'Other Details' },
          fieldGroup: [
           {
             fieldGroupClassName: 'row mx-0 my-2',
             fieldGroup: [
               {
                 key: 'maritalStatus',
                 className: 'col-xs-12 col-md-6',
                 type: 'select',
                 defaultValue: (this.empObj?.maritalStatus?.length > 0) ? this.empObj?.maritalStatus : null,
                 templateOptions: {
                   label: 'Marital Status',
                   options: [
                     { value: 'single', label: 'Single' },
                     { value: 'married', label: 'Married' },
                     { value: 'divorced', label: 'Divorced' },
                     { value: 'widowed', label: 'Widowed' }
                   ]
                 },
               },
               {
                 key: 'caste',
                 className: 'col-xs-12 col-md-6',
                 type: 'input',
                 defaultValue: (this.empObj?.caste?.length > 0) ? this.empObj?.caste : null,
                 templateOptions: {
                   label: 'Caste',
                 },
               },
               {
                 key: 'castecategory',
                 className: 'col-xs-12 col-md-6',
                 type: 'select',
                 defaultValue: (this.empObj?.castecategory?.length > 0) ? this.empObj?.castecategory : null,
                 templateOptions: {
                   label: 'Caste Category',
                   options: [
                     { value: 'general', label: 'General' },
                     { value: 'sc', label: 'Scheduled Caste (SC)' },
                     { value: 'st', label: 'Scheduled Tribe (ST)' },
                     { value: 'obc', label: 'Other Backward Class (OBC)' },
                     { value: 'unreserved', label: 'Unreserved' }
                   ]
                 },
               },
             ],
           },
           {
             fieldGroupClassName: 'row mx-0 my-2',
             fieldGroup: [
               {
                 key: 'aadharno',
                 className: 'col-xs-12 col-md-6',
                 type: 'input',
                 defaultValue: (this.empObj?.aadharno?.length > 0) ? this.empObj?.aadharno : null,
                 templateOptions: {
                   label: 'Aadhar Number',
                 },
                 validation: {
                  messages: {
                    // required: 'PF Number is required',
                    pattern: 'Please enter only numeric characters'
                  }
                },
                validators: {
                  validation: [
                    Validators.pattern('^[0-9]*$') // Regex for numeric validation
                  ]
                },
               },
               {
                 key: 'pannumber',
                 className: 'col-xs-12 col-md-6',
                 type: 'input',
                 defaultValue: (this.empObj?.pannumber?.length > 0) ? this.empObj?.pannumber : null,
                 templateOptions: {
                   label: 'PAN Number',
                 },
                 validation: {
                  messages: {
                    // required: 'PAN Number is required',
                    pattern: 'PAN Number must be alphanumeric'
                  }
                },
                validators: {
                  validation: [
                    Validators.pattern('^[A-Za-z0-9]*$') // Regex for alphanumeric validation
                  ]
                },
               },
               {
                 key: 'pfno',
                 className: 'col-xs-12 col-md-6',
                 type: 'input',
                 defaultValue: (this.empObj?.pfno?.length > 0) ? this.empObj?.pfno : null,
                 templateOptions: {
                   label: 'PF Number',
                 },validation: {
                  messages: {
                    // required: 'PAN Number is required',
                    pattern: 'PF Number must be alphanumeric'
                  }
                },
                validators: {
                  validation: [
                    Validators.pattern('^[A-Za-z0-9]*$') // Regex for alphanumeric validation
                  ]
                },
               },
               {
                 key: 'uannumber',
                 className: 'col-xs-12 col-md-6',
                 type: 'input',
                 defaultValue: (this.empObj?.uannumber?.length > 0) ? this.empObj?.uannumber : null,
                 templateOptions: {
                   label: 'UAN Number',
                 },
                 validation: {
                  messages: {
                    // required: 'PF Number is required',
                    pattern: 'Please enter only numeric characters'
                  }
                },
                validators: {
                  validation: [
                    Validators.pattern('^[0-9]*$') // Regex for numeric validation
                  ]
                },
               },
               {
                 key: 'esino',
                 className: 'col-xs-12 col-md-6',
                 type: 'input',
                 defaultValue: (this.empObj?.esino?.length > 0) ? this.empObj?.esino : null,
                 templateOptions: {
                   label: 'ESI Number',
                 },
                 validation: {
                  messages: {
                    // required: 'PAN Number is required',
                    pattern: 'ESI Number must be alphanumeric'
                  }
                },
                validators: {
                  validation: [
                    Validators.pattern('^[A-Za-z0-9]*$') // Regex for alphanumeric validation
                  ]
                },
               },
               {
                 key: 'pensionNumber',
                 className: 'col-xs-12 col-md-6',
                 type: 'input',
                 defaultValue: (this.empObj?.pensionNumber?.length > 0) ? this.empObj?.pensionNumber : null,
                 templateOptions: {
                   label: 'Pension Number',
                 },
                 validation: {
                  messages: {
                    // required: 'PF Number is required',
                    pattern: 'Please enter only numeric characters'
                  }
                },
                validators: {
                  validation: [
                    Validators.pattern('^[0-9]*$') // Regex for numeric validation
                  ]
                },
               },
               {
                key: 'pfjoiningdate',
                className: 'col-xs-12 col-md-6',
                type: 'input',
                defaultValue: (this.empObj?.pfjoiningdate?.length > 0) ? this.datepipe.transform(this.empObj?.pfjoiningdate, 'yyyy-MM-dd') : null,
                templateOptions: {
                  label: 'PF Joining Date',
                  type: 'date',
                },
                validators: {
                  validation: ['date'],
                }

              },
              //  {
              //    key: 'insuranceNumber',
              //    className: 'col-xs-12 col-md-6',
              //    type: 'input',
              //    defaultValue: (this.empObj?.insuranceNumber?.length > 0) ? this.empObj?.insuranceNumber : null,
              //    templateOptions: {
              //      label: 'Insurance Number',
              //    },
              //    validation: {
              //     messages: {
              //       // required: 'PAN Number is required',
              //       pattern: 'Insurance Number must be alphanumeric'
              //     }
              //   },
              //   validators: {
              //     validation: [
              //       Validators.pattern('^[A-Za-z0-9]*$') // Regex for alphanumeric validation
              //     ]
              //   },
              //  },
             ]
           },
           {
             fieldGroupClassName: 'row mx-0 my-2',
             fieldGroup: [
               // Empty third section as per original template
             ],
           },

          //  {
          //   fieldGroupClassName: 'row mx-0 my-2',
          //   fieldGroup: [
          //     {
          //       key: 'payscale',
          //       className: 'col-xs-12 col-md-6',
          //       type: 'input',
          //       defaultValue: (this.empObj?.payscale?.length > 0) 
          //       ? parseFloat(this.empObj.payscale).toFixed(2): null,
          //       templateOptions: {
          //         label: 'Payscale',
          //       },
          //       validation: {
          //         messages: {
          //           // required: 'PF Number is required',
          //           pattern: 'Please enter only numeric characters'
          //         }
          //       },
          //       validators: {
          //         validation: [
          //           Validators.pattern('^[0-9]*$') // Regex for numeric validation
          //         ]
          //       },
          //     },
          //     {
          //       key: 'efbfcode',
          //       className: 'col-xs-12 col-md-6',
          //       type: 'input',
          //       defaultValue: (this.empObj?.efbfcode?.length > 0) ? this.empObj?.efbfcode : null,
          //       templateOptions: {
          //         label: 'EFBF Code',
          //       },
          //     },
          //   ]
          // },
          // {
          //   fieldGroupClassName: 'row mx-0 my-2',
          //     fieldGroup: [
          //     {
          //       key: 'quarters',
          //       className: 'col-xs-12 col-md-4',
          //       type: 'checkbox',
          //       templateOptions: {
          //         label: 'Quarters',
          //       },
          //       defaultValue: (this.empObj?.quarters?.length > 0) ? this.empObj?.quarters : false,
          //     },
          //     {
          //       key: 'epfeligible',
          //       className: 'col-xs-12 col-md-4',
          //       type: 'checkbox',
          //       templateOptions: {
          //         label: 'EPF Eligible',
          //       },
          //       defaultValue: (this.empObj?.epfeligible?.length > 0) ? this.empObj?.epfeligible : false,
          //     },
          //     {
          //       key: 'esieligible',
          //       className: 'col-xs-12 col-md-4',
          //       type: 'checkbox',
          //       templateOptions: {
          //         label: 'ESI Eligible',
          //       },
          //       defaultValue: (this.empObj?.esieligible?.length > 0) ? this.empObj.esieligible : false,
          //     }
          //   ],
          // },
          ],
          },

        ],
      },
    ];

    console.log('%check inside getField field', 'color: green; background: yellow; font-size: 30px', this.fields);
  }

  ngAfterViewInit() {
    console.log(" ")
    console.log("called ngAfterViewInit ")
    // this.getMandatoryFields();

    this.getMandatoryFields() // when getmandatory fields return , run getFields ... 
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.getField();
        let queryParams;
        this.route.queryParams.subscribe(observer => {
          console.log(observer, "subscriber ")
          queryParams = observer;
          if (queryParams?.name) {
            this.enablebackBtn = true;
            if (queryParams?.isedit === "false") {
              queryParams.isedit = false;
              let types: NodeListOf<HTMLInputElement> = document.querySelectorAll('.leaveType');
              types.forEach(node => {
                node.disabled = true;
              })
            }
          }
        })

        this.empObj = queryParams;
        if (this.empObj?.name) {
          // console.log(this.empObj)
          const arobj = []
          let obj = {
            value: "",
            label: ""
          };
          obj.value = queryParams?.gender
          obj.label = (queryParams?.gender === "M") ? "Male" : "Female"

          arobj.push(obj)

          if (this.fields) {
            this.getFieldobj('gender', this.fields).defaultValue = obj.value;
          }

          let labeL;
          if (this.empObj?.approvalauthoritystatus === "true") {
            labeL = true;

          } else {
            labeL = false;
          }
          let defaultPresent;
          if (this.empObj?.default_present === "true") {
            defaultPresent = true;
          } else {
            defaultPresent = false;
          }
          if (this.fields) {
            this.getFieldobj('default_present', this.fields).defaultValue = defaultPresent;
          }

          let captureAttendance;
          if (this.empObj?.capture_attendance === "true") {
            captureAttendance = true;
          } else {
            captureAttendance = false;
          }
          if (this.fields) {
            this.getFieldobj('capture_attendance', this.fields).defaultValue = captureAttendance;
          }

          if (this.fields) {
            console.log('%check fields ', 'color: green; background: yellow; font-size: 30px', this.fields);
            console.log('%check empobj ', 'color: green; background: yellow; font-size: 30px', this.empObj);
          }

          this.employeeMasterService.getEmployeeMaster(this.empObj?.employeeid, this.empObj?.name, null, 0, 7).subscribe(data => {
            // console.log(data)
            this.model.salaryadditionComp = data.employeeMasterData[0].salaryadditionComp;
            this.model.salarydeductionComp = data.employeeMasterData[0].salarydeductionComp;
            this.model.employeeattributevalueinfo = data.employeeMasterData[0].employeeattributevalueinfo;
            this.model.default_present = data.employeeMasterData[0].default_present;
            this.model.capture_attendance = data.employeeMasterData[0].capture_attendance;
            const employetypeCode = data.employeeMasterData[0].employetype;
            this.getEmptypename(employetypeCode);
            console.log('%check model ', 'color: green; background: yellow; font-size: 30px', this.model);

          })
          this.getSecondaryDepartments(this.empObj?.uniqueemployeeid)
          this.getSalaryMaster();
          this.getWeeklyHolidayScreen();
          this.getIndividualLeaveType(this.empObj?.uniqueemployeeid)
          this.getFamilyDetails(this.empObj?.uniqueemployeeid)
          //get uploaded photo of the employee
          if (this.empObj?.profilepath) {
            this.downloadImage(this.empObj.profilepath)
          }


          this.getField();
        }
      });

    // console.log(queryParams)

    // this.getFieldobj('email',this.fields).templateOptions.label = this.defaultmailid
    console.log(" ")
  }

  ngOnDestroy() {
    // Unsubscribe from all subscriptions to avoid memory leaks
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  get() {
    console.log("salary component ok");
    this.model.secdepartment = this.requestDepartmentObj;
  }

  readamount(e, item, bool) {
    this.amount = e.target.value;
    console.log(this.amount, bool, "amount and bool")
    if (this.amount >= 0 && bool) {
      item.salaryComponentAmount = Number(this.amount);
    } else if (!bool) {
      item.attributevalue = this.amount
    }
  }

  alertmessages(message: string, sign: string): void {
    let alertDiv = document.createElement('div');
    alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
    <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message} </div>`
    let alertcomp = document.querySelector('.alertcomp');
    alertcomp.append(alertDiv);
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    (<HTMLButtonElement>document.getElementById('btndisabled')).disabled = true;
    setTimeout(function () {
      alertcomp.removeChild(alertDiv);
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/employeemaster']);
      });


    }, 5000);
  }


  alertmessages1(message: string, sign: string): void {
    let alertDiv = document.createElement('div');
    alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
    <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message} </div>`
    let alertcomp = document.querySelector('.alertcomp1');
    alertcomp.append(alertDiv);
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    (<HTMLButtonElement>document.getElementById('btndisabled')).disabled = true;
    setTimeout(function () {
      alertcomp.removeChild(alertDiv);
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/employeemaster']);
      });


    }, 5000);
  }





  diff_years(dt2, dt1) {

    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= (60 * 60 * 24);
    return Math.abs(Math.round(diff / 365.25));

  }

  getProfilePhoto(event) {
    console.log(" ")
    console.log(" check this out ")
    console.log(" ")
    console.log(event);
    console.log(" ")
    if (!event.target.files[0] || event.target.files[0].length == 0) {
      // this.msg = 'You must select an image';
      return;
    }
    let mimeType: string = event.target.files[0].type;
    if (mimeType === "image/png" || mimeType === "image/jpeg") {

      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);


      reader.onload = (_event) => {
        console.log(reader)
        // this.msg = "";
        this.url = reader.result;
        console.log(this.url)
      }
      // console.log("inside profile function ")
      // console.log(event.target.files[0])
      this.imageToupload = event.target.files[0];
      // this.model.profilename =event.target.files[0]?.name
      // console.log(this.model)
      // console.log(this.imageToupload)
    } else {
      this.alertmessages(`${mimeType} Not Supported, please Upload Images of type JPEG or PNG `, "danger");
    }
  }

  downloadImage(filePath: any) {
    console.log(" ")
    console.log("downloadImage : ", filePath)
    console.log(" ")
    this.approvalinboxservice.downloadFile(filePath).subscribe(
      data => {
        // console.log(data)
        var downloadUrl = URL.createObjectURL(data);
        // console.log(downloadUrl)
        // console.log()
        var reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = (_event) => {
          // console.log(reader)
          // this.msg = "";
          this.url = reader.result;
          console.log(this.url)
        }
      },
      error => {
        console.log('Error occured in download file');
      }
    );


  }

  downloadImageHelper(file: File, empid: string) {
    return this.employeeMasterService.uploadImage(this.imageToupload, this.model.employeeid)
  }

  addSecdepartment(e, obj: any) {
    if (this.empObj?.isedit === "true") {
      obj.uniqueemployeeid = this.uniqueempid
      if (this.requestDepartmentObj.length === 0) {
        this.requestDepartmentObj.push(obj)
        // e.target.classList.add('bggray')
      } else {

        // console.log(this.requestDepartmentObj)
        let index!: number;
        for (let i = 0; i < this.requestDepartmentObj.length; i++) {
          if (Number(this.requestDepartmentObj[i].departmentId) === Number(obj.departmentId)) {
            // console.log(i)
            index = i;
            break;
          }
        }

        //  let index = this.requestDepartmentObj.findIndex((item,i)=>{
        //   if(Number(item.departmentId) === Number(obj.departmentId)){
        //     console.log(i)
        //     return i;
        //   }
        //  return Number(item.departmentId) === Number(obj.departmentId)
        // })

        console.log(index)
        if (index > -1) {
          this.requestDepartmentObj.splice(index, 1);
        } else {
          this.requestDepartmentObj.push(obj);
        }
      }
    } else {
      // console.log(e,obj);
      obj.uniqueemployeeid = this.uniqueempid
      if (this.requestDepartmentObj.length === 0) {
        this.requestDepartmentObj.push(obj)
        // e.target.classList.add('bggray')
      } else {
        if (this.requestDepartmentObj.includes(obj)) {
          this.removeItem(this.requestDepartmentObj, obj);
          // e.target.classList.remove('bggray')
        } else {

          this.requestDepartmentObj.push(obj);
          // e.target.classList.add('bggray')

        }
      }
    }
  }

  handleFileInput(event) {

    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.fileToUpload = file;
    }
  }

  set_emp_upload() {
    this.clearFileInput();
    this.isFileValid = true;
    this.fileToUpload = null;
  }

  clearFileInput() {
    const fileInput = document.getElementById('file') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = ''; // Clear the file input
    }
  }

  // OnClick of button Upload
  uploadFile() {

    console.log("File in Upload File", this.fileToUpload)
    console.log(this.model)

    this.model.profilepath = null;

    this.submitted = true;
    if (this.fileToUpload?.size == 0) {

      return;
    }

    const typeList = ['xls'];
    console.log("Step one check : fileArray", this.fileToUpload)
    if (typeList.includes(this.fileToUpload.name.split(".").pop())) {
      this.isFileValid = true;
      this.loading = !this.loading;
      this.uploadedDocDetails = [];
      console.log("The Name of Uploaded Files : I If loop", this.fileToUpload.name);

      this.employeeMasterService.uploadEmpExcelDocument(this.fileToUpload).subscribe(
        (data) => {

          // console.log("The Return response is", data.excellmessages);
          this.excelErrors = data.excellmessages;
          if (this.excelErrors && this.excelErrors.length > 0) {
            this.uploadError = true;
            const fileInput = document.getElementById('file') as HTMLInputElement;
            fileInput.value = '';
            this.alertmessagesWithLink("There Are errors in excel.", "danger");
          } else {
            // The array is empty or undefined
            console.log('No errors found.');
            this.uploadError = false;
            const fileInput = document.getElementById('file') as HTMLInputElement;
            fileInput.value = '';
            this.alertmessagesWithLink("File Uploaded Successfully.", "success");
          }

        },
        (error) => {
          console.log("The Error is ", error);
        });

    } else {
      this.isFileValid = false;
      // this._snackBar.open("Upload only '.xls' file!", 'Okay', {
      //   duration: 2000,
      // });
    }



  }

  alertmessagesWithLink(message: string, sign: string): void {
    let alertDiv = document.createElement('div');
    if (this.uploadError) {
      alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
      <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message} 
      <button id="viewErrorsBtn" class="btn btn-link" data-bs-toggle="modal" data-bs-target="#excelErrors">View Errors</button>
      </div>`
    } else {
      alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
    <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message} </div>`
    }

    let alertcomp = document.querySelector('.alertcompExcelUpload');
    alertcomp.append(alertDiv);
    // document.body.scrollTop = document.documentElement.scrollTop = 0;
    (<HTMLButtonElement>document.getElementById('btndisabled')).disabled = true;
    setTimeout(function () {
      alertcomp.removeChild(alertDiv);
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        // this.router.navigate(['/employeemaster']);
      });


    }, 5000);
  }

  alertmessagesforsalary(message: string, sign: string): void {
    let alertDiv = document.createElement('div');
    if (this.uploadError) {
      alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
      <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message} 
      <button id="viewErrorsBtn" class="btn btn-link" data-bs-toggle="modal" data-bs-target="#excelErrors">View Errors</button>
      </div>`
    } else {
      alertDiv.innerHTML = ` <div class="alert alert-${sign} mt-4  role="alert"><button  class="btn-close" data-bs-dismiss="alert" aria-hidden="true">×</button>
    <i class="fa fa-check-circle-o me-2" aria-hidden="true"></i>${message} </div>`
    }

    let alertcomp = document.querySelector('.alertcompSalaryDetail');
    alertcomp.append(alertDiv);
    // document.body.scrollTop = document.documentElement.scrollTop = 0;
    (<HTMLButtonElement>document.getElementById('btndisabled')).disabled = true;
    setTimeout(function () {
      alertcomp.removeChild(alertDiv);
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        // this.router.navigate(['/employeemaster']);
      });


    }, 5000);
  }

  employeeMasterCSVDownload() {
    var employeename = (<HTMLInputElement>document.getElementById('employeename')).value;
    // console.log(`Unique Emp Id: ${this.uniqueEmployeeId}, Year: ${year}, Month: ${month}`)
    this.employeeMasterService.downloadEmployeesCSVFile().subscribe(data => {
      // console.log(data)
      // this.uniqueemployeeid = null;
      var file = new Blob([data], { type: 'application/vnd.ms-excel' });
      var fileURL = URL.createObjectURL(file);
      var anchor = document.createElement("a");
      anchor.download = "EmployeeMasterDetails.xls";
      anchor.href = fileURL;
      anchor.click();
      //window.open(fileURL);
    },
      error => {
        console.log("Error Occured ", error);
        // console.log("Error Code ",error.status);
      })
  }


  uploadImage(): void {

    console.log("File in UploadImage", this.fileToUpload)

    if (this.files.length == 0) {
      this.fileslength = false;
      return;

    } else {
      this.fileslength = true;
    }

    //get image upload file obj;
    console.log("The Files Length is ", this.files.length);


    this.multipleFilePath = [];


    // this.docmentInfoService.uploadMultipleDocument(this.files).subscribe(
    //   data => {
    //     console.log("The Return response is", data);
    //     this.multipleFilePath = data.mutipleFilePaths;
    //   },
    //   error => {
    //     console.log("The Error is ", error);
    //   });
  }

  removeItem<T>(arr: Array<T>, value: T): Array<T> {
    const index = arr.indexOf(value);
    if (index > -1) {
      arr.splice(index, 1);
    }
    // arr.forEach((item:DepartmentInfo,index)=>{
    //   if(item.departmentId === ) 
    // })
    return arr;
  }

  clear() {
    this.requestDepartmentObj = [];
    let checkallele = document.querySelectorAll('.deCheckall');

    checkallele.forEach(item => {
      (<HTMLInputElement>item).checked = false
    })

    // checkallele
  }

  getdefaultemailid() {
    this.customerservie.getdefault_emailid().subscribe({
      next: (data) => {
        console.log(data)
        this.defaultmailid = data.default_email_id;
        // console.log(this.defaultmailid === "Employeeid")
        if (this.defaultmailid === "Employeeid" && this.getFieldobj('email', this.fields)) {
          this.getFieldobj('email', this.fields).templateOptions.label = 'Email'
        } else if (this.getFieldobj('email', this.fields)) {
          this.getFieldobj('email', this.fields).templateOptions.label = 'Username'
        }


      },
      error(error) {
        console.log(error);

      },
      complete() { }
    })
  }

  getLeaveType() {
    console.log("v1/getLeaveType   api is called here ")
    this.employeeMasterservice.getLeaveTypeNameExcludeOnDemand().subscribe(
      data => {
        this.LeaveTypeList = data;
        console.log("List of all the leave type available here  :", this.LeaveTypeList)
      }, error => {
        console.log(error);
      }
    );
  }

  getIndividualLeaveType(uid) {
    console.log(" check here 'getIndividualLeaveType'  : is  ", uid);
    if (this.empObj?.uniqueemployeeid === undefined) {
      this.uidChecker = true;
      this.employeeMasterService.getEmployeeLeaveType(uid).
        subscribe({
          next: (data) => {
            console.log("ovrride leave no of days  :", data);
            data.forEach(item => {
              item.noOfDays = 0;
            });
            this.LeaveTypeListValue = data;
            console.log("leaveType for uid : ", uid, " : and List :  ", this.LeaveTypeListValue)
          },
          error: (e) => {
            console.log(e)
          },
        }
        )
    } else {
      console.log("individul data   this.uidChecker ", this.uidChecker)
      this.employeeMasterService.getEmployeeLeaveType(uid).
        subscribe({
          next: (data) => {
            console.log("original no of days  ", data);
            this.LeaveTypeListValue = data;
            console.log("leaveType for uid : ", uid, " : and List :  ", this.LeaveTypeListValue)
          },
          error: (e) => {
            console.log(e)
          }
        })
    }
  }

  onInputChange(updatedValue: any, index: number) {
    console.log(" oninut change : ", updatedValue, "   index :", index);

    const enteredValue = updatedValue.target.value;
    this.LeaveTypeListValue[index]["noOfDays"] = enteredValue;
    console.log("After override :", this.LeaveTypeListValue[index])

  }

  updateWeeklyType() {
    // here update weel
    console.log("this.updateWeeklyType ");
    console.log("WeeklyOffList ", this.WeeklyOffList);

  }

  onInputChangeforWeeklyOff(updatedValue: any, index: number) {
    console.log(" oninut change : ", updatedValue, "   index :", index);

    const enteredValue = updatedValue.target.value;
    this.LeaveTypeListValue[index]["noOfDays"] = enteredValue;
    console.log("After override :", this.LeaveTypeListValue[index])

  }

  update_LeaveType() {
    console.log("update function ,to update leave Type : ");
    if (this.uidChecker === false) {
      console.log(`Update leave type value where uid :  ${this.empObj?.uniqueemployeeid} and values : `, this.LeaveTypeListValue)
      this.employeeMasterService.update_EmpLeaveMaster(this.LeaveTypeListValue, this.empObj?.uniqueemployeeid).subscribe(
        data => {

          this.LeaveTypeListValue = data;
          console.log("updated leave type ", this.LeaveTypeListValue)
        }, error => {
          console.log(error);
        }
      );
      this.getIndividualLeaveType(this.empObj?.uniqueemployeeid);
      console.log("this 2323232323 : ", this.LeaveTypeListValue)
      this.reloadthescreenwithupdatedData(this.empObj);
    }
    else {
      console.log(" true ", this.LeaveTypeListValue)
      console.log(" ")
    }
  }

  private mediaStream: MediaStream;

  initCamera() {
    navigator.mediaDevices.getUserMedia({ video: true })
      .then((stream) => {
        const video = document.getElementById('video') as HTMLVideoElement;
        video.srcObject = stream;
      })
      .catch((error) => {
        console.error('Error accessing camera:', error);
      });
  }

  capturePhoto() {
    const canvas = document.getElementById('canvas') as HTMLCanvasElement;
    const context = canvas.getContext('2d');
    const video = document.getElementById('video') as HTMLVideoElement;

    context.drawImage(video, 0, 0, canvas.width, canvas.height);

    // Convert the captured image on the canvas to a Blob
    canvas.toBlob((blob) => {
      // Now 'blob' contains the binary data of the captured image
      // You can send the blob to the server using an HTTP request
      // Example: Send the blob as a FormData with an HTTP request

      this.vidOff();
      const formData = new FormData();
      formData.append('image', blob, 'captured_image.png');

      // Example HTTP request using Angular's HttpClient
      // Make sure to inject the HttpClient module in your component
      // and import it from '@angular/common/http'
      // Adjust the URL to match your server endpoint
      // You may need to create a service to handle HTTP requests in a real application
      // For simplicity, this example uses the Angular HttpClient directly in the component

      fetch('/upload', {
        method: 'POST',
        body: formData
      })
        .then(response => response.json())
        .then(data => {
          console.log('Image uploaded successfully:', data);
        })
        .catch(error => {
          console.error('Error uploading image:', error);
        });
    }, 'image/png'); // Specify the desired image format (e.g., 'image/png' or 'image/jpeg')
  }

  vidOff() {
    const video = document.getElementById('video') as HTMLVideoElement;
    video.pause();
    video.srcObject = null;
  }

  deleteprofilepic(image: Faceregister) {
    const index = this.faceregisterdata.indexOf(image);
    if (index !== -1) {
      this.faceregisterdata.splice(index, 1);
    }
    console.log("Deleted image" + image)
  }

  viewprofilepic(filePath: any, Imagename: string) {
    console.log("iiiiiiiiiiiiiiii", filePath)
    console.log("Path for profile photo", filePath);
    this.url2 = undefined;
    console.log("iiiiiiiiiiiiiiii", Imagename)
    this.approvalinboxservice.viewFile(filePath, Imagename).subscribe(
      data => {

        var downloadUrl = URL.createObjectURL(data);
        // console.log(downloadUrl)
        // console.log()
        var reader = new FileReader();
        reader.readAsDataURL(data);

        reader.onload = (_event) => {
          // console.log(reader)
          // this.msg = "";
          this.url2 = reader.result;
          console.log(this.url2)
        }
      },
      error => {
        console.log('Error occured in download file');
      }
    );

  }

  saveImage() {

    if (this.faceregisterdata.length < 3) {
      this.alertmessages("Please select 3 images to register your image", "danger")
    }
    console.log("clicked seave profille pic", this.empObj?.uniqueemployeeid)
    console.log("check : ", this.faceregisterdata);
    console.log("check empid-- : ", this.empObj?.employeeid);
    console.log("check image1--: ", this.faceregisterdata[0].imageName);
    console.log("check image2--: ", this.faceregisterdata[1].imageName);
    console.log("check image3--: ", this.faceregisterdata[2].imageName);

    if (this.empObj?.employeeid == undefined) {
      this.alertmessages("Employee ID is not defined", "danger")
    }
    else {
      this.employeeMasterservice.savefacePath(this.faceregisterdata, this.empObj?.employeeid, this.faceregisterdata[0].imageName, this.faceregisterdata[1].imageName, this.faceregisterdata[2].imageName).subscribe(
        data => {
          console.log("data ", data)

        }, error => {

          this.alertmessages("Please try again later", "danger")
        }
      );
    }
  }

  uploadImageFace(): void {
    let id = 1;
    console.log("File in UploadImage1111", this.fileToUpload)
    this.cameramsg = "Please select camera and press q to capture picture"
    console.log("Camera message", this.cameramsg)
    console.log("Employeeid", this.empObj?.uniqueempid)
    console.log("Employeeid", this.model.employeeid)
    if (this.faceregisterdata.length >= 3) {
      this.alertmessages("You can only capture 3 photos for first time. If u want change photo please delete existing one an proceed", "danger");
    }
    this.employeeMasterService.uploadImageFace(this.model.employeeid).
      subscribe({
        next: (data) => {
          console.log("resonse 3", data.capture_and_save_image.imagepath)

          console.log("resonse 34", data.capture_and_save_image.imagename)
          this.imageName = data.capture_and_save_image.imagename;
          this.imagePath = data.capture_and_save_image.imagepath;
          let obj = new Faceregister(this.imageName, this.imagePath);
          if (this.faceregisterdata.length >= 3) {
            console.log("Image size" + this.faceregisterdata.length)
          } else {
            this.faceregisterdata.push(obj);

            console.log("Image size" + this.faceregisterdata.length)
          }
          console.log("check faceregisterdata", this.faceregisterdata)
          console.log("Image path details :  ", this.imagePath)
        },
        error: (e) => {
          console.log(e)
        }

      })
    this.multipleFilePath = [];

  }

  setMenuName(name: string) {
    this.menuService.setMenuName(name);
  }

  checkAccessForUser() {
    console.log("checkAccessForUser here")
    // console.log("Permisson List in Search Employee : ", this.permissionList)
    this.allowAdminToEdit = this.permissionList.includes('ng.employee.edit.master.data.permission')
    console.log("check allowAdminToEdit : ", this.allowAdminToEdit);


    // if (this.showBasedOnPermissionForLeaveType) {  // showBasedOnPermissionForLeaveType is true 
    //   this.getLeaveType();
    // }

  }

  getadditionSalaryMaster() {
    this.empService.getadditionSalaryMaster().subscribe(
      (data) => {
        console.log(data);
        this.additionsalaryMaster = data.salaryHeadMaster;
      },
      (error) => {
        console.error(error);
      }
    );
  }

  headChangeadd(info: any) {
    console.log("head change ");
    this.additiondetailMasterSection = this.additionsalaryMaster.find(head => head.salaryHeadId == info).salaryDetailMaster;
    console.log(this.additiondetailMasterSection);
  }

  addAdditionSal(headid, detailid, fromdate, todate, amount) {
    console.log("salaryHeadId", this.insertaddsalarydetails.salaryHeadId);
    console.log("salaryHeadName", this.insertaddsalarydetails.salaryHeadName);
    console.log("salarydetailId", this.insertaddsalarydetails.salarydetailId);
    console.log("salaryDetailName", this.insertaddsalarydetails.salaryDetailName);
    console.log("fromDate", this.insertaddsalarydetails.fromDate);
    console.log("toDate", this.insertaddsalarydetails.toDate);
    console.log("Amount", this.insertaddsalarydetails.salaryComponentAmount);
    console.log("additiondetailMasterSection", this.additiondetailMasterSection);

    let headName = this.additionsalaryMaster.find(ele => ele.salaryHeadId == headid).salaryHeadName;
    let detailName;
    if (this.additiondetailMasterSection.length > 1) {
      detailName = this.additiondetailMasterSection.find(ele => ele.salaryDetailId == detailid)?.salaryDetailName;
    } else {
      detailName = this.additiondetailMasterSection[0].salaryDetailName;
    }
    if (detailid == undefined || detailid == '') {
      detailid = this.additiondetailMasterSection[0].salaryDetailId;
    }

    // Validation: Check if fromDate is earlier than the previous entry's fromDate
    const existingDetails = this.insertaddsalarydetail.filter(
      ele => ele.salaryHeadId == headid && ele.salarydetailId == detailid
    );

    if (existingDetails.length > 0) {
      const latestDetail = existingDetails[existingDetails.length - 1];
      const latestFromDate = new Date(latestDetail.fromDate);
      const newFromDate = new Date(fromdate);

      if (newFromDate <= latestFromDate) {
        console.error("Validation Error: The new fromDate cannot be earlier than the previous fromDate.");
        this.alertmessagesforsalary("The new Salary fromDate cannot be earlier than the previous salary fromDate.", "danger");
        return; // Prevent insertion
      }
    }

    // New validation to ensure required fields are filled (except toDate)
    if (headid == '' || detailid == '' || fromdate == '') {
      console.error("Validation Error: All fields except toDate are required.");
      return; // Prevent insertion
    }

    const obj = {
      id: '',
      salaryHeadId: headid,
      salaryHeadName: headName,
      salarydetailId: detailid,
      salaryDetailName: detailName,
      fromDate: fromdate,
      toDate: todate,
      salaryComponentAmount: amount,
      userId: 'Add',
    };

    if (
      obj.salaryHeadId != '' &&
      obj.salarydetailId != '' &&
      obj.salaryDetailName != undefined &&
      obj.salaryHeadName != undefined &&
      obj.fromDate != undefined
    ) {
      this.insertaddsalarydetail.unshift(obj);
      this.insertaddsalarydetails.id = '';
      this.insertaddsalarydetails.salaryHeadId = '';
      this.insertaddsalarydetails.salaryHeadName = '';
      this.insertaddsalarydetails.salarydetailId = '';
      this.insertaddsalarydetails.salaryDetailName = '';
      this.insertaddsalarydetails.fromDate = '';
      this.insertaddsalarydetails.toDate = '';
      this.insertaddsalarydetails.salaryComponentAmount = '';
      this.additiondetailMasterSection = [];
      console.log("after push ", this.insertaddsalarydetail);
    }
  }

  getdeductionSalaryMaster() {
    this.empService.getdeductionSalaryMaster().subscribe(
      (data) => {
        console.log(data);
        this.deductionsalaryMaster = data.salaryHeadMaster;
      },
      (error) => {
        console.error(error);
      }
    );
  }

  headChangededuct(info: any) {
    console.log("head change ");
    this.deductiondetailMasterSection = this.deductionsalaryMaster.find(head => head.salaryHeadId == info).salaryDetailMaster;
    console.log(this.deductiondetailMasterSection);
  }

  addDeductSal(headid, detailid, fromdate, todate, amount) {
    console.log("salaryHeadId", this.insertdeductsalarydetails.salaryHeadId);
    console.log("salaryHeadName", this.insertdeductsalarydetails.salaryHeadName);
    console.log("salarydetailId", this.insertdeductsalarydetails.salarydetailId);
    console.log("salaryDetailName", this.insertdeductsalarydetails.salaryDetailName);
    console.log("fromDate", this.insertdeductsalarydetails.fromDate);
    console.log("toDate", this.insertdeductsalarydetails.toDate);
    console.log("Amount", this.insertdeductsalarydetails.salaryComponentAmount);
    let headName = this.deductionsalaryMaster.find(ele => ele.salaryHeadId == headid).salaryHeadName;
    let detailName;
    if (this.deductiondetailMasterSection.length > 1) {
      detailName = this.deductiondetailMasterSection.find(ele => ele.salaryDetailId == detailid).salaryDetailName;
    } else {
      detailName = this.deductiondetailMasterSection[0].salaryDetailName;
    }
    if (detailid == undefined || detailid == '') {
      detailid = this.deductiondetailMasterSection[0].salaryDetailId;
    }
    // Validation: Check if fromDate is earlier than the previous entry's fromDate
    const existingDetails = this.insertdeductsalarydetail.filter(
      ele => ele.salaryHeadId == headid && ele.salarydetailId == detailid
    );

    if (existingDetails.length > 0) {
      const latestDetail = existingDetails[existingDetails.length - 1];
      const latestFromDate = new Date(latestDetail.fromDate);
      const newFromDate = new Date(fromdate);

      if (newFromDate <= latestFromDate) {
        console.error("Validation Error: The new fromDate cannot be earlier than the previous fromDate.");
        alert("The new fromDate cannot be earlier than the previous fromDate.");
        this.alertmessagesforsalary("The new Salary fromDate cannot be earlier than the previous salary fromDate.", "danger");
        return; // Prevent insertion
      }
    }
    if (headid == '' || detailid == '' || fromdate == '') {
      console.error("Validation Error: All fields except toDate are required.");
      return; // Prevent insertion
    }

    const obj = {
      salaryHeadId: headid,
      salaryHeadName: headName,
      salarydetailId: detailid,
      salaryDetailName: detailName,
      fromDate: fromdate,
      toDate: todate,
      salaryComponentAmount: amount,
      userId: 'Add',
    }

    if (obj.salaryHeadId != '' && obj.salarydetailId != '' && obj.salaryDetailName != undefined && obj.salaryHeadName != undefined
      && obj.fromDate != undefined
    ) {
      this.insertdeductsalarydetail.unshift(obj);
      this.insertdeductsalarydetails.id = '';
      this.insertdeductsalarydetails.salaryHeadId = '';
      this.insertdeductsalarydetails.salaryHeadName = '';
      this.insertdeductsalarydetails.salarydetailId = '';
      this.insertdeductsalarydetails.salaryDetailName = '';
      this.insertdeductsalarydetails.fromDate = '';
      this.insertdeductsalarydetails.toDate = '';
      this.insertdeductsalarydetails.salaryComponentAmount = '';
      this.deductiondetailMasterSection = [];
      console.log("after push ", this.insertdeductsalarydetail);
    }

  }

  deleteDeductSal(index) {
    console.log(" slice obj from ruleComp ", index);
    if (index !== null && index !== undefined) {

      // this.insertPayCalRules.ruleComp.splice(index, 1);

      console.log("this indez ", this.insertdeductsalarydetail[index]);
      this.insertdeductsalarydetail[index].userId = 'Del';
      console.log("List existing ", this.insertdeductsalarydetail);

    } else {
      console.error("Select Object to Delete!");
    }
  }

  deleteAdditiobSal(index) {
    console.log(" slice obj from ruleComp ", index);
    if (index !== null && index !== undefined) {

      // this.insertPayCalRules.ruleComp.splice(index, 1);

      console.log("this indez ", this.insertaddsalarydetail[index]);
      this.insertaddsalarydetail[index].userId = 'Del';
      console.log("List existing ", this.insertaddsalarydetail);

    } else {
      console.error("Select Object to Delete!");
    }
  }

  searchSalaryDetails() {
    this.employeeMasterService.getSalaryDetails(this.currentDate, this.empObj?.uniqueemployeeid)
      .subscribe(
        data => {
          if (data && data.salaryadditionComp && data.salarydeductionComp) {
            // Ensure salaryData is initialized as an array before using concat
            this.insertaddsalarydetail = data.salaryadditionComp;
            this.insertdeductsalarydetail = data.salarydeductionComp;
            console.error("Add SALARY Data:", this.insertaddsalarydetail);
            console.error("Deduct SALARY Data:", this.insertdeductsalarydetail);
          }
        },
        error => {
          console.error("Error Occurred:", error);
          // Reset both arrays in case of error
          this.insertaddsalarydetail = [];
          this.insertdeductsalarydetail = [];
        }
      );
  }

  getEmployeeType() {
    if (this.empObj?.uniqueemployeeid === undefined) {
      this.tempUid = this.uniqueempid;
    } else {
      this.tempUid = this.empObj?.uniqueemployeeid;
    }
    return this.employeeMasterService.getSelectOptions(this.tempUid).
      pipe(
        map(data => {
          console.log("***************EMPLOYEE_TYPE**********",data)
          return data.employeeType;
        })
      );
  }

  getEmptypename(type:any) {
    this.employeeMasterService.getemployeeTypeName(type).subscribe(
      data => {
        console.log(" Employee Type name :", data.emptypename);
        this.model.employetype = type; 
      }, error => {
        console.log(error);
      }
    );
  }

//employee family details screen 
addFamilyMember() {

  this.add_view_toggle = "addFamilyMember"; // Ensure UI updates
  // Get the emp ID
  this.model.uniqueemployeeid = this.empObj?.uniqueemployeeid;
  console.log("this.model.uniqueemployeeid",this.tempUid);

  if (this.empObj?.uniqueemployeeid === undefined) {
    this.tempUid = this.uniqueempid;
    console.log(this.tempUid);
  } else {
    this.tempUid = this.empObj?.uniqueemployeeid;
    console.log(this.tempUid);
  }

  // Create a new empty member

  const newMember = new EmployeeFamilyDetails({});

 // Assign the entered name only if it's the first accordion
  if (this.familyMembers?.length === 0) {
    newMember.fullname = this.insertFamilyDetailsData.fullname; 
    console.log("this.insertFamilyDetailsData.fullname;",this.insertFamilyDetailsData.fullname);
    console.log("newMember.fullname",newMember.fullname);
    
     // Assign entered name to first accordion
  }

  if (this.familyMembers?.length != 0) {
    newMember.fullname = '';

  }
  

  console.log("in add function ", this.insertFamilyDetailsData);
  console.log("in add function nomination is", this.insertFamilyDetailsData.nomination_percentage);
  this.familyMembers.push(newMember); // Add the new member to the list
  console.log("nominee percentage in detail",newMember.nomination_percentage)
  // Clear input field for the next entry
  newMember.fullname = '';

  console.log("Family Members:", this.familyMembers);

  // Wait for the DOM to update, then collapse all and expand only the newly added one
  setTimeout(() => {
    this.closeAllAccordions(); // Collapse all previous accordions

    const newIndex = this.familyMembers.length - 1;
    const newAccordion = document.getElementById(`collapse${newIndex}`);

    if (newAccordion) {
      newAccordion.classList.add('show'); // Expand only the newly added accordion
    }
  }, 100);

  this.getFamilyDetails(this.tempUid);
  //this.clearDetails();
  
}


closeAllAccordions() {
  const accordions = document.querySelectorAll('.panel-collapse');
  accordions.forEach((accordion) => {
    accordion.classList.remove('show'); // Ensure all accordions are closed
  });
}


clearDetails() {
  console.log("Clearing Family Details for accordion index:");

  this.insertFamilyDetailsData = {
      id: null,
      fullname: '',
      emp_relation: '',
      dob: null,
      gender: '',
      aadhaar_number: '',
      pan_number: '',
      mobile_number: '',
      address: '',
      dependent: false,
      bank_name: '',
      ifsc_code: '',
      account_no: '',
      account_type: '',
      nomination_percentage: null,
      nominee: false
  };
 // Check if form is available before resetting
 console.log("this.familyDetailForm",this.familyDetailForm);
 if (this.familyDetailForm) {
  this.familyDetailForm.resetForm(); //  Using resetForm()
  this.insertFamilyDetailsData = {};
  console.log("this.familyDetailForm",this.familyDetailForm);
  
} else {
  console.warn("familyDetailForm is not initialized yet.");
}

  console.log("Cleared Family Details:", this.insertFamilyDetailsData);
}


viewFamilyDetails() {
  //this.getFamilyDetails(this.tempUid);
  this.getFamilyDetailsScreen();
  if (this.empObj?.uniqueemployeeid === undefined) {
    this.uidChecker = true;
    console.log("family detail get method  , when seleced employee doesnt have uid ");
    this.getFamilyDetails(this.uniqueempid);
  } else {
    this.uidChecker = false;
    console.log("family detail get method  , when seleced employee does have uid ");
    this.getFamilyDetails(this.empObj?.uniqueemployeeid);
  }
  this.add_view_toggle = 'viewFamilyDetails';
  console.log("entered to view function ");
  this.addOrUpdate = true;
  this.clearDetails();
  
  
}

// Utility function to format date (DD-MM-YYYY or YYYY-MM-DD based on need)
formatDate(dateString: string): string {
  const dateParts: string[] = dateString.split("-");
  const formattedDate: string = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
  return formattedDate;
}

  
onRelationshipChange(value: string) {
  if (!value) {
    this.insertFamilyDetailsData.emp_relation = '';
  }
}


updateTotalNominationPercentage(newPercentage: number, editedMember?: any): boolean {
  console.log("Updating total nomination percentage...", newPercentage, editedMember);

  if (!this.familyMembers) {
      this.familyMembers = [];
  }

  let currentTotal = this.familyMembers.reduce((sum, member) => {
      if (editedMember && member.id === editedMember.id) {
          return sum; // Exclude the old value of the edited member
      }
      return sum + (member.nominee ? (member.nomination_percentage || 0) : 0);
  }, 0);

  let newTotal = currentTotal + newPercentage;

  if (newTotal > 100) {
      this.nominationError = "Total nomination percentage cannot exceed 100%.";
      console.log(" Nomination exceeded 100%. Stopping operation.");
      setTimeout(() => {
        this.nominationError = "";
        console.log(" Nomination error cleared after 5 seconds.");
    }, 5000); // 5000ms = 5 seconds
      return false; // Stop further execution
  }

  this.nominationError = "";

  if (editedMember) {
      editedMember.nomination_percentage = newPercentage;
  } else {
      this.insertFamilyDetailsData.nomination_percentage = newPercentage;
  }

  this.totalNominationPercentage = this.familyMembers.reduce((sum, member) => {
      return sum + (member.nominee ? (member.nomination_percentage || 0) : 0);
  }, 0);

  console.log(" Updated Total Nomination Percentage:", this.totalNominationPercentage);
  return true; // Proceed with submission or update
}


submitFamilyDetails() {
  this.model.uniqueemployeeid = this.empObj?.uniqueemployeeid;
  console.log("this.model.uniqueemployeeid",this.model.uniqueemployeeid);
  console.log("Family Details:", this.familyMembers);
  console.log("Inserted Family Details:", this.insertFamilyDetailsData);
  console.log("Entered Nomination Percentage:", this.insertFamilyDetailsData.nomination_percentage);

  // Check if insertFamilyDetailsData is empty
  if (!this.insertFamilyDetailsData || Object.keys(this.insertFamilyDetailsData)?.length === 0) {
      console.log("No family members to submit");
      
  }

  // Check required fields
  let missingFields = [];

  if (!this.insertFamilyDetailsData.fullname?.trim()) missingFields.push("Full Name");
  if (!this.insertFamilyDetailsData.dob) missingFields.push("Date of Birth");
  if (!this.insertFamilyDetailsData.emp_relation?.trim()) missingFields.push("Relationship");
  if (!this.insertFamilyDetailsData.mobile_number?.trim()) missingFields.push("Mobile Number");
  if (!this.insertFamilyDetailsData.gender?.trim()) missingFields.push("Gender");
  if (!this.insertFamilyDetailsData.address?.trim()) missingFields.push("Address");

  // If nominee is true, check additional required fields
  if (this.insertFamilyDetailsData.nominee) {
      if (!this.insertFamilyDetailsData.bank_name?.trim()) missingFields.push("Bank Name");
      if (!this.insertFamilyDetailsData.ifsc_code?.trim()) missingFields.push("IFSC Code");
      if (!this.insertFamilyDetailsData.account_no?.trim()) missingFields.push("Account Number");
      if (!this.insertFamilyDetailsData.account_type?.trim()) missingFields.push("Account Type");
      

  }

  // If there are missing fields, show error message
  if (missingFields.length > 0) {
      this.alertmessages1(`Please fill in the required fields`, "danger");
      return;
  }

//  PAN Card Validation
const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
if (this.insertFamilyDetailsData.pan_number && !panRegex.test(this.insertFamilyDetailsData.pan_number)) {
    this.alertmessages1("Invalid PAN Card Number (Format: ABCDE1234F)","danger");
    return;
}

//  IFSC Code Validation
const ifscRegex = /^[A-Z]{4}0[A-Z0-9]{6}$/;
if (this.insertFamilyDetailsData.ifsc_code && !ifscRegex.test(this.insertFamilyDetailsData.ifsc_code)) {
 this.alertmessages1("Invalid IFSC Code (Format: SBIN0001234)","danger");
 return;
}

//  Aadhaar Number Validation
const aadhaarRegex = /^[2-9]{1}[0-9]{11}$/;
if (this.insertFamilyDetailsData.aadhaar_number && !aadhaarRegex.test(this.insertFamilyDetailsData.aadhaar_number)) {
 this.alertmessages1("Invalid Aadhaar Number (Must be 12 digits, should not start with 0 or 1)","danger");
return; 
}

//accont no validation
const accountNumberRegex = /^[0-9]{9,18}$/;
if (this.insertFamilyDetailsData.account_no && !accountNumberRegex.test(this.insertFamilyDetailsData.account_no)) {
  this.alertmessages1("Invalid Account Number (Must be 9 to 18 digits)", "danger");
  return;
}

console.log("Entered Nomination Percentage:", this.insertFamilyDetailsData.nomination_percentage);
console.log("percentage to update", this.insertFamilyDetailsData.nomination_percentage);
console.log("Checking total nomination percentage before submission...");
if (!this.updateTotalNominationPercentage(this.insertFamilyDetailsData.nomination_percentage, this.insertFamilyDetailsData)) {
    this.alertmessages1("Total nomination percentage cannot exceed 100%. Please correct the values.", "danger");
    return; // Stop submission
}

// Proceed with submission if all required fields are present
debugger;  
console.log("Submitting Family Details:", this.insertFamilyDetailsData);
  console.log("Payload being sent:", this.insertFamilyDetailsData);

  this.employeeMasterService.saveEmpFamilyDetails(this.tempUid,this.insertFamilyDetailsData).subscribe(
      (data) => {
          console.log("Response:", data);

          if (data?.successMessages?.length > 0 && data.successMessages[0].key) {
              console.log("Family details saved successfully!", data);
              this.alertmessages1(data.successMessages[0].key, "success");
              this.clearDetails();
          } else if (data?.errorMessages?.length > 0 && data.errorMessages[0].key) {
              console.error("Error saving family details:", data.errorMessages);
              this.alertmessages1(data.errorMessages[0].key, "danger");
          } else {
              console.error("Unexpected response from the server:", data);
              this.alertmessages1("An unexpected error occurred. Please try again.", "danger");
          }

          // Refresh family details view
          this.viewFamilyDetails();
      },
      (error) => {
          console.error("Error saving family details:", error);
          this.alertmessages1("A network error occurred. Please try again later.", "danger");
          
      }
  );
}


updateFamilyMembers() {

  console.log("Updating family members...");
  console.log("this.insertFamilyDetailsData:", this.insertFamilyDetailsData);
  console.log("this.insertFamilyDetailsData:", this.insertFamilyDetailsData.nomination_percentage);
  console.log("this.insertFamilyDetailsData:", this.insertFamilyDetailsData.ifsc_code);
  // Ensure the update button appears
  this.addOrUpdate = false;
  this.add_view_toggle = 'addFamilyMember';

 // Ensure that insertFamilyDetailsData is valid before updating
 if (!this.insertFamilyDetailsData || !this.insertFamilyDetailsData.id) {
  console.warn("Update failed: No valid family member data to update.");
  this.alertmessages1("No valid family member selected for update", "warning");
  return;
}

// Required field validation
if (
  !this.insertFamilyDetailsData.fullname?.trim() ||
  !this.insertFamilyDetailsData.dob ||
  !this.insertFamilyDetailsData.emp_relation?.trim() ||
  !this.insertFamilyDetailsData.mobile_number?.trim() ||
  !this.insertFamilyDetailsData.gender?.trim() ||
  !this.insertFamilyDetailsData.address?.trim()
) {
  this.alertmessages1("Please fill in all required fields", "danger");
  return;
}

// Additional validation if the nominee is true
if (
  this.insertFamilyDetailsData.nominee && (
    !this.insertFamilyDetailsData.bank_name?.trim() ||
    !this.insertFamilyDetailsData.ifsc_code?.trim() ||
    !this.insertFamilyDetailsData.account_no?.trim() ||
    !this.insertFamilyDetailsData.account_type?.trim()) 
  
) {
  this.alertmessages1("Please fill in nominee-related fields", "danger");
  return;
}

console.log("Edited Member ID:", this.insertFamilyDetailsData.id);


//Validations for pan , ifsc , account no , aadhar no 
 const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
 if (this.insertFamilyDetailsData.pan_number && !panRegex.test(this.insertFamilyDetailsData.pan_number)) {
     this.alertmessages1("Invalid PAN Card Number (Format: ABCDE1234F)","danger");
     return;
 }

 
 const ifscRegex = /^[A-Z]{4}0[A-Z0-9]{6}$/;
 if (this.insertFamilyDetailsData.ifsc_code && !ifscRegex.test(this.insertFamilyDetailsData.ifsc_code)) {
  this.alertmessages1("Invalid IFSC Code (Format: SBIN0001234)","danger");
  return;
 }

 
 const aadhaarRegex = /^[2-9]{1}[0-9]{11}$/;
 if (this.insertFamilyDetailsData.aadhaar_number && !aadhaarRegex.test(this.insertFamilyDetailsData.aadhaar_number)) {
  this.alertmessages1("Invalid Aadhaar Number (Must be 12 digits, should not start with 0 or 1)","danger");
return; 
}

const accountNumberRegex = /^[0-9]{9,18}$/;
if (this.insertFamilyDetailsData.account_no && !accountNumberRegex.test(this.insertFamilyDetailsData.account_no)) {
  this.alertmessages1("Invalid Account Number (Must be 9 to 18 digits)", "danger");
  return;
}

console.log("Checking total nomination percentage before updating...");
if (!this.updateTotalNominationPercentage(this.insertFamilyDetailsData.nomination_percentage, this.insertFamilyDetailsData)) {
    this.alertmessages1("Total nomination percentage cannot exceed 100%. Please correct the values.", "danger");
    return; // Stop update
}

// Call the update API
this.employeeMasterService.getUpdateFamilyDetails(this.tempUid, this.insertFamilyDetailsData)
  .subscribe(
    (data) => {
      console.log("Update response:", data);
      this.alertmessages1("Successfully updated", "success");

      // Reset UI and form data
      this.addOrUpdate = false;
      this.clearDetails();
      this.viewFamilyDetails();
    },
    (error) => {
      console.error("Update error:", error);
      this.alertmessages1("Please Try Again", "danger");
    }
  );

console.log("addOrUpdate status:", this.addOrUpdate);
}


closeMRQModal() {
  $('#showAlertVer').modal('toggle');

}

onDeleteHandler(id: any) {
  console.log("delete : ", id);
  this.insertFamilyDetailsData.id = id;
  $('#showAlertVer').modal('toggle');
}



onNominationChange(event: any) {
  console.log("Nomination Percentage Changed:", event.target.value);
  this.insertFamilyDetailsData.nomination_percentage = event.target.value ? Number(event.target.value) : 0;
}


editFamilyDetails(id: any, info: any) {
  console.log("edit pcr");
  console.log("Id in edit fn ", id);
  console.log("Information in edit", info);

  this.add_view_toggle = 'addFamilyMember'; // Ensure view updates
  this.addOrUpdate = false;

  const formattedDate = this.datepipe.transform(info?.dob, 'yyyy-MM-dd');
  console.log(formattedDate, "update date");
 
  console.log("In edit before editing fun nomination percentage",info.nomination_percentage)
  this.insertFamilyDetailsData.nomination_percentage=info.nomination_percentage;
  this.insertFamilyDetailsData.dob = formattedDate;
  this.insertFamilyDetailsData.aadhaar_number=info.aadhaar_number;
  this.insertFamilyDetailsData.pan_number=info.pan_number;
  this.insertFamilyDetailsData = info;
  console.log("In edit function nomination percentage",this.insertFamilyDetailsData.nomination_percentage)
  

  
}


filterNumericInputForMobile_Number(event: any): void {
  event.target.value = event.target.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
  this.insertFamilyDetailsData.mobile_number = event.target.value; // Update ngModel value
}

filterNumericInputForAadharNum(event: any): void {
  event.target.value = event.target.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
  this.insertFamilyDetailsData.aadhaar_number = event.target.value; // Update ngModel value
}

filterPanInput(event: any): void {
  event.target.value = event.target.value.toUpperCase().replace(/[^A-Z0-9]/g, ''); // Allow only uppercase letters & numbers
  if (event.target.value.length > 10) {
    event.target.value = event.target.value.substring(0, 10); // Limit length to 10 characters
  }
  this.insertFamilyDetailsData.pan_number = event.target.value; // Update PAN ngModel value
}

filterNumericInput(event: any): void {
  event.target.value = event.target.value.replace(/[^0-9]/g, ''); // Allow only numbers (0-9)
  this.insertFamilyDetailsData.account_no = event.target.value; // Update ngModel
}

filterIFSCInput(event: any): void {
  event.target.value = event.target.value.toUpperCase().replace(/[^A-Z0-9]/g, ''); // Allow only uppercase letters & numbers
  this.insertFamilyDetailsData.ifsc_code = event.target.value; // Update ngModel
}


deleteFamilyDetails() {
  console.log("delete Family details : ", this.insertFamilyDetailsData)
  //const id = this.FamilyDetailsData.id;

  if (this.insertFamilyDetailsData.id === null) {
    console.error("No ID selected for deletion");
    return;
  }
  console.log("Deleting family member with ID:", this.tempUid);
  console.log("Deleting family member with ID:", this.uniqueempid);
  console.log("Deleting family member with ID:", this.insertFamilyDetailsData.id);
  this.employeeMasterService.deleteFamilyDetails(this.tempUid,this.insertFamilyDetailsData.id).subscribe(
    data => {
      console.log("delete  family detail ", data)
      this.alertmessages1("Successfully deleted ", "success")
      this.getFamilyDetails(this.tempUid);
      this.clearDetails();
     
      $('#showAlertVer').modal('toggle');
    }, error => {

      $('#showAlertVer').modal('toggle');
      this.alertmessages1("Family Detail Id is not deleted ", "danger")
      console.log(error);
    }
  );

  
}


validateNominationPercentage() {
  let value = this.insertFamilyDetailsData.nomination_percentage;

  // Prevent negative numbers or numbers above 100
  if (value < 0 || value > 100) {
      this.nominationError = "Nomination percentage must be between 0 and 100.";
      this.insertFamilyDetailsData.nomination_percentage = 0; // Reset invalid values
  } else {
      this.nominationError = "";
  }
}


onNomineeChange() {
  if (!this.insertFamilyDetailsData.nominee) {
    this.insertFamilyDetailsData.bank_name = '';
    this.insertFamilyDetailsData.ifsc_code = '';
    this.insertFamilyDetailsData.account_no = '';
    this.insertFamilyDetailsData.account_type = '';
    this.insertFamilyDetailsData.nomination_percentage = '';
  }
}

}









