<div class="app-content main-content">
  <div class="side-app main-container">
    <div class="alertcomp"></div>
    <!-- <div class="card-body px-0">
      <div class="page-leftheader my-3">
        <div class="page-title">Expense Submission</div>
      </div>
    </div> -->
    <div class="row">
      <div class="col-xl-12 col-md-12 col-lg-12">
        <div class="card">
          <div class="card-body">
            <form id="fcForm" class="form-horizontal form-label-left row" #expenseform="ngForm">

              <div class="row col-md-12 col-sm-12 col-xs-12">
                <div class="col-md-6 col-sm-6 col-xs-6">
                
                  <div class="form-group label-floating">
                    <label class="form-label">Expense Name</label>
                    <div>
                      <input type="text" name="clientname" class="form-control col-md-12 col-xs-12" autocomplete="off"
                        placeholder="Enter Expense Name" autofocus="true" [(ngModel)]="client_name" required
                        #clientname="ngModel" [ngClass]="(!clientname.valid && clientname.touched) ? 'is-invalid' : ''" />
                    </div>
                    <span class="help-text text-danger" *ngIf="(!clientname.valid && clientname.touched)">Please Enter Expense Name</span>
                  </div>
                </div>

                <div class="col-md-6 col-sm-6 col-xs-6">
                  <div class="form-group label-floating">
                    <label class="form-label">Date of Expense </label>
                    <div>
                      <input type="date" class="form-control" name="date_of_expense" [(ngModel)]="date_of_expense"
                        required #dateofexpense="ngModel"
                        [ngClass]="(!dateofexpense.valid && dateofexpense.touched) ? 'is-invalid' : ''" />
                    </div>
                    <span class="help-text text-danger" *ngIf="(!dateofexpense.valid && dateofexpense.touched)">Please Enter
                      Date of Expense </span>
                  </div>
                </div>

                <div class="col-md-4 col-sm-4 col-xs-4">
                  <div class="form-group label-floating">
                    <label class="form-label" for="expansetype">Expense Type</label>
                    <div class="w-100">
                      <select class="form-control col-md-12 col-xs-12" style="height: 38px"
                        data-style="btn btn-drpdwn btn-round" id="expansetype"
                        [(ngModel)]="selectedList" required name="selectexpensetype" 
                        #selectexpensetype = "ngModel"
                        [ngClass]="(!selectexpensetype.valid && selectexpensetype.touched) ? 'is-invalid' : ''" 
                       >
                      
                        <option *ngFor="let expt of expenseType; let i = index" [ngValue]="expt">
                          {{ expt.expenseName }}
                        </option>
                      </select>
                    </div>
                    <span class="help-text text-danger" *ngIf="(!selectexpensetype.valid && selectexpensetype.touched)">Please Enter
                      Expense Type</span>
                  </div>
                </div>

                <div class="col-md-4 col-sm-4 col-xs-4">
                  <div class="form-group label-floating">
                    <label class="form-label">Amount</label>
                    <div>
                      <input type="text" class="form-control col-md-12 col-xs-12" autocomplete="off"
                        placeholder="Enter amount" name="enteredamount" autofocus="true" [(ngModel)]="amount" required #enteredamount="ngModel"
                        [ngClass]="(!enteredamount.valid && enteredamount.touched) ? 'is-invalid' : ''" />
                    </div>
                    <span class="help-text text-danger" *ngIf="(!enteredamount.valid && enteredamount.touched)">Please Enter
                      Amount</span>
                  </div>
                </div>


                <div class="col-md-4 col-sm-4 col-xs-4">
                  <div class="form-group label-floating">
                    <label for="form-label" class="form-label">Attachment</label>
                    <input type="file" id="file" class="form-control col-md-12 col-xs-12" name="myFilename"
                      style="box-sizing: border-box" required="required" #filename="ngModel"
                      [(ngModel)]="selectedfile"
                      (change)="onFileSelected($event)" [ngClass]="(!filename.valid && filename.touched) ? 'is-invalid' : ''"/>
                    <span class="help-text text-danger" *ngIf="(!filename.valid && filename.touched)">Upload
                      File</span>
                  </div>
                </div>
              </div>

              <div class="actionBar">
                <div class="clearfix"></div>
                <div class="pro-fab">
                  <button class="btn btn-success" type="button" (click)="submit()">
                    Save
                  </button>
                  <button class="btn btn-danger ms-2" type="button" (click)="clear()">
                    Clear
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="card col-md-12 col-sm-12 col-xs-12">
      <div class="card-header border-bottom-0">
        <h3 class="card-title">List of Expense</h3>
      </div>
      <div class="card-body card1S p-0">
        <div class="table-responsive">
          <div class="scrollsearchresults" id="scrolllength"
          id="scroll" infiniteScroll [infiniteScrollDistance]="2"
          [infiniteScrollUpDistance]="1.5" [infiniteScrollThrottle]="100" [scrollWindow]="false"
          (scrolled)="onScroll()">
          <table class="table table-hover table-vcenter text-nowrap table-bordered border-bottom" id="hr-table">
            <thead class="tablerow">
              <tr class="rowcolors stickyatTop">
                <th class="border-bottom-0 fs-7">Expense ID</th>
                <th class="border-bottom-0 w-5 fs-7">Expense Name</th>
                <th class="border-bottom-0 fs-7">Expense Date</th>
                <th class="border-bottom-0 fs-7">Expense Type</th>
                <th class="border-bottom-0 fs-7">Amount</th>
                <th class="border-bottom-0 fs-7">Approved Amount</th>
                <th class="border-bottom-0 fs-7">Attachment</th>
                <th class="border-bottom-0 fs-7">Status</th>
                <th class="border-bottom-0 fs-7">Action</th>
              </tr>
            </thead>
            <tbody style="max-height: 50vh; overflow: scroll; padding-bottom: 10vh;">
              <tr *ngFor="let exp of expenseList; let i = index">
                <td>{{ i + 1 }}</td>
                <td>{{ exp.client_name }}</td>
                <td>{{ exp.date_of_expense| date: 'mediumDate'}}</td>
                <td>{{ exp.expenseName }}</td>
                <td style="text-align: right;">
                  {{ exp.amount | number: '1.2-2' }}
                </td>
                <td style="text-align: right;">{{ exp.approvalamount  | number: '1.2-2'}}</td>
                <td>{{ exp.filename | slice:0:20 }}{{ exp.filename.length > 20 ? '...' : '' }}</td>
                <!-- <td>{{ exp.status }}</td> -->
                <td>


                  <span style="font-size: 13px; font-weight: 500;"
                                                [ngClass]="exp.status === 'Approved' ? 'badge-success' : 
                                                exp.status === 'Partial Approve 1' ? 'bg-custom-orange1 text-custom-dark' : 
                                                exp.status === 'Partial Approve 2' ? 'bg-custom-orange2 text-custom-dark' : 
                                                exp.status === 'Expense Approve Pending' ? 'bg-custom-warning text-custom-dark' : 
                                                exp.status === 'Rejected' ? 'badge-danger' : 'bg-custom-warning text-custom-dark'"
                                                class="badge">{{exp.status}}</span>
                  <!-- <span *ngIf="exp.status === 'PARTIAL APPROVED 1'"
                  class="badge badge-warning">{{exp.status}}</span>
                  <span *ngIf="exp.status === 'PARTIAL APPROVED 2'"
                  class="badge badge-warning">{{exp.status}}</span>
                  <span *ngIf="exp.status === 'EXPENSE APPROVE PENDING'"
                      class="badge badge-warning">{{exp.status}}</span>
                  <span *ngIf="exp.status === 'APPROVED'"
                      class="badge badge-success">{{exp.status}}</span>
                  <span *ngIf="exp.status === 'REJECTED'"
                      class="badge badge-danger">{{exp.status}}</span> -->
              </td>

              
                <td>
                  <button class="btn btn-primary btn-icon btn-sm me-2"
                    (click)="myDocumentDownload(exp.attachment, exp.filename)">
                    <i class="fa fa-download" data-bs-toggle="tooltip" data-original-title="download"></i>
                  </button>

                  <button class="btn btn-primary btn-icon btn-sm me-2"
                    (click)="viewdocument(exp.attachment, exp.filePath)" style="width: 32px;">
                    <i class="fa fa-eye" data-bs-toggle="tooltip" data-original-title="download"></i>
                  </button>
                  <button class="btn btn-danger btn-icon btn-sm me-2" data-method="delete" title="Delete"
                    data-bs-target="#showAlertWarning" (click)="onDeleteHandler(exp.id)">
                    <i class="fa fa-trash" data-bs-toggle="tooltip" data-original-title="edit"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div id="showAlertVer" class="modal" style="top: 40px;">
    <div class="modal-dialog">
        <div class="modal-content animate">
            <div class="modal-header">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">×</button>
                <h4 class="modal-title">Warning</h4>
            </div>

            <div class="modal-body" style="max-height: calc(100vh - 210px);overflow-y: auto;">
                <!-- auto scroll -->
                <span><b> Expense Submission data will be deleted permanently. Are you sure you want to continue?</b></span>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-danger medium" (click)="deleteExpense()">OK</button>
                <button type="button" class="btn btn-primary" (click)="closeMRQModal()">Cancel</button>
            </div>

        </div>
    </div>
</div>

