import { DatePipe, TitleCasePipe } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { Angular2Csv } from 'angular2-csv/angular2-csv';
import { AnnualHoliday } from '../models/AnnualHolidayInfo';
import { LeaveWorkFlow } from '../models/leaveWorkflow';
import { EmployeedocInfo } from '../models/EmployeedocInfo';
import { Noticeinfo } from '../models/Noticeinfo';
import { SessionService } from '../services';
import { CommonService } from '../services/common.service';
import { DocumentInfoService } from '../services/documentinfo.service';
import { EmployeeLeaveService } from '../services/employee-leave.service';
import { NoticeboardService } from '../services/noticeboard.service';


import { TaskDetailsForLeave } from 'src/app/models/task-details-for-leave.model';
import { DashboardService } from 'src/app/services/dashboard.service';
import { EventCalendar } from '../models/EventCalendar';
import { EmployeeAbsence } from '../models/EmployeeAbsenceinfo';


@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {


  loggedUsername: any;
  empabsencelist: EmployeeAbsence[];
  noOfemployeeabsent: number;
  currentdate: Date;
  myDocInfoDetails: any[];
  documentInfoDetails: EmployeedocInfo[];
  annualhoildaylist: AnnualHoliday[];

  filename: string = this.sessionservice.getloggedusername();
  noticeboardlist_based_on_currentdate: Noticeinfo[] = [];

  showLeaveForApproval: TaskDetailsForLeave[] = []
  showLeaveForApprovalListLength: number;

  teamMemberOnLeave: TaskDetailsForLeave[] = []
  teamMemberOnLeaveListLength: number;

  classFlag = false;
  noticeListSize: number;
  slash: String = '/';
  // statusFilter: any = 'Leave Approve Pending';
  statusFilter: any = 'all';
  leaveDetailFlow: LeaveWorkFlow[] = []
  eventDayList: EventCalendar[] = [];
  hasNotices: boolean = false;
  selectedNotice: any = null;
  birthdayWishesTitle: any = "Birthday Wishes";
  workAnniversaryWishesTitle: any = "Work Anniversary Wishes";
  othersTitle: any = "Others";
  brithdayFilteredList: Noticeinfo[];
  workAnniversarFilteredList: Noticeinfo[];
  othersTitleFilteredList: Noticeinfo[];

  filteredNotices: any[] = [];
  selectedNoticeCategory: string = '';



  constructor(private empleaveService: EmployeeLeaveService, private titleCase: TitleCasePipe, private commonService: CommonService, private sessionservice: SessionService, private docmentInfoService: DocumentInfoService, private datepipe: DatePipe, private noticeService: NoticeboardService, private dashboardService: DashboardService) { }

  ngOnInit() {
   
    
    this.loggedUsername = sessionStorage.getItem('username');
    this.currentdate = new Date();
    this.getemployeeAbsenceList();
    this.getApprovedDocumentList();
    this.getemployeedocumentMaster();
    this.getannualHoildayList()
    this.geteventlist();
    this.getLeaveForApproval()
    this.getLeaveForApprovalToday()

    this.getnoticeboardlist_based_on_currentdate()
   

  }

  ngAfterViewInit() {
    this.notificationForLicenseRenewal();
  }


  notificationForLicenseRenewal() {

    if(sessionStorage.getItem('role') === "Employer") {
    if (sessionStorage.getItem('remainingDays') && sessionStorage.getItem('validUptoDate')) {
      var remainingDaysString = sessionStorage.getItem('remainingDays');
      var validUptoDateString = this.datepipe.transform(sessionStorage.getItem('validUptoDate'), 'MMM d, y');
      var remainingDays = parseInt(remainingDaysString, 10);

      if (remainingDays <= Number(sessionStorage.getItem('validity_expiry_range'))) {
        
        if (remainingDays == 0) {     
          this.alertmessages("Your subscription will expire today. Please topup your account to enjoy uninterrupted service. Please contact customer support for topup.", "warning");
        } else {
          this.alertmessages("Your current subscription is valid until <strong>" + validUptoDateString + "</strong>. Please topup your account to enjoy uninterrupted service. Please contact customer support for topup.", "warning");
        }
      }
    }
  }
  }

  getemployeeAbsenceList() {
    if (parseInt(this.sessionservice.getempid()) <= 0) {
      this.noOfemployeeabsent = 0
      return;
    }
    this.empleaveService.getEmployeesAbsentList(this.sessionservice.getempid()).subscribe(data => {
      console.log(data)
      this.empabsencelist = data.empAbsencelist;
      this.noOfemployeeabsent = this.empabsencelist.length;
    }, error => {
      console.log(error)

    })
  }

  getApprovedDocumentList() {
    this.docmentInfoService.listMyDocument().subscribe(
      data => {
        console.log(data, "listalldoc")
        this.myDocInfoDetails = data.empDocuments;
        this.myDocInfoDetails = this.myDocInfoDetails.filter(empdoc => {
          return empdoc.overAllStatus === "Verified & Approved"
        })

        console.log(this.myDocInfoDetails, "doc that are verified")
      },
    );
  }

  downloadCSV() {

    var options = {
      title: 'Document Master Details',
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: true,
      useBom: true,
      headers: ['Employee Id', 'Document Group', 'Document Type', 'Mandatory', 'description', 'Created Date']
    };
    let currentDateTime = this.datepipe.transform((new Date), 'dd/MM/yyyy h:mm:ss');
    this.filename = this.filename + "_" + currentDateTime;
    let doclist = new Array<EmployeedocInfo>();
    this.documentInfoDetails.forEach(d => {
      let docinfo = new EmployeedocInfo();
      docinfo.createdBy = d.createdBy;
      docinfo.docGroup = d.docGroup;
      docinfo.docType = d.docType;
      docinfo.mandatory = d.mandatory;
      docinfo.description = d.description;
      docinfo.createdDate = this.datepipe.transform(d.createdDate, 'dd/MM/yyyy');

      doclist.push(docinfo);
    })
    new Angular2Csv(doclist, this.filename, options);
  }

  // get employee document master list downloaded 

  getemployeedocumentMaster() {
    this.docmentInfoService.getDocument("").subscribe(
      data => {
        this.documentInfoDetails = data.masterDocuments;
        console.log(this.documentInfoDetails, "documents")
      },
      error => {
        console.log("The Error is ", error);
      });
  }

  getannualHoildayList(): void {
    this.commonService.getAnnualHolidays().subscribe(data => {
      console.log(data, "annual holiday list")
      this.annualhoildaylist = data.annuallist
    });
  }

  // getnoticeboardlist_based_on_currentdate() {
  //   this.noticeService.getnoticeinfolist_based_on_currendate(this.datepipe.transform(new Date(), 'yyyy-MM-ddTHH:mm')).subscribe(response => {
  //     this.noticeboardlist_based_on_currentdate = response.notice_list_based_on_date;
  //     this.noticeListSize = this.noticeboardlist_based_on_currentdate.length;
  //     this.hasNotices = this.noticeListSize > 0; // Update flag based on notices
  //     console.log(this.noticeboardlist_based_on_currentdate, "noticeboard")
  //   })
  // }

  getnoticeboardlist_based_on_currentdate() {
    this.noticeService.getnoticeinfolist_based_on_currendate(this.datepipe.transform(new Date(), 'yyyy-MM-ddTHH:mm')).subscribe(response => {
      this.noticeboardlist_based_on_currentdate = response.notice_list_based_on_date;

      // Use variables for filtering
      this.brithdayFilteredList = this.noticeboardlist_based_on_currentdate.filter(
        notice => notice.title === this.birthdayWishesTitle
      );
      this.workAnniversarFilteredList = this.noticeboardlist_based_on_currentdate.filter(
        notice => notice.title === this.workAnniversaryWishesTitle
      );
      this.othersTitleFilteredList = this.noticeboardlist_based_on_currentdate.filter(
        notice => notice.title !== this.birthdayWishesTitle && notice.title !== this.workAnniversaryWishesTitle
      );

      // Set notice list size and flag
      this.noticeListSize = this.noticeboardlist_based_on_currentdate.length;
      this.hasNotices = this.noticeListSize > 0; // Update flag based on notices

      console.log(this.noticeboardlist_based_on_currentdate, "All Notices");
      console.log(this.brithdayFilteredList, "Birthday Wishes");
      console.log(this.workAnniversarFilteredList, "Work Anniversary Wishes");
      console.log(this.othersTitleFilteredList, "Others");
    });
  }


  openNoticeCategory(category: string) {
    this.selectedNoticeCategory = category;

    // Set filtered notices based on category
    if (category === 'Birthday Wishes') {
      this.filteredNotices = this.brithdayFilteredList;
    } else if (category === 'Work Anniversary Wishes') {
      this.filteredNotices = this.workAnniversarFilteredList;
    } else {
      this.filteredNotices = this.othersTitleFilteredList;
    }

    console.log(`Notices for ${category}:`, this.filteredNotices);
  }


  getLeaveForApproval() {
    console.log("Clicked");
    this.dashboardService.getLeaveForApproval().subscribe(
      data => {
        console.log("this.statusFilter ", this.statusFilter);
        this.leaveDetailFlow = data.leaveWorkflow; // since filter is all we can show every thing 

        // if filter is made to !=all then  uncomment below 
        // this.leaveDetailFlow.forEach(item => {
        //   item.employeeLeaveApplied.leaveStatus = this.titleCase.transform(item.employeeLeaveApplied.leaveStatus);
        // })
        // this.leaveDetailFlow = this.leaveDetailFlow.filter(
        //   ele => ele.employeeLeaveApplied.leaveStatus == this.statusFilter
        // );

        this.showLeaveForApprovalListLength = this.leaveDetailFlow?.length;
      },
      error => {
        console.error("Error while getting Leave For Approval ", error);
      }
    )
  }

  getLeaveForApprovalToday() {
    this.dashboardService.getLeaveForApprovalToday().subscribe(
      data => {
        // console.log("Approved :", data);
        this.teamMemberOnLeave = data.employeeApprovedLeaveList;
        this.teamMemberOnLeaveListLength = this.teamMemberOnLeave.length;

        // Commeent / Uncomment below
        // this.showLeaveForApproval = data.employeeApprovedLeaveList;
      },
      error => {
        console.error("Error while getting Leave For Approval ", error);
      }
    )
  }

  //event calendar

  geteventlist(): void {
    this.commonService.getEventDays().subscribe(data => {
      console.log(data, "event list")
      this.eventDayList = data.eventDayList;
    });
  }
  openNoticePopup(notice: any): void {
    this.selectedNotice = notice;
  }

  closePopup(): void {
    this.selectedNotice = null;
  }


  alertmessages(message: string, sign: string): void {
    let alertDiv = document.createElement('div');

    alertDiv.innerHTML = `<div class="d-flex justify-content-between align-items-center p-3 mb-2" role="alert" style="position: relative;">
      <p class="m-0">${message}</p>
      <button  class="btn close_warning p-2" style="position: absolute; top: 0; right: 0; color: black;">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-x" width="24" height="24"
              viewBox="0 0 24 24" stroke-width="2" stroke="black" fill="none" stroke-linecap="round"
              stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M18 6l-12 12" />
              <path d="M6 6l12 12" />
            </svg>
      </button>
    </div>` 

    alertDiv.querySelector('.close_warning').addEventListener('click', () => { alertcomp.removeChild(alertDiv) });

    let alertcomp = document.querySelector('.alertcomp');
    console.log("alertcomp" + alertcomp);
    console.log("alertDiv" + alertDiv);
    alertcomp.append(alertDiv);

    // document.body.scrollTop = document.documentElement.scrollTop = 0;

    // setTimeout(function () {
    //   alertcomp.removeChild(alertDiv);
    // }, 20000);
  }
}

